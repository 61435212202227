import React, { useEffect, useState } from 'react'
import { IStockSymbol } from 'interfaces/IStockSymbol';
import { searchSymbolService as searchService } from 'services/SearchSymbolService';
import * as Icons from 'react-bootstrap-icons';
import { SymbolSearchResult } from 'components/public/ListingsPage/components/AutoSuggestSearch';
import { useTopSymbols } from '_hooks/useTopSymbols';

export const SearchStock: React.FunctionComponent <{ onSelect: (s: IStockSymbol) => void }> = ({ onSelect }) => {

  const [searchString, setSearchString] = useState<string>('');
  const [symbolResults, setSymbolResults] = useState<IStockSymbol[] | undefined>(undefined);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const topSymbols = useTopSymbols();

  useEffect(()=>{
    if (topSymbols.apiState.isLoaded && topSymbols.data && searchString === "") {
      setSymbolResults(topSymbols.data)
    }
  },[topSymbols.apiState, topSymbols.data, searchString])

  const onChangeSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value;
    setSearchString(searchString);

    const searchForSymbol = async () => {

      if (isFetching) {
        searchService.cancel();
      };

      try {
        setIsFetching(true);
        const results = await searchService.search({symbolNameLike: searchString});
        setSymbolResults(results.sort((a, b) => a.symbolName.localeCompare(b.symbolName)));
        setIsFetching(false);
      } catch (error: any) {
        setIsFetching(false);
        console.error((error as Error).message)
      }    
    }

    // first char is neither @ or $
    if (searchString.length > 0 ) {
      searchForSymbol();
    }

    if (searchString.length === 0 && topSymbols.apiState.isLoaded) {
      //setSymbolResults(topSymbols.data || []);
    }
  }

  const onSubmit = async (searchString: string) => {
    // try {
    //   // const options: ISearchOptions = {
    //   //   symbolNames: symbols ? symbols.map((s: string) => s.replaceAll('$', '')) : undefined
    //   // };
    //   // onSearchCallback(options);
    // } catch (error) {
    //   console.error(error);
    // }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit(searchString);
    }
  }

  return (

    <div className="search w-100">
      <div className="search-form my-3 w-100">
        <form>
          <div className="d-flex bg-off-black no-hover flex-row search justify-content-center align-items-center search-container">
            <div className="mx-2 search-start"><Icons.Search size="1.5rem" /></div>
            <div className="search-body">
              <div className="form-group">
                <input type="text" className="search-text"
                  value={searchString}
                  onChange={onChangeSearch}
                  onKeyDown={onKeyDown}
                  maxLength={30}
                  placeholder='Search Stock Name, Description'
                  style={{backgroundColor: "#111111"}}
                />
              </div>
            </div>
            <div className="mx-2 search-end"></div>
          </div>
        </form>

        {symbolResults && symbolResults.length > 0 &&
          <div className="my-3 search-stock">{symbolResults.map((symbolResult: IStockSymbol, i: number) => <div key={`symbol-search-key-${i}`} role="button"
            onClick={() => { onSelect(symbolResult) }}>
            <SymbolSearchResult stockSymbol={symbolResult} /></div>)}
          </div>
        }

      </div>


    </div>

  )
}

