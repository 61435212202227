import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { IRecentActivity } from 'interfaces/IRecentActivity';
import { FollowedAvatars } from './FollowedAvatars';
import { Url } from '_constants';
import { NotificationHelper } from './NotificationHelper';
import { useLastFollowedPrediction } from '_hooks/useLastFollowedPrediction';
import { Helper } from '_utils';

export const FollowedPredictions: React.FunctionComponent<{ activity: IRecentActivity }> = ({ activity }) => {
  const navigate = useNavigate();
  const [text, setText] = useState<React.ReactNode>(<></>);

  const apiFiltered = useLastFollowedPrediction();


  useEffect(() => {
    const load = async () => {
      if (activity.items && activity.items.length > 0) {
        const ids = activity.items.map(i => i.userId as number);
        const names = await NotificationHelper.getUserNames(ids.splice(0, 3));
        if (ids.length > 0) {
          setText(
            <>
              Recent predictions from <span className="fw-bold">{names.map(n => n.username).join(', ')}</span> and {ids.length} other{ids.length > 1 ? 's' : ''}
            </>);
        } else {
          setText(
            <>
            Recent predictions from <span className="fw-bold">{names.map(n => n.username).join(', ')}</span>
            </>
          );
        }
      }
    }
    load();
  }, [activity])

  return (
    <FollowedAvatars
      dateOverride={<span className="text-12 fst-italic text-gray">{apiFiltered.apiState.isLoaded && apiFiltered.data.length > 0 && apiFiltered.data[0].createdAt ? Helper.timeElapsed(new Date(apiFiltered.data[0].createdAt * 1000), new Date()) + ' ago': ''}</span>}
      numberOfAvatars={3} activity={activity} onClick={() => navigate(Url.USER_NOTIFICATIONS_FOLLOWED_USER_PREDICTIONS)}>
      {activity.items && activity.items.length > 0 &&
        <>{text}</>
      }
    </FollowedAvatars>
  );

}
