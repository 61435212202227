import { PixelHelper } from '_utils/PixelHelper';
import React, { useState } from 'react'
import { ZoomIn, ZoomOut } from 'react-bootstrap-icons';

export enum ChartTypeEnum {
  LINE = 1,
  CANDLE = 2,
}

interface IProps {
  chartType: ChartTypeEnum, 
  setChartType: (c: ChartTypeEnum)=>void,
}

export const ChartTypeToggle: React.FunctionComponent<IProps> = ({chartType, setChartType}) => {

  const [currentTypeChart, setCurrentChartType] = useState<ChartTypeEnum>(chartType)
  const className = 'btn bg-charcoal px-3 text-white d-inline-flex justify-content-center align-items-center gap-2 text-11 fw-bold';

  const onClick = (c: ChartTypeEnum) => {
    setCurrentChartType(c);
    setChartType(c);
    PixelHelper.clickZoom(c === ChartTypeEnum.LINE ? 'out' : 'in');
  }

  const getButton = () => {
    if (currentTypeChart === ChartTypeEnum.CANDLE) {
      return <div role="button" onClick={()=>onClick(ChartTypeEnum.LINE)} className={className}><ZoomOut /> Zoom Out</div>
    }
    return <div role="button" onClick={()=>onClick(ChartTypeEnum.CANDLE)} className={className}><ZoomIn /> Zoom In</div>
  }
  
  return (
    <>
      {getButton()}
    </>
  )
}
