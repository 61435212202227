import { SymbolsContext } from 'components/common/SymbolsContext';
import { IStockSymbol } from 'interfaces/IStockSymbol';
import React, { useContext, useEffect, useState } from 'react';
import { SymbolToggleButton } from '../dashboard/SymbolToggleButton';

export const SymbolList: React.FunctionComponent<{ initialValue?: IStockSymbol, onSelect: (s: IStockSymbol) => void, onChange: () => void }> = ({ initialValue, onSelect, onChange }) => {

  const [symbols, setSymbols] = useState<IStockSymbol[]>([]);
  const [value, setValue] = useState<IStockSymbol | undefined>(initialValue);
  const [showValue, setShowValue] = useState<boolean>(initialValue !== undefined);
  const symbolsContext = useContext(SymbolsContext);

  useEffect(() => {
    const _load = async () => {
      setSymbols(await symbolsContext?.symbols || []);
      //console.log({symbols})
    }
    _load();
  }, [showValue, symbolsContext])

  const onClick = (s: IStockSymbol) => {
    setValue(s);
    onSelect(s);
    setShowValue(true);
  }

  const _onChange = () => {
    setShowValue(false);
    onChange();
  }

  return (
    <>
      {value && showValue &&
        <div>
          <div className="btn-curved-side bg-black fw-bold p-2">
            <div className="d-flex flex-row mx-2">
              <div className="flex-grow-1">{value.symbolName}</div>
              <div className="flex-grow-0 ms-auto text-lavender" onClick={() => { _onChange() }} role="button">Change Symbol</div>
            </div>
          </div>
        </div>
      }

      {symbols.length > 0 && !!!showValue &&
        <div className="stock-symbol-container">
          <div className="mb-3">Select from a list of supported stock symbols below. Additional symbols will be added soon.</div>
          <div className="stock-symbol-list gap-2">
            {/* {symbols.map((s: IStockSymbol, i: number) => <div role="button" className={`stock-symbol-list-item ${value?.symbolDescription === s.symbolDescription ? "stock-symbol-list-item-selected" : ""}`} key={`stock-item-key-${i}`} onClick={() => onClick(s)}>
              <div className="stock-symbol fw-bold">{s.symbolName}</div>
              <div className="stock-name text-smaller">{s.symbolDescription}</div>
            </div>)} */}

            {symbols.filter(s=>s.symbolName!=='TNA').map((s, i) => <div key={`key-toggle-${i}`}><SymbolToggleButton isNew={s.symbolName==='IWM'} stockSymbol={s} initialSelected={false} onClick={(s, selected) => {
              const symbol = symbols.find((symbol) => s === symbol.symbolName);
              if (symbol) {
                onClick(symbol);
              }
            }}/></div>)}

          </div>
        </div>
      }
    </>
  );

}