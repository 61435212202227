import { config } from "config";
import { IContest } from "interfaces/IContest";
import { userAuthenticationService } from "services/AuthenticationService";
import { BaseApiService } from "./BaseApiService";
import axios from 'axios';
import { IUserStatistic } from "interfaces/IUserStatistic";


export interface IContestStats extends IUserStatistic {
  count?: number
}

class UserContestApiServiceError extends Error {}

class UserContestApiService<T> extends BaseApiService<T> {

  async getContestStats(contestId: number): Promise<IContestStats[]> {
    const url = `${this.apiBaseUrl}/contest/stats/${contestId}`;
    try {
      const response: any = await axios.post<IContestStats[]>(url, {})    
      if (response.data.error) {
        throw new UserContestApiServiceError(response.data.error.message);
      }
      return response.data.data as IContestStats[];
    } catch (error: any) {
      throw new UserContestApiServiceError((error as Error).message);
    }
  }

  async getContestPublic(contestId: number): Promise<IContest> {
    const url = `${this.apiBaseUrl}/contest/${contestId}`;
    try {
      const response: any = await axios.post<IContest>(url, {})    
      if (response.data.error) {
        throw new UserContestApiServiceError(response.data.error.message);
      }
      return response.data as IContest;
    } catch (error: any) {
      throw new UserContestApiServiceError((error as Error).message);
    }
  }


  async getAllPublic(): Promise<IContest[]> {
    const url = `${this.apiBaseUrl}/contests`;
    try {
      const response: any = await axios.post<IContest>(url, {})    
      if (response.data.error) {
        throw new UserContestApiServiceError(response.data.error.message);
      }
      return response.data.data as IContest[];
    } catch (error: any) {
      throw new UserContestApiServiceError((error as Error).message);
    }
  }


  async joinContest(contestId: number): Promise<boolean>  {
    const url = `${this.apiBaseUrl}/management/contest/join/${contestId}`;
    try {
      const response: any = await axios.post<any>(url, {}, await this.authenticationService.getAuthHeader())
      if (response.data.success === false) {
        throw new UserContestApiServiceError('Unexpected error. Could not join contest.');
      }
      return true;
    } catch (error: any) {
      throw new UserContestApiServiceError((error as Error).message);
    }
  }

  async leaveContest(contestId: number): Promise<boolean>  {
    const url = `${this.apiBaseUrl}/management/contest/leave/${contestId}`;
    try {
      const response: any = await axios.post<any>(url, {}, await this.authenticationService.getAuthHeader())
      if (response.data.success === false) {
        throw new UserContestApiServiceError('Unexpected error. Could not leave contest.');
      }
      return true;
    } catch (error: any) {
      throw new UserContestApiServiceError((error as Error).message);
    }
  }  
}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const userContestApiService: UserContestApiService<IContest> = new UserContestApiService<IContest>(apiBaseUrl, '/management/contest', '/management/contests', userAuthenticationService);

export {
  UserContestApiService,
  userContestApiService,
  UserContestApiServiceError
}
