import React, { useContext } from 'react';
import { Url } from '_constants';
import { UserRoleEnum } from '_constants/UserRoleEnum';
import { AccessDenied } from '../AccessDenied';
import { AccountContext } from '../AccountContext';

interface IProps {
  roles?: UserRoleEnum[],
  children: JSX.Element,
  message?: string | JSX.Element,
}
const ProtectedRoute: React.FunctionComponent<IProps> = ({roles, children, message}) => {

  const acctContext = useContext(AccountContext);
  const account = acctContext?.account;
  
  // use this when roles have been added to the account
  // const isAllowed: boolean =
  // !roles ||
  // roles.length === 0 ||
  // (account && account.roleId && roles.indexOf(account.roleId) >= 0) || false;

  // if no roles, having an account is sufficient access
  const isAllowed: boolean =  account !== undefined && account !== null;  

  if (isAllowed) {
    return children;
  }

  return <AccessDenied account={account} loginUrl={Url.USER_LOGIN} message={message} />
}

export { ProtectedRoute }