import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { PredictionListLazy } from '../ListingsPage/components/PredictionListLazy';
import { ConsensusChart } from './ConsensusChart';
import { NewYorkTz, PredictionTypeEnum, StockHelper } from 'predictagram-lib';
import { usePublicPrediction } from '_hooks/usePublicPrediction';
import { APIState } from '_constants/APIState';
import { Spinner } from 'react-bootstrap';
import { AccountContext } from 'components/common';
import { sortTypeEnum } from '../ListingsPage/components/Prediction/SortTypeSelect';
import { PredictionCountHelper } from '_utils/PredictionCountHelper';
import { TheresMoreModal } from './TheresMoreModal';
import { LeftArrow } from 'components/common/Icons';
import { StockPriceChange } from './StockPriceChange';
import { Helmet } from 'react-helmet';
import { config } from 'config';
import { SeoModel } from 'models/seo.model';
import { ModalSignupLogin } from 'components/public/Stock/predictor/ModalSignUpLogin';

export const hasPredictionsToday = (): boolean => {
  const predictionsToday = PredictionCountHelper.get(NewYorkTz.getDateMidnight(new Date()).getTime(), localStorage) || 0;
  return predictionsToday > 0;
}

export const StockListingPageAnon: React.FunctionComponent = () => {

  const { stockSymbol: _stockSymbol } = useParams();
  const stockSymbol = _stockSymbol?.toUpperCase();
  const location = useLocation();
  const [predTypes, setPredTypes] = useState<PredictionTypeEnum[]>([]);
  const { sortType, team, referrerProfile } = location.state || { sortType: sortTypeEnum.BEST };
  const [showTeamPrompt, setShowTeamPrompt] = useState<boolean>(team === undefined && referrerProfile); // show if user team is not defined and has a refererr
  const acctContext = useContext(AccountContext);
  const navigate = useNavigate();
  const [showRegister, setShowRegister] = useState<boolean>(false);

  const { items: predictions, apiState, setSort, options } = usePublicPrediction(userPredictionApiService, {
    orderBy: sortType || sortTypeEnum.BEST,
    symbolNames: [stockSymbol?.toUpperCase()],
  } as ISearchOptions);


  const StockDetail = () => {
    return (
      <div className="stock-detail d-flex flex-row align-items-center">
        <div className="me-3 stock-detail-symbol">{stockSymbol}</div>
        {stockSymbol && (StockHelper.isMarketOpen(new Date()) || StockHelper.isPostMarketHours(new Date())) && <StockPriceChange stockSymbol={stockSymbol} />}
      </div>
    );
  }

  return (
    <>
      <ModalSignupLogin
        show={showRegister}
        handleClose={()=>setShowRegister(false)}
        loginTitle={<div className="fw-bold text-18">Login</div>}
        registerTitle={<div className="fw-bold text018">Register</div>}
        tagLine={<>See more stock price predictions for <span className="fw-bold">{stockSymbol}</span> and other top tickers.</>}
      />

      <Helmet>
        <title>Predictagram: Stock Predictions - {stockSymbol} </title>
        <meta property="og:title" content={`Predictagram: Stock Predictions - ${stockSymbol}`} />
        <meta property="og:description" content={`Check out this stock prediction for ${stockSymbol}`} />
        <meta property="og:url" content={`${config.wwwDomain}${location.pathname}`} />
        {stockSymbol && SeoModel.symbolMetaDescriptionMap.has(stockSymbol) && <meta name="description" content={SeoModel.symbolMetaDescriptionMap.get(stockSymbol)} />}
        <meta name="keywords" content={`${stockSymbol} Stock, ${stockSymbol} stock price, ${stockSymbol} ticker, ${stockSymbol} stock chart, ${stockSymbol} price target, ${stockSymbol} analyst, ${stockSymbol} trading, ${stockSymbol} options, ${stockSymbol} sentiment, ${stockSymbol} earnings, ${stockSymbol} stock predictions, ${stockSymbol} comments, ${stockSymbol} message board, ${stockSymbol} premarket, ${stockSymbol} dividend`} />
      </Helmet>
      <div className="container">
        {showTeamPrompt &&
          <TheresMoreModal referrerProfile={referrerProfile} team={team} show={showTeamPrompt} handleClose={() => setShowTeamPrompt(false)} />
        }

        <div className="row mb-2">
          <div className="col-5">
            <div className="d-flex justify-content-start align-items-center gap-2">
              <div role="button" onClick={() => navigate(-1)}><LeftArrow /></div>
              <StockDetail />
            </div>
          </div>
          <div className="col-auto d-none d-md-block"><span className="fw-bold">{stockSymbol} </span>Stock Chart</div>
        </div>

        {stockSymbol && 
          <div className="mt-2">
            <div className="d-block d-md-none text-center my-2"><h1 className="text-18"><span className="text-18 fw-bold">{stockSymbol} </span>Stock Chart</h1></div>
            <ConsensusChart title={<h1 className="text-18"><span className="fw-bold">{stockSymbol} </span>Stock Price</h1>} stockSymbol={stockSymbol} predictionTypes={predTypes}/>
          </div>
        }

        {stockSymbol && SeoModel.symbolMetaDescriptionMap.has(stockSymbol) && !acctContext?.isLoggedIn() &&
          <>
            <h1 className="text-18 mt-3">About <span className="fw-bold">{stockSymbol} </span>Stock</h1>
            <div className="text-12 mt-3">{SeoModel.symbolMetaDescriptionMap.get(stockSymbol)}</div>
          </>
        }

        {apiState === APIState.LOADING &&
          <Spinner />
        }

        {apiState === APIState.LOADED && predictions.length > 0 &&
          <div className="my-3">
            
            <PredictionListLazy
              title={<h1 className="text-18 mt-3"><span className="fw-bold">{stockSymbol} </span>Stock Price Predictions</h1>}
              onScrollBottom={()=>setShowRegister(true)}
              predictions={predictions}
              onClickFilterTab={setPredTypes}
              onSelectSort={setSort} orderBy={options?.orderBy || sortTypeEnum.BEST} />
          </div>
        }
      </div>
    </>

  );
}
