import { IPublicProfile } from "interfaces/IPublicProfile";
import { useCallback, useEffect, useState } from "react";
import { UserPredictionApiService, UserPredictionServiceError } from "services/UserPredictionApiService";

export function usePublicProfile (id: number, service: UserPredictionApiService, isCacheOk: boolean = true) {

  const [ item, setItem ] = useState<IPublicProfile>({} as IPublicProfile);
  const [ loaded, setLoaded ] = useState<boolean>(false);
  const [ error, setError ] = useState<UserPredictionServiceError | undefined>(undefined);

  const load = useCallback(async ()=>{
    try {
      const item = await service.getPublicProfile(id, isCacheOk)
      setItem(item);
      setLoaded(true);
    } catch (error) {
      setError(error as Error);
    }
  }, [id, service, isCacheOk])

  useEffect(()=>{
   load();
  }, [load])

  return {item, loaded, error};

}