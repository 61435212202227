import { StorageKey } from "_constants";

export class PredictionCountHelper {
  static add (dayEpoch: number, storage: Storage) {

    // if nothing, create it
    const counters = storage.getItem(StorageKey.PREDICTION_COUNTER);

    if (!counters) {
      const cmap: Map<number, number> = new Map();
      cmap.set(dayEpoch, 1);
      storage.setItem(StorageKey.PREDICTION_COUNTER, JSON.stringify(Array.from(cmap.entries())));
      return;
    }

    if (counters) {
      // is day exists
      const cmap: Map<number, number> = new Map(JSON.parse(counters));
      const count = cmap.get(dayEpoch);
      if (count) {
        cmap.set(dayEpoch, count + 1);
      } else {
        cmap.set(dayEpoch, 1);
      }
      storage.setItem(StorageKey.PREDICTION_COUNTER, JSON.stringify(Array.from(cmap.entries())));
    }

  }

  static get (dayEpoch: number, storage: Storage): number | undefined {
    const counters = storage.getItem(StorageKey.PREDICTION_COUNTER);
    if (counters) {
      const cmap: Map<number, number> = new Map(JSON.parse(counters));
      return cmap.get(dayEpoch) || undefined;
    }
    return undefined;
  }

  static clear (storage: Storage): void {
    storage.removeItem(StorageKey.PREDICTION_COUNTER);
  }
}