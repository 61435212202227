import axios from 'axios';
import {IQuote} from 'components/public/Stock/IQuote';
import {config} from "../config";
import LRUCache from "lru-cache";
import {IStockSymbol} from 'interfaces/IStockSymbol';
import {DateEx, DateHelper, IResponseReport, PredictionTypeEnum, StockHelper, StockStatsIntervalEnum as Interval} from 'predictagram-lib';
import {IStockStat} from "../components/public/Stock/IStockStat";

class PredictionServiceError extends Error {}

class PredictionService {
    private cache: LRUCache<string, []>;

  constructor (
    protected config: {apiUrlBase: string,},
  ) {
      this.cache = new LRUCache({
        max: 20,
        ttl: 60*2*1000, // msec
      });
  }


  async getSymbols (): Promise<IStockSymbol[]> {
    const url = `${this.config.apiUrlBase}/user/stock/symbols`;
    try {
      const response = await axios.post(url);
      return response.data.data as IStockSymbol[];
    } catch (error: any) {
      throw new PredictionServiceError((error as Error).message);
    }
  }

  async getTopSymbols (): Promise<IStockSymbol[]> {
    const url = `${this.config.apiUrlBase}/user/stock/symbols/top/traded`;
    try {
      const response = await axios.post(url);
      return response.data.data as IStockSymbol[];
    } catch (error: any) {
      throw new PredictionServiceError((error as Error).message);
    }
  }

  async getStatsCumulative(tickerSymbol: string, startTime: DateEx, endTime: DateEx, predictionTypes?: PredictionTypeEnum[]) {
      // @TODO: unify UI error handling
    const filters:any = {filters: {
        symbolName: tickerSymbol,
        startTime: startTime.getTimeSec(),
        endTime: endTime.getTimeSec(),
      }};
    if (predictionTypes) {
      filters.filters.predictionTypes = predictionTypes;
    }
      const response = await axios.post(this.config.apiUrlBase + '/user/prediction/stats/cumulative', filters);
      const report = response.data as IResponseReport;
      return report.data as IStockStat[];

  }

  async getBarsData(tickerSymbol: string, startDateTime: DateEx, endDateTime: DateEx, cacheOk=false, interval: Interval= Interval.MIN_1): Promise<ReadonlyArray<IQuote>> {

    //@TODO: it can be 2 caches: for today and previous days. Prev days can have higher exp time
    const key = `${tickerSymbol}${interval}${startDateTime}${endDateTime}`;
    if (cacheOk) {
        const data = this.cache.get(key);
        if (data) {
            //console.debug(`cache hit ${key}`);
            return data;
        }
    }

    const apiUrl = this.config.apiUrlBase + '/user/stock/stats/' + interval;
    try {
      const response = await axios.post(apiUrl, {filters: {
          symbolName: tickerSymbol,
          startTime: startDateTime.getTimeSec(),
          endTime: endDateTime.getTimeSec(),
      }});
      const d = response.data.data;
      this.cache.set(key, d);
      return d;
      // const response = await axios.get(apiUrl, axiosOptions );
      // return response.data.bars;
    } catch (error: any) {
      throw new PredictionServiceError(error);
    }

  }

  async getLastQuote(tickerSymbol: string, interval: Interval= Interval.MIN_1): Promise<IQuote> {
    try {
      const apiUrl = `${this.config.apiUrlBase}/user/stock/stats/latest/${interval}`;
      const response = await axios.post(apiUrl, {filters: {symbolName: tickerSymbol}});
      return response.data as IQuote
    } catch (error: any) {
      throw new PredictionServiceError((error as Error).message);
    }    
  }

  async getLastQuoteLive(tickerSymbol: string): Promise<IQuote> {
    try {
      const apiUrl = `${this.config.apiUrlBase}/user/stock/stats/latest/live`;
      const response = await axios.post(apiUrl, {filters: {symbolName: tickerSymbol}});
      if (response.data === "") {
        throw new PredictionServiceError("no data for symbol")
      }
      return response.data as IQuote
    } catch (error: any) {
      throw new PredictionServiceError((error as Error).message);
    }    
  }


  async getCloseQuote(tickerSymbol: string, dateUtc: Date = new Date()): Promise<IQuote> {
    try {
      if (DateHelper.isSameDay(new Date(), dateUtc)) {
        const s = new Date();
        const dates = StockHelper.workingHours(s);
        const quote = await this.getBarsData(tickerSymbol, dates.start, dates.end, false);
        return quote[quote.length - 1];
      }


      const wh = StockHelper.workingHours(dateUtc);
      const end = wh.end;
      const start = end.getTime() - (5 * 60 * 1000); // get last 5 minutes
      const quote = await this.getBarsData(tickerSymbol, new DateEx(start), new DateEx(end), true);
      return quote[quote.length - 1];

    } catch (error) {
      throw new PredictionServiceError((error as Error).message);
    }
  }

}

const predictionService = new PredictionService({
    apiUrlBase: `${config.apiDomain}${config.apiBasePath}`,
});
export { predictionService, PredictionService }
