import { StockHelper } from "./stock.helper";
import { NewYorkTz } from "./new-york-tz";

type DateString = {
  d: Date,
  s: string,
};

export class IntradayHelper {

  static getWhen = (date: Date): DateString => {
    if (StockHelper.isMarketOpen(date) || (
      StockHelper.isTradingDay(date) && StockHelper.isPreMarketHours(date)
    )) {
      return {d: StockHelper.workingHours(date).end, s:'Today'};
    }

    const tomorrow = new Date(date.getTime());
    tomorrow.setDate(date.getDate() + 1);
    const nextTradingDay = StockHelper.getNextTradingDay(date);
    if(NewYorkTz.isSameDay(nextTradingDay, tomorrow)) {
      return {d: StockHelper.workingHours(tomorrow).end, s: 'Tomorrow'};
    }

    // it's not tomorrow. it could be weekend or holiday.
    return {d: StockHelper.workingHours(nextTradingDay).end, s: NewYorkTz.getWeekday(nextTradingDay)};
  }


  static getMidTime = (date: Date): DateString => {

    const utcOffset = Math.abs(NewYorkTz.getTimezoneOffset(date));
    const nyTime = NewYorkTz.format(date).hour24MinuteNumber();

    if (this.getWhen(date).s === 'Today') {
      if (nyTime <= 930) { // it's pre-market. set hours to 11:30
        const openHour = NewYorkTz.getDateMidnight(date);
        openHour.setUTCHours(11 + utcOffset,30, 0);
        return {d: openHour, s: NewYorkTz.format(openHour).hour24MinuteAmpm()};
      }

      if (nyTime <= 1330) { // not allowed beyond 1:00
        const halfHour = NewYorkTz.addHoursRounded(date, 2);
        return {d: halfHour, s: NewYorkTz.format(halfHour).hour24MinuteAmpm()};
      }
    }

    // post market
    const nextDay = NewYorkTz.getDateMidnight(StockHelper.getNextTradingDay(date));
    nextDay.setUTCHours(utcOffset + 11, 30); // set at 11:30
    return {d:nextDay, s: NewYorkTz.format(nextDay).hour24MinuteAmpm()};
  }

}
