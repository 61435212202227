import { APIState } from "_constants/APIState";
import { ILeagueSeason } from "interfaces/league";
import { useCallback, useEffect, useRef, useState } from "react";
import { UserLeagueApiService, UserLeagueApiServiceError } from "services/UserLeagueApiService";

export function useSeasons (service: UserLeagueApiService) {

  const [ items, setItems ] = useState<ILeagueSeason[]>([]);
  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<UserLeagueApiServiceError | undefined>(undefined);

  const serviceRef = useRef(service);

  const load = useCallback(async ()=>{
    try {
      setApiState(APIState.LOADING);
      const items = await serviceRef.current.getSeasons();
      setItems(items);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as UserLeagueApiServiceError);
    }
  }, [serviceRef])

  useEffect(()=>{
   load();
  }, [load])

  return {seasons: items, apiState, error};

}