export class MessageHelper {

  private static messages: Map<string, string> = new Map([
    ['Error: Error: error updating: "aboutMe" is not allowed to be empty', 'About Me cannot be empty'],
    ['username exists (username)', 'The username is already in use.'],
    ['email exists (email)', 'The email is already in use.'],
    ['username exists (nickname)', 'The nickname is already in use.'],
    ['nickname exists (nickname)', 'The nickname is already in use.'],
    ['Error: Error: error updating: phone number should be in international format', 'Invalid phone number format'],
    ['Error: Error: error updating: phoneNumber exists (phoneNumber)', 'This phone number is already in use'],
    ['Error: Error: error updating: "nickname" must only contain alpha-numeric, dash, underscore characters', 'Nickname may only contain alpha-numeric, dash, underscore characters'],
    ['Error: "valueAt" contains an invalid value', 'The predicted value is invalid. Please try again.'],
    ['Error: "valueTime" contains an invalid value', 'The prediction time is invalid. Please try again.'],
    ['Error: verified phone', 'Phone number has already been verified.'],
    ['Error: phoneNumber exists (phoneNumber)', 'Phone number is already in use.'],
    ['Error: Error: error updating: "phoneNumber" must be a number', 'Phone number is invalid.'],
    ['"ctoken" is not allowed to be empty', 'Please confirm you are human'],
    ['incorrect number of shares to close', 'We did not find enough available shares of this security in your account for the closing order as placed. Please check the number of shares you entered and resubmit your order. '],
    ['invalid price (price)', "The price entered is not within the stock's price range in the last few minutes."],
    ['outdated stats', "Sorry, real-time data is currently delayed for this symbol. Please try another symbol, or try again in a minute."]
  ])

  public static translate(msg: string) {
    const translation = this.messages.get(msg);
    if (translation) {
      return translation;
    }
    return msg;
  }

}