import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AvatarSizeEnum } from "components/common/Avatar";
import { AvatarById } from "components/common/AvatarById";
import { IRecentActivity } from "interfaces/IRecentActivity";
import { Helper } from '_utils';
import { UrlHelper } from '_utils/UrlHelper';

interface IProps {
  activity: IRecentActivity,
  onClick: () => void,
  numberOfAvatars: number,
  dateOverride?: React.ReactNode,
  children: any
}

export const FollowedAvatars: React.FunctionComponent<IProps> = ({ activity, onClick, numberOfAvatars, dateOverride, children }) => {
  const activityTime = activity.items && activity.items.length > 0 ? activity.items[0].createdAt : undefined;
  const navigate = useNavigate();

  const _onClick = (e: React.MouseEvent<HTMLDivElement>, userId: number) => {
    navigate(UrlHelper.getProfileUrl(userId), { replace: true });
    e.stopPropagation();
  }

  return (
    <div className="activity" role="button" onClick={() => onClick()}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-start gap-1 align-items-center">
          {activity.items && activity.items.length > 0 &&
            activity.items.slice(0, numberOfAvatars).map((item, i) =>
              <div key={`avatar-key-${i}`} className="activity-avatar" onClick={(e) => _onClick(e, item.userId as number)}>
                <AvatarById userId={item.userId as number} size={AvatarSizeEnum.SMALL} canClick={true} />
              </div>
            )
          }
        </div>

        {dateOverride ?
          <>{dateOverride}</>
          :
          <>
            {activityTime && <div className="text-end text-gray fst-italic text-12">
              {Helper.timeElapsed(new Date(activityTime * 1000), new Date())} ago
            </div>}
          </>
        }
      </div>
      <div className="activity-detail text-14 text-white mt-1">
        {children}
      </div>
    </div>
  )
}