import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { PriceLookup } from './PriceLookup';
import { useAdminAutoTradePriceLookup } from '_hooks/useAdminAutoTradePriceLookup';
import { formatUSCurrency } from './Trades';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import { IGroupedTradeSecurity } from './TradesListGroupBySecurityPage';

export const ManualTradeGroupModal: React.FunctionComponent<{
  show: boolean,
  handleClose: any,
  onConfirm: () => void,
  title: string,
  group: IGroupedTradeSecurity,
  isSubmitting: boolean,
}> = ({ show, handleClose, onConfirm, title, group, isSubmitting }) => {

  const priceApi = useAdminAutoTradePriceLookup(group.securityId);

  useEffect(()=>{
    priceApi.setFilter(group.trades[0].id);
  },[priceApi, group])

  const renderPrice = () => {
    if (priceApi.apiState.isLoaded) {
      const p = priceApi.data;
      return <tr>
        <td className="text-center">{formatUSCurrency(p.bidPrice)}</td>
        <td className="text-center">{formatUSCurrency(p.askPrice)}</td>
        <td className="text-center">{formatUSCurrency(p.lastPrice)}</td>
      </tr>
    }

    if (priceApi.apiState.isError) {
      return <tr><td><ErrorMessage className="bg-white text-black">{priceApi.error || ''}</ErrorMessage></td></tr>
    }

    if (priceApi.apiState.isLoading) {
      return <tr><td><Spinner minHeight={25} /></td></tr>
    }

    return <></>
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered keyboard={false}>
      <Modal.Body className="bg-white">

        {isSubmitting ?
          <div className="d-flex gap-2 bg-white text-black justify-content-center align-items-center p-3">Processing...<Spinner minHeight={25} /></div>

          :


          <div className="d-flex flex-column gap-1 mt-3 me-2 align-items-center px-3 text-black text-12">

            <div className="fw-bold text-16">{title}</div>
            <div className="text-14">{group.optionName}</div>

            <div>Latest Price</div>

            <table className="table table-striped table-hover">
              <thead>
                <th className="text-center">Bid Price</th>
                <th className="text-center">Ask Price</th>
                <th className="text-center">Current Price</th>
              </thead>
              <tbody>
                {renderPrice()}
              </tbody>
            </table>

            <div>Underlying Stock</div>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th className="text-end">Bid</th>
                  <th className="text-end">Ask</th>
                  <th className="text-end">Last</th>
                  <th className="text-end">Best Price</th>
                </tr>
              </thead>
              <tbody>
                <PriceLookup symbolId={group.stockSymbolId} />
              </tbody>
            </table>

            <div className="d-flex flex-column gap-2 align-items-center justify-content-center mb-3">
              <div className="fw-bold">Are you sure?</div>
              <div className="d-flex gap-2">
                <button type="button" className="btn btn-primary text-12" onClick={() => { onConfirm();}}>Confirm</button>
                <button type="button" className="btn btn-secondary text-12" onClick={handleClose}>Cancel</button>
              </div>
            </div>
          </div>
        }
      </Modal.Body>
    </Modal>
  )
}

