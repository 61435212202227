import React from 'react'
import { Spinner } from 'components/common/Spinner';
import { NewYorkTz as tz } from 'predictagram-lib';
import { Helper } from '_utils';
import { ISymbolDataLive } from 'interfaces/ISymbolData';

export const StockPrice:React.FunctionComponent<{symbolDataLive: ISymbolDataLive}> = ({symbolDataLive}) => {

  const formatted = (): string => {
    const lastPriceDate = new Date(symbolDataLive.time * 1000);
    const dt = tz.mdyhms(lastPriceDate);
    const dst = tz.getTimezoneOffset(lastPriceDate) === -5 ? 'EST' : 'EDT';
    return `${dt.month}/${dt.day}/${dt.year.substring(2)} ${tz.format(lastPriceDate).hour24MinuteAmpm()} ${dst}`;
  } 

  return (
<>
    {symbolDataLive ? (
      <div className="text-14">
      <span className="text-lavender fw-bold">{(symbolDataLive.stockValue).toFixed(2)}</span> <span className="prediction-detail-date">as of {Helper.roundUpToClosing(formatted())}</span>
      </div>
    ) : (
      <>
      <Spinner />
      </>
    )}
</>
  )
}

