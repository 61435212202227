import React, { useContext, useEffect } from "react";
import { IAccount, ICredentials } from "interfaces";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { AccountContext } from "components/common";
import { LoginForm } from "components/common";
import { Url } from "_constants";

interface IProps { 
  loginCallback?: () => void,
  registerCallback?: () => void,
  onClickForgotPassword?: () => void,
}

const UserLogin: React.FunctionComponent<IProps> = ({ loginCallback, registerCallback, onClickForgotPassword }) => {
  const navigate = useNavigate();
  const accountContext = useContext(AccountContext);

  useEffect(() => {
    if (accountContext?.account) {
      // check if expired; may need to log in again
      navigate(Url.USER_DASHBOARD);
    }
  }, [])

  const initialFormState: ICredentials = {
    email: "",
    password: "",
  };

  // const initialFormState: ICredentials = {
  //   email: "roy-1201-1@adblade.com",
  //   password: "h3@vyL1ftinG",
  // };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter your email address"),
    password: Yup.string().required("Password required"),
  });

  const onSubmit = async (data: any, actions: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    const redir = queryParams.get("redir");

    const { email, password } = data;
    const { setStatus } = actions;
    try {
      const account: IAccount | undefined = await accountContext?.login({ email, password });
      if (!account) {
        throw Error('Could not get account');
      }
      if (loginCallback) {
        loginCallback();
      } else {
        navigate(Url.DEFAULT_HOME, { replace: true });
      }

    } catch (error) {
      setStatus("Invalid Login");
    }
  };

  const onClickRegister = () => {
    if (registerCallback) {
      registerCallback();
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="login-form mx-3">
        <LoginForm 
            onClickRegister={onClickRegister} 
            initialFormState={initialFormState} 
            validationSchema={validationSchema} 
            onClickForgot={onClickForgotPassword}
            onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export { UserLogin };
