import { IPublicProfile } from 'interfaces/IPublicProfile';
import React from 'react'

export const PredictorProfitability:React.FunctionComponent<{profile: IPublicProfile}> = ({profile}) => {
  const color = (profile.broker.realizedProfitPct || 0) >= 0 ? "text-bright-green" : "text-bright-red";
  const text = `${profile.broker.realizedProfitPct >= 0 ? "+" : ""}${((profile.broker.realizedProfitPct || 0)).toFixed(2)}%`;
  return (
    <div className="predictor-profitability d-flex justify-content-center align-items-center gap-3">
      <div>
        <div className={`fw-bold text-16 ${color}`}>{text}</div>
        <div className="text-lighter-gray text-10">profitability</div>
      </div>

      <div>
        <div className={`fw-bold text-16`}>{profile.broker.transactions || 0}</div>
        <div className="text-lighter-gray text-10">trades</div>
      </div>

    </div>

  )
}

