import React from 'react';
import { ModalDoc } from './ModalDoc';
import { PrivacyPolicy } from './_static';

export const ModalPrivacyPolicy: React.FunctionComponent<{show: boolean, handleClose: ()=>void }> = ({show, handleClose}) => {
  return (
  <ModalDoc show={show} handleClose={handleClose} title="Privacy Policy">
  <PrivacyPolicy isModal={true}/>
  </ModalDoc>  
  );
}