
import { SymbolFilterTypeEnum } from 'components/user/dashboard/filters/SymbolFilter';
import { UserTypeFilterEnum, userTypeDropDownItems } from 'components/user/dashboard/filters/UserTypeDropDownFilter';
import { PredictionTypeFilterEnum } from 'components/user/dashboard/filters/PredictionTypeDropDownFilter';
import { TraderTypeFilterEnum, traderTypeDropDownItems } from 'components/user/dashboard/filters/TraderTypeDropDownFilter';

export interface IDashboardFilterSettings {
  userTypeFilter: UserTypeFilterEnum, //IDropdownFilterItem<UserTypeFilterEnum>,
  symbolFilterType: SymbolFilterTypeEnum,
  predictionTypeFilter: PredictionTypeFilterEnum, //IDropdownFilterItem<PredictionTypeEnum[]>,
  isExpanded: boolean,
  selectedSymbols: string[],
  traderTypeFilter: TraderTypeFilterEnum,
}

type returnType = [
  dashboardSettings: IDashboardFilterSettings, 
  setDashboardSettings: (settings: IDashboardFilterSettings) => void,
]
  
export function useDashboardSettings (storage: Storage, key: string): returnType {

  const dashboardSettings = (function () {
    const settings = storage.getItem(key) || "{}";
    if (settings === "{}") {
      // console.log('no default settings; creating');

      const defaultSettings: IDashboardFilterSettings = {
        isExpanded: true,
        predictionTypeFilter: PredictionTypeFilterEnum.ALL,
        symbolFilterType: SymbolFilterTypeEnum.ALL,
        userTypeFilter: userTypeDropDownItems[0].value,
        selectedSymbols: [],
        traderTypeFilter: traderTypeDropDownItems[0].value,
      }

      storage.setItem(key, JSON.stringify(defaultSettings));
    }
    const item = storage.getItem(key) || "{}";
    return JSON.parse(item);
  })();


  const setDashboardSettings = (settings: IDashboardFilterSettings) => {
    localStorage.setItem(key, JSON.stringify(settings));
  }


  return [dashboardSettings, setDashboardSettings];

}