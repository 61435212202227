import React, { CSSProperties } from 'react'
import { IPublicProfile } from 'interfaces/IPublicProfile'
import { AvatarLink } from 'components/user/dashboard/UserInfo';
import { AvatarSizeEnum } from '../Avatar';
import { ProfileAccuracy } from './ProfileAccuracy';

export const AvatarSectionReversed: React.FunctionComponent<{ profile: IPublicProfile }> = ({ profile }) => {
  const borderStyle: CSSProperties = {
    border: "solid 2px #FF6060",
    borderRadius: "50%",
  }

  return (
    <div className='d-flex flex-column align-items-center'>
      <ProfileAccuracy profile={profile} />

      <div title={profile.username} className="team-avatars-thumbs-img" style={borderStyle}>
        <AvatarLink profile={profile} size={AvatarSizeEnum.MEDIUM} />
      </div>
    </div>

  );
}


export const AvatarSection: React.FunctionComponent<{ profile: IPublicProfile }> = ({ profile }) => {
  const borderStyle: CSSProperties = {
    border: `solid 2px #5EFF44`,
    borderRadius: "50%",
  }
  return (
    <div className='d-flex flex-column align-items-center'>
      <div title={profile.username} className="team-avatars-thumbs-img" style={borderStyle}>
        <AvatarLink profile={profile} size={AvatarSizeEnum.MEDIUM} />
      </div>

      <ProfileAccuracy profile={profile} />
    </div>
  );
}
