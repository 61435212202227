import React from "react";
import { BaseForm, FieldWithError } from "components/common";
import { validationMap, Validator } from "schemas";
import { adminLeagueApiService } from "services/AdminApiService";
import { IDataField } from "interfaces";
import { ILeague } from "interfaces/league";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: ILeague) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

export const AdminLeagueForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {
  return (
    <BaseForm
      apiService={adminLeagueApiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_LEAGUE_FORM)}
      editableFields={editableFields}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <>

            <FieldWithError 
              errors={errors} 
              touched={touched} 
              fieldName="name" 
              placeholder="League Name" 
              label="League Name" />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="description"
              placeholder="Description"
              label="Description"
            />

          </>
        );
      }}
    </BaseForm>
  );
};


