import React from 'react';
import { Modal } from "react-bootstrap";
import { IMessage } from './IMessage';

const ModalMessage: React.FunctionComponent<{ show: any; handleClose: any; message: IMessage; }> = ({
  show,
  handleClose,
  message,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body className="text-white">
        {message.title ? (
          <div className="my-3">
            <div className="d-flex flex-row justify-content-between mx-3">
              <div className="fw-bold">{message.title}</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="d-flex justify-content-center mx-3 mt-3">
              <div className="modal-message w-100">{message.body}</div>
            </div>
          </div>
        ) : (
          <div className="my-3">
            <div className="d-flex flex-row justify-content-between mx-3">
              <div className="fw-bold">{message.body}</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
            </div>
          </div>
        )
        }
      </Modal.Body>
    </Modal>
  );
};

export { ModalMessage }