import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const ScrollToTop: React.FunctionComponent<any> = ({children}) => {
  const location = useLocation();
  useEffect( ()=> {
    window.scrollTo(0, 0);
  }, [location])

  return <>{children}</>
}

export { ScrollToTop }