import { IUserAchievementUserStatSearchFilter, userAchievementService, IUserAchievementUserStatResponse } from "services/UserAchievementService";
import { useApi } from "./useApi";

export function useApiAchievementUserStats (filter: IUserAchievementUserStatSearchFilter) {
  const load = async (filter: IUserAchievementUserStatSearchFilter, abortSignal?: AbortSignal) => {
    return await userAchievementService.getUserStats(filter, abortSignal);
  }
  return useApi<IUserAchievementUserStatResponse[], IUserAchievementUserStatSearchFilter>(load, filter);
}

