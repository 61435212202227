import React from 'react'
import { DropdownFilter, IDropdownFilterItem } from 'components/common/DropDownFilter/DropdownFilter';

export enum TradeSortEnum {
  SYMBOL_A_Z = 1,
  SYMBOL_Z_A = 2,
  PROFIT_ASC = 3,
  PROFIT_DESC = 4,
  MOST_RECENT = 5,
}

export type TradeSortEnumDropdownItemType = IDropdownFilterItem<TradeSortEnum, TradeSortEnum>;
export const tradeSortDropdownItemsMap: Map<TradeSortEnum, TradeSortEnumDropdownItemType> = (() => {
  const map = new Map<TradeSortEnum, TradeSortEnumDropdownItemType>();

  const list: [TradeSortEnum, string][] = [
    [TradeSortEnum.SYMBOL_A_Z, 'Symbol A - Z'],
    [TradeSortEnum.SYMBOL_Z_A, 'Symbol Z - A'],
    [TradeSortEnum.PROFIT_ASC, 'Profit Ascending'],
    [TradeSortEnum.PROFIT_DESC, 'Profit Descending'],
    [TradeSortEnum.MOST_RECENT, 'Most Recent'],
  ]

  list.forEach(([t, s]) => {
    map.set(t, {key: t, value: t, label: s})
  })

  return map;
})();

interface IProps {
  initialValue: TradeSortEnum
  setValue: (value: TradeSortEnum) => void,
  className?: string,
}

export const TradeSortDropdown:React.FunctionComponent<IProps> = ({initialValue, setValue, className}) => {

  const items = Array.from(tradeSortDropdownItemsMap).map((item) => item[1])
  
  return (
    <DropdownFilter<TradeSortEnum, TradeSortEnum> 
        items={items}
        initialSelectedKey={TradeSortEnum.MOST_RECENT}
        onSelect={(item)=>setValue(item)}
        className={className}/>
  )

}
