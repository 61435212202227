import { ContestState } from "_constants/ContestState";
import { IContest } from "interfaces/IContest";
import { StockHelper, NewYorkTz as tz } from "predictagram-lib";
import { Helper } from "./Helper";

export class ContestHelper {
  public static getContestState (contest: IContest, date: Date):ContestState  {
    const epoch = date.getTime() / 1000;
    if (epoch >= contest.startTime && epoch <= contest.endTime) return ContestState.OPEN;
    if (epoch < contest.startTime ) return ContestState.PRE_OPEN;
    return ContestState.CLOSED;
  }  

  public static getTimeUntilOpen (contest: IContest, date: Date): string  {
    return Helper.formatDuration(date, new Date(contest.startTime * 1000) );
  }  

  public static getTimeUntilClose (contest: IContest, date: Date): string  {
    return Helper.formatDuration(date, new Date(contest.endTime * 1000));
  }  

  public static getTimeUntilResults (contest: IContest, date: Date): string  {
    return Helper.formatDuration(date, new Date(contest.resultTime * 1000));
  }  

  public static getResultDateTime(contest: IContest, date: Date): string {
    const nyResultDate = new Date(contest.resultTime * 1000);
    const isSameDay = tz.isSameDay(nyResultDate, new Date(date));

    if (isSameDay) {
      return `later at ${tz.format(nyResultDate).hour24MinuteAmpm()} ET`
    }

    return `on ${tz.format(nyResultDate).monthNameDateTime()} ET`

  }

  public static getPreviousDayName = (date: Date) => {
    const previousTradingDay = StockHelper.findPreviousTradingDay(date).getTime();
    const todayNyDate = tz.getDateMidnight(date);
    const previousCalendarDate = todayNyDate.setDate(todayNyDate.getDate() - 1);
    return StockHelper.isTradingDay(new Date(previousCalendarDate)) ? 'Yesterday' : tz.getWeekday(new Date(previousTradingDay));
  }

  public static getDateRange = (contest: IContest) => {
    const contestStart = tz.format(new Date(contest?.startTime * 1000));
    const contestEnd = tz.format(new Date(contest?.endTime * 1000));
    return `${contestStart.monthDayWithSuffix()} ${contestStart.hour24MinuteAmpm(false)} - ${contestEnd.monthDayWithSuffix()} ${contestEnd.hour24MinuteAmpm(false)}`
  }

  public static getActiveContests = (contests: IContest[], currentDate: Date = new Date()) => {
    const currentTime = currentDate.getTime();
    return contests.filter(d => currentTime >= (d.startTime * 1000) && currentTime <= (d.endTime * 1000))
  }
}