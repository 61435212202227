import React, { useContext } from 'react'
import { ITradegramSecurity } from "services/TradegramApiService";
import { TradeLineItem } from "../tradegram/TradeLineItem";
import { UserInfoProfit } from './UserInfoProfit';
import { SecurityCard } from '../tradegram/SecurityCard';
import { MoreTradesButton } from './TradeWide';
import { AccountContext } from 'components/common';
import { Padlock } from 'components/common/Icons'
import { RequestAccessButton } from '../tradegram/RequestAccessButton';

interface IProps {
  security: ITradegramSecurity,
  onClick?: () => void,
  isWide?: boolean,
  showUserProfile?: boolean,
  children?: any,
  showLastTrade?: boolean,
  detailed?: boolean,
}

// const RequestAccessButton = () => {
//   return (
//     <div className="bg-gradient-platinum px-4 py-2 text-black text-12 fw-bold pill d-inline text-center">
//       Request Access
//     </div>
//   );
// }

const LockedTrade = () => {
  return (
    <div className="d-flex gap-2 align-items-center justify-content-center">
      <Padlock />
      <span className="fw-bold text-14">Locked Trade</span>
    </div>
  );
}

const LockedSecurityCard = () => {
  return (
    <div className="tradegram-card locked mt-2">
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column gap-2 p-3">
          <div><LockedTrade /></div>
          <div><RequestAccessButton color="platinum" /></div>
        </div>
      </div>
    </div>
  );
}


export const Trade: React.FunctionComponent<IProps> = ({ security, onClick, isWide = false, showUserProfile = true, showLastTrade = true, detailed=false, children }) => {

  const acctContext = useContext(AccountContext);

  const t = security.lastTransaction;
  if (!t) return <></>
  const cardColor = security.transactions === 1 ? "" : security.profit < 0 ? "red" : "green";

  if (!!!acctContext?.account.allowTradegram) {
    return <div>
      {!!showUserProfile && <div className="m-1 mt-3 mb-2 happening">
        <UserInfoProfit security={security} trade={t} />
      </div>}
      <LockedSecurityCard />
    </div>
  }

  return (
    <>
      {!!showUserProfile && <div className="m-1 mt-3 mb-2 happening">
        <UserInfoProfit security={security} trade={t} />
      </div>}

      <SecurityCard security={security} className={`happening ${cardColor}`} onClick={onClick} detailed={detailed}>
        <div className={`d-flex h-100 ${showLastTrade ? "p-3 pb-1" : ""} `}>

          <div className="d-flex flex-column justify-content-start w-100">
            {showLastTrade && <TradeLineItem security={security} tradegramTransaction={t} index={0} showProfit={isWide} />}

            {onClick && (security.transactions > 1) &&
              <div className="align-self-end mt-auto w-100 mb-2"><MoreTradesButton security={security} onClick={onClick} /></div>
            }
          </div>
        </div>
        {children}
        <div className="p-2"></div>
      </SecurityCard>
    </>
  );
}