import { APIState } from "_constants/APIState";
import { useCallback, useEffect, useRef, useState } from "react";
import { AdminApiReportService, IRegistrationApiItem, IRegistrationApiSearchOptions } from "services/AdminApiReportService";


export function useAdminRegistrations (service: AdminApiReportService, searchOptions: IRegistrationApiSearchOptions) {

  const [ items, setItems ] = useState<IRegistrationApiItem[]>([]);
  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<Error | undefined>(undefined);
  const [ options, setOptions ] = useState<IRegistrationApiSearchOptions>(searchOptions);


  const serviceRef = useRef(service);

  const load = useCallback(async ()=>{
    try {
      setApiState(APIState.LOADING);
      const items = await serviceRef.current.getRegistrations(options);
      setItems(items.map((item: IRegistrationApiItem, i: number) => {return {...item, id: i}} ));
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.LOADED);
      setError(error as Error);
    }
  }, [serviceRef, options])

  useEffect(()=>{
   load();
  }, [load])

  return {referrers: items, apiState, error, options, setOptions};

}