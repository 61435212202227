import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Url } from '_constants';
import { ActivityStat } from './ActivityStat';
import { IRecentActivity } from 'interfaces/IRecentActivity';

export const PredictionCompleted:React.FunctionComponent<{activity: IRecentActivity}> = ({activity}) => {

  const navigate = useNavigate();

  const onClick = () => {
    navigate(Url.USER_NOTIFICATIONS_RECENT_PREDICTIONS);
  }

  const icon = <><img src="/images/bullseye.png" alt="target" style={{width: "2.1875rem", height: "2.1875rem"}}/></>
  return <ActivityStat onClick={onClick} icon={icon} activity={activity}>
    {activity.count} of your predictions {activity.count > 1 ? 'have' : 'has'} been scored!
  </ActivityStat>

}
