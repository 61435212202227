import React from 'react'
import { PriceLookup } from './PriceLookup'

export const SymbolsWatchList: React.FunctionComponent<{ symbols: number[], show: boolean, setShow: (v: boolean) => void }> = ({ symbols, show, setShow }) => {

  return (
    <div className="admin-trades-watchlist">
      <div className="d-flex justify-content-end" ><button onClick={() => setShow(!show)}>{show ? "Collapse" : "Show Prices"}</button></div>
      {show &&
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Symbol</th>
              <th align="right">Bid</th>
              <th align="right">Ask</th>
              <th align="right">Last</th>
              <th align="right">Best Price</th>
            </tr>
          </thead>
          <tbody>
            {symbols.map((id) => <PriceLookup symbolId={id} />)}
          </tbody>
        </table>
      }
    </div>
  )
}
