
import { GrayTicketSvg } from './TicketSvgGray'
import { FailSvg } from './FailSvg'

export const TicketFail = () => {
  return (
    <div style={{ position: "relative", display: "inline" }}>
      <GrayTicketSvg />

      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <FailSvg />
      </div>
    </div>

  )
}
