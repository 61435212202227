import React, { useState } from 'react'
import { ShortRegisterForm } from 'components/user/account/ShortRegisterForm';
import { Modal } from "react-bootstrap";

interface IProps {
  show: boolean,
  handleClose: () => void,
  registerTitle?: JSX.Element | string,
  loginTitle?: JSX.Element | string,
  head?: JSX.Element | string,
  tagLine?: JSX.Element | string,
}
export const ModalSignupLogin: React.FunctionComponent<IProps> = ({ show, handleClose, registerTitle, loginTitle, head, tagLine }) => {

  const [isRegisterForm, setIsRegisterForm] = useState<boolean>(false);
  const [isLoginForm, setIsLoginForm] = useState<boolean>(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" backdrop={'static'} keyboard={false} centered>
        <Modal.Body>
          {!isRegisterForm && !isLoginForm &&
            <div className="modal-signup d-flex flex-column justify-content-center">
              {head}
              <div className="text-18 mx-4 my-3 text-center">
                {tagLine}
              </div>
              <div className="my-3 mx-5">
                <button className="btn btn-primary w-100 p-2" onClick={() => setIsRegisterForm(true)}>Sign up - It's Free</button>
              </div>
              <div className="mb-3 mx-5">
                <button className="btn btn-secondary w-100 p-2" onClick={() => setIsLoginForm(true)}>Login</button>
              </div>
            </div>
          }

          {isRegisterForm &&
            <div className="mx-1 mx-md-5">
              <ShortRegisterForm
                onLoginCallback={() => window.location.reload()}
                onRegisterCallback={() => window.location.reload()}
                onLoginClick={() => { }}
                buttonLabel="Create Account"
                skipFg={true}
                registerTitle={registerTitle}
                loginTitle={loginTitle}
              />
            </div>
          }

          {isLoginForm &&
            <div className="mx-5">
              <ShortRegisterForm
                onLoginCallback={() => window.location.reload()}
                onRegisterCallback={() => window.location.reload()}
                onLoginClick={() => { }}
                buttonLabel="Create Account"
                skipFg={true}
                initialIsLogin={true}
              />
            </div>
          }

        </Modal.Body>
      </Modal>
    </>

  )
}
