import {AnalysisDailyProfitSharesOutput, AnalysisReportData, AnalysisScore, MathEx} from "predictagram-lib";
import { adminApiServiceCommon } from "services/AdminApiService";
import {ExportToCsv} from "export-to-csv-fix-source-map";

export type FilterOptions = {startTime:number, endTime:number, signalAlertIds:number[]};

export class AlertSummaryModel {
  
  static async generateReportWrap (searchOptions:FilterOptions, setStatus: (v: string) => void, setIsLoading: (v: boolean) => void) {
    try {
      await this.generateReport(searchOptions, setStatus, setIsLoading);
    } catch(e:any) {
      console.error(e);
      setStatus(e.toString());
    }
  }
  
  
  static async generateReport (searchOptions:FilterOptions, setStatus: (v: string) => void, setIsLoading: (v: boolean) => void, isCompact=false) {
    setStatus('');
    setIsLoading(true);
  
    const data:AnalysisDailyProfitSharesOutput[] = await adminApiServiceCommon.analysisStrategyProfitCombined(searchOptions);
  
    type RepAlertSignal = {
      signalAlertId: number,
      overallPct: number,
      posDirProfitPct: number,
      negDirProfitPct: number,
      trades: number,
      tradesProfitable: number,
      overallAmount: number,
    }
  
    type RepItem = {
      date: string;
      algoAccount: number,
      closePrice: number,
      totalTrades: number,
      tradeWinRate: number,
      totalProfit: number,
      profitPerTrade: number,
      algoAccountValue: number,
      benchmarkValue: number,
  
      alerts: Partial<RepAlertSignal>[],
    };
    const rep: RepItem[] = [];
    const toFixed = (v: number, pos: number = 6) => pos == null ? pos : v.toFixed(pos);
    let maxTrades = 0;
    for (const d of data) {
      const repItem: RepItem = {
        date: d.date,
        algoAccount: 0,
        closePrice: d.stockClosePrice,
        profitPerTrade: 0,
        totalProfit: 0,
        totalTrades: 0,
        tradeWinRate: 0,
        algoAccountValue: 0,
        benchmarkValue: 0,
        alerts: [],
      };
      rep.push(repItem);
      let profitableTrades = 0;
      for (const a of d.alertResults) {
        const stats = a.result as AnalysisScore;
        const scores = stats.score;
        const amount = AnalysisReportData.calcOverallNumbers(scores).overallAmount;
        const totalTrades = stats.trades.total;
        const profTrades = stats.trades.profitable;
        const repItemAlert:RepAlertSignal = {
          signalAlertId: a.signalAlertId,
        } as RepAlertSignal;
        if (!isCompact) {
          repItemAlert.overallPct = toFixed(scores.overall * 100, 4) as any;
          repItemAlert.posDirProfitPct = toFixed(scores.positiveDirection * 100, 4) as any;
          repItemAlert.negDirProfitPct = toFixed(scores.negativeDirection * 100, 4) as any;
          repItemAlert.trades = totalTrades;
          repItemAlert.tradesProfitable = profTrades;
        }
        repItemAlert.overallAmount = toFixed(amount,3) as any;

        repItem.totalTrades+=totalTrades;
        profitableTrades+=profTrades;
        repItem.totalProfit+=MathEx.round(amount,2);
        repItem.alerts.push(repItemAlert);
      }
      maxTrades = Math.max(maxTrades, repItem.totalTrades);
      repItem.profitPerTrade = repItem.totalTrades ? MathEx.round(repItem.totalProfit/repItem.totalTrades, 3) : 0;
      repItem.tradeWinRate = repItem.totalTrades ? MathEx.round(profitableTrades / repItem.totalTrades, 4): 0;
    }
  
    for (let i=0;i<rep.length;i++) {
      const curr = rep[i];
      if (i===0) {
        curr.benchmarkValue = MathEx.round(maxTrades*curr.closePrice,2);
        curr.algoAccountValue = MathEx.round(curr.benchmarkValue + curr.totalProfit,2);
        curr.algoAccount = curr.closePrice + curr.profitPerTrade;
      } else {
        const prev = rep[i-1];
        curr.benchmarkValue = MathEx.round(maxTrades*curr.closePrice,2);
        curr.algoAccountValue = MathEx.round(prev.algoAccountValue + curr.totalProfit,2);
        curr.algoAccount = prev.algoAccount + curr.profitPerTrade;
      }
    }
  
  const repFinal:any[] = [];
  
    for (const r of rep) {
      const alerts = r.alerts;
      delete (r as any).alerts;
      const rec={};
      Object.assign(rec, r);
      for (const a of alerts) {
        const signalId = a.signalAlertId;
        if (!isCompact) {
          // add space
          Object.assign(rec, {
            [`signalNumber${signalId}`]: ''
          });
        }
        delete (a as any).signalAlertId;
        for (const k of Object.keys(a)) {
          Object.assign(rec, {
            [`${k}${signalId}`]: (a as any)[k],
          });
        }
      }
      repFinal.push(rec);
  
    }
  
    // const dateStr = (time:number)=>DateHelper.dateTimeFormatUs(time).substring(0, 10);
    const filename = `report`;
    // if (rep.length === 0) {
    //   setStatus('no data found for specified range');
    // } else {
      const csv = new ExportToCsv({ useKeysAsHeaders: true, quoteStrings: '', filename});
      csv.generateCsv(repFinal);
    // }
  
    setIsLoading(false);
  }

}
