export class ProfilerTime {

    protected startedAt:Date|null = null;

    public start() {
        this.startedAt = new Date();
        return this;
    }

    public stop() {
        this.startedAt = null;
        return this;
    }

    /**
     * @return msecs
     */
    public elapsed() {
        if (null===this.startedAt) {
            throw new Error('not started');
        }
        return new Date().getTime()-(this.startedAt as any).getTime();
    }

    /**
     * Return elapsed and reset counter
     * @return msecs
     */
    public elapsedReset() {
        const el = this.elapsed();
        this.start();
        return el;
    }

}
