export enum AdminReportType {
  SIGNAL_ALERT_COMBOS                           = 1,
  SIGNAL_ALERT_COMBOS_QUALIFIED_ALERTS          = 2,
}

export class AdminReportTypeHelper {
    protected static names = new Map<AdminReportType, string>([
        [AdminReportType.SIGNAL_ALERT_COMBOS, 'Signal Alert Combos'],
        [AdminReportType.SIGNAL_ALERT_COMBOS_QUALIFIED_ALERTS, 'Signal Alert Combo\'s Qualified Alerts']
    ]);

    public static getNames() {
        return this.names;
    }
}
