import {IQuoteFull, LinePoints, PointUpDown, StockCalculatedLines} from "../interface";
import {MathEx} from "../utils";
import {AnalysisHelper} from "./analysis.helper";
import {PivotPointData} from "./pivot-point.data";

export class StockLines {

    public static calcStockLines(prevDaysPrices:IQuoteFull[]) {
        const vwapGetCal = this.calcVwap();
        const pp = new PivotPointData();
        const lines: StockCalculatedLines = {
            macd: this.emptyLine(),
            ema6: this.emptyLine(),
            ema12: this.emptyLine(),
            ema26: this.emptyLine(),
            ema120: this.emptyLine(),
            sma120: this.emptyLine(),

            ema26day: this.emptyLine(),
            ema12day: this.emptyLine(),
            ema6day: this.emptyLine(),

            vwap: vwapGetCal.getData().line,
            supRes1hStock: [],
            supRes3dStock: [],

            pivotPointCenter: pp.data().center,
            pivotPointAtr100pctMax: pp.data().atr100pctMax,
            pivotPointAtr100pctMin: pp.data().atr100pctMin,
        };

        let minPrice:number = null as any;
        let maxPrice:number = null as any;
        let minTime:number = null as any;
        let maxTime:number = null as any;

        // start from first only
        // if (i===0) {
        //     return;
        // }
        // MACD=12-Period EMA − 26-Period EMA
        // EMA [today] = (Price [today] x K) + (EMA [yesterday] x (1 – K))
        //
        // Where:
        //
        //   K = 2 ÷(N + 1)
        // N = the length of the EMA
        // Price [today] = the current closing price
        // EMA [yesterday] = the previous EMA value
        // EMA [today] = the current EMA value
        const k120 = 2/(120-1);
        const k26 = 2/(26-1);
        const k12 = 2/(12-1);
        const k6 = 2/(6-1);

        const emaCalc = (k: number, today:number, prev:number)=>{
            if (!prev) {
                return today;
            }
            return MathEx.round(today * k + prev * (1 - k), 6);
        }

        let ema6dPreCalc = null as any as number;
        let ema12dPreCalc = null as any as number;
        let ema26dPreCalc = null as any as number;

        let count = 0;
        const len = prevDaysPrices.length;
        // precalculate ema for previous days, basically have yesterday's close EMA
        // it's use for today's calc for each minute
        for (let i=Math.max(len-1-25, 0); i<len;i++) {
            count++;
            const price = prevDaysPrices[i].c;
            if (i>=len-5 && len>=5) {
                // console.debug(i);
                ema6dPreCalc = emaCalc(k6, price, ema6dPreCalc);
            }
            if (i>=len-11 && len>=11) {
                ema12dPreCalc = emaCalc(k12, price, ema12dPreCalc);
            }
            if (i>=len-25 && len>=25) {
                ema26dPreCalc = emaCalc(k26, price, ema26dPreCalc);
            }
        }


        // use first closePrice to calc initial first point
        // const first = stockLine.y[0];
        // let prev:StockCalculatedLines = {
        //     time: stockLine.x[0],
        //     MACD: 0,
        //     EMA6: first,
        //     EMA12: first,
        //     EMA26: first,
        //     EMA120: first,
        //     SMA120: first,
        // };
        // lines.push(prev);
        //
        // for (let i=1; i<stockLine.x.length; i++) {
        let startIndex = null as any as number;
        let endIndex = null as any as number;
        const smaSize = 120;
        let sma120Sum = 0;
        let sma120Count = 0;
        const calc = (i: number, stockStats: readonly IQuoteFull[])=> {
            if (startIndex===null) {
                startIndex = i;
                endIndex = i;
            }
            const currQ = stockStats[i];
            const isFirst = i === 0;
            const time = currQ.t;
            const priceToday = currQ.c;

            minTime = Math.min(minTime||time, time);
            maxTime = Math.max(maxTime||time, time);
            minPrice = Math.min(minPrice||currQ.l, currQ.l);
            maxPrice = Math.max(maxPrice||currQ.h, currQ.h);

            // const ema6 = isFirst ? priceToday : priceToday * k6 + lines.ema6.y[i - 1] * (1 - k6);
            const ema6 = emaCalc(k6, priceToday, lines.ema6.y?.[i - 1]);
            // const ema12 = isFirst ? priceToday : priceToday * k12 + lines.ema12.y[i - 1] * (1 - k12);
            const ema12 = emaCalc(k12, priceToday, lines.ema12.y?.[i - 1]);
            // const ema26 = isFirst ? priceToday : priceToday * k26 + lines.ema26.y[i - 1] * (1 - k26);
            const ema26 = emaCalc(k26, priceToday, lines.ema26.y?.[i - 1])
            // const ema120 = isFirst ? priceToday : priceToday * k120 + lines.ema120.y[i - 1] * (1 - k120);
            const ema120 = emaCalc(k120, priceToday, lines.ema120.y?.[i - 1])
            const macd = isFirst ? 0 : MathEx.round(ema12 - ema26, 6);
            //
            // let sma120 = 0;
            // let sma120count = 0;
            sma120Sum+=priceToday;
            sma120Count+=1;
            endIndex = i;
            if (sma120Count>smaSize) {
                sma120Sum-=stockStats[startIndex].c;
                sma120Count-=1;
                startIndex++;
            }

            // const until = time - 7200;
            // for (let y = i; y >= 0; y--) {
            //     const p = stockStats[y];
            //     const yClose = p.c;
            //     if (sma120count>=smaSize) {
            //         break;
            //     }
            //     sma120 += yClose;
            //     sma120count++;
            // }

            const addPoint = (l: LinePoints, y: number) => {
                l.y.push(y);
                l.x.push(time);
            }
            vwapGetCal.calc(currQ);
            pp.calc(i, stockStats);
            addPoint(lines.macd, macd);
            addPoint(lines.ema6, ema6);
            addPoint(lines.ema12, ema12);
            addPoint(lines.ema26, ema26);
            addPoint(lines.ema120, ema120);
            addPoint(lines.sma120, MathEx.round(sma120Sum / sma120Count, 4));

            if (ema6dPreCalc) {
                addPoint(lines.ema6day, emaCalc(k6, priceToday, ema6dPreCalc));
            }
            if (ema12dPreCalc) {
                addPoint(lines.ema12day, emaCalc(k12, priceToday, ema12dPreCalc));
            }
            if (ema26dPreCalc) {
                addPoint(lines.ema26day, emaCalc(k26, priceToday, ema26dPreCalc));
            }

            // const macdToday:StockCalculatedLines = {
            //     time: time,
            //     MACD: ema12 - ema26,
            //     EMA6: ema6,
            //     EMA12: ema12,
            //     EMA26: ema26,
            //     EMA120: ema120,
            //     // SMA120: MathEx.round(sma120/sma120count, 4),
            // };
            // lines.push(macdToday);
            // prev = macdToday;
            // }
            // return lines;
        }
        return {
            data:()=>{return{
                lines: lines,
                vwapData: vwapGetCal.getData(),
                minPrice: minPrice,
                maxPrice: maxPrice,
                minTime: minTime,
                maxTime: maxTime,
            }},
            calc: calc,
        }
    }

    public static calcVwap() {
        let volumeSum = 0;
        let priceVolSum = 0;
        // const line: Point[] = [];
        const d = {
            signals: [] as PointUpDown[],
            line: {x:[], y:[]} as LinePoints,
            min: null as any as number,
            max: null as any as number,
        };
        let prevDist:number = null as any;
        // let prevTime:number = null as any;
        const calc = (q:IQuoteFull)=>{

            const close = q.c;
            const volume = q.v;
            const time = q.t;
            const price = (q.c+q.h+q.l)/3;
            // // @TODO: it can be removed, because cumulative supports only 1d
            // // reset calc, it can be multi-day calc
            // if (prevTime!==null && q.t-prevTime>3600) {
            //     volumeSum = 0;
            //     priceVolSum = 0;
            //     prevDist = null as any;
            //     prevTime = null as any;
            // }


            volumeSum+=volume;
            priceVolSum += price*volume;
            const vwapPrice = MathEx.round(priceVolSum/volumeSum, 4);

            d.min = d.min !== null ? Math.min(d.min, vwapPrice) : vwapPrice;
            d.max = d.max !== null ? Math.max(d.max, vwapPrice) : vwapPrice;
            d.line.x.push(time);
            d.line.y.push(vwapPrice);
            const newDist = vwapPrice-close;
            if (prevDist!==null) {
                if (prevDist<0 && newDist>=0) {
                    // signal to buy?
                    d.signals.push({x: time,y: vwapPrice,isSellSignal: true,});
                } if (prevDist>=0 && newDist<0) {
                    // signal to sell?
                    d.signals.push({x: time,y: vwapPrice,isSellSignal: false});
                }
            }
            prevDist = vwapPrice-close;
            // prevTime = time;
        }
        return {
            getData: () => d,
            calc: calc,
        }
    }

    public static emptyLine() {
        return AnalysisHelper.emptyLine();
    }
}
