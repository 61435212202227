import React from "react";

import { IKeyValue } from "interfaces";
import { teamStatusNames } from "interfaces/league";
import { DropDown } from "components/common/form/DropDown";

export const StatusDropDown: React.FunctionComponent<{ errors: any; touched: any; name: string }> = ({ errors, touched, name }) => {


  return (
      <DropDown enumMap={teamStatusNames}
        defaultItem={{ key: 0, value: 'Select Status' } as IKeyValue<number>}
        errors={errors}
        touched={touched}
        label="Status" name={name} />
  );
};

