import React from "react";

import { IKeyValue } from "interfaces";
import { ILeague, ITeam } from "interfaces/league";
import { DropDown } from "components/common/form/DropDown";
import { adminLeagueTeamApiService } from "services/AdminApiService";
import { useApiService } from "_hooks/useApiService";

export const TeamDropDown: React.FunctionComponent<{ errors: any; touched: any; name: string }> = ({ errors, touched, name }) => {

  const league = useApiService<ITeam>(adminLeagueTeamApiService, undefined, true, false);
  const getMap = (items: ILeague[]) => new Map<number, string>(
    items.map((i: ILeague) => [i.id as number, i.name as string])
  );

  return (
    <>

      {league.loaded && league.items && <DropDown enumMap={getMap(league.items)}
        defaultItem={{ key: 0, value: 'Select Team' } as IKeyValue<number>}
        errors={errors}
        touched={touched}
        label="Team" name={name} />}

      {!league.loaded && <div>Loading Teams...</div>}
    </>
  );
};

