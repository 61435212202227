
type LevelsInfo = {
    current: {level: number, minPoints: number, rank: number},
    next: {level: number, minPoints: number, rank: number} | null,
};

export class StreakLevels {
    protected static levels = [
        {level:1, minPoints:0, rank: 1},
        {level:2, minPoints:100, rank: 1},
        {level:3, minPoints:300, rank: 1},
        {level:4, minPoints:700, rank: 1},
        {level:5, minPoints:1_500, rank: 1},

        {level:6, minPoints:3_100, rank: 2},
        {level:7, minPoints:5_500, rank: 2},
        {level:8, minPoints:9_100, rank: 2},
        {level:9, minPoints:14_500, rank: 2},
        {level:10, minPoints:22_600, rank: 2},

        {level:11, minPoints:33_900, rank: 3},
        {level:12, minPoints:49_800, rank: 3},
        {level:13, minPoints:72_000, rank: 3},
        {level:14, minPoints:103_100, rank: 3},
        {level:15, minPoints:146_700, rank: 3},

        {level:16, minPoints:196_840, rank: 4},
        {level:17, minPoints:254_501, rank: 4},
        {level:18, minPoints:320_811, rank: 4},
        {level:19, minPoints:397_068, rank: 4},
        {level:20, minPoints:484_763, rank: 4},

        {level:21, minPoints: 585_612, rank: 5},
    ];

    public static levelsInfo(currXp:number): LevelsInfo {
        let currentPos = 0;
        for (let i=0; i<this.levels.length;i++) {
            if (currXp<this.levels[i].minPoints) {
                break;
            }
            currentPos=i;
        }
        return {
            current: this.levels[currentPos],
            next: currentPos+1<this.levels.length ? this.levels[currentPos+1]: null,
        }
    }
}
