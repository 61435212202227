import { ModalForgotPassword } from 'components/user/account/ModalForgotPassword';
import { ModalLogin } from 'components/user/account/ModalLogin';
import { ModalRegister } from 'components/user/account/ModalRegister';
import { useCallback, useRef, useState } from 'react';


export const useModalLoginRegisterPassword = (onRegisterCallback?: ()=>void, onLoginCallback?: ()=>void)=> {

  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showRegister, setShowRegister] = useState<boolean>(false);
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);

  const _registerRef = useRef(onRegisterCallback);
  const _loginRef = useRef(onLoginCallback);
  
  const _onRegister = useCallback(()=>{
    setShowRegister(false);
    if (_registerRef.current) {
      _registerRef.current();
    }
  }, [_registerRef])

  const _onLogin = useCallback(()=>{
    setShowLogin(false);
    if (_loginRef.current) {
      _loginRef.current();
    }    
  }, [_loginRef])

  const render = () => {
    return <>
      <ModalLogin 
          handleClose={() => setShowLogin(false)} 
          show={showLogin} 
          onLogin={() => _onLogin() } 
          registerCallback={() => { setShowLogin(false); setShowRegister(true) }} 
          onClickForgotPassword={() => {setShowForgotPassword(true); setShowLogin(false)}}
      />
      <ModalRegister handleClose={() => setShowRegister(false)} show={showRegister} onRegister={() => { _onRegister() }} onClickLogin={() => { setShowRegister(false); setShowLogin(true) }} />
      <ModalForgotPassword handleClose={() => setShowForgotPassword(false)} show={showForgotPassword} onReset={() => { setShowLogin(false); setShowForgotPassword(false) }} />
    </>
  }

  return {
    login: { show: setShowLogin },
    register: { show: setShowRegister },
    forgotPassword: { show: setShowLogin },
    render
  }

}