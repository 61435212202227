import { Route, Routes } from 'react-router-dom';

import { LandingPageV1 } from 'components/marketing/landing/v1';
import { MarketingLayout } from 'components/public/layout/MarketingLayout';
import { LandingPageV2 } from 'components/marketing/landing/v2';
import { LandingPageV3 } from 'components/marketing/landing/v3';
import { LandingPageV4 } from 'components/marketing/landing/v4';
import { LandingPageV5 } from 'components/marketing/landing/v5';
import { NotFoundPage } from 'components/NotFoundPage';

export const LandingRoutes: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<MarketingLayout />}>
        <Route path="spy" element={<LandingPageV1 />} />
        <Route path="v2/spy" element={<LandingPageV2 />} />
        <Route path="v3/spy" element={<LandingPageV3 />} />

        <Route path="v1.1/spy" element={<LandingPageV1 hasDisclaimer={true} />} />
        <Route path="v2.1/spy" element={<LandingPageV2 hasDisclaimer={true} />} />
        <Route path="v3.1/spy" element={<LandingPageV3 hasDisclaimer={true} />} />
        <Route path="v4.1/spy" element={<LandingPageV4 hasDisclaimer={true} />} />
        <Route path="v5.1/spy" element={<LandingPageV5 hasDisclaimer={true} />} />

        <Route path="v1.2/spy" element={<LandingPageV1 hasDisclaimer={true} hasLoginSignup={true} />} />
        <Route path="v2.2/spy" element={<LandingPageV2 hasDisclaimer={true} hasLoginSignup={true} />} />
        <Route path="v3.2/spy" element={<LandingPageV3 hasDisclaimer={true} hasLoginSignup={true} />} />

        <Route path="v8.1/spy" element={<LandingPageV4 hasDisclaimer={true} />} />

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}