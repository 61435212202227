
import React from 'react'
import { DropdownFilter, IDropdownFilterItem } from 'components/common/DropDownFilter/DropdownFilter';
import { BrokerTransactionTypeEnum } from 'predictagram-lib';

// export enum BrokerTransactionTypeEnum {
//   BUY_TO_OPEN = 1,
//   BUY_TO_CLOSE = 2,
//   SELL_TO_OPEN = 3,
//   SELL_TO_CLOSE = 4,
// }


export type BrokerTransactionTypeEnumDropdownItemType = IDropdownFilterItem<BrokerTransactionTypeEnum, BrokerTransactionTypeEnum>;

export const optionTranTypeDropdownItemsMap: Map<BrokerTransactionTypeEnum, BrokerTransactionTypeEnumDropdownItemType> = new Map<BrokerTransactionTypeEnum, BrokerTransactionTypeEnumDropdownItemType>([
  [BrokerTransactionTypeEnum.BUY_TO_OPEN, {key: BrokerTransactionTypeEnum.BUY_TO_OPEN, value: BrokerTransactionTypeEnum.BUY_TO_OPEN, label: 'Buy To Open'}],
  [BrokerTransactionTypeEnum.BUY_TO_CLOSE, {key: BrokerTransactionTypeEnum.BUY_TO_CLOSE, value: BrokerTransactionTypeEnum.BUY_TO_CLOSE, label: 'Buy To Close'}],
  [BrokerTransactionTypeEnum.SELL_TO_OPEN, {key: BrokerTransactionTypeEnum.SELL_TO_OPEN, value: BrokerTransactionTypeEnum.SELL_TO_OPEN, label: 'Sell To Open'}],
  [BrokerTransactionTypeEnum.SELL_TO_CLOSE, {key: BrokerTransactionTypeEnum.SELL_TO_CLOSE, value: BrokerTransactionTypeEnum.SELL_TO_CLOSE, label: 'Sell To Close'}],
])

export const optionTranTypeDropdownItemsModifyMap: Map<BrokerTransactionTypeEnum, BrokerTransactionTypeEnumDropdownItemType> = new Map<BrokerTransactionTypeEnum, BrokerTransactionTypeEnumDropdownItemType>([
  [BrokerTransactionTypeEnum.BUY_TO_OPEN, {key: BrokerTransactionTypeEnum.BUY_TO_OPEN, value: BrokerTransactionTypeEnum.BUY_TO_OPEN, label: 'Buy To Add'}],
  [BrokerTransactionTypeEnum.BUY_TO_CLOSE, {key: BrokerTransactionTypeEnum.BUY_TO_CLOSE, value: BrokerTransactionTypeEnum.BUY_TO_CLOSE, label: 'Buy To Close'}],
  [BrokerTransactionTypeEnum.SELL_TO_OPEN, {key: BrokerTransactionTypeEnum.SELL_TO_OPEN, value: BrokerTransactionTypeEnum.SELL_TO_OPEN, label: 'Sell To Add'}],
  [BrokerTransactionTypeEnum.SELL_TO_CLOSE, {key: BrokerTransactionTypeEnum.SELL_TO_CLOSE, value: BrokerTransactionTypeEnum.SELL_TO_CLOSE, label: 'Sell To Close'}],
])

interface IProps {
  initialValue: BrokerTransactionTypeEnum
  setValue: (value: BrokerTransactionTypeEnum) => void,
  isNewPosition?: boolean,
  className?: string,
}

export const BrokerTransactionTypeDropdown:React.FunctionComponent<IProps> = ({initialValue, setValue, isNewPosition=true, className}) => {

  const items = isNewPosition ? Array.from(optionTranTypeDropdownItemsMap).map((item) => item[1]) : Array.from(optionTranTypeDropdownItemsModifyMap).map((item) => item[1])

  const itemsFiltered = items.filter(i=>
    (initialValue === BrokerTransactionTypeEnum.BUY_TO_OPEN && [BrokerTransactionTypeEnum.SELL_TO_CLOSE, BrokerTransactionTypeEnum.BUY_TO_OPEN].includes(i.value) )
    ||
    (initialValue === BrokerTransactionTypeEnum.BUY_TO_CLOSE && [BrokerTransactionTypeEnum.SELL_TO_OPEN, BrokerTransactionTypeEnum.BUY_TO_CLOSE].includes(i.value) )
    ||
    (initialValue === BrokerTransactionTypeEnum.SELL_TO_OPEN && [BrokerTransactionTypeEnum.SELL_TO_OPEN, BrokerTransactionTypeEnum.BUY_TO_CLOSE].includes(i.value) )
    ||
    (initialValue === BrokerTransactionTypeEnum.SELL_TO_CLOSE && [BrokerTransactionTypeEnum.SELL_TO_CLOSE, BrokerTransactionTypeEnum.BUY_TO_OPEN].includes(i.value) )
  )

  const _setValue = (value: BrokerTransactionTypeEnum) => {
    setValue(value);
  }
  return (
    <DropdownFilter<BrokerTransactionTypeEnum, BrokerTransactionTypeEnum> items={isNewPosition ? items : itemsFiltered } initialSelectedKey={initialValue} onSelect={(item)=>_setValue(item)} className={className}/>
  )
}




