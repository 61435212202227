import React, { useState } from 'react';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { FieldWithError } from './FieldWithError';

interface IProps {
  errors: any,
  touched: any,
  placeholder: string,
  label: string,
  readOnly: boolean,
  fieldName: string,
}

export const ShowHidePassword: React.FunctionComponent<IProps> = ({
  errors, touched, placeholder, label, readOnly, fieldName
}) => {
  const [showContent, setShowContent] = useState<boolean>(false);

  return (
    <div className="d-flex flex-row gap-3">
      <div>
        <FieldWithError
          errors={errors}
          touched={touched}
          fieldName={fieldName}
          placeholder={placeholder}
          type={showContent ? "input" : "password"}
          label={label}
          readOnly={readOnly}
        />
      </div>

      <div role="link" className="my-4" onClick={() => { setShowContent(!showContent); }}>{showContent ? <Eye className="text-light" /> : <EyeSlash className="text-light" />}</div>
    </div>

  );

}



/* notes: buggy state. not in use. */

// import React, { useState } from 'react';
// import { Eye, EyeSlash } from 'react-bootstrap-icons';

// interface IChildProps {
//   showContent: boolean;
// }

// interface IAppProps {
//   children: (props: IChildProps) => React.ReactNode
// }

// export const ShowHidePassword = ({children}: IAppProps) => {
//   const [showContent, setShowContent] = useState<boolean>(false);

//   return (
//     <div className="d-flex flex-row">
//       <div className="flex-grow-1">
//       {children({showContent})}
//       </div>
//       <div role="link" className="btn btn-link flex-grow-0 align-self-center" onClick={() => { setShowContent(!showContent);  }}>{showContent ? <Eye className="text-dark" /> : <EyeSlash className="text-dark" />}</div>
//     </div>

//   );

// }

