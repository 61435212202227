import { SymbolsContext } from 'components/common/SymbolsContext';
import { IStockSymbol } from 'interfaces/IStockSymbol';
import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { SymbolToggleButton } from './SymbolToggleButton';

interface IProps {
  show: any,
  handleClose: any,
  selectedSymbols: string[] | null,
  onSymbolClick: (symbol: string, selected: boolean) => void,
  title?: string
}

export const ModalSymbols: React.FunctionComponent<IProps> = ({show, handleClose, selectedSymbols, onSymbolClick, title = 'Select Symbols'}) => {

  const symbolsContext = useContext(SymbolsContext);

  return(
    <Modal className="modal-symbols" scrollable={true} show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <div className="my-2 fw-bold text-18 p-2">Edit Watchlist</div>
          <div role="button" onClick={()=>handleClose()} className="me-1 text-12">Close</div>
        </div>
        <div className="my-2 p-2">Select from a list of supported stock symbols below. Additional symbols will be added soon.</div>

        <div className="d-flex flex-wrap gap-2 justify-content-center my-2">
        {
          symbolsContext?.symbols.filter(s=>s.symbolName!=='TNA').map((s: IStockSymbol, i: number) => <div key={`symbol-key-${i}`} title={s.symbolDescription}>
              <SymbolToggleButton isNew={s.symbolName==='IWM'} stockSymbol={s} initialSelected={selectedSymbols?.includes(s.symbolName) || false} onClick={onSymbolClick}/>
          </div>)
        }
        </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}


