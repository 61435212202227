import {IUserStatistic} from "../../../../interfaces/IUserStatistic";

export class StatsHelper {
  /**
   * sortId format: gsssspppppp
   * g - group id - higher-better
   * ssss - score adjusted
   * ppppppp - preds count
   *
   * @param item
   */
  static usersLeadBoardSortId(item:IUserStatistic&{sortId:number}, daysRange:number) {

    if (item?.sortId) {
      return item.sortId;
    }

    // @NOTE: The sorting is been changing very often, all time something new
    const score = (item: IUserStatistic) => Math.round((item.score || 0) * 100);
    const s = score(item);
    const scorePad = 10_000_000;
    const scorePadLen = scorePad.toString().length - 1;
    const predsPad = 100_0000;
    const preds = item.predictionsCompleted;
    const scoreFactor2 = () => parseInt((s * (preds + 80)).toString().substring(0, scorePadLen), 10);
    const scoreFactor3 = () => parseInt((s * (preds)).toString().substring(0, scorePadLen), 10);
    let sortId: number;
    if (daysRange === 1) {
      // Top Tier: use minimum of 30 predictions for day, and use simple descending % for sorting.
      // Second Tier: Number of predictions per day is between 10 (inclusive) and 30 (exclusive). Use factor 2.
      // Third tier: 3 (exclusive) to 10 (exclusive), same as 3rd tier for other time ranges.
      //  Tier 4: 2 and 3
      if (preds>=30) {
        sortId = 4* scorePad + s;
      } else if (preds>=10) {
        sortId = 3* scorePad + scoreFactor2();
      } else if (preds>=3) {
        sortId = 2* scorePad + scoreFactor3();
      } else {
        sortId = 1 * scorePad + s;
      }
    } else {
      if (s >= 50 && preds >= 7 * daysRange) {
        sortId = (4 * scorePad + s);
      } else if (s >= 50 && preds >= 3 * daysRange) {
        const scoreAdj = scoreFactor2();
        sortId = (3 * scorePad + scoreAdj);
      } else if (s >= 42 && preds >= 2 * daysRange) {
        const scoreAdj = scoreFactor3();
        sortId = (2 * scorePad + scoreAdj);
      } else {
        sortId = 1 * scorePad + s;
      }
    }
    const finalId = sortId * predsPad + preds;
    item.sortId = finalId;
    // console.debug(`${item.sortId} ${item.username} ${s} ${daysRange}`);
    return item.sortId;
  }
}
