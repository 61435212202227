import React from 'react'
import { usePublicProfile } from '_hooks/usePublicProfile'
import { userPredictionApiService } from 'services/UserPredictionApiService'
import { Spinner } from './Spinner'
import { Avatar, AvatarSizeEnum } from './Avatar'
import { Link } from 'react-router-dom'
import { UrlHelper } from '_utils/UrlHelper'

export const AvatarById: React.FunctionComponent<{ userId: number, size: AvatarSizeEnum, canClick?: boolean }> = ({ userId, size, canClick=false}) => {
  const user = usePublicProfile(userId, userPredictionApiService);
    
  if (user.error) {
    return <div className="alert alert-critical">Error</div>
  }

  if (!user.loaded) {
    return <Spinner />
  }

  if (user.loaded) {
    if (canClick) {
      return (
        <Link to={UrlHelper.getProfileUrl(userId)}>
        <Avatar profile={user.item} size={size} />
        </Link>
      )
    }
    return (
      <Avatar profile={user.item} size={size} />
    )
  }

  return <></>

}

