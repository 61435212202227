import React, { useState } from 'react'
import { SecuritiesDatasetEnum, useTradegramSecurities } from '_hooks/useTradegramSecurities';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import { ITradegramSecurity, tradegramApiService } from 'services/TradegramApiService';
import { TradeWide } from '../dashboard/TradeWide';
import { ModalTrades } from '../tradegram/ModalTrades';
import { NotificationHeader } from './NotificationHeader';
import { Helmet } from 'react-helmet';

export const RecentFollowedTradesPage: React.FunctionComponent = () => {
  return (
    <NotificationHeader title="Recent Trades">
      <RecentFollowedTrades />
    </NotificationHeader>
  );
}

const RecentFollowedTrades: React.FunctionComponent = () => {
  const apiSecurities = useTradegramSecurities(tradegramApiService, SecuritiesDatasetEnum.FOLLOWING);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalSecurity, setModalSecurity] = useState<ITradegramSecurity>({} as ITradegramSecurity);

  if (apiSecurities.apiState.isLoading) {
    return <Spinner />
  }

  if (apiSecurities.apiState.isError) {
    return <ErrorMessage>{apiSecurities.error?.message}</ErrorMessage>
  }

  const onClickTrades = (s: ITradegramSecurity) => {
    setShowModal(true);
    setModalSecurity(s);
  }

  if (apiSecurities.apiState.isLoaded) {
    return (
      <>
        <Helmet>
          <title>Predictagram: Notifications - Recent Followed Trades</title>
        </Helmet>

        <ModalTrades onSuccess={()=>{}} show={showModal} security={modalSecurity} handleClose={() => setShowModal(false)} />
        <div className="my-3 my-md-1 d-flex gap-1 align-items-stretch flex-column">

          {apiSecurities.items.length === 0 && apiSecurities.apiState.isLoaded &&
            <div className="d-flex justify-content-center align-items-center my-3">No trades found.</div>
          }

          {apiSecurities.items.length > 0 &&
            apiSecurities.items.map((s: ITradegramSecurity, i: number) => <div key={`prediction-key-${i}`} className="my-1">
              <TradeWide security={s} onClick={() => onClickTrades(s)} />
            </div>)}
        </div>
      </>
    );
  }
  return <ErrorMessage>Unexpected State</ErrorMessage>
}