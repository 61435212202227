import {default as variables} from "../scss/common/chart-variables.module.scss";
import * as Plotly from "plotly.js";
import {format as d3Format} from "d3-format";
import {IQuote} from "../components/public/Stock/IQuote";
import {IChart} from "../interfaces/chart/IChart";
import {
  DateEx,
  DateHelper,
  NewYorkTz,
  PredictionTypeEnum as PredType,
  PredictionTypeEnum,
  PredictionTypeHelper,
  StockStatsIntervalEnum as StockInterval,
  PlotLineData,
  IPrediction,
  OptionType,
  IQuoteFull
} from "predictagram-lib";
import {StockHelper} from "../_utils/stock.helper";
import {ChartOptionsList} from "../components/admin/common/helper";


export interface RgbaColor {
  r: string;
  g: string;
  b: string;
  a: string;
}

export interface PlotLines {
  before: PlotLineData, // before prediction made
  during: PlotLineData, // between prediction made time and end prediction time
  after: PlotLineData, // after prediction end
}


export type PlotCandleType = {
  x: number[], 
  close: number[], 
  high: number[], 
  open: number[], 
  low: number[],
  volume: number[],
}

export type CandleStartEndType = {
    interval: number,
    startTimeSecs: number,
    endTimeSecs: number,
}

export type PlotlyDataCandle = {
  // type: "candlestick",
  // x: number[],
  // close: number[],
  // high: number[],
  // low: number[],
  // open: number[],
  source: PlotCandleType,
} & Plotly.Data

export interface PlotCandles {
  before: PlotCandleType,
  during: PlotCandleType,
  after: PlotCandleType
}

export interface ChartSvgRectangleGradient {
  replaceColor: RgbaColor, // rgb/rgba color
  gradient: {
    rotate: number,
    // startColor: string,
    color: RgbaColor, // rgba string
    // stopColor: string,
  }
}
interface ChartSvgRectangleGradientSearch {
  gradient: ChartSvgRectangleGradient,
  searchRgb: string;
  searchOpacity: string;
  id: string;
}

export interface RangeBreak {
  bounds: number[]; //[start,end]
  dvalue: number;
}
export interface LineBreak {
  x0: any;
  x1: any;
}

export interface TickLabel {
  time: number;
  prevLabelDist: number; // in plot points
  label: string; // empty label means no space will take
  name: string;
}

export interface ChartParams {
  tickVals:any,
  tickText:any,
  yMin:number,
  yMax:number,
  stockSymbol?:string,
  formatStr:string,
  plotData:Plotly.PlotData[] | Plotly.Data[],
  rangeBreaks?: RangeBreak[],
  rangeBreaksLines?: LineBreak[],
}
export class ChartModel {

  static readonly color = variables.color;
  static readonly fontSize = parseInt(variables.fontSize);
  static readonly fontFamily = variables.fontFamily;
  static readonly plotLineColor = variables.plotLineColor;
  static readonly plotLineDarkColor = variables.plotLineDarkColor;
  static readonly plotLineLessDarkColor = variables.plotLineLessDarkColor;
  static readonly lineColor = variables.plotLineColor;
  static readonly rectangleGreenColor = variables.rectangleGreenColor;
  static readonly rectangleRedColor = variables.rectangleRedColor;
  static readonly rectLineGreenColor = variables.rectangleLineGreenColor;
  static readonly rectLineRedColor = variables.rectangleLineRedColor;
  static readonly candlestickGreenFaded = variables.candlestickGreenFaded;
  static readonly candlestickRedFaded = variables.candlestickRedFaded;
  static readonly candlestickGreenMuted = variables.candlestickGreenMuted;
  static readonly candlestickRedMuted = variables.candlestickRedMuted;
  static readonly candlestickBlue = variables.candlestickBlue;
  static readonly candlestickOrange = variables.candlestickOrange;
  static readonly chartHeight = 340;
  static readonly gridLinesColor = 'rgba(28, 28, 28, 1)';
  static readonly gapColor = 'rgb(39,38,38,1)'; // 'rgba(255, 255, 255, 0.3)'
  static readonly yAxisWidth = 80;
  static readonly xAxisLabelWidth = 40;

  static readonly interval_5MinsInSeconds = 60 * 5;

  static readonly narrowWidth = 410;

  // static readonly plotRedColor =  'rgba(225, 97, 97, 1)';
  // static readonly plotGreenColor = 'rgba(173, 240, 162, 1)';

  static readonly config: Partial<Plotly.Config> = {
    responsive: true,
    showLink: false,
    displayModeBar: false,
    scrollZoom: false,
    doubleClick: false,
    showAxisDragHandles: false,
    showTips: false,
  };

  static readonly plotData = (name:string, line: PlotLineData, color:string, dash:Plotly.Dash = "solid")=>{
    return {
      x:line.x,
      y:line.y, 
      mode:'lines', 
      name:name, 
      line: {dash:dash, width:1, color:color,}, 
      hoverinfo:'none'
    } as Plotly.PlotData;
  };

  static readonly plotCandleData = (name: string, candle: PlotCandleType, colors: [string, string] = [this.candlestickGreenMuted, this.candlestickRedMuted]) => {
    return {
      x: candle.x,
      type: "candlestick",
      close: candle.close,
      high: candle.high,
      low: candle.low,
      open: candle.open,
      name,
      increasing: { line: {color: colors[0]}},
      decreasing: { line: {color: colors[1]}},
      hoverinfo: 'none',
      source: candle,
    } as PlotlyDataCandle;
  }

  static formatPriceStr = (maxVal:number)=>'.' + (maxVal >= 100_000 ? 0: (maxVal >= 10_000 ? 1: 2)) + 'f';
  static priceFormatFunc = (maxVal:number)=>d3Format(this.formatPriceStr(maxVal));
  static timeFormatHour = (tSec:number)=>NewYorkTz.format(new Date(tSec * 1000)).hour12ampm(); // DateHelper.newYorkTimezone(tSec).formatHour12ampm();
  static timeFormatHourMin = (tSec:number)=>NewYorkTz.format(new Date(tSec * 1000)).hour24MinuteAmpm(); // DateHelper.newYorkTimezone(tSec).formatHour12ampm();
  static timeFormatMDY = (tSec:number)=>{
    const dt = new Date(tSec * 1000);
    const nydate = NewYorkTz.mdyhms(dt);
    if (NewYorkTz.format(dt).hour24MinuteNumber() === 1000) { // 10:00 AM is the first entry for day 
      return `${nydate.monthName} ${nydate.day}`;
    } 
    return NewYorkTz.format(dt).hour12ampm(); 
  }
  static timeFormatMDNoTime = (tSec:number) : string => {
    const dt = new Date(tSec * 1000);
    const nydate = NewYorkTz.mdyhms(dt);
    const t = `${nydate.monthName}-${nydate.day}`;
    return t;
  }

  /**
   *
   * @param lastTimeSec - exclusive
   * @param endDateSec - inclusive
   * @param addFunc
   */
  static fillFullDay =  (lastTimeSec: number, endDateSec:number, addFunc:(i:IQuoteFull)=>void, stepSecs:number)=>{
    // add stats to fill entire day
    if (lastTimeSec < endDateSec) {
      for (let i = (lastTimeSec + stepSecs); i <= endDateSec; i += stepSecs) {
        const item: IQuoteFull = { t: i, c: null as any, h: null as any, l: null as any, o: null as any, v: null as any};
        addFunc(item);
      }
    }
  }

  static shapeHorizontalLineValue = (value:number, x0:number|Date=0, xref= 'paper', color='#7873B066')=>{
    return { // curr value horizontal line
      type: 'line', xref: xref,  x0: x0, y0: value, x1: 1, y1: value, layer: "below",
      line: {color: color, width: 2, dash: 'dash'}
    } as Partial<Plotly.Shape>;
  }

  static shapeVerticalLinePredictionTime = (predTimeSec:number, y0=0, y1=1,color='#fff')=>{
    return { // vertical prediction time
      type: 'line', x0: predTimeSec, y0: y0, x1: predTimeSec, yref: 'paper', y1: y1,
      line: {color: color,width: 1.05,dash: 'solid'}
    }  as Partial<Plotly.Shape>
  }

  static timeZoneAnnotation = ()=>{
    return {
      text: 'Eastern Time Zone',
      x: 0.5, y: 0, xref: 'paper', yref: 'paper',
      xanchor: "center", yanchor: "top",
      showarrow: false,
      yshift: -30,
    } as Partial<Plotly.Annotations>
  }

  static marginsSymbol = ()=>{
    return { l: 10, r: 60, b: 50, t: 15, pad: 3, } as Plotly.Margin;
  };

  static marginDefault = ()=>{
    return {l: 0, r: 60, b: 50, t: 10, pad: 0,} as Plotly.Margin;
  }
  static font = ()=>{
    return { family: this.fontFamily, size: this.fontSize , color: this.color} as Plotly.Font;
  };

  static dailyChartTemplate = (p:ChartParams)=>{
    const yTickPrefix = "   ";
    // @NOTE: if don't calculate range and use several plot series, it can be issue with last line is just one last dot
    // it's a workaround to set fixed range
    // let xMin:number|null = null;
    // let xMax:number|null = null;
    // p.plotData.forEach((i)=>{
    //   const xData = i.x as number[];
    //   const l = xData.length;
    //   if (l===0) {
    //     return;
    //   }
    //   xMin = Math.min(xData[0], xData[l-1], xMin!==null ? xMin : xData[0]);
    //   xMax = Math.max(xData[0], xData[l-1], xMax!==null ? xMax : xData[0]);
    // });
    // xMax= xMax as any + 3600;
    const chart: IChart = {
      layout: {
        font: this.font(),
        showlegend: false,
        dragmode: false,
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        margin: this.marginDefault(),
        yaxis: { showgrid: false, side: "right", range: [p.yMin, p.yMax], tickformat: p.formatStr, tickprefix: yTickPrefix },
        xaxis: {
          type: 'date',
          rangebreaks: p.rangeBreaks || [],
          showgrid: false,
          tickmode: "array", /*range: [xMin, xMax],*/
          tickvals: p.tickVals,
          ticktext: p.tickText,
          tick0: p.tickVals[0],
          dtick: 1,
          rangeslider: {visible: false}, // hide bottom range slider
        },
        shapes: [] as Plotly.Shape[],
        annotations: [
          this.timeZoneAnnotation(),
        ],
      } as any as Plotly.Layout,
      data: p.plotData || [],
      config: this.config,
    } as IChart;
    if (p.stockSymbol) {
      (chart.layout as any).margin = this.marginsSymbol();
      chart.layout.annotations?.push({ // stock symbol top-left
        text: p.stockSymbol,
        xref: 'paper',
        yref: 'paper',
        x: 0,
        y: 1.07,
        font: { color: this.lineColor, size: 14, },
        showarrow: false,
      });
    }
    if (p.rangeBreaksLines) {
      for (const lb of p.rangeBreaksLines) {
        // console.debug('render line break');
        chart.layout.shapes.push({
          type: 'rect', x0: lb.x0, y0: p.yMin, x1: lb.x1, y1: p.yMax, fillcolor: this.gapColor, layer: "below",
          line: { width: 0 }
        })
      }
    }
    // console.debug({chart});
    return chart;
  }

  static prebuildChartDay(params:{
    stats:ReadonlyArray<IQuoteFull>,
    endFillTimeSec:number,
    predictionTime?:number,
    chartWidth?:number,
    predictionValueAtTime?: number, // predicted value will be at the time
    predictionTypeId?: PredType,
    xAxisLabelMonthDayOnly?: boolean,
    xAxisGapInSeconds?: number
  }) {

    const predTypeId = params?.predictionTypeId;
    const xAxisLabelMonthDayOnly = params?.xAxisLabelMonthDayOnly || false;
    const stats = params.stats;
    const endFillTimeSec = params.endFillTimeSec;
    const predictionTime = params.predictionTime;
    const predictionValueAtTime = params.predictionValueAtTime;
    const emptyQ: IQuote = { c: 0, t: endFillTimeSec, h: 0, l: 0, o: 0};
    const lastStats = stats.length > 0 ? stats[stats.length - 1] : emptyQ;
    const firstStats = stats.length > 0 ? stats[0]: emptyQ;
    let dataLowDay = lastStats.c;
    let dataHighDay = lastStats.c;
    let dataLowAfterPrediction = lastStats.c; 
    let dataHighAfterPrediction = lastStats.c;
    const splitXTimeBefore = predictionTime || 0;
    const splitXTimeAfter = predictionValueAtTime || null;
    const xAxisGapInSeconds = params.xAxisGapInSeconds || 3600;
    const secsStep = xAxisGapInSeconds === 3600 ? this.intervalSecs(predTypeId ? PredictionTypeHelper.predictionInterval(predTypeId) : StockInterval.MIN_1) : this.interval_5MinsInSeconds;

    // one iteration for everything
    const plotLines = {
      before: {x:[], y:[]},
      during: {x:[], y:[]},
      after: {x:[], y:[]}
    } as PlotLines;

    const plotCandles: PlotCandles = {
      before: {x:[], close: [], high:[], open: [], low: [], volume: []},
      during: {x:[], close: [], high:[], open: [], low: [], volume: []},
      after: {x:[], close: [], high:[], open: [], low: [], volume: []}
    }

    // const chartDataPlotlyXLeft: any[] = [];
    // const chartDataPlotlyYLeft: any[] = [];
    // const chartDataPlotlyXRight: any[] = [];
    // const chartDataPlotlyYRight: any[] = [];
    // let tickText: string[] = [];
    // const tickVals: number[] = [];
    //let predictionClose:IQuote = null as any;
    let lastX = lastStats.t;
    const rangeBreaks: RangeBreak[] = [];

    let prevCountPoints = 0;
    const ticks: TickLabel[] = [];
    let pricePrev = firstStats.c;
    let actualPriceAtPredictedTime:null|number = null;
    const processItem = (d: IQuoteFull, addTick=true) => {
      // get price
      if (actualPriceAtPredictedTime===null && predictionTime && d.t>=predictionTime) {
        actualPriceAtPredictedTime = d.c!==null ? d.c : pricePrev;
      }

      if (d.c !== null) {
        if (d.c > dataHighDay) {
          dataHighDay = d.c;
        }
        if (d.c < dataLowDay) {
          dataLowDay = d.c;
        }

        if (predictionTime) {
          if (d.t >= predictionTime) {
            if (d.c < dataLowAfterPrediction) {
              dataLowAfterPrediction = d.c;
            } else  if (d.t >= predictionTime && d.c > dataHighAfterPrediction) {
              dataHighAfterPrediction = d.c;
            }
          }
        }
        pricePrev = d.c;
      }

      // if ((d.t % (30 * 60) === 0) && endFillTimeSec === 0) {
      //   const name = xAxisLabelMonthDayOnly ? this.timeFormatMDNoTime(d.t) :
      //     predTypeId === PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D ? this.timeFormatMDY(d.t) : this.timeFormatHourMin(d.t);
      //   ticks.push({
      //     name: name,
      //     label: name,
      //     time: d.t,
      //     prevLabelDist: prevCountPoints,
      //   });
      //   prevCountPoints = 0;
      // }

      if (d.t % xAxisGapInSeconds === 0) {
        // as a result of gaps, it can be very short interval between hourly labels
        if (addTick) {
          const name = xAxisLabelMonthDayOnly ? this.timeFormatMDNoTime(d.t) :
               predTypeId === PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D ? this.timeFormatMDY(d.t) : xAxisGapInSeconds === 3600 ? this.timeFormatHour(d.t) : this.timeFormatHourMin(d.t);
          ticks.push({
            name: name,
            label: name,
            time: d.t,
            prevLabelDist: prevCountPoints,
          });
          prevCountPoints = 0;
        }
        // if (prevCountPoints>30 && addTick) {
        //   tickText.push(this.timeFormatHour(d.t));
        //   tickVals.push(d.t);
        //   prevCountPoints = 0;
        // }
      }

      // build 3 plot lines
      let plotLine: PlotLineData;
      let plotCandle: PlotCandleType;

      if (splitXTimeAfter && d.t > splitXTimeAfter) {
        plotLine = plotLines.after;
        plotCandle = plotCandles.after;
      } else if (d.t >= splitXTimeBefore) {
        plotLine = plotLines.during;
        plotCandle = plotCandles.during;
      } else {
        plotLine = plotLines.before;
        plotCandle = plotCandles.before;
      }
      plotLine.x.push(d.t);
      plotLine.y.push(d.c);

      // candles
      plotCandle.x.push(d.t);
      plotCandle.close.push(d.c);
      plotCandle.high.push(d.h);
      plotCandle.low.push(d.l);
      plotCandle.open.push(d.o);
      plotCandle.volume.push(d.v);

      lastX = d.t;
      prevCountPoints++;
    };
    const breakLines: LineBreak[] = [];
    let prev = stats[0];
    if (!prev || (predictionTime && predictionTime<prev.t)) {
      prev = {t: predictionTime, c: null as any} as IQuoteFull;
    }
    const startPt = prev;
    const maxGapLimitSec = 120*60; // 2h   secsStep; // 120 chart points
    const shrinkGapToSec = 60*60; // 1h // secsStep; // 60 points
    const dvalue = 86400; // https://plotly.com/python/reference/layout/xaxis/#layout-xaxis-rangebreaks

    const handleStat = (pt:number, vt:number)=>{
      if (vt - pt > maxGapLimitSec) { // big gap 2h, add to chart
        const start = pt === startPt.t ? pt : pt + secsStep; // inclusive
        const end = vt - secsStep; // inclusive
        const startHide = pt + shrinkGapToSec + secsStep; // keep 1 hour on plotly to show the gap exists
        const endHide = vt - secsStep;
        const startLine = start;
        const endLine = startHide - secsStep;
        const lines: LineBreak[] = [];
        const breaks: RangeBreak[] = [];
        if (predictionTime && predictionTime >= start && predictionTime <= end) { // prediction made in the gap
          const predTimeAdjusted = predictionTime; // Math.round(predictionTime/1800) * 1800;
          // need to calc so the prediction is in the middle
          const predStartLine = Math.max(startLine, predTimeAdjusted - shrinkGapToSec / 2);
          const predEndLine = Math.min(endHide, predStartLine + shrinkGapToSec);
          lines.push({x0: predStartLine, x1: predEndLine/*+59*/});
          if (start < predStartLine) {
            breaks.push({bounds: [start, predStartLine - secsStep] as any, dvalue: dvalue});
          }
          if (end > predEndLine) {
            breaks.push({bounds: [predEndLine + secsStep, end] as any, dvalue: dvalue});
          }
        } else {
          lines.push({x0: startLine, x1: endLine/*+59*/});
          breaks.push({bounds: [startHide, endHide] as any, dvalue: dvalue});
          // add the lineBreak range to chart with "null" value
        }
        for (const line of lines) {
          // don't render point with last tick - it will be very close to 10AM and will look not good
          const skipLastTick = line.x0 % 3600 === 0 && line.x1 % 3600 === 0;
          // -step because exclusive inside the start
          this.fillFullDay(line.x0 - secsStep, skipLastTick ? line.x1 - secsStep : line.x1, processItem, secsStep);
        }
        breakLines.push(...lines);
        rangeBreaks.push(...breaks);
      }
    }
    stats.forEach((v)=>{
      const pt = prev.t;
      const vt = v.t;
      handleStat(pt, vt);
      processItem(v);
      prev = v;
    });
    //const lastStat = stats[stats.length-1]||emptyQ;
    // add stats to fill entire day
    if (endFillTimeSec) {
      this.fillChartWithEmptyUntil(lastStats.t, endFillTimeSec, handleStat, predTypeId, processItem, secsStep);
    }


    // const tickTextReduced = tickText;
    const totalPoints = plotLines.before.x.length + plotLines.during.x.length + plotLines.after.x.length;

    const ticksData = this.reduceTickLabels(ticks, 7, params.chartWidth, totalPoints);

    const getUniqueTicks = (tickData: {text: string[], vals: number[]}) => {
      const { text, vals } = tickData;
      const output: {
        text: string[],
        vals: number[],
      } = {
        text: [],
        vals: [],
      }
      for (let i: number = 0; i < text.length; i++ ) {
        if (!output.text.includes(text[i])) {
          output.text = [...output.text, text[i]];
          output.vals = [...output.vals, vals[i]];
        }
      }
      return output;
    }

    const uniqueTicks = getUniqueTicks(ticksData);

    const formatStr = this.formatPriceStr(dataHighDay);
    const priceFormat = this.priceFormatFunc(dataHighDay);

    return {
      formatStr: formatStr,
      priceFormat: priceFormat,
      plotLines: plotLines,
      plotCandles: plotCandles,
      // chartDataPlotlyXBefore: chartDataPlotlyXLeft,
      // chartDataPlotlyXDuring: chartDataPlotlyXRight,
      // chartDataPlotlyYBefore: chartDataPlotlyYLeft,
      // chartDataPlotlyYDuring: chartDataPlotlyYRight,
      chartDataPlotlyXGaps: rangeBreaks,
      chartDatePlotlyXGapsLines: breakLines,
      //predictionClose: predictionClose,
      dataLowDay: dataLowDay,
      dataHighDay: dataHighDay,
      dataLowAfterPrediction,
      dataHighAfterPrediction,
      tickVals: xAxisLabelMonthDayOnly ? uniqueTicks.vals : ticksData.vals ,
      ticksFull: ticks,
      tickText: xAxisLabelMonthDayOnly ? uniqueTicks.text : ticksData.text ,
      lastStats: lastStats,
      lastX: lastX,
      firstStats: firstStats,
      actualPriceAtPredictedTime: actualPriceAtPredictedTime,
    }
  }

  private static intervalSecs(interval:StockInterval) {
    switch (interval) {
      case StockInterval.MIN_5:
        return 300;
      case StockInterval.MIN_1:
        return 60;
      default: throw new Error('unsupported');
    }
  }

  private static fillChartWithEmptyUntil(
    lastStatTimeSec: number,
    endFillTimeSec: number,
    handleStat: (pt: number, vt: number) => void,
    predType: PredType|undefined,
    processItem: (d: IQuoteFull, addTick?: boolean) => void,
    stepSizeSecs: number
  ) {

    // const end = new DateEx(endFillTimeSec * 1000);
    const ds = StockHelper.workingHours(new Date(lastStatTimeSec*1000));
    const start = ds.start;

    //if no data for "endFill" working day - need to add "future" gap to chart
    if (start.getTimeSec() > lastStatTimeSec) {
      handleStat(lastStatTimeSec, start.getTimeSec());
      lastStatTimeSec = start.getTimeSec() - stepSizeSecs;
    }

    const getEnd = (d:DateEx) => {
      const dsLoc = StockHelper.workingHours(d);
      if (predType) {
        switch (predType) {
          case PredType.VALUE_AT_8PM:
            return dsLoc.end8PM.getTimeSec();
          case PredType.VALUE_CLOSE_UP_DOWN_3D:
            return DateHelper.round5MinFloor(dsLoc.end.getTimeSec()).getTimeSec();
        }
      }
      return stepSizeSecs === this.interval_5MinsInSeconds ?  endFillTimeSec : dsLoc.end.getTimeSec(); // check if 30 min step size
    };

    // end can be in N days, not today or next working day
    let currDay = new DateEx(lastStatTimeSec*1000);
    let endTOld:number|null = null;
    while (currDay.getTimeSec()<=endFillTimeSec) {
      // first day render start from provided, next start from beginning
      const startT = currDay.getTimeSec()===lastStatTimeSec ?
                     lastStatTimeSec :
                     StockHelper.workingHours(currDay).start.getTimeSec()-stepSizeSecs;

      const endT = getEnd(currDay);

      if (endTOld!==null) {
        handleStat(endTOld, startT);
      }
      this.fillFullDay(startT, endT, processItem, stepSizeSecs);
      if (endFillTimeSec-endT<7200) { // end day is current - no need to search next
        break;
      }
      currDay = new DateEx(StockHelper.getNextTradingDay(currDay));
      endTOld = endT;
    }

  }

  public static reduceTickLabels(ticks: TickLabel[], maxLabels:number=7, chartWidth?:number, totalPoints?: number) {
    // return tickTextIn;
    const mapToData = ()=>{
      let tickText:string[] = [];
      let tickVals: number[] = [];
      ticks.forEach(v=>{
        tickText.push(v.label);
        tickVals.push(v.time);
      });
      return {text:tickText,vals:tickVals};
    }

    if (!chartWidth || !totalPoints) {
      if (ticks.length<=maxLabels) {
        return mapToData();
      }
      // need to reduce tick vals
      // pick n elements from a, distributed evenly
      const pickn = function (a: any[], n: number) {
        const p = Math.round(a.length / n);
        return a.slice(0, p * n).filter((_, i) => 0 === i % p);
      }
      const d = mapToData();
      const arr = [];
      for (let i = 0; i < d.text.length; i++) {
        arr.push(i);
      }
      const tickValsLabels = pickn(arr, maxLabels);
      // set to empty if shouldn't be rendered
      for (let i = 0; i < d.text.length; i++) {
        if (!tickValsLabels.includes(i)) {
          d.text[i] = '';
        }
      }
      // console.debug({tickText,tickValsLabels,arr});
      return d;
    } else {
        const yAxisWidth = this.yAxisWidth;
        const labelWidth = this.xAxisLabelWidth;// about 50-70pixels
        const gapLastHour = labelWidth/2;
        const xWidthAvailable = chartWidth - yAxisWidth - gapLastHour;
        // min number of plot rendered points to fit hourly label
        const minDistance = Math.ceil(labelWidth / (xWidthAvailable/totalPoints));
        const text:string[] = [];
        const vals:number[] = [];
        let currDist:number = 0;
        let minSpaceTickCurr = Math.ceil(minDistance/2); // first label requires just half - basically empty space on left side only
        ticks.forEach(t=>{
          // first label requires half size only, after first render - increase required to maximum
          if (t.prevLabelDist + currDist >= minSpaceTickCurr) {
            text.push(t.label);
            vals.push(t.time);
            currDist = 0;
            minSpaceTickCurr = minDistance;
          } else {
            //text.push('');
            currDist+=t.prevLabelDist;
          }
          // console.debug({prev: currDist,t});
        });
        // console.debug({text,ticks,chartWidth,totalPoints,minDistance});
        return {
          text:text,vals:vals
        }
    }
  }

  protected static buildDateMinusNHours(d:Date, hours=2) {
    const dMinus = new DateEx(d);
    dMinus.setMinutes(dMinus.getMinutes() - 60*hours);
    return dMinus;
  }

  /**
   * If predictions is empty - means usually "create" prediction call
   */
  public static calcStatsRangeMakePrediction(nowSec: number, statsLastSec: number, predictionType: PredType) {
    const plotLineEnd = new DateEx(nowSec * 1000);
    const statsLast = new DateEx(statsLastSec * 1000);
    const datesCurr = StockHelper.workingHours(statsLast);
    const dateCurrEndOrig = datesCurr.end;
    switch (predictionType) {
      case PredType.VALUE_AT_8PM:
        datesCurr.end = new DateEx(datesCurr.end8PM);
        break;
      case PredType.VALUE_CLOSE_UP_DOWN_3D:
        datesCurr.end = PredictionTypeHelper.predictionValueAt(predictionType, plotLineEnd);
        break;
    }
    let plotLineStart = datesCurr.start;
    let end = plotLineEnd;
    // return extra from previous days for all predictions made before 10AM
    const minStart = new DateEx(datesCurr.start.getTime() + 60*29 * 1_000);
    if (plotLineEnd < minStart) { // before hour check 00:00-10:00
      // append previous day's last N hours
      const prevTradingDay = StockHelper.findPreviousTradingDay(datesCurr.start);
      const datesPrev = StockHelper.workingHours(prevTradingDay);
      plotLineStart = this.buildDateMinusNHours(datesPrev.end);
      if (plotLineEnd>datesCurr.start) { // also append today stats what is available so far
        end = plotLineEnd;
      } else {
        end = datesPrev.end;
      }
    } else if (plotLineEnd > datesCurr.end) { // after hour check 16:00-23:59
      // start from N previous hours
      plotLineStart = this.buildDateMinusNHours(dateCurrEndOrig); // use available stats for 8PM too
      end = datesCurr.end;
    }
    // x-axis should end at
    const chartEndSec = Math.max(datesCurr.end.getTimeSec(), nowSec);
    return {
      start: plotLineStart,
      end: end, // plot line end
      chartEndSec: chartEndSec, // empty chart without stats
    }
  }

  public static calcRangeDatePrediction(predMadeTimeSec:number, predValueAtTimeSec:number) {
    const predMade = new DateEx(predMadeTimeSec*1000);
    const predValueAt = new DateEx(predValueAtTimeSec*1000);
    const endDate = StockHelper.workingHours(predValueAt).end;
    const endDate8PM = StockHelper.workingHours(predValueAt).end8PM;
    let start:DateEx;
    if (!StockHelper.isMarketOpen(predMade)) {
      if (StockHelper.isTradingDay(predMade) && StockHelper.isPostMarketHours(predMade)) {
        start = this.buildDateMinusNHours(StockHelper.workingHours(predMade).end);
      } else {
        start = this.buildDateMinusNHours(StockHelper.workingHours(StockHelper.findPreviousTradingDay(predMade)).end);
      }
    } else {
      start = StockHelper.workingHours(predMade).start;
    }

    return {
      start: start,
      end: endDate,
      end8PM: endDate8PM,
    }
  }


  public static setSvgGradients(plotlyDiv: Readonly<HTMLElement>, gradients: ChartSvgRectangleGradient[]) {
    const svg = plotlyDiv.getElementsByClassName("main-svg")[0];
    const search:ChartSvgRectangleGradientSearch[] = [];
    gradients.forEach(g=>{
      const rc = g.replaceColor;
      const id = `${rc.r}-${rc.g}-${rc.b}-${rc.a}`;
      search.push({
        id: id,
        gradient: g,
        searchRgb: `rgb(${rc.r},${g.replaceColor.g},${rc.b})`,
        searchOpacity:  `opacity:${rc.a}`,
      });
      const c = g.gradient.color;
      const cStr = `rgba(${c.r},${c.g},${c.b}, ${c.a})`;
      svg.getElementsByTagName('defs')[0].innerHTML +=
        `<linearGradient gradientTransform="rotate(${g.gradient.rotate} 0.5 0.5)" id="${id}">
            <stop offset="0%" style="stop-color: ${cStr}; stop-opacity: 1"/> 
            <stop offset="50%" style="stop-color: ${cStr}; stop-opacity: 0.4"/>            
            <stop offset="100%" style="stop-color: ${cStr}; stop-opacity: 0.1"/>          
        </linearGradient>`;
    });

    const setGradient = ()=>{
      const list = svg.getElementsByClassName('shapelayer');
      for (let i = 0; i < list.length; i++) {
        const l = list[i]; //second console output
        const ps = l.getElementsByTagName('path');
        for (let j = 0; j < ps.length; j++) {
          const p = ps[j];
          const s = p?.getAttribute('style')?.replaceAll(' ', '') as any;
          search.forEach(gs=>{
            if (s && s.indexOf(gs.searchRgb) >= 0 && s.indexOf(gs.searchOpacity) >=0) {
              // console.debug({m:'found element', p});
              p.setAttribute('style', '');
              p.setAttribute('fill', `url(#${gs.id})`);
            }
          });
        }
      }
    };
    (plotlyDiv as any).on('plotly_afterplot', setGradient);
    setGradient();
  }

  public static parseColor(color:string) {
    const parsedColor = color.replaceAll(' ', '').split("(")[1].split(")")[0].split(",");
    const colors = {
      r: parsedColor[0],
      g: parsedColor[1],
      b: parsedColor[2],
      a: parsedColor[3] || '1',
    };
    //normalize - append zero. Zero can be removed during minification
    if (colors.a.startsWith('.')) {
      colors.a = '0' + colors.a;
    }
    return colors;
  }

  public static getCandleStartEnd(prediction: IPrediction, currentDate: Date, chartWidth: number | undefined): CandleStartEndType {

    // values if prediction is less than 15 minutes old
    const secondsElapsed = (currentDate.getTime() - new Date(prediction.createdAt as number * 1000).getTime()) / 1000;

    let candleIntervalMinutes = 60;
    let prevHourSecs = (prediction.createdAt as number) - (60 * candleIntervalMinutes);
    let nextHourSecs = (prediction.valueAt as number) + (60 * candleIntervalMinutes);

    if (chartWidth && chartWidth <= this.narrowWidth) {
      if (secondsElapsed < (15 * 60)) {
        // prediction is less than 15 minutes old
        nextHourSecs = prediction.valueAt as number;
      } else {
        candleIntervalMinutes = 15;
      }
    }

    return {
      interval: candleIntervalMinutes,
      startTimeSecs: prevHourSecs,
      endTimeSecs: nextHourSecs
    }
  }

  public static toUsTimeHHMM(ts:number) {
    const d = DateHelper.extractDateTimeUs(ts);
    return `${d.hour}:${d.minute<10?'0':''}${d.minute}`;
  }

  public static stockInfoMinute(price:number,timeStr:string) {
    return [`price: ${price}`,`time: ${timeStr}`].join('<br>');
  }

  public static setCustomPlotCandleInfo(lIn:PlotlyDataCandle, timeFormat=ChartModel.toUsTimeHHMM) {
    const l = lIn as Plotly.CandlestickData;
    // l.hovertemplate = '%{customdata[0]}<extra></extra>';
    l.text = l.x.map((timeSec,i)=>
      ChartModel.candleInfoMinute({
        o: l.open[i],
        h: l.high[i],
        l: l.low[i],
        c: l.close[i],
        v: lIn.source.volume[i],
        timeStr: timeFormat(timeSec as any)})
    )
    // l.text = l.x.map((timeSec,i)=>{
    //   return [
    //     `open: ${l.open[i]}`,
    //     `high: ${l.high[i]}`,
    //     `low: ${l.low[i]}`,
    //     `close: ${l.close[i]}`,
    //     `vol: ${lIn.source.volume[i]}`,
    //     `time: ${toUsTime(timeSec as any)}`].join('<br>');
    // })
  }

  public static candleInfoMinute(p:{o:number,c:number,h:number,l:number,v:number,timeStr:string}) {
    return [
      `open: ${p.o}`,
      `high: ${p.h}`,
      `low: ${p.l}`,
      `close: ${p.c}`,
      `vol: ${p.v}`,
      `time: ${p.timeStr}`
    ].join('<br>');
  }

  static setCumulativeChartOpts(chart: IChart, revision:number) {
    // @ts-ignore
    chart.config.scrollZoom = false;
    // @ts-ignore
    chart.config.displayModeBar = true;
    // chart.layout.height = 500;
    // chart.divId = `${symbol}-${timeSecsLatest}`;
    //setItems(items);
    chart.layout.paper_bgcolor = 'rgb(0,0,0)';
    chart.layout.showlegend = true;
    chart.layout.legend = { x: 0, xanchor: 'left', y: 0 };
    chart.layout.showlegend = true;
    chart.layout.hovermode = 'x unified';
    // @TODO: it's not clear why need to provide booth, looks like plotly wrapper has an extra version flag
    chart.layout.uirevision = revision;
    // chart.revision = revision;
  }

  static setCustomPlotStockInfo(cData: Plotly.PlotData, timeFormat=ChartModel.toUsTimeHHMM) {
    cData.text = (cData.x as number[]).map((v:number,i)=>
      ChartModel.stockInfoMinute(cData.y[i] as number, timeFormat(v as any))
    );
  }

  public static hasToggle(prediction: IPrediction, currentDate: Date) {
    if (PredictionTypeHelper.isOffHour(prediction.typeId as PredictionTypeEnum) && !StockHelper.isMarketOpen(currentDate)) {
      return false;
    }
    return true;
  }

  public static getArrow(isSell: boolean, optionType: OptionType): {
    direction: "down" | "up",
    color: "green" | "red",
  } {
    if (isSell) {
      return {
        direction: optionType === OptionType.CALL ? "down" : "up",
        color: "red",
      }
    }

    return {
      direction: optionType === OptionType.CALL ? "up" : "down",
      color: "green",
    }

  }


}
