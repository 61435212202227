import React from "react"

export const TicketSvg: React.FunctionComponent<{width?: number, height?: number}> = ({width=89, height=78}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 89 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.5679 61.1567C35.2519 58.1133 31.0401 55.1494 26.8333 52.18C20.5543 47.748 14.2754 43.316 7.99648 38.8841C6.58285 37.8901 6.46809 37.1763 7.4864 35.7671C9.25604 33.3194 11.0275 30.8734 12.8008 28.429C13.6778 27.2257 14.4014 27.0941 15.6491 27.873C17.2342 28.8851 19.1452 29.2578 20.9939 28.9153C22.8425 28.5729 24.4902 27.5409 25.602 26.0291C26.7139 24.5173 27.2065 22.6391 26.9799 20.7762C26.7533 18.9132 25.8243 17.2052 24.3818 15.9993C24.1557 15.8051 23.9028 15.6341 23.6914 15.4235C23.4057 15.1641 23.2248 14.8095 23.1827 14.4265C23.1406 14.0436 23.2403 13.6587 23.4629 13.3443C25.4129 10.5566 27.3683 7.77381 29.3519 5.01228C30.0389 4.0558 30.9028 3.99649 31.9812 4.7597L50.7387 18.0339L63.0791 26.7841C63.2525 26.9096 63.4202 27.0436 63.6679 27.2336L39.5679 61.1567Z" fill="url(#paint0_linear_7742_39932)"/>
      <path d="M43.1973 63.6566C51.2262 52.3398 59.252 41.0266 67.2744 29.7171C69.8833 31.5572 72.4122 33.3415 74.9583 35.1373C77.0304 36.5999 79.0802 38.0937 81.1745 39.5249C82.1465 40.1912 82.5502 41.2182 81.6807 42.3319C79.8113 44.7376 78.1277 47.2858 76.3681 49.7758C75.3892 51.1651 74.7162 51.3106 73.2954 50.43C71.7074 49.4832 69.8222 49.1637 68.0116 49.5346C66.201 49.9055 64.5963 50.9398 63.5138 52.4337C62.4313 53.9276 61.9497 55.7726 62.1638 57.6051C62.3779 59.4375 63.2722 61.1244 64.6704 62.3332C64.9006 62.5251 65.1494 62.6984 65.36 62.9121C65.6577 63.1791 65.8447 63.5473 65.8842 63.9445C65.9237 64.3417 65.8129 64.7391 65.5735 65.0585C63.6204 67.8453 61.6582 70.624 59.6868 73.3946C59.5735 73.5757 59.4238 73.7314 59.2471 73.852C59.0704 73.9725 58.8706 74.0553 58.6602 74.0951C58.4499 74.1348 58.2334 74.1307 58.0246 74.0831C57.8158 74.0354 57.619 73.9451 57.4467 73.818C52.7438 70.4957 48.0505 67.1761 43.354 63.8556C43.2948 63.795 43.2423 63.7283 43.1973 63.6566Z" fill="url(#paint1_linear_7742_39932)"/>
      <defs>
        <linearGradient id="paint0_linear_7742_39932" x1="49.029" y1="9.20502" x2="-13.9091" y2="28.1368" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9C41"/>
          <stop offset="1" stopColor="#FF43B4"/>
        </linearGradient>
        <linearGradient id="paint1_linear_7742_39932" x1="73.0287" y1="31.2895" x2="30.4731" y2="37.6729" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9C41"/>
          <stop offset="1" stopColor="#FF43B4"/>
        </linearGradient>
      </defs>
    </svg>
  )
}


