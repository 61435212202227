import React from 'react'
import { IUserSignalAlert } from 'services/UserSignalApiService'
import { Signal } from './Signal'

export const Signals: React.FunctionComponent<{ items: IUserSignalAlert[] }> = ({ items }) => {
  return (

    <div className="d-flex flex-column justify-content-start gap-1 ">

      {items.map(item =>
        <div className="d-flex justify-content-start border-bottom border-color-gray">
          <Signal signal={item} />
        </div>
      )}

    </div>

  )
}
