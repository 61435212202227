import { APIState } from "_constants/APIState";
import { IAPIPagedResponse } from "interfaces/IAPIPagedResponse";
import { useCallback, useEffect, useRef, useState } from "react";
import { ITradegramSecuritiesFilter, ITradegramSecurity, TradegramApiService } from "services/TradegramApiService";


export interface ITradegramSecurities {
  items: ITradegramSecurity[],
  apiState: {
    isLoading: boolean,
    isLoaded: boolean,
    isIdle: boolean,
    isError: boolean,
  },
  error: Error | null,
  reload: () => void,
  setCurrentDataset: React.Dispatch<React.SetStateAction<SecuritiesDatasetEnum>>,
  setFilter: React.Dispatch<React.SetStateAction<ITradegramSecuritiesFilter>>,
  getNextPage: ()=>void,
}

export enum SecuritiesDatasetEnum {
  ALL = 1,
  OWNED = 2,
  TOP = 3,
  FOLLOWING = 4
}

export function useTradegramSecurities(
  service: TradegramApiService, 
  dataset: SecuritiesDatasetEnum = SecuritiesDatasetEnum.ALL,
  initialFilter?: ITradegramSecuritiesFilter): ITradegramSecurities {

  const [items, setItems] = useState<ITradegramSecurity[]>([]);
  const [pagedResponse, setPagedResponse] =   useState<IAPIPagedResponse<ITradegramSecurity, number>>();

  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [error, setError] = useState<Error | null>(null);
  const [refresh, setRefresh] = useState<number>(0);
  const [filter, setFilter] = useState<ITradegramSecuritiesFilter>(initialFilter as ITradegramSecuritiesFilter)
  const [currentDataset, setCurrentDataset] = useState<SecuritiesDatasetEnum>(dataset);

  const serviceRef = useRef(service);

  const noRejectedFilter = (d: ITradegramSecurity) => d.transactionsRejected === 0;

  const load = useCallback(async (abortSignal: AbortSignal) => {

    try {
      setApiState(APIState.LOADING);

      let pagedResponse: IAPIPagedResponse<ITradegramSecurity, number> = {} as IAPIPagedResponse<ITradegramSecurity, number>;

      switch (currentDataset) {
        case SecuritiesDatasetEnum.OWNED:
          pagedResponse = await serviceRef.current.getMySecurities(filter, abortSignal);
          setPagedResponse(pagedResponse);
          setItems(pagedResponse.data);
          break;
        case SecuritiesDatasetEnum.TOP:
          pagedResponse = await serviceRef.current.getMySecuritiesByTop(filter, abortSignal);
          setPagedResponse(pagedResponse);
          setItems(pagedResponse.data.filter(noRejectedFilter));
          break;
        case SecuritiesDatasetEnum.FOLLOWING:
          pagedResponse = await serviceRef.current.getMySecuritiesByFollowed(filter, abortSignal);
          setPagedResponse(pagedResponse);
          setItems(pagedResponse.data.filter(noRejectedFilter));
          break;
        default:
          pagedResponse = await serviceRef.current.getSecurities(filter, abortSignal);
          setPagedResponse(pagedResponse);
          setItems(pagedResponse.data.filter(noRejectedFilter));
      }
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as Error);
    }
  }, [serviceRef, currentDataset, filter])

  const reload = () => {
    setRefresh(refresh + 1);
  }

  const getNextPage = () => {
    if (!pagedResponse?.pages.next) {
      return;
    }

    (async () => {
      const _filter: ITradegramSecuritiesFilter = {
        ...filter,
        pages: {
          current: pagedResponse.pages.next
        }
      }

      // @RT: this is repeated because having a setApiState(loading) causes the page to re-render and 
      // repositions the scrll on top of the page.
      // Need to refactor this.
      let _pagedResponse: IAPIPagedResponse<ITradegramSecurity, number> = {} as IAPIPagedResponse<ITradegramSecurity, number>;

      switch (currentDataset) {
        case SecuritiesDatasetEnum.OWNED:
          _pagedResponse = await serviceRef.current.getMySecurities(_filter);
          setPagedResponse(_pagedResponse);
          setItems((prevItems) => [...prevItems, ..._pagedResponse.data]);
          break;
        case SecuritiesDatasetEnum.TOP:
          _pagedResponse = await serviceRef.current.getMySecuritiesByTop(_filter);
          setPagedResponse(_pagedResponse);
          setItems((prevItems) => [...prevItems, ..._pagedResponse.data.filter(noRejectedFilter)]);
          break;
        case SecuritiesDatasetEnum.FOLLOWING:
          _pagedResponse = await serviceRef.current.getMySecuritiesByFollowed(_filter);
          setPagedResponse(_pagedResponse);
          setItems((prevItems) => [...prevItems, ..._pagedResponse.data.filter(noRejectedFilter)]);
          break;
        default:
          _pagedResponse = await serviceRef.current.getSecurities(_filter);
          setPagedResponse(_pagedResponse);
          setItems((prevItems) => [...prevItems, ..._pagedResponse.data.filter(noRejectedFilter)]);
      }

    })()

  }

  useEffect(() => {
    const abortController = new AbortController();
    load(abortController.signal);
    return () => {
      if (abortController) {
        abortController.abort();
      }
    }
  }, [load, refresh])

  return { 
    items,
    apiState: {
      isIdle: apiState === APIState.IDLE,
      isLoaded: apiState === APIState.LOADED,
      isLoading: apiState === APIState.LOADING,
      isError: apiState === APIState.ERROR
    },
    error,
    reload,
    setCurrentDataset,
    setFilter,
    getNextPage};

}