import {
    Analysis,
    IQuoteFull,
    LinePoints,
    MinMaxPointsMap,
    MinMaxType, PointUpDown
} from "../interface";
import {MathEx} from "../utils";
import {StockSignalsEnum} from "../dict";
import {MinMaxAtrData} from "./min-max-atr.data";

export interface LinePointsMinMax {
    factor: number,
    rangeSecs: number,
    min: LinePoints,
    max: LinePoints,
    stepSecs: number,
}

export interface LinePointsMinMaxAtr {
    min: LinePoints,
    max: LinePoints,
}


export interface MinMaxStatsAtr {
    points: number,
    pct100: LinePointsMinMaxAtr,
    pct160: LinePointsMinMaxAtr,
    pct220: LinePointsMinMaxAtr,
    pct400: LinePointsMinMaxAtr,
    pct1000: LinePointsMinMaxAtr,
    pct2000: LinePointsMinMaxAtr,
    pct4000: LinePointsMinMaxAtr,
}

export interface MinMaxStatsAtrWithSignals extends MinMaxStatsAtr{
    signals: {
        [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED]: PointUpDown[],

        [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK]: PointUpDown[],

        [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE]: PointUpDown[],
        [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE]: PointUpDown[],

        [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE]: PointUpDown[],

    }
}

export interface MinMaxStats {
    stats2hAvg: LinePointsMinMax,
    stats2h220pctAvg: LinePointsMinMax,
    stats2h160pctAvg: LinePointsMinMax,

    stats4h160pctAvg: LinePointsMinMax,
    stats4hAvg: LinePointsMinMax,

}
export class MinMaxData {

    public static roundToStep(timeSecs: number, stepSecs: number) {
        const step = stepSecs;
        return Math.ceil(timeSecs / step) * step;
    }

    public static emptyMap(): MinMaxPointsMap {
        return {points: new Map<number, MinMaxType>(), stepSecs: 1800}; // time-min/max map
    }

    /**
     * Points are rounded using "ceil": 1 sec becomes 1800 secs
     * Points are "forming" next 30m point
     * @param time
     * @param minMaxCumMap
     * @param val
     * @param deviationHalf
     */
    public static addValToMinMaxMap(
            time: number, minMaxCumMap: MinMaxPointsMap, val: number, deviationHalf: number) {
        const t180m = this.roundToStep(time, minMaxCumMap.stepSecs)
        if (!minMaxCumMap.points.has(t180m)) {
            minMaxCumMap.points.set(t180m, {
                minSum: 0,
                maxSum: 0,
                count: 0,
                items: [],
                time: t180m,
            });
        }
        const item = minMaxCumMap.points.get(t180m) as MinMaxType;
        const min = (val - deviationHalf);
        const max = (val + deviationHalf);
        item.minSum += min;
        item.maxSum += max;
        item.count++;
        item.items.push({min: min, max: max, time: time});
    }

    public static calcMinMax(minMaxCumMap: MinMaxPointsMap): MinMaxStats {

        const hour4 = 240*60;
        const hour2 = 120*60;

        const buildEmpty = (factor: number, rangeSecs: number): LinePointsMinMax=>{
            return {factor: 1, rangeSecs:hour4,stepSecs: minMaxCumMap.stepSecs,
                max: {x: [] as number[], y: [] as number[]},
                min:{x: [] as number[], y: [] as number[]}
            }
        }

        const minMaxStatsPred: MinMaxStats = {
            stats4hAvg: buildEmpty(0, hour4), // 100%
            stats4h160pctAvg: buildEmpty(0.6, hour4),

            stats2hAvg: buildEmpty(0, hour2),
            stats2h160pctAvg: buildEmpty(0.6, hour2),
            stats2h220pctAvg: buildEmpty(1.2, hour2),
        };

        type MinMaxAggrPred = {min: number, max: number, count:number};

        const addToMinMaxLine = (line: LinePointsMinMax, time:number, min: number, max:number) => {
            line.min.y.push(min);
            line.min.x.push(time);
            line.max.y.push(max);
            line.max.x.push(time);
        }

        const addNewPoint = (time:number, minMax2: MinMaxAggrPred, minMax4: MinMaxAggrPred) =>{
            const min4 = MathEx.round(minMax4.min/minMax4.count, 6);
            const max4 = MathEx.round(minMax4.max/minMax4.count, 6);

            const band4h60pct = (max4-min4)*0.6;

            addToMinMaxLine(minMaxStatsPred.stats4hAvg, time, min4, max4);
            addToMinMaxLine(minMaxStatsPred.stats4h160pctAvg, time, min4 - band4h60pct, max4 + band4h60pct);

            // 2h data
            const min2 = MathEx.round(minMax2.min/minMax2.count, 6);
            const max2 = MathEx.round(minMax2.max/minMax2.count, 6);
            const band2h160pct = (max2-min2)*0.6;
            const band2h220pct = (max2-min2)*1.2;
            addToMinMaxLine(minMaxStatsPred.stats2hAvg, time, min2, max2);
            addToMinMaxLine(minMaxStatsPred.stats2h160pctAvg, time, min2 - band2h160pct, max2 + band2h160pct);
            addToMinMaxLine(minMaxStatsPred.stats2h220pctAvg, time, min2-band2h220pct, max2+band2h220pct);
        }

        const keys = Array.from(minMaxCumMap.points.keys());
        for (let i=0;i<keys.length;i++) {
            const curr = minMaxCumMap.points.get(keys[i]) as MinMaxType;
            const currTime = curr.time;

            const addToMinMax = (minMaxAggr: MinMaxAggrPred, minMax: MinMaxType)=>{
                minMaxAggr.min+=minMax.minSum;
                minMaxAggr.max+=minMax.maxSum;
                minMaxAggr.count+=minMax.count;
                return minMaxAggr;
            }


            // create sum of previous points excluding current one
            const loopBehindUntil = currTime-hour4;
            const emptyMinMax = ():MinMaxAggrPred=>{return {min: 0, max:0, count: 0}};
            const minMax4: MinMaxAggrPred = emptyMinMax();
            const minMax2: MinMaxAggrPred = emptyMinMax();
            for (let y=i-1;y>=0;y--) {
                const prev = minMaxCumMap.points.get(keys[y]) as MinMaxType;
                const prevTime = prev.time;
                if (prevTime<=loopBehindUntil) {
                    break;
                }

                if (prevTime>currTime-hour4) {
                    addToMinMax(minMax4, prev);
                }

                if (prevTime>currTime-hour2) {
                    addToMinMax(minMax2, prev);
                }

            }

             // loop all points behind current one
            const prevCumData = {minSum: 0, maxSum: 0, count: 0} as MinMaxType;
            for (let z=0;z<curr.items.length;z++) {
                const p = curr.items[z];
                if (p.time<currTime) {
                    prevCumData.minSum+=p.min;
                    prevCumData.maxSum+=p.max;
                    prevCumData.count++;
                    const next = z+1<curr.items.length ? curr.items[z+1] : null;
                    if (!next || next.time!==p.time) {
                        // add only if next item isn't same time
                        // add point between 30m intervals using up to "currTime" data
                        const tmpMinMax2 = addToMinMax(Object.assign({}, minMax2), prevCumData);
                        const tmpMinMax4 = addToMinMax(Object.assign({}, minMax4), prevCumData);
                        addNewPoint(p.time, tmpMinMax2, tmpMinMax4);
                    }

                }
            }


            // add current point firstly
            addToMinMax(minMax4, curr);
            addToMinMax(minMax2, curr);

            // add intermediate points too


            addNewPoint(currTime, minMax2, minMax4);

        }
        return minMaxStatsPred;
    }

    // public static minMaxAtr() {
    //     return new MinMaxAtrData();
    // }



    // public static emptyLine() {
    //     return { x: [] as number[], y: [] as number[]} as LinePoints;
    // }
}
