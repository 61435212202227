import React from 'react';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar';
import { ITeam } from 'interfaces/league';

export const Avatars: React.FunctionComponent<{ members: IPublicProfile[], team:ITeam }> = ({ members, team }) => {

  const NAME_COUNT = 3;
  const THUMB_COUNT = 8;

  return (
    <div className="team-avatars">
      <div className="team-avatars-thumbs d-flex flex-row justify-content-start align-items-center gap-1">
        {members.slice(0,THUMB_COUNT).map((m: IPublicProfile, i: number) => <div className="team-avatars-thumbs-img" key={`avatar-img-key-${i}`}>
          <Avatar profile={m} size={AvatarSizeEnum.MEDIUM_LARGE} />
        </div>
        )}
      </div>

      <div className="team-avatars-text my-2">
        <span className="fw-bold">{members.slice(0, NAME_COUNT).map(m => m.username).join(', ')}</span> and {team.members - NAME_COUNT > 0 ? <>{(team.members - NAME_COUNT).toLocaleString()}</> : <></>} other highly ranked predictors are on this team
      </div>
    </div>
  );
}