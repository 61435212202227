import React from "react";
import { BaseTable} from "components/common";
import { IRegistrationApiItem, IRegistrationApiSearchOptions, adminApiReportService } from "services/AdminApiReportService";
import { useAdminRegistrations } from "_hooks/useAdminRegistrations";
import { APIState } from "_constants/APIState";
import { Spinner } from "components/common/Spinner";
import { AdminRegistrationsFilter } from "./Filter";
import { NewYorkTz, ReferralTypeHelper } from "predictagram-lib";
import { useExcelDownload } from "_hooks/useExcelDownload";
import * as Icons from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Url } from "_constants";

export const AdminRegistrationsList: React.FunctionComponent = () => {

  const todayStart = NewYorkTz.getDateMidnight(new Date()).getTime() / 1000;
  const todayEnd = todayStart + (24 * 60 * 60) - 1; // set to last minute of today

  const initialSearch: IRegistrationApiSearchOptions = {
    startTime: todayStart,
    endTime: todayEnd,
  }

  const initialValues = {
    startTime: todayStart,
    endTime: todayEnd,
    usernameLike: undefined,
    userId: undefined,
  }


  const mapper = (item: IRegistrationApiItem) => {
    return {
      ...item,
      phoneNumberConfirmed: item.phoneNumberConfirmed === 1 ? "Yes" : "No"
    }
  }

  const adminRegistrations = useAdminRegistrations(adminApiReportService, initialSearch);
  const excelDownload = useExcelDownload<IRegistrationApiItem>(mapper, 'referrers');

  const getRowType = (row: IRegistrationApiItem) => ReferralTypeHelper.getName(row.refTypeId);
  const refPredictionId = (row: IRegistrationApiItem) => {
    if (!row.refPredictionId) return <></>
    return (
      <div className="d-flex align-items-center gap-1">
      <div>{row.refPredictionId}</div>
      <div><Link target="_blank" to={Url.PUBLIC_STOCK_DETAIL.replace(':predictionId', row.refPredictionId.toString())}><Icons.BoxArrowUpRight /></Link></div>
      </div>
    );
  }

  const refContestId = (row: IRegistrationApiItem) => {
    if (!row.refContestId) { return <></>}
    return (
      <div className="d-flex align-items-center gap-1">
      <div>{row.refContestId}</div>
      <div><Link target="_blank" to={Url.USER_CONTEST.replace(':contestId', row.refContestId.toString())}><Icons.BoxArrowUpRight /></Link></div>
      </div>
    );
  }

  const getUser = (row: IRegistrationApiItem) => {
    return (
      <div>
        <div className="d-flex gap-2 align-items-center">{row.userId} <div><Link target="_blank" to={`${Url.PUBLIC_STOCK_PREDICTOR}/${row.userId}`}><Icons.BoxArrowUpRight /></Link></div></div>
        <div>{row.userUsername}</div>
        <div>{row.userEmail}</div>
      </div>
    );
  }

  const getReferer = (row: IRegistrationApiItem) => {
    if (!row.refById) {
      return <></>
    }
    return (
      <div>
        <div className="d-flex gap-2 align-items-center">{row.refById} <div><Link target="_blank" to={`${Url.PUBLIC_STOCK_PREDICTOR}/${row.refById}`}><Icons.BoxArrowUpRight /></Link></div></div>
        <div>{row.refByUsername}</div>
        <div>{row.refByEmail}</div>
      </div>
    );
  }

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['userId', ['User', getUser]],
    ['phoneNumberConfirmed', ['Confirmed', (row: IRegistrationApiItem) => <>{row.phoneNumberConfirmed === 1 ? "Yes" : "No"}</>]],
    // ['userUsername', ['User Name', undefined]],
    // ['userEmail', ['User Email', undefined]],
    ['refById',['Referer', getReferer]],
    // ['refById', ['Referred By User Id', undefined]],
    // ['refByUsername', ['Referred By User Name', undefined]],
    // ['refByEmail', ['Referred by Email', undefined]],
    ['refTypeId', ['Reference Type', getRowType]],
    ['refPredictionId', ['Prediction ID', refPredictionId]],
    ['refContestId', ['Contest ID', refContestId]],
    ['utmSource', ['UTM Source', undefined]],
    ['ip4', ['IP Address', undefined]],
    ['createdAt', ['Registration Date', (row: IRegistrationApiItem) => <>{(new Date(row.createdAt * 1000)).toLocaleString()}</>]],
  ]);

  const onClickCallback = (searchOptions: IRegistrationApiSearchOptions) => {
    adminRegistrations.setOptions({
      ...searchOptions,
      limit: 3000,
    });
  }  

  return (
    <>
    <div>
      <div className="d-flex justify-content-center align-items-end gap-3">
      <AdminRegistrationsFilter initialValues={initialValues} onClick={onClickCallback} />
      <div className="mb-3">{adminRegistrations.apiState === APIState.LOADED && <>{excelDownload.render(adminRegistrations.referrers)}</>}</div>
      </div>

    </div>

    {adminRegistrations.apiState === APIState.LOADING && <Spinner />}

    {adminRegistrations.error && <div>{(adminRegistrations.error as Error).message}</div>}

    {adminRegistrations.apiState === APIState.LOADED &&
    <BaseTable
      data={adminRegistrations.referrers}
      fieldList={fieldList}
      onSelectClick={() => { }}
      hasRowCounter={true}
    />
    }
    </>
  );
};


