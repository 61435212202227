import React from 'react';
import { useAdminIbkrAccount } from '_hooks/useAdminIbkrAccount';
import { Spinner } from 'components/common/Spinner';


export const IbkrAccountStatus:React.FunctionComponent = () => {

  const api = useAdminIbkrAccount();

  const render = () => {

    if (api.apiState.isLoading) {
      return <Spinner minHeight={15} />
    }
  
    if (api.apiState.isError) {
      return <div className="d-flex justify-content-start align-items-center">
      <div className="bg-red p-3 text-white rounded fw-bold">IBKR API Error: {api.error}</div>
      </div>
    }
  
    if (api.apiState.isLoaded) {
      return <div className="d-flex gap-3 bg-green rounded p-3 align-items-center">
        <div className="fw-bold">IBKR Account Logged In:</div>
        <div className="d-flex gap-3">
        <div>{api.data.selectedAccount}</div>
        <div>{!api.data.isPaper ? "LIVE" : "PAPER"}</div>
        </div>
      </div>
    }
  
    return <></>

  }

  return <div className="d-flex gap-3" style={{height: '25px'}}>
    {render()}
  </div>
}
