import React, {useContext, useRef, useState} from 'react'
import {useSearchParams} from 'react-router-dom';
import {
  AutoTradeHelper,
  AutoTradeManagementTypeEnumHelper,
  AutoTradePositionTypeEnumHelper,
  AutoTradeSecurityTypeEnum, AutoTradeSecurityTypeEnumHelper,
  AutoTradeStatusEnum,
  AutoTradeStatusEnumHelper,
  AutoTradeStatusReasonEnumHelper,
  IAutoTrade,
  TriggerExitTypeEnumHelper,
} from 'predictagram-lib';
import {adminApiServiceCommon} from "../../../services/AdminApiService";
import {MessengerContext} from "../../common/messenger";
import {PresetDateRange} from './filters/PresetDateRangeFilter';

import {
  ArrowClockwise,
  CaretDownFill,
  CaretRightFill,
  Clock,
  Funnel,
  FunnelFill,
  GraphUpArrow,
  Grid3x2,
  PlusCircle,
  SortDown,
  SortUp,
  XCircle
} from 'react-bootstrap-icons'
import {ISortColumn, TableColumn, TradeModel} from 'models/trade.model';
import {ExitOverlay} from './ExitOverlay';
import {LinkAdminTradeSetup} from './LinkAdminTradeSetup';
import {ManualTradeCloseModal} from './ManualTradeCloseModal';
import {colorize, formatUSCurrency, TradeSetupNameWithOptionType} from './Trades';
import {useTrades} from './hooks/useTrades';
import {useAdminAutoTradeSetup} from '_hooks/useAdminAutoTradeSetup';
import {IbkrOrder} from './IbkrOrder';
import {NewYorkDate} from 'components/common/NewYorkDate';
import {ManualTradeAddModal} from './ManualTradeAddModal';
import {TradeLivePrice} from './TradeLivePrice';
import {UrlHelper} from '_utils/UrlHelper';
import {IbkrAccountStatus} from './IbkrAccountStatus';

export const onClickHistory = (signalAlertId: number|null) => {
  // const signalAlertId = TradeModel.getSignalIdFromTradeSetupName(setupName);
  if (signalAlertId) {
    window.open(UrlHelper.getAdminCumeHistory(signalAlertId));
  } else {
    alert('No Signal Alert Detected');
  }
}

export const TradesListPage: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);
  const [searchParams] = useSearchParams();
  const setupId = searchParams.get('setupId');

  const [tradeSetupId, setTradeSetupId] = useState<number | undefined>(undefined);

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const tradeSetupCellRef = useRef<Array<HTMLDivElement | null>>([]);
  const exitTradeOverlayRef = useRef<HTMLDivElement | null>(null);
  const [exitOverlayPosition, setExitOverlayPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });

  const [activeTrade, setActiveTrade] = useState<IAutoTrade | undefined>(undefined);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false);

  const [activeRowTradeId, setActiveRowTradeId] = useState<number | undefined>(undefined);
  const [showRefreshTradeIds, setShowRefreshTradeIds] = useState<number[]>([]);

  // table columns
  const tableColumns: TableColumn[] = [
    ['id', 'ID', undefined],
    ['setupName', 'Trade Setup Name', undefined],
    ['security', 'Security', (a: IAutoTrade, b: IAutoTrade, currentDirection: "asc" | "desc") => {
      const valueA = `${a.ibkr.openOrders.length > 0 && a.ibkr.openOrders[0].optionName}`;
      const valueB = `${b.ibkr.openOrders.length > 0 && b.ibkr.openOrders[0].optionName}`;;
      return TradeModel.compareHelper(valueA, valueB, currentDirection)
    }],
    ['positionTypeId', 'Position Type',undefined],
    ['setupquantity', <div className="text-end">Setup Qty</div>, undefined],
    // ['ibkrOpen', <div className="text-end">IBKR Open Quantity</div>, undefined],
    // ['ibkrClose', <div className="text-end">IBKR Close Quantity</div>, undefined],
    [undefined, <div className="text-end">IBKR Open Price</div>, undefined],
    [undefined, <div className="text-end">IBKR Close Price</div>, undefined],
    [undefined, <div className="text-end">IBKR<br/>P/L</div>, undefined],
    ['createdAt', 'Created At', undefined],
    ['openAt', 'Opened On', undefined],
    [undefined, 'Trade', undefined],
    [undefined, <div className="text-end">Bid Price</div>, undefined],
    [undefined, <div className="text-end">Ask Price</div>, undefined],
    [undefined, <div className="text-end">Last Price</div>, undefined],
    ['managementTypeId', 'Exit Mode', undefined],
    ['closeAt', 'Closed On', (a: IAutoTrade, b: IAutoTrade, currentDirection: "asc" | "desc") => {
      const valueA = `${a.closeAt || ''}`;
      const valueB = `${b.closeAt || ''}`;
      return TradeModel.compareHelper(valueA, valueB, currentDirection)
    }],
    ['statusReasonId', 'Trade Reason', undefined],
    ['closeTriggerTypeId', 'Exit Trigger', undefined],
    ['statusId', 'Status', undefined]
  ].map((fields) => {
    const [name, title, comparator] = fields;
    return {
      name,
      title,
      comparator
    } as TableColumn
  });

  // sort
  const defaultSort: ISortColumn = { tableColumn: tableColumns[0], direction: "asc" };
  const [sortColumn, setSortColumn] = useState<ISortColumn>(defaultSort);

  const tradesApi = useTrades(setupId);
  const tradeSetupsApi  = useAdminAutoTradeSetup();

  const closeTrade = async () => {
    if (!activeTrade) {
      msgrContext.setMessage({ body: 'Missing Trade. Nothing done.' });
      return;
    }
    const id = activeTrade.id;
    setIsDeleting(true);
    try {
      const res = await adminApiServiceCommon.closeTrade(id);
      if (res) {
        msgrContext.setMessage({ body: `Closed #${id}` });
        tradesApi.filters.presetDateRange.set(PresetDateRange.TODAY);
        tradesApi.filters.status.set(undefined);
        tradesApi.reload();
      } else {
        console.error(JSON.stringify(res, null, 2));
        throw Error(`unexpected error trying to close`);
      }
    } catch (error: any) {
      msgrContext.setMessage({ body: `${(error as Error).message}` });
    } finally {
      setIsDeleting(false);
      setShowCloseModal(false);
    }
  }

  const addTrade = async () => {
    if (!activeTrade) {
      msgrContext.setMessage({ body: 'Missing Trade. Nothing done.' });
      return;
    }
    const id = activeTrade.id;
    setIsAdding(true);
    try {
      const res = await adminApiServiceCommon.addTrade(id);
      if (res) {
        msgrContext.setMessage({ body: `Added #${res.id}` });
        tradesApi.filters.presetDateRange.set(PresetDateRange.TODAY);
        tradesApi.filters.status.set(undefined);
        tradesApi.reload();
      } else {
        console.error(JSON.stringify(res, null, 2));
        throw Error(`unexpected error trying to add`);
      }
    } catch (error: any) {
      msgrContext.setMessage({ body: `${(error as Error).message}` });
    } finally {
      setIsAdding(false);
      setShowAddModal(false);
    }
  }

  const _setSortColumn = (tableColumn: TableColumn) => {
    const _sortColumn: ISortColumn = {
      tableColumn,
      direction: "asc"
    }

    if (sortColumn.tableColumn.name === tableColumn.name) {
      if (sortColumn.direction === "asc") {
        _sortColumn.direction = "desc"
      } else {
        _sortColumn.direction = "asc";
      }
    }
    setSortColumn(_sortColumn);
  }

  const renderSortIcon = (name: string) => {
    if (name === sortColumn.tableColumn.name) {
      if (sortColumn.direction === "asc") {
        return <SortUp />
      } else {
        return <SortDown />
      }
    }
    return <></>
  }


  const getSecurityNameFilter = (tradeId:number, securityName:string) => {
    if (!securityName) {
      return <></>
    }
    return <div className="d-flex flex-row flex-wrap gap-1">
      {securityName}
      {/* <div ref={el => securityCellRef.current[index] = el} onMouseEnter={(e) => { onMouseOverSecurity(trade, index); e.stopPropagation() }}>
        <GraphUpArrow style={{ color: 'blue' }} />
      </div> */}

      {/* <Link target="_blank" state={trade} to={UrlHelper.getAdminConsensusOrders(trade.id)}>
        <GraphUpArrow style={{ color: 'blue' }} />
        <pre>{JSON.stringify(trade, null, 2)}</pre>
      </Link> */}
      <div>
        <div style={{width:"20px", float:"left"}} role="button" onClick={()=>window.open(UrlHelper.getAdminConsensusOrders(tradeId))} >
          <GraphUpArrow style={{ color: 'blue' }} />
        </div>
        <div style={{width:"20px", float:"left"}} role="button" onClick={() => tradesApi.toggleSecurityFilter(securityName)}>{tradesApi.filters.security.value ? <FunnelFill color='gray' /> : <Funnel color='blue' />}</div>
      </div>
    </div>
  }


  const onMouseOverTradeCell = (tradeId: number, index: number) => {
    const overlay = exitTradeOverlayRef.current;
    const cell = tradeSetupCellRef.current[index];

    if (!overlay || !cell) { return; }

    const cellRect = cell.getBoundingClientRect();
    const overlayRect = overlay.getBoundingClientRect();
    let top = cellRect.top;
    const left = cellRect.left + (cellRect.width * 0.5);

    const overlayHeight = 350;
    if (top + window.scrollY + overlayHeight > window.innerHeight) {
      top = window.innerHeight - overlayHeight;
    }

    setTradeSetupId(tradeId);
    setExitOverlayPosition({ top: top, left })
  }


  const confirmAdd = (trade: IAutoTrade) => {
    setActiveTrade(trade);
    setShowAddModal(true);
  }

  const confirmClose = (trade: IAutoTrade) => {
    setActiveTrade(trade);
    setShowCloseModal(true);
  }

  return (
    <>
      {activeTrade && <ManualTradeAddModal
        show={showAddModal}
        handleClose={() => setShowAddModal(false)}
        title='Add Trade'
        onConfirm={addTrade}
        trade={activeTrade}
        isSubmitting={isAdding}
      />}

      {activeTrade && <ManualTradeCloseModal
        show={showCloseModal}
        handleClose={() => setShowCloseModal(false)}
        title='Close Trade'
        onConfirm={closeTrade}
        trade={activeTrade}
        isSubmitting={isDeleting}
      />}

      <div>
        <div className="d-flex gap-3 justify-content-start align-items-center">
          <div className="page-title my-3">Trades</div>
          <IbkrAccountStatus />
        </div>


        <tradesApi.filterComponent />

        <div className="fixed-table-head admin-trades-list">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                {tableColumns.map((tableColumn, index) => {
                  if (!tableColumn.name) {
                    return <th key={`th-${index}`}><>{tableColumn.title}</></th>
                  }
                  return <th key={`th-${index}`} role="button" onClick={() => _setSortColumn(tableColumn)}>{tableColumn.title} {renderSortIcon(tableColumn.name)}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {tradesApi.filteredData.sort((a, b) => {
                if (sortColumn.tableColumn.comparator) {
                  return sortColumn.tableColumn.comparator(a, b, sortColumn.direction);
                }
                // default comparator if no comparator
                const valueA = a[sortColumn.tableColumn.name as keyof IAutoTrade]?.toString() as string;
                const valueB = b[sortColumn.tableColumn.name as keyof IAutoTrade]?.toString() as string;
                return TradeModel.compareHelper(valueA, valueB, sortColumn.direction);
              })
                .map((trade, index) => {
                  return <>
                  <tr key={`trade-${trade.id}`}>
                    <td>
                      <div className="d-flex gap-1 align-items-center" role="button" onClick={()=>{setActiveRowTradeId(trade.id === activeRowTradeId ? undefined : trade.id)}}>
                      {trade.id === activeRowTradeId ? <CaretDownFill /> : <CaretRightFill />}
                      {trade.id}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex flex-wrap align-items-center">
                        <div>
                          <TradeSetupNameWithOptionType trade={trade} api={tradeSetupsApi} />
                        </div>
                        <div className="flex-nowrap">
                          <div>
                            <div style={{width:"20px", float:"left"}}  role="button" className="text-primary" onClick={()=>onClickHistory(trade.setupSignalAlertId)} title="Cumulative Chart History"><Clock /></div>
                            <div style={{width:"20px", float:"left"}} ref={el => tradeSetupCellRef.current[index] = el} onMouseEnter={(e) => { onMouseOverTradeCell(trade.setupId, index); e.stopPropagation() }}><Grid3x2 style={{ color: 'blue' }} /></div>
                            <div style={{width:"20px", float:"left"}} role="button" onClick={() => tradesApi.toggleTradeSetupFilter(trade.setupId)}>{tradesApi.filters.setup.value ? <FunnelFill color='gray' /> : <Funnel color='blue' />}</div>
                            <div style={{width:"20px", float:"left"}}><LinkAdminTradeSetup tradeSetupId={trade.setupId} /></div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="text-nowrap">
                      <div className="d-flex flex-row flex-nowrap">
                        {getSecurityNameFilter(trade.id, TradeModel.getSecurityName(trade))}
                      </div>
                    </td>
                    <td>{AutoTradePositionTypeEnumHelper.names.get(trade.positionTypeId)}</td>
                    <td className="text-end">{trade.openQuantity}</td>
                    <td className="text-end">{formatUSCurrency(trade.ibkr.openPriceAvg)}</td>
                    <td className="text-end">{formatUSCurrency(trade.ibkr.closePriceAvg)}</td>
                    <td className="text-end">{colorize(formatUSCurrency(AutoTradeHelper.calcRealizedProfitLossTradeIbkr(trade)))}</td>
                    <td><NewYorkDate dateInMs={trade.createdAt * 1000} /></td>
                    <td><NewYorkDate dateInMs={trade.openAt * 1000} /></td>
                    <td>
                      <div className="d-flex gap-2">
                        {trade.ibkr.openOrders.length > 0 && <button className="btn btn-primary text-13" onClick={() => confirmAdd(trade)}><PlusCircle title="Add Trade" /></button>}
                        {trade.statusId === AutoTradeStatusEnum.ACTIVE &&
                          <button className="btn text-13 bg-red text-white" onClick={() => confirmClose(trade)}><XCircle title="Close Trade" /></button>
                        }
                      </div>
                    </td>
                      {trade.statusId === AutoTradeStatusEnum.ACTIVE ?
                        <>{tradesApi.renderLivePrices(trade)}</>
                        :
                        <>
                          {showRefreshTradeIds.includes(trade.id) ?
                            <TradeLivePrice trade={trade} />
                            :
                            <td colSpan={3}>
                              {trade.ibkr.openOrders.length > 0 &&
                                <div className="d-flex justify-content-center">
                                  <button type="button" className="btn btn-primary" onClick={() => setShowRefreshTradeIds([
                                    ...showRefreshTradeIds,
                                    trade.id
                                  ])}><ArrowClockwise /></button>
                                </div>
                              }
                            </td>
                          }
                        </>
                      }
                    {/* {tradesApi.renderLivePrices(trade)} */}
                    <td>{AutoTradeManagementTypeEnumHelper.names.get(trade.managementTypeId)}</td>
                    <td>{trade.closeAt ? <NewYorkDate dateInMs={trade.closeAt * 1000} /> : ''}</td>
                    <td>
                      <div className="d-flex gap-1">
                        {AutoTradeStatusReasonEnumHelper.names.get(trade.statusReasonId)}
                        <div role="button" onClick={() => tradesApi.filters.statusReason.value ? tradesApi.filters.statusReason.set(undefined) : tradesApi.filters.statusReason.set(trade.statusReasonId)}>{tradesApi.filters.statusReason.value ? <FunnelFill color='gray' /> : <Funnel color='blue' />}</div>
                      </div>
                    </td>
                    <td>{TriggerExitTypeEnumHelper.names.get(trade.closeTriggerTypeId)}</td>
                    <td>{AutoTradeStatusEnumHelper.names.get(trade.statusId)}</td>
                  </tr>
                  {(trade.ibkr.openOrders.length > 0 || trade.ibkr.closeOrders.length > 0) && trade.id === activeRowTradeId && <tr>
                      <td colSpan={21}>
                        <div className="d-flex flex-row gap-2">
                          {trade.ibkr.openOrders.length > 0 &&
                            <div className="d-flex flex-column border p-3">
                              <div className="fw-bold">Open Orders</div>
                              <IbkrOrder orders={trade.ibkr.openOrders} />
                            </div>
                          }
                          {trade.ibkr.closeOrders.length > 0 &&
                            <div className="d-flex flex-column border p-3">
                              <div className="fw-bold">Close Orders</div>
                              <IbkrOrder orders={trade.ibkr.closeOrders} />
                            </div>
                          }
                        </div>
                    </td>
                  </tr>
                    }
                  </>
                })}
            </tbody>
          </table>
        </div>

        <div ref={exitTradeOverlayRef} onMouseLeave={(e) => {
          setTradeSetupId(undefined);
          e.stopPropagation();
        }} style={{ position: "absolute", top: exitOverlayPosition.top, left: exitOverlayPosition.left }}>
          {tradeSetupId && <ExitOverlay tradeSetupId={tradeSetupId} onEscape={() => setTradeSetupId(undefined)} />}
        </div>

      </div>
    </>
  );
}
