import React from 'react'
import { DropdownFilter, IDropdownFilterItem } from 'components/common/DropDownFilter/DropdownFilter'

import { IconLabel } from '../IconLabel';
import { Fire, Globe, People } from 'react-bootstrap-icons';
import { useDashboardSettings } from '_hooks/useDashboardSettings';
import { StorageKey } from '_constants';

export type UserTypeDropdownItemType = IDropdownFilterItem<UserTypeFilterEnum, UserTypeFilterEnum>;

export enum UserTypeFilterEnum  {
  TOP = 1,
  FOLLOWING = 2,
  EVERYONE = 3
}

export const userTypeDropDownItemsMap: Map<UserTypeFilterEnum, UserTypeDropdownItemType> = new Map<UserTypeFilterEnum, UserTypeDropdownItemType>([
  [UserTypeFilterEnum.TOP, { key: UserTypeFilterEnum.TOP, value: UserTypeFilterEnum.TOP, label: <IconLabel icon={<Fire />} label='Top Users' /> }],
  [UserTypeFilterEnum.FOLLOWING, { key: UserTypeFilterEnum.FOLLOWING, value: UserTypeFilterEnum.FOLLOWING, label: <IconLabel icon={<People />} label='Following' /> }],
  [UserTypeFilterEnum.EVERYONE, { key: UserTypeFilterEnum.EVERYONE, value: UserTypeFilterEnum.EVERYONE, label: <IconLabel icon={<Globe />} label='Everyone' /> }],
]
);

export const userTypeDropDownItems: UserTypeDropdownItemType[] = Array.from(userTypeDropDownItemsMap).map((item) => {
  return item[1]
})

export const getUserTypeDropDownItem = (userTypeFilter: UserTypeFilterEnum) => {
  return userTypeDropDownItemsMap.get(userTypeFilter) || userTypeDropDownItems[0];
}

interface IProps {
  initialValue: UserTypeFilterEnum
  setValue: (value: UserTypeFilterEnum) => void,
  className?: string,
}

export const UserTypeDropDownFilter:React.FunctionComponent<IProps> = ({initialValue, setValue, className}) => {

  const items = userTypeDropDownItems;
  const [dashboardSettings, setDashboardSettings] = useDashboardSettings(localStorage, StorageKey.DASHBOARD_SETTINGS);

  const _setValue = (value: UserTypeFilterEnum) => {
    setDashboardSettings({
      ...dashboardSettings,
      userTypeFilter: value,
    })
    setValue(value);
  }

  return (
    <DropdownFilter<UserTypeFilterEnum, UserTypeFilterEnum> items={items} initialSelectedKey={initialValue} onSelect={(item)=>_setValue(item)} className={className}/>
  )
}
