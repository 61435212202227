import React from 'react';
import { DropDown } from 'components/common/form/DropDown';
import { IKeyValue } from 'interfaces';
import { contestTypeNames } from '_constants/ContestTypeNames';
import { ContestTypeEnum } from 'predictagram-lib';

export const ContestTypeDropDown: React.FunctionComponent<
{
  errors: any,
  touched: any,
  label: string,
  name: string,
  [x: string] : any,
}
> = ({errors, touched, label, name, ...rest}) => {

const defaultItem: IKeyValue<number> = {
  key: ContestTypeEnum.PUBLIC, value: contestTypeNames.get(ContestTypeEnum.PUBLIC) || ''
}

return <>
    <DropDown
    errors={errors} 
    touched={touched} 
    label={label} 
    name={name}
    defaultItem={defaultItem}
    enumMap={contestTypeNames} 
    {...rest}/>
  </>
}
