import React, { useContext } from 'react';
import { config } from 'config';
import { Url } from '_constants';
import { MessengerContext, Severity } from './messenger';
import { RWebShare } from "react-web-share";
import { AccountContext } from './AccountContext';
import { SearchParams } from '_constants/SearchParams';
import { ShareBase } from './ShareBase';

export const SharePredictionTypeStats: React.FunctionComponent<{ userId: number, symbol: string, username: string }> = ({ userId, symbol, username }) => {

  const msgrContext = useContext(MessengerContext);
  const acctContext = useContext(AccountContext);
  const baseUrlPath = `${config.wwwDomain}${
    Url.USER_STATS_PREDICTION_TYPE
      .replace(':userId', userId.toString())
      .replace(':symbol', symbol)
    }`;
  const qstrings: string[] = [];

  if (acctContext?.isLoggedIn()) {
    qstrings.push(`${SearchParams.UI_REFERRAL_USER_ID}=${acctContext.account.id}`);
  }

  const url = qstrings.length > 0 ? `${baseUrlPath}?${qstrings.join("&")}` : baseUrlPath;

  const onCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(url);
      msgrContext.setMessage({ body: 'Url was copied to clipboard!' });
    } catch (error: any) {
      msgrContext.setMessage({ body: `Could not copy: ${error.message}` }, true, Severity.FATAL);
      console.error({ error });
    }
  }

  return (
    <ShareBase onCopyLink={onCopyLink} title='Share' className="my-2">
      {({ setShow }) => {
        return <RWebShare
          data={{
            text: `Check out ${username}'s stock prediction stats on www.predictagram.com.`,
            url: url,
            title: `Stock Prediction Accuracy`,
          }}
          onClick={() => setShow(false)}
        >
          <div role="button">Share via...</div>
        </RWebShare>
      }}
    </ShareBase>
  );
}


