import React from 'react'
import { RoundBackButton } from 'components/common/buttons/RoundBackButton'
import { useNavigate } from 'react-router-dom'
import { Bell } from 'components/common/Icons';

export const NotificationHeader: React.FunctionComponent<{ title: string, children: any }> = ({ title, children }) => {
  const navigate = useNavigate();

  return (
    <div className="mx-3">
      <div className="d-flex justify-content-start gap-1 mb-4 fw-bold"><Bell /> Notifications</div>

      <div className="d-flex align-items-center gap-2">
        <RoundBackButton onClick={() => navigate(-1)} /><div className="fw-bold text-18 my-3">{title}</div>
      </div>
      {children}
    </div>
  )
}
