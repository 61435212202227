import React from "react";
import { Helper } from "_utils";
import { IQuote } from "./IQuote";

export const QuoteAsOf:React.FunctionComponent<{quote: IQuote}> = ({quote}) => {
  const datetime = Helper.roundUpToClosing(Helper.quoteDateFormat(new Date(quote.t * 1_000)));
  const justdate = datetime.replace('EST', '');

  if (!quote.c) {
    return <></>
  }

  return (
    <div className="text-12 text-lighter-gray">
    <span className="text-lavender fw-bold">{quote.c.toFixed(2)}</span> as of <span className="text-gray">{justdate} <span className="text-11 text-light-gray">EST</span></span>
    </div>
  );
}