import { IApiResponse, useApi } from "./useApi";
import { IAutoTradeStockLimit, adminAutoTradeStockLimitService } from "services/AdminApiService";

export interface IAdminAutoTradeStockLimitApi {
  api: IApiResponse<IAutoTradeStockLimit[], null>;
  update: (item: IAutoTradeStockLimit, quantityOpenMax: number | null) => Promise<IAutoTradeStockLimit>
}

export function useAdminAutoTradeStockLimit (): IAdminAutoTradeStockLimitApi {
  const load = async () => {
    const data =  await adminAutoTradeStockLimitService.getAll(true, true);
    return data;
  }

  const update = async (item: IAutoTradeStockLimit, quantityOpenMax: number | null) => {
    try {
      const updated = await adminAutoTradeStockLimitService.update({ quantityOpenMax } as IAutoTradeStockLimit, item.stockSymbolId);
      return updated;
    } catch (error: any) {
      console.error(error);
      throw new Error('could not update: ', error.message);
    }
  }

  return {
    api: useApi<IAutoTradeStockLimit[], null>(load, null),
    update
  }
}

