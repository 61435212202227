import { AccountContext } from 'components/common';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';


export const UserLogout:React.FunctionComponent = () => {
  const accountContext = useContext(AccountContext);
  const navigate = useNavigate();

  useEffect(()=>{
    const _load = async () => {
      await accountContext?.logout();
      navigate(Url.DEFAULT_HOME);
    }
    _load();
  }, [])

  return (
    <>
    You have been logged out.
    </>
  );
}