import { APIState } from "_constants/APIState";
import { IQuote } from "components/public/Stock/IQuote";
import { IPrediction } from "interfaces/IPrediction";
import { useCallback, useEffect, useRef, useState } from "react";
import { PredictionService } from "services";

export function useQuoteAtPredictionTime (prediction: IPrediction, service: PredictionService) {

  const [ quote, setQuote ] = useState<IQuote | undefined>(undefined);
  const [ apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<Error | undefined>(undefined);

  const serviceRef = useRef(service);

  const load = useCallback(async ()=>{
    try {

      if (!prediction.valueTime) {
        throw Error('missing valueTime');
      }

      setApiState(APIState.LOADING);
      // let valueTimeDate = new DateEx(prediction.valueTime * 1_000);
      // if (!StockHelper.isMarketOpen(valueTimeDate) || StockHelper.isPreMarketHours(valueTimeDate) || NewYorkTz.isWeekend(valueTimeDate)) {
      //   const prevDay = StockHelper.findPreviousTradingDay(valueTimeDate);
      //   valueTimeDate = StockHelper.workingHours(prevDay).end;
      // } else if (StockHelper.isPostMarketHours(valueTimeDate)) {
      //   valueTimeDate = StockHelper.workingHours(valueTimeDate).end;
      // } 
      // const quotes = await serviceRef.current.getBarsData(prediction.stockSymbol, valueTimeDate, valueTimeDate, false);
      // if (quotes.length === 0) {
      //   throw Error('Could not find quote for prediction time ' + valueTimeDate.toLocaleString());
      // }

      // see #794. valueStock was added.
      setQuote({
        c: prediction.valueStock,
        t: prediction.createdAt
      } as IQuote);
      setApiState(APIState.LOADED);
    } catch (error: any) {
      setApiState(APIState.ERROR);
      setError(error as Error);
    }
  }, [serviceRef, prediction])

  useEffect(()=>{
   load();
  }, [load])

  return {quote, apiState, error, status: {
    loading: apiState === APIState.LOADING,
    loaded:  apiState === APIState.LOADED,
    idle: apiState === APIState.IDLE,
    error: apiState === APIState.ERROR
  }};

}