export enum SignalAlertStatusEnum {
    ACTIVE              = 1,
    PAUSED              = 2,
    ARCHIVED            = 3,
}

export class SignalAlertStatusEnumHelper {
    static readonly names: Map<SignalAlertStatusEnum, string> = new Map([
        [SignalAlertStatusEnum.ACTIVE, 'Active'],
        [SignalAlertStatusEnum.PAUSED, 'Paused'],
        [SignalAlertStatusEnum.ARCHIVED, 'Archived'],
    ]);
}
