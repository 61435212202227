import React from 'react'
import { Helmet } from 'react-helmet';
import { PurpleLogoLink } from '../../common/PurpleLogoLink';
import { PixelHelper } from '_utils/PixelHelper';


export const surveyUrl = 'https://www.surveymonkey.com/r/8NB35BD';

export const navigateToSurvey = () => {
  PixelHelper.trackGAEvent('click_crowd_fund_lp_reserve_my_place');
  window.location.href = surveyUrl;
}

const CrowdFunding = () => {


  const imageStyle: React.CSSProperties = {
    height: "48px",
    width: "48px",
  }

  const mainTitleStyle: React.CSSProperties = {
    fontWeight: 900,
    fontSize: '2rem',
    lineHeight: '2.4375rem',
  }

  const rightStyle: React.CSSProperties = {
    background: "linear-gradient(159.01deg, #1C1C1C 12.15%, rgba(0, 0, 0, 0) 185.19%)"
  }

  const graphicStyle: React.CSSProperties = {
    width: "100%"
  }

  const LeftSide = () => {
    return (
      <div className="px-3 pt-1 pt-md-3 mt-1 mb-3 me-1 me-md-3 d-flex flex-column justify-content-start align-items-start gap-3">

        <div className="text-18 text-lavender" style={{
          fontFamily: "Barlow Condensed",
          fontWeight: "500",
        }}>GET A PIECE OF PREDICTAGRAM</div>

        <div style={mainTitleStyle}>
          Invest in our mission to predict the market using crowd and artificial intelligence
        </div>

        <div className="text-16 opacity-65">
          Our crowd-sourced predictions are already generating accurate predictive indicators, and we're just getting started.
        </div>

        <div className="mb-3">
          <img style={graphicStyle} src="/images/crowd-funding/graphic.svg" alt="Crowd Funding" />
        </div>
      </div>

    );
  }


  const RightSide = () => {


    return (

      <div className="p-4 py-4 p-md-5" style={rightStyle}>
        <div className="text-24 fw-bold mt-1">We're considering opening up <span className="text-lavender">ownership</span> of Predictagram to our users.</div>

        <div className="text-18 my-4">Invest in our next stage as we continue to innovate in the following areas:</div>

        <div className="d-flex flex-column gap-3">

          <div className="d-flex align-items-center gap-3">
            <img style={imageStyle} src="/images/crowd-funding/icon-ai-ci.svg" alt="AI and CI" />
            <span className="text-18 opacity-65">Combining AI and CI (Crowd Intelligence) to accurately predict market movement</span>
          </div>

          <div className="d-flex align-items-center gap-3">
            <img style={imageStyle} src="/images/crowd-funding/icon-monetization.svg" alt="AI and CI" />
            <span className="text-18 opacity-65">Developing a unique financial creator monetization platform</span>
          </div>

          <div className="d-flex align-items-center gap-3">
            <img style={imageStyle} src="/images/crowd-funding/icon-eye.svg" alt="AI and CI" />
            <span className="text-18 opacity-65">Creating the most transparent meritocratic online finance community</span>
          </div>


          <div className="mt-3 my-2 text-14 align-self-center d-flex flex-column flex-md-row">
            <span>Let us know if you're interested in investing. </span><span className="align-self-center align-self-md-start">(No Obligation)</span>
          </div>

        </div>

        <div className="my-1">
          <button className="btn bg-lavender text-black w-100 p-3" onClick={() => navigateToSurvey()}>Reserve My Place</button>
        </div>
      </div>
    );

  }

  return (
    <div className="container g-0">
      <div className="row">
        <div className="px-4 me-3 my-3">
          <PurpleLogoLink />
        </div>
      </div>

      <div className="row g-0">
        <div className="col-12 col-md-6">
          <LeftSide />
        </div>

        <div className="col-12 col-md-6">
          <RightSide />
        </div>
      </div>
    </div>
  );
}

export const CrowdFundingPage: React.FunctionComponent = () => {

  return (
    <>
      <Helmet>
        <title>Predictagram: Crowd Funding</title>
      </Helmet>

      <div className="g-0">
        <div className="d-flex h-100 flex-column justify-content-center" style={{ minHeight: "100vh" }}>
          <CrowdFunding />
        </div>
      </div>
    </>
  )
}
