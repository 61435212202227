import React from 'react';
import { useContestRankings } from '_hooks/useContestStats';
import { Spinner } from 'components/common/Spinner';
import { IContest } from 'interfaces/IContest';
import { IContestStats, userContestApiService } from 'services/common/ContestApiService';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar';

export const ContestWinner: React.FunctionComponent<{ contest: IContest }> = ({ contest }) => {
  const { items: stats, loaded, error } = useContestRankings(contest.id, userContestApiService);

  const renderer = (user: IContestStats) => {
    const { userId, avatarUrl, username } = user;
    const profile: IPublicProfile = {
      userId,
      avatarUrl,
      username
    } as IPublicProfile;

    return (
      <div className="contest-winner">
        <div className="contest-winner-image"><Avatar profile={profile} size={AvatarSizeEnum.MEDIUM} /></div>
        <div className="contest-winner-username">@{user.username}</div>
        <div className="contest-winner-score d-flex justify-content-center align-items-center gap-1"><span role="img" aria-label="accuracy">🎯</span> {user.score !== null ? `${(user.score * 100).toFixed(0)}%` : "TBD"}</div>
      </div>
    );
  }

  return (<>
    {!loaded && !error && <Spinner />}
    {error && <div>{error.message}</div>}
    {loaded && stats.length === 0 && <div>No Winner.</div>}
    {loaded && stats.length > 0 && stats.splice(0, 1).map(renderer)}
  </>
  );
}