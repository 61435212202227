import React, { useContext } from 'react';
import { AccountContext, Footer } from 'components/common';
import { useModalLoginRegisterPassword } from '_hooks/useModalLoginRegisterPassword';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';

export const FooterSignupLogin: React.FunctionComponent<{ isFixed?: boolean, hasDisclaimer?: boolean }> = ({ isFixed = false, hasDisclaimer = true }) => {
  const acctContext = useContext(AccountContext);
  const loginRegisterPassword = useModalLoginRegisterPassword();
  const navigate = useNavigate();

  return (
    <>
      {loginRegisterPassword.render()}
      {!acctContext?.isLoggedIn() &&
        <div className={`container-fluid g-0 footer-signup-login ${isFixed ? 'footer-signup-login-fixed' : ''}`}>
          <div className="d-flex flex-row justify-content-center gap-3 my-3">
            <div><div className="btn btn-login btn-secondary" role="button" onClick={() => { loginRegisterPassword.login.show(true) }} >Log in</div></div>
            <div><div className="btn btn-signup btn-primary" role="button" onClick={() => { loginRegisterPassword.register.show(true) }}>Sign up</div></div>
          </div>
          {hasDisclaimer ? (
            <Footer />
          ) : (
            <div className="d-flex justify-content-center align-items-center gap-3 mb-3">
            <div className="text-smaller text-lighter-charcoal">&copy; {new Date().getFullYear()} Predictagram.com</div>
            <div role="button" className="text-smaller text-lighter-charcoal" onClick={() => navigate(Url.USER_TERMS)}>Terms and Conditions</div>
            <div role="button" className="text-smaller text-lighter-charcoal" onClick={() => navigate(Url.USER_PRIVACY_POLICY)}>Privacy Policy</div>
            </div>
          )
          }

        </div>
      }
    </>
  )
}