import {IParsedOption, OptionPattern} from "./OptionPattern";
import {OptionType} from "predictagram-lib";
import {TradegramDateHelper} from "./TradegramDateHelper";

export class OptionPatternSymbolPrice extends OptionPattern {

  protected pattern = /^([A-Za-z]+)\s\$?(\d+(?:\.\d{1,3})?)\s(CALL|PUT|c|p)\s((?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)\s\d{1,2}\/\d{2})$/i; //SPY $435 CALL NOV 10/23
  
  parse(optionName: string) {
    const match = optionName.match(this.pattern);
    if (!match) {
      throw new Error('Invalid option contract name format');
    }

    const [, symbol, price, optionType, date] = match;
    const parsedOption: IParsedOption = {
      symbol: symbol.toUpperCase(),
      date: TradegramDateHelper.convertMMMDDYYToDateUs(date.replaceAll("/", " ")).getTime() / 1000,
      optionType: optionType.toLocaleUpperCase().slice(0,1) === 'C' ? OptionType.CALL : OptionType.PUT,
      price: parseFloat(price)
    }
    return parsedOption;
  }
}
