import { useAdminAutoTradePriceLookup } from '_hooks/useAdminAutoTradePriceLookup';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import { AutoTradeStatusEnum, IAutoTrade } from 'predictagram-lib'
import React, { useEffect } from 'react'
import { calcUnrealizedProfitLossOptionFormatted, formatUSCurrency } from './Trades';
import { ArrowClockwise } from 'react-bootstrap-icons';

export const TradeLivePrice:React.FunctionComponent<{trade: IAutoTrade}> = ({trade}) => {
  
  const priceApi = useAdminAutoTradePriceLookup(trade.id);

  useEffect(()=>{

  }, [priceApi.apiState])

  if (priceApi.apiState.isLoading) {
    return <td colSpan={3}><Spinner minHeight={15} /></td>
  }

  const updatePrice = () => {
    priceApi.reload();    
  }

  if (priceApi.apiState.isLoaded) {
    return <>
    <td className="text-end">{formatUSCurrency(priceApi.data.bidPrice)}</td>
    <td className="text-end"><div className="d-flex flex-column gap-1 align-items-end">
      {formatUSCurrency(priceApi.data.askPrice)}
      <button type="button" className="btn btn-primary" onClick={() => updatePrice()}><ArrowClockwise /></button>
      </div></td>
    <td className="text-end">
        <div className="d-flex flex-column gap-1 align-items-end">
          {formatUSCurrency(priceApi.data.lastPrice)}
          {[AutoTradeStatusEnum.ACTIVE, AutoTradeStatusEnum.CLOSED].includes(trade.statusId) && <div className="text-10 my-1">
            {calcUnrealizedProfitLossOptionFormatted(trade, priceApi.data.lastPrice, true)}
          </div>}
        </div>
      </td>
    </>
  }

  if (priceApi.apiState.isError) {
    return <td colSpan={3}><ErrorMessage>{priceApi.error}</ErrorMessage></td>
  }

  return <td colSpan={3}></td>
}
