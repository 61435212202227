import React, { useContext, useEffect, useRef, useState } from 'react';
import { IStockSymbol } from 'interfaces/IStockSymbol';
import { MakePredictionForm } from './MakePredictionForm';
import { SymbolList } from './SymbolList';
import { IPrediction } from 'interfaces/IPrediction';
import { AccountContext } from 'components/common';
import { MessengerContext, Severity } from 'components/common/messenger';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { ShortRegisterForm } from '../account/ShortRegisterForm';
import { CloseButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';
import { PredictionTypeSelect } from './PredictionType';
import { IntradayHelper, NewYorkTz, PredictionTypeEnum, StockHelper } from 'predictagram-lib';
import * as Icons from 'components/common/Icons';
import { ModalHelpTypeSelect, MoreInfoTypeSelect } from 'components/public/Help/ModalHelpTypeSelect';
import { PredictionContext } from 'components/common/PredictionContext';
import { useUniqueUserId } from '_hooks/useUniqueUserId';
import { predictionTypeNames } from '_constants/PredictionTypeEnum';
import { PredictionCountHelper as pch } from "_utils/PredictionCountHelper";
import { PixelHelper } from '_utils/PixelHelper';
import { useUtmSource } from '_hooks/useUtmSource';
import { MessageHelper } from '_utils/MessageHelper';
import { PostPredictionChart } from './PostPredictionChart';

export enum WizardStep {
  PICK_SYMBOL = 1,
  CHOOSE_PREDICTION_TYPE = 2,
  SET_PREDICTION = 3,
  REGISTER_OR_LOGIN = 4,
  WAITING_FOR_RETRY = 5,
}


export const PredictionWizard: React.FunctionComponent<{
  onClickClose: () => void,
  onComplete: () => void,
  onRetrySignup: () => void,
  showShareOnSavePrediction?: boolean,
  initialSymbol?: IStockSymbol,
  initialWizardStep?: WizardStep,
  initialPredictionType?: PredictionTypeEnum,
  initialValueAt?: Date, // @RT: added for contest valueAt override. clean this up.
}> = ({ onClickClose, onComplete, onRetrySignup, initialSymbol, initialWizardStep, initialPredictionType, initialValueAt, showShareOnSavePrediction = true }) => {

  const uniqueUserId = useUniqueUserId();

  const [currentStep, setCurrentStep] = useState<WizardStep>(initialWizardStep || WizardStep.PICK_SYMBOL);
  const [symbol, setSymbol] = useState<IStockSymbol | undefined>(initialSymbol);
  const [prediction, setPrediction] = useState<IPrediction | undefined>(undefined);

  const refChartContainer = useRef<HTMLDivElement>(null);
  const [cardParentWidth, setCardParentWidth] = useState<number>(0);

  const [predictionType, setPredictionType] = useState<PredictionTypeEnum>(initialPredictionType || PredictionTypeEnum.VALUE_CLOSE);
  const [predictionValueAt, setPredictionValueAt] = useState<Date | null>(initialValueAt || null);

  const [showTypeSelectHelp, setShowTypeSelectHelp] = useState<boolean>(false);
  const [todayPredictions, setTodaysPredictions] = useState<IPrediction[]>([]);

  const acctContext = useContext(AccountContext);
  const msgrContext = useContext(MessengerContext);
  const predictionContext = useContext(PredictionContext);
  const navigate = useNavigate();

  const isDirectToTool: boolean = !!initialWizardStep;

  const today = new Date();
  const utmSource = useUtmSource();

  useEffect(() => {

    if (refChartContainer.current) {
      setCardParentWidth(refChartContainer.current.offsetWidth);
    } else {
    }

  }, []);

  const onSelectStockSymbol = async (s: IStockSymbol) => {

    // "symbolNames": ["SPY"],
    // "predictionTypes": [1],
    // "userId": 4692409027639446

    if (acctContext?.isLoggedIn()) {
      const search: ISearchOptions = {
        symbolNames: [s.symbolName],
        userId: acctContext?.account.id,
      }
      const predictions = await userPredictionApiService.getAllPublic(search);
      const now = new Date();
      const todayPredictions = predictions.filter((p) => p.valueAt > (now.getTime() / 1000));
      setTodaysPredictions(todayPredictions);
    }
    setSymbol(s);
    setCurrentStep(WizardStep.CHOOSE_PREDICTION_TYPE);
  }

  const onSaveCallback = async (prediction: IPrediction) => {
    try {
      if (!acctContext?.account) {
        const newPrediction = await savePrediction(prediction, uniqueUserId); // anon. include unique user id
        setPrediction(newPrediction);
        setCurrentStep(WizardStep.REGISTER_OR_LOGIN);
      } else {
        await savePrediction(prediction);

        // update today's counter
        pch.add(NewYorkTz.getDateMidnight(new Date()).getTime(), localStorage);

        // if (isDirectToTool) {
        //   // if it came from landing page, navigate to symbol page
        //   navigate(Url.PUBLIC_STOCK_PREDICTIONS.replace(':stockSymbol', prediction.stockSymbol))
        // }
      }
    } catch (error: any) {
      if (error.message.includes("You have active prediction")) {
        const predTypeName = predictionTypeNames.get(prediction.typeId || 0);
        if (!!acctContext?.isLoggedIn()) {
          msgrContext.setMessage({ body: `You've already made a ${predTypeName} Prediction for ${prediction.stockSymbol} today. ${!isDirectToTool ? 'Please try another.' : ''} ` }, true, Severity.FATAL);
        } else {
          setCurrentStep(WizardStep.WAITING_FOR_RETRY);
          const signup = <>
            You've already made a prediction of this type for {prediction.stockSymbol} today. Please sign up for FREE to track your accuracy and see what others are predicting.
            <div role="button" className="btn btn-primary my-3" onClick={() => {
              msgrContext.clearMessage();
              onRetrySignup();
              setCurrentStep(WizardStep.REGISTER_OR_LOGIN);
            }}>Sign up</div>
          </>

          msgrContext.setMessage({ body: signup }, true, Severity.FATAL);
        }
      } else {
        msgrContext.setMessage({ body: MessageHelper.translate(error.message) }, true, Severity.FATAL);
        onComplete();
      }
    }
  }

  const savePrediction = async (prediction: IPrediction, uniqueUserId?: string) => {
    try {
      const result = await predictionContext?.addPrediction(prediction, uniqueUserId); //userPredictionApiService.create(prediction);
      PixelHelper.submittedPrediction(utmSource);
      if (!uniqueUserId) {
        if (showShareOnSavePrediction) {
          msgrContext.setMessage({ title: 'Prediction Submitted!', body: <>
          {result && StockHelper.isMarketOpen(new Date()) && result.typeId !== PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D &&
            <>
            <PostPredictionChart prediction={result} onClose={()=>msgrContext.clearMessage()}/>
            </>
          }
          </> }, true);
        }
        onComplete();
      }
      return result;
    } catch (error: any) {
      throw Error(MessageHelper.translate(error.message));
    }
  }

  /**
   * this is called after a succesful registration
   */
  const onRegister = async () => {
    try {
      if (!prediction) {
        throw Error('Unexpected error trying to save. Missing prediction.');
      }
      navigate(Url.PUBLIC_STOCK_PREDICTIONS.replace(':stockSymbol', prediction.stockSymbol));
      onComplete();
      msgrContext.setMessage({ body: "Successfully added your prediction. Please check your email to complete your registration." });
    } catch (error: any) {
      msgrContext.setMessage({ body: `Registration succeeded but could not save prediction: ${error.message}` }, true, Severity.FATAL)
      onComplete();
    }
  }

  const onLogin = async () => {
    try {
      if (!prediction) {
        throw Error('Unexpected error trying to save. Missing prediction.');
      }
      navigate(Url.PUBLIC_STOCK_PREDICTIONS.replace(':stockSymbol', prediction.stockSymbol));
      onComplete();
      msgrContext.setMessage({ body: "Successfully added your prediction." });
    } catch (error: any) {
      msgrContext.setMessage({ body: `Logged in but could not save prediction: ${error.message}` }, true, Severity.FATAL)
      onComplete();
    }
  }

  const _onClickClose = () => {
    setCurrentStep(WizardStep.PICK_SYMBOL);
    onClickClose();
  }

  const onSelectPredictionFormType = (predictionTypeId: PredictionTypeEnum, valueAt: Date) => {
    setPredictionType(predictionTypeId);
    setPredictionValueAt(valueAt);
    setCurrentStep(WizardStep.SET_PREDICTION);
  }

  const renderStep = () => {
    if (currentStep === WizardStep.PICK_SYMBOL) {
      return <div className="mx-3 mb-1 mt-2">
        <SymbolList onSelect={onSelectStockSymbol} onChange={() => { setCurrentStep(WizardStep.CHOOSE_PREDICTION_TYPE) }} />
      </div>
    }

    if (currentStep === WizardStep.CHOOSE_PREDICTION_TYPE && symbol) {
      return <PredictionTypeSelect symbol={symbol} onSelect={onSelectPredictionFormType} todaysPredictions={todayPredictions} />
    }

    if (currentStep === WizardStep.SET_PREDICTION && symbol) {
      return <>
        {!isDirectToTool &&
          <>
            <div className="mx-3 mb-1 mt-2">
              <SymbolList initialValue={symbol} onSelect={onSelectStockSymbol} onChange={() => { setCurrentStep(WizardStep.PICK_SYMBOL) }} />
            </div>
            <div>
              <MakePredictionForm
                cardWidth={cardParentWidth}
                symbol={symbol}
                refreshLimit={10}
                onSaveCallback={onSaveCallback}
                predictionTypeEnum={predictionType}
                valueAt={predictionValueAt as Date} />
            </div>
          </>
        }

        {isDirectToTool && cardParentWidth && 
          <div>
            <MakePredictionForm
              cardWidth={cardParentWidth}
              symbol={symbol}
              onSaveCallback={onSaveCallback}
              predictionTypeEnum={predictionType}
              valueAt={initialValueAt || IntradayHelper.getWhen(new Date()).d}
              showTip={true}
              forceHeight={Math.max((window.innerHeight || document.documentElement.clientHeight) * (predictionType === PredictionTypeEnum.VALUE_AT_8PM ? 0.75 : 0.65), 350)} />
          </div>
        }
      </>
    }

    if (currentStep === WizardStep.REGISTER_OR_LOGIN) {
      return <div className="my-3">
        {!StockHelper.isLastHalfHour(new Date()) &&
          <div className="mx-4">
            <div className="register-message-title">Nice!</div>
            <div className="register-message-subtitle">Sign up now to save your prediction & see how you do compared to others!</div>
          </div>
        }
        <ShortRegisterForm onRegisterCallback={onRegister} onLoginCallback={onLogin} />
      </div>
    }

    return <></>
  }

  return (
    <>
      <ModalHelpTypeSelect show={showTypeSelectHelp} handleClose={() => setShowTypeSelectHelp(false)} />
      <div className="d-flex flex-row justify-content-center">
        <div className="d-flex flex-column justify-content-center flex-fill" ref={refChartContainer}>
          {!isDirectToTool &&
            <div className="d-flex flex-row mt-1 justify-content-center align-items-center mx-3">
              <div className="text-larger fw-bold flex-grow-1 d-flex flex-row gap-3">
                {currentStep > 1 && acctContext?.isLoggedIn() === true ? <div role="button" onClick={() => setCurrentStep(currentStep - 1)}><Icons.LeftArrow /></div> : <></>}
                <div>Make a Prediction</div>
                {currentStep === WizardStep.CHOOSE_PREDICTION_TYPE &&
                  <MoreInfoTypeSelect onClick={() => setShowTypeSelectHelp(true)} />
                }
              </div>
              <div role="button" title="Close" className="fw-bold ms-auto text-white"><CloseButton variant="white" onClick={_onClickClose} /></div>
            </div>
          }

          {StockHelper.isLastHalfHour(today) &&
            <>
              {isDirectToTool &&
                <div className="m-3">
                  <div className="fw-bold">
                    We are no longer accepting predictions for today. Please register now and return after the market close to make a prediction for tomorrow.
                  </div>
                  <div role="button" className="btn btn-primary my-3" onClick={() => setCurrentStep(WizardStep.REGISTER_OR_LOGIN)}>Register</div>
                </div>
              }

              {!isDirectToTool &&
                <div className="fw-bold m-3">
                  We are no longer accepting predictions for today. Please return after the market close to make a prediction for tomorrow.
                </div>
              }
            </>
          }

          {(!StockHelper.isLastHalfHour(today) || ((StockHelper.isLastHalfHour(today) && [WizardStep.REGISTER_OR_LOGIN, WizardStep.WAITING_FOR_RETRY].includes(currentStep)))) &&
            <>
              {renderStep()}
            </>
          }

        </div>
      </div>
    </>
  );
}
