import React, {useRef} from "react";
import {MathEx, NewYorkTz, SignalAlertPerfTypeEnum, SignalAlertPerfTypeEnumHelper} from "predictagram-lib";
import {adminApiServiceCommon} from "../../../services/AdminApiService";
import {Form, Formik} from "formik";
import {useApiServiceState} from "../../../_hooks/useApiServiceState";
import {Spinner} from "../../common/Spinner";
import {DatePickerTzEst} from "../../common/DatePickerTz";
import {
  flexRender,
  getCoreRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from "@tanstack/react-table";
import {ReactTableHelper} from "../../../_utils/ReactTableHelper";
import {SortDown, SortUp} from "react-bootstrap-icons";
import {SignalAlertCategoriesDropDown} from "../../common/SignalAlertCategoriesDropDown";

interface Options {
  startTime: number,
  endTime: number,
}

const Filter: React.FunctionComponent<{
  initialValues: Options,
  onClick: (search:Options)=>void,
}> = ({ initialValues, onClick }) => {

  const onSubmit = (dataIn: any, actions: any) => {
    const data = Object.assign({}, dataIn);
    onClick({
      ...data,
    });
  }


  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue }) => {
          return <Form>
            <div className="d-flex justify-content-start align-items-center gap-3 my-3">
              <div className="d-flex flex-column">
                <SignalAlertCategoriesDropDown />
              </div>
              <div className="d-flex flex-column">
                <span className="fw-bold">Start Date EST (inclusive)</span>
                <DatePickerTzEst
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("startTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.startTime * 1000)}
                />
                <br />
                <span className="fw-bold">End Date EST (inclusive)</span>
                <DatePickerTzEst
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("endTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.endTime * 1000)}
                />
              </div>
              <div className="align-self-end">
                <button type="submit" className="btn btn-primary p-2">Update Search</button>
              </div>
            </div>

          </Form>
        }}

      </Formik>
    </div>
  )
}


export const AdminTradesByScore: React.FunctionComponent = () => {

  const todayStart = NewYorkTz.getDateMidnight(new Date()).getTime() / 1000;
  const todayEnd = todayStart + (24 * 60 * 60) - 1; // set to last minute of today
  const [sorting, setSorting] = React.useState<SortingState>([])

  const initialSearch: Options = {
    startTime: todayStart,
    endTime: todayEnd,
  }
  const initialValues = {
    startTime: todayStart,
    endTime: todayEnd,
  } as Options;
  const searchOptions = useRef(initialSearch);

  type StatItem = {
    typeId:SignalAlertPerfTypeEnum,
    score: number|null,
    ibkrTrades: number,
    ibkrProfitLoss: number,
  };
  const supplier = async(ops:Options):Promise<StatItem[]>=> {
    const data = await adminApiServiceCommon.getTradesByScore({filters:ops});
    return data as any;
  }

  // const [ options, setOptions ] = useState<Options>(initialSearch);
  const {items, state, setSupplier} = useApiServiceState<StatItem[]>(()=>supplier(initialSearch));
  items?.map((v:any)=>{
    v['ibkrProfitPerTrade'] = v.ibkrTrades ? MathEx.round(v.ibkrProfitLoss/v.ibkrTrades, 2): 0;
  });
  const columnHelper = ReactTableHelper.createHelper<StatItem>();
  // const sum = (name)=>{}table.getFilteredRowModel().rows.reduce((total, row) => total + row.getValue(name), 0),

  const columns = [
    columnHelper.columnBuilder('typeId', {
      header: 'Type',
      cell: (info:any)=>info.getValue()?SignalAlertPerfTypeEnumHelper.names().get(info.getValue()):'',
    }).build(),
    columnHelper.columnBuilder('score', {header: 'Score'}).number().build(),
    columnHelper.columnBuilder('ibkrTrades', {header: 'IBKR Trades'}).numberFooterSum().build(),
    columnHelper.columnBuilder('ibkrProfitLoss', {header: 'IBKR Profit/Loss $'}).numberFooterSum({fixed:2}).build(),
    columnHelper.columnBuilder('ibkrProfitPerTrade', {header: 'IBKR ProfitPerTrade $',}).numberFooterSum({fixed:2}).build(),

  ];


  const table = useReactTable({
    data: items as any || [],
    columns: columns as any,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    // getGroupedRowModel: getGroupedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
    // debugTable: true,
  });

  const onClickCallback = (opts: Options) => {
    searchOptions.current = {
      startTime: opts.startTime,
      endTime: opts.endTime,
    } as Options;
    setSupplier(()=>supplier(opts));
  }

  return (
    <>
      <div>
        <div className="d-flex justify-content-center align-items-end gap-3">
          <Filter initialValues={initialValues} onClick={onClickCallback} />
        </div>
      </div>

      {state.isLoading() && <Spinner />}

      {state.isError() && <div>{state.error()?.message}</div>}

      {state.isLoaded() &&
       <table className="table table-hover table-striped">
         <thead>
         {table.getHeaderGroups().map((headerGroup,index) => (
           <tr key={headerGroup.id}>
             {headerGroup.headers.map(header => {
               return <>
                  <th key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()}
                      className={[
                        ...header.column.columnDef.meta?.classNames||[],
                        header.column.getCanSort()? 'cursor-pointer select-none': ''
                      ].join(' ')}
                  >
                 {header.isPlaceholder
                  ? null
                  : flexRender(
                     header.column.columnDef.header,
                     header.getContext()
                   )}
                 {{
                    asc: <SortUp />,
                    desc: <SortDown />,
                  }[header.column.getIsSorted() as string] ?? null}
               </th>
               </>
             }
             )}
           </tr>
         ))}
         </thead>
         <tbody>
         {table.getRowModel().rows.map(row => (
           <tr key={row.id}>
             {row.getVisibleCells().map(cell => (
               <td key={cell.id} className={cell.column.columnDef.meta?.classNames?.join(' ')}>
                 {flexRender(cell.column.columnDef.cell, cell.getContext())}
               </td>
             ))}
           </tr>
         ))}
         </tbody>
         <tfoot>
         {table.getFooterGroups().map(footerGroup => (
           <tr key={footerGroup.id}>
             {footerGroup.headers.map(header => (
               <th key={header.id} className={header.column.columnDef.meta?.classNames?.join(' ')}>
                 {header.isPlaceholder
                  ? null
                  : flexRender(
                     header.column.columnDef.footer,
                     header.getContext()
                   )}
               </th>
             ))}
           </tr>
         ))}
         </tfoot>
       </table>
       // <BaseTable
       //   data={items as any}
       //   fieldList={fieldList}
       //   onSelectClick={() => { }}
       // />
      }
    </>
  );
};


