import { IUserStatistic } from "interfaces/IUserStatistic";
import { Spinner } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { IContestOutletContext } from "../IContestOutletContext";
import { UserStatistic } from "./UserStatistic";
import { Ranking } from "./Ranking";

export const RankedList: React.FunctionComponent = () => {

  const { contest, rankings } = useOutletContext<IContestOutletContext>();

  // const { items: stats, loaded } = useContestRankings(contest.id, userContestApiService);
  const { items, loaded } = rankings;
  const stats = items.filter(obj => obj.predictionsCompleted > 0);

  return (
    <div className="ranked-list">
      {loaded && stats.length > 0 && stats[0].score !== null && <div className="mx-1"><Ranking stats={stats} /></div>}

      <div className="team-roster mx-3 my-2">
        {!loaded && <Spinner />}
        {loaded && stats.length === 0 && <div className="my-5 d-flex justify-content-center align-items-center">No rankings available.</div>}

        {loaded && stats.length > 0 && stats[0].score === null && stats.map((m: IUserStatistic, i: number) => <div className="row my-1 py-2 team-roster-item" key={`roster-key-${i}`}>
          <UserStatistic statistic={m} index={i + 1} norank={true}/>
        </div>)
        }


        {loaded && stats.length > 0 && stats[0].score !== null && stats.map((m: IUserStatistic, i: number) => <div className="row my-1 py-2 team-roster-item" key={`roster-key-${i}`}>
          <UserStatistic statistic={m} index={i + 1} />
        </div>)
        }
      </div>
    </div>

  );
}