import { ISearchNameResult } from 'interfaces/ISearchNameResult';
import React, { useContext, useRef, useState } from 'react'
import { searchService } from 'services/SearchService';
import * as Icons from 'react-bootstrap-icons';
import { ISearchOptions } from 'services/UserPredictionApiService';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';
import { SymbolsContext } from 'components/common/SymbolsContext';
import { IStockSymbol } from 'interfaces/IStockSymbol';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar';
import useClickOutside from '_hooks/useClickOutside';


export const SymbolSearchResult: React.FunctionComponent<{stockSymbol: IStockSymbol}> = ({stockSymbol}) => {
  return (
    <div className="my-2 text-white user-search-result">
      {stockSymbol.symbolName} - {stockSymbol.symbolDescription}
    </div>
  );
}

const UserSearchResult: React.FunctionComponent<{searchNameResult: ISearchNameResult}> = ({searchNameResult}) => {
  const { avatarUrl, id: userId, username } = searchNameResult
  const profile: IPublicProfile = {
    avatarUrl,
    userId,
    username
  } as IPublicProfile;

  return (
    <div className="user-search-result d-flex justify-content-left align-items-center gap-2 my-2">
      <div className="user-search-result-avatar align-self-start"><Avatar profile={profile} size={AvatarSizeEnum.LARGE} /></div>
      <div className="user-search-result-name">
        <div className="text-16 fw-bold text-white">{searchNameResult.nickname}</div>
        <div className="text-14">@{searchNameResult.username}</div>
      </div>
    </div>
  );
}


export const AutoSuggestSearch: React.FunctionComponent<{ onSearchCallback: (options: ISearchOptions) => void }> = ({ onSearchCallback }) => {

  const symbols = useContext(SymbolsContext);

  const [searchString, setSearchString] = useState<string>('');
  const [nameResults, setNameResults] = useState<ISearchNameResult[] | undefined>(undefined);
  const [symbolResults, setSymbolResults] = useState<IStockSymbol[] | undefined>(undefined);


  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const navigate = useNavigate();

  const refResults = useRef<HTMLDivElement>(null);

  useClickOutside(refResults, () => setExpanded(false));

  const onChangeSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value;
    setSearchString(searchString);
    const firstChar = searchString.charAt(0);

    const searchForUser = async (): Promise<number | undefined> => {

      if (isFetching) {
        searchService.cancelUserSearch();
      };

      const searchUserString = firstChar === '@' ? searchString.slice(1).toLocaleLowerCase() : searchString.toLocaleLowerCase();

      try {
        setIsFetching(true);
        const results = await searchService.searchUserName(searchUserString);
        setNameResults(results.sort((a: ISearchNameResult, b: ISearchNameResult) => {
          const username_a = a.username.substring(0, searchUserString.length).toLocaleLowerCase();
          const username_b = b.username.substring(0, searchUserString.length).toLocaleLowerCase();
          if (username_a === searchUserString && username_b === searchUserString) {
            return username_a.localeCompare(username_b);
          } else if (username_a === searchUserString) {
            return -1;
          } else if (username_b === searchUserString) {
            return 1;
          } else {
            return 0;
          }
        }));
        setIsFetching(false);
        return results.length;
      } catch (error: any) {
        setIsFetching(false);
        console.error((error as Error).message)
      }    
    }

    const searchForSymbol = (): number => {
      const searchSymbol = firstChar === '$' ? searchString.slice(1).toLocaleLowerCase() : searchString.toLocaleLowerCase();
      const filteredSymbols = symbols?.symbols
        .filter(s => s.symbolName.toLocaleLowerCase().includes(searchSymbol.toLocaleLowerCase()));
      
      if (filteredSymbols) {
        setExpanded(true);
        setSymbolResults(filteredSymbols.sort((a: IStockSymbol, b: IStockSymbol) => {
          const symbol_a = a.symbolName.substring(0, searchSymbol.length).toLocaleLowerCase();
          const symbol_b = b.symbolName.substring(0, searchSymbol.length).toLocaleLowerCase();
          if (symbol_a === searchSymbol && symbol_b === searchSymbol) {
            return symbol_a.localeCompare(symbol_b);
          } else if (symbol_a === searchSymbol) {
            return -1;
          } else if (symbol_b === searchSymbol) {
            return 1;
          } else {
            return 0;
          }
        })
        );
        return filteredSymbols.length;
      } else {
        setExpanded(false)
        return 0;
      }
    }

    // if starts with $, look for symbol only
    if (firstChar === '$') {
      setNameResults(undefined);
      if (searchString === '$') {
        // return all of the symbols if just "$"
        setSymbolResults(symbols?.symbols);
        setExpanded(symbols !== null && symbols.symbols && symbols.symbols.length > 0)
      } else {
        searchForSymbol();
      }
      return;
    }

    // if starts with @, look for username only
    if (firstChar === '@') {
      setSymbolResults(undefined);
      const userResults = await searchForUser();
      setExpanded((userResults || 0) > 0)
      return;
    }

    // first char is neither @ or $
    if (searchString.length > 0 ) {
      const symbolResults = searchForSymbol();
      if (searchString.length > 1) {
        const results = await searchForUser();
        setExpanded(((results || 0) > 0) || symbolResults > 0)
      }
    } else {
      setExpanded(false);
    }
  }

  const onSubmit = async (searchString: string) => {
    try {
      const symbols = searchString.replaceAll(',', '').match(/\$(\S+)/gi);
      const usernames = searchString.replaceAll(',', '').match(/@(\S+)/gi);

      const options: ISearchOptions = {
        usernames: usernames ? usernames.map((n: string) => n.replaceAll('@', '')) : undefined,
        symbolNames: symbols ? symbols.map((s: string) => s.replaceAll('$', '')) : undefined
      };
      onSearchCallback(options);
    } catch (error) {
      console.error(error);
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      setNameResults(undefined);
      setSymbolResults(undefined);
      setSearchString("");
      setExpanded(false);
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      setNameResults(undefined);
      setExpanded(false);
      onSubmit(searchString);
    }
  }

  return (
    <div className="search">
      <div className="search-form">
        <form>
          <div className="d-flex flex-row search justify-content-center align-items-center">
            <div className="mx-2"><Icons.Search width="1.25rem" height="1.25rem" color="#FFFFFF66" /></div>
            <div className="search-body">
              <div className="form-group">
                <input type="text" className="search-text"
                  value={searchString}
                  onChange={onChangeSearch}
                  onKeyDown={onKeyDown}
                  maxLength={30}
                  size={30}
                  placeholder='Search $AMZN @username'
                />
              </div>
            </div>
            <div className="mx-2"></div>
          </div>
        </form>

        {/* {((nameResults && nameResults.length > 0) || (symbolResults && symbolResults.length > 0) ) &&  */}
        {expanded && 
          <div className="search-suggestions" ref={refResults}>

            {symbolResults && symbolResults.length > 0 &&
              <>{symbolResults.map((symbolResult: IStockSymbol, i: number) => <div key={`symbol-search-key-${i}`} role="button" 
                onClick={()=>{navigate(`${Url.PUBLIC_STOCK_PREDICTIONS.replace(":stockSymbol", symbolResult.symbolName)}`)}}>
                <SymbolSearchResult stockSymbol={symbolResult} /></div>)}
              </>
            }

            {nameResults && nameResults.length > 0 && (
              <>
                {nameResults.map((r: ISearchNameResult, i: number) => <div key={`search_result_${i}`} role="button" onClick={() => {
                  navigate(`${Url.PUBLIC_STOCK_PREDICTOR}/${r.id}`);
                }}><UserSearchResult searchNameResult={r} /></div>)}
              </>
            )}
          </div>
        }

      </div>


    </div>
  )
}
