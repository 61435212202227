import React from 'react';
import { ModalDoc } from './ModalDoc';
import { Terms } from './Terms';


export const ModalTerms: React.FunctionComponent<{show: boolean, handleClose: ()=>void }> = ({show, handleClose}) => {
  return (
  <ModalDoc show={show} handleClose={handleClose} title="Terms and Conditions">
  <Terms isModal={true}/>
  </ModalDoc>  
  );
}