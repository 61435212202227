import { AbortableHttpService } from "./AbortableHttpService";

export interface IAdminUserTicketsSearchOptions {
  userId?: number,
  startTime: number,
  endTime: number,
}

export interface IAdminUserTicketsResponse {
  id: number,
  username: string,
  email: string,
  predictionsCompleted: string,
  openedAt: number,
}

export class AdminUserTicketsApiService {
  constructor(
    protected apiBaseUrl:string, 
    protected httpService: AbortableHttpService,
  ) {}

  async getUserTickets(options: IAdminUserTicketsSearchOptions, abortSignal?: AbortSignal): Promise<IAdminUserTicketsResponse[]> {
    const url = `${this.apiBaseUrl}/ticket/users-tickets`;
    const payload = {filters: options};
    return await this.httpService.getMany(url, payload, false, abortSignal)
  }

}