import React from 'react';
import { Modal } from "react-bootstrap";
import { InfoCircleFill } from 'react-bootstrap-icons';
import { HelpTypeSelect } from './HelpTypeSelect';


export const MoreInfoTypeSelect: React.FunctionComponent<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div role="button" onClick={onClick}><InfoCircleFill /></div>
  );
}

export const ModalHelpTypeSelect: React.FunctionComponent<{ show: any; handleClose: any }> = ({ show, handleClose }) => {

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <HelpTypeSelect handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
}