import { IUserStreakStatResponse, userAchievementService } from "services/UserAchievementService";
import { useApi } from "./useApi";

export function useStreakStats () {
  const load = async (abortSignal?: AbortSignal) => {
    return await userAchievementService.getStreakStats(abortSignal);
  }
  return useApi<IUserStreakStatResponse, undefined>(load, undefined);
}

