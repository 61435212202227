
import { IAccount, IApiConfig, ICredentials } from "interfaces";
import axios from "axios";
import { config } from 'config';
import { AccountTypeEnum, StorageKey } from "_constants";
import { TokenHelper } from "_utils/TokenHelper";
import Cookies from "js-cookie";

class AuthenticationService {

  constructor(
    protected apiConfig: IApiConfig,
    protected accountType: AccountTypeEnum
  ) { }

  async login(credentials: ICredentials): Promise<IAccount | undefined> {
    const url: string = `${this.apiConfig.baseUrl}/login`;
    try {
      const response: any = await axios.post(url, credentials);
      if (!response.data || response.data.error) {
        throw Error(`${response.data.error.message}`)
      }
      TokenHelper.setToken(response.data.token, this.apiConfig.storageTokenName, localStorage);
      await this.setLocalAccount();
      return response.data;

    } catch (error: any) {
      throw Error(error.message);
    }
  }


  setToken(token: string) {
    TokenHelper.setToken(token, this.apiConfig.storageTokenName, localStorage);
  }

  getLocalAccount(): IAccount | null {
    //console.log('getting local account');
    const data = TokenHelper.getToken(this.apiConfig.storageAccountName, localStorage);
    if (data) {
      const obj = JSON.parse(data);
      return obj.account;
    } else {
      return null;
    }
  }

  async setLocalAccount() {
    // get the account and save it
    try {
      const url: string = `${this.apiConfig.baseUrl}/account`;
      const response = await axios.get(url, await this.getAuthHeader());
      // console.log('updatedresponse', response.data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      const account: IAccount = response.data;
      account.accountType = this.accountType;
      TokenHelper.setToken(JSON.stringify(account), this.apiConfig.storageAccountName, localStorage);
    } catch (err) {
      console.error(err);
      throw Error(`error setting account: ${err}`);
    }
  }

  logout = async () => {
    [
      this.apiConfig.storageTokenName,
      this.apiConfig.storageAccountName,
      StorageKey.PREDICTION_COUNTER,
      StorageKey.REFERRAL,
      StorageKey.REFERRAL_USER_ID,
      StorageKey.USER_TOKEN,
      StorageKey.USER_TOKEN_LAST_CHECKED,
      StorageKey.SEEN_TEAM_CONTEST_RULES,
      StorageKey.DISCLAIMER,
      StorageKey.DASHBOARD_SETTINGS,
      // StorageKey.SEEN_WALKTHROUGH_CONSENSUS,
      // StorageKey.SEEN_WALKTHROUGH_PREDICTION,
      //StorageKey.LAST_NOTIFICATION_KEY,  // should not be cleared on logout
      //StorageKey.LAST_NOTIFICATION_SEEN
    ].forEach((i: string) => localStorage.removeItem(i));

    [
      StorageKey.COOKIES_VERIFY_REMINDER_DASHBOARD,
      StorageKey.COOKIES_VERIFY_REMINDER_TEAMLB,
      StorageKey.COOKIES_DISCLAIMER_REMINDER
    ].forEach((c) => Cookies.remove(c));
  }

  getToken(): string {
    return TokenHelper.getToken(this.apiConfig.storageTokenName, localStorage);
  }

  async getAuthHeader(redir?: string) {

    const redirect = () => {
      let redirPath = this.apiConfig.loginUrl;
      console.debug('redirecting');
      this.logout();
      if (redir) {
        redirPath = `${redirPath}?redir={redir}`
      } 
      window.location.href = redirPath;
      return;
    }

    const token = this.getToken();
    if (token === '') {
      console.debug('redirecting, empty token');
      redirect();
    }

    const payload: any = TokenHelper.getDecodedToken(token);
    if (!payload) {
      console.debug('invalid payload. redirecting');
      redirect();
    }

    if ( !(payload.hasOwnProperty('p') && payload.hasOwnProperty('iat') && payload.hasOwnProperty('exp'))) {
      console.debug('redirecting, invalid token');
      redirect();
    }

    // if expired, redirect 
    if (TokenHelper.isTokenExpired(payload, new Date())) {
      console.debug('redirecting expired token');
      redirect();
    }

    // check to get refreshed token, if about to expire, refresh
    if (TokenHelper.isTokenAboutToExpire(payload, new Date())) {
      try {
        console.debug('calling refreshAuth');
        await this.refreshAuth();
      } catch (error: any) {
        console.error('refreshAuth failed', {error});
        this.logout();
        redirect();
      }
    }

    // not expired so return header with authorization
    const header = {
      headers: {
        Authorization: 'Bearer ' + token,
        token
      }
    };
    //console.debug('returning header with token', {header});
    return header;
  }

  isTokenExpired(): boolean {
    const payload = TokenHelper.getDecodedToken(this.getToken());
    if (!payload) {
      return true;
    }
    return TokenHelper.isTokenExpired(payload, new Date());

  }

  async refreshAuth(): Promise<any> {
    try {
      const url: string = `${this.apiConfig.baseUrl}/token/refresh`;

      const token = this.getToken();

      const header = {
        headers: {
          Authorization: 'Bearer ' + token,
          token: token
        }
      };

      const response = await axios.get(url, header);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      TokenHelper.setToken(response.data.token, this.apiConfig.storageAccountName, localStorage);
      console.log('refreshed token');
    } catch (err) {
      console.error(err);
      throw Error(`error refreshAuth: ${err}`);
    }
  }

}


const basePath = `${config.apiDomain}${config.apiBasePath}`;

const adminAuthenticationService = new AuthenticationService({
  baseUrl: `${basePath}/admin`,
  storageAccountName: StorageKey.ADMIN_ACCT,
  storageTokenName: StorageKey.ADMIN_TOKEN,
  loginUrl: `${basePath}/admin`,
}, AccountTypeEnum.ADMIN);

const userAuthenticationService = new AuthenticationService({
  baseUrl: `${basePath}/user`,
  storageAccountName: StorageKey.USER_ACCT,
  storageTokenName: StorageKey.USER_TOKEN,
  loginUrl: `${config.wwwDomain}/login`,
}, AccountTypeEnum.USER);


export {
  AuthenticationService,
  adminAuthenticationService,
  userAuthenticationService
}
