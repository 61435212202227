import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { MessengerProvider } from 'components/common/messenger';
import { AccountProvider } from 'components/common/AccountProvider';
import { userAccountService } from 'services/AccountService';
import { userAuthenticationService } from 'services/AuthenticationService';
import { PixelHelper } from '_utils/PixelHelper';
import { useUtmSource } from '_hooks/useUtmSource';


export const WelcomeLayout: React.FunctionComponent = () => {
  const utmSource = useUtmSource();
  
  useEffect(()=>{
    PixelHelper.firePageView(utmSource);
  },[])

  return (
    <MessengerProvider>
      <AccountProvider accountService={userAccountService} authService={userAuthenticationService}>
        <Outlet />
      </AccountProvider>
    </MessengerProvider>
  );
}