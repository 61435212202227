import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { IRecentActivity, RecentActivityItemType } from 'interfaces/IRecentActivity';
import { FollowedAvatars } from './FollowedAvatars';
import { Url } from '_constants';
import { NotificationHelper } from './NotificationHelper';
import { SecuritiesDatasetEnum, useTradegramSecurities } from '_hooks/useTradegramSecurities';
import { tradegramApiService } from 'services/TradegramApiService';
import { Helper } from '_utils';
import { UserNotificationTypeEnum } from 'predictagram-lib';

export const FollowedTrades: React.FunctionComponent<{ activity: IRecentActivity }> = ({ activity }) => {
  const navigate = useNavigate();
  const [text, setText] = useState<React.ReactNode>(<></>);
  const [act, setAct] = useState<IRecentActivity | null>(null);
  const apiSecurities = useTradegramSecurities(tradegramApiService, SecuritiesDatasetEnum.FOLLOWING);

  useEffect(() => {
    const load = async () => {
      if (activity.items && activity.items.length > 0 && apiSecurities.apiState.isLoaded) {
        //const ids = activity.items.map(i => i.userId as number);

        const ids = apiSecurities.items.map(i => i.userId.toString()).filter(Helper.uniqueFilter).map(i => parseInt(i));

        const tradeItems: RecentActivityItemType[] = ids.map(i => {
          const act: RecentActivityItemType = {
            userId: i,
            createdAt: (activity.items && activity.items[0].createdAt) || Date.now() / 1000,
          }
          return act;
        })

        const recentActivity: IRecentActivity = {
          typeId: UserNotificationTypeEnum.FOLLOWEE_NEW_SECURITY_TRANSACTION,
          items: tradeItems,
          count: 3
        }

        setAct(recentActivity);

        const names = await NotificationHelper.getUserNames(ids.splice(0, 2));
        if (ids.length > 0) {
          setText(
            <>
              Recent trades from <span className="fw-bold">{names.map(n => n.username).join(', ')}</span> and {ids.length} other{ids.length > 1 ? 's' : ''}
            </>);
        } else {
          setText(
            <>
              Recent trades from <span className="fw-bold">{names.map(n => n.username).join(', ')}</span>
            </>
          );
        }
      }
    }
    load();
  }, [activity, apiSecurities.apiState.isLoaded, apiSecurities.items])

  return (
    <>
      {
        act !== null &&
        <FollowedAvatars numberOfAvatars={3} activity={act} onClick={() => navigate(Url.USER_NOTIFICATIONS_FOLLOWED_USER_TRADES)}>
          {activity.items && activity.items.length > 0 &&
            <>{text}
            </>
          }
        </FollowedAvatars>
      }
    </>
  );

}
