import { IStockSymbol } from 'interfaces/IStockSymbol'
import React, { useState } from 'react'


interface IProps {
  stockSymbol: IStockSymbol,
  initialSelected: boolean,
  onClick: (stockSymbol: string, selected: boolean) => void,
  nameOnly?: boolean,
  isNew?:boolean,
}

export const SymbolToggleButton:React.FunctionComponent<IProps> = ({stockSymbol, initialSelected, onClick, nameOnly = false, isNew = false}) => {

  const [ selected, setSelected ] = useState<boolean>(initialSelected);

  const _onClick = () => {
    setSelected(!selected);
    onClick(stockSymbol.symbolName, selected);
  }

  return (
    <div role="button" className={`symbol-toggle-button ${nameOnly ? 'symbol-toggle-button-name-only' : ''} symbol-toggle-button-${initialSelected ? 'on' : 'off'}`} onClick={()=>_onClick()}>
      <div className="symbol-toggle-button-name d-flex justify-content-between align-items-center">{stockSymbol.symbolName} {!!isNew && <span className="text-lavender text-10 fw-bold">NEW!</span>}</div>
      {!nameOnly && 
      <div className="symbol-toggle-button-description">{stockSymbol.symbolDescription}</div>
      }
  </div>
  )
}

