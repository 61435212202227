import React, { useContext, useRef, useState } from 'react'
import { TopPredictors } from './welcome/TopPredictors';
import { ShortRegisterForm } from 'components/user/account/ShortRegisterForm';
import { useNavigate } from 'react-router';
import { StorageKey, Url } from '_constants';
import { AccountContext } from 'components/common';
import { RedirectComponent } from 'components/common/RedirectComponent';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { ModalPhoneConfirmationWizard } from 'components/user/account/ModalPhoneConfirmationWizard';
import { Helmet } from 'react-helmet';
import { config } from 'config';
import { Emoji } from 'components/common/Emoji';
import { useDiscountPromo } from '_hooks/useDiscountPromo';

export const WelcomePage: React.FunctionComponent = () => {

  const navigate = useNavigate();
  const acctContext = useContext(AccountContext);
  const itemsRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const discountPromo = useDiscountPromo(localStorage.getItem(StorageKey.SEEN_DISCOUNT_PROMO));

  const [isRegisterForm, setIsRegisterForm] = useState<boolean>(true);
  const [showPhone, setShowPhone] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(true);

  // @TODO: swipe was copied from RecentHappenings. Change to function or class.
  const swipeConfig = {
    delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true,           // prevents scroll during swipe (*See Details*)
    trackTouch: true,                      // track touch input
    trackMouse: true,                     // track mouse input
    rotationAngle: 0,                      // set a rotation angle
    swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)
    preventDefaultTouchmoveEvent: true,
  }

  const swipeHandlers = useSwipeable({

    onSwiping: (eventData: SwipeEventData) => {
      if (itemsRef.current && containerRef.current) {

        const leftmostX = itemsRef.current.scrollWidth - containerRef.current.offsetWidth;
        const currLeft = window.getComputedStyle(itemsRef.current).getPropertyValue('left');
        const newPosX = parseInt(currLeft, 10) + eventData.deltaX;

        if (newPosX > 0) {
          itemsRef.current.style.left = `0px`;
          return;
        }

        if ((newPosX + 50) < -leftmostX) {
          //itemsRef.current.style.left = `${leftmostX}px`;  
          return;
        }
        itemsRef.current.style.transition = "0.5s";
        itemsRef.current.style.left = `${parseInt(currLeft, 10) + eventData.deltaX}px`;
      }
    },
    ...swipeConfig
  })


  const onRegisterCallback = () => {
    // console.log('onRegistercallback called')
    setRedirect(false);
    setShowPhone(true);
  }

  if (acctContext?.isLoggedIn() && redirect === true) {
    // console.log('redirecting');
    return <RedirectComponent to={Url.DEFAULT_HOME} />
  }

  return (
    <>

      <Helmet>
        <title>Predictagram: Welcome!</title>
        <meta property="og:title" content={`Predictagram: Welcome!`} />
        <meta property="og:description" content={`Free access to the best real-time stock market predictions.`} />
        <meta property="og:url" content={`${config.wwwDomain}/welcome`} />
      </Helmet>

      {discountPromo.modalElement()}
      
      <ModalPhoneConfirmationWizard
        handleClose={() => { setShowPhone(false); navigate(Url.DEFAULT_HOME) }}
        show={showPhone}
        onSuccess={() => navigate(Url.DEFAULT_HOME)} />

      <div className="welcome container-fluid">
        <div className="row">
          <div className="col-12 col-md-8 g-0 mt-1 mt-md-5 p-2 p-md-5 pe-0 pe-md-0">
            <div className="head mb-3">
              <div className="head-logo mb-4">
                <img src="/images/purple-logo.png" alt="logo" />
              </div>
              <div className="head-title">
                <span className="text-lavender">Free</span> access to the best <span className="text-lavender">real-time</span> stock market predictions.
              </div>
            </div>

            <div className="section my-5">
              <div className="section-image m-1 ms-md-0 me-md-4 align-items-center">
                <img src="/images/welcome_chart.png" alt="chart" />
              </div>
            </div>

            <div className="section m-2 m-md-0 my-5">
              <div className="section-title d-flex gap-2 align-items-center"><Emoji text="" symbol="🎯" label="bullseye" />Verified Prediction Accuracy</div>
              <div className="section-subtitle">All users' accuracy % is tracked and visible, so you know who to trust.</div>

              <div className="top-predictors my-3" {...swipeHandlers} ref={containerRef}>
                <div className="top-predictors-items" ref={itemsRef}>
                  <TopPredictors />
                </div>
              </div>
            </div>

          </div>

          <div className="col-12 col-md-4 border-start border-dark g-0 mt-1 mt-md-5 p-2 p-md-5 pe-0 pe-md-0 join">

            <div className="d-flex flex-column">
              <div className="page-title mx-4">{isRegisterForm ? 'Join Now' : 'Log In'}</div>
              <ShortRegisterForm
                onLoginCallback={() => { navigate(Url.DEFAULT_HOME) }}
                onRegisterCallback={onRegisterCallback}
                onLoginClick={() => setIsRegisterForm(!isRegisterForm)}
                buttonLabel="Create Account"
                skipFg={true}
              />
            </div>
            <div className="my-5"></div>
          </div>
        </div>
      </div>
    </>
  )
}

