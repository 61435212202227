import {AutoTradeSecurityTypeEnum, IBKRSide} from "../dict";
import {IAutoTrade} from "../interface";

export class AutoTradeHelper {
    static calcRealizedProfitLossTradeIbkr(trade: IAutoTrade) {
        return this.calcProfitLoss({
            open:trade.ibkr.openPriceAvg,
            close:trade.ibkr.closePriceAvg,
            quantity:trade.ibkr.closedQuantity,
            multiplier:this.multiplier(trade),
            isLong: trade.ibkr.openOrders?.filter(v=>v.orderSide===IBKRSide.BUY).length>0,
        });
    }

    static calcUnrealizedProfitLossIbkr (trade: IAutoTrade, currentPrice: number) {
        return this.calcProfitLoss({
            open:trade.ibkr.openPriceAvg,
            close:currentPrice,
            quantity: trade.ibkr.openedQuantity-trade.ibkr.closedQuantity,
            multiplier: this.multiplier(trade),
            isLong: trade.ibkr.openOrders?.filter(v=>v.orderSide===IBKRSide.BUY).length>0,
        });
    }

    public static calcProfitLoss(p:{open: number, close: number, quantity: number, multiplier:number, isLong:boolean}) {
        const amount = (p.close - p.open) * p.quantity * p.multiplier;
        return p.isLong ? amount : -amount;
    }

    static multiplier(trade:IAutoTrade) {
        return trade.securityTypeId===AutoTradeSecurityTypeEnum.OPTION?100:1;
    }
}
