import React, { useContext, useEffect, useState } from 'react';
import { config } from 'config';
import { Url } from '_constants';
import { MessengerContext, Severity } from './messenger';
import { RWebShare } from "react-web-share";
import { AccountContext } from './AccountContext';
import { SearchParams } from '_constants/SearchParams';
import { ShareBase } from './ShareBase';
import { ITradegramSecurity } from 'services/TradegramApiService';

export const ShareTradegram: React.FunctionComponent<{ security: ITradegramSecurity, title?:string }> = ({ security, title=""}) => {

  const msgrContext = useContext(MessengerContext);
  const acctContext = useContext(AccountContext);
  //const baseUrlPath = `${config.wwwDomain}${Url.USER_PROFILE_POSITIONS.replace(':userId', security.userId.toString()).replace(':securityId', security.id.toString())}`;
  const [baseUrlPath, setBaseUrlPath] = useState<string>('');
  const qstrings: string[] = [];

  if (acctContext?.isLoggedIn()) {
    qstrings.push(`${SearchParams.UI_REFERRAL_USER_ID}=${acctContext.account.id}`);
    qstrings.push(`${SearchParams.UI_REFERRAL_SECURITY_ID}=${security.id}`);
  }

  const url = qstrings.length > 0 ? `${baseUrlPath}?${qstrings.join("&")}` : baseUrlPath;

  useEffect(()=>{
    if (security.userId) {
      const url = `${config.wwwDomain}${Url.USER_PROFILE_POSITIONS
        .replace(':userId', security.userId.toString())
        .replace(':securityId', security.id.toString())
      }`;
      //console.log({url});
      setBaseUrlPath(url);
    }
  },[security.id, security.userId, security.stockSymbol])

  const onCopyLink = async () => {
    if (security) {
      try {
        await navigator.clipboard.writeText(url);
        msgrContext.setMessage({ body: 'Url was copied to clipboard!' });
      } catch (error: any) {
        msgrContext.setMessage({ body: `Could not copy: ${error.message}` }, true, Severity.FATAL);
        console.error({ error });
      }
    }
  }

  return (
    <ShareBase onCopyLink={onCopyLink} title={title}>
      {({ setShow }) => {
        return <RWebShare
          data={{
            text: `Check out this trade from Predictagram!`,
            url: url,
            title: `Follow My Trades`,
          }}
          onClick={() => setShow(false)}
        >
          <div role="button">Share via...</div>
        </RWebShare>
      }
      }
    </ShareBase>
  );
}
