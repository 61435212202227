import React from 'react'
import { ISeason, Season, SeasonEnum } from 'predictagram-lib'
import { DropdownFilter, IDropdownFilterItem } from 'components/common/DropDownFilter/DropdownFilter';

interface IProps {
  season: SeasonEnum
  setSeason: (value: SeasonEnum) => void,
  className?: string,
}

type ddItemType = IDropdownFilterItem<SeasonEnum, ISeason>;
const ddItemsMap: Map<SeasonEnum, ddItemType> = new Map<SeasonEnum, ddItemType>();

export const SeasonFilter:React.FunctionComponent<IProps> = ({season, setSeason, className}) => {

  for (const [key, value] of Object.entries(Season.seasons)) {
    ddItemsMap.set(key as SeasonEnum, {key: key as SeasonEnum, label: key, value});
  }

  const items = Array.from(ddItemsMap).map((item) => item[1]);

  return (
    <div className="d-flex justify-content-start">
    <DropdownFilter<SeasonEnum, ISeason> 
        items={items}
        initialSelectedKey={season}
        onSelect={(item)=>setSeason(item)}
        />
    </div>
  )
}
