import React, { useState } from 'react'
import { AutoTradeStatusEnum, AutoTradeStatusEnumHelper } from 'predictagram-lib';
import { SelectDropDown } from 'components/common/form/SelectDropDown';


interface IProps {
  value: AutoTradeStatusEnum | undefined,
  setValue: (value: AutoTradeStatusEnum) => void,
  className?: string,
}

export const StatusFilter:React.FunctionComponent<IProps> = ({value, setValue, className}) => {

  return (
    <SelectDropDown<AutoTradeStatusEnum, string> 
      onSelect={setValue}
      options={AutoTradeStatusEnumHelper.names}
      selected={value}
      title="Status"
      className={className}
    />
  )
}



