import { useCallback, useEffect, useRef, useState } from "react";
import { APIState } from "_constants/APIState";
import { PredictionService } from "services";
import { DateEx, IQuote, StockStatsIntervalEnum } from "predictagram-lib";


export interface IBarsApi {
  items: readonly IQuote[],
  apiState: APIState,
  apiStatus: {
    isLoading: boolean,
    isLoaded: boolean,
    isIdle: boolean,
    isError: boolean
  },
  error: Error | null,
  reload: () => void,
}

export function useBarsApi (service: PredictionService, symbolName: string, startTime: number, endTime: number, interval: StockStatsIntervalEnum = StockStatsIntervalEnum.MIN_1): IBarsApi {

  const [ items, setItems ] = useState<readonly IQuote[]>([]);
  const [ apiState, setApiState ] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<Error | null>(null);
  const [ refresh, setRefresh ] = useState<number>(0);
  
  const serviceRef = useRef(service);

  const load = useCallback(async ()=>{
    try {
      setApiState(APIState.LOADING);
      const items = await serviceRef.current.getBarsData(symbolName, new DateEx(startTime * 1000), new DateEx(endTime * 1000), true, interval);
      setItems(items);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as Error);
    } 
  }, [symbolName, endTime, startTime, interval])

  useEffect(()=>{

    load();

    // clean up
  }, [load, refresh])

  const reload = () => {
    setRefresh(refresh + 1);
  }

  const isLoaded = apiState === APIState.LOADED;
  const isLoading = apiState === APIState.LOADING;
  const isError = apiState === APIState.ERROR;
  const isIdle = apiState === APIState.IDLE;

  return {items, apiState, apiStatus: {isLoaded, isLoading, isError, isIdle}, error, reload};

}
