import { IApiResponse, useApi } from "./useApi";
import { IAutoTradeSetup } from "predictagram-lib";
import { useState } from "react";
import { adminAutoTradeSetupService } from "services/AdminApiService";

export interface IAdminAutoTradeSetupApi {
  api: IApiResponse<IAutoTradeSetup[], null>;
  getById: (id: number) => IAutoTradeSetup | undefined;   
}

export function useAdminAutoTradeSetup (): IAdminAutoTradeSetupApi {
  const [data, setData] = useState<IAutoTradeSetup[]>([]);
  const load = async () => {
    const data =  await adminAutoTradeSetupService.getAll(true, true);
    setData(data);
    return data;
  }
  return {
    api: useApi<IAutoTradeSetup[], null>(load, null),
    getById: (id: number) => {return data.find(v => v.id === id)}
  }
}

