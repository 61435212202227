import React from "react";

import { IKeyValue } from "interfaces";
import { DropDown } from "components/common/form/DropDown";
import { predictionTypeNames } from "_constants/PredictionTypeEnum";

export const PredictionTypesDropdown: React.FunctionComponent<{ errors: any; touched: any; name: string, multiple?:boolean,showDefault?:boolean, className?:string }> = ({ errors, touched, name ,multiple=false,showDefault=true, className}) => {

  return (
      <DropDown enumMap={predictionTypeNames}
        defaultItem={showDefault?{ key: 0, value: 'All' } as IKeyValue<number>:undefined}
        errors={errors}
        touched={touched}
        multiple={multiple}
        className={className}
        label={<div className="fw-bold">Prediction Type</div>} name={name} />
  );
};

