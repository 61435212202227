import { config } from "config";
import { AuthenticationService, userAuthenticationService } from "./AuthenticationService";
import { IPaymentProfile, paymentService, PaymentService } from "./PaymentService";
import { AbortableHttpService } from "./AbortableHttpService";
import { UserPlanStatusEnum } from "predictagram-lib";

export interface IUserSignalPlan {
    planId: number,
    planName: string,
    planPrice: number,
    expireAt: number,
    statusId: number,
    userStatusId: number
}

class SubscriptionService {

  constructor(
    protected apiBaseUrl: string,
    protected authenticationService: AuthenticationService,
    protected httpService: AbortableHttpService,
    protected paymentService: PaymentService
  ) {}


  async subscribeToPlan(planId: number) {
    const url = `${this.apiBaseUrl}/management/signal/plan/subscribe/${planId}`;
    try {
      const result = await this.httpService.httpPost<any>(url, {}, undefined, false);
      return result;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }


  async subscribeAndPay(profile: IPaymentProfile, planId: number) {
    const current: any = await this.paymentService.listPaymentProfile();
    try {
      if (current.data.length === 0 ) {
        await this.paymentService.submitPaymentProfile(profile);
      }
      await this.subscribeToPlan(planId);
    } catch (error: any) {
      throw new Error(error.message)
    }
  }

  async getPlans(abortSignal?: AbortSignal): Promise<IUserSignalPlan[]> {
    const url = `${this.apiBaseUrl}/management/signal/plans`;
    try {
      const result = await this.httpService.httpPost<any>(url, {}, abortSignal, false);
      return result;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async cancelSubscription(planId: number, reason: string) {
    const url = `${this.apiBaseUrl}/management/signal/plan/subscription/${planId}`;
    try {
      const result = await this.httpService.put<any>(url, {userStatusId: UserPlanStatusEnum.PAUSED_CANCELED, reason}, undefined, false);
      return result;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async renewSubscription(planId: number) {
    const url = `${this.apiBaseUrl}/management/signal/plan/subscription/${planId}`;
    try {
      const result = await this.httpService.put<any>(url, {userStatusId: UserPlanStatusEnum.ACTIVE}, undefined, false);
      return result;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }


}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const subscriptionService = new SubscriptionService(apiBaseUrl, userAuthenticationService, new AbortableHttpService(userAuthenticationService), paymentService);

export { subscriptionService, SubscriptionService }