export enum ReferralTypeEnum {
    PREDICTION             = 1,
    TEAM                   = 2,
    CONTEST                = 3,
    SECURITY               = 4,
    USER_PROFILE           = 5,
}

export class ReferralTypeHelper {

    public static names: Map<ReferralTypeEnum, string> = new Map<ReferralTypeEnum, string>([
            [ReferralTypeEnum.CONTEST, 'Contest'],
            [ReferralTypeEnum.TEAM, 'Team'],
            [ReferralTypeEnum.PREDICTION, 'Prediction'],
            [ReferralTypeEnum.SECURITY, 'Security'],
            [ReferralTypeEnum.USER_PROFILE, 'User Profile'],
    ])

    public static getName = (typeId: ReferralTypeEnum) => {
        return this.names.get(typeId);
    }
}
