import {Helper} from "../_utils";
import {Calendar as Cal, DateEx} from "predictagram-lib";
export {CalendarStub} from "predictagram-lib";

export class Calendar extends Cal {
  newDate() {
    const sec = Helper.timeSecQuery();
    if (sec!=null) {
      return new DateEx(sec*1000);
    }
    return super.newDate();
  }
}
