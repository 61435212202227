import React from "react";
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker';
import {DateHelper} from "predictagram-lib";

interface DatePickerTzParams extends ReactDatePickerProps {
}
export const DatePickerTzEst:React.FunctionComponent<DatePickerTzParams> = (props) => {
  return (
    <DatePickerTz {...props} tz={DateHelper.EAST_TIMEZONE}/>
  )
}

export const DatePickerTz:React.FunctionComponent<DatePickerTzParams&{tz: string}> = (props) => {
  /*
  It's impossible to use fixed offset, because calendar dates can go from/to clock switch days.
  So, offset is custom for each day
   */

  const calcOffSet = (date: Readonly<Date>) => {
    const local = date;
    const lf = local.getTimezoneOffset()/60;
    const dt =  DateHelper.extractDateTimeUs(local, props.tz);
    const offSetFull = dt.utcOffset + lf;
    return offSetFull;
  }

  const convertToTz = (date:Readonly<Date>) => {
    const dt = new Date(date);
    dt.setUTCHours(calcOffSet(date) + date.getUTCHours());
    return dt;
  }
  const convertFromTz = (date:Readonly<Date>|null) => {
    if (!date) {
      return null;
    }
    const dt = new Date(date);
    dt.setUTCHours(-calcOffSet(date) + date.getUTCHours());
    return dt;
  }

  return (
    <ReactDatePicker
      {...props}
      onChange={(date,ev)=>props?.onChange?props.onChange(convertFromTz(date),ev):null}
      endDate={props?.endDate?convertToTz(props.endDate):null}
      startDate={props?.startDate?convertToTz(props.startDate):null}
      selected={props?.selected?convertToTz(props?.selected):null}

    />
  )
}

