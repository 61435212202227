import React, {useEffect, useState} from "react";

import {IKeyValue} from "interfaces";
import {DropDown} from "components/common/form/DropDown";
import {adminApiServiceCommon} from "../../services/AdminApiService";

export const SymbolIdsDropdown: React.FunctionComponent<{
  errors: any,
  touched: any,
  name: string,
  defaultDescription?: string,
  defaultKey?: any,
  multiple?: boolean,
  showDefault?: boolean,
  label?: string | JSX.Element,
  className?: string,
}> = ({ errors, touched, name, defaultDescription = 'Select Stock' , showDefault=true, defaultKey = 0, multiple=false, label=<span className="me-2">Symbols</span>, className}) => {
  const [ symbols, setSymbols ] = useState<any | undefined>(undefined);

  useEffect(()=>{
    const _load = async () => {
      const symbols = await adminApiServiceCommon.getSymbols();
      setSymbols(new Map(symbols.map(s=>[
        s.id, s.description
      ])));
    }
    _load();
  }, [])

  return (
    <>
      {symbols  && <DropDown enumMap={symbols}
        className={className}
        defaultItem={showDefault ? { key: defaultKey, value: defaultDescription } as IKeyValue<number>: undefined}
        errors={errors}
        touched={touched}
        multiple={multiple}
        label={label}
        name={name} />}

      {!symbols && <div>Loading Symbols...</div>}
    </>
  );
};

