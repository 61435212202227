import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { IRecentActivity } from 'interfaces/IRecentActivity';
import { FollowedAvatars } from './FollowedAvatars';
import { Url } from '_constants';
import { NotificationHelper } from './NotificationHelper';

export const NewFollower: React.FunctionComponent<{activity: IRecentActivity}> = ({activity}) => {
  const navigate = useNavigate();
  const [text, setText] = useState<React.ReactNode>(<></>);

  useEffect(()=>{
    const load = async () => {
      if (activity.items && activity.items.length > 0) {
        const ids = activity.items.map(i=>i.userId as number);
        const names = await NotificationHelper.getUserNames(ids.splice(0,10));
        if (ids.length > 0) {
          setText(
            <>
              <span className="fw-bold">{names.map(n => n.username).join(', ')}</span> and {ids.length} other{ids.length > 1 ? 's' : ''} followed you
            </>);
        } else {
          setText(
            <>
            <span className="fw-bold">{names.map(n => n.username).join(', ')}</span> followed you
            </>
          );
        }        

      }
    }
    load();
  }, [activity])

  return (
    <FollowedAvatars numberOfAvatars={10} activity={activity} onClick={() => navigate(Url.USER_DASHBOARD_FOLLOWERS)}>
      {activity.items && activity.items.length > 0 &&
        <>{text}</>
      }
    </FollowedAvatars>

  );

}
