export enum Url {
  // ADMIN paths
  ADMIN_HOME_PAGE = '/admin',
  ADMIN_PROFILE = '/admin/profile',
  ADMIN_DASHBOARD = '/admin/dashboard',
  ADMIN_LOGIN = '/admin',
  ADMIN_USER = '/admin/user',
  ADMIN_CONTEST = '/admin/contests',
  ADMIN_LEAGUES = '/admin/leagues',
  ADMIN_LEAGUE_SEASONS = '/admin/leagues/seasons',
  ADMIN_LEAGUE_TEAMS = '/admin/leagues/teams',
  ADMIN_LEAGUE_TEAM_DETAIL = '/admin/leagues/teams/:teamId',
  ADMIN_STOCK_HISTORY = '/admin/stock/history',
  ADMIN_STOCK_HISTORY_DETAIL = '/admin/stock/history/:historyId',
  ADMIN_PREDICTIONS = '/admin/predictions',
  ADMIN_BADGES = '/admin/badges',
  ADMIN_REGISTRATIONS = '/admin/registrations',
  ADMIN_CUMULATIVE_DETAIL = '/admin/analysis/cumulative-detail',
  ADMIN_CUMULATIVE_DETAIL_DAY = '/admin/analysis/cumulative-detail-day',
  ADMIN_TRADEGRAM_PENDING_VERIFICATION = '/admin/tradegram/pending-verification',
  ADMIN_TRADEGRAM_EDIT_SECURITY_BULK = '/admin/tradegram/edit-security-bulk/:securityId',
  ADMIN_USERS_STATS = '/admin/report/users-stats',
  // ADMIN_SIGNALS = '/admin/report/signals',
  ADMIN_ANALYSIS_STRATEGY = '/admin/analysis/strategy-profit',
  ADMIN_SIGNAL_ALERTS = '/admin/analysis/signal/alerts',
  ADMIN_SIGNAL_ALERT_COMBINED_REPORT = '/admin/analysis/signal/alert-combined-report',
  ADMIN_SAVED_REPORTS = '/admin/report/saved-reports',
  ADMIN_TICKETS = '/admin/tickets',
  ADMIN_STREAK_STATS = '/admin/report/users-streak-stats',

  ADMIN_AUTOTRADE_DASHBOARD = '/admin/autotrade/dashboard',
  ADMIN_AUTOTRADE_SETUP = '/admin/autotrade/trade-setup',
  ADMIN_AUTOTRADE_SETUPS = '/admin/autotrade/trade-setups',
  ADMIN_AUTOTRADE_TRADES = '/admin/autotrade/trades',
  ADMIN_AUTOTRADE_TRADES_GROUPBY_SETUP = '/admin/autotrade/trades-groupby-setup',
  ADMIN_AUTOTRADE_TRADES_GROUPBY_SECURITY = '/admin/autotrade/trades-groupby-security',
  ADMIN_AUTOTRADE_SYMBOL_LIMITS = '/admin/autotrade/symbol/limits',
  ADMIN_AUTOTRADE_CONSENSUS_ORDERS = '/admin/autotrade/consensus-orders/:tradeId',
  ADMIN_AUTOTRADE_TRADES_GROUPBY_SCORE = '/admin/autotrade/trades-by-score',
  
  ADMIN_ANALYSIS_CUME_HISTORY = '/admin/analysis/cume-history/:signalAlertId',
  
  USER_TEAM_DETAIL = '/user/teams/:teamId',
  USER_TEAM_ROSTER = '/user/teams/roster/:teamId',
  USER_TEAM_PREDICTION = '/user/teams/predictions/:teamId',
  USER_TEAM_INVITATION = '/user/teams/invitation/:teamId',
  USER_TEAM_LEADERBOARD = '/user/teams/leaderboards/:teamId',
  USER_TEAM_CONTEST_RULES = '/team/contest-rules',

  USER_TICKETS = '/user/tickets',
  
  USER_MULTI_COMPARE_TOOL = '/tool/multi-compare',



  // USER paths
  USER_LOGIN = '/login',
  USER_LOGOUT = '/logout',
  USER_REGISTRATION = '/register',

  USER_DASHBOARD = '/user/dashboard',
  USER_ACCOUNT_PROFILE = '/user/profile',
  USER_SHOW_MORE = '/user/show-more',

  USER_DASHBOARD_PREDICTIONS = '/user/predictions',
  USER_DASHBOARD_TRADES = '/user/trades',
  USER_DASHBOARD_FOLLOWERS = '/user/followers',
  USER_DASHBOARD_FOLLOWS = '/user/follows',

  USER_DASHBOARD_SIGNALS_SIGNALS = '/user/dashboard/signals/signals',
  USER_DASHBOARD_SIGNALS_CHART = '/user/dashboard/signals/charts',

  USER_NOTIFICATIONS = '/user/notifications',
  USER_NOTIFICATIONS_RECENT_PREDICTIONS = '/user/notifications/recent-predictions',
  USER_NOTIFICATIONS_FOLLOWED_USER_TRADES = '/user/notifications/followed-user-trades',
  USER_NOTIFICATIONS_FOLLOWED_USER_PREDICTIONS = '/user/notifications/followed-user-predictions',
  

  FOLLOWED_BY_USER = '/user/followed',

  USER_MAKE_PREDICTION = '/user/make-prediction',

  USER_FAQ = '/faq',
  USER_PRIVACY_POLICY = '/privacy-policy',
  USER_TERMS = '/terms-and-conditions',
  USER_FORGOT_PASSWORD = '/forgot-password',
  USER_RESET_PASSWORD = '/reset-password/:authID',
  PUBLIC_CONTACT = '/contact',
  
  USER_JOIN_CONTEST = '/user/contest/join/:contestId',
  USER_CONTEST_LEADERBOARD = '/user/contest/leaderboard/:contestId',
  USER_CONTEST = '/user/contest/:contestId',
  USER_CONTEST_PREDICTIONS = '/user/contest/predictions/:contestId',

  USER_TRADEGRAM = '/tradegram',
  USER_TRADEGRAM_MY_GRAMS = '/tradegram/my-tradegrams',
  USER_TRADEGRAM_TRADES = '/tradegram/trades',
  USER_TRADEGRAM_RECENT = '/tradegram/recent-trades',
  USER_PROFILE_POSITIONS = '/stock/predictor/:userId/positions/:securityId/',

  USER_STATS_SYMBOL = '/user/stats/symbol/:userId',
  USER_STATS_PREDICTION_TYPE = '/user/stats/prediction-type/:symbol/:userId',
  USER_STOCK_EXPANDED = '/stock/expanded/:predictionId',

  PUBLIC_STOCK_PREDICTOR = '/stock/predictor',
  PUBLIC_STOCK_PREDICTIONS = '/stock/predictions/:stockSymbol',
  PUBLIC_STOCK_DETAIL = '/stock/prediction/:predictionId',
  

  USER_LEAGUE_RANKINGS = '/user/league/rankings',

  PUBLIC_WELCOME = '/welcome',

  LEADERBOARD_ROOT = '/leaderboard',
  LEADERBOARD_USERS = '/leaderboard/users',
  LEADERBOARD_TEAMS = '/leaderboard/teams',

  CONTESTS_ROOT = '/contests',
  
  // common paths

  CROWD_FUNDING = '/crowd-funding',
  DISCOUNT_PROMO = '/pro-signals-package',
  DISCOUNT_PROMO_SIGNUP = '/pro-signals-package/signup',

  PAGE_NOT_FOUND = '/not-found',
  LOGOUT = '/logout',
  DEFAULT_HOME = '/'
}
