import axios, { AxiosRequestConfig } from 'axios';
import { AuthenticationService } from './AuthenticationService';
import { IAPIPagedResponse } from 'interfaces/IAPIPagedResponse';


export class AbortableHttpService {

  constructor (
    protected authenticationService: AuthenticationService,
  ) {}


  async put<T>(url: string, payload: any, signal?: AbortSignal, isAnonymous?:boolean): Promise<T> {
    try {
      const config: AxiosRequestConfig = {
        signal,
        headers: !isAnonymous ? (await this.authenticationService.getAuthHeader()).headers : undefined
      }
      const response = await axios.put<any>(url, payload, config);
      if (response.data.error) {
        throw new Error(response.data.error.message)
      }
      if (response.data.data) {
        return response.data.data as T;
      }
      return response.data as T;
    } catch (error: any) {
      throw new Error((error as Error).message);
    }
  }

  async httpPost<T>(url: string, payload: any, signal?: AbortSignal, isAnonymous:boolean = false): Promise<T> {
    try {
      const config: AxiosRequestConfig = {
        signal,
        headers: !isAnonymous ? (await this.authenticationService.getAuthHeader()).headers : undefined
      }
      const response = await axios.post<any>(url, payload, config);
      if (response.data.error) {
        throw new Error(response.data.error.message)
      }
      if (response.data.data) {
        return response.data.data as T;
      }
      return response.data as T;
    } catch (error: any) {
      // if (axios.isCancel(error)) {
      //   console.error(`Request canceled: ${error.message}`)
      // }
      throw new Error((error as Error).message);
    }
  }

  async getMany<T>(url: string, payload?: any, isAnonymous?: boolean, signal?: AbortSignal): Promise<T[]> {
    try {
      const config: AxiosRequestConfig = {
        signal,
        headers: !isAnonymous ? (await this.authenticationService.getAuthHeader()).headers : undefined
      }
      const response = await axios.post<any>(url, payload, config);
      if (response.data.error) {
        throw new Error(response.data.error.message)
      }
      return response.data.data as T[];
    } catch (error: any) {
      throw new Error((error as Error).message);
    }
  }

  async getSingle<T>(url: string, isAnonymous?: boolean, signal?: AbortSignal, isGet=false): Promise<T> {
    const config: AxiosRequestConfig = {
      signal,
      headers: !isAnonymous ? (await this.authenticationService.getAuthHeader()).headers : undefined
    }
    try {
      const response = isGet ? await axios.get<any>(url, config) : await axios.post<any>(url, {}, config);
      if (response.data.error) {
        throw new Error(response.data.error.message)
      }
      return response.data as T;
    } catch (error: any) {
      throw new Error((error as Error).message);
    }
  }

  async fetchPost<T, N>(url: string, payload: any, signal?: AbortSignal, isAnonymous:boolean = false): Promise<IAPIPagedResponse<T, N>> {
    try {
      const config: AxiosRequestConfig = {
        signal,
        headers: !isAnonymous ? (await this.authenticationService.getAuthHeader()).headers : undefined
      }
      const response = await axios.post<any>(url, payload, config);
      if (response.data.error) {
        throw new Error(response.data.error.message)
      }
      return response.data as IAPIPagedResponse<T, N>
    } catch (error: any) {
      // if (axios.isCancel(error)) {
      //   console.error(`Request canceled: ${error.message}`)
      // }
      throw new Error((error as Error).message);
    }
  }


}
