import { useApi } from "./useApi";
import { ISignalPlan, userSignalApiService } from "services/UserSignalApiService";

export function useApiSignalPlans () {
  const load = async (abortSignal?: AbortSignal) => {
    return await userSignalApiService.getSignalPlans(abortSignal);
  }
  return useApi<ISignalPlan[], undefined>(load, undefined);
}

