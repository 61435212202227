import axios from 'axios';
import { AuthenticationService } from './AuthenticationService';


export class HttpService {

  constructor (
    protected authenticationService: AuthenticationService,
  ) {}

  async httpPost<T>(url: string, payload: any, withAuth: boolean = true): Promise<T> {
    try {
      const response = await axios.post<any>(url, payload, withAuth ? await this.authenticationService.getAuthHeader() : undefined);
      if (response.data.error) {
        throw new Error(response.data.error.message)
      }
      return response.data as T;
    } catch (error: any) {
      throw new Error((error as Error).message);
    }
  }

  async getMany<T>(url: string, payload?: any, authService?: AuthenticationService): Promise<T[]> {
    try {
      const header = authService ? await authService.getAuthHeader() : undefined;
      const response = await axios.post<any>(url, payload, header);
      if (response.data.error) {
        throw new Error(response.data.error.message)
      }
      return response.data.data as T[];
    } catch (error: any) {
      throw new Error((error as Error).message);
    }
  }

  async getSingle<T>(url: string): Promise<T> {
    try {
      const response = await axios.post<any>(url);
      if (response.data.error) {
        throw new Error(response.data.error.message)
      }
      return response.data as T;
    } catch (error: any) {
      throw new Error((error as Error).message);
    }
  }

}