import { PredictionTypeEnum } from 'predictagram-lib';

export {PredictionTypeEnum} from 'predictagram-lib';

export const predictionTypeNames: Map<number, string> = new Map<number, string>([
  [PredictionTypeEnum.VALUE_AT, 'Intraday Price'],
  [PredictionTypeEnum.VALUE_CLOSE, 'Closing Price'],
  [PredictionTypeEnum.VALUE_HIGH, 'High Price'],
  [PredictionTypeEnum.VALUE_LOW, 'Low Price'],
  [PredictionTypeEnum.VALUE_AT_8PM, 'At 8PM'],
  [PredictionTypeEnum.VALUE_30_MIN, '30 Minutes'],
  [PredictionTypeEnum.VALUE_30_MIN_UP_DOWN, 'Up or Down 30 Minutes'],
  [PredictionTypeEnum.VALUE_1H_UP_DOWN, 'Up or Down in 1 Hour'],
  [PredictionTypeEnum.VALUE_CLOSE_UP_DOWN, 'Up or Down at Close'],
  [PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D, 'Up or Down in 3 Days'],
  [PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE, 'Big Up or Down at Close'],
  [PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE, 'Big Up or Down in 1 Hour'],
  [PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE, 'Big Up or Down 30 Minutes'],
])