import { usePublicProfile } from '_hooks/usePublicProfile'
import { userPredictionApiService } from 'services/UserPredictionApiService'
import { AvatarLink, UserLink } from 'components/user/dashboard/UserInfo'
import { AvatarSizeEnum } from 'components/common/Avatar';
import { IUserStatistic } from 'interfaces/IUserStatistic';

export const TopPredictor: React.FunctionComponent<{ userStat: IUserStatistic }> = ({ userStat }) => {

  const profile = usePublicProfile(userStat.userId, userPredictionApiService);

  if (!profile.loaded) {
    return <div className="predictor-card top shimmer"></div>
  }

  if (profile.loaded ) {
    return (
      <div className="predictor-card top user-select-none gap-1">
        <div className="d-flex align-items-center gap-2">
          <div className="avatar">
            <AvatarLink profile={profile.item} size={AvatarSizeEnum.SMALL} />
          </div>

          <div className="username">
            <UserLink id={userStat.userId} username={profile.item.username} /><br />
            {/* <span className="fw-bold">{profile.item.username}</span><br /> */}
            {profile.item.twitterUsername && <div className="d-flex flex-row align-items-center gap-2">
              <img src="/images/x.svg" style={{width:"0.9375rem", height: "0.9375rem" }} alt="X" />
              <span className="twitter-name">{profile.item.twitterUsername}</span>
            </div>}
          </div>
        </div>

        <div className="d-flex align-items-end gap-1">
          <span role="img" aria-label="accuracy">🎯</span>
          <span className="text-14">{((userStat.score || 0) * 100).toFixed(0)}%</span>
          <span className="text-12">{userStat.predictionsCompleted} predictions</span>
        </div>

      </div>
    )
  }

  if (profile.error) {
    return <div className="error">
      {profile.error.message}
    </div>
  }

  return <></>


}