import React from 'react'
import { Modal } from 'react-bootstrap'
import { Wizard } from './Wizard'
import { ITradegramSecurity } from 'services/TradegramApiService'
import { CardContext } from '_utils/TradegramHelper'


interface IProps {
  show: any,
  handleClose: any,
  onSuccess: ()=>void,
  security?: ITradegramSecurity,
  cardContext?: CardContext
}

export const ModalWizard: React.FunctionComponent<IProps> = ({show, handleClose, onSuccess, security, cardContext}) => {
  return (
    <Modal className="modal-prediction" backdrop="static" keyboard={false} scrollable={true} show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="d-flex justify-content-center flex-column p-3 pb-0">
        <Wizard handleClose={handleClose} onSuccess={onSuccess} security={security} cardContext={cardContext}/>
      </Modal.Body>
    </Modal>
  )
}

