import { IPrediction } from "interfaces/IPrediction";
import { useCallback, useEffect, useState } from "react";
import { UserPredictionApiService, UserPredictionServiceError } from "services/UserPredictionApiService";
import { APIState } from "_constants/APIState";
import { PredictionTypeEnum } from "predictagram-lib";
import { ISymbolData } from "interfaces/ISymbolData";


export interface ISymbolDataSearchOptions {
  symbol: string,
  predictionType: PredictionTypeEnum,
  time?: number
}

export function useSymbolData(service: UserPredictionApiService, initialSearchOptions: ISymbolDataSearchOptions) {

  const [item, setItem] = useState<ISymbolData>({} as ISymbolData);
  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [error, setError] = useState<UserPredictionServiceError | undefined>(undefined);
  const [options, setOptions] = useState<ISymbolDataSearchOptions>(initialSearchOptions)

  const load = useCallback(async () => {
    try {
      setApiState(APIState.LOADING);
      const { symbol, predictionType, time } = options;
      const item = await service.getSymbolData(symbol, predictionType, time);
      setItem(item);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as UserPredictionServiceError);
    }
  }, [service, options])

  const refresh = () => {
    load();
  }

  useEffect(() => {
    load();
  }, [load, options])  
  
  
  return { item, apiState, error, options, setOptions, refresh };

}