import { Url } from "_constants";
import { IContest } from "interfaces/IContest";
import { NewYorkTz as tz } from "predictagram-lib";
import { useNavigate } from "react-router-dom";

export const ContestDetails: React.FunctionComponent<{ contest: IContest }> = ({ contest }) => {

  const navigate = useNavigate();

  if (!contest.stockSymbols || contest.stockSymbols.length === 0) return <></>

  const url = `${Url.PUBLIC_STOCK_PREDICTIONS.replace(":stockSymbol", contest.stockSymbols[0]) }`;

  const contestStart = tz.format(new Date(contest?.startTime * 1000));
  const contestEnd = tz.format(new Date(contest?.endTime * 1000));


  return (
    <div className="contest-details px-md-3 px-1">
      <div className="d-flex justify-content-start align-items-center gap-3 my-3">
        <div className="contest-details-symbol" role="button" onClick={()=>navigate(url)}>${contest.stockSymbols?.join(',')}</div>
        <div className="contest-body-details-range">{contestStart.monthDayWithSuffix()} {contestStart.hour24MinuteAmpm(false)} - {contestEnd.monthDayWithSuffix()} {contestEnd.hour24MinuteAmpm(false)}</div>
      </div>
    </div>
  );
}