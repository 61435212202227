import { useApi } from "./useApi";
import { IStreakStatSearchOptions, IUserStreakStat, adminApiReportService } from "services/AdminApiReportService";

export function useAdminUserStreakStats (filter: IStreakStatSearchOptions) {
  const load = async (filter: IStreakStatSearchOptions) => {
    return await adminApiReportService.getUserStreakStats(filter);
  }
  return useApi<IUserStreakStat[], IStreakStatSearchOptions>(load, filter);
}

