import React, { useState } from 'react';
import { PixelHelper } from '_utils/PixelHelper';
import { useUtmSource } from '_hooks/useUtmSource';
import { ModalWizard } from './ModalWizard';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';


export const MakeATradeButton:React.FunctionComponent<{onClick?: ()=>void}> = ({onClick}) => {

  const [ showDialog, setShowDialog ] = useState<boolean>(false);
  const navigate = useNavigate();
  const utmSource = useUtmSource();

  const _onClick = async () => {
    PixelHelper.clickMakeTrade(utmSource);
    if (onClick) {
      onClick();
    } else {
      setShowDialog(true);
    }
  }

  const onSuccess = () => {
    navigate(Url.DEFAULT_HOME);
  }

  return (
    <div className="make-prediction">
      <ModalWizard handleClose={()=>setShowDialog(false)} onSuccess={onSuccess} show={showDialog}/>
      <button className="btn-make-trade" onClick={_onClick}>Make Trade</button>
    </div>
  );
}