import { ConsensusHelper } from "_utils/ConsensusHelper";
import { useApi } from "./useApi";
import { IUserSignalAlert, IUserSignalAlertSearch, userSignalApiService } from "services/UserSignalApiService";
import { DateEx, StockHelper } from "predictagram-lib";

export function useApiSignals (filter?: IUserSignalAlertSearch) {
  const load = async (filter?: IUserSignalAlertSearch, abortSignal?: AbortSignal) => {
    const dt = ConsensusHelper.getConsensusDate(new DateEx());
    const startTime = StockHelper.workingHours(dt).start;
    const endTime = StockHelper.workingHours(dt).end;

    const f: IUserSignalAlertSearch = {
      ...filter,
      endTime: filter?.endTime || endTime.getTimeSec(),
      startTime: filter?.startTime || startTime.getTimeSec(),
    }
    return await userSignalApiService.getSignalAlerts(f, abortSignal);
  }
  return useApi<IUserSignalAlert[], IUserSignalAlertSearch | undefined>(load, filter);
}

