import React from "react";

export class Checkbox extends React.Component<{ label: any, value: any, onChange: any, name?: any }> {
  render() {
    let { label, value, onChange, name } = this.props;
    return (
      <label>
        <input type="checkbox" name={name} checked={value} onChange={onChange} />
        <span className="ms-1">{label}</span>
      </label>
    );
  }
}
