import React, { useState } from 'react'
import { useTeamPublic } from '_hooks/useTeamPublic';
import { Spinner } from 'components/common/Spinner';
import { ILeagueSeason, ITeam } from 'interfaces/league';
import { useOutletContext } from 'react-router-dom';
import { userLeagueApiService } from 'services/UserLeagueApiService';
import { DateFilterEnum, IDateFilterTimeRange, TeamDateFilters } from './components/TeamDateFilter';
import { APIState } from '_constants/APIState';
import { NewYorkTz as tz } from "predictagram-lib";
import { TeamStatistic } from './components/TeamStatistic';
import { ContestOverviewGotIt } from './components/ContestOverviewGotIt';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { Winners } from './components/Winners';
import { StorageKey, Url } from '_constants';
import { SeasonHelper } from '_utils/SeasonHelper';
import { Helmet } from 'react-helmet';
import { config } from 'config';

export const LeaderboardTeamsPage: React.FunctionComponent = () => {

  const { season, user, previousSeason } = useOutletContext<{ season: ILeagueSeason, user: IPublicProfile, previousSeason: ILeagueSeason }>();

  const {
    teams,
    apiState,
    error: teamError,
    options: teamSearchOption,
    setOptions: setTeamSearchOptions } = useTeamPublic(userLeagueApiService, {
      startTime: season.startTime,
      endTime: season.endTime,
      leagueSeasonId: season.id,
      minUserScore: season.teamMemberMinScore
    });


  const onClickCallback = (timeRange: IDateFilterTimeRange, dateFilter: DateFilterEnum, season: ILeagueSeason) => {
    setTeamSearchOptions({
      startTime: timeRange.startTime / 1000,
      endTime: timeRange.endTime ? timeRange.endTime / 1000 : undefined,
      leagueSeasonId: season.id,
      minUserScore: season.teamMemberMinScore
    })
  }

  const top = teams.filter((t: ITeam) => t.predictionsCompleted >= 200);
  const bottom = teams.filter((t: ITeam) => t.predictionsCompleted < 200);

  const isFirstDayOfSeason = teamSearchOption.startTime ? tz.isSameDay(new Date(), new Date(teamSearchOption.startTime * 1000)) : false;

  const [showWinner, setShowWinner] = useState<boolean>(String(localStorage.getItem(StorageKey.SEEN_SEASON_WINNER) || '') !== '1');
  // const [showRules, setShowRules] = useState<boolean>(String(localStorage.getItem(StorageKey.SEEN_TEAM_CONTEST_RULES) || '') !== '1');
  const [showRules, setShowRules] = useState<boolean>(false); // restore above line when contests resume!

  return (
    <>
      <Helmet>
        <title>Predictagram: Teams Leaderboard</title>
        <meta property="og:title" content={`Predictagram: Teams Leaderboard`} />
        <meta property="og:description" content={`See which team has the most accurate predictions!`} />
        <meta property="og:url" content={`${config.wwwDomain}/${Url.LEADERBOARD_TEAMS}`} />
      </Helmet>

      <div className="team-ranked-list">
        {(showWinner || showRules) &&
          <div className="container g-0 mb-4">
            <div className="row">
              {showWinner &&
                <div className="col-12 col-md-6">
                  <Winners setShow={setShowWinner} />
                </div>
              }

              {showRules &&
                <div className="col-12 col-md-6 my-3 my-md-0">
                  <ContestOverviewGotIt setShow={setShowRules} season={season} />
                </div>
              }
            </div>
          </div>
        }

        <div className="my-3">
          {(!user.team?.id) &&
            <div className="mx-2 mb-3">
              <div className="fw-bold text-18">{SeasonHelper.seasonStartEnd(new Date(), season)}</div>
              {SeasonHelper.isDuringSeason(new Date(), season) &&
                <div className="text-14">Choose a team below to participate in the current season.</div>
              }
            </div>
          }

          <TeamDateFilters onClick={onClickCallback} season={season} previousSeason={previousSeason} initialDateRange={DateFilterEnum.THIS_SEASON} />
        </div>

        <div className="team-roster mx-3 my-2">
          {apiState === APIState.LOADING && <Spinner />}

          {apiState === APIState.LOADED && teams.length === 0 && <div className="my-5 d-flex justify-content-center align-items-center">No rankings available.</div>}

          {apiState === APIState.LOADED && top.length > 0 && top.map((m: ITeam, i: number) => <div className={`row my-1 py-2 team-roster-item ${isFirstDayOfSeason ? 'team-roster-item-no-bg' : ''}`} key={`roster-key-${i}`}>
            <TeamStatistic team={m} index={i + 1} showRank={!isFirstDayOfSeason} />
          </div>)
          }

          {apiState === APIState.LOADED && bottom.length > 0 && bottom.map((m: ITeam, i: number) => <div className={`row my-1 py-2 team-roster-item ${isFirstDayOfSeason ? 'team-roster-item-no-bg' : ''}`} key={`roster-key-${i}`}>
            <TeamStatistic team={m} index={i + top.length + 1} showRank={!isFirstDayOfSeason} />
          </div>)
          }


          {teamError && <div>{teamError.message}</div>}
        </div>
      </div>
    </>
  );


}

