import React, { useContext, useEffect } from 'react'
import { AccountContext } from 'components/common';
import { TradegramLockedContent } from 'components/user/tradegram/TradegramLocked';
import { BetaText } from './BetaText';
import { UserWithCoin } from './Icons';


export const BetaTradegramRouter: React.FunctionComponent<{ children: JSX.Element, showHead?: boolean }> = ({ children, showHead = false }) => {

  const acctContext = useContext(AccountContext);

  useEffect(()=>{
    if (acctContext?.account) {
      acctContext.refreshAccount();
    }
  },[])


  if (acctContext?.isLoggedIn() && !!acctContext?.account.allowTradegram) {
    return children
  }

  if (!showHead) {
    return (
      <div>
        <TradegramLockedContent />
      </div>
    );
  }

  return (
    <div className="tradegram-beta min-vh-100">
      <div className="tradegram-beta-head d-flex justify-content-end flex-column bg-gradient-purple pb-2 pb-md-3">
        <div className="d-flex justify-content-start align-items-center gap-2 ps-3"><UserWithCoin /> <span className="fw-bold">Trades</span> <BetaText /></div>
      </div>
      <TradegramLockedContent />
    </div>
  )
}
