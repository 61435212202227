import React from 'react'
import { IAutoTradeIbkrOrder } from 'predictagram-lib'
import { formatUSCurrency } from './Trades'
import { NewYorkDate } from 'components/common/NewYorkDate'

export const IbkrOrder: React.FunctionComponent<{ orders: IAutoTradeIbkrOrder[] }> = ({ orders }) => {
  return (
    <div className="ibkr-order">
      <table className="table">

        <tbody>
          <tr>
            <th>Create Time</th>
            <th>Update Time</th>
            <th>Status</th>
            <th className="text-end">Side</th>
            <th className="text-end">Type</th>
            <th className="text-end">Sec.</th>
            <th className="text-end">Price</th>
            <th className="text-end">Qty Filled</th>
            <th className="text-end">IBKR ID</th>
            <th className="text-end">Loc. ID</th>
          </tr>
          {orders.map((order) => <tr key={`tr-key-${order.id}`}>
            <td><NewYorkDate dateInMs={order.createdAt * 1000} /></td>
            <td><NewYorkDate dateInMs={order.updatedAt * 1000} /></td>
            <td><div className={`${order.orderStatus?.toUpperCase()==="FILLED" ? 'text-green fw-bold' : ''}`}>{order.orderStatus}</div></td>
            <td>{order.orderSide}</td>
            <td>{order.orderType}</td>
            <td>{order.orderSecurityType}</td>
            <td className="text-end">{formatUSCurrency(order.orderPrice)}</td>
            <td className="text-end">{order.orderQuantityFilled}</td>
            <td className="text-end">{order.orderId}</td>
            <td className="text-end">{order.id}</td>
          </tr>)}
          <tr>
          </tr>
        </tbody>
      </table>


    </div>
  )
}
