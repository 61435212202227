import React, { useState } from 'react'
import { APIState } from '_constants/APIState'
import { Spinner } from 'components/common/Spinner'
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect'
import * as Icons from "components/common/Icons";
import { IFilteredPredictions } from '_hooks/useFilteredPredictions'
import { Happenings } from './Happenings'
import { PredictionListLazy } from 'components/public/ListingsPage/components/PredictionListLazy'
import { SymbolFilter, SymbolFilterTypeEnum } from './filters/SymbolFilter'
import { IAccountSettings } from 'interfaces/account/IAccountSettings'
import { PredictionTypeDropDownFilter, PredictionTypeFilterEnum, predictionTypeDropDownItemsMap } from './filters/PredictionTypeDropDownFilter'
import { UserTypeDropDownFilter, UserTypeFilterEnum } from './filters/UserTypeDropDownFilter'
import { useDashboardSettings } from '_hooks/useDashboardSettings'
import { StorageKey, Url } from '_constants'
import { useNavigate } from 'react-router-dom'
import { useModalHelp } from '_hooks/useModalHelp';

interface IProps {
  settings: IAccountSettings,
  userTypeFilterState: [UserTypeFilterEnum, React.Dispatch<React.SetStateAction<UserTypeFilterEnum>>],
  isExpandedState: [boolean, (value: boolean) => void],
  predictionTypeFilterState: [PredictionTypeFilterEnum, (predictionFilterType: PredictionTypeFilterEnum) => void],
  selectedSymbolsState: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  symbolFilterTypeState: [SymbolFilterTypeEnum, React.Dispatch<React.SetStateAction<SymbolFilterTypeEnum>>],
  filteredPredictions: IFilteredPredictions,
}

export const PredictionsFeed: React.FunctionComponent<IProps> = ({
  settings,
  userTypeFilterState,
  isExpandedState,
  predictionTypeFilterState,
  selectedSymbolsState,
  symbolFilterTypeState,
  filteredPredictions,
}) => {


  const [dashboardSettings, setDashboardSettings] = useDashboardSettings(localStorage, StorageKey.DASHBOARD_SETTINGS);

  const [isExpanded, setIsExpanded] = isExpandedState;
  const [userTypeFilter, setUserTypeFilter] = userTypeFilterState;
  const [predictionTypeFilter, setPredictionTypeFilter] = predictionTypeFilterState;
  const modalHelp = useModalHelp();
  const navigate = useNavigate();


  const onChangeSymbols = (symbols: string[]) => {
    filteredPredictions.setOptions({
      ...filteredPredictions.options,
      symbolNames: symbols
    })
  }

  const _setSelectedPredictionTypes = (predictionTypeFilter: PredictionTypeFilterEnum) => {
    filteredPredictions.setOptions({
      ...filteredPredictions.options,
      predictionTypes: predictionTypeDropDownItemsMap.get(predictionTypeFilter)?.value
    })
    setPredictionTypeFilter(predictionTypeFilter);
  }

  const _setIsExpanded = (value: boolean) => {
    setIsExpanded(value);
    setDashboardSettings({
      ...dashboardSettings,
      isExpanded: value,
    })
  }

  return (
    <div className="">
      
      {modalHelp.modalElement()}

      <div className="mx-3 my-3 my-md-5 d-flex gap-1 align-items-start flex-column">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex justify-content-start gap-3 ms-md-3">
            <div className="dashboard-tab dashboard-tab-selected">Predictions</div>
            <div className="opacity-70 dashboard-tab" role="button" onClick={()=>navigate(Url.USER_DASHBOARD_TRADES)}>Trades</div>
          </div>
          <div className="d-flex align-items-center justify-content-start d-block d-md-none gap-2">
            <div className={`chart-btn p-2 ${isExpanded ? "chart-btn-enabled" : ""}`} role="button" onClick={() => _setIsExpanded(!isExpanded)} title="Toggle Chart"><Icons.LineChartWithBorder on={isExpanded} width={18} height={18} /> </div>
            {modalHelp.helpIcon()}
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="dashboard-subfilters mt-3">
            <div className="dashboard-subfilters-container mx-md-3">
              <div className="dashboard-subfilter" title="Filter by User Type">
                <UserTypeDropDownFilter initialValue={userTypeFilter} setValue={setUserTypeFilter} className="user-type-filter" />
              </div>
              <div className="dashboard-subfilter" title="Filter by Stock Symbol">
                <SymbolFilter settings={settings} selectedSymbolsState={selectedSymbolsState} onChangeSymbols={onChangeSymbols} symbolFilterTypeState={symbolFilterTypeState} />
              </div>
              <div className="dashboard-subfilter" title="Filter by Prediction Type">
                <PredictionTypeDropDownFilter setValue={_setSelectedPredictionTypes} initialSelectedKey={predictionTypeFilter} className="prediction-type-filter" />
              </div>
            </div>
          </div>

          <div className="d-none d-md-block">
            <div className="d-flex flex-nowrap align-items-center justify-content-start gap-2">
              <div className={`chart-btn p-2 ${isExpanded ? "chart-btn-enabled" : ""}`} role="button" onClick={() => _setIsExpanded(!isExpanded)} title="Toggle Chart"><Icons.LineChartWithBorder on={isExpanded} width={18} height={18}/> </div>
              {modalHelp.helpIcon()}
            </div>
          </div>
        </div>

        {filteredPredictions.apiState === APIState.LOADING ? (
          <div className="happening-item"><Spinner /></div>
        ) : (
          <div className="container g-0 p-md-3">
            {isExpanded ?
              <PredictionListLazy predictions={filteredPredictions.items} onSelectSort={() => { }} orderBy={sortTypeEnum.LATEST} showMenu={false} />
              :
              <Happenings predictions={filteredPredictions.items} />
            }
          </div>
        )}

      </div>
    </div>
  )
}
