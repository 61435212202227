import { ShareContest } from "components/common/ShareContest";
import { ContestStats } from "./ContestStats";
import { IContest } from "interfaces/IContest";
import { ContestRulesLinkWhite } from "components/user/league/leaderboards/components/ContestRulesLink";

export const ContestHead: React.FunctionComponent<{ contest: IContest }> = ({ contest }) => {
  return (
    <div className="contest-head p-3">
        <div className="d-flex justify-content-between">
          <div className="contest-head-logo-image">
            <img src={contest.logoUrl} alt={contest.name} />
          </div>

          <div className="contest-head-share">
            <ShareContest contest={contest} />
          </div>
        </div>

        <div className="contest-head-name mt-3">{contest?.name}</div>
        <div className="contest-head-description mt-2">{contest?.description}</div>

        <ContestStats contest={contest} />

        <ContestRulesLinkWhite />

    </div>
  );
}