import React, { useEffect, useState } from 'react';
import { predictionService } from 'services/PredictionService';
import ReactApexChart from 'react-apexcharts';
import { ChartHelper } from 'components/public/Stock/ChartHelper';
import { IQuote } from 'components/public/Stock/IQuote';
import * as moment from 'moment-timezone';
import { ChartFilters } from 'components/public/Stock/ChartFilters';
import Form from 'react-bootstrap/Form'
import { TimeZone } from '_constants/TimeZone';
import {Helper} from "_utils";
import { StockHelper } from 'predictagram-lib';


export const TodayChart: React.FunctionComponent<{ symbol: string, scaled: boolean, currentDate: Date, previousDate?: Date }> = ({ symbol, scaled, currentDate, previousDate }) => {

  const [chartData, setChartData] = useState<any>(undefined);
  const [options, setOptions] = useState<any>(undefined);
  const [status, setStatus] = useState<any>(undefined);
  const [todayDisplay, setTodayDisplay] = useState<string>('block');
  const [showGraph, setShowGraph] = useState<boolean>(true);
  const [ opacity, setOpacity] = useState<number>(95);
  const [ backgroundColor, setBackgroundColor] = useState<string>('rgba(251, 251, 251, 0.75)');

  const loadData = async () => {
    try {
      const priorDates = StockHelper.workingHours(previousDate);
      const priorDay: ReadonlyArray<IQuote> = previousDate ? await predictionService.getBarsData(symbol, priorDates.start, priorDates.end) : [];
      const dates = StockHelper.workingHours(currentDate);
      const _today: ReadonlyArray<IQuote> = await predictionService.getBarsData(symbol, dates.start, dates.end);
      const today = _today.filter(ChartFilters.tradingHours);
      const prior2Hours = priorDay.filter(ChartFilters.last2Hours);

      if (!scaled) {
        setChartData([
          {
            name: 'Today',
            type: 'line',
            data: ChartHelper.mergeFullXAxis([...prior2Hours, ...today].map(ChartHelper.toLineData), 1),
            toolbar: {
              show: false,
            },
          },
        ])
      }

      if (scaled) {
        setChartData([
          {
            name: 'Today',
            type: 'line',
            data: ChartHelper.mergeFullXAxis([...prior2Hours, ...today].map(ChartHelper.deltaPct), 1),
            toolbar: {
              show: false,
            },
          },
        ])

      }
    } catch (error: any) {
      setStatus((error as Error).message)
    }

    const _options: ApexCharts.ApexOptions = {
      colors: ['#458ff6'],
      stroke: {
        // dashArray: [5, 10, 10],
        colors: ['#458ff6'],
        width: 2,
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        labels: {
          colors: ['#458ff6'],
          useSeriesColors: false
        }
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: 'bottomLeft',
        },
      },
      // tooltip: {
      //   // custom: function({series, seriesIndex, dataPointIndex, w}) {
      //   //   return `<div class="mx-3">
      //   //     <span>${series[seriesIndex][dataPointIndex]}</span>
      //   //     </div>`;
      //   // }
      // },
      plotOptions: {
        candlestick: {
          colors: scaled ? {
            upward: '#336699',
            downward: '#336699'
          } : {
            upward: '#00cc00',
            downward: '#cc3300',
                    
          }
        }
      },
      xaxis: {
        labels: {
          show: true,
          hideOverlappingLabels: true,
          // formatter: function (value, timestamp, opts) {
          //   // const t = moment.tz(value, TimeZone.AMERICA_NEW_YORK);
          //   // return ['15:00', '09:30', '12:00'].includes(t.format('HH:mm')) ? t.format('YYYY-MM-DD HH:mm') : '';
          //   return [0, 15, 30, 45].includes(new Date(value).getMinutes()) ? value : '';
          // }
        }
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value: number) => {
            let result = '';
            try {
              result = `${parseFloat(value.toString()).toFixed(3)}`;
              return result;
            } catch (error) {
              return '';
            }
          }
        }
      },
      annotations: {
        xaxis: [
          {
            x: `${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')} 09:30`,
            borderColor: '#000000',
          },
          {
            x: `${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')} 09:30`,
            borderColor: '#000000',
          },
          {
            x: `${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')} 12:00`,
            borderColor: '#000000',
          },
          {
            x: `${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')} 14:30`,
            borderColor: '#000000',
          },
        ]
      }
    }

    setOptions(_options);
  }

  const refresh = () => {
    loadData();
  }

  useEffect(() => {
    try {
      loadData();
    } catch (error) {
      console.error({ error });
    }

  }, [symbol, scaled, status])


  const style: React.CSSProperties = {
    display: "block",
  }

  return (
    <div className={`d-${todayDisplay}`} style={ style } >
      <div className='d-flex justify-content-end' style={{ backgroundColor: '#23afaf38' }}>
        <div className="fw-bold py-3 px-3" style={{ backgroundColor: '#23afaf' }} >{symbol} - {moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('ddd, YYYY-MM-DD')}</div>
        <div className="mx-3 align-self-center">
          <Form.Check
            type="switch"
            id="today-toggle"
            label="Overlay"
            defaultChecked={showGraph}
            onClick={() => { setShowGraph(!showGraph) }}
          />
        </div>
        {/* <div className="mx-3 align-self-center">
          Opacity:
          <input type="text" style={{width:'50px'}} value={opacity} onChange={onChangeOpacity}/>
        </div> */}
        <div className="mx-3 align-self-center">
          <button className="btn btn-primary" onClick={() => { refresh() }}>Refresh</button>
        </div>
      </div>

      {status &&
        <div style={{ width: "950px", height: "650px" }} className="border d-flex justify-content-center flex-column">
          <div className="alert alert-warning p-5" role="status" >
            Could not load today's chart. Try again in a few minutes. API may be unavailable due to delay.
            <p className="m-2">Error: {status}</p>
            <button className="btn btn-primary" onClick={() => setTodayDisplay('none')}>Close</button>
          </div>
        </div>
      }

      {!chartData && !status &&
        <div style={{ width: "950px", height: "650px" }} className="border d-flex justify-content-center flex-column">
          <div className="spinner-border align-self-center" role="status">
          </div>
          <span className="align-self-center">Loading {moment.tz(currentDate,TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')}</span>
        </div>
      }

      {chartData &&
        <div style={{ backgroundColor: `${backgroundColor}` }} className={`d-${showGraph ? "block" : "none"}`}>
          <ReactApexChart options={options} series={chartData} type="line" height={650} width="1000px" />
        </div>
      }
      <div className="fw-bold py-1" style={{ backgroundColor: '#23afafd9' }}> </div>


    </div>
  );
}
