import React from 'react';
import { usePublicProfile } from "_hooks/usePublicProfile";
import { Spinner } from 'components/common/Spinner';
import { Helper } from '_utils';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { IPrediction } from 'interfaces/IPrediction';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar';
import { UrlHelper } from '_utils/UrlHelper';
import { AbsoluteLink } from 'components/common/AbsoluteLink';


export const UserLink: React.FunctionComponent<{id: number, username: string}> = ({id, username}) => {
  return <AbsoluteLink className="text-lavender text-underline-hover d-inline" to={UrlHelper.getProfileUrl(id)}>{username}</AbsoluteLink>
}

export const AvatarLink: React.FunctionComponent<{profile: IPublicProfile, size: AvatarSizeEnum}> = ({profile, size}) => {
  return <AbsoluteLink to={`${UrlHelper.getProfileUrl(profile.userId as number)}`}>
    <Avatar profile={profile} size={size} />
  </AbsoluteLink>
}


export const UserInfo:React.FunctionComponent<{prediction: IPrediction}> = ({prediction}) => {

  const profile = usePublicProfile(Number(prediction.userId), userPredictionApiService, true);

  if (!profile.loaded) {
    return <Spinner />
  }

  return (
    <div className="profile d-flex align-items-center gap-2">
      <div className="profile-image">
        <AvatarLink profile={profile.item} size={AvatarSizeEnum.SMALL_MEDIUM} />
      </div>

      <div>
        <div className="d-flex justify-content-center gap-3">
          <div className="text-12 fw-bold"><UserLink id={Number(prediction.userId)} username={profile.item.username}/></div>
          <div className="text-12"><span role="img" aria-label="accuracy">🎯</span>{((profile.item.predictions.averageScore || 0) * 100).toFixed(0)}%</div>
        </div>
        <div className="text-12 fst-italic">{Helper.timeElapsed(new Date(Number(prediction.createdAt) * 1000), new Date())} ago</div>
      </div>
    </div>
  );
}