import { AccountContext } from 'components/common'
import React, {useContext} from 'react'
import { StockListingPage } from './StockListingPage';
import { FloatingMakeAPredictionButton } from 'components/common/FloatingMakeAPredictionButton';
import { StockListingPageAnon } from './StockListingPageAnon';

export const StockListingRedirector: React.FunctionComponent = () => {
  const acctContext = useContext(AccountContext);

  if (acctContext?.isLoggedIn()) {
    return <><StockListingPage /><FloatingMakeAPredictionButton /></>
  }

  return (
    <StockListingPageAnon />
  )
}
