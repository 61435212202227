import { StorageKey, Url } from '_constants'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import * as Icons from 'components/common/Icons';
import { useDefaultSeason } from '_hooks/useDefaultSeason';
import { userLeagueApiService } from 'services/UserLeagueApiService';
import { AccountContext } from 'components/common';
import { usePublicProfile } from '_hooks/usePublicProfile';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { usePreviousSeason } from '_hooks/usePreviousSeason';
import { WeeklyContest } from './components/WeeklyContest';
import Cookies from 'js-cookie';
import { PixelHelper, VerificationEventEnum } from '_utils/PixelHelper';
import { ModalPhoneConfirmationWizard } from 'components/user/account/ModalPhoneConfirmationWizard';
import { NotificationDot } from 'components/common/NotificationDot';
import { useContests } from '_hooks/useContests';
import { ContestHelper } from '_utils/ContestHelper';
import { LevelBadges } from 'components/user/badges/LevelBadges';
import { RoundBackButton } from 'components/common/buttons/RoundBackButton';
import { LeagueHelper } from 'components/user/rankings/LeagueHelper';
import { LeaguePrizes } from 'components/user/rankings/LeagueRankingsPage';

export const LeaderboardLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const contests = useContests();

  const [showPrize, setShowPrize] = useState<boolean>(LeagueHelper.showLeaguePriceCard(localStorage.getItem(StorageKey.SEEN_LEAGUE_PRIZE), new Date()));
  const { item: season, loaded: loadedSeason, error: errorSeason } = useDefaultSeason(userLeagueApiService);
  const { item: prevSeason, loaded: loadedPrevSeason, error: errorPrevSeason } = usePreviousSeason(userLeagueApiService);

  const acctContext = useContext(AccountContext);
  const acctId: number = (acctContext?.account ? acctContext.account.id : 0) || 0;
  const userPublicProfile = usePublicProfile(acctId, userPredictionApiService);

  const [showPhoneModal, setShowPhoneModal] = useState<boolean>(false);
  const [wasAnon, setWasAnon] = useState<boolean>(acctContext?.isLoggedIn() || true); // keep original state

  useEffect(() => {
    if (acctContext?.isLoggedIn()) {

      if (!!acctContext.account.phoneNumberConfirmed) {
        return;
      }

      const lastReminder = Cookies.get(StorageKey.COOKIES_VERIFY_REMINDER_TEAMLB);
      const dayInMs = 1000 * 60 * 60 * 24;
      const dt = new Date();

      if (!lastReminder || (dt.getTime() - parseInt(lastReminder) >= (dayInMs * 1))) { // is it past one day
        setShowPhoneModal(true);
        Cookies.set(StorageKey.COOKIES_VERIFY_REMINDER_TEAMLB, dt.getTime().toString(), { expires: dayInMs });
        PixelHelper.trackVerificationEvent(VerificationEventEnum.SHOW_REMINDER);
      } else {
        if (!wasAnon) {
          setShowPhoneModal(false);
        }
      }
    } else {
      setShowPhoneModal(false);
    }
  }, [acctContext, wasAnon])


  const pathMatches = (path: string) => {
    return location.pathname === path;
  }

  const handleClose = () => {
    setShowPhoneModal(false);
  }

  const onSuccessPhone = () => {
    Cookies.remove(StorageKey.COOKIES_VERIFY_REMINDER_TEAMLB);
  }

  return (
    <div className="leaderboard container">

      {acctContext?.isLoggedIn() &&
        <ModalPhoneConfirmationWizard show={showPhoneModal} onSuccess={onSuccessPhone} handleClose={handleClose} />
      }

      <div className="mx-3 mb-3"><LevelBadges /></div>

      {(pathMatches(Url.LEADERBOARD_USERS) || pathMatches(Url.LEADERBOARD_ROOT)) && 
      
        <div className="d-flex flex-column flex-md-row gap-3">
          {showPrize && <LeaguePrizes onClose={()=>{
            setShowPrize(false);
            localStorage.setItem(StorageKey.SEEN_LEAGUE_PRIZE, new Date().getTime().toString());
          }}/>}

          <WeeklyContest />
        </div>
      }

      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex justify-content-start gap-2">
          <RoundBackButton onClick={()=>navigate(-1)}/>
          <div className="leaderboard-head-title ms-3">Leaderboard</div>

        </div>


        <div style={{ position: "relative" }}>
          <div className="btn btn-tertiary text-14" role="button" onClick={() => navigate(Url.CONTESTS_ROOT)}>
            <div className="d-flex justify-content-center align-items-center gap-1 px-2"><Icons.Trophy /><span>Contests</span></div>
          </div>

          {contests.apiState.isLoaded && contests.data !== null &&
            <>
              {contests.apiState.isLoaded && contests.data !== null && ContestHelper.getActiveContests(contests.data).length > 0 &&
                <div style={{ position: "absolute", top: "-0.5rem", left: "5px" }}>
                  <NotificationDot>!</NotificationDot>
                </div>
              }
            </>
          }
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-center gap-3 my-4">
        <div role="button" className={`d-flex gap-2 btn-tabs p-2 px-3 ${pathMatches(Url.LEADERBOARD_USERS) || pathMatches(Url.LEADERBOARD_ROOT) ? 'btn-tabs-active-sort' : ''}`} onClick={() => navigate(Url.LEADERBOARD_USERS)}><Icons.Person on={pathMatches(Url.LEADERBOARD_USERS)} />Users</div>
        <div role="button" className={`d-flex gap-2 btn-tabs p-2 px-3 ${pathMatches(Url.LEADERBOARD_TEAMS) ? 'btn-tabs-active-sort' : ''}`} onClick={() => navigate(Url.LEADERBOARD_TEAMS)}><Icons.Banner on={pathMatches(Url.LEADERBOARD_TEAMS)} />Teams</div>
      </div>

      {loadedSeason && loadedPrevSeason &&
        <Outlet context={{ season, user: userPublicProfile.item, previousSeason: prevSeason }} />
      }

      {errorSeason &&
        <div><pre>{JSON.stringify(errorSeason, null, 2)}</pre></div>
      }

      {errorPrevSeason &&
        <div><pre>{JSON.stringify(errorPrevSeason, null, 2)}</pre></div>
      }

    </div>
  )
}

