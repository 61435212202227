import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';

export const Debug: React.FunctionComponent<{ children: any }> = ({ children }) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const debug = queryParams.get('debug');
  const [show, setShow] = useState<boolean>(!!debug);

  if (!debug) return <></>;

  return (
    <div style={{ zIndex: 1000, position: "absolute", top: 0, left: 0, backgroundColor: "white", opacity: "0.75" }}>
      <button type="button" onClick={()=>setShow(!show)}>{show ? 'Hide' : 'Show Debug'}</button>
      {show && <>{children}</>}
    </div>
  );
}
