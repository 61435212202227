import React, { useEffect, useRef, useState } from 'react';
import { Share } from './Icons';


interface IAppProps {
  children: (props: {setShow: any}) => React.ReactNode,
  onCopyLink: any;
  title?: string | JSX.Element;
  className?: string;
  location?: "top" | "bottom"
}
export const ShareBase: React.FunctionComponent<IAppProps> = ({ children, onCopyLink, title = 'Share', className, location }) => {

  const divRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState<boolean>(false);
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {

      const targetElement = event.target as HTMLElement;

      // check if click inside the social media menu. Without this check, the click is canceled by setShow(false);
      if (targetElement.className === 'rws-icon' || targetElement.tagName === 'path') return; // social media menus

      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef])

  const _onCopyLink = () => {
    setShow(false);
    onCopyLink();
  }

  return <>
    <div className={`w-100 share-component d-flex flex-row justify-content-center align-items-center ${className || ''}`} role="button" onClick={() => setShow(!show)}>
      <div >
        <div className="d-flex flex-row">
          <Share width={16} height={16}/>{title !== "" ? <span className="ms-2">{title}</span> : <></>}
        </div>
      </div>

      {show &&
        <div className={`share-menu ${location==="bottom" ? "share-menu-bottom" : ""} my-3 d-flex flex-column align-items-start px-3`} ref={divRef}>
          <div className="share-menu-item my-1" role="button" onClick={()=>_onCopyLink()}>Copy Link</div>
          <div className="share-menu-item my-1">{children({setShow})}</div>
        </div>
      }
    </div>

  </>
  

}