import React from 'react';
import { IPrediction } from 'interfaces/IPrediction';
import { NewYorkTz as tz} from 'predictagram-lib';

export const PredictionTime:React.FunctionComponent<{prediction: IPrediction}> = ({prediction}) => {


  const Time = () => {
    if (!prediction || !prediction.createdAt) {
      return <></>
    }
    const createdAt = new Date(prediction.createdAt * 1000);
    const daysAgo = tz.getDaysAgo(createdAt);
    if (daysAgo.search(/(yesterday|today)/gi) === 0) {
      return <>{tz.format(createdAt).hour24MinuteAmpm()} {tz.getTimezoneOffset(createdAt) === -5 ? 'EST' : 'EDT'}</>
    }
    return <></>
  }

  return (
    <>
    {prediction.createdAt &&
      <div className="align-self-center flex-fill text-end predictor-card-time-left">{(tz.getDaysAgo(new Date(prediction.createdAt * 1000))).replaceAll(' days ago', 'd ago')} <br/><Time /></div>
    }
    </>
  );
}