import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IStockSymbol } from 'interfaces/IStockSymbol';
import { Helper } from '_utils';
import { Validator, validationMap } from 'schemas';
import { Formik, Form } from 'formik';
import { FieldWithError } from 'components/common';
import { BrokerTransactionTypeDropdown, optionTranTypeDropdownItemsMap } from './BrokerTransactionTypeDropdown';
import ReactDatePicker from 'react-datepicker';
import { TradegramHelper, CardContext } from '_utils/TradegramHelper';
import { MessengerContext } from 'components/common/messenger';
import { ITradegramParams, ITradegramSecurity, tradegramApiService } from 'services/TradegramApiService';
import {
  BrokerSecurityTypeEnum,
  BrokerTransactionTypeEnum,
  BrokerTransactionTypeHelper,
  OptionType
} from 'predictagram-lib';
import { MessageHelper } from '_utils/MessageHelper';
import { CloseButton } from 'react-bootstrap';
import { SearchStock } from './SearchStock';
import { RoundBackButton } from '../../common/buttons/RoundBackButton';
import { SecuritiesDatasetEnum, useTradegramSecurities } from '_hooks/useTradegramSecurities';
import { Tooltip } from 'components/common/Tooltip';
import {IParsedOption} from "../../../_utils/OptionPattern";

interface IOptionTransaction {
  stockSymbol: string,
  contracts: number,
  expiration: number,
  strikePrice: number,
  actionType: number,
  executedPrice: number,
  optionType: OptionType,
  transactionType: BrokerTransactionTypeEnum,
  transactionDescription: string,
}

enum Step {
  PICK_SYMBOL = 2,
  ENTER_DETAILS = 3,
  ENTER_EXECUTED_PRICE = 4,
  ENTER_CONTRACT_COUNT = 5,
  FINAL_REVIEW = 6
}

interface IProps {
  onSuccess: () => void,
  onCancel: () => void,
  security?: ITradegramSecurity,
  cardContext?: CardContext,
  shortName?: string
}

type InitialWizardValues = {
  currentStep: Step,
  brokerTransactionType: BrokerTransactionTypeEnum,
  stockSymbol: IStockSymbol,
  stockSymbolOption: string,
  shortName: string,
  optionType: OptionType,
  optionTransaction: IOptionTransaction,
}

export const AddOption: React.FunctionComponent<IProps> = ({ onSuccess, onCancel, security, cardContext, shortName }) => {

  const mySecurities = useTradegramSecurities(tradegramApiService, SecuritiesDatasetEnum.OWNED);

  const initialWizardValues: InitialWizardValues = security ? {
    currentStep: Step.ENTER_CONTRACT_COUNT,
    brokerTransactionType: cardContext ? TradegramHelper.getBrokerTransactionTypeFromContext(cardContext, security) : 
      TradegramHelper.patternOsi.parse(security.stockSymbolOption).action === "buy" ? BrokerTransactionTypeEnum.BUY_TO_OPEN : BrokerTransactionTypeEnum.SELL_TO_CLOSE,
    stockSymbol: { symbolName: security.stockSymbol } as IStockSymbol,
    shortName: security.stockSymbol,
    stockSymbolOption: security.stockSymbolOption,
    optionType: TradegramHelper.patternOsi.parse(security.stockSymbolOption).optionType,
    optionTransaction: {
      contracts: cardContext && cardContext === CardContext.CLOSE ? Math.abs(TradegramHelper.getQuantityBalance(security)) : null,
      expiration: TradegramHelper.patternOsi.parse(security.stockSymbolOption).date,
      optionType: TradegramHelper.patternOsi.parse(security.stockSymbolOption).optionType,
      strikePrice: TradegramHelper.patternOsi.parse(security.stockSymbolOption).price,
      transactionDescription: '',
    } as IOptionTransaction,
  } : {
    currentStep: Step.PICK_SYMBOL,
    brokerTransactionType: BrokerTransactionTypeEnum.BUY_TO_OPEN,
    stockSymbol: {} as IStockSymbol,
    shortName: '',
    optionType: OptionType.CALL,
    optionTransaction: {} as IOptionTransaction,
    stockSymbolOption: '',
  }


  const [currentStep, setCurrentStep] = useState<Step>(initialWizardValues.currentStep);
  const [stockSymbol, setStockSymbol] = useState<IStockSymbol>(initialWizardValues.stockSymbol);
  const [optionType, setOptionType] = useState<OptionType>(initialWizardValues.optionType);
  const [brokerTransactionType, setBrokerTransactionType] = useState<BrokerTransactionTypeEnum>(initialWizardValues.brokerTransactionType);
  const [optionTransaction, setOptionTransaction] = useState<IOptionTransaction>(initialWizardValues.optionTransaction)

  const initialFormValues = {
    contracts: initialWizardValues.optionTransaction?.contracts || undefined,
    expiration: initialWizardValues.optionTransaction?.expiration || TradegramHelper.getNextFriday(new Date()).getTime() / 1000,
    strikePrice: initialWizardValues.optionTransaction?.strikePrice || undefined,
    optionType: initialWizardValues.optionTransaction?.optionType || OptionType.CALL,
    executedPrice: initialWizardValues.optionTransaction?.executedPrice || undefined,
    stockSymbol: stockSymbol.symbolName,
  }


  const [isShortCut, setIsShortCut] = useState<boolean>(false);

  const msgrContext = useContext(MessengerContext);

  const buttonSelectedClassname = "bg-white text-black";

  const stepIs = (s: Step) => {
    return Helper.enumIs<Step>(s, currentStep);
  }

  const tradeTypeIs = (t: OptionType) => {
    return Helper.enumIs<OptionType>(t, optionType);
  }

  const onStockSymbolSelect = (s: IStockSymbol) => {
    setStockSymbol(s);
    setCurrentStep(Step.ENTER_DETAILS);
  }

  const parseShortName = useCallback((shortName: string) => {
    if (!shortName) {
      return;
    }
    try {
      const parsedOption = TradegramHelper.parseOptionContractName(shortName);
      if (!parsedOption) {
        throw Error('Invalid option name');
      }
      const s: IStockSymbol = {
        symbolName: parsedOption.symbol
      } as IStockSymbol
      setStockSymbol(s);
      setOptionType(parsedOption.optionType);

      const t: IOptionTransaction = {
        ...optionTransaction,
        strikePrice: parsedOption.price,
        expiration: parsedOption.date,
        executedPrice: parsedOption.executionPrice || 0,
        contracts: Math.abs(parsedOption.quantity || 0),
      }

      // check if it exists
      const osiFormat = TradegramHelper.formatOptionToOSI(parsedOption);
      const existingSecurity = mySecurities.items.find(i => i.stockSymbolOption === osiFormat) || null;
      const initialTransactionType = TradegramHelper.getBrokerTransactionTypeFromExistingSecurity(existingSecurity, parsedOption.action || "buy");
      setBrokerTransactionType(initialTransactionType)
      setOptionTransaction(t);
      setCurrentStep(Step.ENTER_CONTRACT_COUNT);
      setIsShortCut(true);
    } catch (error: any) {
      msgrContext.setMessage({ body: (error as Error).message });
    }
  },[])

  useEffect(()=>{
    if (shortName !== '' && shortName !== undefined) {
      parseShortName(shortName);
    } 
  },[shortName, parseShortName])


  const PickSymbol = () => {
    return (
      <div>
        <SearchStock onSelect={onStockSymbolSelect} />
      </div>
    );
  }

  const EnterDetails = () => {

    const onSubmit = (data: any, actions: any) => {
      const {
        contracts,
        expiration,
        strikePrice,
      } = data;

      const opt = {
        ...optionTransaction,
        stockSymbol: stockSymbol.symbolName,
        contracts: Number(contracts),
        expiration: Number(expiration),
        strikePrice: Number(strikePrice),
      }
      setOptionTransaction(opt as IOptionTransaction);
      setCurrentStep(Step.ENTER_EXECUTED_PRICE);
    }

    return (
      <div className="container">

        <div className="text-center">Post <span className="fw-bold">{stockSymbol.symbolName} Option</span> Trade</div>

        <Formik initialValues={initialFormValues} enableReinitialize onSubmit={onSubmit} validationSchema={validationMap.get(Validator.ADD_OPTION_FORM)}>
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <Form>
                <div className="text-center mt-5">
                  <span className="text-12"># of Contracts</span>
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="contracts"
                    placeholder="# of Contracts"
                  />
                </div>

                <div className="text-center mt-2">
                  <div className="text-12">Expiration</div>
                  <ReactDatePicker
                    className="bg-charcoal text-white pill text-center border-0 py-2"
                    dateFormat="MM/dd/yyyy"
                    onChange={(date: Date) => {
                      setFieldValue("expiration", date.getTime() / 1000);
                    }}
                    selected={values.expiration ? new Date(values.expiration * 1000) : new Date()}
                  />
                  {errors['expiration'] && 
                  <div className="text-red text-14">{errors['expiration']}</div>
                  }

                </div>

                <div className="text-center mt-2">
                  <span className="text-12">Strike</span>
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="strikePrice"
                    placeholder="Strike Price"
                  />
                </div>

                <button type="submit" className="btn btn-secondary my-3 w-100 p-3">Continue</button>

              </Form>
            )
          }}
        </Formik>

      </div>
    );
  }

  const OptionFullName = () => {
    return <>{stockSymbol.symbolName} {TradegramHelper.formatOptionDate(new Date(optionTransaction.expiration * 1000))} ${optionTransaction.strikePrice.toFixed(2)} {optionType}</>
  }

  const saveTransaction = async (optionTransaction: IOptionTransaction) => {

    const parsedOption: IParsedOption = {
      symbol: stockSymbol.symbolName,
      date: optionTransaction.expiration,
      optionType: optionType,
      price: optionTransaction.strikePrice,
    }

    const payload: ITradegramParams = {
      price: optionTransaction.executedPrice * 100,
      quantity: optionTransaction.contracts * (BrokerTransactionTypeHelper.isBuy(brokerTransactionType) ? 1 : -1),
      stockSymbol: stockSymbol.symbolName,
      stockSymbolOption: TradegramHelper.formatOptionToOSI(parsedOption),
      securityTypeId: BrokerSecurityTypeEnum.OPTION,
      transactionTypeId: brokerTransactionType,
      transactionDescription: optionTransaction.transactionDescription
    }

    if ((payload.transactionDescription || "") === "") {
      delete payload.transactionDescription;
    }

    try {
      await tradegramApiService.addTransaction(payload);
    } catch (error) {
      setCurrentStep(Step.ENTER_CONTRACT_COUNT)
      msgrContext.setMessage({ body:  `Error during saving Option: ${MessageHelper.translate((error as Error).message)}` });
      return;
    }
    msgrContext.setMessage({ body: "Transaction Saved" });
    onSuccess();
  }

  const EnterContractCount = () => {
    const onSubmit = (data: any, actions: any) => {
      const opt: IOptionTransaction = {
        ...optionTransaction,
        executedPrice: data.executedPrice,
        contracts: data.contracts
      }
      setOptionTransaction(opt);
      setCurrentStep(Step.FINAL_REVIEW)
    }

    return (
      <div className="flex-grow-1 container d-flex flex-column justify-content-start align-items-center">
        <div className="my-2">Post <span className="fw-bold">{stockSymbol.symbolName} Option</span> Trade</div>

        <Formik initialValues={optionTransaction} onSubmit={onSubmit} enableReinitialize validationSchema={validationMap.get(Validator.OPTION_CONTRACTS_FORM)}>
          {({ values, errors, touched, isSubmitting }) => {
            return (
              <Form className="flex-grow-1">
                <div className="d-flex flex-column justify-content-start align-items-stretch h-100">
                <div className="text-center">
                  <OptionFullName />
                </div>

                <div className="text-14 text-center mt-2">Trade</div>
                <div>
                  <BrokerTransactionTypeDropdown
                    initialValue={brokerTransactionType}
                    setValue={setBrokerTransactionType}
                    isNewPosition={cardContext === undefined || (security && TradegramHelper.isClosed(security))} />
                </div>

                <div className="text-center mt-2">
                  <Tooltip text={<div>The price per share that you paid or received for this transaction.</div>}>
                    <div className="text-14">
                      Share Price
                    </div>
                  </Tooltip>

                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="executedPrice"
                    placeholder="Executed Price"
                  />
                </div>

                <div className="text-center mt-2">
                  <span className="text-14">Contracts</span>
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="contracts"
                    placeholder="Contracts"
                  />
                </div>

                <div className="flex-grow-1 d-flex align-items-end">
                  <button type="submit" className="btn btn-secondary my-3 w-100 p-3" disabled={isSubmitting}>Continue</button>
                </div>
                </div>
              </Form>
            )
          }}
        </Formik>

      </div>
    );
  }

  const EnterExecutedPrice = () => {

    const onSubmit = (data: any, actions: any) => {
      const opt: IOptionTransaction = {
        ...optionTransaction,
        executedPrice: data.executedPrice,
        transactionType: brokerTransactionType
      }
      setOptionTransaction(opt);
      setCurrentStep(Step.FINAL_REVIEW)
    }

    return (
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <div className="my-3">Post <span className="fw-bold">{stockSymbol.symbolName} Option</span> Trade</div>

        <Formik initialValues={optionTransaction} onSubmit={onSubmit} validationSchema={validationMap.get(Validator.OPTION_EXECPRICE_FORM)}>
          {({ values, errors, touched, isSubmitting }) => {
            return (
              <Form>

                <div className="text-center">
                  <OptionFullName />
                </div>

                <div className="text-14 text-center mt-2">Trade</div>
                <div>
                  <BrokerTransactionTypeDropdown initialValue={brokerTransactionType} setValue={setBrokerTransactionType} isNewPosition={cardContext === undefined || (security && TradegramHelper.isClosed(security))} />
                </div>

                <div className="text-14 mt-3 text-center">Type</div>
                <div className="d-flex justify-content-center align-items-center gap-3 mb-3">
                  <button type="button" onClick={() => setOptionType(OptionType.CALL)} className={`btn btn-tertiary text-16 p-4 ${tradeTypeIs(OptionType.CALL) ? buttonSelectedClassname : ""}`}>Call</button>
                  <button type="button" onClick={() => setOptionType(OptionType.PUT)} className={`btn btn-tertiary text-16 p-4 ${tradeTypeIs(OptionType.PUT) ? buttonSelectedClassname : ""}`}>Put</button>
                </div>
                <div className="text-center">
                  <Tooltip text={<div>The price per share that you paid or received for this transaction.</div>}>
                  <div className="text-14">
                      Share Price
                  </div>
                  </Tooltip>
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="executedPrice"
                    placeholder="Share price"
                  />
                </div>

                <button type="submit" className="btn btn-secondary my-5 w-100 p-3" disabled={isSubmitting}>Continue</button>

              </Form>
            )
          }}
        </Formik>

      </div>
    );
  }

  const onSubmitNotes = async (data: any, actions: any) => {
    const t = {
      ...optionTransaction,
      transactionDescription: data.transactionDescription
    };
    await saveTransaction(t);
  }

  const FinalReview = () => {
    return (
      <Formik initialValues={{ transactionDescription: optionTransaction.transactionDescription }} onSubmit={onSubmitNotes}>
        {({ values, errors, touched, isSubmitting }) => {
          return (
            <div className="d-flex flex-column justify-content-center align-items-center my-3">
              <div>{optionTranTypeDropdownItemsMap.get(brokerTransactionType)?.label}</div>
              <div className="text-16 my-3">{optionTransaction.contracts} {optionTransaction.contracts > 1 ? "contracts" : "contract"} of {stockSymbol.symbolName}</div>

              <div className={`card bg-${tradeTypeIs(OptionType.CALL) ? "bright-green" : "crimson"} w-100 px-5 py-2 text-center d-flex flex-column justify-content-center text-white`}>
                <div className="fw-bold"><OptionFullName /></div>
                <div>for</div>
                <div className="fw-bold">${Number(optionTransaction.executedPrice).toFixed(2)}</div>
              </div>

              <Form className="w-100">
                <div className="text-center mt-2">
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    type="textarea"
                    as="textarea"
                    fieldName="transactionDescription"
                    rows={5}
                    placeholder="Add trade notes here (stop loss, target price, etc)"
                  />
                </div>
                <button type="submit" className="btn btn-secondary mt-3 w-100 mt-5" disabled={isSubmitting}>Post</button>
              </Form>
            </div>
          )
        }}
      </Formik>
    );
  }

  const wizard: Map<Step, JSX.Element> = new Map<Step, JSX.Element>([
    [Step.PICK_SYMBOL, <PickSymbol />],
    [Step.ENTER_DETAILS, <EnterDetails />],
    [Step.ENTER_EXECUTED_PRICE, <EnterExecutedPrice />],
    [Step.ENTER_CONTRACT_COUNT, <EnterContractCount />],
    [Step.FINAL_REVIEW, <FinalReview />]
  ])

  const onClickBack = () => {

    if (currentStep === Step.PICK_SYMBOL) {
      onCancel();
      return;
    }

    // modal triggered from Add/Close from Trade Card
    if (cardContext) {
      if (stepIs(Step.ENTER_CONTRACT_COUNT)) {
        onCancel();
        return;
      }
    }

    if (isShortCut) {
      if (stepIs(Step.ENTER_CONTRACT_COUNT)) {
        onCancel();
        return;
      }

      if (stepIs(Step.FINAL_REVIEW)) {
        setCurrentStep(Step.ENTER_CONTRACT_COUNT);
        return;
      }
    }

    if (stepIs(Step.FINAL_REVIEW)) {
      setCurrentStep(Step.ENTER_EXECUTED_PRICE);
      return;
    }

    setCurrentStep(currentStep - 1);
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex justify-content-start align-items-center">
        <div role="button" onClick={onClickBack}><RoundBackButton /></div>
        <div role="button" title="Close" className="fw-bold ms-auto text-white"><CloseButton variant="white" onClick={onCancel} /></div>
      </div>

      {wizard.get(currentStep)}
    </div>
  )
}

