import React, { useEffect, useState } from 'react'
import { useAdminUserStreakStats } from '_hooks/useAdminUserStreakStats'
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import { BadgeHelper } from 'components/user/badges/Helper';
import { leagueTabNames } from 'components/user/rankings/LeagueTabs';
import { SeasonFilter } from './SeasonFilter';
import { IStreakStatSearchOptions, IUserStreakStat } from 'services/AdminApiReportService';
import { Season, SeasonEnum } from 'predictagram-lib';
import { useExcelDownload } from '_hooks/useExcelDownload';

export const AdminUsersRankingPage: React.FunctionComponent = () => {

  const initialSeason = SeasonEnum.SEASON_2024_Q1;

  const [ season, setSeason ] = useState<SeasonEnum>(initialSeason);

  const options: IStreakStatSearchOptions = {
    startTime: Season.seasons[initialSeason].startDateSec,
    endTime: Season.seasons[initialSeason].endDateSec,
  }

  const api = useAdminUserStreakStats(options);
  const setFilter = api.setFilter;

  useEffect(()=>{
    setFilter({
      startTime: Season.seasons[season].startDateSec,
      endTime: Season.seasons[season].endDateSec,
      })
  },[season, setFilter])

  const mapper = (item: IUserStreakStat) => {
    const {
      userId,
      username,
      points,
      predictions,
      averageScoreUser,
    } = item;
    return {
      userId,
      username,
      league: leagueTabNames.get(BadgeHelper.getLevelProgress(item).levelInfo.current.rank)?.label,
      points,
      predictions,
      averageScoreUser
    }
  }  
  const excelDownload = useExcelDownload<IUserStreakStat>(mapper, 'season-league');

  if (api.apiState.isLoading) {
    return <Spinner />
  }

  if (api.apiState.isError) {
    return <ErrorMessage>{api.error}</ErrorMessage>
  }

  if (api.apiState.isLoaded) {

    const items = api.data;

    return <div className="admin-users-streak-stats">

      <div className="d-flex justify-content-between align-items-center">
      <div className="page-title">Leagues</div>

      <div className="d-flex gap-2">
        <SeasonFilter season={season} setSeason={setSeason}/>
        {api.data.length > 0 && <>{excelDownload.render(api.data)}</>}      
      </div>
      </div>

      <table className="table table-hover table-striped">
        <thead>
          <tr>
            <th>User Id</th>
            <th>User Name</th>
            <th>Ranking</th>
            <th>Prediction Count</th>
            <th>Points</th>
            <th>Average Score</th>
          </tr>
        </thead>

        <tbody>
          {items.map((item, i) => {
          
          const p = BadgeHelper.getLevelProgress(item);

          return <tr key={`row-${i}`}>
            <td>{item.userId}</td>
            <td>{item.username}</td>
            <td>{leagueTabNames.get(p.levelInfo.current.rank)?.label}</td>
            <td>{item.predictions}</td>
            <td>{item.points}</td>
            <td>{(item.averageScoreUser * 100).toFixed(2)}</td>
          </tr>

        })}
        </tbody>

        <tfoot>

        </tfoot>
      </table>


    </div>
  }

  return (
    <div>Streak Stats</div>
  )
}
