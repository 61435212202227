import { Footer } from 'components/common';
import { FooterSignupLogin } from 'components/public/layout/FooterSignupLogin';
import { PredictionWizard, WizardStep } from 'components/user/prediction/PredictionWizard';
import { IStockSymbol } from 'interfaces/IStockSymbol';
import React, { useEffect } from 'react';

const LandingPageText = (): JSX.Element => {
  return (
    <div className="landing-page-text">
      <div className="landing-page-title">Are you a stock market guru?</div>
      <div className="landing-page-list d-flex flex-column">
        <div className="mt-3 mt-sm-4">Make a prediction using the slider <span className="landing-page-text-mobile">below</span><span className="landing-page-text-desktop">to the right</span>.</div>
        <div className="my-3 my-sm-4">Sign up for a FREE account to preserve your prediction for bragging rights.</div>
      </div>

    </div>
  );
}

export const LandingPageV1: React.FunctionComponent<{hasLoginSignup?: boolean, hasDisclaimer?: boolean}> = ({hasLoginSignup = false, hasDisclaimer = false}) => {

  const initialSymbol: IStockSymbol = {
    symbolName: 'SPY'
  } as IStockSymbol

  const onClickClose = () => { };
  const onComplete = () => { };
  const onRetrySignup = () => { };

  const renderFooter = (): JSX.Element => {
    if (hasDisclaimer && hasLoginSignup) {
      return <FooterSignupLogin />
    }
    if (hasDisclaimer && !hasLoginSignup) {
      return <Footer />
    }
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="text-smaller text-lighter-charcoal mt-3">&copy; {new Date().getFullYear()} Predictagram.com</div>
      </div>
    );
  }

  return (
    <div className="container-fluid g-0 landing-page min-vh-100 position-relative">
      <div className="d-flex flex-column align-items-stretch">

        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="container">
            <div className="row my-3 mt-md-5 mb-md-5">
              <div className="logo d-flex flex-row justify-content-start align-items-center">
                <img src="/images/logo.png" alt="Predictagram Stock Predictions" />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col col-12 col-md-6 mt-1 mt-md-3">
                <LandingPageText />
              </div>
              <div className="col col-12 col-md-6 align-items-sm-center align-items-start-md mt-3 mt-md-1">
                <div className="d-flex justify-content-center flex-row">
                  <div className="landing-page-form">
                    <PredictionWizard
                      onClickClose={onClickClose}
                      onComplete={onComplete}
                      onRetrySignup={onRetrySignup}
                      initialSymbol={initialSymbol}
                      initialWizardStep={WizardStep.SET_PREDICTION}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
      {renderFooter()}
      </div>

    </div >
  );
}