import React from 'react';
import { predictionService } from 'services';
import { useStockDetail } from '_hooks/useStockDetail';
import { APIState } from '_constants/APIState';
import { Spinner } from 'components/common/Spinner';

export const StockPriceChange: React.FunctionComponent<{stockSymbol: string }> = ({stockSymbol}) => {

  const stockDetail = useStockDetail(stockSymbol || '', new Date(), predictionService);
  
  if (stockDetail.apiState === APIState.LOADING) {
    return <Spinner />
  }
  if (stockDetail.apiState === APIState.ERROR) {
    return <>Err</>
  }
  return (
    <>
      {stockDetail.item.today && stockDetail.item.yesterday &&
        <div className="d-flex justify-content-center align-items-center gap-2">
          <div className={`stock-detail-arrow arrow-${stockDetail.item.today.c > stockDetail.item.yesterday.c ? 'up' : 'down'}`}></div>
          <div className={`stock-detail-pct text-${stockDetail.item.today.c > stockDetail.item.yesterday.c ? 'green' : 'red'}`}>{((stockDetail.item.today.c - stockDetail.item.yesterday.c) / stockDetail.item.yesterday.c * 100).toFixed(2)}%</div>
        </div>
      }
    </>
  );
}