import React, { useContext, useEffect, useRef, useState } from 'react';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { PredictionListLazy } from './components/PredictionListLazy';
import { Spinner } from 'components/common/Spinner';
import { PredictionFilters } from 'components/public/ListingsPage/components/Prediction/PredictionFilters';
import { PredictionTypeEnum } from '_constants/PredictionTypeEnum';
import { ScrollDirection, useScrollCounter } from '_hooks/useScrollCounter';
import { Helper } from '_utils';
import { ModalHelp } from '../Help/ModalHelp';
import { sortTypeEnum, SortTypeSelect } from './components/Prediction/SortTypeSelect';
import { usePublicPrediction } from '_hooks/usePublicPrediction';
import { APIState } from '_constants/APIState';
import { Disclaimer } from 'components/common/Disclaimer';
import { ConsensusChart } from '../Stock/ConsensusChart';
import { SymbolsContext } from 'components/common/SymbolsContext';
import { AutoSuggestSearch } from './components/AutoSuggestSearch';
import { Help } from './components/Help';
// import { useQuickTour } from '_hooks/useQuickTour';

export const ListingsPageDynamic: React.FunctionComponent = () => {
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const navRef = useRef<HTMLDivElement>(null);
  const [scrollCounter, scrollDirection] = useScrollCounter();
  const [lastDirection, setLastDirection] = useState<ScrollDirection>(ScrollDirection.IDLE);

  const symbolsContext = useContext(SymbolsContext);
  const [validSymbols, setValidSymbols] = useState<string[]>([]);

  const { items: predictions, apiState, options, setOptions, setSort } = usePublicPrediction(userPredictionApiService);

  useEffect(() => {
    if (symbolsContext?.symbols) {
      setValidSymbols(symbolsContext?.symbols.map((s) => s.symbolName));
    }
  }, [symbolsContext])

  useEffect(() => {
    if (navRef.current) {
      const nav = navRef.current;
      const navIsInView = Helper.isInViewPort(nav, 0);
      if (scrollDirection !== lastDirection && scrollDirection === ScrollDirection.UP && !navIsInView) {
        // console.log('adding nav sticky')
        nav.classList.add('top-nav-sticky');
      }
      if (scrollDirection === ScrollDirection.DOWN && nav.style.position !== 'initial') {
        nav.classList.remove('top-nav-sticky');
      }
      setLastDirection(scrollDirection);
    }
  }, [scrollCounter, scrollDirection, lastDirection])

  const onSearch = (search: ISearchOptions) => {
    const { usernames: searchNames, symbolNames: searchSymbols } = search;
    if (!options) {
      setOptions({
        usernames: searchNames,
        symbolNames: searchSymbols?.filter(Helper.uniqueFilter),
      })
      return;
    }

    const { usernames, symbolNames, ...rest } = options;
    setOptions({
      usernames: searchNames,
      symbolNames: searchSymbols?.filter(Helper.uniqueFilter),
      ...rest
    })
  }

  const onClickTab = (predTypes: PredictionTypeEnum[]) => {
    if (!options) {
      setOptions({
        predictionTypes: predTypes
      })
      window.scrollTo(0, 0);
      return;
    }

    const { predictionTypes, ...rest } = options;

    setOptions({
      predictionTypes: predTypes.length > 0 ? predTypes : undefined,
      ...rest
    })

    window.scrollTo(0, 0);
  }

  const onClickHelp = () => {
    setShowHelp(true);
  }

  return (
    <>
      {/* {qt.render()} */}

      <ModalHelp show={showHelp} handleClose={() => setShowHelp(false)} />
      <div className="container">
        <Disclaimer />

        <div className="top-nav" ref={navRef}>
          <div className="mt-2 mb-4">
            <AutoSuggestSearch onSearchCallback={onSearch}/>
          </div>
          <div className="d-flex justify-content-between m-2">
            <div className="align-self-start fw-bold">Predictions</div>
            <div className="align-self-end d-flex flex-column justify-content-end align-self-center ms-auto"><Help onClick={onClickHelp} /></div>
          </div>
          <div className="d-flex justify-content-between align-items-start flex-wrap">
            <div className="d-flex mt-2 align-self-start"><SortTypeSelect onSelectCallback={setSort} selected={options?.orderBy || sortTypeEnum.BEST} /></div>
            <div className="d-flex mt-2 align-self-end"><PredictionFilters onClickTab={onClickTab} /></div>
          </div>
        </div>

        {apiState === APIState.LOADING &&
          <Spinner />
        }

        {apiState === APIState.LOADED && predictions.length > 0 && options?.symbolNames && options?.symbolNames && options?.symbolNames.length > 0 &&
          <div className="my-3">
            {options.symbolNames.map((s: string, i: number) => {
              if (!validSymbols.includes(s.toUpperCase())) {
                return <></>
              }

              return <div key={`bubble-key${i}`} className="my-2">
                <ConsensusChart predictionTypes={options.predictionTypes} stockSymbol={s.toUpperCase()} />
              </div>
            })}
          </div>
        }

        {apiState === APIState.LOADED && predictions.length > 0 &&
          <div className="my-3">
            <PredictionListLazy predictions={predictions} showMenu={false} onSelectSort={setSort} orderBy={options?.orderBy || sortTypeEnum.BEST} />
          </div>
        }

        {apiState === APIState.LOADED && predictions.length === 0 &&
          <div className="my-3">
            <div className="alert alert-warning">No Results</div>
          </div>
        }

      </div>
    </>

  );
}
