import React, { useContext, useRef, useState } from 'react'
import {
  adminBrokerTransactionsApiService,
  SearchFilter,
  useAdminBrokerTransactions
} from '_hooks/useAdminBrokerTransactions'
import { TradegramHelper } from '_utils/TradegramHelper';
import { Spinner } from 'react-bootstrap';
import { BrokerTransactionVerificationStatusEnum } from '_constants/BrokerTransactionVerificationStatusEnum';
import { MessengerContext } from 'components/common/messenger';
import { NewYorkTz } from 'predictagram-lib';
import { IBrokerTransaction } from 'services/AdminBrokerTransactionsApiService';
import { Url } from '_constants';
import {UrlHelper} from "../../../_utils/UrlHelper";
import {Field, Form, Formik} from "formik";


export const TradegramLink: React.FunctionComponent<{ transaction: IBrokerTransaction }> = ({ transaction }) => {

  const link = Url.USER_PROFILE_POSITIONS.replaceAll(':userId', transaction.userId.toString()).replaceAll(':securityId', transaction.securityId.toString());
  return <a href={link} target="_blank" rel="noreferrer">View Tradegram</a>

}

export const PriceLookupLink: React.FunctionComponent<{ transaction: IBrokerTransaction }> = ({ transaction }) => {

  if (TradegramHelper.isEquitySecurity(transaction.securityTypeId)) {
    const link = `https://finance.yahoo.com/quote/${transaction.stockSymbolName}?p=${transaction.stockSymbolName}`;
    return <a href={link} target="_blank" rel="noreferrer">Lookup</a>
  }

  const link = `https://finance.yahoo.com/chart/${transaction.stockSymbolOption}?showOptin=1`;
  return <a href={link} target="_blank" rel="noreferrer">Lookup</a>

}

export const EditLink: React.FunctionComponent<{securityId:number}> = ({securityId}) => {
  const link = UrlHelper.getAdminEditSecurityBulk(securityId);
  return <a href={link}>Edit</a>;
}

export const VerifyStatusDropDown: React.FunctionComponent<{ id: number, currentStatus: BrokerTransactionVerificationStatusEnum, setVerifyStatus: (id: number, newStatus: BrokerTransactionVerificationStatusEnum) => void }> = ({ currentStatus, setVerifyStatus, id }) => {

  const ref = useRef<HTMLSelectElement>(null);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setVerifyStatus(id, parseInt(e.target.value));
  }

  return (
    <select name={`select-${id}`} ref={ref} onChange={onChange} defaultValue={currentStatus}>
      <option value={BrokerTransactionVerificationStatusEnum.PENDING}>Pending</option>
      <option value={BrokerTransactionVerificationStatusEnum.APPROVED}>Verified</option>
      <option value={BrokerTransactionVerificationStatusEnum.REJECTED}>Rejected</option>
    </select>
  );
}

const defValues = {
  brokerTransactionVerificationStatusId: BrokerTransactionVerificationStatusEnum.PENDING,
  userId: '' as any,
} as SearchFilter;
export const SearchForm: React.FunctionComponent<{
  onSubmit: (f: any) => void
}> = ({ onSubmit }) => {

  const submit = (f:SearchFilter) => {
    // console.debug(f);
    onSubmit({
      brokerTransactionVerificationStatusId: parseInt(f.brokerTransactionVerificationStatusId as any) as BrokerTransactionVerificationStatusEnum,
      usernameLike: f.usernameLike,
      symbolNameLike: f.symbolNameLike,
    });
  }

  return (
    <Formik initialValues={defValues} onSubmit={submit}>
      {({ values, touched, errors, setFieldValue }) => {
        return <Form>
          <div className="d-flex justify-content-start gap-3 my-3 analysis">
            <div className="d-flex flex-column">
              <Field name={`brokerTransactionVerificationStatusId`}  as="select">
                  <option value={BrokerTransactionVerificationStatusEnum.PENDING}>Pending</option>
                  <option value={BrokerTransactionVerificationStatusEnum.APPROVED}>Verified</option>
                  <option value={BrokerTransactionVerificationStatusEnum.REJECTED}>Rejected</option>
              </Field>
            </div>
            <div>
              Username: <Field type={'text'} name={'usernameLike'} />
            </div>
            <div>
              Symbol: <Field type={'text'} name={'symbolNameLike'} />
            </div>
            <div className="align-self-end">
              <button type="submit" className="btn btn-primary p-2">Search</button>
            </div>
          </div>
        </Form>
      }}
    </Formik>
  );
}

export const AdminPendingVerificationsPage: React.FunctionComponent = () => {

  // const [filterStatus, setFilterStatus] = useState<BrokerTransactionVerificationStatusEnum>(BrokerTransactionVerificationStatusEnum.PENDING);

  const { data, apiState, error, setFilter } = useAdminBrokerTransactions(defValues);

  const msgrContext = useContext(MessengerContext);

  const verify = async (id: number, status: BrokerTransactionVerificationStatusEnum) => {
    try {
      const result = await adminBrokerTransactionsApiService.updateVerificationStatus(id, status);
      if (result.success === false) {
        throw Error(`API could not update`);
      }
    } catch (error: any) {
      msgrContext.setMessage({ body: (error as Error).message })
    }
  }
  return (
    <>

      <div className="container-fluid g-0">
        <div className="row">
          <div className="col-12 col-md-2">
            <SearchForm onSubmit={setFilter}/>
          </div>
        </div>
      </div>

      {apiState.isLoading && <Spinner /> }
      { error && <div className="alert alert-danger">{error}</div> }


      {apiState.isLoaded && data &&
       <div className="verifications fixed-table-head">

         <table className="table table-hover table-striped">
           <thead>
           <tr>
             <th>View Tradegram</th>
             <th>Username</th>
             <th>Symbol</th>
             <th>Option Name</th>
             <th>Security Type</th>
             <th>Description</th>
             <th className="text-end">Quantity</th>
             <th className="text-end">Stock price (at time of entry)</th>
             <th className="text-end">Share Price</th>
             <th>Date/Time Entered (New York Time)</th>
             <th>Edit</th>
             <th>Price Lookup</th>
             <th>Verify Status</th>
           </tr>
           </thead>

           <tbody>
           {data.length === 0 ?
            <tr>
              <td colSpan={8}>No Data.</td>
            </tr>
                              :
            <>
              {data.map((d, i) => <tr key={`row-${i}`}>
                <td><TradegramLink transaction={d}/></td>
                <td>{d.username}</td>
                <td>{TradegramHelper.isEquitySecurity(d.securityTypeId) ? d.stockSymbolName : d.stockSymbolOption}</td>
                <td>{TradegramHelper.isEquitySecurity(d.securityTypeId) ? ""
                                                                        : TradegramHelper.formatContractNameFromOSI(
                    d.stockSymbolOption)}</td>
                <td>{TradegramHelper.isEquitySecurity(d.securityTypeId) ? "Equity" : "Option"}</td>
                <td style={{maxWidth: "150px"}}>{d.description}</td>
                <td className="text-end">{d.quantity}</td>
                <td className="text-end">{TradegramHelper.formatMoney(d.stockPrice)}</td>
                <td className="text-end">{TradegramHelper.formatMoney(
                  d.price / (TradegramHelper.isOptionSecurity(d.securityTypeId) ? 100 : 1))}</td>
                <td>{NewYorkTz.format(new Date(d.createdAt * 1000)).monthNameDateTime()}</td>
                <td><EditLink securityId={d.securityId}/></td>
                <td><PriceLookupLink transaction={d}/></td>
                <td><VerifyStatusDropDown id={d.id} currentStatus={d.verificationStatusId} setVerifyStatus={verify}/>
                </td>
              </tr>)}
            </>
           }
           </tbody>
         </table>
       </div>

      }

    </>
  );




  {/*return <div>Something went wrong.</div>*/}
}
