import { IPublicProfile } from 'interfaces/IPublicProfile'
import React from 'react'
import { LetterAvatar } from './LetterAvatar'

export enum AvatarSizeEnum {
  EXTRA_SMALL = "xs",
  SMALL = 'sm',
  SMALL_MEDIUM = 'sm-md',
  MEDIUM = 'md',
  MEDIUM_LARGE = 'md-lg',
  LARGE = 'lg',
  XXL = 'xxl',
}

export const Avatar: React.FunctionComponent<{profile: IPublicProfile, size: AvatarSizeEnum, imageWidth?: string}> = ({profile, size, imageWidth}) => {

  return (
      <>
      {profile.avatarUrl ? <img className="rounded-circle" style={{objectFit: "cover", objectPosition: "center", width: imageWidth, height: imageWidth}} src={profile.avatarUrl} alt={profile?.nickname} /> : <LetterAvatar size={size} letter={profile.username.charAt(0)}/>}
      </>
  )
}

