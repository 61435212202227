import React from "react";
import { Formik, Form } from "formik";
import { IApiService, IDataField } from "interfaces";
import { Helper } from "_utils";

interface IChildrenProps {
  errors: any;
  touched: any;
  setFieldValue?: any;
  values?: any;
}

interface IAppProps<T> {
  isEditing: boolean;
  onSuccess: (item: T) => void;
  initialValues: T;
  onCancelClick: () => void;
  validationSchema: any;
  apiService: IApiService<T>;
  children: (props: IChildrenProps) => React.ReactNode;
  editableFields?: IDataField[];
  beforeSubmit?: { (data: any, actions: any): Promise<any>}; // this function called before submitting form.
}

const BaseForm = <T extends { id?: number, uid?: number }>({
  isEditing,
  onSuccess,
  initialValues,
  onCancelClick,
  validationSchema,
  apiService,
  children,
  editableFields,
  beforeSubmit,
}: IAppProps<T>) => {
  const onSubmit = async (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;
    const itemData: T = Object.assign(data);

    if (beforeSubmit) {
      try {
        await beforeSubmit(data, actions);
      } catch (error: any) {
        setStatus(error.message);
        return;
      }
    }

    setSubmitting(true);
    if (isEditing) {
      const fields: string[] = editableFields
        ? editableFields.filter((f: IDataField) => f.editable === true).map((f: IDataField) => f.name)
        : [];
      const updateData: T = editableFields ? Helper.cloneObject<T>(fields, itemData) : itemData;

      try {
        const id: number = itemData.id || itemData.uid || 0;
        // console.log({itemData});
        delete itemData.id;
        const item: T = await apiService.update(updateData, id);
        item.id = id;
        onSuccess(item);
      } catch (error: any) {
        console.error("could not edit", error);
        setStatus(`Edit Failed: ${error.message}`);
      }
    } else {
      try {
        const item: T = await apiService.create(itemData);
        onSuccess(item);
      } catch (error) {
        console.error("Add Failed", error);
        setStatus(`Could not create record: ${error}`);
      }
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, isSubmitting, errors, touched, status, setFieldValue }) => {
        return (
          <Form>
            {children({ errors, touched, setFieldValue, values })}

            {status && <div className="alert my-3">{status}</div>}

            <div className="d-flex gap-3 justify-content-start align-items-center my-3">
              <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-md">
                Submit
              </button>
              <button disabled={isSubmitting} type="button" onClick={onCancelClick} className="btn btn-secondary btn-md mx-3">
                Cancel
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export { BaseForm };
