import { config } from 'config';
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { AccountContext } from 'components/common';
import { Helper } from '_utils';

export function useApiPixel ()  {

  const location = useLocation();
  const acctContext = useContext(AccountContext);

  const getPixel = (event: string) => {
    const _load = async () => {
      const params = {
        event: event,
        pathname: location.pathname,
        search: location.search,
        userid: acctContext?.account.id
      }
      const url = `${config.apiDomain}/check${Helper.objectToQueryString(params)}`
      await fetch(url);
    }
    _load();
  }

  return {
    getPixel
  }
}
