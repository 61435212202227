import React from "react";
import { ListContainer } from "components/common";
import { adminLeagueApiService } from "services/AdminApiService";
import { FormButtons } from "_constants";
import { IDataField } from "interfaces";

import { ILeague } from "interfaces/league";
import { AdminLeagueForm } from "./Form";

export const AdminLeagueList: React.FunctionComponent = () => {
  const emptyValues: ILeague = {
    name: '',
    description: '',
  } as ILeague

  const editableFields: IDataField[] = [
    { name: 'id', title: 'ID', editable: false },
    { name: 'name', title: 'Name', editable: true },
    { name: 'description', title: 'Description', editable: true },
  ];

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['name', ['Name', undefined]],
    ['description', ['Description', undefined]],
  ]);

  return (
    <>
      <ListContainer
        title="Leagues"
        apiService={adminLeagueApiService}
        emptyValues={emptyValues}
        fieldList={fieldList}
        buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
        editableFields={editableFields}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }) => {
          return (
            <AdminLeagueForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick} editableFields={editableFields} />
          );
        }}
      </ListContainer>
    </>
  );
};


