import { SecuritiesDatasetEnum, useTradegramSecurities } from '_hooks/useTradegramSecurities';
import { ModalHelp } from 'components/public/Help/ModalHelp';
import React, {useCallback, useRef, useState } from 'react'
import { Spinner } from 'components/common/Spinner';
import { ITradegramSecuritiesFilter, ITradegramSecurity, tradegramApiService } from 'services/TradegramApiService';
import { ModalTrades } from 'components/user/tradegram/ModalTrades';
import { TradeWide } from 'components/user/dashboard/TradeWide'; 
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { SecurityTypeDropDownFilter, SecurityTypeFilterEnum } from 'components/user/dashboard/filters/SecurityTypeDropDownFilter';
import { Helper } from '_utils';
import { BrokerSecurityTypeEnum } from 'predictagram-lib';
import { TextDropDown } from 'components/common/TextDropDown/TextDropDown';
import { useIntersectionObserver } from '_hooks/useIntersectionObserver';
import { LoadingMoreSpinner } from 'components/common/LoadingMoreSpinner';

interface IProps {
  user: IPublicProfile,
  securityId?: number,
}

export const PredictorTrades: React.FunctionComponent<IProps> = ({user, securityId}) => {

  const securitiesApi = useTradegramSecurities(tradegramApiService, SecuritiesDatasetEnum.ALL,  {userId: user.userId, limit: 25} as ITradegramSecuritiesFilter);
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalSecurity, setModalSecurity] = useState<ITradegramSecurity>({} as ITradegramSecurity);

  const [symbolFilter, setSymbolFilter] = useState<string>('');
  const [securityTypeFilter, setSecurityTypeFilter ] = useState<SecurityTypeFilterEnum | BrokerSecurityTypeEnum>(SecurityTypeFilterEnum.ALL);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useIntersectionObserver(containerRef, ()=>{
    if (securitiesApi.apiState.isLoaded) {
      securitiesApi.getNextPage();
    }
  }, {threshold: 1});

  const symbolNames = useCallback(() => {
    const items = securitiesApi.items
      .filter(s => s.userId === user.userId)
      .map(s=>s.stockSymbol)
      .filter(Helper.uniqueFilter)
      .sort((a, b) => a.localeCompare(b));
    return [
      ...items
    ]
  }, [securitiesApi.items, user.userId])


  if (securitiesApi.apiState.isLoading) {
    return <Spinner />
  }

  const onClickTrades = (s: ITradegramSecurity) => {
    setShowModal(true);
    setModalSecurity(s);
  }

  const onSuccess = () => {
    securitiesApi.reload();
  }

  const userId = user.userId;

  const filteredResults = () => {
    const filteredSecurities = securitiesApi.items
      .filter((s)=>s.userId === userId)
      .filter(s => symbolFilter === "" || symbolFilter === 'All Symbols' || (symbolFilter.toUpperCase() === s.stockSymbol.toUpperCase()))
      .filter(s => securityTypeFilter === 0 || s.typeId === securityTypeFilter)

      return (
      <div className="my-3 my-md-1 d-flex gap-1 align-items-stretch flex-column">

        {filteredSecurities.length === 0 && securitiesApi.apiState.isLoaded &&
          <div className="d-flex justify-content-center align-items-center my-3">No trades found.</div>
        }

        {filteredSecurities.length > 0 && filteredSecurities
          .map((s: ITradegramSecurity, i: number) => <div key={`prediction-key-${i}`} className="my-3">
            <TradeWide security={s} onClick={() => onClickTrades(s)} showByDefault={securityId === s.id}/>
          </div>)}
      </div>
    );
  }

  return (
    <div className="profile ">
      <ModalTrades onSuccess={onSuccess} show={showModal} security={modalSecurity} handleClose={()=>setShowModal(false)}/>
      <ModalHelp show={showHelp} handleClose={() => setShowHelp(false)} />
      <div className="my-3 d-flex justify-content-start align-items-center gap-3">
        <div style={{width: "180px"}}><TextDropDown items={symbolNames()} onSelect={setSymbolFilter} /></div>
        <div style={{width: "150px"}}><SecurityTypeDropDownFilter initialValue={SecurityTypeFilterEnum.ALL} setValue={setSecurityTypeFilter} className="security-drop-down"/></div>
      </div>

      {filteredResults()}

      <div style={{height: '10px'}} ref={containerRef}><LoadingMoreSpinner loading={securitiesApi.apiState.isLoading}/></div>

    </div>

  );

}

