import {DateEx} from "./date.ex";

export type DateOrUnixTime =  Date|number;

export type DateExtracted = {
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number,
    utcOffset: number, // hour
    weekday: number, // 0-sun,1-mon...
    // localOffset: number, // extracted to local timezone, hour
}


export class DateHelper {

    public static readonly EAST_TIMEZONE = 'America/New_York';
    public static readonly LOCALE = 'en-US';

    /**
     *
     * @param dt
     * @returns yyyy-MM-dd HH:mm:ss
     */
    static dateTimeFormatUtc(dt: DateOrUnixTime = new Date(), isMsec = false): string {
        if (typeof dt === 'number') {
            dt = new Date( isMsec ? dt : dt*1000);
        }
        const hms = [
            this.zeroPad(''+dt.getUTCHours()),
            this.zeroPad(''+dt.getUTCMinutes()),
            this.zeroPad(''+dt.getUTCSeconds())
        ].join(':');
        return this.dateFormatUtc(dt) + " " + hms;
    }

    static dateTimeFormatUs(dt: DateOrUnixTime = new Date(), isMsec = false): string {
        if (typeof dt === 'number') {
            dt = new Date( isMsec ? dt : dt*1000);
        }
        const usDt = DateHelper.extractDateTimeUs(dt);
        const hms = [
            this.zeroPad(''+usDt.hour),
            this.zeroPad(''+usDt.minute),
            this.zeroPad(''+usDt.second)
        ].join(':');
        const to2 = (v: number) => {
            return (v < 10) ? '0' + v : v;
        }
        const ymd = [
            usDt.year,
            to2(usDt.month),
            to2(usDt.day),
        ].join('-');


        return ymd + " " + hms;
    }

    /**
     *
     * @param dt
     * @returns yyyy-MM-dd HH:mm:ss
     */
    static dateTimeFormat(dt: DateOrUnixTime = new Date(), isMsec = false): string {
        if (typeof dt === 'number') {
            dt = new Date( isMsec ? dt : dt*1000);
        }
        const hms = [
            this.zeroPad(''+dt.getHours()),
            this.zeroPad(''+dt.getMinutes()),
            this.zeroPad(''+dt.getSeconds())
        ].join(':');
        return this.dateFormatUtc(dt) + " " + hms;
    }

    static toDate(dt: DateOrUnixTime = new Date(), isMsec = false) {
        if (typeof dt === 'number') {
            dt = new Date(isMsec ? dt : dt * 1000);
        }
        return dt;
    }

    /**
     *
     * @param dt
     * @return string 'yyyy-mm-dd'
     */
    static dateFormatUtc(dt: DateOrUnixTime = new Date(), isMsec = false) : string {
        const dtSt = this.dateFormatUtcNumber(dt, isMsec).toString(10);
        return dtSt.substr(0,4) + '-' + dtSt.substr(4, 2) + '-' + dtSt.substr(6, 2);
    }

    static dateFormatUtcNumber(dt: DateOrUnixTime = new Date(), isMsec = false) : number {
        if (typeof dt === 'number') {
            dt = new Date( isMsec ? dt : dt*1000);
        }
        const month = '' + (dt.getUTCMonth() + 1);
        const day = '' + dt.getUTCDate();
        const year = dt.getUTCFullYear();

        return parseInt([year, this.zeroPad(month), this.zeroPad(day)].join(''), 10);
    }

    /**
     *
     * @param dt
     * @return string 'yyyy-mm-dd'
     */
    static dateFormat(dt: DateOrUnixTime = new Date(), isMsec = false) : string {
        const dtSt = this.dateFormatNumber(dt, isMsec).toString(10);
        return dtSt.substr(0,4) + '-' + dtSt.substr(4, 2) + '-' + dtSt.substr(6, 2);
    }

    static dateFormatNumber(dt: Date|number = new Date(), isMsec = false) : number {
        if (typeof dt === 'number') {
            dt = new Date( isMsec ? dt : dt*1000);
        }
        const month = '' + (dt.getMonth() + 1);
        const day = '' + dt.getDate();
        const year = dt.getFullYear();

        return parseInt([year, this.zeroPad(month), this.zeroPad(day)].join(''), 10);
    }

    static yearMonthFormatUtcNumber(dt: DateOrUnixTime = new Date(), isMsec = false) : number {
        const dtNumber = DateHelper.dateFormatUtcNumber(dt, isMsec);
        return Math.floor(dtNumber/100);
    }

    /**
     *
     * @param date 'yyyy-mm-dd
     */
    static parseDateUtc(date: string) {
        return new DateEx(date + 'T00:00:00.000+00:00');
    }

    /**
     *
     * @param date 'yyyy-mm-dd hh:ii:ss'
     */
    static parseDateTimeUtc(date: string) {
        return new DateEx(date.replace(' ', 'T') +'.000+00:00');
    }

    static epochSec(dt: Date = new Date()) {
        return Math.round(dt.getTime() / 1000);
    }

    public static zeroPad(val: string) {
        if (val.length<2) {
            val = '0' + val;
        }
        return val;
    }

    static formatDateTimeUs(dt:DateOrUnixTime, timeZone= DateHelper.EAST_TIMEZONE, params?: Intl.DateTimeFormatOptions) {
        params = Object.assign({
            dateStyle: 'short',
            timeStyle: 'short',
            timeZoneName: undefined,
            timeZone: timeZone
        }, params||{}) as Intl.DateTimeFormatOptions;
        return new Intl.DateTimeFormat(this.LOCALE, params)
                .format(DateHelper.toDate(dt));
    }

    static formatDateUs(dt:DateOrUnixTime, timeZone= DateHelper.EAST_TIMEZONE, params?: Intl.DateTimeFormatOptions) {
        params = Object.assign({
            dateStyle: 'short',
            timeStyle: undefined,
            timeZoneName: undefined,
            timeZone: timeZone
        }, params||{}) as Intl.DateTimeFormatOptions;
        return new Intl.DateTimeFormat(this.LOCALE, params)
                .format(DateHelper.toDate(dt));
    }

    static extractDateTimeUs(dt:DateOrUnixTime, timeZone= DateHelper.EAST_TIMEZONE) {
        dt = DateHelper.toDate(dt);
        const str = DateHelper.formatDateTimeUs(dt, timeZone, {
            // hour12: false,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            dateStyle: undefined,
            timeStyle: undefined,
            hourCycle: 'h23',
            weekday: 'short',
        } as any);
        const data = str.split(', ');
        const dayOfWeek = data[0].toLowerCase();
        const date = data[1].split('/');
        const time = data[2].split(':');
        const hEst = +time[0];
        const hUtc = dt.getUTCHours();
        const utcOffset = (hEst > hUtc ? hUtc + (24 - hEst) : hUtc - hEst) * -1;
        const daysMap = new Map<string,number>([
                ['sun',0],['mon',1],['tue',2],['wed',3],['thu',4],['fri',5],['sat',6]
        ]);
        // EST, UTC−05:00. EDT, UTC−04:00
        return {
            year: +date[2],
            month: +date[0],
            day: +date[1],
            hour: +time[0],
            minute: +time[1],
            second: +time[2],
            // @TODO: it should minute offset ideally, to cover minute shifts for rare timezones
            utcOffset: (hEst > hUtc ? hUtc + (24 - hEst) : hUtc - hEst) * -1,
            weekday: daysMap.get(dayOfWeek) as number,
            // localOffset:  dt.getTimezoneOffset()/60 + utcOffset,
        } as DateExtracted;
    }

    /**
     * check the YMD part of the dates if they are equal (no time)
     * @param dt1
     * @param dt2
     * @returns boolean
     */
    static isSameDay(dt1: DateOrUnixTime, dt2: DateOrUnixTime): boolean {
        return this.dateFormat(dt1) === this.dateFormat(dt2)
    }

    /**
     * Previous US day, DST calc
     * @param curr
     * @param daysAgo - can be position or negative
     */
    static prevNextDateUs(curr: Date, daysAgo:number): DateEx {
        // @TODO: can impact performance, otherwise need to predefine DST shifts
        // can't just  -1 day, because hour can "shift" as result of Daylight saving time
        let dPrev = new DateEx(curr); // @TODO:  it should be
        const usNow = this.extractDateTimeUs(curr);
        dPrev.setDate(dPrev.getDate()+daysAgo);
        const usPrev = this.extractDateTimeUs(dPrev);
        const diff = usNow.utcOffset-usPrev.utcOffset;
        if (diff!==0) {
            dPrev = new DateEx(curr.getTime() + daysAgo*86400_000 + diff*3_600_000);
        }
        return dPrev;
    }

    static makeDate(now:Date|null, days:number=0, hours:number=0): DateEx {
        const date = now === null ? new DateEx() : new DateEx(now.getTime());
        if (days!==0) {
            date.setDate(date.getDate()+days);
        }
        if (hours!==0) {
            date.setHours(date.getHours()+hours);
        }
        return date;
    }

    static round5MinFloor(dt: DateOrUnixTime) {
        return this.roundSecsFloor(dt, 300);
    }

    static roundSecsFloor(dt: DateOrUnixTime, secs:number) {
        return new DateEx( Math.floor(this.toDate(dt).getTime()/1000/secs)*secs*1000)
    }

    static isRoundedMinute(value:number) {
        return value % 60 === 0
    }

    /**
     * @TODO: it doesn't have Clock EDT/EST forwards.
     *  This check is not critical for current usage, but if add - will slow down performance
     *
     * start - inclusive, first day is Sun
     * end - inclusive (23h:59m:59s)
     * @param now
     */
    static weekStartEndUs(now: DateEx) {
        const usDate = DateHelper.extractDateTimeUs(now);
        const nowSecs = now.getTimeSec();
        const startSecs = nowSecs - (usDate.weekday*86400+usDate.hour*3600+usDate.minute*60+usDate.second);
        const endSecs = startSecs + 86400*7 - 1;
        return {
            start: startSecs,
            end: endSecs
        }
    }

    static dayStartEndUs(now: DateEx) {
        const usDate = DateHelper.extractDateTimeUs(now);
        const nowSecs = now.getTimeSec();
        const startSecs = nowSecs - (usDate.hour*3600+usDate.minute*60+usDate.second);
        const endSecs = startSecs + 86400 - 1;
        return {
            start: startSecs,
            end: endSecs
        }
    }

    public static getFormattedTimeHHMM12hUS(p?:{
        date?: Date,
        padHour?:boolean,
        addTz?:boolean
    }): string {
        const date = p?.date || new Date();

        const usD = DateHelper.extractDateTimeUs(date);
        let hour = usD.hour;
        const ampm = hour >= 12 ? "PM" : "AM";
        if (hour > 12) {
            hour = hour - 12;
        }
        const tz = usD.utcOffset===-4?'EDT':'EST';
        const minute = usD.minute.toString().padStart(2, '0');
        const hourStr = p?.padHour?hour.toString().padStart(2, '0') : hour.toString();
        let tsUs = `${hourStr}:${minute} ${ampm}`;
        if (p?.addTz) {
            tsUs += ` ${tz}`;
        }
        return tsUs;
    }
}

