import { config } from "config";
import { AbortableHttpService } from "./AbortableHttpService";
import { userAuthenticationService } from "./AuthenticationService";
import { BaseSearchService } from "./BaseSearchService";
import { IStockSymbol } from "interfaces/IStockSymbol";

export type SymbolSearchParam = {
  symbolNameLike?: string,
  symbolName?: string,
}

const url: string = `${config.apiDomain}${config.apiBasePath}/user/stock/symbols/all`;
export const searchSymbolService = new BaseSearchService<IStockSymbol, SymbolSearchParam>(url, userAuthenticationService, new AbortableHttpService(userAuthenticationService))
