import { Helper } from '_utils';
import React from 'react'

export const colorMap: Map<number, [number, number]> = new Map<number, [number, number]>([
  [0, [255, 0]],
  [10, [255, 42]],
  [20, [255, 84]],
  [30, [255, 126]],
  [40, [255, 168]],
  [50, [255, 214]],
  [60, [214, 255]],
  [70, [168, 255]],
  [80, [126, 255]],
  [90, [84, 255]],
  [100, [0, 255]],
]);

export const getRgb  = (progress: number, opacity: number = 1) => {
  const normalizedProgress = Math.min(100, Math.max(0, progress));
  const [red, green] = colorMap.get(Helper.getNextN(10, normalizedProgress)) || [255, 0];
  return `rgb(${red},${green},0, ${opacity})`;
}

export const ProgressBar: React.FunctionComponent<{ progress: number, colorFn?: (progress: number) => string }> = ({ progress, colorFn }) => {
  // Ensure progress is within the valid range
  const normalizedProgress = Math.min(100, Math.max(0, progress));

  // Calculate color based on progress
  const getColor = () => {
    if (colorFn) {
      return colorFn(normalizedProgress);
    }

    // red 0 to green 2
    //const red = Math.round(255 - (normalizedProgress * 2.55));
    // const green = Math.round(normalizedProgress * 2.55);
    return getRgb(progress);
  };

  const containerStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    backgroundColor: '#343434',
    overflow: 'hidden',
  };

  const fillerStyle: React.CSSProperties = {
    height: '100%',
    width: `${normalizedProgress}%`,
    backgroundColor: getColor(),
    transition: 'width 0.5s',
  };

  return (
    <div className="pill" style={containerStyle}>
      <div style={fillerStyle}></div>
    </div>
  );

}

