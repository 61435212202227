import {useCallback, useEffect, useRef, useState} from "react";
import {APIState, ApiStateImpl, IApiState} from "../_constants/APIState";

export function useApiServiceState<T> (s:()=>Promise<T>) {

  const [ items, setItems ] = useState<T>()
  const [ state, setState ] = useState<IApiState>(ApiStateImpl.IDLE);
  const [supplier, setSupplier] = useState<()=>Promise<T>>(()=>s);
  // const counter = useRef(0);
  const load = useCallback(async ()=>{
    try {
      setState(ApiStateImpl.LOADING);
      setItems(await supplier());
      setState(ApiStateImpl.LOADED);
    } catch (error) {
      setState(new ApiStateImpl(APIState.ERROR, error as Error));
    }
  }, [supplier]);

  useEffect(()=>{
   load();
  }, [load])

  return {items, state, setSupplier:(s:()=>Promise<T>)=>setSupplier(()=>s), reload:()=>{load()}, setState};

}
