import { StorageKey } from "_constants";
import { ILastNotification } from "interfaces/account/ILastNotification";

export class LastNotificationHelper {


  private static isEmpty(notification: ILastNotification): boolean {
    const stringNotification = JSON.stringify(notification);
    return stringNotification === '{}' || stringNotification === '' || stringNotification === '""';
  }

  static setLast(notification: ILastNotification, storage: Storage) {
    if (this.isEmpty(notification)) {
      storage.setItem(StorageKey.LAST_NOTIFICATION_SEEN, '1'); 
      return;
    }
    storage.setItem(StorageKey.LAST_NOTIFICATION_KEY, JSON.stringify(notification));
    storage.setItem(StorageKey.LAST_NOTIFICATION_SEEN, '0'); 
  }

  static getLast(storage: Storage): string | null {
    return  storage.getItem(StorageKey.LAST_NOTIFICATION_KEY);
  }

  static isSeen(storage: Storage): boolean {
    const seen = storage.getItem(StorageKey.LAST_NOTIFICATION_SEEN);
    if (seen === null) {
      return true;      
    }
    return seen === "1";
  }

  static setSeen(storage: Storage) {
    storage.setItem(StorageKey.LAST_NOTIFICATION_SEEN, "1");
  }

  static isNew(notification: ILastNotification, storage: Storage): boolean {
    if (this.isEmpty(notification)) {
      return false;
    }
    const last = this.getLast(storage);
    return (last !== JSON.stringify(notification)) 
  }

  static remove(storage: Storage) {
    storage.removeItem(StorageKey.LAST_NOTIFICATION_KEY)
    storage.removeItem(StorageKey.LAST_NOTIFICATION_SEEN)
  }

}