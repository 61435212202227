import { APIState } from "_constants/APIState";
import { IQuote } from "components/public/Stock/IQuote";
import { useCallback, useEffect, useRef, useState } from "react";
import { PredictionService } from "services";

export function useStockSymbolLastQuote (symbolName: string, service: PredictionService) {

  const [ quote, setQuote ] = useState<IQuote>({} as IQuote);
  const [ apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<Error | undefined>(undefined);

  const serviceRef = useRef(service);

  const load = useCallback(async ()=>{
    try {
      setApiState(APIState.LOADING);
      const quote = await serviceRef.current.getLastQuote(symbolName);
      setQuote(quote);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as Error);
    }
  }, [serviceRef, symbolName])

  useEffect(()=>{
   load();
  }, [load])

  return {quote, apiState, error, status: {
    loading: apiState === APIState.LOADING,
    loaded:  apiState === APIState.LOADED,
    idle: apiState === APIState.IDLE,
    error: apiState === APIState.ERROR
  }};

}