import React from 'react';
import { Modal } from "react-bootstrap";
import { PhoneConfirmationWizard } from './PhoneConfirmationWizard';

interface IProps {
  show: any;
  handleClose: any;
  onSuccess: (phoneNumber: number) => void;
  initialPhoneNumber?: number,
}

export const ModalPhoneConfirmationWizard: React.FunctionComponent<IProps> = ({
  show,
  handleClose,
  onSuccess,
  initialPhoneNumber
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>

        <PhoneConfirmationWizard initialPhoneNumber={initialPhoneNumber} onSuccess={onSuccess} handleClose={handleClose}/>

      </Modal.Body>
    </Modal>
  );
};

