export enum LimitVerticalDrag {
  UP = 1,
  DOWN = 2
}

export interface IDragParams {
    elmnt: HTMLDivElement;
    containerElement: HTMLDivElement;
    overlay: HTMLDivElement;
    overlayYShiftTop: number;
    onDragCallback: (pixelsMoved: any)=>void;
    beforeDragCallback?: ()=>void;
    limitVerticalDrag?: LimitVerticalDrag;
}

export function dragElement(p: IDragParams) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

  var initialVerticalPosition = p.elmnt.offsetTop;
  

  p.elmnt.ontouchstart = onTouchStart;
  p.elmnt.onmousedown = dragMouseDown;
  p.elmnt.style.cursor = 'grabbing';

  function onTouchStart ( e: any ) {

    //document.body.style.overflow = "hidden"; // stop scrolling when dragging

    // get the mouse cursor position at startup:
    pos3 = e.targetTouches[0].clientX;
    pos4 = e.targetTouches[0].clientY;

    if (p.beforeDragCallback) {
      p.beforeDragCallback();
    }

    document.ontouchend = onTouchEnd;
    //document.ontouchmove = elementSwipe;
    document.addEventListener('touchmove', elementSwipe, {passive: false})
  }
  
  function onTouchEnd ( e: any ) {
    document.ontouchend = null;
    document.ontouchmove = null;
    document.removeEventListener('touchmove', elementSwipe);
  }

  function dragMouseDown(e: any) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;

    if (p.beforeDragCallback) {
      p.beforeDragCallback();
    }

    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: any) {
    e = e || window.event;
    e.preventDefault();

    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;

    const rect = p.containerElement.getBoundingClientRect();
    const childRect = p.elmnt.getBoundingClientRect();

    const offsetTop = (p.elmnt.offsetTop - pos2) < 0 ? 0 :
        (p.elmnt.offsetTop - pos2) >= rect.height - childRect.height ? rect.height - childRect.height : (p.elmnt.offsetTop - pos2) ;

    // check for vertical limit
    if (p.limitVerticalDrag) {
      if (p.limitVerticalDrag === LimitVerticalDrag.UP && offsetTop >  initialVerticalPosition) {
        return;
      }

      if (p.limitVerticalDrag === LimitVerticalDrag.DOWN &&  offsetTop < initialVerticalPosition) {
        return;
      }
    }

    // currentYPosition = offsetTop;
    p.elmnt.style.top = offsetTop + "px";
    p.overlay.style.top = (offsetTop-p.overlayYShiftTop) + "px";
    p.onDragCallback(offsetTop);

  }

  function elementSwipe(e: any) {
    e = e || window.event;
    e.preventDefault();

    // calculate the new cursor position:

    const clientX = e.targetTouches[0].clientX;
    const clientY = e.targetTouches[0].clientY;

    pos1 = pos3 - clientX;
    pos2 = pos4 - clientY;
    pos3 = clientX;
    pos4 = clientY;

    const rect = p.containerElement.getBoundingClientRect();
    const childRect = p.elmnt.getBoundingClientRect();

    const offsetTop = (p.elmnt.offsetTop - pos2) < 0 ? 0 :
        (p.elmnt.offsetTop - pos2) >= rect.height - childRect.height ? rect.height - childRect.height : (p.elmnt.offsetTop - pos2) ;

    // check for vertical limit
    if (p.limitVerticalDrag) {
      if (p.limitVerticalDrag === LimitVerticalDrag.UP && offsetTop >  initialVerticalPosition) {
        return;
      }

      if (p.limitVerticalDrag === LimitVerticalDrag.DOWN &&  offsetTop < initialVerticalPosition) {
        return;
      }
    }

    // currentYPosition = offsetTop;
    p.elmnt.style.top = offsetTop + "px";
    p.overlay.style.top = (offsetTop-p.overlayYShiftTop) + "px";
    p.onDragCallback(offsetTop);

  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
