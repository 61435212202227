import React from 'react'
import { WatchList } from './WatchList'
import { useAccountSettings } from '_hooks/useAccountSettings';
import { APIState } from '_constants/APIState';
import { Helmet } from 'react-helmet';

export const DashboardPage2: React.FunctionComponent = () => {
  const { settings: userSettings, apiState: settingsApiState, updateSettings, /* settingsError */ } = useAccountSettings();
  return (
    <>
      <Helmet>
        <title>Predictagram: Dashboard</title>
      </Helmet>
      <div className="dashboard-2 mt-md-4">

        {settingsApiState === APIState.LOADED &&
          <div className="">
            <WatchList settings={userSettings} updateSettings={updateSettings} />
          </div>
        }
      </div>


    </>

  )
}
