import React, { useState } from 'react'
import { StockHelper, NewYorkTz as tz } from 'predictagram-lib'
import { ILeagueSeason } from 'interfaces/league';
import { ContestHelper } from '_utils/ContestHelper';
import { SeasonFilter } from './SeasonFilter';
import { SeasonHelper } from '_utils/SeasonHelper';


export const enum DateFilterEnum {
  TODAY = 'Today',
  THIS_SEASON = 'Current Season',
  YESTERDAY = 'Yesterday',
  SEVEN_DAYS = '7 Days',
  SEASON = 'Season',
}

export interface IDateFilterTimeRange {
  startTime: number,
  endTime?: number,
}

export const TeamDateFilters: React.FunctionComponent<{
  season: ILeagueSeason,
  onClick: (timeRange: IDateFilterTimeRange, dateFilter: DateFilterEnum, season: ILeagueSeason) => void,
  initialDateRange?: DateFilterEnum,
  previousSeason?: ILeagueSeason,
  showSeasonFilter?: boolean,
}> = ({
  season,
  onClick,
  initialDateRange,
  previousSeason,
  showSeasonFilter = true
}) => {

    const dayInMs = 24 * 60 * 60 * 1000;
    const todayNyDate = tz.getDateMidnight(new Date());
    const today = tz.getDateMidnight(new Date()).getTime();
    const yesterday = StockHelper.findPreviousTradingDay(new Date(today)).getTime();
    const sevenDaysAgo = (today - (dayInMs * 7)) / 1000;
    const sevenDays = sevenDaysAgo < season.startTime ? season.startTime * 1000 : sevenDaysAgo * 1000;
    const seasonStartTime = StockHelper.workingHours(new Date(season.startTime * 1000)).start.getTime();
    const seasonEndTime = StockHelper.workingHours(new Date(season.endTime * 1000)).end.getTime();

    const yesterdayLabel = ContestHelper.getPreviousDayName(new Date(today));

    const [activeTab, setActiveTab] = useState<DateFilterEnum>(initialDateRange || DateFilterEnum.SEVEN_DAYS);
    const [ selectedSeason, setSelectedSeason ] = useState<ILeagueSeason>(season);

    const filters: Map<DateFilterEnum, IDateFilterTimeRange> = new Map<DateFilterEnum, IDateFilterTimeRange>();
    if (StockHelper.isTradingDay(todayNyDate) && StockHelper.isPostMarketHours(new Date())) {
      filters.set(DateFilterEnum.TODAY, { startTime: today });
    }

    if (!tz.isSameDay(new Date(), new Date(season.startTime * 1000))) {
      // only add yesterday if it's not the first day of the season
      filters.set(DateFilterEnum.YESTERDAY, {
        startTime: StockHelper.workingHours(new Date(yesterday)).start.getTime(),
        endTime: StockHelper.workingHours(new Date(yesterday)).end.getTime()
      });
    }
    filters.set(DateFilterEnum.SEVEN_DAYS, { startTime: sevenDays });
    filters.set(DateFilterEnum.THIS_SEASON, { startTime: seasonStartTime, endTime: seasonEndTime });

    // if (previousSeason) {
    //   const { startTime, endTime } = previousSeason;
    //   const prevSeasonStartTime = StockHelper.workingHours(new Date(startTime * 1000)).start.getTime();
    //   const prevSeasonEndTime = StockHelper.workingHours(new Date(endTime * 1000)).end.getTime();
    //   filters.set(DateFilterEnum.SEASON, { startTime: prevSeasonStartTime, endTime: prevSeasonEndTime });
    // }

    const _onClick = (dateFilter: DateFilterEnum, timeRange: IDateFilterTimeRange, season: ILeagueSeason) => {
      setActiveTab(dateFilter);
      onClick(timeRange, dateFilter, season)
    }

    const getTabLabel = (filter: string, timeRange: IDateFilterTimeRange) => {
      if (filter === DateFilterEnum.YESTERDAY) return yesterdayLabel;

      if (filter === DateFilterEnum.THIS_SEASON) return 'Season';

      return filter;
    }

    const _onSelectSeason = (season: ILeagueSeason) => {
      const { startTime, endTime } = season;
      setSelectedSeason(season);
      _onClick(DateFilterEnum.SEASON, {
        startTime: StockHelper.workingHours(new Date(startTime * 1000)).start.getTime(),
        endTime: StockHelper.workingHours(new Date(endTime * 1000)).end.getTime(),
        }, season);
    }


    return (
      <div className="date-filters">
          <div className="date-filters-tabs d-flex justify-content-start align-items-center">
          {!!SeasonHelper.isDuringSeason(new Date(), selectedSeason) &&
            <>
            {Array.from(filters).map(([filter, timeRange], i: number) => <div
              role="button"
              key={`${i}-date-filter`}
              className={`p-2 px-3 btn-tabs ${activeTab === filter ? 'btn-tabs-active' : ''}`}
              onClick={() => _onClick(filter, timeRange, season)}>
              {getTabLabel(filter, timeRange)}
            </div>)}
            </>
        }
        </div>
        {!!showSeasonFilter ?
          <div className="date-filters-seasons">
            <SeasonFilter onSelect={_onSelectSeason} initialValue={season} />
          </div>
          :
          <div>{season.name}</div>
        }
      </div>
    )
  }
