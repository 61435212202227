/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { ModalDoc, ConfirmCheck, FieldWithError } from "components/common";
import { Terms } from "components/common/Terms";
import { ShowHidePassword } from "components/common/form/ShowHidePassword";

const AccountSetup: React.FunctionComponent<{
  errors: any;
  touched: any;
  readOnly?: boolean;
  showPasswordToggle?: boolean;
  emailDisabled?: boolean;
  showNickname?: boolean;
  loginUrl?: string;
  onShowPassword?: () => void;
  title?: JSX.Element | string
}> = ({
  errors,
  touched,
  readOnly = false,
  showPasswordToggle = false,
  emailDisabled = false,
  loginUrl,
  onShowPassword,
  showNickname = false,
  title = <div className="page-title">Register</div>
}) => {
  
    const [showPasswordFields, setShowPasswordFields] = useState<boolean>(false);
    const [showToC, setShowToC] = useState<boolean>(false);

    const onClickToC = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setShowToC(true);
    };

    return (
      <div className="container">
        {title}

        <div className="d-flex flex-column justify-content-center align-items-start">

          <div className="my-1">
            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="email"
              placeholder="E-Mail Address"
              label={`Email Address ${readOnly || emailDisabled ? "(Read-Only)" : ""}`}
              readOnly={readOnly || emailDisabled}
            />
            <div className="text-14 text-lighter-charcoal">Your email will be used for verification.</div>
          </div>

          <div className="my-1">
            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="username"
              placeholder="@username"
              label={`User Name (@name) ${readOnly || emailDisabled ? "(Read-Only)" : ""}`}
              readOnly={readOnly || emailDisabled}
              maxLength={15}
            />
          </div>

          {showNickname && 
          <div className="my-1">
            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="nickname"
              placeholder="Nickname"
              label={`Nickname`}
              readOnly={readOnly}
              maxLength={25}
            />
          </div>
          }

          {showPasswordToggle && !showPasswordFields && (
            <button
              type="button"
              className="btn btn-secondary text-90 my-2"
              onClick={() => {
                setShowPasswordFields(true);
                if (onShowPassword) {
                  onShowPassword();
                }

              }}
            >
              Change Password
            </button>
          )}

          {(!showPasswordToggle || (showPasswordToggle && showPasswordFields)) && (
            <>
              <ShowHidePassword
                errors={errors}
                touched={touched}
                fieldName="password"
                placeholder="Enter Password"
                label="Password"
                readOnly={readOnly}
              />

              <ShowHidePassword
                errors={errors}
                touched={touched}
                fieldName="confirmPassword"
                placeholder="Confirm Password"
                label="Confirm Password"
                readOnly={readOnly}
              />

              {!emailDisabled && (
                <div className="row my-3">
                  <ModalDoc show={showToC} handleClose={() => setShowToC(false)} title="Terms and Conditions">
                    <Terms isModal={true} />
                  </ModalDoc>
                  <ConfirmCheck name="agreeTerms" errors={errors} touched={touched} readOnly={readOnly}>
                    <div className="agree-terms ">
                      I understand that this is not financial advice and I have read and accept the{" "} <a role="button" className="btn-link" onClick={onClickToC}>
                        Terms and Conditions
                      </a>
                    </div>
                  </ConfirmCheck>
                </div>
              )}

              {loginUrl && (
                <div className="row">
                  <div className="col text-center my-3">
                    Already have an account? <button type="button" role="link" className="btn btn-link">Log in</button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

      </div>
    );
  };

export { AccountSetup };
