import React, { useContext } from 'react';
import { config } from 'config';
import { Url } from '_constants';
import { MessengerContext, Severity } from './messenger';
import { RWebShare } from "react-web-share";
import { AccountContext } from './AccountContext';
import { SearchParams } from '_constants/SearchParams';
import { ShareBase } from './ShareBase';
import { IPublicProfile } from 'interfaces/IPublicProfile';

export const ShareProfile: React.FunctionComponent<{user: IPublicProfile}> = ({user}) => {

  const msgrContext = useContext(MessengerContext);
  const acctContext = useContext(AccountContext);
  const baseUrlPath = `${config.wwwDomain}${Url.PUBLIC_STOCK_PREDICTOR}/${user.userId}`;
  const qstrings: string[] = [];

  if (acctContext?.isLoggedIn()) {
    qstrings.push(`${SearchParams.UI_REFERRAL_USER_ID}=${acctContext.account.id}`);
  }

  const url = qstrings.length > 0 ? `${baseUrlPath}?${qstrings.join("&")}` : baseUrlPath;

  const onCopyLink = async () => {
    try {
      // console.log('copied url', { url });
      await navigator.clipboard.writeText(url);
      msgrContext.setMessage({ body: 'Url was copied to clipboard!' });
    } catch (error: any) {
      msgrContext.setMessage({ body: `Could not copy: ${error.message}` }, true, Severity.FATAL);
      console.error({ error });
    }
  }

  return (
    <ShareBase onCopyLink={onCopyLink} title="" className="my-2" location="bottom">
      {({ setShow }) => {
        return <RWebShare
          data={{
            text: `Check out my stock predictions at Predictagram! It's fun and completely free. Come join the fun and, who knows, you might even win prizes!`,
            url: url,
            title: `Check out my stock price predictions at Predictagram`,
          }}
          onClick={() => setShow(false)}
        >
          <div role="button">Share via...</div>
        </RWebShare>
      }}
    </ShareBase>
  );
}


