import React, {useContext, useRef, useState} from "react";
import {ErrorMessage, Field, Formik, FormikContext, useFormikContext} from "formik";

// @TODO: Formik is dead?:
// https://github.com/jaredpalmer/formik/discussions/3526

// Formik is missing a hook to map easily input value to selected option,
// "setFieldValue" map output value and it works fine, but there is not way to populate back properly easily

export interface DropDownType<Label,Value> {
  label: string | JSX.Element;
  name: string;
  options: {label:Label, value:Value}[];
  multiple?: boolean;
  onChange?: (v:Value[]|Value)=>void;
  value?: Value[]|Value,

  [key: string]: any; // rest of params
}

export function DropDownGeneric<Label,Value>(props: DropDownType<Label,Value>) {

  const refVal = useRef(props.value==null ? undefined : props.value);

  const context = useContext(FormikContext);

  // @TODO: need to think more about the population
  // let valForm: any = undefined; // props.value;
  const mapBack = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const getByIndex = (val:string)=>{
      const index = parseInt(val);
      return props.options[index].value;
    }
    if (!props.multiple) {
      return getByIndex(e.target.value);
    } else {
      const options = e.target.options;
      const sOpts = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          sOpts.push(getByIndex(`${i}`));
        }
      }
      return sOpts;
    }
    // return fullMap.get(list[index])
  }
  let onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      // context.handleChange(e);
      const val = mapBack(e);
      if (context) {
        context.setFieldValue(props.name, val);
      }
      refVal.current = val as Value[];
      if (props.onChange) {
          // @ts-ignore
        return props.onChange(val);
      }
      // valForm = val;
  };

  if (refVal.current===undefined && context) {
      const data = context.getFieldProps({name: props.name});
      refVal.current = data.value;
  }
  // if (valForm===undefined && props.defaultValue!==undefined) {
  //   valForm = props.defaultValue;
  // }
  let index:any;
  if (!props.multiple) {
    index = props.options.findIndex((v)=>v.value==refVal.current);
    if (index===-1) {
      index = undefined;
    }
  } else {
    index = [];
    (refVal.current as []||[])?.forEach((sVal=>{
      const pos = props.options.findIndex((v)=>v.value==sVal);
      if (pos!==-1) {
        index.push(pos);
      }
      // console.debug({sVal,pos,opts:props.options});
    }));
  }
  // console.debug({currVal:refVal.current,props});
  const formikField = ()=>{
    return (
      <div className="form-group">
        {props.label && props.label !=="" && <>{props.label}</>}
        <Field as="select"
               {...props}
               // name={props.name}
          // className={"form-control" + (props.errors && props.errors[props.name] && props.touched && props.touched[props.name] ? " is-invalid" : "")}
               // className={props.className}
               onChange={onChange}
               value={index}
               // multiple={props.multiple}

        >
          {/*{props.defaultItem && <option value={props.defaultItem.key}>{props.defaultItem.value}</option>}*/}

          {props.options.map((opt, i) => {
            return (
              <option key={`code_${i}`} value={i}
                // selected={val===opt.value}
              >
                {opt.label as any}
              </option>
            )
          })}
        </Field>
        {/*</select>*/}
        {/*{props.errors && <ErrorMessage name={props.name} component="div" className="invalid-feedback" />}*/}
      </div>
    );
  }

  const formField = ()=>{
    return (
      <div className="form-group">
        {props.label && props.label !=="" && <>{props.label}</>}
        {/*<select*/}
        <select
          {...props}
         // name={props.name}
         // className={"form-control"}
         onChange={onChange}
         value={index}
         // multiple={props.multiple}

        >

          {props.options.map((opt, i) => {
            return (
              <option key={`code_${i}`} value={i}
                // selected={val===opt.value}
              >
                {opt.label as any}
              </option>
            )
          })}
        </select>
        {/*</select>*/}
        {/*{props.errors && <ErrorMessage name={props.name} component="div" className="invalid-feedback" />}*/}
      </div>
    );
  }
    if (context) {
      return (
        formikField()
      )
    } else {
      return (
        formField()
      )
    }
}
