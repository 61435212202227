import { config } from 'config';
import React, { useCallback, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AccountContext } from './AccountContext';
import { Helper } from '_utils';

export const ApiPixel: React.FC = () => {

  const location = useLocation();
  const acctContext = useContext(AccountContext);

  const getPixelCb = useCallback(async () => {
    const params = {
      event: 'view',
      pathname: location.pathname,
      search: location.search,
      userid: acctContext?.isLoggedIn() ? acctContext?.account.id : undefined
    }
    const url = `${config.apiDomain}/check${Helper.objectToQueryString(params)}`
    await fetch(url);
  }, [location.pathname])

  useEffect(()=>{
    getPixelCb();
  },[getPixelCb])

  return (
    <></>
  )
}
