import { RoundCloseButton } from 'components/common/buttons/RoundCloseButton';
import React from 'react'
import { Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';
import { PixelHelper } from '_utils/PixelHelper';

const DiscountPromo: React.FunctionComponent<{ onClose: () => void }> = ({ onClose }) => {

  const navigate = useNavigate();

  const onClick = () => {
    onClose();
    PixelHelper.trackGAEvent('click_modal_reserve_discount');
    navigate(Url.DISCOUNT_PROMO);
  }

  return (
    <div className="discount-promo-modal">
      <div className="d-flex justify-content-end"><RoundCloseButton onClick={()=>{onClose()}}/></div>

      <div className="d-flex justify-content-center align-items-center">
        <div className="discount-promo-modal-chart">
          <img src="/images/discount-promo/chart.png" alt="Chart" />
        </div>
      </div>

      <div className="d-flex flex-column gap-3 my-3 align-items-center">
        <div className="fw-bold text-18 text-center">Don't miss out on our <span className="text-mint-green">Pro Signals</span>!</div>
        <div className="text-center text-14">We’re about to make our advanced trading algorithms available to the public, and we’re offering a 50% discount to the first 500 users who sign up. </div>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <div className="pill bg-mint-green py-3 px-4 text-black fw-bold text-center" role="button" onClick={onClick}>Learn More &amp; Reserve Discount</div>
        </div>
      </div>
    </div>
  );
}

export const ModalDiscountPromo: React.FunctionComponent<{ show: boolean; handleClose: () => void }> = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered backdrop="static" keyboard={false} >
      <Modal.Body>
        <div className="p-3">
        <DiscountPromo onClose={handleClose} />
        </div>
      </Modal.Body>
    </Modal>

  )

}


