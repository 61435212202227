import { useApi } from "./useApi";
import { config } from "config";
import { adminAuthenticationService } from "services";
import { AbortableHttpService } from "services/AbortableHttpService";
import { AdminUserTicketsApiService, IAdminUserTicketsResponse, IAdminUserTicketsSearchOptions } from "services/AdminUserTicketsApiService";

const apiUrlBase = `${config.apiDomain}${config.apiBasePath}/admin`;
const httpService = new AbortableHttpService(adminAuthenticationService);
export const adminUserTicketsService = new AdminUserTicketsApiService(apiUrlBase, httpService);

export function useAdminUserTickets (filter: IAdminUserTicketsSearchOptions) {
  const load = async (filter: IAdminUserTicketsSearchOptions, abortSignal?: AbortSignal) => {
    return await adminUserTicketsService.getUserTickets(filter, abortSignal);
  }
  return useApi<IAdminUserTicketsResponse[], IAdminUserTicketsSearchOptions>(load, filter);
}

