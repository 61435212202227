import { APIState } from "_constants/APIState";
import { IAccountSettings } from "interfaces/account/IAccountSettings";
import { useEffect, useState } from "react";
import { userAccountService } from "services";

export function useAccountSettings () {

  const [ settings, setSettings] = useState<IAccountSettings>({} as IAccountSettings);
  const [ apiState, setApiState ] = useState<APIState>(APIState.IDLE);
  const [ error, setError] = useState<Error | undefined>(undefined);

  useEffect(()=>{
    const _load = async () => {
      try {
        setApiState(APIState.LOADING);
        const settings = await userAccountService.getSettings();
        setSettings(settings);
        setApiState(APIState.LOADED);
      } catch (error: any) {
        setError(error);
        setApiState(APIState.ERROR);
      }
    }
    _load();
  }, [])

  const updateSettings = async (settings: IAccountSettings | unknown) => {
    const updated = await userAccountService.saveSettings(settings as IAccountSettings);
    setSettings(updated);
  }

  return { settings, apiState, updateSettings, settingsError: error }
}