import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import * as Icons from 'components/common/Icons';
import { ILeagueSeason, ITeam } from 'interfaces/league';
import { userLeagueApiService } from 'services/UserLeagueApiService';
import { AccountContext } from 'components/common';
import { MessengerContext, Severity } from 'components/common/messenger';
import { useModalLoginRegisterPassword } from '_hooks/useModalLoginRegisterPassword';
import { Url } from '_constants';
import { Helper } from '_utils';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { NewYorkTz } from 'predictagram-lib';
import { usePublicProfile } from '_hooks/usePublicProfile';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { ITeamSearchOptions } from '_hooks/useTeamPublic';

export const TeamDetailLayout: React.FunctionComponent = () => {

  const navigate = useNavigate();
  const { teamId } = useParams();
  const acctContext = useContext(AccountContext);
  const msgrContext = useContext(MessengerContext);
  const [status, setStatus] = useState<string | undefined>(undefined);

  const tId = Number(teamId);

  const acctId: number = (acctContext?.account ? acctContext.account.id : 0) || 0;
  const user = usePublicProfile(acctId, userPredictionApiService);


  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [team, setTeam] = useState<ITeam | undefined>(undefined);
  const [members, setMembers] = useState<IPublicProfile[]>([]);
  const [season, setSeason] = useState<ILeagueSeason | undefined>(undefined);

  //const { item: season, loaded: loadedSeason } = useSeason(team?.latestSeasonId, userLeagueApiService);

  const joinTeam = async (teamId: number) => {
    try {
      await userLeagueApiService.joinTeam(teamId);
      setIsJoined(true);
      msgrContext.setMessage({ body: `Thank you for joining the team!` }, true, Severity.NORMAL);
    } catch (error: any) {
      const errBody = error.message;
      if ( String(errBody).includes('is already belongs to a team') ) {
        msgrContext.setMessage({ body: "You already belong to another team." }, true, Severity.FATAL);
      } else {
        msgrContext.setMessage({ body: `${error.message}` }, true, Severity.FATAL);
      }
    }
  }

  const onRegisterCallback = async () => {
    if (teamId) {
      if (Helper.lastPath(window.location.pathname) === Helper.lastPath(Url.USER_TEAM_INVITATION)) {
        // if "invitation" url, registration automatically joins the team so don't rejoin, just redirect
        navigate(Url.USER_TEAM_DETAIL.replaceAll(':teamId', teamId));
      } else {
        await joinTeam(Number(teamId));
      }
      setIsJoined(true);
    }
  }

  const loginRegisterPassword = useModalLoginRegisterPassword(onRegisterCallback);

  const _load = useCallback(async () => {
    try {
      const season = await userLeagueApiService.getDefaultSeason(new Date());
      const options: ITeamSearchOptions = {
        endTime: season.endTime,
        startTime: season.startTime,
        minUserScore: 0.4,
      }
      const [team, members] = await Promise.all([
        userLeagueApiService.getTeamById(tId, options),
        userLeagueApiService.getTeamMembers(tId),
      ]);
      setSeason(season);
      setTeam(team);
      setMembers(members);
      setIsJoined((user.item.team?.id === tId) || false);
        
    } catch (error: any) {
      const msg = error.message;
      if (String(msg).includes('Not Found')) {
        setStatus('Team Not Found');
      } else {
        setStatus((error as Error).message);
        console.error({error});
      }
    }
  }, [tId, user.item.team] )

  useEffect(() => {
    if (!teamId) {
      throw Error('no team id');
    }

    _load();
  }, [_load, teamId])


  const onJoinClick = async () => {
    if (acctContext && acctContext.isLoggedIn()) {
      if (tId) {
        await joinTeam(tId);
      }
    } else {
      loginRegisterPassword.register.show(true);
    }
  }

  const btnActiveClass = (path: string[]) => {
    return path.filter(s => Helper.lastPath(window.location.pathname) === Helper.lastPath(s)).length > 0 ? 'btn-tabs-active' : '';
  }

  const getDate = (dateInSec: number) => {
    const tz = NewYorkTz.mdyhms(new Date(dateInSec * 1000));
    return `${tz.monthName} ${tz.day}${Helper.numberSuffix(Number(tz.day))}`;
  }

  const isPreSeason = () => {
    if (!season) return false;
    const today = Date.now();
    return today < season.startTime * 1000
  }

  const isDuringSeason = () => {
    if (!season) return false;
    const today = Date.now();
    return today >= season.startTime * 1000 && today <= season.endTime * 1000
  }

  const teamPredictions = (team: ITeam): JSX.Element => {
    return (
      <>
        {season && isDuringSeason() ? (
          <div className="team-detail-owner-details-predictions">{team.predictionsCompleted} Predictions</div>
        ) : (
          <div className="team-detail-owner-details-predictions">No Predictions Yet</div>
        )}
      </>
    );
  }

  const onTabClick = (urls: string[], url: string) => {
    if (urls.filter(s => Helper.lastPath(window.location.pathname) === Helper.lastPath(s)).length > 0) {
      return;
    }

    navigate(url);
  }

  const Welcome = (): JSX.Element => {
    return <div className="d-flex flex-column justify-content-center align-items-center team-detail-welcome my-3">
      <div className="team-detail-welcome-title fw-bold text-110">Welcome to the team!</div>
      <PreseasonText />
    </div>
  }

  const PreseasonText = () => {
    return <>{season && isPreSeason() &&
      <div className="team-detail-welcome-info my-3">
        <div className="fw-bold text-normal">{season && <>The season begins on {getDate(season.startTime)}.</>}</div>
        <div className="text-80 mt-2">Make some predictions now to hone your skills during the preseason!</div>
      </div>
      }
    </>;
  }

  const TeamPredictions: React.FunctionComponent<{ team: ITeam }> = ({ team }) => {
    return <div className="team-detail-predictions">
      <div className="d-flex justify-content-center align-items-center my-3 gap-2">
        {season && isDuringSeason() && <div><span role="img" aria-label="accuracy">🎯</span>{(team.score * 100).toFixed(0)}%</div>}
        {season && isPreSeason() && <div><span role="img" aria-label="accuracy">🎯</span>N/A</div>}
        {teamPredictions(team)}
      </div>
    </div>

  }

  const PageTabs = () => {
    return <div className="team-detail-tabs my-3">
      <div className="d-flex justify-content-center align-items-center">
        <div role="button" className={`text-center py-2 btn-tabs ${btnActiveClass([Url.USER_TEAM_DETAIL, Url.USER_TEAM_INVITATION])}`} onClick={() => { onTabClick([Url.USER_TEAM_DETAIL, Url.USER_TEAM_INVITATION], Url.USER_TEAM_DETAIL.replaceAll(':teamId', tId.toString())) }}>Team Home</div>
        <div role="button" className={`text-center py-2 btn-tabs ${btnActiveClass([Url.USER_TEAM_ROSTER])}`} onClick={() => { onTabClick([Url.USER_TEAM_ROSTER], Url.USER_TEAM_ROSTER.replaceAll(':teamId', tId.toString())) }}>Roster</div>
        {!isPreSeason() &&
          <div role="button" className={`text-center py-2 btn-tabs ${btnActiveClass([Url.USER_TEAM_PREDICTION])}`} onClick={() => { onTabClick([Url.USER_TEAM_PREDICTION], Url.USER_TEAM_PREDICTION.replaceAll(':teamId', tId.toString())) }}>Predictions</div>
        }
      </div>
    </div>
  }

  const TeamLogoName: React.FunctionComponent<{ team: ITeam }> = ({ team }) => {
    return <>
      <div className="team-detail-logo m-auto mt-4">
        <img src={team?.logoUrl} alt="Team Logo" />
      </div>

      <div className="team-detail-name d-flex justify-content-center align-items-center mt-3">
        <div>{team.name}</div>
      </div>
    </>
  }

  const JoinTeamButton = () => {
    return <div role="button" className="btn btn-tertiary btn-top text-14" onClick={onJoinClick}>+ Join Team</div>
  }

  return (
    <>
      {loginRegisterPassword.render()}

      <div className="team-detail container my-3 my-md-5">

        {status && <div className="alert-warning">{status}</div>}
        {team &&
          <div className="d-flex flex-column justify-content-center">
            <div className="team-detail-head">
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <div role="button" onClick={() => { navigate(-1) }}><Icons.LeftArrow /></div>
                </div>

                {!isJoined && <JoinTeamButton />}

              </div>
            </div>

            <TeamLogoName team={team}/>

            <TeamPredictions team={team} />

            {(!isPreSeason() || acctContext?.isLoggedIn()) && <PageTabs />}

            <div className="d-flex justify-content-center align-items-center">

            {isJoined && <Welcome />}

            {!isJoined && <PreseasonText />}

            </div>

            <hr />

            <Outlet context={{ team, members, onJoinClick, isJoined, season, isDuringSeason: isDuringSeason(), teamPredictions: teamPredictions(team) }} />

          </div>
        }

      </div>
    </>
  );
}