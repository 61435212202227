import { Emoji } from 'components/common/Emoji'
import React from 'react'

export const AccuracyBar:React.FunctionComponent<{value: number, predictions: number, threshold: number}> = ({value, threshold, predictions}) => {

  const controlWidth = 160;
  const sliderWidth = 5;
  const labelWidth = 70;
  const valuePxPos = controlWidth * value / 100;

  const getCenterLabelPos = () => {
    const valuePxPos = controlWidth * value / 100;
    const offset = labelWidth / 2;
    const pos = valuePxPos - offset;
    if (pos < 0) {
      return 0;
    }
    if (pos > (controlWidth - labelWidth)) {
      return controlWidth - labelWidth;
    }
    return pos;
  }

  const containerStyle: React.CSSProperties = {
    width: `${controlWidth}px`,
    position: "relative",
    height: "14px",
    borderRadius: "5px",
    padding: "25px 0",
    margin: "0 10px",
  }

  const leftSide: React.CSSProperties = {
    width: "50%",
    position: "absolute",
    top: "25px",
    backgroundColor: "#1D1D1D",
    height: "14px",
    borderBottomLeftRadius: "5px",
    borderTopLeftRadius: "5px",
  }

  const rightSide: React.CSSProperties = {
    width: "50%",
    position: "absolute",
    left: "50%",
    backgroundColor: `${value >= threshold ?  "green" : "gray"}`,
    height: "14px",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    
  }

  const slider: React.CSSProperties = {
    display: predictions === 0 ? "none" : "block",
    position: "absolute",
    left: `${valuePxPos - (sliderWidth / 2)}px`,
    width: `${sliderWidth}px`,
    height: "20px",
    top: "22px",
    backgroundColor: "white",
  }

  const label: React.CSSProperties = {
    position: "absolute",
    width: `${labelWidth}px`,
    textAlign: "center",
    left: `${getCenterLabelPos()}px`,
    top: "-5px",

  }

  return (
    <div style={containerStyle}>
      <div style={leftSide}></div>
      <div style={rightSide}></div>
      <div style={slider}></div>
      <div style={label}>
        <Emoji symbol="🎯" text={""} label="accuracy" />{predictions === 0 ? "N/A" : value.toFixed(0)}%
      </div>
    </div>
  )
}
