import React from 'react';

export const Emoji: React.FunctionComponent<{label: string, symbol: string, text: string}> = ({label, symbol, text}) => {

  // const emojis = [
  //   "🎯",
  //   "🔥",
  //   "👏",
  //   "🙌",
  //   "😅",
  // ]

  return (
    <span 
      role="img"
      aria-label={label}
    >{symbol} {text}</span>
  );
}