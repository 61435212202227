import React, { useEffect, useRef, useState } from 'react'
import { ITradegramSecurity } from 'services/TradegramApiService'
import { ModalTrades } from './ModalTrades'
import { NetBalance, ProfitPct, ProfitPerShare, SymbolName } from './SecurityCard'
import { TradegramHelper } from '_utils/TradegramHelper'
import { TradeLineItem } from './TradeLineItem'
import { Copy } from 'components/common/Icons'
import { MoreTradesButton, ShareTradegramButton } from '../dashboard/TradeWide'

interface IProps {
  security: ITradegramSecurity,
  onSuccess: () => void, // call this when ModalTrade completes a trade
  addPosition?: () => void,
  closePosition?: () => void,
  collapsed?: boolean,
  showAddCloseButtons?: boolean,
  initialShowModal?: boolean,
}


const StatsLine: React.FunctionComponent<{ security: ITradegramSecurity }> = ({ security }) => {
  return (
    <div className="d-flex justify-content-between align-items-center gap-2">
      <div className="d-flex justify-content-start gap-3">
        {(TradegramHelper.hasSold(security) || TradegramHelper.isClosed(security)) ?
          <>
            <ProfitPct security={security} />
            <ProfitPerShare security={security} />
          </>
          :
          <div className="opacity-70 fw-bold text-12">
            {TradegramHelper.getPositionActionName(security)}
          </div>
        }
      </div>
      <NetBalance security={security} />
    </div>
  );
}


export const TradegramCard: React.FunctionComponent<IProps> = ({ security, addPosition, closePosition, onSuccess, collapsed = false, showAddCloseButtons = false, initialShowModal = false }) => {
  const [showModal, setShowModal] = useState<boolean>(initialShowModal);
  const [modalSecurity, setModalSecurity] = useState<ITradegramSecurity>({} as ITradegramSecurity);

  const onClick = () => { setModalSecurity(security); setShowModal(true); }

  const tradeLinkRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!initialShowModal && tradeLinkRef.current) {
      tradeLinkRef.current.click();
    }
  }, []);

  const _onSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  }

  const AddCloseButtons = () => {
    return <>
      <div className="d-flex justify-content-between tradegram-card-add-close align-items-center gap-2">
        <div className="d-flex justify-content-start gap-2">
          {showAddCloseButtons &&
            <div className="d-flex gap-2">
              {addPosition && <>
                {TradegramHelper.getQuantityBalance(security) === 0 ?
                  <div onClick={addPosition} role="button" className="btn btn-rounded border-white text-white px-3 text-12 d-flex justify-content-center align-items-center fw-bold gap-2"><Copy />Copy</div>
                  :
                  <button className="btn btn-primary text-14" onClick={addPosition}><span className="px-3">Add</span></button>
                }
                {closePosition && TradegramHelper.getQuantityBalance(security) !== 0 && <div><button className="btn border-white bg-black text-white text-14" onClick={closePosition}><span className="px-3">Close</span></button></div>}
              </>}
            </div>
          }
        </div>
        <div className="d-flex align-items-center gap-2">
          {security.transactions > 1 && <MoreTradesButton security={security} onClick={(onClick)} />}
          <ShareTradegramButton security={security} />
        </div>
      </div>
    </>
  }

  return (
    <div>
      <ModalTrades show={showModal} security={modalSecurity} handleClose={() => setShowModal(false)} onSuccess={_onSuccess} />
      <div role="button" onClick={onClick}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <SymbolName security={security} />
            </div>

            <div className="col-12 my-2">
              <StatsLine security={security} />
            </div>
          </div>

          <div className="my-3">
            <TradeLineItem security={security} tradegramTransaction={security.lastTransaction} index={0} showProfit={true} />
          </div>

        </div>
      </div>
      <AddCloseButtons />
    </div>
  )
}
