import React from 'react'

export const Badge:React.FunctionComponent<{children: any, footerText: string, [key: string]: any}> = ({children, footerText, ...rest}) => {
  return (
    <div className="badge" {...rest}>
      <div className="badge-content">
        {children}
      </div>

      <div className="badge-footer">
        {footerText}
      </div>
    </div>
  );
}
