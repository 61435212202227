import React from "react";

export interface IUserDropdownItem {
  id: number,
  username: string,
}

export const UsernameDropDown: React.FunctionComponent<{items: IUserDropdownItem[], setUser: (i: number) => void, initialValue: number}> = ({items, setUser, initialValue}) => {
  const _items: IUserDropdownItem[] = [
    {id: 0, username: 'All'},
    ...items.filter((obj, index, array) => {
      return index === array.findIndex(i => i.id === obj.id)
    })
    .sort((a, b) => a.username.localeCompare(b.username))
  ]

  
  const onChange  = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUser(parseInt(e.target.value));
  }

  return <div className="form-group">
    Select User
    <select name="select-username" className="form-control" value={initialValue} onChange={onChange}>
      {_items.map((item, idx) => <option value={item.id} key={`item-key-${idx}`}>{item.username}</option>)}
    </select>
  </div>
}