import { useCallback, useEffect, useRef, useState } from "react";
import { APIState } from "_constants/APIState";
import { ITradegramTotalsResponseItem, ITradegramTotalsParam, TradegramApiService } from "services/TradegramApiService";


export interface ITradegramSecurityTotals {
  items: ITradegramTotalsResponseItem[],
  apiState: {
    isLoading: () => boolean,
    isLoaded: () => boolean,
    isIdle: () => boolean,
    isError: () => boolean
  },
  error: Error | null,
  reload: () => void,
}

export function useTradegramSecurityTotals (service: TradegramApiService, symbolName?: string, userId?: number): ITradegramSecurityTotals {

  const [ items, setItems ] = useState<ITradegramTotalsResponseItem[]>([]);
  const [ apiState, setApiState ] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<Error | null>(null);
  const [refresh, setRefresh] = useState<number>(0);
  
  const serviceRef = useRef(service);

  const load = useCallback(async (abortSignal: AbortSignal)=>{
    try {
      setApiState(APIState.LOADING);
      if (userId) {
        const param: ITradegramTotalsParam = {symbolName: symbolName, userId};
        const items = await serviceRef.current.getSecuritiesTotals(param, abortSignal);
        setItems(items);
      } else {
        const param: ITradegramTotalsParam = {symbolName: symbolName};
        const items = await serviceRef.current.getMySecuritiesTotals(param, abortSignal);
        setItems(items);
      }
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as Error);
    } 
  }, [symbolName, userId])

  useEffect(()=>{
    const abortController = new AbortController();
    load(abortController.signal);

    // clean up
    return () => {
      if (abortController) {
        abortController.abort();
      }
    }    
  }, [load, refresh])

  const reload = () => {
    setRefresh(refresh + 1);
  }

  const isLoaded = () => apiState === APIState.LOADED;
  const isLoading = () => apiState === APIState.LOADING;
  const isError = () => apiState === APIState.ERROR;
  const isIdle = () => apiState === APIState.IDLE;

  return {items, apiState: {isLoaded, isLoading, isError, isIdle}, error, reload};

}