import React, { useContext, useState } from 'react';
import { AccountContext } from 'components/common';
import { MessengerContext, Severity } from 'components/common/messenger';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';
import { DeleteAccount } from './DeleteAccount';
import { Referrals } from './Referrals';
import * as Icons from "components/common/Icons";
import { Form } from 'react-bootstrap';
import { IAccountSettings } from 'interfaces/account/IAccountSettings';
import { useAccountSettings } from '_hooks/useAccountSettings';
import { AlertGroupInterval, ResultDeliveryTimeEnum, alertGroupIntervalNames, resultDeliveryTimeNames } from '_constants/ResultDeliveryTimeEnum';
import { ModalPhoneConfirmationWizard } from './ModalPhoneConfirmationWizard';
import { AccountDetails } from './AccountDetails';
import { APIState } from '_constants/APIState';
import { ManageSubscription } from './ManageSubscription';
import { SignalsSettings } from './SignalsSettings';
import { SignalSetting } from './SignalSetting';
import { useSignalSettings } from '_hooks/useSignalSettings';
import { Spinner } from 'components/common/Spinner';
import { ISignalSettings } from 'services/UserSignalApiService';
import { CancelSubscription } from './CancelSubscription';
import { useSubscription } from '_hooks/useSubscription';
import { useModalSignalSignup } from '_hooks/useModalSignalSignup';

export const enum SettingOption {
  NONE = 0,
  DELETE = 1,
  REFERRALS = 2,
  ALERTS = 3,
  PREDICTION_RESULTS = 4,
  REMINDERS = 5,
  DELIVERY_SCHEDULE = 6,
  ACCOUNT_DETAILS = 7,
  DELIVERY_INTERVAL = 8,
  PAYMENT_INFO = 9,
  MANAGE_SUBSCRIPTION = 10,
  SIGNALS = 11,
  SIGNAL_SETTING = 12,
  CANCEL_SUBSCRIPTION = 13,
  RENEW_SUBSCRIPTION = 14

}

export const ReceiveViaTextMessage = () => {
  return (
    <>
      <div className="text-10 text-light-gray">Coming soon!</div>
      <div className="text-light-gray">
        Receive Via Text Message
      </div>
    </>
  )
}


export const ReceiveViaNotification = () => {
  return (
    <>
      <div className="text-10 text-light-gray">Coming soon!</div>
      <div className="text-light-gray">
        Receive Via Text Notification
      </div>
    </>
  )
}

export const Settings: React.FunctionComponent<{ closeHandler?: any }> = ({ closeHandler }) => {

  // setShow is the parent setShow. use to hide the parent that called this, if any

  const acctContext = useContext(AccountContext);
  const msgrContext = useContext(MessengerContext);
  const [activeSetting, setActiveSetting] = useState<SettingOption>(SettingOption.NONE);
  const [showPhone, setShowPhone] = useState<boolean>(false);
  const [smsField, setSmsField] = useState<string | undefined>(undefined);
  const [currentSignalSetting, setCurrentSignalSetting] = useState<ISignalSettings | undefined>(undefined);

  const navigate = useNavigate();

  const { settings: userSettings, apiState, updateSettings, settingsError } = useAccountSettings();

  const signalSettingsHook = useSignalSettings();
  const subscriptionHook = useSubscription();
  const signup = useModalSignalSignup(false);

  const _handleClose = () => {
    setActiveSetting(SettingOption.NONE);
    if (closeHandler) {
      closeHandler();
    }
  }

  const onDeleteCallback = () => {
    _handleClose();
    navigate(Url.DEFAULT_HOME);
    msgrContext.setMessage({ body: 'Your account has been permanently removed.' }, true, Severity.NORMAL);
  }

  const handleCheckToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await updateSettings({ [event.target.name]: event.target.checked } as unknown)
  }

  const handleDeliveryOption = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await updateSettings({ resultsDeliveryTime: Number(event.target.value) } as IAccountSettings)
  }

  const handleAlertSendEmail = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await updateSettings({ alertsSendEmail: event.target.checked } as IAccountSettings);
    if (event.target.checked) {
      setActiveSetting(SettingOption.DELIVERY_INTERVAL);
    }
  }

  const handleSignalCheckToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!currentSignalSetting) {
      throw Error('no current signal setting found');
    }
    const payload: Partial<ISignalSettings> = {
      [event.target.name]: event.target.checked
    };
    try {
      await signalSettingsHook.saveSettings(currentSignalSetting.symbolId, payload);
      const setting = {
        ...currentSignalSetting,
        [event.target.name]: event.target.checked
      }
      setCurrentSignalSetting(setting)
      
    } catch (error: any) {
      msgrContext.setMessage({body: error.message}, true, Severity.FATAL);
    }

    //await updateSettings({ [event.target.name]: event.target.checked } as unknown)
  }

  const handleAlertGroupOption = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await updateSettings({ alertsGroupInterval: Number(event.target.value) } as IAccountSettings)
  }


  const handleCheckSMSToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {

    if (!acctContext?.account) {
      throw Error('Unexpected error. Missing account');
    }

    // turning on and no phone number
    if (event.target.checked === true && (!acctContext.account.phoneNumber || (acctContext.account.phoneNumber && acctContext.account.phoneNumberConfirmed === false))) {
      setSmsField(event.target.name);
      setShowPhone(true);
      return;
    }
    await updateSettings({ [event.target.name]: event.target.checked })
  }

  const handleCancelSubscription = async (reason: string) => {
    const plan = subscriptionHook.plans[0];
    try {
      await subscriptionHook.cancelSubscription(plan.planId, reason);
      setActiveSetting(SettingOption.MANAGE_SUBSCRIPTION);
    } catch (error: any) {
      msgrContext.setMessage({body: error.message})      
    }
  }

  const handleRenewSubscription = async () => {
    const plan = subscriptionHook.plans[0];
    await subscriptionHook.renewSubscription(plan.planId);
  }
  

  const onSuccessPhone = async (phoneNumber: number) => {
    if (!smsField) return;

    await updateSettings({ [smsField]: true })
    setShowPhone(false);
  }

  const onClosePhone = () => {
    setShowPhone(false);
  }

  if (apiState === APIState.LOADING) {
    return <>...</>
  }

  if (settingsError) {
    return <>Error</>
  }

  return (
    <>

      <ModalPhoneConfirmationWizard show={showPhone} onSuccess={onSuccessPhone} handleClose={onClosePhone} />

      <div className="settings settings-dialog">
        {
          activeSetting === SettingOption.NONE &&
          <div className="row justify-content-center">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="page-title">Account Settings</div>
              {closeHandler &&
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
              }
            </div>
          </div>
        }

        {activeSetting === SettingOption.NONE &&
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="my-3 d-flex flex-column justify-content-center align-items-center">

              <div className="w-100">
                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setActiveSetting(SettingOption.ACCOUNT_DETAILS)}>
                  <div className="settings-menu-item">Account Details</div>
                  <Icons.RightArrow />
                </div>

                {/* <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setActiveSetting(SettingOption.MANAGE_SUBSCRIPTION)}>
                  <div className="settings-menu-item">Manage Subscription</div>
                  <Icons.RightArrow />
                </div>

                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setActiveSetting(SettingOption.SIGNALS)}>
                  <div className="settings-menu-item">ProSignal Alerts</div>
                  <Icons.RightArrow />
                </div> */}

                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setActiveSetting(SettingOption.ALERTS)}>
                  <div>Prediction Alerts</div>
                  <div className="d-flex flex-row justify-content-between gap-3">
                    <div>{userSettings.alertsEnable ? 'On' : 'Off'}</div>
                    <Icons.RightArrow />
                  </div>
                </div>

                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setActiveSetting(SettingOption.PREDICTION_RESULTS)}>
                  <div className="settings-menu-item">My Prediction Results</div>
                  <div className="d-flex flex-row justify-content-between gap-3">
                    <div>{userSettings.resultsEnable ? 'On' : 'Off'}</div>
                    <Icons.RightArrow />
                  </div>
                </div>


                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setActiveSetting(SettingOption.REMINDERS)}>
                  <div className="settings-menu-item">Reminders</div>
                  <div className="d-flex flex-row justify-content-between gap-3">
                    <div>{userSettings.remindersEnable ? 'On' : 'Off'}</div>
                    <Icons.RightArrow />
                  </div>
                </div>


                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setActiveSetting(SettingOption.REFERRALS)}>
                  <div className="settings-menu-item">Referrals</div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-end h-100 mb-3">
              <div role="button" className="text-white settings-menu-item" onClick={() => { acctContext?.logout(); navigate(Url.DEFAULT_HOME) }}>Logout</div>
            </div>
          </div>
        }

        {activeSetting === SettingOption.ACCOUNT_DETAILS &&
          <div className="d-flex flex-column justify-content-between h-100">
            <div >
              <AccountDetails handleClose={_handleClose} setActiveSetting={setActiveSetting} />
            </div>

            <div>
              <div role="button" className="text-white settings-menu-item" onClick={() => { acctContext?.logout(); navigate(Url.DEFAULT_HOME) }}>Logout</div>
              <hr />
              <div role="button" className="text-red fw-bold settings-menu-item mb-3" onClick={() => setActiveSetting(SettingOption.DELETE)}>Delete my Data</div>
            </div>
          </div>
        }

        {activeSetting === SettingOption.ALERTS && <>
          <div className="">
            <div className="d-flex justify-content-start align-items-center gap-3">
              <div role="button" onClick={() => setActiveSetting(SettingOption.NONE)}><Icons.LeftArrow /></div>
              <div className="flex-grow-1 page-title">Alerts</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
            </div>

            <div className="my-5">
              <div className="my-3 help-items-item d-flex flex-row justify-content-between">
                <div>Enable Alerts</div>
                <div><Form.Check
                  type="switch"
                  id="alerts-enable"
                  name="alertsEnable"
                  checked={userSettings.alertsEnable}
                  onChange={handleCheckToggle}
                />
                </div>
              </div>

              {userSettings.alertsEnable && <>
                <div className="my-3 help-items-item d-flex flex-row justify-content-between">
                  <div className="ms-3">Receive Via EMail</div>
                  <div><Form.Check
                    type="switch"
                    id="alerts-send-email"
                    name="alertsSendEmail"
                    checked={userSettings.alertsSendEmail}
                    onChange={handleCheckToggle}
                  />
                  </div>
                </div>

                {userSettings.alertsSendEmail &&
                  <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setActiveSetting(SettingOption.DELIVERY_INTERVAL)}>
                    <div className="ms-3">When</div>
                    <div className="d-flex justify-content-end gap-2">
                      <div>{alertGroupIntervalNames.get(userSettings.alertsGroupInterval)}</div>
                      <div><Icons.RightArrow /></div>
                    </div>
                  </div>
                }


                <div className="my-3 help-items-item d-flex flex-row justify-content-between">
                  <div className="ms-3">
                    <ReceiveViaTextMessage />
                  </div>
                  <div><Form.Check
                    aria-disabled
                    disabled
                    type="switch"
                    id="alerts-send-sms"
                    name="alertsSendSms"
                    checked={userSettings.alertsSendSms}
                    onChange={handleCheckSMSToggle}
                  />
                  </div>
                </div>
              </>}
            </div>
          </div>
        </>
        }

        {activeSetting === SettingOption.PREDICTION_RESULTS && <>
          <div className="">
            <div className="d-flex justify-content-start align-items-center gap-3">
              <div role="button" onClick={() => setActiveSetting(SettingOption.NONE)}><Icons.LeftArrow /></div>
              <div className="flex-grow-1 page-title">My Prediction Results</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
            </div>

            <div className="my-5">
              <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button">
                <div className="fw-bold">Receive My Results</div>
                <div><Form.Check
                  type="switch"
                  id="results-enabled"
                  name="resultsEnable"
                  checked={userSettings.resultsEnable}
                  onChange={handleCheckToggle}
                />
                </div>
              </div>

              {userSettings.resultsEnable && <>
                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setActiveSetting(SettingOption.DELIVERY_SCHEDULE)}>
                  <div className="ms-3">When</div>
                  <div className="d-flex justify-content-end gap-2">
                    <div>{resultDeliveryTimeNames.get(userSettings.resultsDeliveryTime)}</div>
                    <div><Icons.RightArrow /></div>
                  </div>
                </div>

                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button">
                  <div className="ms-3">Receive Via EMail</div>
                  <div><Form.Check
                    type="switch"
                    id="results-send-email"
                    name="resultsSendEmail"
                    checked={userSettings.resultsSendEmail}
                    onChange={handleCheckToggle}
                  />
                  </div>
                </div>

                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button">
                  <div className="ms-3"><ReceiveViaTextMessage /></div>
                  <div><Form.Check
                    aria-disabled
                    disabled
                    type="switch"
                    id="results-send-sms"
                    name="resultsSendSms"
                    checked={userSettings.resultsSendSms}
                    onChange={handleCheckSMSToggle}
                  />
                  </div>
                </div>
              </>}

            </div>
          </div>
        </>
        }

        {activeSetting === SettingOption.REMINDERS && <>
          <div className="">
            <div className="d-flex justify-content-start align-items-center gap-3">
              <div role="button" onClick={() => setActiveSetting(SettingOption.NONE)}><Icons.LeftArrow /></div>
              <div className="flex-grow-1 page-title">Reminders</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
            </div>

            <div className="my-5">

              <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button">
                <div>Reminders</div>
                <div><Form.Check
                  type="switch"
                  id="reminders-enable"
                  name="remindersEnable"
                  checked={userSettings.remindersEnable}
                  onChange={handleCheckToggle}
                />
                </div>
              </div>

              {userSettings.remindersEnable && <>
                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button">
                  <div className="ms-3">Receive Via EMail</div>
                  <div><Form.Check
                    type="switch"
                    id="reminders-send-email"
                    name="remindersSendEmail"
                    checked={userSettings.remindersSendEmail}
                    onChange={handleCheckToggle}
                  />
                  </div>
                </div>

                <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button">
                  <div className="ms-3"><ReceiveViaTextMessage /></div>
                  <div><Form.Check
                    aria-disabled
                    disabled
                    type="switch"
                    id="reminders-send-sms"
                    name="remindersSendSms"
                    checked={userSettings.remindersSendSms}
                    onChange={handleCheckSMSToggle}
                  />
                  </div>
                </div>
              </>}

            </div>
          </div>
        </>
        }

        {activeSetting === SettingOption.DELIVERY_SCHEDULE &&
          <>
            <div className="">
              <div className="d-flex justify-content-start align-items-center gap-3">
                <div role="button" onClick={() => setActiveSetting(SettingOption.PREDICTION_RESULTS)}><Icons.LeftArrow /></div>
                <div className="flex-grow-1 page-title">My Prediction Results</div>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
              </div>

              <div className="my-3 help-items-item d-flex flex-row justify-content-between">
                <div className="d-flex flex-row justify-content-between gap-3 my-3">
                  <input type="radio"
                    name="resultsDeliveryTime"
                    value={ResultDeliveryTimeEnum.END_OF_DAY}
                    id="when-end"
                    checked={userSettings.resultsDeliveryTime === ResultDeliveryTimeEnum.END_OF_DAY}
                    onChange={handleDeliveryOption}
                  />

                  <label htmlFor="when-end">
                    <div>End of the day</div>
                    <div className="text-light-gray">Receive a summary of all your predictions made each day</div>
                  </label>
                </div>
              </div>

              <div className="my-3 help-items-item d-flex flex-row justify-content-between">

                <div className="d-flex flex-row justify-content-between gap-3">
                  <input type="radio"
                    name="resultsDeliveryTime"
                    value={ResultDeliveryTimeEnum.WHEN_READY}
                    id="when-ready"
                    checked={userSettings.resultsDeliveryTime === ResultDeliveryTimeEnum.WHEN_READY}
                    onChange={handleDeliveryOption}
                  />

                  <label htmlFor="when-end">
                    <div>When my predictions are scored</div>
                    <div className="text-light-gray">Receive an alert for each individual prediction upon completion</div>
                  </label>
                </div>
              </div>

            </div>
          </>
        }

        {activeSetting === SettingOption.DELETE &&
          <>
            {acctContext ? (
              <DeleteAccount acct={acctContext?.account} onDeleteCallback={onDeleteCallback} />
            ) : (
              <div>No account found.</div>
            )
            }
          </>
        }

        {activeSetting === SettingOption.REFERRALS &&
          <>
            <div className="">
              <div className="d-flex justify-content-start align-items-center gap-3">
                <div role="button" onClick={() => setActiveSetting(SettingOption.NONE)}><Icons.LeftArrow /></div>
                <div className="flex-grow-1 page-title">Referrals</div>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
              </div>

              <div className="my-5">
                <Referrals />
              </div>
            </div>
          </>
        }

        {userSettings.alertsEnable && activeSetting === SettingOption.DELIVERY_INTERVAL &&
          <div className="">
            <div className="d-flex justify-content-start align-items-center gap-3">
              <div role="button" onClick={() => setActiveSetting(SettingOption.ALERTS)}><Icons.LeftArrow /></div>
              <div className="flex-grow-1 page-title">When Alerts Are Delivered</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
            </div>

            <div className="my-3 help-items-item d-flex flex-row justify-content-between">
              <div className="d-flex flex-row justify-content-between gap-3 my-3">
                <input type="radio"
                  name="alertsGroupInterval"
                  value={AlertGroupInterval.ASAP}
                  id="when-end"
                  checked={userSettings.alertsGroupInterval === AlertGroupInterval.ASAP}
                  onChange={handleAlertGroupOption}
                />

                <label htmlFor="when-end">
                  <div>Real-Time</div>
                  <div className="text-light-gray">Receive a notification as soon as the user you follow makes a prediction.</div>
                </label>
              </div>
            </div>


            <div className="my-3 help-items-item d-flex flex-row justify-content-between">
              <div className="d-flex flex-row justify-content-between gap-3 my-3">
                <input type="radio"
                  name="alertsGroupInterval"
                  value={AlertGroupInterval.EVERY_3}
                  id="when-end"
                  checked={userSettings.alertsGroupInterval === AlertGroupInterval.EVERY_3}
                  onChange={handleAlertGroupOption}
                />

                <label htmlFor="when-end">
                  <div>Every 3 Minutes</div>
                  <div className="text-light-gray">Your emails are grouped in 3-minute increments</div>
                </label>
              </div>
            </div>


            <div className="my-3 help-items-item d-flex flex-row justify-content-between">
              <div className="d-flex flex-row justify-content-between gap-3 my-3">
                <input type="radio"
                  name="alertsGroupInterval"
                  value={AlertGroupInterval.EVERY_15}
                  id="when-end"
                  checked={userSettings.alertsGroupInterval === AlertGroupInterval.EVERY_15}
                  onChange={handleAlertGroupOption}
                />

                <label htmlFor="when-end">
                  <div>Every 15 Minutes</div>
                  <div className="text-light-gray">Your emails are grouped in 15-minute increments</div>
                </label>
              </div>
            </div>


            <div className="my-3 help-items-item d-flex flex-row justify-content-between">
              <div className="d-flex flex-row justify-content-between gap-3 my-3">
                <input type="radio"
                  name="alertsGroupInterval"
                  value={AlertGroupInterval.EVERY_30}
                  id="when-end"
                  checked={userSettings.alertsGroupInterval === AlertGroupInterval.EVERY_30}
                  onChange={handleAlertGroupOption}
                />

                <label htmlFor="when-end">
                  <div>Every 30 Minutes</div>
                  <div className="text-light-gray">Your emails are grouped in 30-minute increments</div>
                </label>
              </div>
            </div>

          </div>
        }

        {activeSetting === SettingOption.MANAGE_SUBSCRIPTION &&
          <>
            <div className="d-flex justify-content-start align-items-center gap-3">
              <div role="button" onClick={() => setActiveSetting(SettingOption.NONE)}><Icons.LeftArrow /></div>
              <div className="flex-grow-1 page-title">Manage Subscription</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
            </div>

            {signup.render()}
            <ManageSubscription
              plans={subscriptionHook.plans}
              onCancel={()=>setActiveSetting(SettingOption.CANCEL_SUBSCRIPTION)}
              onRenew={handleRenewSubscription}
              onStart={()=>{
                signup.setShow(true);
              }}
            />
          </>
        }

        {activeSetting === SettingOption.CANCEL_SUBSCRIPTION &&
        <>
            <div className="d-flex justify-content-start align-items-center gap-3">
              <div role="button" onClick={() => setActiveSetting(SettingOption.NONE)}><Icons.LeftArrow /></div>
              <div className="flex-grow-1 page-title">Cancel Subscription</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
            </div>
            <CancelSubscription 
              onClick={handleCancelSubscription} />
        </>
        }


        {/* {activeSetting === SettingOption.RENEW_SUBSCRIPTION &&
          <>
            <div className="d-flex justify-content-start align-items-center gap-3">
              <div role="button" onClick={() => setActiveSetting(SettingOption.NONE)}><Icons.LeftArrow /></div>
              <div className="flex-grow-1 page-title">Manage Subscription</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
            </div>

            <RenewSubscription
              plans={subscriptionHook.plans}
              onClick={handleRenewSubscription}
            />
          </>
        } */}


        {activeSetting === SettingOption.SIGNALS &&
          <>
            <div className="d-flex justify-content-start align-items-center gap-3">
              <div role="button" onClick={() => setActiveSetting(SettingOption.NONE)}><Icons.LeftArrow /></div>
              <div className="flex-grow-1 page-title">Signals</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
            </div>

            {signalSettingsHook.api.apiState.isLoaded ?
              <SignalsSettings onClick={(signalSettings: ISignalSettings) => {
                setCurrentSignalSetting(signalSettings);
                setActiveSetting(SettingOption.SIGNAL_SETTING);
              }
              }
                signalSettings={signalSettingsHook.data}
                setAll={signalSettingsHook.setAll}
              /> : <Spinner minHeight={15} />
            }
          </>
        }

        {activeSetting === SettingOption.SIGNAL_SETTING &&
          <>
            <div className="d-flex justify-content-start align-items-center gap-3">
              <div role="button" onClick={() => setActiveSetting(SettingOption.SIGNALS)}><Icons.LeftArrow /></div>
              <div className="flex-grow-1 page-title">{currentSignalSetting?.stockSymbol} Signals</div>
              <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
            </div>

            {currentSignalSetting && signalSettingsHook.api.apiState.isLoaded &&
              <SignalSetting
                  symbolName={currentSignalSetting.stockSymbol}
                  handleCheckToggle={handleSignalCheckToggle}
                  isEnabled={currentSignalSetting.signalEnable}
                  signalSettings={currentSignalSetting} />
            }
          </>
        }

      </div>

    </>
  );
};

