import { IUserSignalPlan, subscriptionService } from "services/SubscriptionService";
import { IApiResponse, useApi } from "./useApi";
import { useState } from "react";
import { IPaymentProfile } from "services/PaymentService";


export interface ISignalSettingsApi {
  api: IApiResponse<IUserSignalPlan[], null>,
  plans: IUserSignalPlan[],
  subscribeAndPay: (profile: IPaymentProfile, planId: number) => Promise<void>,
  cancelSubscription: (planId: number, reason: string) => Promise<void>,
  renewSubscription: (planId: number) => Promise<void>
}

export function useSubscription (): ISignalSettingsApi {
  const [data, setData] = useState<IUserSignalPlan[]>([]);
  
  const load = async (filter: null, abortSignal?: AbortSignal) => {
    const data = await subscriptionService.getPlans(abortSignal);
    setData(data);
    return data;
  }

  const api = useApi<IUserSignalPlan[], null>(load, null);

  const subscribeAndPay = async (profile: IPaymentProfile, planId: number) => {
    await subscriptionService.subscribeAndPay(profile, planId);
    api.reload();
  }

  const cancelSubscription = async (planId: number, reason: string) => {
    await subscriptionService.cancelSubscription(planId, reason);
    api.reload();
  }

  const renewSubscription = async (planId: number) => {
    await subscriptionService.renewSubscription(planId);
    api.reload();
  }

  return {
    api,
    subscribeAndPay,
    cancelSubscription,
    renewSubscription,
    plans: data,
  } 

}

