import React, { useState } from "react";
import { Helper } from "_utils";
import { TradegramHelper } from "_utils/TradegramHelper";
import { BrokerTransactionTypeHelper, BrokerSecurityTypeEnum } from "predictagram-lib";
import { ITradegramTransaction, ITradegramSecurity } from "services/TradegramApiService";
import { ChatLeftText } from "react-bootstrap-icons";
import { PurpleCheckFill } from "components/common/Icons";

interface IProps {
  tradegramTransaction: ITradegramTransaction,
  security: ITradegramSecurity,
  index: number,
  showProfit?: boolean,
}

export const ExpandableText: React.FunctionComponent<{ children: any }> = ({ children }) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const text = children as string;
  if (text.length < 100 || showAll) {
    return <>{children}</>
  }

  return (
    <span role="button" onClick={() => { setShowAll(!showAll) }}>{children.substring(0, 100)} ...</span>
  );
}

export const TradeLineItem: React.FunctionComponent<IProps> = ({ tradegramTransaction: t, security, index, showProfit = true }) => {
  if (!t) {
    return <>Error</>
  }

  const transactionProfitPerShare = TradegramHelper.getTransactionProfitPerShare(security, t);

  const PriceVerified = () => {
    return <div className="d-flex justify-content-start align-items-start gap-1">
      <div className="text-18 fw-bold text-lavender">{TradegramHelper.formatMoney(t.price / (TradegramHelper.isOptionSecurity(security.typeId) ? 100 : 1))}</div>
      <div className="">{TradegramHelper.isVerifiedTransaction(t) && <PurpleCheckFill className="d-inline" />}</div>
    </div>
  }

  const ActivityText = () => {
    return <span>
      <span className="fw-bold text-18 text-white">{TradegramHelper.getTransactionActionDescription(t, index === security.transactions - 1, security)}</span>
      {" "} <span className="text-lavender text-18 fw-bold">{Math.abs(t.quantity)}</span> {Helper.pluralize(security.typeId === BrokerSecurityTypeEnum.EQUITY ? "share" : "contract", Math.abs(t.quantity))}
      {" "} at &nbsp;
    </span>
  }

  return (
    <div className="text-lighter-gray text-12">

      <div className="d-flex flex-wrap">
        <ActivityText />
        <PriceVerified />
      </div>

      {!!showProfit && BrokerTransactionTypeHelper.isClose(t.typeId) &&
        <div className="d-flex gap-1 align-items-center">
          <div className={`${t.profit < 0 ? "text-bright-red" : "text-bright-green"}`}>{`${t.profit < 0 ? "" : "+"}`}{(t.profitPct).toFixed(2)}%</div>
          <div>{TradegramHelper.formatMoney(transactionProfitPerShare)} per share</div>
        </div>
      }

      {t.description &&
        <div className="text-14 d-flex gap-2 align-items-center my-1">
          <div className="flex-shrink-0 align-self-start"><ChatLeftText /></div>
          <div className="tradegram-description">{t.description}</div>
        </div>
      }

      <div className="my-1">
        <span>{Helper.quoteDateFormat(new Date(t.createdAt * 1000))}</span>
        {security.typeId === BrokerSecurityTypeEnum.OPTION && t.stockPrice && <span>, {security.stockSymbol} was {TradegramHelper.formatMoney(t.stockPrice)}</span>}
      </div>

    </div>
  );
}