import { usePublicProfile } from '_hooks/usePublicProfile';
import { AccountContext } from 'components/common'
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar'
import { TeamInfo } from 'components/public/Stock/TeamInfo';
import React, { useContext } from 'react'
import { userPredictionApiService } from 'services/UserPredictionApiService';
import * as Icons from 'components/common/Icons';

export const PredictorCard: React.FunctionComponent = () => {

  const acctContext = useContext(AccountContext);
  const acct = acctContext?.account;

  const currentUser = usePublicProfile(acct?.id as number, userPredictionApiService, true);

  if (!currentUser.loaded) {
    return <div className="predictor-card-body shimmer"></div>
  }

  return (
    <div className="settings-predictor">
      <div className="d-flex gap-3">
        <div className="image">
          <Avatar profile={currentUser.item} size={AvatarSizeEnum.XXL} />
        </div>
        <div className="d-flex flex-column">
          <div className="text-18 fw-bold d-flex justify-content-start gap-2">{currentUser.item.nickname} <Icons.PurpleCheck /></div>
          <div className="text-16">@{currentUser.item.username}</div>
          {currentUser.item.team &&
            <TeamInfo profileTeam={currentUser.item.team} />
          }
        </div>
      </div>
    </div>
  )
}

