import { AccountContext } from 'components/common'
import React, { useContext } from 'react'
import { RedirectComponent } from 'components/common/RedirectComponent';
import { Url } from '_constants';

export const HomePage: React.FunctionComponent =  () => {

  const acctContext = useContext(AccountContext);

  if (acctContext?.isLoggedIn()) {
    return  <RedirectComponent to={Url.USER_DASHBOARD_PREDICTIONS} />
  } 

  return <RedirectComponent to={Url.PUBLIC_WELCOME} />

}
