export enum UserNotificationTypeEnum {
    NEW_FOLLOWER                        = 1, // recent active #1
    MENTIONED_PREDICTION                = 2,
    REMINDER_TO_MAKE_PREDICTION         = 3,
    PREDICTION_RESULT                   = 4,
    FOLLOWEE_NEW_PREDICTION             = 5, // recent activity #5, followee created a new prediction
    ACHIEVEMENT_TICKET                  = 6, // recent activity #2
    // ACHIEVEMENT_STREAK                  = 7, // recent activity #3
    PREDICTION_COMPLETED                = 8, // recent activity #4, "my" processed prediction
    FOLLOWEE_NEW_SECURITY_TRANSACTION   = 9, // recent activity #6, followee added a sec. transaction
}
