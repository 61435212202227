export enum TriggerExitType {
    PROFIT          = 1,
    LOSS            = 2,
    SIGNAL          = 3,
    STEP_SIZE       = 4,
    AHEAD_CLOSE     = 5,
}

export class TriggerExitTypeEnumHelper {
    static names: Map<TriggerExitType, string> = new Map<TriggerExitType, string>([
        [TriggerExitType.PROFIT, 'Profit'],
        [TriggerExitType.LOSS, 'Loss'],
        [TriggerExitType.SIGNAL, 'Signal'],
        [TriggerExitType.STEP_SIZE, 'Step Size'],
        [TriggerExitType.AHEAD_CLOSE, 'Ahead Close'],
    ]);
}