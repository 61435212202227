import { PredictionContext } from "components/common/PredictionContext";
import { sortTypeEnum } from "components/public/ListingsPage/components/Prediction/SortTypeSelect";
import { IPrediction } from "interfaces/IPrediction";
import { useCallback, useContext, useEffect, useState } from "react";
import { ISearchOptions, UserPredictionApiService, UserPredictionServiceError } from "services/UserPredictionApiService";
import { APIState } from "_constants/APIState";
import { UserTypeFilterEnum } from "components/user/dashboard/filters/UserTypeDropDownFilter"; 

export interface IFilteredPredictions {
  items: IPrediction[],
  apiState: APIState,
  error: UserPredictionServiceError | undefined,
  setOptions: React.Dispatch<React.SetStateAction<ISearchOptions>>,
  options: ISearchOptions,
  setSort: (sortType: sortTypeEnum) => void,
  refresh: ()=>void,
}

export function useFilteredPredictions(service: UserPredictionApiService, filter: UserTypeFilterEnum = UserTypeFilterEnum.TOP, search: ISearchOptions): IFilteredPredictions {

  const [items, setItems] = useState<IPrediction[]>([]);
  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [error, setError] = useState<UserPredictionServiceError | undefined>(undefined);

  const [options, setOptions] = useState<ISearchOptions>(search);

  const predictionContext = useContext(PredictionContext);

  const setSort = useCallback((sortType: sortTypeEnum) => {
    if (!options) {
      setOptions({
        orderBy: sortType
      })
      return;
    }
    const {orderBy, ...rest} = options;
    setOptions({
      orderBy: sortType,
      ...rest
    })
  }, [options]);


  const load = useCallback(async () => {
    try {
      setApiState(APIState.LOADING);
      if (filter === UserTypeFilterEnum.TOP) {
        const items = await service.getPredictionsByTopPredictors (options as ISearchOptions);
        setItems(items);
      }

      if (filter === UserTypeFilterEnum.FOLLOWING) {
        const items = await service.getPredictionsByFollowed (options as ISearchOptions);
        setItems(items);
      }

      if (filter === UserTypeFilterEnum.EVERYONE) {
        const items = await service.getAllPublic (options as ISearchOptions);
        setItems(items);
      }

      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as UserPredictionServiceError);
    }
  }, [service, options, filter])

  const refresh = () => {
    load();
  }

  useEffect(() => {
    load();
  }, [load, options, predictionContext])  
  
  return { items, apiState, error, options, setOptions, setSort, refresh };

}