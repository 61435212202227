import React from 'react'
import { IPublicProfile } from 'interfaces/IPublicProfile'
import { Accuracy } from './Accuracy';

export const ProfileAccuracy: React.FunctionComponent<{ profile: IPublicProfile }> = ({ profile }) => {

  const profileStyle: React.CSSProperties = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '80px',
    whiteSpace: 'nowrap',
  }

  const username = profile.username.includes('-(You)') ? 'You' : `@${profile.username}`

  return (
    <div className="text-12 px-2 d-flex justify-content-center flex-column align-items-center gap-1">
      <div className="bg-charcoal no-hover px-2 rounded mt-1 text-white text-center">
        <div>
          <div style={profileStyle}>{username}</div>
          <Accuracy profile={profile} />
        </div>
      </div>
    </div>

  );
}