import { IAdminAutoTradeSetupApi } from "_hooks/useAdminAutoTradeSetup";
import { Helper } from "_utils";
import { ITradeLivePrice, TradeModel } from "models/trade.model";
import { IAutoTrade, OptionType } from "predictagram-lib";
import React from "react";

export const formatUSCurrency = (n: number | undefined) => {
  if (!n) {
    return ""
  }
  return Helper.formatUSCurrency(n);
}

export const colorize = (v: string | number, suffix?: string | JSX.Element): JSX.Element => {
  const value = typeof v === "string" ? parseFloat(v.replace(/[^0-9.-]/g, '')) : v;
  const className = value < 0 ? "text-red" : "text-green";
  return <span className={`fw-bold ${className}`}>{v}{suffix}</span>
}

export const calcUnrealizedProfitLossOptionFormatted = (trade: IAutoTrade, currentPrice: number, formatted: boolean) => {
    const result = TradeModel.calcUnrealizedProfitLossIbkr(trade, currentPrice);
    return result ? 
      (formatted ? colorize(Helper.formatUSCurrency(result)) : result) : undefined
}

export const ProfitLoss: React.FunctionComponent<{trades: IAutoTrade[]}> = ({trades}) => {
  const realized = TradeModel.getTradesProfitLossIbkr(trades).realized;
  return <div className="d-flex flex-column gap-1 text-end">
    <div className="fw-bold">Realized P/L</div>
    {colorize(formatUSCurrency(realized.profitLoss))}
    {realized.invested && colorize((realized.profitLoss/realized.invested*100).toFixed(2), '%')}
  </div>
}

export const UnrealizedProfitLossActive: React.FunctionComponent<{trades: IAutoTrade[], livePrices: ITradeLivePrice[]}> = ({trades, livePrices}) => {
  return <div className="d-flex flex-column gap-1 text-end">
    <div className="fw-bold">Active Unrealized P/L</div>
    {colorize(formatUSCurrency(TradeModel.getUrealizedProfitLossOptionTrades(trades, livePrices)))}
  </div>
}

export const ClosedShares: React.FunctionComponent<{trades: IAutoTrade[]}> = ({trades}) => {
  return <div className="d-flex flex-column gap-1 text-end">
    <div className="fw-bold">Closed Shares</div>
    {TradeModel.getClosedShares(trades).toLocaleString('en-us')}
  </div>
}

export const ActiveShares: React.FunctionComponent<{trades: IAutoTrade[]}> = ({trades}) => {
  return <div className="d-flex flex-column gap-1 text-end">
    <div className="fw-bold">Active Shares</div>
    {TradeModel.getActiveShares(trades).toLocaleString('en-us')}
  </div>
}


export const ActiveInvested: React.FunctionComponent<{trades: IAutoTrade[]}> = ({trades}) => {
  return <div className="d-flex flex-column gap-1 text-end">
    <div className="fw-bold">Total Invested</div>
    {formatUSCurrency(TradeModel.getActiveTotalCost(trades))}
  </div>
}

export const MaxTotalInvested: React.FunctionComponent<{trades: IAutoTrade[]}> = ({trades}) => {
  return <div className="d-flex flex-column gap-1 text-end">
    <div className="fw-bold">Max Total Invested</div>
    {formatUSCurrency(TradeModel.getMaxTotalInvested(trades))}
  </div>
}

export const TradesSummary: React.FunctionComponent<{ trades: IAutoTrade[], livePrices: ITradeLivePrice[] }> = ({ trades, livePrices }) => {
  return <div className="d-flex flex-column gap-1">
    <div className="d-flex flex-row gap-3 text-12 flex-wrap flex-md-nowrap">
      <ProfitLoss trades={trades} />
      <ClosedShares trades={trades} />
      <UnrealizedProfitLossActive trades={trades} livePrices={livePrices} />
      <ActiveShares trades={trades} />
      <ActiveInvested trades={trades} />
      <MaxTotalInvested trades={trades} />
    </div>
  </div>
}

export const ColoredOptionType: React.FunctionComponent<{optionType: OptionType}> = ({optionType}) => {
  return <span className={`fw-bold text-${optionType===OptionType.CALL ? 'green' : 'red'}`}>{optionType}</span>
}

export const TradeSetupNameWithOptionType: React.FunctionComponent<{trade: IAutoTrade, api: IAdminAutoTradeSetupApi}> = ({trade, api}) => {
  const fullName = TradeModel.getTradeSetupFullTitle(trade.setupName, trade.setupSignalAlertId);
  if (!api.api.apiState.isLoaded) {
    return <>{fullName}</>
  }
  const tradeSetup = api.getById(trade.setupId);
  if (!tradeSetup) {
    return <>{fullName}</>
  }
  return <span>{fullName} <ColoredOptionType optionType={tradeSetup.tradeTemplate.optionType} /></span>
}
