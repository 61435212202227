import { IApiResponse, useApi } from "./useApi";
import { useState } from "react";
import { ISignalSettings, userSignalApiService } from "services/UserSignalApiService";

export interface ISignalSettingsApi {
  api: IApiResponse<ISignalSettings[], null>,
  getById: (id: number) => ISignalSettings | undefined,
  saveSettings: (id: number, signalSettings: Partial<ISignalSettings>) => Promise<boolean>,
  data: ISignalSettings[],
  setAll: (enabled: boolean) => void,
}

export function useSignalSettings () {
  const [data, setData] = useState<ISignalSettings[]>([]);
  
  const sortByStockSymbol = (a: ISignalSettings, b: ISignalSettings) => a.stockSymbol.localeCompare(b.stockSymbol);

  const load = async (filter: null, abortSignal?: AbortSignal) => {
    const data = await userSignalApiService.getSignalSettings(abortSignal)
    setData(data.sort(sortByStockSymbol));
    return data;
  }

  const getById = (id: number) => {
    return data.find(v=>v.id===id);
  }

  const saveSettings = async (symbolId: number, signalSettings: Partial<ISignalSettings>) => {
    const result = await userSignalApiService.setSignalSettings(symbolId, signalSettings);
    const row = data.find(i=>i.symbolId === symbolId);
    if (row) {
      const updated = {
        ...row,
        ...signalSettings
      }
      const newData = [
        ...data.filter(i=>i.symbolId !== symbolId),
        updated
      ]
      setData(newData.sort(sortByStockSymbol));
    }
    return result;
  }

  const api = useApi<ISignalSettings[], null>(load, null);

  const setAll = async (enabled: boolean) => {
    const updatePromises = data.map( (item) => userSignalApiService.setSignalSettings(item.symbolId, {signalEnable: enabled}));
    await Promise.all(updatePromises);
    api.reload();
  }

  return {
    api,
    data,
    getById,
    saveSettings,
    setAll,
  } as ISignalSettingsApi

}

