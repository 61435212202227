import React, { useContext, useEffect, useState } from 'react';
import { FolloweeContext } from 'components/common/FolloweeContext';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { FollowButton } from 'components/public/ListingsPage/components/Prediction/FollowButton';
import { Url } from '_constants';
import { useNavigate } from 'react-router-dom';
import { IFollowee } from 'interfaces/IFollow';
import { SymbolLinks } from 'components/public/Stock/SymbolLinks';
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar';
import { Helmet } from 'react-helmet';

const FollowCard: React.FunctionComponent<{ follow: IFollowee | number }> = ({ follow }) => {
  const [user, setUser] = useState<IPublicProfile | undefined>(undefined);
  const navigate = useNavigate();
  const [userId, setUserId] = useState<number>(0);

  useEffect(() => {
    const _load = async () => {
      let userId = 0;

      if (typeof follow === 'number') {
        userId = follow;
      } else {
        userId = follow.followeeId
      }
      const user = await userPredictionApiService.getPublicProfile(userId);
      setUser(user);
      setUserId(userId);
    }
    _load();
  }, [follow])


  const onSymbolSelect = (s: string | undefined) => {
    if (s) {
      navigate(`/stock/predictions/${s}`);
    }
  }

  return (
    <>
      {user && user.predictions &&
        <div className="followee-card d-flex flex-row align-items-center">
          <div className="me-3 flex-grow-0">
            <div className="followee-card-nickname my-2"><a className="predictor-link" href={`${Url.PUBLIC_STOCK_PREDICTOR}/${userId}`}>{user.nickname}</a></div>
            <div className="followee-card-image"><a className="predictor-link" href={`${Url.PUBLIC_STOCK_PREDICTOR}/${userId}`}><Avatar profile={user} size={AvatarSizeEnum.MEDIUM} /></a></div>
            <div className="text-center my-3"><FollowButton userId={userId} /></div>
          </div>
          <div>
            <div className="followee-card-details">
              <div className="d-flex justify-content-start flex-row flex-wrap">
                <div className="m-3">Predictions: {user.predictions.completed}</div>
                <div className="m-3">Accuracy: {((user.predictions.averageScore || 0) * 100).toFixed(0)}%</div>
                <div className="m-3">Symbols Predicted: <SymbolLinks onClickCallback={onSymbolSelect}
                  symbols={user.predictions.symbols ?
                    user.predictions.symbols.filter((item, index) => user.predictions.symbols !== null && user.predictions.symbols.indexOf(item) === index)
                    : []} /></div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

const DashboardFollows: React.FunctionComponent = () => {

  const followeeContext = useContext(FolloweeContext);

  useEffect(() => {
    const _load = async () => {
      await followeeContext?.getFollowees();
    }
    _load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="page-title">Following</div>

      {followeeContext && followeeContext?.followees.length > 0 &&
        <>
          <Helmet>
            <title>Predictagram: Followed Users</title>
          </Helmet>

          {followeeContext?.followees.map((f: IFollowee, i: number) => <div className="my-3" key={`followed-key-${i}`}>
            <FollowCard follow={f} />
          </div>)
          }
        </>
      }

      {followeeContext && followeeContext?.followees.length === 0 &&
        <>
          <Helmet>
            <title>Predictagram: Followed Users</title>
          </Helmet>
          <div className="mt-5">
            You are not following anyone.
          </div>
        </>
      }


    </div>
  );
}

export { DashboardFollows, FollowCard }