import React from 'react';
import { ContestRulesLink } from './ContestRulesLink';
import { ILeagueSeason } from 'interfaces/league';
import { NewYorkTz as tz } from 'predictagram-lib';


export const ContestOverview: React.FunctionComponent<{ season: ILeagueSeason }> = ({ season }) => {
  const seasonRange: string = `${tz.format(new Date(season.startTime * 1000)).monthDayWithSuffix()} to ${tz.format(new Date(season.endTime * 1000)).monthDayWithSuffix()}`;

  const minimumPredictions = new Date().getTime() >= 1717214400000 ? 100 : 50;

  return (
    <div className="team-contest-overview">
      <div className="d-flex justify-content-start align-items-center gap-2">
        <div className="team-contest-overview-image">
          <img src="/images/money-bag.png" alt="money-bag" />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="team-contest-overview-text">Make your predictions daily<br /> for a chance to win</div>
          <div className="team-contest-overview-amount">$500</div>
        </div>
      </div>

      <div className="team-contest-overview-text my-3">
        {season.name} from {seasonRange}. Winning team must have at least 1,200 predictions during the season and at least 5 players with {minimumPredictions} or more predictions.
      </div>

      <div className="team-contest-overview-text my-3">
        The 3 qualifying players with the highest scores on the winning team will each win $500. Players must make at least {minimumPredictions} predictions per season to win a prize.
      </div>

      <div>
        <ContestRulesLink />
      </div>
    </div>
  );
}