import React from "react";
import { BaseForm, FieldWithError } from "components/common";
import { validationMap, Validator } from "schemas";
import { adminLeagueSeasonApiService } from "services/AdminApiService";
import { IDataField } from "interfaces";
import DatePicker from "react-datepicker";
import { ILeagueSeason } from "interfaces/league";
import { LeagueDropDown } from "../LeagueDropDown";
import { FieldArray } from "formik";
import { TeamDropDown } from "../TeamDropDown";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: ILeagueSeason) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

export const AdminLeagueSeasonForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {
  const beforeSubmit = async (data: any, actions: any) => {
    data.teams = data.teams.filter((e: any) => e !== null).map((e: any) => e.toString());
  };

  return (
    <BaseForm
      apiService={adminLeagueSeasonApiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_CONTEST_FORM)}
      editableFields={editableFields}
      beforeSubmit={beforeSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <>

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="name"
              placeholder="Season Name"
              label="Season Name" />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="description"
              placeholder="Description"
              label="Description"
            />

            <LeagueDropDown errors={errors} name="leagueId" touched={touched} />

            <div>
              <span>Start Date</span>
              <DatePicker
                showTimeSelect
                dateFormat="MM/dd/yyyy hh:mm aa"
                onChange={(date: Date) => {
                  setFieldValue("startTime", date.getTime() / 1000);
                }}
                selected={new Date(values.startTime * 1000)}
              />
            </div>

            <div>
              <span>End Date</span>
              <DatePicker
                showTimeSelect
                dateFormat="MM/dd/yyyy hh:mm aa"
                onChange={(date: Date) => {
                  setFieldValue("endTime", date.getTime() / 1000);
                }}
                selected={new Date(values.endTime * 1000)}
              />
            </div>

            <div>
            <FieldArray name="teams">
              {({ insert, remove, push }: { insert: any, remove: any, push: any }) => (
                <div>
                  {values.teams.length > 0 &&
                    values.teams.map((team: any, index: number) => (
                      <div className="d-flex justify-content-start align-items-center">
                        <TeamDropDown name={`teams.${index}`} errors={errors} touched={touched}/>
                        <button type="button" className="btn btn-secondary align-self-end mx-3" onClick={() => remove(index)}>X</button>
                      </div>
                    ))
                  }
                  <div className="my-3">
                  <button type="button" className="btn btn-primary" onClick={() => push(null)}>Add Team</button>
                  </div>
                </div>
              )}
            </FieldArray>
            </div>

          </>
        );
      }}
    </BaseForm>
  );
};


