/**
 * NewYorkTZ Helper Class
 * collection of static functions to handle time in NY Time Zone
 */
export class NewYorkTz {

  public static readonly EAST_TIMEZONE = 'America/New_York';
  public static readonly UTC_TIMEZONE = 'UTC';
  public static readonly LOCALE = 'en-US';

  /**
   * check if given dates are the same
   * @param date1
   * @param date2
   * @returns
   */
  static isSameDay(date1: Date, date2: Date) {
    return this.getDateMidnight(date1).getTime() === this.getDateMidnight(date2).getTime();
  }

  /**
   * get the offset, in hours, of New York time zone
   * this takes into account DST and non-DST hours
   * @param date
   * @returns
   */
  static getTimezoneOffset(date: Date): number {
    const nyDate = new Date(date.toLocaleString('en-US', { timeZone: this.EAST_TIMEZONE }));
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: this.UTC_TIMEZONE }));
    return (nyDate.getTime() - utcDate.getTime()) / 60000 / 60; // return offset in hours
  }

  /**
   * returns the new york date midnight from utc
   * @param utcDate
   * @returns
   */
  static getDateMidnight(utcDate: Date): Date {
    const newYorkTimezoneOffset = this.getTimezoneOffset(utcDate);
    const nyDate = NewYorkTz.mdyhms(utcDate);
    const utcDateNew = new Date(Date.UTC(
      parseInt(nyDate.year, 10),
      parseInt(nyDate.month, 10) - 1,
      parseInt(nyDate.day, 10),
      0,
      0,
    ));
    const offsetInMs = newYorkTimezoneOffset * 60 * 60 * 1000;
    utcDateNew.setTime(utcDateNew.getTime() + Math.abs(offsetInMs));
    return utcDateNew;
  }

  /**
   * return various date parts, in NYC Time Zone, based on passed UTC Date
   * @param utcDate
   * @returns Object
   */
  static mdyhms(utcDate: Date) {
    return {
      year: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE,  year: "numeric" }),
      month: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE,  month: "numeric" }),
      day: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE,  day: "numeric" }),
      hour12: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE,  hour: "numeric", hour12: true }),
      hour23: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE, hourCycle: 'h23', hour: "numeric" }),
      hour24: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE, hour: 'numeric', hour12: false }),
      minute: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE,  minute: "numeric" }),
      seconds: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE,  second: "numeric" }),
      weekday: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE,  weekday: "long" }),
      monthName: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE,  month: "short" }),
      monthNameFull: utcDate.toLocaleString(this.LOCALE, { timeZone: this.EAST_TIMEZONE,  month: "long" }),
    }
  }

  /**
   * functions for various formatting
   * @param utcDate
   * @returns format Object
   */
  static format(utcDate: Date) {
    const mdyhms = this.mdyhms(utcDate);
    const ampm = parseInt(mdyhms.hour23, 10) >= 12 ? 'PM' : 'AM';
    const hr12 = (parseInt(mdyhms.hour23, 10) > 12 ? parseInt(mdyhms.hour23, 10) - 12 : parseInt(mdyhms.hour23, 10)).toString().padStart(2, '0');
    const hr12nopad = (parseInt(mdyhms.hour23, 10) > 12 ? parseInt(mdyhms.hour23, 10) - 12 : parseInt(mdyhms.hour23, 10));
    const hr12number = (parseInt(mdyhms.hour23, 10) > 12 ? parseInt(mdyhms.hour23, 10) - 12 : parseInt(mdyhms.hour23, 10));

    /**
     * format a date to NY Time - HH AM/PM. example: 10 AM, 2 PM
     * @param dt
     * @returns string
     */
    const hour12ampm = (): string => {
      return `${hr12number === 0 ? 12 : hr12number} ${ampm}`;
    }

    /**
     * format a date to NY Time. HH MM AM/PM. example: 10:30 AM, 2:30 PM
     * @param dt
     * @returns string
     */
    const hour24MinuteAmpm = (isPadded: boolean = false): string => {
      if (isPadded) return `${hr12}:${mdyhms.minute.toString().padStart(2, '0')} ${ampm}`;
      return `${hr12nopad}:${mdyhms.minute.toString().padStart(2, '0')} ${ampm}`;
    }

    /**
     * format the time in 24-hour number. example: 1:00 PM is 1300; 2:00 is 1400.
     * @param dt
     */
    const hour24MinuteNumber = (): number => {
        const m = mdyhms.minute.toString().length===1?'0'+mdyhms.minute:mdyhms.minute;
      return parseInt(`${mdyhms.hour23}${m}`, 10)
    }

    /**
     * format the given date to NY TIme zone YYYY-MM-DD format
     * example: 2022-05-15
     * @param dt
     */
    const yyyymmdd = (): string => {
      return `${mdyhms.year}-${mdyhms.month.toString().padStart(2, '0')}-${mdyhms.day.toString().padStart(2, '0')}`;
    }

    /**
     * returns a formatted NY timezone date
     * examples: Feb-02, 2023
     * @returns string
     */
    const monthNameDate = (): string => {
      return `${mdyhms.monthName}-${mdyhms.day}, ${mdyhms.year}`;
    }

    /**
     * returns a formatted date NY timezone date with time
     * examples:
     * Feb-21, 2023 10:25 PM
     * Feb-22, 2023 5:33 AM
     * @returns string
     */
    const monthNameDateTime = (): string => {
      return `${mdyhms.monthName}-${mdyhms.day}, ${mdyhms.year} ${hr12nopad}:${mdyhms.minute.toString().padStart(2, '0')} ${ampm}`;
    }

      const monthNameDateTimeSec = (): string => {
          return `${mdyhms.monthName}-${mdyhms.day}, ${mdyhms.year} ${hr12nopad}:${mdyhms.minute.toString().padStart(2, '0')}:${mdyhms.seconds.toString().padStart(2, '0')} ${ampm}`;
      }

    /**
     * returns a formatted month and day with suffix ("st", "th", etc)
     * for example:
     * February 21st
     * March 15th
     * @returns string
     */
    const monthDayWithSuffix = (): string => {
      const day = parseInt(mdyhms.day, 10);
      return `${mdyhms.monthNameFull} ${day}${this.numberSuffix(day)}`
    }

    /**
     * returns a formatted month and day with suffix ("st", "th", etc) and year
     * for example:
     * February 21st, 2023
     * March 15th, 2023
     * @returns string
     */
    const monthDayYearWithSuffix = (): string => {
      const day = parseInt(mdyhms.day, 10);
      return `${mdyhms.monthNameFull} ${day}${this.numberSuffix(day)}, ${mdyhms.year}`
    }

    const monthDayHour = (): string => {
      const day = parseInt(mdyhms.day, 10);
      return `${mdyhms.month}/${day} ${hr12nopad}${ampm}`

    }

    return {
      hour12ampm,
      hour24MinuteAmpm,
      hour24MinuteNumber,
      yyyymmdd,
      monthNameDate,
      monthNameDateTime,
      monthNameDateTimeSec,
      monthDayWithSuffix,
      monthDayYearWithSuffix,
      monthDayHour,
    }

  }

  /**
   * return the day name in uppercase
   * example: Saturday, Sunday, Month
   * @returns string
   */
  static getWeekday = (utcDate: Date): string => {
    return this.mdyhms(utcDate).weekday;
  }

  /**
   * returns true if it's date falls on a saturday or sunday
   * @returns boolean
   */
  static isWeekend = (utcDate: Date): boolean => {
    return ['SATURDAY', 'SUNDAY'].includes(this.mdyhms(utcDate).weekday.toUpperCase());
  }


  /**
   * returns the nearest half hour
   * for example:
   * 8:29 return 8:30
   * 8:31 returns 9:00
   * @param date
   * @returns Date
   */
  static nearestHalfHour = (utcDate: Date): Date => {
    const date = new Date(utcDate.getTime());
    const minutes = date.getMinutes();
    date.setMinutes(minutes >= 30 ? 30 : 0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  /**
   * returns the next 30 minutes, minimum 5 minute increments
   * example:
   * 8:00 return 8:30
   * 8:01 returns 8:35
   * 8:06 return 8:40
   * @param utcDate
   * @returns Date
   */
  static nextThirtyMinutes = (utcDate: Date): Date => {
    const plus30 = new Date(utcDate.getTime());
    plus30.setMinutes(utcDate.getMinutes() + 30);
    plus30.setSeconds(0);
    plus30.setMilliseconds(0);
    const atleast5 = plus30.getMinutes() % 5;
    if (atleast5 > 0) {
      plus30.setMinutes(plus30.getMinutes() + (5 - atleast5));
    }
    return plus30;
  }

  /**
   * returns the next N minutes, minimum 5 minute increments
   * example:
   * mins = 30: 8:00 return 8:30
   *  8:01 returns 8:35
   *  8:06 return 8:40
   * mins = 60: 8:00 returns 9:00
   *  8:01 return 9:05
   *  8:06 return 9:10
   *
   * @param m: minutes, utcDate
   * @returns Date
   */
  static nextNMinutes = (mins: number, utcDate: Date): Date => {
    const plusN = new Date(utcDate.getTime());
    plusN.setMinutes(utcDate.getMinutes() + mins);
    plusN.setSeconds(0);
    plusN.setMilliseconds(0);
    const atleast5 = plusN.getMinutes() % 5;
    if (atleast5 > 0) {
      plusN.setMinutes(plusN.getMinutes() + (5 - atleast5));
    }
    return plusN;
  }

  /**
   * adds N hours to given date and rounds to
   * nearest half hour with a minimum of 2 hours between
   * @param utcDate
   * @param n: hours to add
   * @returns Date
   */
  static addHoursRounded = (utcDate: Date, n: number): Date => {
    // First, add 2 hours to the original date
    const hoursInMs = n * 60 * 60 * 1000;
    const halfHourInMs = 60 * 60 * 1000 / 2
    const date = new Date(utcDate.getTime() + hoursInMs);
    const halfHour = this.nearestHalfHour(date);

    // should be minimum of two hours
    if ((halfHour.getTime() - utcDate.getTime()) >= hoursInMs) {
      return halfHour;
    }

    // add 30 more minutes
    const date2 = new Date(utcDate.getTime() + (hoursInMs + halfHourInMs));
    return this.nearestHalfHour(date2)
  }

  /**
   * return the absolute difference (from) between date1 and date2
   * @param dt1 -- date being subtracted from
   * @param dt2 -- date being subtracted
   * if dt1 > dt2, result is positive - May5,2023 - May42023 = 1
   * if dt1 == dt2, result is 0
   * if dt1 < dt2, result is negative - May4,2023 - May5,2023 = -1
   * @returns number
   */
  static dayDiff(dt1: Date, dt2: Date): number {
    const usDate1 = this.getDateMidnight(dt1);
    const usDate2 = this.getDateMidnight(dt2);
    // console.log({
    //   dt1,
    //   dt1l: [dt1.toLocaleString(), dt1.getTime()],
    //   dt2,
    //   dt2l: [dt2.toLocaleString(), dt2.getTime()],
    //   usDate1,
    //   usDate1l: usDate1.toLocaleString(),
    //   usDate2,
    //   usDate2l: usDate2.toLocaleString(),

    // })
    const timeDiff = usDate1.getTime() - usDate2.getTime();
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return dayDiff;
  }

  /**
   * returns the given date's time after dropping the seconds.
   * @param dt
   * @returns Date
   */
  static roundTimeNoSeconds = (date: Date): number => {
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime() / 1000;
  }

  /**
   * return the suffix (1st, 2nd, 3rd, etc) of given number
   *
   * @param n
   * @returns
   */
  private static numberSuffix(n: number): string {
    return ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th"
  }

  /**
   * returns the days elapsed from today
   * example:
   * Today, Yesterday 2 days ago
   * @param fromDate
   * @returns string
   */
  static getDaysAgo = (fromDate: Date): string => {
    const daysAgo = this.dayDiff(new Date(), fromDate);

    if (daysAgo === 0) {
      return `Today`;
    }

    if (daysAgo === 1) {
      return 'Yesterday'
    }
    return `${daysAgo} days ago`
  }
}
