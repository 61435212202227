import React from 'react';
import { CloseButton, Modal } from 'react-bootstrap';

const ModalDoc: React.FunctionComponent<{ show: any, handleClose: any, children: any, title: string}> = ({show, handleClose, children, title}) => {
  return(
    <Modal className="modal-doc" scrollable={true} show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="modal-doc-header">
      <div className="modal-doc-header-title">{title}</div>
      <div role="button" title="Close" className="fw-bold ms-auto text-white"><CloseButton variant="white" onClick={handleClose} /></div>
      </Modal.Header>

      <Modal.Body className="modal-doc-body">
        {children}
      </Modal.Body>
    </Modal>
  );
}

export { ModalDoc }