import React from 'react'
import { useApiSignals } from '_hooks/useApiSignals'
import { Spinner } from 'components/common/Spinner'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { Signals } from './Signals'
import { IUserSignalAlertSearch } from 'services/UserSignalApiService'

export const SignalsPage: React.FunctionComponent<{symbols?: string[]}> = ({symbols}) => {

  const api = useApiSignals();

  const render = () => {

    if (api.apiState.isLoading) {
      return <Spinner />
    }

    if (api.apiState.isError) {
      return <ErrorMessage>{api.error}</ErrorMessage>
    }

    if (api.apiState.isLoaded) {
      if (symbols) {
        return (
          <Signals items={api.data.filter(s=>symbols.includes(s.symbolName))} />
        );
      }

      return (
        <Signals items={api.data} />
      );

    }

    return <></>
  }

  return (
    <div className="user-signals mt-3">
      {render()}
    </div>
  )
}
