import React, { useCallback, useRef, useState } from 'react'
import styles from '../DropDownFilter/DropdownFilter.module.scss';
import * as Icons from 'components/common/Icons';
import useClickOutside from '_hooks/useClickOutside';

export const TextDropDown: React.FunctionComponent<{
  items: string[],
  onSelect: (item: string) => void,
  className?: string,
}> = ({ items, onSelect, className }) => {

  const [searchString, setSearchString] = useState<string>('');
  const [matchedItems, setMatchedItems] = useState<string[]>(items);
  const [expanded, setExpanded] = useState<boolean>(false);

  const [matchIndex, setMatchIndex] = useState<number>(-1);

  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);

  useClickOutside(divRef, () => setExpanded(false));

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      // setNameResults(undefined);
      // setSymbolResults(undefined);
      setSearchString("");
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();

      if (matchIndex < 0) {
        setExpanded(false);
        setMatchIndex(-1);
        onSelect(searchString);
      } else {
        itemClick(matchedItems[matchIndex])
      }

      if (btnRef.current) {
        btnRef.current.click()
        btnRef.current.focus()
      }
    }

    if (e.key === 'ArrowDown' && matchIndex < matchedItems.length - 1) {
      setMatchIndex(matchIndex + 1);
      setSearchString(matchedItems[matchIndex + 1]);
    }

    if (e.key === 'ArrowUp' && matchIndex >= 1) {
      setMatchIndex(matchIndex - 1);
      setSearchString(matchedItems[matchIndex - 1]);
    }
  }

  const refreshMatches = useCallback((value: string) => {
    const matches = items.filter(s => (value || "").length === 0 || (value.length > 0 && s.toLowerCase().includes(value.toLowerCase())));
    setMatchedItems(matches);
  }, [items])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // const matches = items.filter(s => value.length > 0 && s.toLowerCase().includes(value.toLowerCase()));
    // console.log(matches, value);
    // setMatchedItems(matches)
    refreshMatches(value);
    setSearchString(value);
    if (!expanded) {
      setExpanded(true);
    }
  }

  const itemClick = (item: string) => {
    refreshMatches(item);
    setSearchString(item);
    onSelect(item);
    setExpanded(false);
    setMatchIndex(-1);
  }

  const downClick = () => {
    if (!expanded) {
      refreshMatches(searchString);
    }
    setExpanded(!expanded);
  }

  return (

    <div className={`${styles.filter} ${className}`} ref={divRef}>
      <div className={`${styles.filterSelection} ${expanded ? styles.filterSelectionExpanded : ''}`} role="button">
        <div className={styles.filterSelected}>
          <input type="text" className="text-16 w-100" tabIndex={0}
            value={searchString}
            onChange={onChange}
            onKeyDown={onKeyDown}
            maxLength={10}
            placeholder='Symbol...'
            style={{ background: "none", border: "none", color: "#fff" }}
          />
        </div>
        {!expanded && searchString !== "" && <div onClick={() => itemClick("")} className="opacity-70"><Icons.CloseX width={10} height={10} /></div>}
        <div onClick={downClick}><Icons.DownArrow /></div>
      </div>
      {expanded && <div tabIndex={1} className={`${styles.filterItems} ${styles.filterItemsExpanded}`} ref={btnRef}>
        {matchedItems.map((item, idx) => <div
          role="button"
          className={`${matchIndex === idx ? styles.filterItemSelected : ''} text-14 my-1`}
          key={`dropdown-key-${idx}`}
          onClick={() => itemClick(item)}
        >
          {item}
        </div>)}
      </div>}
    </div>
  )
}
