export enum AutoTradeManagementTypeEnum {
    AUTO        = 1,
    MANUAL      = 2,
}

export class AutoTradeManagementTypeEnumHelper {
    static names: Map<AutoTradeManagementTypeEnum, string> = new Map<AutoTradeManagementTypeEnum, string>([
        [AutoTradeManagementTypeEnum.AUTO, 'Auto'],
        [AutoTradeManagementTypeEnum.MANUAL, 'Manual'],
    ])
}