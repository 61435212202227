import { IUserTicketResponse } from "interfaces/IUserTicket";
import { UserTicketStatusEnum } from "predictagram-lib";
import { useState, useEffect } from "react";
import { useUserTickets } from "./useUserTickets";
import { TicketProgress } from "components/user/tickets/TicketProgress";
import { ModalTickets } from "components/user/badges/ModalTickets";

export function useUserTicketsActive(filename: string | null) {

  const userTickets = useUserTickets(filename);
  const [activeTicket, setActiveTicket] = useState<IUserTicketResponse | undefined>()
  const [showModal, setShowModal] = useState<boolean>(false);

  const renderTicket = (radius?: number, strokeWidth?: number) => {
    return (
      <>
        <ModalTickets show={showModal} handleClose={()=>setShowModal(false)} />

        {activeTicket &&
          <div className='hover-off-black p-2 rounded-circle' role="button" onClick={()=>setShowModal(true)}>
            <TicketProgress ticket={activeTicket} radius={radius} strokeWidth={strokeWidth} />
          </div>
        }
      </>
    );
  }


  useEffect(() => {
    if (userTickets.apiState.isLoaded) {
      const activeTicket = userTickets.data?.find((t) => t.statusId === UserTicketStatusEnum.ACTIVE);
      if (activeTicket) {
        setActiveTicket(activeTicket);
      } else {
        // if no active ticket, default to the first one.
        if (userTickets.data && userTickets.data.length > 0) {
          setActiveTicket(userTickets.data[0])
        }
      }
    }
  }, [userTickets.apiState, userTickets.data])

  return {
    ...userTickets,
    activeTicket,
    renderTicket,
    showModal: ()=>setShowModal(true),
    hideModal: ()=>setShowModal(false)
  }

}

