import React from 'react'
import { Emoji } from 'components/common/Emoji'
import { TicketSvg } from './TicketSvg'
import { GrayTicketSvg } from './TicketSvgGray'
import { IUserTicketResponse } from 'interfaces/IUserTicket'
import { UserTicketStatusEnum } from 'predictagram-lib'
import { TicketFail } from './TicketFail'

export const TicketsCollected: React.FunctionComponent<{ tickets: IUserTicketResponse[] | null }> = ({ tickets }) => {

  if (tickets === null) {
    return <></>
  }

  const renderTicket = (t: IUserTicketResponse) => {
    if ([
        UserTicketStatusEnum.ACTIVE,
        UserTicketStatusEnum.PENDING,
      ].includes(t.statusId)) {
      return <GrayTicketSvg />
    }

    if (t.statusId === UserTicketStatusEnum.FAILED) {
      return <div className="d-flex flex-column justify-content-start align-items-center gap-2">
      <TicketFail />
      <div><Emoji symbol="🎯" text={""} label="accuracy" />{t.scoreAvg * 100}%</div>
    </div>

    }

    if (t.statusId === UserTicketStatusEnum.PASSED) {
      return <div className="d-flex flex-column justify-content-start align-items-center gap-2">
        <TicketSvg />
        <div><Emoji symbol="🎯" text={""} label="accuracy" />{Math.round(t.scoreAvg * 100)}%</div>
      </div>
    }

    
  }

  return (
    <div className="d-flex justify-content-between gap-4">
      {tickets.map((t, i) => <div key={`ticket-key-${i}`}>
        {renderTicket(t)}
      </div>)}
    </div>
  );
}