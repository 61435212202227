import React from 'react'
import { ContestOverview } from './ContestOverview'
import { StorageKey } from '_constants';
import { ILeagueSeason } from 'interfaces/league';

export const ContestOverviewGotIt: React.FunctionComponent<{setShow: React.Dispatch<React.SetStateAction<boolean>>, season: ILeagueSeason}> = ({setShow, season}) => {

  const onClick = () => {
    localStorage.setItem(StorageKey.SEEN_TEAM_CONTEST_RULES, '1');
    setShow(false);
  }

  return (
    <div className="team-contest-overview-container">
    <ContestOverview season={season}/>
    <div className="disclaimer-button mt-3" role="button" onClick={()=>{onClick()}}>
    <span className="disclaimer-button-text">👍 Got it!</span>
    </div>
  </div>
   )
}
