import { PixelHelper, VerificationEventEnum } from "_utils/PixelHelper";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

export interface IReminder {
  show: boolean, 
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  clear: ()=>void,
  setLastReminder: ()=>void,
}
export const useVerificationReminder = (dt: Date, phoneIsVerified: boolean): IReminder => {

  const [ show, setShow ] = useState<boolean>(false);
  const COOKIE_NAME = 'ar'; // stands for auth reminder
  const EXPIRE_DAYS = 2;
  const dayInMs = 1000 * 60 * 60 * 24;


  const clear = () => {
    Cookies.remove(COOKIE_NAME);
  }

  const setLastReminder = () => {
    Cookies.set(COOKIE_NAME, dt.getTime().toString(), { expires: EXPIRE_DAYS });
  }

  useEffect(()=>{

    if (phoneIsVerified) {
      return;
    }

    let lastReminder = Cookies.get(COOKIE_NAME);
    
    if (!lastReminder || (lastReminder && dt.getTime() - parseInt(lastReminder) >= dayInMs)) {
      setLastReminder();
      PixelHelper.trackVerificationEvent(VerificationEventEnum.SHOW_REMINDER);
      setShow(true);
    } else {
      setShow(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return { show, setShow, clear, setLastReminder}

}