// @TODO: get this from library

export enum ResultDeliveryTimeEnum {
    END_OF_DAY = 1,
    WHEN_READY = 2,
}

export const resultDeliveryTimeNames: Map<number, string> = new Map<number, string>([
  [ResultDeliveryTimeEnum.END_OF_DAY, 'End of the day'],
  [ResultDeliveryTimeEnum.WHEN_READY, 'When Scored'],
])

export enum AlertGroupInterval {
  ASAP = 0,
  EVERY_3 = 3,
  EVERY_15 = 15,
  EVERY_30 = 30,
}

export const alertGroupIntervalNames: Map<number, string> = new Map<number, string>([
  [AlertGroupInterval.ASAP, 'ASAP'],
  [AlertGroupInterval.EVERY_3, 'Every 3 Minutes'],
  [AlertGroupInterval.EVERY_15, 'Every 15 Minutes'],
  [AlertGroupInterval.EVERY_30, 'Every 30 Minutes'],

])