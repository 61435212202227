import React from 'react';
import { Modal } from "react-bootstrap";
import { UserLogin } from './UserLogin';

interface IProps {
  show: any;
  handleClose: any;
  onLogin?: any;
  registerCallback?: () => void;
  onClickForgotPassword?: () => void;
}

const ModalLogin: React.FunctionComponent<IProps> = ({
  show,
  handleClose,
  onLogin,
  registerCallback,
  onClickForgotPassword
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>

        <div className="row justify-content-center login-form mx-3 mb-2">
          <div className="d-flex flex-row justify-content-between align-items-center ps-5 pe-3">
            <div className="page-title">Log In</div>
            <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
          </div>
        </div>

        <UserLogin loginCallback={onLogin} registerCallback={registerCallback} onClickForgotPassword={onClickForgotPassword} />

      </Modal.Body>
    </Modal>
  );
};

export { ModalLogin }