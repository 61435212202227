import { AccountContext, FieldWithError } from 'components/common'
import React, { useContext, useState } from 'react'
import * as Icons from "components/common/Icons";
import { SettingOption } from './Settings';
import { Form, Formik } from 'formik';
import { ShowHidePassword } from 'components/common/form/ShowHidePassword';
import { Validator, validationMap } from 'schemas';
import { MessengerContext } from 'components/common/messenger';
import { ModalPhoneConfirmationWizard } from './ModalPhoneConfirmationWizard';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

interface IProps {
  setActiveSetting: any,
  handleClose: any,
}
export const AccountDetails: React.FunctionComponent<IProps> = ({ setActiveSetting, handleClose }) => {

  const acctContext = useContext(AccountContext);
  const msgrContext = useContext(MessengerContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPhone, setShowPhone] = useState<boolean>(false);

  const initialValues = {
    email: acctContext?.account.email || '',
    phoneNumber: acctContext?.account.phoneNumber,
  }

  const onSubmit = async (data: any, actions: any) => {
    const { password } = data;
    try {
      await acctContext?.update({ password });
      msgrContext.setMessage({ body: 'Password changed.' });
      setShowPassword(false);
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  const onSuccess = (phoneNumber: number, setFieldValue: any, setFieldTouched: any) => {
    setFieldValue("phoneNumber", phoneNumber, true);
    setFieldTouched("phoneNumber");
    msgrContext.setMessage({ body: "Thank you for verifying your phone number" })
  }

  return (
    <div>
      <div className="d-flex justify-content-start align-items-center gap-3">
        <div role="button" onClick={() => setActiveSetting(SettingOption.NONE)}><Icons.LeftArrow /></div>
        <div className="flex-grow-1 page-title">Account Details</div>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
      </div>

      <div className="my-4">
        <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize validationSchema={validationMap.get(Validator.ACCOUNT_DETAILS_FORM)}>
          {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
            return <Form>

              <ModalPhoneConfirmationWizard
                initialPhoneNumber={values['phoneNumber'] as number}
                handleClose={() => { setShowPhone(false) }}
                show={showPhone}
                onSuccess={(phoneNumber: number) => onSuccess(phoneNumber, setFieldValue, setFieldTouched)} />

              <FieldWithError
                errors={errors}
                touched={touched}
                fieldName="email"
                placeholder="E-Mail Address"
                label="Email Address"
                readOnly={true} />

              {values['phoneNumber'] &&
                <div className="form-group mt-2">
                  <label>Phone Number</label>
                  <div className="my-1 d-flex justify-content-start align-items-center gap-2">

                    <PhoneInput disabled={true}
                      value={values["phoneNumber"]?.toString()} 
                      />
                    <div role="button" className="text-14 fw-bold text-lavender" onClick={() => setShowPhone(true)}>{values["phoneNumber"] ? 'Change' : 'Set'}</div>
                  </div>
                </div>
              }

              {!values['phoneNumber'] &&
                <div className="my-3">
                  <div style={{ position: 'relative' }}>
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName="phoneNumber"
                      label="Phone Number"
                      placeholder="(000) 000 0000"
                      readOnly={true} />

                    <div style={{ position: "absolute", top: "53%", right: "20px" }}>
                      <div role="button" className="text-14 fw-bold text-lavender" onClick={() => setShowPhone(true)}>{values["phoneNumber"] ? 'Change' : 'Set'}</div>
                    </div>
                  </div>
                </div>
              }


              <div className="text-lighter-charcoal text-15 my-2 ms-1">Only you can see your email address and phone number.</div>


              {showPassword &&
                <div className="my-3">
                  <ShowHidePassword
                    errors={errors}
                    touched={touched}
                    fieldName="password"
                    placeholder="Enter Password"
                    label="Password"
                    readOnly={false}
                  />

                  <ShowHidePassword
                    errors={errors}
                    touched={touched}
                    fieldName="confirmPassword"
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    readOnly={false}
                  />

                  <div className="d-flex gap-3">
                    <button type="button" className="btn btn-secondary mt-3 flex-shrink-1" onClick={() => {
                      setFieldValue("password", "", true);
                      setFieldValue("confirmPassword", "", true);
                      setShowPassword(false);
                    }}>Cancel</button>
                    <button type="submit" className="btn btn-primary mt-3 flex-grow-1">Save</button>
                  </div>
                </div>

              }

              {!showPassword &&
                <button type="button" className="btn btn-secondary my-3" onClick={() => setShowPassword(true)}>Change Password</button>
              }


            </Form>

          }}
        </Formik>
      </div>



    </div>

  )
}
