import { IQuote } from "./IQuote";
import { NewYorkTz } from 'predictagram-lib';

export class ChartFilters {
  static last2Hours (quote: IQuote) {
    const time: number = NewYorkTz.format(new Date(quote.t * 1000)).hour24MinuteNumber(); // DateHelper.newYorkTimezone(quote.t).formatHour24MinuteNumber();
    return time >= 1500 && time <= 1600;
  }

  static tradingHours (quote: IQuote) {
    // trading hours is 9:30 through 1600
    const time: number = NewYorkTz.format(new Date(quote.t * 1000)).hour24MinuteNumber(); // DateHelper.newYorkTimezone(quote.t).formatHour24MinuteNumber();
    return time >= 930 && time <= 1600;
  }

}
