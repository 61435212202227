import { useCallback, useEffect, useState } from 'react';

export enum ScrollDirection {
    UP = 1,
    DOWN = 2,
    IDLE = 3,
}

export const useScrollCounter = () => {

    const [ count, setCount] = useState<number>(0);
    const [ direction, setDirection ] = useState<ScrollDirection>(ScrollDirection.IDLE);
    const [ lastScrollTop, setLastScrollTop] = useState<number>(document.documentElement.scrollTop || document.body.scrollTop);

    const handleScroll = useCallback((event: any) => {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0 && lastScrollTop < currentScroll  ) {
        setDirection(ScrollDirection.DOWN)
      } else {
        setDirection(ScrollDirection.UP)
      }
      setLastScrollTop(currentScroll);
      setCount(count + 1);
    }, [count, lastScrollTop]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return [count, direction];
}

