import React, { useContext, useState } from "react";

import { AccountContext, FieldWithError, FileUpload } from "components/common";
import { MessengerContext } from "components/common/messenger";
import { Form, Formik } from "formik";
import { IAccount } from "interfaces";
import { validationMap, Validator } from "schemas";
import { userStorageService } from "services";
import * as Icons from 'components/common/Icons';
import { MessageHelper } from "_utils/MessageHelper";
import { AccuracyScore } from "../AccuracyScore";

const UserProfile: React.FunctionComponent<{ onSaveCallback?: (profile: IAccount) => void }> = ({ onSaveCallback }) => {

  const accountContext = useContext(AccountContext);
  const msgrContext = useContext(MessengerContext);

  const [validationSchema, setValidationSchema] = useState<any>(validationMap.get(Validator.PROFILE_FORM));

  const [avatarObj, setAvatarObj] = useState<any>(null); // save the uploaded image in case of

  const initialValues = {

    email: accountContext?.account.email || '',
    // firstName: accountContext?.account.firstName || '',
    // lastName: accountContext?.account.lastName || '',
    username: accountContext?.account.username || '',
    nickname: accountContext?.account.nickname || '',
    twitterUsername: accountContext?.account.twitterUsername || '',
    // phoneNumber: accountContext?.account.phoneNumber ? Helper.formatPhoneAsUS(accountContext?.account.phoneNumber.toString()) : '',
    // phoneNumberConfirmed: accountContext?.account.phoneNumberConfirmed || false,
    // password: '',
    // confirmPassword: '',
    avatar: '',
    avatarUrl: accountContext?.account.avatarUrl || '/images/blank-avatar-male.jpg',
    aboutMe: accountContext?.account.aboutMe || '',
  }

  const onSubmit = async (data: any, actions: any) => {
    const { setSubmitting, setStatus } = actions;

    if (typeof data.avatar === 'object') {
      setAvatarObj(data.avatar);
    }

    if (data.avatar) {
      try {
        if (typeof data.avatar === "object") {
          data.avatar = await userStorageService.uploadImage(data.avatar);
        } else {
          data.avatar = await userStorageService.uploadImage(avatarObj);
        }
      } catch (error: any) {
        setSubmitting(false);
        setStatus("Error uploading file");
        console.error({ error });
        throw Error(error.message);
      }
    } else {
      delete data.avatar;
    }

    // if (data.password === "") {
    //   delete data.password;
    //   delete data.confirmPassword;
    // }

    if (data.aboutMe === "") {
      data.aboutMe = null;
    }

    if (data.twitterUsername === "") {
      data.twitterUsername = null;
    }
    // if (data.phoneNumber === "") {
    //   delete data.phoneNumber;
    // }

    const account: IAccount = {
      // firstName: data.firstName,
      // lastName: data.lastName,
      // email: data.email,  // cannot update email
      // password: data.password,
      nickname: data.nickname,
      // address1: data.address1,
      // address2: data.address2,
      // city: data.city,
      // state: data.state,
      // zipcode: data.zip,
      // phoneNumber: typeof data.phoneNumber === "number" ? data.phoneNumber : data.phoneNumber ? data.phoneNumber.replaceAll(/[^\d]/g, '') : undefined,
      // dateOfBirth: data.dateOfBirth,
      avatar: data.avatar,
      aboutMe: data.aboutMe,
      twitterUsername: data.twitterUsername
    };

    try {
      setSubmitting(true);
      const profile = await accountContext?.update(account)
      msgrContext.setMessage({ body: "Record Saved" });
      if (onSaveCallback && profile) {
        onSaveCallback(profile);
      }
    } catch (err: any) {
      console.error(err.message);
      const errMessage = MessageHelper.translate(err.message);
      msgrContext.setMessage({ body: `${errMessage}` });
      setStatus(errMessage);
    }
  };

  return (
    <div className="container">
      {accountContext && accountContext.account &&
        <>
          <div className="user-profile">
            <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
              {({ touched, errors, setFieldValue, status, values }) => {
                return (
                  <Form>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="flex-fill">
                        <FileUpload
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          name="image-upload"
                          targetField="avatar"
                          touched={touched}
                          initialUrl={values["avatarUrl"]}
                          buttonLabel={<Icons.PurplePlusCircle />}
                        />
                      </div>
                      <div className="flex-fill">
                        <AccuracyScore classNames="text-20" userId={Number(accountContext.account.id)} />
                      </div>
                    </div>

                    <div className="my-1">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="username"
                        placeholder="@username"
                        label={`User Name (@name)`}
                        readOnly={true}
                        maxLength={15}
                      />
                    </div>

                    <div className="my-1">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="nickname"
                        placeholder="Nickname"
                        label={`Nickname`}
                        readOnly={false}
                        maxLength={25}
                      />
                    </div>

                    <div className="my-3">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="aboutMe"
                        placeholder="About Me"
                        label="About Me"
                        type="textarea"
                        as="textarea"
                        rows={5}
                      />
                    </div>

                    <div className="my-3">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="twitterUsername"
                        placeholder="Your Twitter handle (without @ sign)"
                        label="Twitter @UserName"
                        maxLength={15}
                      />
                    </div>                    

                    <div className="my-3">
                      <button type="submit" className="btn btn-primary w-100">Save Changes</button>
                    </div>

                    {status &&
                      <div className="row mt-5 justify-content-center">
                        <div className="col-md-6 alert alert-warning text-center">
                          {status}
                        </div>
                      </div>
                    }

                  </Form>
                )
              }}

            </Formik>
          </div>
        </>
      }
    </div>

  );
};

export { UserProfile };
