import { Route, Routes } from 'react-router-dom';
import { PublicLayout } from 'components/public/layout/PublicLayout';
import { AboutUs } from 'components/common/_static';
import { ForgotPassword, ResetPassword, UserLogin } from 'components/user/account';
import { FullRegistration } from 'components/user/account/FullRegistration';
import { ProtectedRoute } from 'components/common';
import { UserRoleEnum } from '_constants/UserRoleEnum';
import { UserLogout } from 'components/user/account/UserLogout';
import { FloatingMakeAPredictionButton } from 'components/common/FloatingMakeAPredictionButton';
import { SetPassword } from 'components/user/account/SetPassword';
import { StockPredictionPage } from 'components/public/Stock/StockPredictionPage';

import { ContestJoinPage } from 'components/user/contest/ContestJoinPage';
import { TeamContestRules } from 'components/common/TeamContestRules';
import { LeaderboardLayout } from 'components/user/league/leaderboards/LeaderboardLayout';
import { LeaderboardTeamsPage } from 'components/user/league/leaderboards/LeaderboardTeamsPage';
import { LeaderboardUsersPage } from 'components/user/league/leaderboards/LeaderboardUsersPage';
import { ContestListPage } from 'components/user/contest/ContestListPage';
import { SettingsPage } from 'components/user/account/SettingsPage';

import { HomePage } from 'components/public/HomePage';
import { PredictorLayout } from 'components/public/Stock/predictor/PredictorLayout';
import { PredictorPredictionsPage } from 'components/public/Stock/predictor/PredictorPredictionsPage';
import { PredictorPositionsPage } from 'components/public/Stock/predictor/PredictorPositionsPage';
import { BetaTradegramRouter } from 'components/common/BetaTradegramRouter';
import { FloatingPlusButton } from 'components/common/FloatingPlusButton';
import { StockListingRedirector } from 'components/public/Stock/StockListingRedirector';
import { PostPredictionPage } from 'components/user/prediction/PostPredictionPage';
import { TermsPage } from 'components/common/TermsPage';
import { PrivacyPolicyPage } from 'components/common/PrivacyPolicyPage';
import { StockExpandedPage } from 'components/public/Stock/StockExpandedPage';
import { NotFoundPage } from 'components/NotFoundPage';
import { SignalsWizardPage } from 'components/user/account/SignalsWizardPage';

export const PublicRoutes: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<HomePage />} />
        <Route path="terms-and-conditions" element={<TermsPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:authID" element={<ResetPassword />} />
        <Route path="set-password/:authID" element={<SetPassword />} />

        <Route path="contest/join/:contestId" element={<ContestJoinPage />} />

        <Route path="login" element={<UserLogin />} />
        <Route path="logout" element={<UserLogout />} />
        <Route path="register" element={<FullRegistration />} />

        <Route path="stock">
          <Route path="predictions/:stockSymbol" element={<StockListingRedirector />} />

          <Route path="predictor/:userId" element={<><PredictorLayout /><FloatingPlusButton /></>} >
            <Route index element={<PredictorPredictionsPage />} />
            <Route path="positions/:securityId" element={<BetaTradegramRouter><PredictorPositionsPage /></BetaTradegramRouter>} />
            <Route path="positions" element={<BetaTradegramRouter><PredictorPositionsPage /></BetaTradegramRouter>} />
          </Route>

          <Route path="prediction/:predictionId" element={
            // <ProtectedRoute roles={[UserRoleEnum.USER]} message="Get access to hundreds of real-time stock predictions from top predictors.">
            <><StockPredictionPage /><FloatingMakeAPredictionButton /></>
            // </ProtectedRoute>
          } />

          <Route path="expanded/:predictionId" element={
            <ProtectedRoute roles={[UserRoleEnum.USER]} message="Get access to hundreds of real-time stock predictions from top predictors.">
              <><StockExpandedPage /></>
            </ProtectedRoute>
          } />

          <Route path="postprediction/:predictionId" element={<PostPredictionPage />} />
        </Route>

        <Route path="settings" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><SettingsPage /></ProtectedRoute>}></Route>
        <Route path="team/contest-rules" element={<TeamContestRules />}></Route>

        <Route path="leaderboard" element={<LeaderboardLayout />}>
          <Route index element={<ProtectedRoute roles={[UserRoleEnum.USER]}><LeaderboardUsersPage /></ProtectedRoute>} />
          <Route path="users" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><LeaderboardUsersPage /></ProtectedRoute>} />
          <Route path="teams" element={<LeaderboardTeamsPage />} />
        </Route>

        <Route path="contests" element={<ContestListPage />} />

        <Route path='pro-signals/signup'>
          <Route index element={<SignalsWizardPage />} />
        </Route>


        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>


  );
}