import React from 'react';
import { ModalDoc } from './ModalDoc';
import { TeamContestRules } from './TeamContestRules';

export const ModalContestRules: React.FunctionComponent<{show: boolean, handleClose: ()=>void }> = ({show, handleClose}) => {
  return (
  <ModalDoc show={show} handleClose={handleClose} title="Contest Rules">
  <TeamContestRules isModal={true}/>
  </ModalDoc>  
  );
}