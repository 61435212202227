import React, { useContext, useState } from 'react'
import { Plus } from './Icons'
import { MakeAPredictionButton } from 'components/public/ListingsPage/components/MakeAPredictionButton';
import { MakeATradeButton } from 'components/user/tradegram/MakeATradeButton';
import { AccountContext } from './AccountContext';


export const FloatingPlusButton: React.FunctionComponent = () => {

  const acctContext = useContext(AccountContext);
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className="d-block d-md-none">
      <div className="floating-plus">
        {show ?
          <div className="d-flex justify-content-end align-items-end flex-column gap-3">
            <MakeAPredictionButton />
            {!!acctContext?.account.allowTradegram && <MakeATradeButton />}
            <div className="floating-plus-close d-flex flex-column justify-content-center align-items-center">
              <div role="button" onClick={() => setShow(false)}>X</div>
            </div>
          </div>
          :
          <div className="floating-plus-button d-flex flex-column justify-content-center align-items-center">
            <div role="button" onClick={() => { setShow(true) }}><Plus /></div>
          </div>
        }
      </div>
    </div>
  )
}
