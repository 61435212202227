import { ModalTerms } from "components/common/ModalTerms";
import { useState } from "react";

export const Disclaimer:React.FunctionComponent<{className?: string}> = ({className}) => {
  const [showTerms, setShowTerms] = useState<boolean>(false);

  const _className = `${className || "text-12 opacity-70 text-center mt-3 mb-2"} text-white`;

  return (
    <>
  <ModalTerms handleClose={()=>setShowTerms(false)} show={showTerms} />
  <div className={_className}>
    Not financial advice. For entertainment and educational purposes only. See <div className="d-inline" role="button" 
      onClick={()=>setShowTerms(true)}><span className="text-decoration-underline">Terms & Conditions</span></div> for full details.
  </div>    
  </>
  );
}