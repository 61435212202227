import React from 'react';
import { StorageKey, Url } from '_constants';
import { useNavigate } from 'react-router-dom';

export const Winners: React.FunctionComponent<{setShow: React.Dispatch<React.SetStateAction<boolean>>}> = ({setShow}) => {
  
  const navigate = useNavigate();
  
  return (
    <>
        <div className="winners d-flex flex-column justify-content-start align-items-center">
          <div className="align-self-end me-3 mb-3">
            <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => { 
              localStorage.setItem(StorageKey.SEEN_SEASON_WINNER, '1');
              setShow(false);
            }}></button>
          </div>
          <div className="winners-head">
            <div className="winners-head-title">CONGRATS</div>
            <div className="winners-head-subtitle">TO THE WINNERS OF SEASON 12</div>
          </div>

          <div className="winners-flag my-4">
            <div className="winners-flag-image" role="button" onClick={()=>navigate(Url.USER_TEAM_DETAIL.replace(':teamId', '2847591'))}><img src="/images/season12/team.png" alt="Team Bullish Wolves" /></div>
          </div>

          <div className="winners-users">
            <div className="winners-users-user" role="button" onClick={() => navigate(`${Url.PUBLIC_STOCK_PREDICTOR}/5003576988617762`)}><img src="/images/season12/1.png" alt="1st place" /></div>
            <div className="winners-users-user" role="button" onClick={() => navigate(`${Url.PUBLIC_STOCK_PREDICTOR}/8970764288266575`)}><img src="/images/season12/2.png" alt="2nd place" /></div>
            <div className="winners-users-user" role="button" onClick={() => navigate(`${Url.PUBLIC_STOCK_PREDICTOR}/5593390424674749`)}><img src="/images/season12/3.png" alt="3rd place" /></div>
          </div>
        </div>
    </>
  )
}

