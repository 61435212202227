import React from 'react'
import { Modal } from 'react-bootstrap'
import { CloseX } from 'components/common/Icons'
import { TradegramHelper } from '_utils/TradegramHelper'
import { NewYorkTz } from 'predictagram-lib'


interface IProps {
  show: any,
  handleClose: any,
}

export const ModalSupportedFormats: React.FunctionComponent<IProps> = ({ show, handleClose }) => {

  const nextFriday = TradegramHelper.getNextFriday(new Date());

  const mdy = NewYorkTz.mdyhms(nextFriday);

  const mmm = mdy.monthName;
  const day = mdy.day.padStart(2, '0');
  const mm = mdy.month.padStart(2, '0');
  const yy = parseInt(mdy.year) - 2000;
  const yymmdd = `${yy}${mm}${day}`;
  const ddmmmyy = `${day} ${mmm.toUpperCase()} ${yy}`; //19 JAN 24
  
  return (

    <Modal className="modal-supported-formats" scrollable={true} show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="d-flex justify-content-center flex-column p-3">
        <div className="align-self-end"><div role="button" onClick={handleClose}><CloseX /></div></div>

        <div className="text-center">

          <div className="my-3 fw-bold text-18 text-white">Supported Option Formats</div>

          <div className="text-14">
            AAPL {`${mmm}-${day}-${yy}`} $180 Puts <br />
            TNA {`${mmm} ${day} '${yy}`} $33 Call <br />
            NVDA{`${yymmdd}`}C00500000 <br />
            .NVDA{`${yymmdd}`}C480 <br />
            SPY $435.50 CALL {`${mmm.toUpperCase()} ${day}/${yy}`} <br />
            BOT +100 NFLX 100 {`${ddmmmyy}`} 500 CALL @9.90 <br />
            SOLD -100 NFLX 100 {`${ddmmmyy}`} 500 CALL @9.90 <br />
            BOT NFLX 100 {`${ddmmmyy}`} 500 CALL @9.90 <br />
          </div>

          <div className="text-12 fw-bold text-white my-3">Email us at support@predictagram.com to<br /> request an additional format</div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
