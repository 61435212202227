import React, { useState, useContext, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { PredictionListLazy } from '../ListingsPage/components/PredictionListLazy';
import { usePublicPrediction } from '_hooks/usePublicPrediction';
import { Spinner } from 'react-bootstrap';
import { APIState } from '_constants/APIState';
import { sortTypeEnum } from '../ListingsPage/components/Prediction/SortTypeSelect';
import { Helmet } from 'react-helmet';
import { config } from 'config';
import { AccountContext } from 'components/common';
import { ModalSignupLogin } from './predictor/ModalSignUpLogin';
import { AvatarSizeEnum } from 'components/common/Avatar';
import { AvatarById } from 'components/common/AvatarById';
import { usePublicProfile } from '_hooks/usePublicProfile';
import { LineChart } from 'components/common/Icons';
import { StorageKey, Url } from '_constants';
import { MorePredictionsSymbol } from './MorePredictionsSymbol';
import { Help } from '../ListingsPage/components/Help';
import { ConsensusChart } from './ConsensusChart';
import { useIntersectionObserver } from '_hooks/useIntersectionObserver';
import { useModalCrowdFunding } from '_hooks/useModalCrowdFunding';

const Username: React.FunctionComponent<{ userId: number }> = ({ userId }) => {
  const user = usePublicProfile(userId, userPredictionApiService, true);
  if (user.loaded) {
    return (
      <>{user.item.username}</>
    );
  }
  return <></>
}

const TheresMore = () => {
  const navigate = useNavigate();
  return (
    <div className="d-inline-flex flex-column">
      <div className="fw-bold mb-2">There's more exclusive prediction data to see!</div>
      <button type="button" className="btn btn-primary px-4 py-2" onClick={() => navigate(Url.DEFAULT_HOME)}>
        <LineChart /><span className="ms-2">Go to my Dashboard</span>
      </button>
    </div>
  );
}


export const StockPredictionPage: React.FunctionComponent = () => {

  const { predictionId } = useParams();
  const { items: predictions, apiState, setSort, options } = usePublicPrediction(userPredictionApiService, { predictionId: predictionId ? parseInt(predictionId, 10) : 0 } as ISearchOptions)
  const location = useLocation();

  // const modalCrowdFunding = useModalCrowdFunding(localStorage.getItem(StorageKey.SEEN_CROWDFUND));

  const acctContext = useContext(AccountContext);
  const [showLogin, setShowLogin] = useState<boolean>(acctContext?.isLoggedIn() === false);
  const [showWalkthrough, setShowWalkthrough] = useState<boolean>(localStorage.getItem(StorageKey.SEEN_WALKTHROUGH_PREDICTION) === null);
  const [showConsensusWalkthrough, setShowConsensusWalkthrough] = useState<boolean>(false);
  const [seen, setSeen] = useState<boolean>(localStorage.getItem(StorageKey.SEEN_WALKTHROUGH_CONSENSUS) !== null);
  const consensusRef = useRef<HTMLDivElement | null>(null);

  useIntersectionObserver(consensusRef, () => {
    if (!showWalkthrough && !showConsensusWalkthrough && !seen) {
      setShowConsensusWalkthrough(true);
      setSeen(true);
    }
  }, { threshold: 1 });

  return (
    <>
      {apiState === APIState.LOADED &&
        predictions[0].userId &&
        <ModalSignupLogin
          tagLine={<>See exclusive stock market predictions by <span className="fw-bold"><Username userId={predictions[0].userId} /></span> and other top traders.</>}
          show={showLogin}
          handleClose={() => setShowLogin(false)}
          head={<div className="followee-card-image align-self-center my-3 d-flex justify-content-center align-items-center">
            <AvatarById userId={predictions[0].userId} size={AvatarSizeEnum.LARGE} />
          </div>}
        />
      }
      <div className="container">
        {predictions.length === 0 && apiState === APIState.LOADED &&
          <div>No Predictions</div>
        }

        {apiState === APIState.LOADING &&
          <Spinner />
        }

        {apiState === APIState.LOADED && predictions.length > 0 &&
          <>
            <Helmet>
              <title>Predictagram: Stock Predictions - {predictions[0].stockSymbol} </title>
              <meta property="og:title" content={`Predictagram: Stock Predictions - ${predictions[0].stockSymbol}`} />
              <meta property="og:description" content={`Check out this stock prediction for ${predictions[0].stockSymbol}`} />
              <meta property="og:url" content={`${config.wwwDomain}${location.pathname}`} />
            </Helmet>


            {/* {acctContext?.isLoggedIn() && modalCrowdFunding.modalElement()} */}
            
            <div className="my-3">
              {setShowWalkthrough &&
                <div className="d-flex justify-content-end"><Help title="How To Read This Predictagram" onClick={() => {
                  localStorage.removeItem(StorageKey.SEEN_WALKTHROUGH_PREDICTION);
                  setShowWalkthrough(true);
                }} /></div>
              }
              <PredictionListLazy showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough} showMenu={false} predictions={predictions} onSelectSort={setSort} orderBy={options?.orderBy || sortTypeEnum.LATEST} />
            </div>
            <div className="my-3 rounded bg-charcoal p-3 bg-gradient-lavender no-hover">
              <TheresMore />
            </div>


            {!showWalkthrough &&
                <div ref={consensusRef} className="mb-5">
                  <ConsensusChart predictionTypes={[]} stockSymbol={predictions[0].stockSymbol} showWalkthrough={showConsensusWalkthrough} setShowWalkthrough={setShowConsensusWalkthrough} />
                </div>
            }

            {!showConsensusWalkthrough && !showWalkthrough &&
            <MorePredictionsSymbol prediction={predictions[0]} className="mt-3" />
            }

          </>
        }
      </div>
    </>
  );
}
