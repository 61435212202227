import React, { useCallback, useEffect, useRef, useState } from 'react';
import { predictionService } from "../../../services";
import { ChartModel, ChartModel as ChartOptions } from "../../../models/chart.model";
import { StockHelper } from "../../../_utils/stock.helper";
import { IChart } from "../../../interfaces/chart/IChart";
import { PredictionChart } from "../ListingsPage/components/Prediction/PredictionChart";
import {DateEx, IQuoteFull, PointUpDownOption, PredictionTypeEnum} from 'predictagram-lib';
import { predictionTypeNames } from '_constants/PredictionTypeEnum';
import { CumulativeChartModel } from "../../../models/cumulative-chart.model";
import { useStockDetail } from '_hooks/useStockDetail';
import { QuoteAsOf } from './QuoteAsOf';
import { SymbolPredictors } from './SymbolPredictors';
import { ConsensusHelper } from '_utils/ConsensusHelper';
import { Walkthrough } from 'components/user/walkthrough/Wallkthrough';
import { WalkthroughConsensusModel } from 'models/walkthrough.consensus.model';
import { StorageKey, Url } from '_constants';
import { Help } from '../ListingsPage/components/Help';
import { IUserSignalAlert, UserSignalAlertDirectionType } from 'services/UserSignalApiService';
import { Signals } from 'components/user/dashboard/signals/Signals';
import { useNavigate } from 'react-router-dom';


interface IProps {
  stockSymbol: string,
  predictionTypes: PredictionTypeEnum[] | undefined,
  heightOverride?: number,
  title?: string | JSX.Element,
  showWalkthrough?: boolean,
  showPredictors?: boolean,
  setShowWalkthrough?: (value: boolean) => void,
  isCandle?: boolean,
  moreAnnotationData?: PointUpDownOption[],
  signalAlerts?: IUserSignalAlert[],
  showSignalDetail?: boolean
}

export const ConsensusChart: React.FunctionComponent<IProps> = ({ stockSymbol, predictionTypes, heightOverride, title, showWalkthrough, showPredictors=true, setShowWalkthrough, isCandle = false, moreAnnotationData, signalAlerts, showSignalDetail = true }) => {

  const [chartData, setChartData] = useState<IChart>(undefined as any);

  const refParentContainer = useRef<HTMLDivElement>(null);
  const [cardWidth, setCardWidth] = useState<number | undefined>(undefined);
  const [walkthroughIndex, setWalkthroughIndex] = useState<number>(0);
  const [showHelp, setShowHelp] = useState<boolean>(true);
  const navigate = useNavigate();

  const getConsensusDate = useCallback(()=>{
    return ConsensusHelper.getConsensusDate(new DateEx())
  },[]);

  const walkthroughRef = useRef<HTMLDivElement | null>(null);
  const [plotlyDiv, setPlotlyDiv] = useState<any>(null);

  const stockDetail = useStockDetail(stockSymbol, getConsensusDate(), predictionService);

  const loadChart = useCallback(async () => {
    const dt = getConsensusDate();
    const hours = StockHelper.workingHours(dt);
    const end = dt.getTimeSec() < hours.end.getTimeSec() ? dt : hours.end;
    const stats = await predictionService.getBarsData(stockSymbol, hours.start, end);
    const d = ChartOptions.prebuildChartDay({ stats: stats as IQuoteFull[], endFillTimeSec: hours.end.getTimeSec() });

    // @TODO: this is a copy from the other chart. Move this to a model.
    const annotationYAxis = (y: number, extra?: Partial<Plotly.Annotations>) => {
      return Object.assign({
        x: 1,
        align: "right",
        xref: 'paper',
        y: y,
        borderpad: 1,
        borderwidth: 1,
        xshift: 8,
        xanchor: 'left',
        yanchor: 'middle',
        text: ` ${d.priceFormat(y)} `,
        showarrow: false,
        font: { color: '#fff', },
      }, extra) as Partial<Plotly.Annotations>;
    }

    const redBox = { bgcolor: 'rgba(0, 0, 0, 0.9)', bordercolor: 'rgba(255, 0, 0, 1)' }
    const greenBox = { bgcolor: 'rgba(0, 0, 0, 0.9)', bordercolor: 'rgba(16, 240, 0, 1)' }

    const cumData = await new CumulativeChartModel(predictionService).preBuildStats(stockSymbol, hours.start, end, predictionTypes);

    const maxYLast = cumData.dMaxY_60.length - 1;
    const minYLast = cumData.dMinY_60.length - 1
    const highAnnotation = annotationYAxis(cumData.dMaxY_60[maxYLast], greenBox);
    const lowAnnotation = annotationYAxis(cumData.dMinY_60[minYLast], redBox);
    const aCloseValue = annotationYAxis(d.lastStats.c, { bgcolor: 'rgba(138,126,247,0.8)', bordercolor: 'rgba(138,126,247,0.8)' });


    const plotData = isCandle ?
    [
//      ChartModel.plotCandleData('before', d.plotCandles.before, [ChartModel.candlestickGreenFaded, ChartModel.candlestickRedFaded]),
      ChartModel.plotCandleData('during', d.plotCandles.during, [ChartModel.candlestickGreenFaded, ChartModel.candlestickRedFaded]),
//      ChartModel.plotCandleData('after', d.plotCandles.after, [ChartModel.candlestickGreenFaded, ChartModel.candlestickRedFaded]),
    ]
    :
    [
      ChartModel.plotData('before', d.plotLines.before, ChartModel.plotLineDarkColor),
      ChartModel.plotData('during', d.plotLines.during, ChartModel.plotLineColor),
      ChartModel.plotData('after', d.plotLines.after, ChartModel.plotLineDarkColor)
    ]

    const chartYMin = Math.min(d.dataLowDay, cumData.chartYMin || d.dataLowDay);
    const chartYMax = Math.max(d.dataHighDay, cumData.chartYMax || d.dataHighDay);
    const chart = ChartOptions.dailyChartTemplate({
      yMin: chartYMin,
      yMax: chartYMax,
      tickVals: d.tickVals,
      tickText: d.tickText,
      formatStr: d.formatStr,
      plotData: [
        //ChartOptions.plotData('day', d.plotLines.during, ChartOptions.plotLineColor),

        ...plotData,
        
        cumData.maxLine,
        cumData.minLine,
        cumData.max60Line,
        cumData.min60Line,
      ],
    }) as IChart;

    if (!!showWalkthrough && setShowWalkthrough && cumData.maxLine.x.length < 2) {
      setShowWalkthrough(false);
      setShowHelp(false);
    } 

    chart.layout.annotations.push(aCloseValue);
    chart.layout.shapes.push(ChartOptions.shapeHorizontalLineValue(d.lastStats.c));

    if (!isNaN(cumData.dMaxY_60[maxYLast]) && maxYLast > 0) {
      chart.layout.annotations.push(highAnnotation);
    }
    if (!isNaN(cumData.dMinY_60[minYLast]) && minYLast > 0) {
      chart.layout.annotations.push(lowAnnotation);
    }

    // additional Annotations
    if (moreAnnotationData) {
      const annots: Partial<Plotly.Annotations>[] = moreAnnotationData.map(s => {
        const green: string = ChartModel.rectLineGreenColor, red: string = ChartModel.rectLineRedColor;

        const arrow = ChartModel.getArrow(s.isSellSignal, s.optionType );

        return {
          text: '',
          showarrow: true,
          x: s.x,
          y: s.y,
          arrowcolor: arrow.color === "green" ? green : red, //: s.isSellSignal && ? red : green, // ''; cumulStats.signals.color,
          arrowside: 'end',
          ax: s.x,
          axref: "x",
          ay: s.y + (arrow.direction === "up" ? 1 : -1) * (chartYMax - chartYMin) / 6,
          ayref: "y",
          startstandoff: 10,
        }
      })
      chart.layout.annotations.push(...annots);
    }


    if (signalAlerts && signalAlerts.length > 0) {
      const annots: Partial<Plotly.Annotations>[] = signalAlerts.map(s => {
        const green: string = ChartModel.rectLineGreenColor, red: string = ChartModel.rectLineRedColor;
        const direction = s.directionType === UserSignalAlertDirectionType.UP ? "up" : "down";
        return {
          text: '',
          showarrow: true,
          arrowwidth: 0,
          x: s.stockTime,
          y: s.stockPrice,
          arrowcolor: direction === "up" ? green : red, //: s.isSellSignal && ? red : green, // ''; cumulStats.signals.color,
          arrowside: 'end',
          ax: s.stockTime,
          axref: "x",
          ay: s.stockPrice + (direction === "down" ? 1 : -1) * (chartYMax - chartYMin) / 6,
          ayref: "y",
          startstandoff: 10,
        }
      })
      chart.layout.annotations.push(...annots);

    }

    chart.onInitialized = (figure, plotlyDiv: Readonly<HTMLElement>) => {
      setPlotlyDiv(plotlyDiv);
    };

    // // @ts-ignore
    // chart.config.scrollZoom = true;
    // // @ts-ignore
    // chart.config.displayModeBar = true;
    // chart.layout.dragmode = 'zoom';
    // //chart.layout.height = 300;
    // chart.layout.paper_bgcolor = 'rgb(0,0,0)';
    // chart.layout.showlegend = false;
    // chart.layout.legend = { x: 0, xanchor: 'left', y: 0 };
    // chart.layout.hovermode = 'x unified';
    // chart.layout.autosize = true;
    // chart.layout.xaxis.fixedrange = false;
    // chart.layout.yaxis.fixedrange = false;

    setChartData(chart);

  }, [getConsensusDate, predictionTypes, stockSymbol, setShowWalkthrough, showWalkthrough, isCandle]);

  useEffect(() => {
    if (refParentContainer.current) {
      setCardWidth(refParentContainer.current.offsetWidth);
    }
    loadChart();
    //};
  }, [predictionTypes, loadChart])

  const getWalkthrough = () => {
    const model = new WalkthroughConsensusModel(plotlyDiv);
    return model.getWalkthrough();
  }

  const removeFade = () => {
    const model = new WalkthroughConsensusModel(plotlyDiv);
    return model.removeFade();
  }

  return (
    <div className="predictor-card-chart">

      {setShowWalkthrough && showHelp && plotlyDiv && 
        <div className="d-flex justify-content-end my-3"><Help title="How To Read This Consensus Chart" onClick={() => {
          localStorage.removeItem(StorageKey.SEEN_WALKTHROUGH_CONSENSUS);
          setShowWalkthrough(true);
        }} /></div>
      }

      {chartData &&
        <div ref={refParentContainer}>
          <div>{title || `${stockSymbol} Consensus ${predictionTypes ? predictionTypes.map((pt: PredictionTypeEnum) => predictionTypeNames.get(pt)).join(', ') : ''} Predictions`}</div>
          <div><QuoteAsOf quote={stockDetail.item.today} /></div>

          <div className="d-flex flex-row justify-content-center align-items-stretch" style={{ "height": `${heightOverride || ChartOptions.chartHeight}px`, "width": `${cardWidth}px` }}>
            <>
              {!!showWalkthrough && plotlyDiv && <div ref={walkthroughRef}>
                <Walkthrough onClose={() => {
                  if (setShowWalkthrough) {
                    setShowWalkthrough(false)
                  }
                  localStorage.setItem(StorageKey.SEEN_WALKTHROUGH_CONSENSUS, '1'); //@TODO: refactor
                  removeFade();
                }} onIndexChange={setWalkthroughIndex} walkthrough={getWalkthrough()} />
              </div>
              }
              <PredictionChart chartData={chartData} />
            </>
          </div>

          {showSignalDetail && signalAlerts && signalAlerts.length > 0 &&
            <div className="d-flex flex-column gap-2 mt-2">
              <div className="d-flex justify-content-between">
                <div className="text-14">Latest {stockSymbol} Signals</div>
                <div className="text-14" role="button" onClick={()=>navigate(Url.USER_DASHBOARD_SIGNALS_SIGNALS)}>View More</div>
              </div>
              <div className="user-signals user-signals-scroll">
                <Signals items={signalAlerts} />
              </div>
            </div>
          }

          {showPredictors &&
            <div className="my-2">
              <SymbolPredictors stockSymbol={stockSymbol} />
            </div>
          }
        </div>
      }
    </div>
  );

}
