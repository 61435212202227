import React from 'react'
import { Emoji } from 'components/common/Emoji';
import { ProgressBar } from './ProgressBar';
import { IGroupedPredictionTypeTotals } from '_utils/UserStatsHelper';

export const PredictionTypeStats: React.FunctionComponent<{ items: IGroupedPredictionTypeTotals[] }> = ({ items }) => {
  return (
    <div className="user-stats-items">
      {items.map((stats, i) => <div key={`stats-key-${i}`} className="d-flex justify-content-center align-items-center w-100 gap-2 my-4">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="d-flex justify-content-between gap-2 w-100">
            <div className="text-14">{stats.groupName} <span className="text-gray">({stats.totalPredictions})</span></div>
            <div className="text-14">{Math.round(stats.avgScoreWeighted * 100)}% <Emoji symbol="🎯" text={""} label="accuracy" /></div>
          </div>
          <div style={{width: "100%", height: "0.625rem"}}>
            <ProgressBar progress={Math.round(stats.avgScoreWeighted * 100)} />
          </div>
        </div>

      </div>
      )}
    </div>
  );
}