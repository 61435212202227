import React from "react";
import { ErrorMessage, Field } from "formik";
import { IKeyValue } from "interfaces";

const DropDown: React.FunctionComponent<{
  errors: any;
  touched: any;
  enumMap: Map<number | string, string>;
  defaultItem?: IKeyValue<number>;
  defaultKey?: number | string,
  label: string | JSX.Element;
  name: string;
  [key: string]: any;
}> = ({ errors, touched, enumMap, label, name, defaultItem, defaultKey, ...rest }) => {
  return (
    <div className="form-group">
      {label && label !=="" && 
        <>{label}</>
      }

      <Field
        name={name}
        as="select"
        className={"form-control" + (errors && errors[name] && touched && touched[name] ? " is-invalid" : "")}
        {...rest}
      >
        {defaultItem && <option value={defaultItem.key}>{defaultItem.value}</option>}

        {Array.from(enumMap.keys()).map((key: number | string, i: number) => {
          return (
            <option key={`code_${i}`} value={key} defaultValue={key}>
              {enumMap.get(key)}
            </option>
          )
        })}
      </Field>
      {errors && <ErrorMessage name={name} component="div" className="invalid-feedback" />}
    </div>
  );
};

export { DropDown };
