import { IWalkthrough, WalkthroughCardType } from "components/user/walkthrough/Wallkthrough"

export class WalkthroughPredictionsModel {

  private FADED_OPACITY = '0.15';
  private FULL_OPACITY = '1';

  private mainSvg0: HTMLElement;
  private mainSvg1: HTMLElement;

  public constructor(
    protected plotlyDiv: any,
    protected predictionUserDiv: HTMLDivElement,
  ) { 
    this.mainSvg0 = this.plotlyDiv.getElementsByClassName("main-svg")[0];
    this.mainSvg1 = this.plotlyDiv.getElementsByClassName("main-svg")[1];
  }

  private getPredictionLineElement = () => {
    return this.plotlyDiv.getElementsByClassName('layer-above')[0] as HTMLDivElement;
  }

  private page1Style = (a: HTMLDivElement, b: HTMLDivElement): React.CSSProperties => {
    this.mainSvg0.style.zIndex = 'unset';
    this.mainSvg1.style.zIndex = '101';

    const annotations = this.mainSvg1.getElementsByClassName('infolayer')[0].getElementsByClassName('annotation');
    Array.from(annotations).forEach((annot: any) => {
      annot.style.opacity = this.FADED_OPACITY;
    })
    this.getPredictionLineElement().style.opacity = this.FULL_OPACITY;

    const rect = b.getBoundingClientRect();
    const predictionUser = this.predictionUserDiv;
    if (predictionUser) {
      predictionUser.style.zIndex = 'unset';
    }

    const left = rect.x - a.offsetWidth - 10;
    const center = (window.innerWidth - a.offsetWidth) / 2;
    const bottom = rect.bottom + 10;

    return {
      left: `${left < 0 ? center : left }px`,
      top: `${bottom + a.offsetHeight > window.innerHeight ? rect.top - a.offsetHeight : bottom}px`,
    }
  }

  private page2Style = (a: HTMLDivElement, b: HTMLDivElement): React.CSSProperties => {
    const rect = b.getBoundingClientRect();
    const predictionUser = this.predictionUserDiv;
    if (predictionUser) {
      predictionUser.style.zIndex = 'unset';
    }

    // fade line
    this.getPredictionLineElement().style.opacity = this.FADED_OPACITY;

    // fade annotations
    const annotations = this.mainSvg1.getElementsByClassName('infolayer')[0].getElementsByClassName('annotation') as HTMLCollectionOf<HTMLElement>;
    [
      annotations[0],
      annotations[1],
      annotations[2],
    ].forEach((annot: any) => {
      if (annot && annot.style) {
        annot.style.opacity = this.FADED_OPACITY;
      }
    });

    const isHighLow = annotations[3].querySelector('.annotation-text-g .cursor-pointer .annotation-text')?.innerHTML.includes('Predicted');
    annotations[3].style.opacity = isHighLow ? this.FULL_OPACITY : this.FADED_OPACITY;

    [
      annotations[4],
      annotations[5],
    ].forEach((annot: any) => {
      if (annot && annot.style) {
        annot.style.opacity = this.FULL_OPACITY;
      }
    });

    (this.mainSvg0.getElementsByClassName('layer-below')[0] as HTMLDivElement).style.opacity = this.FADED_OPACITY;
    const cartesianLayer =  this.mainSvg0.getElementsByClassName('cartesianlayer')[0] as HTMLDivElement;
    (cartesianLayer.getElementsByClassName('subplot xy')[0].getElementsByClassName('plot')[0] as HTMLDivElement).style.opacity = this.FADED_OPACITY;
    (cartesianLayer.getElementsByClassName('yaxislayer-above')[0] as HTMLDivElement).style.opacity = this.FADED_OPACITY;
    (cartesianLayer.getElementsByClassName('xaxislayer-above')[0] as HTMLDivElement).style.opacity = this.FADED_OPACITY;

    this.mainSvg0.style.zIndex = '101';
    this.mainSvg1.style.zIndex = '101';

    const left = rect.x - a.offsetWidth - 10;
    const center = (window.innerWidth - a.offsetWidth) / 2;
    const bottom = rect.bottom + 10;

    return {
      left: `${left < 0 || ((left + a.offsetWidth) > window.innerWidth) ? center : left}px`,
      top: `${bottom + a.offsetHeight > window.innerHeight ? rect.top - (a.offsetHeight + 55) : bottom}px`,
    }
  }

  private page3Style = (a: HTMLDivElement, b: HTMLDivElement): React.CSSProperties => {
    const rect = b.getBoundingClientRect();
    b.style.zIndex = '1000';
    b.style.position = 'relative';
    const mainSvg = this.mainSvg0;
    mainSvg.style.zIndex = 'unset';

    this.mainSvg0.style.zIndex = 'unset';
    this.mainSvg1.style.zIndex = 'unset';

    return {
      left: `${rect.x + 25}px`,
      top: `${rect.bottom}px`,
    }
  }

  private page1: WalkthroughCardType = [
    <>The vertical line shows when the prediction was made.</>,
    this.getPredictionLineElement(),
    this.page1Style
  ]

  private page2: WalkthroughCardType = [
    <>The colored and dashed line(s) shows the predicted price target.</>,
    (() => {
      const infoLayer = this.plotlyDiv.getElementsByClassName('infolayer')[0];
      const annotationLayer = infoLayer.getElementsByClassName('annotation')[3];
      return annotationLayer;
    })(),
    this.page2Style
  ]

  private page3: WalkthroughCardType = [
    <>The user's accuracy score is shown by their username</>,
    (() => {
      return this.predictionUserDiv
    })(),
    this.page3Style
  ]

  public removeFade () {
    const annotations = this.mainSvg1.getElementsByClassName('infolayer')[0].getElementsByClassName('annotation');
    Array.from(annotations).forEach((annot: any) => {
      annot.style.opacity = this.FULL_OPACITY;
    })

    this.getPredictionLineElement().style.opacity = this.FULL_OPACITY;
    this.mainSvg0.style.opacity = this.FULL_OPACITY;
    this.mainSvg1.style.opacity = this.FULL_OPACITY;

    (this.mainSvg0.getElementsByClassName('layer-below')[0] as HTMLDivElement).style.opacity = this.FULL_OPACITY;

    const cartesianLayer = this.plotlyDiv
      .getElementsByClassName("main-svg")[0]
      .getElementsByClassName('cartesianlayer')[0]

    cartesianLayer.getElementsByClassName('subplot xy')[0]
      .getElementsByClassName('plot')[0].style.opacity = this.FULL_OPACITY;
    cartesianLayer.getElementsByClassName('yaxislayer-above')[0].style.opacity = this.FULL_OPACITY;
    cartesianLayer.getElementsByClassName('xaxislayer-above')[0].style.opacity = this.FULL_OPACITY;
  }
  
  public getWalkthrough(): IWalkthrough {
    const walkthrough: IWalkthrough = {
      title: <>How to read a prediction</>,
      steps: [
        this.page1,
        this.page2,
        this.page3
      ]
    }
    return walkthrough;
  }

}
