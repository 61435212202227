import {StockStatsIntervalEnum} from "./stock-stats-interval.enum";
import {DateEx, DateHelper, IntradayHelper, MathEx, NewYorkTz, StockHelper} from "../utils";

export enum PredictionTypeEnum {
    VALUE_CLOSE             = 1, // allowed off-hours
    VALUE_HIGH              = 2, // allowed off-hours
    VALUE_LOW               = 3, // allowed off-hours
    VALUE_AT                = 4,
    VALUE_30_MIN            = 5,
    VALUE_30_MIN_UP_DOWN    = 6,
    VALUE_1H_UP_DOWN        = 7,
    VALUE_CLOSE_UP_DOWN     = 8,
    VALUE_CLOSE_UP_DOWN_DISTANCE = 9, // up-down + score based on distance
    VALUE_30_MIN_UP_DOWN_DISTANCE = 10, // up-down + score based on distance
    VALUE_1H_UP_DOWN_DISTANCE     = 11, // up-down + score based on distance
    VALUE_AT_8PM            = 20, // allowed off-hours
    VALUE_CLOSE_UP_DOWN_3D  = 30,

}

export class PredictionTypeHelper {
    // public static publicList() {
    //     return [
    //         PredictionTypeEnum.VALUE_CLOSE,
    //         PredictionTypeEnum.VALUE_HIGH,
    //         PredictionTypeEnum.VALUE_LOW,
    //         PredictionTypeEnum.VALUE_AT,
    //     ]
    // }

    public static allList() {
        return [
            PredictionTypeEnum.VALUE_CLOSE,
            PredictionTypeEnum.VALUE_AT,
            PredictionTypeEnum.VALUE_LOW,
            PredictionTypeEnum.VALUE_HIGH,
            PredictionTypeEnum.VALUE_30_MIN,
            PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
            PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_1H_UP_DOWN,
            PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
            PredictionTypeEnum.VALUE_AT_8PM,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
        ];
    }

    // @TODO: maybe move to db
    public static predictionName(typeId: PredictionTypeEnum) {
        switch (typeId) {
            case PredictionTypeEnum.VALUE_CLOSE:
            case PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D:
            case PredictionTypeEnum.VALUE_CLOSE_UP_DOWN:
            case PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE:
                return 'closing';
            case PredictionTypeEnum.VALUE_AT_8PM:
                return 'closing';
            case PredictionTypeEnum.VALUE_AT:
            case PredictionTypeEnum.VALUE_30_MIN:
            case PredictionTypeEnum.VALUE_30_MIN_UP_DOWN:
            case PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE:
            case PredictionTypeEnum.VALUE_1H_UP_DOWN:
            case PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE:
                return 'intraday';
            case PredictionTypeEnum.VALUE_HIGH:
                return 'high';
            case PredictionTypeEnum.VALUE_LOW:
                return 'low';
        }
        throw new Error('invalid type');
    }

    public static predictionInterval(typeId: PredictionTypeEnum) {
        switch(typeId) {
            case PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D:
                return StockStatsIntervalEnum.MIN_5;
            default:
                return StockStatsIntervalEnum.MIN_1;
        }
    }

    public static isUpDown(typeId: PredictionTypeEnum) {
        const upDownList = [
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
            PredictionTypeEnum.VALUE_1H_UP_DOWN,
            PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
            PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
        ];
        return upDownList.includes(typeId);
    }

    public static isRange(typeId: PredictionTypeEnum) {
        return [
            PredictionTypeEnum.VALUE_AT,
            PredictionTypeEnum.VALUE_AT_8PM,
            PredictionTypeEnum.VALUE_CLOSE
        ].includes(typeId);
    }

    public static isOnlyWhenOpen(typeId: PredictionTypeEnum) {
        const intraday = [PredictionTypeEnum.VALUE_30_MIN/*, PredictionTypeEnum.VALUE_AT*/];
        return this.isUpDown(typeId) || intraday.includes(typeId);
    }

    public static isOffHour(typeId: PredictionTypeEnum) {
        return !this.isOnlyWhenOpen(typeId);
    }

    public static predictionValueAt(typeId: PredictionTypeEnum, now: Date): DateEx {
        if (typeId===PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D) {
            const dFirst = StockHelper.isTradingDay(now) && !StockHelper.isPostMarketHours(now)
                           ? now : StockHelper.getNextTradingDay(now);
            const dLast = StockHelper.getNextTradingDay(StockHelper.getNextTradingDay(dFirst));
            return StockHelper.workingHours(dLast).end;
        } else if (typeId===PredictionTypeEnum.VALUE_1H_UP_DOWN || typeId===PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE) {
            return new DateEx(NewYorkTz.nextNMinutes(60, now));
        } else if ([PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE, PredictionTypeEnum.VALUE_30_MIN].includes(typeId)) {
            return new DateEx(NewYorkTz.nextNMinutes(30, now));
        } else if (typeId===PredictionTypeEnum.VALUE_AT) {
            return new DateEx(IntradayHelper.getMidTime(now).d);
        } else if (this.oneDayCloseTypes().includes(typeId)) {
            return new DateEx(IntradayHelper.getWhen(now).d);
        } else if (typeId===PredictionTypeEnum.VALUE_AT_8PM) {
            return StockHelper.workingHours(now).end8PM;
        } else {
            // @TODO: all types should be here
            throw new Error('unsupported type');
        }
    }

    public static oneDayCloseTypes() {
        return [
            PredictionTypeEnum.VALUE_CLOSE,
            PredictionTypeEnum.VALUE_HIGH,
            PredictionTypeEnum.VALUE_LOW,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
        ];
    }

    public static getPreposition(typeId: PredictionTypeEnum) {
        const prepositions: Map<PredictionTypeEnum, string> = new Map<PredictionTypeEnum, string>([
            [PredictionTypeEnum.VALUE_30_MIN, 'by'],
            [PredictionTypeEnum.VALUE_30_MIN_UP_DOWN, 'by'],
            [PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE, 'by'],
            [PredictionTypeEnum.VALUE_1H_UP_DOWN, 'by'],
            [PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE, 'by'],
            [PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D, 'at'],
            [PredictionTypeEnum.VALUE_AT, 'at'],
            [PredictionTypeEnum.VALUE_AT_8PM, 'at'],
            [PredictionTypeEnum.VALUE_CLOSE, 'at'],
            [PredictionTypeEnum.VALUE_HIGH, 'by'],
            [PredictionTypeEnum.VALUE_LOW, 'by'],
          ]);
        return prepositions.get(typeId);
    }

    public static getPriceMove() {
        return [
            PredictionTypeEnum.VALUE_CLOSE,
            PredictionTypeEnum.VALUE_AT,
            PredictionTypeEnum.VALUE_30_MIN,
            PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
            PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_1H_UP_DOWN,
            PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_AT_8PM,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
        ];
    }

    public static stepSizeFactor(typeId: PredictionTypeEnum) {
        const rate13 = [
            PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
        ];
        if (rate13.includes(typeId)) {
            return 1.3;
        } else {
            return 1;
        }
    }


    public static getHighLow() {
        return [
            PredictionTypeEnum.VALUE_LOW,
            PredictionTypeEnum.VALUE_HIGH,
        ];
    }

    public static calcUpDownExpectedValue(typeId: PredictionTypeEnum, isUp: boolean, stepSize: number, stockValue:number) {
        if (!PredictionTypeHelper.isUpDown(typeId)) {
            throw new Error('invalid type :' + typeId)
        }
        const rate = PredictionTypeHelper.stepSizeFactor(typeId);

        const exp = MathEx.round(isUp ? stockValue + stepSize*rate : stockValue-stepSize*rate, 5);
        return exp;
    }
}
