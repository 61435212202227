import React, { useContext, useEffect, useState } from 'react';
import { AccountContext } from 'components/common';
import { FolloweeContext } from 'components/common/FolloweeContext';
import { MessengerContext } from 'components/common/messenger';
import { ModalLogin } from 'components/user/account/ModalLogin';
import { ModalRegister } from 'components/user/account/ModalRegister';
import { IFollowee } from 'interfaces/IFollow';
import * as Icons from 'components/common/Icons';


export const FollowButton: React.FunctionComponent<{
  userId: number,
}> = ({ userId }) => {

  const followeeContext = useContext(FolloweeContext);
  const acctContext = useContext(AccountContext);
  const msgrContext = useContext(MessengerContext);

  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showSignup, setShowSignup] = useState<boolean>(false);
  const [isFollow, setIsFollow] = useState<boolean>(false);
  const [isNotify, setIsNotify] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    const _load = async () => {
      await followeeContext?.getFollowees();
      await acctContext?.getSettings();
    }
    _load();
  }, []);

  useEffect(() => {
    if (followeeContext?.followees) {
      // console.log({followees: followeeContext.followees})
      const f: IFollowee[] = followeeContext.followees.filter((f: IFollowee) => f.followeeId === userId);
      // console.log({f, userId});
      if (f.length > 0) {
        setIsFollow(true);
        setIsNotify(f[0].alertEnable);
        return;
      }
    }
    setIsFollow(false);
    setIsNotify(false);

  }, [followeeContext?.followees, userId, counter, isFollow, isNotify])

  const onClickFollow = async () => {
    if (!acctContext?.account) {
      setShowLogin(true);
    } else {
      if (userId) {
        try {
          if (isFollow) {
            await followeeContext?.deleteFollowee(userId);
          } else {
            await followeeContext?.addFollowee(userId);
          }
        } catch (error: any) {
          msgrContext.setMessage({ body: `Could not complete request: ${error.message}`, title: 'Follow/Unfollow error' })
        }
      }
      setCounter(counter + 1);
    }
  }

  const onClickBell = async () => {
    if (userId) {
      try {
        if (isNotify) {
          await followeeContext?.disableNotify(userId);
        } else {
          await followeeContext?.enableNotify(userId);
        }
      } catch (error: any) {
        msgrContext.setMessage({ body: `Could not complete request: ${error.message}`, title: 'Follow/Unfollow error' })
      }
    }
    setCounter(counter + 1);    
  }

  const onLogin = async () => {
    setShowLogin(false);
    await followeeContext?.addFollowee(userId);
  }

  const onClickBellDisabled = () => {
    msgrContext.setMessage({body: "You need to enable alerts in your account settings to receive alerts from this user"});
  }


  return (
    <>
      <ModalLogin handleClose={() => setShowLogin(false)} show={showLogin} onLogin={() => { onLogin() }} registerCallback={() => { setShowLogin(false); setShowSignup(true) }} />
      <ModalRegister handleClose={() => setShowSignup(false)} show={showSignup} onRegister={() => { setShowSignup(false) }} onClickLogin={() => { setShowSignup(false); setShowLogin(true) }} />

      <div className="d-flex flex-row justify-content-center align-items-center gap-2 ms-auto">
        <div role="button" className="btn btn-secondary btn-secondary-small text-14 px-4 py-3" onClick={onClickFollow}>{isFollow ? 'Unfollow' : 'Follow +'}</div>
        {acctContext?.isLoggedIn() && isFollow && 
          <>
          {!!acctContext?.account.alertsEnable ? (
            <div role="button" onClick={onClickBell} style={{borderRadius: '50%', backgroundColor: `${isNotify ? '#ffffff' : '#111111'}`}}><Icons.NotificationBell on={isNotify} /></div>
          ) : (
            <div role="button" onClick={onClickBellDisabled} style={{borderRadius: '50%'}}><Icons.NotificationBellDisabled /></div>
          )}
          </>
        }
      </div>
    </>

  );
}