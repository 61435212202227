import { SymbolsContext } from 'components/common/SymbolsContext';
import { IStockSymbol } from 'interfaces/IStockSymbol';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { SymbolToggleButton } from '../SymbolToggleButton';
import { IAccountSettings } from 'interfaces/account/IAccountSettings';
import * as Icons from 'components/common/Icons';
import { useDashboardSettings } from '_hooks/useDashboardSettings';
import { StorageKey } from '_constants';
import { MessengerContext } from 'components/common/messenger';
import useClickOutside from '_hooks/useClickOutside';

interface IProps {
  settings: IAccountSettings,
  onChangeSymbols: (symbols: string []) => void,
  selectedSymbolsState: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  symbolFilterTypeState: [SymbolFilterTypeEnum, React.Dispatch<React.SetStateAction<SymbolFilterTypeEnum>>]
}

export enum SymbolFilterTypeEnum {
  ALL = 'All',
  WATCH_LIST = 'Watchlist',
}

export const SymbolFilter:React.FunctionComponent<IProps> = ({settings, onChangeSymbols, selectedSymbolsState, symbolFilterTypeState}) => {

  const symbolsContext = useContext(SymbolsContext);
  const msrgContext = useContext(MessengerContext);

  const divRef = useRef<HTMLDivElement>(null);
  const [filterType, setFilterType] = symbolFilterTypeState;
  const [selectedSymbols, setSelectedSymbols] = selectedSymbolsState;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [dashboardSettings, setDashboardSettings] = useDashboardSettings(localStorage, StorageKey.DASHBOARD_SETTINGS);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  useClickOutside(divRef, ()=>{setExpanded(false)});

  const _onSymbolClick = (symbol: string, selected: boolean) => {
    
    if (isSaveDisabled) {
      setIsSaveDisabled(false);
    }

    if (selectedSymbols.includes(symbol)) {
      const symbols = selectedSymbols.filter((s)=>s!==symbol)
      if (symbols.length === 0 ){
        msrgContext.setMessage({body: "You must select at least one symbol"});
        return;
      }
      setSelectedSymbols(symbols);
      setDashboardSettings({
        ...dashboardSettings,
        selectedSymbols: symbols,
      })
      //onChangeSymbols(symbols);
    } else {
      const symbols = [...selectedSymbols, symbol]
      setDashboardSettings({
        ...dashboardSettings,
        selectedSymbols: symbols,
      })
      setSelectedSymbols(symbols);
      //onChangeSymbols(symbols);
    }
  }

  const filterTypeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

    if (isSaveDisabled) {
      setIsSaveDisabled(false);
    }
    const value = e.target.value as SymbolFilterTypeEnum;
    setFilterType(value);
    if (value === SymbolFilterTypeEnum.ALL) {
      const symbols = symbolsContext ? symbolsContext.symbols.map((s)=>s.symbolName) : [];
      setSelectedSymbols(symbols);
      setDashboardSettings({
        ...dashboardSettings,
        selectedSymbols: symbols,
      })

      //onChangeSymbols(symbols);
    } else {
      const symbols = settings.symbolsWatchlist || []
      setSelectedSymbols(symbols);
      setDashboardSettings({
        ...dashboardSettings,
        selectedSymbols: symbols,
      })

      //onChangeSymbols(symbols);
    }
  }


  const toggleExpand = (value: boolean) => {
    setExpanded(value);
    if (value === false && !isSaveDisabled) {
      onChangeSymbols(selectedSymbols);
    }
  }

  return (
    <div className="symbol-filter" ref={divRef}>
      {/* <div style={{position:'fixed', top: 0, left: 0}}><pre>{JSON.stringify(selectedSymbols, null, 2)}</pre></div> */}
      <div className={`gap-1 symbol-filter-tab ${expanded ? '' : 'symbol-filter-tab-closed'}`} role="button" onClick={()=>{toggleExpand(!expanded)}}>
        <div className="symbol-filter-tab-label-dollar">$</div>
        <div className="symbol-filter-tab-label">{selectedSymbols.length === symbolsContext?.symbols.length ? 'All' : selectedSymbols.join(', ')}</div>
        <div><Icons.DownArrow /></div>
      </div>
      <div className={`symbol-filter-selection-container${expanded ? '' : '-hidden'}`}>
        <div className="symbol-filter-selection-which d-flex justify-content-between mx-3">
          <div className="d-flex justify-content-start align-items-center gap-3">
            <div><input type="radio" name="filterType" value={SymbolFilterTypeEnum.ALL} checked={filterType === SymbolFilterTypeEnum.ALL} onChange={filterTypeHandler}/><span className="ms-2">{SymbolFilterTypeEnum.ALL}</span></div>
            <div><input type="radio" name="filterType" value={SymbolFilterTypeEnum.WATCH_LIST} checked={filterType === SymbolFilterTypeEnum.WATCH_LIST} onChange={filterTypeHandler}/><span className="ms-2">{SymbolFilterTypeEnum.WATCH_LIST}</span></div>
          </div>
          <div>
            <button className="btn btn-secondary text-14 px-3 fw-bold align-self-end" type="button" onClick={()=>toggleExpand(false)} disabled={isSaveDisabled}>Save</button>
          </div>
        </div>

        <div className="symbol-filter-selection-symbols mx-3 d-flex flex-column align-items-start">
          <div className="d-flex flex-wrap gap-2 justify-content-start mt-3">
          {
            symbolsContext?.symbols.map((s: IStockSymbol, i: number) => <div key={`symbol-key-${i}`} title={s.symbolDescription}>
                <SymbolToggleButton nameOnly={true} stockSymbol={s} initialSelected={selectedSymbols?.includes(s.symbolName) || false} onClick={_onSymbolClick}/>
            </div>)
          }
          </div>
        </div>
      </div>
    </div>
  )
}

