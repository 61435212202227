import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';


export interface ISlide {
  id: number,
  detail: string | JSX.Element,
  imageUrl: string,
}

export const HelpSlider: React.FunctionComponent = () => {
  const [index, setIndex] = useState<number>(0);
  const handleSelect = (selectedIndex: any) => {
    setIndex(selectedIndex);
  };

  const slides: ISlide[] = [
    { id: 1, detail: 'Each prediction is shown over the price chart of a symbol.', imageUrl: '/images/help/01.png' },
    { id: 2, detail: <>The <span className="fw-bold">Prediction Range</span> is also shown over the price chart. An increasing price range is green and a declining price range is red.</>, imageUrl: '/images/help/02.png' },
    { id: 3, detail: <>The <span className="fw-bold">Prediction Range</span> shows the upper and lower price range for the prediction for a specific time.</>, imageUrl: '/images/help/03.png' },
    { id: 4, detail: 'This is when the prediction was made.', imageUrl: '/images/help/04.png' },
    { id: 5, detail: <>Each user's overall prediction accuracy is tracked to help you put their prediction in context.</>, imageUrl: '/images/help/05.png' },
  ]

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
      {slides.map((s: ISlide) => <Carousel.Item>
        <div className="help-slide d-flex flex-column justify-content-center align-items-center">

          <div className="help-slide-detail my-3">
            {s.detail}
          </div>
          <div className="help-slide-image">
            <img className="d-block" width={330} height={330} src={s.imageUrl} alt="Help slides" />
          </div>
        </div>
      </Carousel.Item>)}
    </Carousel>

  );
}