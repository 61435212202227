import React, { useState } from 'react';
import * as Icons from 'components/common/Icons';

export enum sortTypeEnum {
  LATEST = "latest",
  BEST = "best",
}

export const SortTypeSelect: React.FunctionComponent<{onSelectCallback: (sortType: sortTypeEnum) => void, selected: sortTypeEnum}> = ({onSelectCallback, selected}) => {

  const [ value, setValue ] = useState<sortTypeEnum | undefined>(selected);

  const onSelect = (sortType: sortTypeEnum) => {
    setValue(sortType);
    onSelectCallback(sortType);
  }

  return (
    <div className="d-flex flex-row justify-content-center align-items-center gap-1">
      <div role="button" className={`d-flex gap-2 btn-tabs p-2 px-3 ${value === sortTypeEnum.LATEST ? 'btn-tabs-active-sort' : ''}`} onClick={()=>onSelect(sortTypeEnum.LATEST)}><Icons.Clock /> Latest</div>
      <div role="button" className={`d-flex gap-2 btn-tabs p-2 px-3 ${value === sortTypeEnum.BEST ? 'btn-tabs-active-sort' : ''}`} onClick={()=>onSelect(sortTypeEnum.BEST)}><Icons.LineUpTrend />Best</div>
    </div>
  );
}