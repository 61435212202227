import React from 'react'
import { AutoTradePositionTypeEnum, AutoTradePositionTypeEnumHelper } from 'predictagram-lib';
import { SelectDropDown } from 'components/common/form/SelectDropDown';


interface IProps {
  value: AutoTradePositionTypeEnum | undefined,
  setValue: (value: AutoTradePositionTypeEnum) => void,
  className?: string,
}

export const PositionTypeFilter:React.FunctionComponent<IProps> = ({value, setValue, className}) => {

  return (
    <SelectDropDown<AutoTradePositionTypeEnum, string> 
      onSelect={setValue}
      options={AutoTradePositionTypeEnumHelper.names}
      selected={value}
      title="Position Type"
      className={className}
    />
  )
}



