import {IQuoteFull, LinePoints} from "../interface";
import {AnalysisHelper} from "./analysis.helper";

export class PivotPointData {

    // https://www.tradingview.com/pine-script-reference/v5/
    // https://www.tradingcode.net/tradingview/relative-moving-average/
    // RMA = alpha * source + (1-alpha)* RMA[1]
    // alpha = 1/length
    protected atrPeriod = 10;
    protected atrFactor = 1;
    protected alpha = 1/this.atrPeriod;

    // https://www.tradingview.com/support/solutions/43000501823-average-true-range-atr/
    // https://www.tradingview.com/pine-script-reference/v5/#fun_ta.atr
    // true range = max[(high - low), abs(high - previous close), abs (low - previous close)]

    // RMA for True Range
    protected atr: LinePoints = AnalysisHelper.emptyLine();

    protected pivotPoints = new Map<number, {isHigh:boolean;isLow:boolean}>();
    protected pivotPointLines = {
        center: AnalysisHelper.emptyLine(),
        atr100pctMax: AnalysisHelper.emptyLine(),
        atr100pctMin: AnalysisHelper.emptyLine(),
    };




    // public constructor() {
    //
    // }

    public data() {
        return this.pivotPointLines;
    }

    protected calcAtr(currPos: number, stats: readonly IQuoteFull[]) {
        const period = this.atrPeriod;
        // const factor = this.atrFactor;
        const alpha = this.alpha;
        const currQ = stats[currPos];
        const prevPeriod = stats[currPos-period>0?currPos-period:0];
        let high = currQ.h;
        let low = currQ.l;
        for (let i = currPos-1;i>=0&&i>currPos-period;i--) {
            const q = stats[currPos];
            if (q.h>high) {
                high = q.h;
            }
            if (q.l<low) {
                low = q.l;
            }
        }

        const trueRange = Math.max(high - low, Math.abs(high - prevPeriod.c), Math.abs(low - prevPeriod.c));
        const atr = currPos===0 ? trueRange : alpha * trueRange + (1-alpha)*this.atr.y[currPos-1];
        this.atr.y.push(atr);
        this.atr.x.push(currQ.t);
        return atr;
    }

    // https://stackoverflow.com/questions/64019553/how-pivothigh-and-pivotlow-function-work-on-tradingview-pinescript
    protected calcCurrPivotPoint(currPos: number, stats: readonly IQuoteFull[]) {

        const currQ = stats[currPos];
        const ppPeriod = 2;
        if (currPos<ppPeriod) {
            return (currQ.h + currQ.l + currQ.c) / 3;
        }
        if (currPos+2>stats.length-1) {
            return false;
        }

        let isHigh = true;
        let isLow = true;
        // use TrendingView PP - check 2 prev and 2 next, of something is not available - can't calculate PP
        [-2,-1,1,2].forEach(i=>{
            const q = stats?.[currPos+i];
            if (!q) {
                return false;
            }
            // make sure there is not pivot point recently, it's relevant when high/low is same
            const pivot = this.pivotPoints.get(q.t);
            if (q.l<currQ.l || (q.l===currQ.l && pivot && pivot.isLow)) {
                isLow = false;
            }
            if (q.h>currQ.h || (q.h===currQ.h && pivot && pivot.isHigh)) {
                isHigh = false;
            }
        });
        if (isHigh || isLow) {
            this.pivotPoints.set(currQ.t, {isLow, isHigh});
        }
        if (isHigh) {
            return currQ.h
        }
        if (isLow) {
            return currQ.l;
        }
        return false;

    }

    public calc(currPos: number, stats: readonly IQuoteFull[]) {

        const pivotPointRes = this.calcCurrPivotPoint(currPos, stats);
        const ppCenter = this.pivotPointLines.center;
        const atrMax = this.pivotPointLines.atr100pctMax;
        const atrMin = this.pivotPointLines.atr100pctMin;
        const prevPoint = ppCenter.y?.[currPos-1];

        let ppVal = prevPoint;
        if (pivotPointRes) {
            if (prevPoint) {
                ppVal = (prevPoint*2 + pivotPointRes) / 3;
            } else {
                ppVal = pivotPointRes;
            }
        }
        const time = stats[currPos].t;
        ppCenter.y.push(ppVal);
        ppCenter.x.push(time);

        const atr = this.calcAtr(currPos, stats);
        const atrFactor = this.atrFactor*atr;
        atrMax.y.push(ppVal+atrFactor);
        atrMax.x.push(time);

        atrMin.y.push(ppVal-atrFactor);
        atrMin.x.push(time);

    }
}
