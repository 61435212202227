import { Url } from "_constants";
import { Helper } from "_utils";
import { IContest } from "interfaces/IContest";
import { useLocation, useNavigate } from "react-router-dom";

export const ContestNavigation: React.FunctionComponent<{contest: IContest}> = ({contest}) => {

  const location = useLocation();
  const navigate = useNavigate();
  
  const cid = contest.id;

  return (
    <div className="contest-head-navigation my-3 d-flex justify-content-start gap-3 px-md-3 px-1">
      <div role="button" className={`py-2 btn-tabs ${Helper.lastPath(location.pathname) === Helper.lastPath(Url.USER_CONTEST) ? 'btn-tabs-active' : ''}`} onClick={() => navigate(Url.USER_CONTEST.replaceAll(':contestId', cid.toString()))}>Rankings</div>
      <div role="button" className={`py-2 btn-tabs ${Helper.lastPath(location.pathname) === Helper.lastPath(Url.USER_CONTEST_PREDICTIONS) ? 'btn-tabs-active' : ''}`} onClick={() => navigate(Url.USER_CONTEST_PREDICTIONS.replaceAll(':contestId', cid.toString()))}>Predictions</div>
   </div>
  );
}