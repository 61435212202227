import { IContest } from "interfaces/IContest";

import { useCallback, useEffect, useState } from "react";
import { IContestStats, UserContestApiService, UserContestApiServiceError } from "services/common/ContestApiService";

export function useContestRankings (contestId: number, service: UserContestApiService<IContest>) {

  const [ items, setItems ] = useState<IContestStats[]>([]);
  const [ loaded, setLoaded ] = useState<boolean>(false);
  const [ error, setError ] = useState<UserContestApiServiceError | undefined>(undefined);


  const load = useCallback(async ()=>{

    try {
      const items = await service.getContestStats(contestId);
      setItems(items);
      setLoaded(true);
    } catch (error) {
      setError(error as UserContestApiServiceError);
    }
  }, [contestId, service])

  useEffect(()=>{
   load();
  }, [load])

  return {items, loaded, error};

}