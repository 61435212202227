import React, { useContext, useState } from 'react'
import { LeagueTabEnum, LeagueTabs, leagueTabNames } from './LeagueTabs'
import { LeagueUserList } from './LeagueUserList'
import { RoundBackButton } from 'components/common/buttons/RoundBackButton';
import { useNavigate } from 'react-router-dom';
import { SeasonContext } from 'components/common/SeasonContext';
import { StreakLevels } from 'predictagram-lib';
import { IUserAchievementUserStatResponse, IUserAchievementUserStatSearchFilter } from 'services/UserAchievementService';
import { ProgressBar } from '../stats/ProgressBar';
import { useApiAchievementUserStats } from '_hooks/useApiAchievementUserStats';
import { AccountContext } from 'components/common';
import { Help } from 'components/public/ListingsPage/components/Help';
import { ModalHelpLeveling } from 'components/public/Help/ModalHelpLeveling';
import { Spinner } from 'components/common/Spinner';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { BadgeHelper } from '../badges/Helper';
import { Helmet } from 'react-helmet';
import { RoundCloseButton } from 'components/common/buttons/RoundCloseButton';
import { ModalContestRules } from 'components/common/ModalContestRules';
import { StorageKey } from '_constants';
import { LeagueHelper } from './LeagueHelper';
import { ContestRulesLink } from '../league/leaderboards/components/ContestRulesLink';
import { Disclaimer } from './Disclaimer';

export const barColor: Record<LeagueTabEnum, string> = {
  [LeagueTabEnum.BRONZE]: '#FF8933',
  [LeagueTabEnum.SILVER]: '#FFFFFF',
  [LeagueTabEnum.GOLD]: '#E7AB38',
  [LeagueTabEnum.PLATINUM]: '#38E7DC',
  [LeagueTabEnum.MASTER]: '#E738B6',
}


export const LeaguePrizes: React.FunctionComponent<{onClose: ()=>void}> = ({onClose}) => {

  const [showRules, setShowRules] = useState<boolean>(false);
  const prizeAmount = [0, 50, 100, 200, 300];

  return (
    <>
    <ModalContestRules show={showRules} handleClose={() => setShowRules(false)} />
    <div className="border border-color-lavender border-radius-15 p-4 bg-off-black no-hover" style={{minHeight: "20.6875rem"}}>

      <div className="d-flex justify-content-between align-items-center">
        <div className="fw-bold text-14 text-lavender">NEW!</div>
        <div onClick={onClose}><RoundCloseButton /></div>
      </div>
      
      <div className="d-flex flex-column">
        <div className="fw-bold">League Prizes</div>
        <div className="opacity-70 text-14">Drawing on April 2</div>
      </div>

      <div className="d-flex justify-content-between align-items-center my-4 px-3 flex-nowrap">
        {Array.from(leagueTabNames).map(([key, value], i) =>
          <div
            key={`tab-key-${i}`}
            className={`${value.label.toLowerCase()} opacity-100`}>

            <div className="d-flex flex-column gap-1 align-items-center">
              <div className={`league-rank-image rank-${key}`}></div>
              <div className={`rank-${key} fw-bold`} style={{color: barColor[key]}}>${prizeAmount[key-1]}</div>
            </div>

          </div>
        )}
      </div>


      <div className="text-14 my-4">
        Maintain an accuracy score above 🎯<span className="fw-bold">70%</span> to be eligible for your league's prize drawing.
      </div>

      <div>
      <ContestRulesLink />
      </div>
    </div>
    </>
  );
}

const LevelProgress: React.FunctionComponent<{ userStat: IUserAchievementUserStatResponse }> = ({ userStat }) => {
  const { currentLevel, levelMin, levelMax, progress, levelInfo } = BadgeHelper.getLevelProgress(userStat);
  const label = levelMax === null ? <span className="fw-bold">Max Level {levelMin}</span> : <><span className="fw-bold text-12">Lvl {currentLevel}</span></>

  return (
    <div className="my-3 d-flex justify-content-center justify-content-md-start align-items-center">

      <div className="d-block d-md-none">
        <div className="rounded py-2 px-3 bg-off-black no-hover league-rank-card">
          <div className="d-flex gap-2 justify-content-start align-items-center my-2">
            <div className={`mt-1 league-rank-image rank-${levelInfo.current.rank}`}></div>
            <div>
              <div className="align-self-start">{label}</div>
              <div style={{ height: "0.4375rem", width: "10rem" }} className="user-stat-card-bar-progress"><ProgressBar progress={progress} colorFn={() => barColor[levelInfo.current.rank as LeagueTabEnum]} /></div>
            </div>
          </div>
          <div className="text-11 text-center opacity-70">Earn a reward at the end of the season based on the league you're in!</div>
        </div>
      </div>

      <div className="d-none d-md-block w-100">
        <div className="rounded py-2 px-3 bg-off-black no-hover league-rank-card w-100">
          <div className="d-flex gap-2 justify-content-start align-items-center my-2">
            <div className={`mt-1 league-rank-image rank-${levelInfo.current.rank}`}></div>
            <div>
              <div className="align-self-start">{label}</div>
              <div style={{ height: "0.4375rem", width: "20rem" }}><ProgressBar progress={progress} colorFn={() => barColor[levelInfo.current.rank as LeagueTabEnum]} /></div>
            </div>
          </div>
          <div className="text-11 text-start opacity-70">Earn a reward at the end of the season based on the league you're in!</div>
        </div>
      </div>


    </div>
  )
}

export const LeagueRankingsPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const seasonContext = useContext(SeasonContext);
  const acctContext = useContext(AccountContext);
  const [showHelp, setShowHelp] = useState<boolean>(false);

  const [showPrize, setShowPrize] = useState<boolean>(LeagueHelper.showLeaguePriceCard(localStorage.getItem(StorageKey.SEEN_LEAGUE_PRIZE), new Date()));

  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(LeagueHelper.showDisclaimer())

  const initialFilter: IUserAchievementUserStatSearchFilter = {
    endTime: seasonContext?.season?.endDateSec,
    startTime: seasonContext?.season?.startDateSec,
    userId: acctContext?.account.id
  };

  const userStatsApi = useApiAchievementUserStats(initialFilter);

  if (userStatsApi.apiState.isLoading) {
    return <Spinner />
  }

  const closeDisclaimer = () => {
    localStorage.setItem(StorageKey.SEEN_LEAGUE_DISCLAIMER, '1');
    setShowDisclaimer(false);
  }

  if (userStatsApi.apiState.isLoaded) {
    return (
      <>
      <Helmet>
        <title>Predictagram: League Rankings</title>
      </Helmet>
        <ModalHelpLeveling handleClose={() => setShowHelp(false)} show={showHelp} />
        <div className="league-page p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <RoundBackButton onClick={() => navigate(-1)} />
            <div className="d-flex gap-2">
              {seasonContext && seasonContext.season !== null &&
                <div className="d-flex flex-row gap-2 align-items-end">
                  <div className="text-14 fw-bold">{seasonContext.season.id}</div>
                  <div className="text-12 opacity-70 me-2">{seasonContext.season.name}</div>
                </div>
              }
              <div className="align-self-end d-flex flex-column justify-content-end align-self-center ms-auto"><Help onClick={() => setShowHelp(true)} /></div>
            </div>
          </div>


          <div className="d-flex flex-column gap-3">
          {showDisclaimer && <Disclaimer onClose={()=>closeDisclaimer()} />}

          {showPrize && <LeaguePrizes onClose={()=>{
            setShowPrize(false);
            localStorage.setItem(StorageKey.SEEN_LEAGUE_PRIZE, new Date().getTime().toString());
          }}/>}
          </div>

          {userStatsApi.apiState.isLoaded &&
            <LeagueRankings userStat={userStatsApi.data[0]} />
          }
        </div>
      </>
    )
  }

  if (userStatsApi.error) {
    return <ErrorMessage>{userStatsApi.error}</ErrorMessage>
  }

  return <ErrorMessage>Unknown stats</ErrorMessage>
}

const LeagueRankings: React.FunctionComponent<{ userStat: IUserAchievementUserStatResponse }> = ({ userStat }) => {
  const [leagueTab, setLeagueTab] = useState<LeagueTabEnum>(StreakLevels.levelsInfo(userStat ? userStat.points : 0).current.rank);
  return (
    <>
      <LevelProgress userStat={userStat} />
      <div className="text-16 fw-bold mb-4">Leagues</div>
      <LeagueTabs leagueTab={leagueTab} setLeagueTab={setLeagueTab} userStat={userStat} />
      <div className="pt-2"><LeagueUserList leagueTab={leagueTab} /></div>
    </>
  );
}