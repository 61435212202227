import { UrlHelper } from '_utils/UrlHelper';
import { AccountContext } from 'components/common'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';


const ConfirmReminder: React.FunctionComponent = () => {
  const acctContext = useContext(AccountContext);
  
  if (!!acctContext?.account.phoneNumberConfirmed) {
    return <></>
  }

  return (
    <div>
      To receive signals via SMS, please remember to confirm your phone number in your <Link className="text-white fw-bold" to={UrlHelper.getProfileUrl(acctContext?.account.id as number)}>Account Profile</Link>.
    </div>
  );
}


export const Done:React.FunctionComponent<{onClick: ()=>void}> = ({onClick}) => {




  return (
    <div className="signals-done">
      <div className="d-flex flex-column justify-content-center gap-5 align-items-center" style={{ minHeight: "37.5rem" }}>
        <div className="text-18 fw-bold">Trade Signals Inbound!</div>
        <div>
          You've successfully subscribed to signals!
        </div>

        <div style={{width: "154px", height: "163px"}}>
          <img src="/images/rocket.png" alt="Done!" width="100%" height="100%"/>
        </div>

        <ConfirmReminder />

        <div className="btn btn-secondary w-100" onClick={onClick}>Done</div>

      </div>

    </div>
  )
}
