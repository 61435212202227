import React from 'react';
import { Modal } from "react-bootstrap";
import { HelpLeveling } from './HelpLeveling';
import { RoundCloseButton } from 'components/common/buttons/RoundCloseButton';

export const ModalHelpLeveling: React.FunctionComponent<{ show: any; handleClose: any }> = ({ show, handleClose }) => {

  return (
    <Modal show={show} onHide={handleClose} size="sm" centered>
      <Modal.Body>
        <div>
          <div className="d-flex justify-content-end m-2"><RoundCloseButton onClick={() => handleClose()} /></div>
          <HelpLeveling />
        </div>
      </Modal.Body>
    </Modal>
  );
}