import { IbkrDataStructure } from "predictagram-lib";
import { useApi } from "./useApi";
import { adminApiServiceCommon } from "services/AdminApiService";

export function useAdminIbkrAccount () {
  const load = async () => {
    return await adminApiServiceCommon.getIbkrAccount();
  }
  return useApi<IbkrDataStructure, null>(load, null);
}
