import { useState } from 'react';
import { ModalHelp } from "components/public/Help/ModalHelp"
import { Help } from 'components/public/ListingsPage/components/Help';
import { PixelHelper } from '_utils/PixelHelper';

export function useModalHelp () {
  
  const [ showHelp, setShowHelp] = useState<boolean>(false);

  const helpIcon = () => {
    return <Help title="Help" onClick={() => {
      PixelHelper.clickHelp();
      setShowHelp(true);
    }}/>
  }

  const modalElement = () => {
    return <ModalHelp show={showHelp} handleClose={() => setShowHelp(false)} />
  }

  return {
    helpIcon,
    modalElement
  }
}