import React from 'react'
import { TopPerformerSymbol } from './TopPerformerSymbol'
import { PredictionTypeStats } from './PredictionTypeStats'
import { useUserStatsPredictionType } from '_hooks/useUserStatsPredictionType'
import { useOutletContext, useParams } from 'react-router-dom'
import { IPublicProfile } from 'interfaces/IPublicProfile'
import { Spinner } from 'react-bootstrap'
import { userPredictionApiService } from 'services/UserPredictionApiService'
import { useUserStatsStockSymbol } from '_hooks/useUserStatsStockSymbol'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { useNavigate } from "react-router";
import { SharePredictionTypeStats } from 'components/common/SharePredictionTypeStats'
import { UserStatsHelper } from '_utils/UserStatsHelper'
import { getRgb } from './ProgressBar'
import { UrlHelper } from '_utils/UrlHelper'

export const UserStatsPredictionType: React.FunctionComponent = () => {

  const { userId, profile, startTime, endTime } = useOutletContext<{ userId: number, profile: IPublicProfile, startTime: number, endTime: number }>()
  const { symbol } = useParams();
  const stats = useUserStatsPredictionType(userPredictionApiService, userId, symbol, startTime, endTime);
  const symbolStat = useUserStatsStockSymbol(userPredictionApiService, userId, symbol, startTime, endTime);
  const navigate = useNavigate();

  const BACKGROUND_OPACITY = 0.10;

  if (stats.error) {
    return <ErrorMessage>{stats.error}</ErrorMessage>
  }

  if (stats.apiState.isLoading()) {
    return <Spinner />
  }

  if (stats.apiState.isLoaded()) {
    return (
      <div className="user-stats-detail mx-2">
        <div role="button" className="btn-underline-hover my-3 text-14 d-flex gap-2 align-items-center" onClick={()=>navigate(UrlHelper.getUserStatSymbol(profile.userId || 0))}>&lt;- Back to Symbols</div>
        {symbolStat.apiState.isLoaded && symbolStat.items && symbolStat.items.length > 0 &&
          <div className="p-4 border-radius-15" style={{backgroundColor: getRgb(Math.round(symbolStat.items[0].scoreAvg * 100), BACKGROUND_OPACITY)}}>
            <TopPerformerSymbol symbol={symbolStat.items[0].stockSymbol} accuracy={symbolStat.items[0].scoreAvg} predictions={symbolStat.items[0].predictionsCompleted} />

            {symbolStat.apiState.isLoaded() && symbolStat.items && symbolStat.items.length > 0 &&
            <div className="mt-4">
              <div className="pill bg-charcoal mt-3 px-3 py-1 bg-black"><SharePredictionTypeStats username={profile.username} symbol={symbolStat.items[0].stockSymbol} userId={userId} /></div>
            </div>
            }
            </div>
        }

        <div className="mx-4">
          {(stats.items === null || stats.items.length === 0) ?
            <ErrorMessage className="my-3">No data</ErrorMessage>
            :
            <>
            <div className="fw-bold text-14 mt-4">Top Prediction Types</div>
            <div>
            <PredictionTypeStats items={UserStatsHelper.groupedPredictionTypes(stats.items)} />
            </div>
            </>
          }
        </div>

      </div>
    )
  }

  return <>Unexected Error</>

}
