import {OptionType} from "predictagram-lib";

export interface IParsedOption {
  action?: "buy" | "sell",
  quantity?: number,
  symbol: string,
  date: number,
  optionType: OptionType,
  price: number, // strike price
  executionPrice?: number,
}

export interface IOptionPattern {
  parse (optionName: string): IParsedOption,
  isValid (optionName: string): boolean,
}

export abstract class OptionPattern implements IOptionPattern{

  protected abstract pattern: RegExp

  abstract parse (optionName: string): IParsedOption;

  isValid (optionName: string) {
    return optionName.length > 0 && this.pattern.test(optionName);
  }
  
}

