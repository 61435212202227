import React from "react";
import {StockHelper} from "../../../../_utils/stock.helper";
import {Form, Formik} from "formik";
import {SymbolsDropdown} from "../../../common/SymbolsDropdown";
import ReactDatePicker from "react-datepicker";

export type SearchFilterOptions = {startTime:number, endTime:number, symbolName: string};
export const SearchFilter: React.FunctionComponent<{
  initialValues?: Partial<SearchFilterOptions>,
  onClick: (search:any)=>void,
  searchTitle?: string;
}> = ({ initialValues, onClick, searchTitle }) => {

  const stockDay = StockHelper.findTradingDay(new Date(), -1, false);
  const todayStart = stockDay.startAt();
  const todayEnd = stockDay.endAt();
  const defVals: SearchFilterOptions = {
    startTime: todayStart,
    endTime: todayEnd,
    symbolName: 'SPY',
  };
  const initVals: SearchFilterOptions = Object.assign(defVals, initialValues) as SearchFilterOptions;

  return (
    <div>
      <Formik initialValues={initVals} onSubmit={onClick}>
        {({ values, touched, errors, setFieldValue }) => {
          return <Form>
            <div className="d-flex justify-content-start align-items-center gap-3 my-3">
              <div className="d-flex flex-column gap-2">
                <SymbolsDropdown name="symbolName" errors={errors} touched={touched} />
              </div>
              <div className="d-flex flex-column">
                <span className="fw-bold">Start Date</span>
                <ReactDatePicker
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("startTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.startTime * 1000)}
                />
              </div>

              <div className="d-flex flex-column">
                <span className="fw-bold">End Date</span>
                <ReactDatePicker
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("endTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.endTime * 1000)}
                />
              </div>
              <div className="align-self-end">
                <button type="submit" className="btn btn-primary p-2">{searchTitle||'Search'}</button>
              </div>
            </div>

          </Form>
        }}

      </Formik>
    </div>
  )
}
