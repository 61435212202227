import React, { useEffect, useState } from 'react'

export const CircleProgress: React.FunctionComponent<{ percent: number, radius?: number, strokeWidth?: number}> = ({ percent, radius=80, strokeWidth=15 }) => {

  // const radius = 80;
  // const strokeWidth = 15;
  const diameter = radius * 2;
  const circumference = diameter * Math.PI;
  const progress = (percent / 100) * circumference;

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(circumference - progress);
  }, [progress, circumference]);

  return (
    <svg
      className="circle-progress-bar"
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${diameter + strokeWidth} ${diameter + strokeWidth}`}
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: 'red' }} />
          <stop offset="100%" style={{ stopColor: 'orange' }} />
        </linearGradient>
      </defs>

      <circle
        className="circle-progress-bar__trail"
        cx={radius + strokeWidth / 2}
        cy={radius + strokeWidth / 2}
        r={radius}
        strokeWidth={strokeWidth}
        stroke="#222222"
        fill="none"
      />
      <circle
        className="circle-progress-bar__path"
        cx={radius + strokeWidth / 2}
        cy={radius + strokeWidth / 2}
        r={radius}
        strokeWidth={strokeWidth}
        stroke="url(#gradient)"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform={`rotate(-90 ${radius + strokeWidth / 2} ${radius + strokeWidth / 2})`}
        fill="none"
      />
    </svg>
  );

}
