import React from 'react'
import { Spinner } from './Spinner'

export const LoadingMoreSpinner: React.FunctionComponent<{ loading: boolean }> = ({ loading }) => {
  return (
    <>
      {loading ?
        <div className="d-flex justify-content-center align-items-center gap-2 text-gray">Loading More...<Spinner minHeight={40} /></div>
        :
        <div></div>
      }
    </>
  )
}


