import React, { useContext } from 'react'
import { AccountContext } from 'components/common';
import { TradegramLockedContent } from '../tradegram/TradegramLocked';
import { TradesFeed } from './TradesFeed';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';

export const TradesFeedPage: React.FunctionComponent = () => {
  const acctContext = useContext(AccountContext);
  const navigate = useNavigate();
  const isBlocked = acctContext?.isLoggedIn() && !!!acctContext?.account.allowTradegram;
  if (isBlocked) {
    return (
      <div className="dashboard">
        <div className="tradegram-beta">

          <div className="mx-3 my-1 mx-md-3 my-md-5 d-flex gap-1 align-items-start flex-column">
            <div className="d-flex justify-content-start gap-3 ms-1 ms-md-3 my-3 my-md-1">
              <div className="opacity-70" role="button" onClick={() => navigate(Url.USER_DASHBOARD_PREDICTIONS)}>Predictions</div>
              <div className="fw-bold">Trades</div>
            </div>
          </div>

          <div className="ms-md-4">
          <TradegramLockedContent />
          </div>
        </div>
      </div>
    );
  }
  return (
    <TradesFeed />
  );
}