import {IParsedOption, OptionPattern} from "./OptionPattern";
import {OptionType} from "predictagram-lib";
import {TradegramDateHelper} from "./TradegramDateHelper";

export class OptionPatternAbbv extends OptionPattern {

  protected pattern = /^([A-Za-z]+)\s([a-zA-Z]{3}\s\d{1,2}\s'?\d{2,4})\s\$?(\d+(?:\.\d{1,2})?)\s(CALL|PUT|c|p)$/i; // TNA Aug 18 '23 $33 Call

  parse(optionName: string) {
    const match = optionName.match(this.pattern);
    if (!match) {
      throw new Error('Invalid option contract name format');
    }

    const [, symbol, dateString, price, optionType] = match;

    const parsedOption: IParsedOption = {
      symbol: symbol.toUpperCase(),
      date: TradegramDateHelper.convertMMMDDYYToDateUs(dateString).getTime() / 1000,
      optionType: optionType.toLocaleUpperCase().slice(0,1) === 'C' ? OptionType.CALL : OptionType.PUT,
      price: parseFloat(price)
    }
    return parsedOption;

  }
}
