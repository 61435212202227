import React, { useState } from "react";
import { BaseForm, FieldWithError, FileUpload } from "components/common";
import { validationMap, Validator } from "schemas";
import { adminAwardApiService } from "services/AdminApiService";
import { IDataField } from "interfaces";
import { IAward } from "interfaces/IAward";
import * as Icons from 'components/common/Icons';
import { userStorageService } from "services";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IAward) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

export const AdminAwardForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {

  const [imageObj, setImageObj] = useState<any>(null); // save the uploaded image in case of

  const beforeSubmit = async (data: any, actions: any) => {

    const { setSubmitting, setStatus } = actions;

    if (typeof data.image === 'object') {
      setImageObj(data.image);
    }

    if (data.image) {
      try {
        if (typeof data.image === "object") {
          data.image = await userStorageService.uploadImage(data.image);
        } else {
          data.image = await userStorageService.uploadImage(imageObj);
        }
      } catch (error: any) {
        setSubmitting(false);
        setStatus("Error uploading file");
        console.error({ error });
        throw Error(error.message);
      }
    } else {
      delete data.image;
    }

  };

  return (
    <BaseForm
      apiService={adminAwardApiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_BADGES_FORM)}
      editableFields={editableFields}
      beforeSubmit={beforeSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <>

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="name"
              placeholder="Badge Name"
              label="Badge Name" />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="description"
              placeholder="Description"
              label="Description"
            />

            <div className="form-group">
              <label>Image</label>
              <FileUpload
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                name="image-upload"
                targetField="image"
                touched={touched}
                initialUrl={values["imageUrl"]}
                buttonLabel={<Icons.PurplePlusCircle />}
              />
            </div>

          </>
        );
      }}
    </BaseForm>
  );
};


