import {
    Analysis,
    StockDirectionType as DirectionType,
    IQuote,
    IQuoteFull,
    LinePoints,
    Point,
    PointUpDown,
    PointUpDownRepeat,
    StockDirectionType as SignalDirectionType,
    SignalOptionsFull,
    SignalPatternInput,
    SignalPatternInputPred,
    SignalPrice,
    SignalRepeatType,
    SignalSetup
} from "../interface/stock";
import {LinePointsMinMax} from "./min-max.data";
import {SearchHelper} from "../utils/search.helper";
import {IStockDay} from "../interface";
import {SupResLine, SupResLinesData} from "./sup-res-lines.data";
import {StockHelper} from "../utils";
import {StockSignalsEnum, StockSignalsHelper, StockStatsIntervalEnum} from "../dict";
import {TaLibData, TaLibStockInput} from "./ta-lib.data";
import {CandlePatternsSignal} from "./signal/candle-patterns.signal";
import {StockPriceSignal} from "./signal/stock-price.signal";
import {PredictionSignal} from "./signal/prediction.signal";

type ComparePoint = (foundedPoint:Point)=>boolean;

interface StatsIntervalInput  {
    [StockStatsIntervalEnum.MIN_1]: {
        currPos: number,
        stock: Readonly<IQuoteFull[]>,
        taStats: TaLibStockInput,
    },
    [StockStatsIntervalEnum.MIN_5]: {
        currPos: number,
        stock: Readonly<IQuoteFull[]>,
        taStats: TaLibStockInput,
    }

}
export class SignalsData {

    /**
     * Calculate closest Y axis value for min/max for provided point
     * @param pointTime
     * @param minMax
     * @param preds
     */
    public static calcMinMaxIntersectionPoint(pointTime: number, minMax: LinePointsMinMax) {
        // let count = 0;
        // need to find point with latest ts up to pointTime
        // const steps = Math.round(minMax.stepSecs/60);
        const pointRounded = pointTime; // Math.round(pointTime/60)*60;// round just in case
        const res = SearchHelper.binarySearch(minMax.min.x, (a)=>pointRounded-a);
        const pos = res>=0 ? res: Math.abs(res)-1-1;
        // don't use old points, it can be multi-day query
        if (pos>=0 && minMax.min.x[pos]>=pointTime-minMax.rangeSecs) {
            const min = minMax.min.y[pos];
            const max = minMax.max.y[pos];
            // const bound = (max-min)*minMax.factor;
            return {
                minY: min,// - bound,
                maxY: max,// + bound
            }
        }

        return null;
    }

    protected static findStockYByX(stockLine:LinePoints, x:number) {
        let stockY = 0;
        for (let i = 0; i<stockLine.x.length; i++) {
            if (stockLine.x[i]===x) {
                stockY = stockLine.y[i];
                break;
            }
        }
        return stockY;
    }

    public static calcSignalsPredUpDownMinMax(preds: readonly Analysis.Prediction[], minMax: LinePointsMinMax): PointUpDown[] {
        const signals = [] as PointUpDown[];
        for (const p of preds) {
            const tPoint = {x: p.valueTime, y: p.value};
            const cumulPoint = this.calcMinMaxIntersectionPoint(tPoint.x, minMax);
            if (!cumulPoint) {
                continue;
            }

            const isUp = tPoint.y>=cumulPoint.maxY;
            const isDown = !isUp && tPoint.y<=cumulPoint.minY;
            if (isUp || isDown) {
                signals.push({
                    x: tPoint.x,
                    y: tPoint.y,
                    isSellSignal: isUp,
                });

                // console.debug({tPoint,stockPoint,isUp, up:checkPointIsOutside(stockPoint, cumulativeLineUp, up),cumulativeLineUp});
            }
        }
        return signals;
    }

    public static calcSignalsStockUpDownMinMax(stockLine:LinePoints, minMax: LinePointsMinMax): PointUpDown[] {
        const signals = [] as PointUpDown[];
        for (let i=0;i<stockLine.x.length;i++) {
            const tPoint = {x: stockLine.x[i], y: stockLine.y[i]};
            const cumulPoint = this.calcMinMaxIntersectionPoint(tPoint.x, minMax);
            if (!cumulPoint) {
                continue;
            }

            const isUp = tPoint.y>=cumulPoint.maxY;
            const isDown = !isUp && tPoint.y<=cumulPoint.minY;
            if (isUp || isDown) {
                signals.push({
                    x: tPoint.x,
                    y: tPoint.y,
                    isSellSignal: isUp,
                });

                // console.debug({tPoint,stockPoint,isUp, up:checkPointIsOutside(stockPoint, cumulativeLineUp, up),cumulativeLineUp});
            }
        }
        return signals;
    }

    public static calcSignalsStockUpDownMinMaxInside(stockLine:LinePoints, minMax: LinePointsMinMax): PointUpDown[] {
        const signals = [] as PointUpDown[];
        let prev:{upDir:boolean;downDir:boolean}|null = null;
        for (let i=0;i<stockLine.x.length;i++) {
            const stockPoint = {x: stockLine.x[i], y: stockLine.y[i]};
            const cumulPoint = this.calcMinMaxIntersectionPoint(stockPoint.x, minMax);
            if (!cumulPoint) {
                continue;
            }

            const upDir = stockPoint.y >= cumulPoint.maxY;
            const downDir = stockPoint.y<=cumulPoint.minY;

            if (prev) {
                // crossing UP the red lines(min line) - BULLISH
                if (!downDir && prev?.downDir) {
                    signals.push({x: stockPoint.x, y: stockPoint.y, isSellSignal: false});
                }
                if (!upDir && prev?.upDir) {
                    signals.push({x: stockPoint.x, y: stockPoint.y, isSellSignal: true});
                }

            }
            prev = {upDir: upDir, downDir: downDir};
        }
        return signals;
    }

    public static calcSignals(targetLine: LinePoints, stockLine:LinePoints, minMax: LinePointsMinMax): PointUpDown[] {

        const signals = [] as PointUpDown[];


        for (let i = 0; i<targetLine.x.length; i++) {
            const tPoint = {x:targetLine.x[i], y:targetLine.y[i]};



            const stockY = this.findStockYByX(stockLine, tPoint.x);

            const stockPoint = {x: tPoint.x, y: stockY};
            const cumulPoint = this.calcMinMaxIntersectionPoint(tPoint.x, minMax);
            if (!cumulPoint || !stockY) {
                continue;
            }
            // console.debug({cumulPoint,x:tPoint.x});

            const isUp = tPoint.y>=stockPoint.y && stockPoint.y>=cumulPoint.maxY;
            const isDown = !isUp && tPoint.y<=stockPoint.y && stockPoint.y<=cumulPoint.minY;

            // const up = (f:Point)=> tPoint.y > f.y;
            // const down = (f:Point)=> tPoint.y < f.y;
            // const upStock = (f:Point)=> stockPoint.y > f.y;
            // const downStock = (f:Point)=> stockPoint.y < f.y;
            //
            // const isUp = this.hasLinesIntersec(tPoint, [stockLine], up) && stockY>0 &&
            //              this.hasLinesIntersec(stockPoint, [minMax.max], upStock);
            // const isDown = !isUp &&
            //                this.hasLinesIntersec(tPoint, [stockLine], down) && stockY>0 &&
            //                this.hasLinesIntersec(stockPoint, [minMax.min], downStock);
            if (isUp || isDown) {
                signals.push({
                    x: tPoint.x,
                    y: tPoint.y,
                    isSellSignal: isUp,
                });

                // console.debug({tPoint,stockPoint,isUp, up:checkPointIsOutside(stockPoint, cumulativeLineUp, up),cumulativeLineUp});
            }

        }

        return signals;
    }


    public static calcSignalUpDownStock(stockLine: LinePoints, orLines: LinePoints[]) {

        const signals = [] as PointUpDown[];

        for(const l of orLines) {
            for (let i = 0; i<l.x.length; i++) {
                const tPoint = {x: l.x[i], y: l.y[i]};
                // const upStock = (f:Point)=> tPoint.y > f.y;
                // const downStock = (f:Point)=> tPoint.y < f.y;
                const stockY = this.findStockYByX(stockLine, tPoint.x);
                if (!stockY) {
                    continue;
                }

                const isUp = tPoint.y > stockY;
                const isDown = tPoint.y < stockY;
                // const isUp = this.hasLinesIntersec(tPoint, [stockLine], upStock);
                // const isDown = this.hasLinesIntersec(tPoint, [stockLine], downStock);
                if (isUp || isDown) {
                    signals.push({
                        x: tPoint.x,
                        y: tPoint.y,
                        isSellSignal: isUp,
                    });
                }
            }
        }

        return signals;
    }

    public static calcSignalCrossStock(stockLine: LinePoints, line: LinePoints) {

        const signals = [] as PointUpDown[];

        if (!stockLine.x.length || !line.x.length) {
            return signals;
        }

        const findClosestPrevPointIndex = (stockX: number)=>{
            let prevIndex = null;
            for (let i = 0;i<=line.x.length;i++) {
                if (line.x[i]<=stockX) {
                    prevIndex = i;
                } else {
                    break;
                }
            }
            return prevIndex;
        };

        let prevDir:SignalDirectionType = null as any;

        for (let i = 0; i<stockLine.x.length; i++) {
            const stockX = stockLine.x[i];
            const stockY = stockLine.y[i];
            const pointIndex = findClosestPrevPointIndex(stockX);
            if (!pointIndex) {
                continue;
            }
            const lineY = line.y[pointIndex];
            // const lineX = line.x[pointIndex];
            const dir = lineY >= stockY ? SignalDirectionType.UP : SignalDirectionType.DOWN;
            if (prevDir!==null && dir!==prevDir) {
                signals.push({
                    x: stockX,
                    y: stockY,
                    isSellSignal: dir===SignalDirectionType.UP,
                });
            }
            prevDir = dir;
        }

        return signals;
    }

    /**
     * Filter-out and adjust signals list  - preprocess based on income params
     * @param signals
     * @param params
     */
    public static signalsCalcAdjust(signals:PointUpDown[], params:{
        endTimeSecs:number, // latest unix sec in search/query range
        repeatSecs?:number, // primary-repeat calculation
        oppositeDirection?: boolean, // invert signal meaning
        repeatTypes?: SignalRepeatType[], // filter-out final result by type
        cancellationSecs?: number, // don't use signal if there is contradiction signal in last n secs
        directionTypes?: SignalDirectionType[],
    }) {
        let prevSig: PointUpDown = null as any;
        const newList:PointUpDownRepeat[] = [];
        for (let i = 0; i<signals.length; i++) {
            const s = signals[i];
            const sR = Object.assign({}, s ) as PointUpDownRepeat;
            sR.repeatType = !params?.repeatSecs || prevSig === null || s.x - prevSig.x > params.repeatSecs || prevSig.isSellSignal !== s.isSellSignal ?
                            SignalRepeatType.PRIMARY : SignalRepeatType.REPEAT;
            if (params?.oppositeDirection) {
                sR.isSellSignal = !s.isSellSignal;
            }
            let isOkToAdd = true;
            if (params?.repeatTypes?.length && !params.repeatTypes.includes(sR.repeatType)) {
                isOkToAdd = false;
                // newList.push(sR);
            }
            const direction = sR.isSellSignal?SignalDirectionType.DOWN:SignalDirectionType.UP;
            if (params?.directionTypes?.length && !params.directionTypes.includes(direction)) {
                isOkToAdd = false;
            }

            if (params?.cancellationSecs) {
                // Don't add signal if:
                //  - ahead/"future" is not available
                //  - there is same signal in future
                let nextSignal = null;
                for (let y=i+1;y<signals.length;y++) {
                    const nextS = signals[y];
                    // stop behind range
                    if (nextS.x> s.x + params?.cancellationSecs) {
                        break;
                    }
                    // pick up same type
                    if (nextS.isSellSignal!==s.isSellSignal) {
                        nextSignal = nextS;
                        break;
                    }
                }
                const futureIsUnclear = !nextSignal && s.x+params.cancellationSecs > params.endTimeSecs;
                if (nextSignal || futureIsUnclear) {
                    isOkToAdd = false;
                } else {
                    sR.x += params.cancellationSecs;
                    // reset price, because old one is incorrect anymore for current minute
                    sR.price = undefined;
                }
            }

            if (isOkToAdd) {
                newList.push(sR);
            }

            prevSig = s;
        }
        return newList as PointUpDownRepeat[];
    }

    public static calcYby2Points(x:number, p1: Point, p2:Point) {
        if (p2.x===p1.x) {
            throw new Error(`invalid points: ${JSON.stringify(p1)} ${JSON.stringify(p2)}`);
        }
        const slope = (p2.y-p1.y) / (p2.x-p1.x);
        const b = p1.y - slope * p1.x;
        return slope * x + b;
    }

    static hasLinesIntersec(p:Point, lines:LinePoints[], cmp:ComparePoint) {
        let c = 0;
        lines.forEach(l=> {
            if (this.checkPointIsOutside(p, l, cmp)) {
                c++;
            }
        });
        return c>0 && c===lines.length;
    }

    static checkPointIsOutside(p:Point, l:LinePoints, cond:ComparePoint){
        for (let i=0;i<l.x.length;i++) {
            const x = l.x[i];
            const y = l.y[i];
            if (x>=p.x) { // found crossing point on line
                if (x===p.x) {
                    return cond({x:x,y:y});
                } else {
                    // calc intersection using line equation, use prev point and current
                    if (i-1>=0) {
                        const pPrev = {y:l.y[i-1],x:l.x[i-1]};
                        const pNext = {x: x, y: y};
                        const yF = this.calcYby2Points(p.x, pPrev, pNext);

                        const res = cond({x:p.x, y:yF});
                        if (res) {
                            // console.debug({res, p, next: pNext, prev: pPrev, d: new Date(p.x * 1000), yF});
                        }
                        return res;
                    } else {
                        // can't be calculated, no prev point on chart
                    }
                }
                break;
            }

        }
        return false;
    };

    // @TODO: refactor calculations, speed it up
    static calcSupResStock1h(stock: readonly IQuoteFull[]) {
        const result = {
            signals: [] as PointUpDown[],
            lines: [] as SupResLine[],
        }
        if (stock.length<2) {
            return result;
        }

        let prevDaySupRes:SupResLine = null as any;
        for (let i = 0; i<stock.length;i++) {
            const sStart = stock[i];
            const currDay = StockHelper.getIfIsTradingDay(sStart.t) as IStockDay;
            if (!currDay) {
                throw new Error('invalid time: ' + sStart.t);
            }
            // let lastPos =  stock.length-1;

            const currDayData:IQuoteFull[] = [];
            for (let y = i; y < stock.length; y++) {
                const s = stock[y];
                // if behind  current day - stop loop and set to check next day
                if (s.t > currDay.endAt()) {
                    i = y;
                    // lastPos = y
                    break;
                }
                currDayData.push(s);

                if (y===stock.length-1) {
                    i = y;
                }
            }
            const currDayLines = SupResLinesData.hourlyLines(currDayData);
            if (prevDaySupRes) {
                currDayLines.unshift(prevDaySupRes);
            }

            const signal = (yPrev:number, yCurr: number, y: number) => {
                if (yPrev<y && yCurr>=y) {
                    return false;
                }
                if (yPrev>=y && yCurr<y) {
                    return true;
                }
                return null;
            }
            // calc signals for current day
            for (let di = 1;di<currDayData.length;di++) {
                const prev = currDayData[di-1];
                const curr = currDayData[di];
                for (const l of currDayLines) {
                    const lt = l.tLast as number;
                    if (prev.t>=lt && curr.t>=lt) {
                        [l.r1, l.r2, l.s1, l.s2].forEach(y => {
                            const res = signal(prev.c, curr.c, y);
                            if (res !== null) {
                                result.signals.push({isSellSignal: res, x: curr.t, y: y});
                            }
                        });
                    }
                }
            }
            // reset current day
            prevDaySupRes = SupResLinesData.calcSupResLineForPoints(currDayData) as SupResLine;
            result.lines.push(...currDayLines);
        }

        // for each day calc

        return result;
    }

    public static calcTrendLineRegression(stock: readonly IQuoteFull[]) {
        type Regression = {
            slope: number,
            intercept: number,
            y: (x:number)=>number,
        };
        const n = stock.length;
        type RSummary = {
            sumX: number,
            sumXY: number,
            sumXX: number,
            sumY: number,
            // sumYY: number,
        }
        const low: RSummary  = {
            sumX: 0,
            sumXX: 0,
            sumXY: 0,
            sumY: 0,
            // sumYY: 0,
        }
        const high: RSummary = Object.assign({}, low);
        const addData = (rs: RSummary, time:number, val: number) => {
            rs.sumX += time;
            rs.sumXX += (time*time);
            rs.sumXY += (time*val);
            rs.sumY += val;
            // rs.sumYY  = (val*val);
        };
        const calcFinal = (rs: RSummary) => {
            const divid = (n*rs.sumXX - rs.sumX*rs.sumX);
            const slope = (n*rs.sumXY-rs.sumX*rs.sumY) / divid;
            const intercept = (rs.sumY*rs.sumXX-rs.sumX*rs.sumXY) / divid;
            return {
                // Slope = (N * Σ(xy) - Σx * Σy) / (N * Σ(x^2) - (Σx)^2)
                slope: slope,
                intercept: intercept,
                y: (x)=>(slope*x+intercept)
            } as Regression;
        }
        let count = 0;
        for (const p of stock) {
            // const time = p.t;
            const time = count;
            addData(low, time, p.l);
            addData(high, time, p.h);
            count++;
        }

        return {
            high: calcFinal(high),
            low: calcFinal(low),
        }
    }

    // static calcSignals2hVd30(
    //         minMaxStatsAtr: MinMaxStatsAtr,
    //         stockStats: readonly IQuoteFull[],
    //         last3predsAvg: LinePoints
    // ) {
    //
    // }



    public static handleStockSetupResults(
            stats: StatsIntervalInput,
            signalResultPos: number,
            // stockStats1m: Readonly<IQuoteFull[]>,
            // stockStats5m: Readonly<IQuoteFull[]>,
            // stockTaLib1m: Readonly<TaLibStockInput>,
            // stockTaLib5m: Readonly<TaLibStockInput>,
            signalSetup: Readonly<SignalSetup>,
            setUpResults: (PointUpDown[])[]
    ) {
        if (!signalSetup?.signals?.length) {
            return;
        }

        for (let sgOutPos=0;sgOutPos<signalSetup?.signals?.length;sgOutPos++) {
            const sg = signalSetup.signals[sgOutPos];
            if (!sg.type) {
                continue;
            }
            const sInfo = StockSignalsHelper.info(sg.type);
            const st = stats[sInfo.interval as StockStatsIntervalEnum.MIN_5]; // hack for type conversion
            const currPos = st.currPos;
            const stockStats = st.stock;
            const taLibStats = st.taStats;
            const sCurr = stockStats[currPos];
            if (sInfo.isTaLib) {
                const result = TaLibData.execute({
                    name: sInfo.cleanName,
                    low: taLibStats.low,
                    high: taLibStats.high,
                    close: taLibStats.close,
                    open: taLibStats.open,
                    startIdx: currPos,
                    endIdx: currPos,
                });
                if (result.nbElement===1 && result.result.outInteger[0]!==0) {
                    setUpResults[sgOutPos].push({x: signalResultPos, y: sCurr.c, isSellSignal: result.result.outInteger[0]<0});
                }
            } else {
                const funcMap = new Map<StockSignalsEnum, (input:SignalPatternInput)=>null|SignalPrice>([
                    [StockSignalsEnum.SIGNALS_STOCK_GAP, StockPriceSignal.stockSignalGap],
                    [StockSignalsEnum.SIGNALS_PRICE_OPEN_RANGE_HIGH_LOW, StockPriceSignal.priceOpenRangeHighLow],
                    [StockSignalsEnum.SIGNALS_PRICE_PREV_DAY_HIGH_LOW, StockPriceSignal.pricePrevDayHighLow],
                    [StockSignalsEnum.CDL_CURR_PRICE_1STCDL_BODY, CandlePatternsSignal.currPrice1stCandleBody],
                    [StockSignalsEnum.M5_CDL_CURR_PRICE_1STCDL_BODY, CandlePatternsSignal.currPrice1stCandleBody],
                    [StockSignalsEnum.CDL_CURR_PRICE_1STCDL_BODY_2NDCDL_STEM, CandlePatternsSignal.currPrice1stCandleBody2ndCandleSteam],
                    [StockSignalsEnum.M5_CDL_CURR_PRICE_1STCDL_BODY_2NDCDL_STEM, CandlePatternsSignal.currPrice1stCandleBody2ndCandleSteam],
                    [StockSignalsEnum.CDL_CURR_PRICE_INTRPTCDL_BODY, CandlePatternsSignal.currPriceIntrptCandleBody],
                    [StockSignalsEnum.M5_CDL_CURR_PRICE_INTRPTCDL_BODY, CandlePatternsSignal.currPriceIntrptCandleBody],
                    [StockSignalsEnum.CDL_CURR_PRICE_INTRPTCDL_BODY_2NDCDL_STEM, CandlePatternsSignal.currPriceIntrptCandleBody2ndCandleSteam],
                    [StockSignalsEnum.M5_CDL_CURR_PRICE_INTRPTCDL_BODY_2NDCDL_STEM, CandlePatternsSignal.currPriceIntrptCandleBody2ndCandleSteam],
                    [StockSignalsEnum.CDL_CURR_PRICE_CDL_QUARTILE, CandlePatternsSignal.currPriceCandleSizeRate],
                    [StockSignalsEnum.M5_CDL_CURR_PRICE_CDL_QUARTILE, CandlePatternsSignal.currPriceCandleSizeRate],
                    [StockSignalsEnum.CDL_CONSECUTIVE_COLORS, CandlePatternsSignal.sameDirectionCandles],
                    [StockSignalsEnum.SIGNALS_PRICE_PREV_DAY_HIGH_LOW, StockPriceSignal.pricePrevDayHighLow],
                    [StockSignalsEnum.SIGNALS_PRICE_OPEN_RANGE_HIGH_LOW, StockPriceSignal.priceOpenRangeHighLow],
                    [StockSignalsEnum.SIGNALS_PRICE_PREV_RANGE_HIGH_LOW, StockPriceSignal.pricePrevRangeHighLow],
                    [StockSignalsEnum.SIGNALS_PREV_ClOSE_LINE_STOCK, StockPriceSignal.pricePrevCloseLine],
                    [StockSignalsEnum.SIGNALS_OPEN_LINE_STOCK, StockPriceSignal.priceOpenLine],
                    // [StockSignalsEnum.SIGNALS_SUP_RES_1H_STOCK_DIST, StockPriceSignal.supRes1hStockDist],

                    [StockSignalsEnum.CDL_OPEN_RANGE_HIGH_LOW, CandlePatternsSignal.currPriceOpenRangeHighLow],
                    [StockSignalsEnum.CDL_VWAP, CandlePatternsSignal.currPriceVwap],
                    [StockSignalsEnum.CDL_SMA120, CandlePatternsSignal.currPriceSma120],

                    [StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_160PCT, CandlePatternsSignal.currPriceMinMax2hVd30m160pct],
                    [StockSignalsEnum.CDL_MIN_2H_VD_30M_160PCT,     CandlePatternsSignal.currPriceMin2hVd30m160pct],
                    [StockSignalsEnum.CDL_MAX_2H_VD_30M_160PCT,     CandlePatternsSignal.currPriceMax2hVd30m160pct],

                    [StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_220PCT, CandlePatternsSignal.currPriceMinMax2hVd30m220pct],
                    [StockSignalsEnum.CDL_MIN_2H_VD_30M_220PCT,     CandlePatternsSignal.currPriceMin2hVd30m220pct],
                    [StockSignalsEnum.CDL_MAX_2H_VD_30M_220PCT,     CandlePatternsSignal.currPriceMax2hVd30m220pct],

                    [StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_400PCT, CandlePatternsSignal.currPriceMinMax2hVd30m400pct],
                    [StockSignalsEnum.CDL_MIN_2H_VD_30M_400PCT,     CandlePatternsSignal.currPriceMin2hVd30m400pct],
                    [StockSignalsEnum.CDL_MAX_2H_VD_30M_400PCT,     CandlePatternsSignal.currPriceMax2hVd30m400pct],

                    [StockSignalsEnum.CDL_PIVOT_POINT_STREND_CROSS,            CandlePatternsSignal.currPricePivotPointTrendCross],
                    [StockSignalsEnum.CDL_PIVOT_POINT_STREND_CROSS_PERSISTENT, CandlePatternsSignal.currPricePivotPointTrendCrossRemains],
                    [StockSignalsEnum.CDL_PIVOT_POINT_ATR_100PCT_MIN_CROSS, CandlePatternsSignal.currPricePivotPointAtr100PctMinCross],
                    [StockSignalsEnum.CDL_PIVOT_POINT_ATR_100PCT_MAX_CROSS, CandlePatternsSignal.currPricePivotPointAtr100PctMaxCross],


                    [StockSignalsEnum.CDL_SUP_RES_1H_STOCK, CandlePatternsSignal.currPriceSupRes1h],
                    [StockSignalsEnum.CDL_SUP_RES_1H_STOCK_DIST, CandlePatternsSignal.currPriceSupRes1hDist],
                    [StockSignalsEnum.CDL_SUP_RES_3D_STOCK_DIST, CandlePatternsSignal.currPriceSupRes3dDist],
                    [StockSignalsEnum.CDL_OPEN_PRICE, CandlePatternsSignal.currPriceOpenPrice],
                    [StockSignalsEnum.CDL_PREV_DAY_CLOSE_PRICE, CandlePatternsSignal.currPricePrevDayClosePrice],

                    [StockSignalsEnum.SIGNALS_EMA6_CANDLE_DAY, CandlePatternsSignal.ema6CdlDay],
                    [StockSignalsEnum.SIGNALS_EMA12_CANDLE_DAY, CandlePatternsSignal.ema12CdlDay],
                    [StockSignalsEnum.SIGNALS_EMA26_CANDLE_DAY, CandlePatternsSignal.ema26CdlDay],

                ]);
                // TODO: add somehow another kind of handling to throw error
                const func = funcMap.get(sg.type);
                if (!func) {
                    continue;
                }
                const res = func({currPos, stock:stockStats, options:sg.options as SignalOptionsFull});
                if (res) {
                    setUpResults[sgOutPos].push({
                        x: signalResultPos,
                        y: res.y||res.price,
                        price: res.price,
                        isSellSignal: res.dir===DirectionType.DOWN,
                    });
                }
            }
        }
    }

    static handlePredSetupResults(
        stats: readonly Analysis.Prediction[],
        currPos: number,
        signalSetup: Readonly<SignalSetup>,
        setUpResults: (PointUpDown[])[]
    ) {
        if (!signalSetup?.signals?.length) {
            return;
        }

        for (let sgOutPos=0;sgOutPos<signalSetup?.signals?.length;sgOutPos++) {
            const sg = signalSetup.signals[sgOutPos];
            if (!sg.type) {
                continue;
            }
            // const sInfo = StockSignalsHelper.info(sg.type);
            const funcMap = new Map<StockSignalsEnum, (input:SignalPatternInputPred)=>null|SignalPrice>([
                [StockSignalsEnum.PREDS_CONSENSUS, PredictionSignal.calcSignalsConsensusNPredMmin],
            ]);
            // TODO: add somehow another kind of handling to throw error
            const func = funcMap.get(sg.type);
            if (!func) {
                continue;
            }
            const res = func({currPos, predictions:stats, options:sg.options as SignalOptionsFull});
            if (res) {
                setUpResults[sgOutPos].push({
                    x: stats[currPos].valueTime,
                    y: stats[currPos].value,
                    price: res.price,
                    isSellSignal: res.dir===DirectionType.DOWN,
                });
            }
        }
    }
}
