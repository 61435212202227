

export enum SeasonEnum {
  SEASON_2024_Q1 = 'Season 1',
  SEASON_2024_Q2 = 'Season 2',
  SEASON_2024_Q3 = 'Season 3',
  SEASON_2024_Q4 = 'Season 4',

  SEASON_2025_Q1 = 'Season 5',
  SEASON_2025_Q2 = 'Season 6',
  SEASON_2025_Q3 = 'Season 7',
  SEASON_2025_Q4 = 'Season 8',
}


export interface ISeason {
  id: SeasonEnum,
  name: string,
  startDateSec: number,
  endDateSec: number
}

export class Season {

  public static readonly seasons: Record<SeasonEnum, ISeason> = {
    [SeasonEnum.SEASON_2024_Q1]: {id: SeasonEnum.SEASON_2024_Q1, name: 'Q1 2024', startDateSec: 1704085200, endDateSec: 1711943999},
    [SeasonEnum.SEASON_2024_Q2]: {id: SeasonEnum.SEASON_2024_Q2, name: 'Q2 2024', startDateSec: 1711944000, endDateSec: 1719806399},
    [SeasonEnum.SEASON_2024_Q3]: {id: SeasonEnum.SEASON_2024_Q3, name: 'Q3 2024', startDateSec: 1719806400, endDateSec: 1727755199},
    [SeasonEnum.SEASON_2024_Q4]: {id: SeasonEnum.SEASON_2024_Q4, name: 'Q4 2024', startDateSec: 1727755200, endDateSec: 1735707599},
    [SeasonEnum.SEASON_2025_Q1]: {id: SeasonEnum.SEASON_2025_Q1, name: 'Q1 2025', startDateSec: 1735707600, endDateSec: 1743479999},
    [SeasonEnum.SEASON_2025_Q2]: {id: SeasonEnum.SEASON_2025_Q2, name: 'Q2 2025', startDateSec: 1743480000, endDateSec: 1751342399},
    [SeasonEnum.SEASON_2025_Q3]: {id: SeasonEnum.SEASON_2025_Q3, name: 'Q3 2025', startDateSec: 1751342400, endDateSec: 1759291199},
    [SeasonEnum.SEASON_2025_Q4]: {id: SeasonEnum.SEASON_2025_Q4, name: 'Q4 2025', startDateSec: 1759291200, endDateSec: 1767243599},
  }

  public static getSeasonFromDate(date: Date): ISeason | null  {
    const dateInSec = date.getTime() / 1000;
    if (dateInSec < this.seasons[SeasonEnum.SEASON_2024_Q1].startDateSec || dateInSec > this.seasons[SeasonEnum.SEASON_2025_Q4].endDateSec)  {
      return null;
    }

    for (const season of Object.values(this.seasons)) {
      if (dateInSec >= season.startDateSec && dateInSec <= season.endDateSec) {
        return season;
      }
    }

    return null;
  }

}