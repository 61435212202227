import { APIState, ApiStateImpl, IApiState } from "_constants/APIState";
import { IQuote } from "components/public/Stock/IQuote";
import { useCallback, useEffect, useRef, useState } from "react";
import { PredictionService } from "services";


export function useLastQuote (service: PredictionService, stockSymbol: string, live: boolean = false) {

  const [ item, setItems ] = useState<IQuote>({} as IQuote);
  const [ state, setState ] = useState<IApiState>(ApiStateImpl.IDLE);

  const serviceRef = useRef(service);

  const load = useCallback(async ()=>{
    try {
      setState(ApiStateImpl.LOADING);
      if (live) {
        const items = await serviceRef.current.getLastQuoteLive(stockSymbol);
        setItems(items);
      } else {
        const items = await serviceRef.current.getLastQuote(stockSymbol);
        setItems(items);
      }
      setState(ApiStateImpl.LOADED);
    } catch (error) {
      setState(new ApiStateImpl(APIState.ERROR, error as Error));
    }
  }, [stockSymbol])

  useEffect(()=>{
   load();
  }, [load])

  return {item, state};

}