import React, { useContext, useState } from "react";
import { ListContainer } from "components/common";
import { FormButtons } from "_constants";
import { IDataField } from "interfaces";

import { IAward } from "interfaces/IAward";
import { adminAwardApiService } from "services/AdminApiService";
import { AdminAwardForm } from "./Form";
import { AwardTypeEnum } from "predictagram-lib";
import { AdminModalGrant } from "./ModalGrant";
import { MessengerContext, Severity } from "components/common/messenger";

export const AdminAwardList: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);

  const emptyValues = {
    name: '',
    description: '',
    image: '',
    typeId: AwardTypeEnum.BADGE,
  } as IAward

  const editableFields: IDataField[] = [
    { name: 'id', title: 'ID', editable: false },
    { name: 'name', title: 'Name', editable: true },
    { name: 'description', title: 'Description', editable: true },
    { name: 'image', title: 'Image', editable: true },
    { name: 'imageUrl', title: 'Image URL', editable: false },
  ];

  const getImage = (row: IAward) => <img src={row.imageUrl} alt={row.name} />

  const [showGrantAwardModal, setShowGrantAwardModal] = useState<boolean>(false);
  const [awardId, setAwardId] = useState<number | undefined>(undefined);


  const giveAward = (row: IAward) => <div role="button" className="btn btn-secondary" style={{ maxWidth: "150px" }} onClick={() => { setAwardId(row.id); setShowGrantAwardModal(true) }}>Grant User</div>

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['name', ['Name', undefined]],
    ['description', ['Description', undefined]],
    ['image', ['Image', getImage]],
    ['Users', ['Grant Award', giveAward]]
  ]);

  const onSave = async (userId: number) => {
    try {
      if (!awardId) {
        throw new Error('missing award id');
      }
      await adminAwardApiService.awardUser(awardId, userId);
      msgrContext.setMessage({ body: 'Granted award.' }, true);
    } catch (error: any) {
      msgrContext.setMessage({ body: `error occured: ${error.message}` }, true, Severity.FATAL);
    }
  }

  return (
    <>
      <AdminModalGrant handleClose={() => setShowGrantAwardModal(false)} show={showGrantAwardModal} onSave={onSave} />
      <ListContainer
        title="Badges"
        apiService={adminAwardApiService}
        emptyValues={emptyValues}
        fieldList={fieldList}
        buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
        editableFields={editableFields}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }) => {
          return (
            <AdminAwardForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick} editableFields={editableFields} />
          );
        }}
      </ListContainer>
    </>
  );
};


