import { IStockSymbol } from "interfaces/IStockSymbol";
import { useApi } from "./useApi";
import { predictionService } from "services";

export function useSymbols() {
  const load = async () => {
    return await predictionService.getSymbols();;
  }
  return useApi<IStockSymbol[], undefined>(load, undefined);
}
