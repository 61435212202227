import { APIState } from "_constants/APIState";
import { ITeam } from "interfaces/league";
import { useCallback, useEffect, useRef, useState } from "react";
import { UserLeagueApiService, UserLeagueApiServiceError } from "services/UserLeagueApiService";


export interface ITeamSearchOptions {
  startTime?: number, // in seconds
  endTime?: number, // in seconds
  leagueSeasonId?: number,
  minUserScore?: number,
}

export function useTeamPublic (service: UserLeagueApiService, searchOptions: ITeamSearchOptions) {

  const [ items, setItems ] = useState<ITeam[]>([]);
  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<UserLeagueApiServiceError | undefined>(undefined);
  const [ options, setOptions ] = useState<ITeamSearchOptions>(searchOptions);

  const serviceRef = useRef(service);

  const load = useCallback(async ()=>{
    try {
      setApiState(APIState.LOADING);
      const items = await serviceRef.current.getTeamsPublic(options);
      setItems(items);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.LOADED);
      setError(error as UserLeagueApiServiceError);
    }
  }, [serviceRef, options])

  useEffect(()=>{
   load();
  }, [load])

  return {teams: items, apiState, error, options, setOptions};

}