import React, { useEffect, useState } from "react";
import {SymbolIdsDropdown} from "../SymbolIdsDropdown";

export const MultiSymbolsDropDown: React.FunctionComponent<{ errors: any, touched: any, name: string, label?: string, enableByDefault?:boolean, className?: string,}> = ({ errors, touched, name, label='Broad Market Symbols', enableByDefault=true, className}) => {

  const [ enable, setEnabled] = useState<boolean>(enableByDefault);

  useEffect(()=>{
    setEnabled(enableByDefault);
  }, [enableByDefault])

  return (
    <>
      {true &&
       <>
         <label>
           <input type="checkbox" checked={enable} onChange={() => setEnabled(!enable)}/>
           <span className="mx-1">{label}</span>
         </label>
         {enable && <SymbolIdsDropdown
         className={className}
           errors={errors}
           label={''}
            showDefault={false}
           touched={touched}
           multiple={true}
           name={name}
          // onChange={(v:any)=>console.debug(v)}
         />
         }
       </>
      }
     </>
  );
};
