import { config } from "config";
import { HttpService } from "./HttpService";
import { AuthenticationService, userAuthenticationService } from "./AuthenticationService";


export interface ISurveyPayload {
  userEmail: string,
  surveyName: string,
  surveyData?: any,
}

class SurveyService {
  
  constructor(
    protected apiBaseUrl:string, 
    protected authenticationService: AuthenticationService,
    protected httpService: HttpService
  ) { }

  async save (payload: ISurveyPayload) {
    const url = `${apiBaseUrl}/survey`;
    const result = httpService.httpPost(url, payload, false);
    return result;
  }

}


const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const httpService = new HttpService(userAuthenticationService);
const surveyService = new SurveyService(apiBaseUrl, userAuthenticationService, httpService);

export { surveyService, SurveyService }