import { Url } from '_constants';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';


export const SignalsLayout: React.FunctionComponent = () => {

  const location = useLocation();
  const navigate = useNavigate();


  const pathMatches = (path: string) => {
    return location.pathname === path;
  }
  
  return (
    <div className="user-signals m-3">

      {/* menu tabs */}
      <div className="d-flex justify-content-start align-items-center gap-3">
        <div onClick={()=>navigate(Url.USER_DASHBOARD_SIGNALS_CHART)} role="button" className={`dashboard-tab ${pathMatches(Url.USER_DASHBOARD_SIGNALS_CHART) ? `dashboard-tab-selected-no-border` : ''}`}>Charts</div>
        <div onClick={()=>navigate(Url.USER_DASHBOARD_SIGNALS_SIGNALS)}  role="button" className={`dashboard-tab ${pathMatches(Url.USER_DASHBOARD_SIGNALS_SIGNALS) ? `dashboard-tab-selected-no-border` : ''}`}>Signals</div>
      </div>

      <hr />

      <Outlet />


    </div>

  )
}
