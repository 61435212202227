import React, { useContext } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { AccountContext } from 'components/common';
import { usePublicProfile } from '_hooks/usePublicProfile';
import { PredictorCard } from '../PredictorCard';
import { Url } from '_constants';
import { Spinner } from 'components/common/Spinner';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { useFollowers } from '_hooks/useFollowers';
import { useFollowed } from '_hooks/useFollowed';

const MenuTabs: React.FunctionComponent<{ userId: number }> = ({ userId }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const acctContext = useContext(AccountContext);

  let menu: Map<string, string> = new Map<string, string>();

  menu.set('Predictions', `${Url.PUBLIC_STOCK_PREDICTOR}/${userId}`);

  if (acctContext?.isLoggedIn() && !!acctContext?.account.allowTradegram) {
    menu.set('Trades', `${Url.PUBLIC_STOCK_PREDICTOR}/${userId}/positions`);
  }

  const pathMatches = (path: string) => {
    return location.pathname === path;
  }


  return (
    <div className="d-flex justify-content-start align-items-center gap-4">
      {Array.from(menu).map(([title, url], i) => <div key={`menu-tab-key${i}`}>
        <div role="button" onClick={() => navigate(url, { replace: false })} className={`text-16 ${pathMatches(url) ? "fw-bold" : ""}`}>{title}</div>
      </div>)}
    </div>
  );

}

export const PredictorLayout: React.FunctionComponent = () => {
  const { userId } = useParams();

  const followerApi = useFollowers();
  const followedApi = useFollowed();

  const accountContext = useContext(AccountContext);
  const user = usePublicProfile(userId ? parseInt(userId) : 0, userPredictionApiService, false);

  if (followedApi.apiState.isError ) {
    return <ErrorMessage>{followedApi.error}</ErrorMessage>
  }

  if (followerApi.apiState.isError ) {
    return <ErrorMessage>{followerApi.error}</ErrorMessage>
  }

  if (user.error) {
    return <ErrorMessage>Unexpected error {user.error.message}</ErrorMessage>
  }

  if (!user.loaded) {
    return <Spinner />
  }

  if (user.loaded) {
    return (
      <div>
        <div className="mx-3">
          {user &&
            <>
            <PredictorCard user={user.item} userId={userId ? parseInt(userId) : 0} loggedInUserId={accountContext?.account.id as number}/>
            </>
          }
        </div>
        <div className="w-100 border-bottom border-color-darkest-gray my-3 "></div>
        <div className="mx-3">
          <MenuTabs userId={user.item.userId as number} />
          <Outlet context={{ user: user.item, isMyProfile: user.item.userId === accountContext?.account.id }} />
        </div>
      </div>
    );
  }

  return <>Something went wrong...</>
}

