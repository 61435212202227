import { IEquityTradingFormParameters } from "components/admin/autotrade/TradingFormEquity";
import { IOptionTradingFormParameters } from "components/admin/autotrade/TradingFormOptions";
import {
  AutoTradeSecurityTypeEnum,
  IBKROrderType,
  IBKROrderTypeHelper,
  IBKRSide,
  IBKRSideHelper,
  OptionType
} from "predictagram-lib";

export class TradeSetupModel {
  public static optionType = new Map([
    [OptionType.CALL, 'Call'],
    [OptionType.PUT, 'Put'],
  ]);

  public static orderType = IBKROrderTypeHelper.names;

  public static ibkrSide = IBKRSideHelper.names;

  public static daysAway = new Map(
    Array(31).fill(null).map((_, i)=>[i, i.toString()])
  )
  

  public static optionsInitialValues: Partial<IOptionTradingFormParameters> = {
      // contracts: null,
      strikePriceOffset: 0,
      minExpirationDays: 2,
      maxExpirationDays: 6,
      tradeSecurityTypeId: AutoTradeSecurityTypeEnum.OPTION,
      ibkrSide: IBKRSide.BUY,
      ibkrOrderType: IBKROrderType.LMT_PB,
      optionType: OptionType.CALL,
  }


  public static equityInitialValues: Partial<IEquityTradingFormParameters> = {
    // contracts: null,
    // strikePriceOffset: null,
    tradeSecurityTypeId: AutoTradeSecurityTypeEnum.EQUITY,
    ibkrSide: IBKRSide.BUY,
    ibkrOrderType: IBKROrderType.MKT,
}


}
