import React from 'react'
import { Wizard } from './signals/Wizard'

export const SignalsWizardPage: React.FunctionComponent = () => {
  return (
    <div>

      <Wizard onClose={()=>{}} />

    </div>
  )
}
