import React from 'react';
import { Helper } from "_utils";
import { IRecentActivity } from "interfaces/IRecentActivity";


interface IProps {
  activity: IRecentActivity,
  icon: React.ReactNode,
  onClick: ()=>void,
  children: any
}

export const ActivityStat: React.FunctionComponent<IProps> = ({ activity, icon, onClick, children }) => {

  const activityTime = activity.items && activity.items.length > 0 ? activity.items[0].createdAt : undefined;

  return (
    <div className="activity" role="button" onClick={()=>onClick()}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="activity-icon">
          {icon}
        </div>
        {activityTime && <div className="text-end text-gray fst-italic text-12">
          {Helper.timeElapsed(new Date(activityTime * 1000), new Date())} ago
        </div>}
      </div>
      <div className="activity-detail text-14 text-white mt-1">
        {children}
      </div>
    </div>
  )
}
