import React from 'react'
import { CloseX } from 'components/common/Icons'


export const RoundCloseButton:React.FunctionComponent<{width?: number, height?: number, className?: string, onClick?: ()=>void}> = ({width=14, height=14, className, onClick}) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className={`btn-circle bg-transparent-light d-flex justify-content-center align-items-center ${className}`}><CloseX className="d-inline" width={width} height={height}/></div>
  )
}

