import React from "react";
import { BaseForm, FieldWithError } from "components/common";
import { IContest } from "interfaces/IContest";

import { validationMap, Validator } from "schemas";
import { adminContestApiService } from "services/AdminApiService";
import { IDataField } from "interfaces";
import DatePicker from "react-datepicker";
import { ContestTypeDropDown } from "./ContestTypeDropDown";
import { FieldArray } from "formik";
import { SymbolsDropdown } from "components/common/SymbolsDropdown";
import { PredictionTypesDropdown } from "components/common/PredictionsDropDown";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IContest) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

export const AdminContestForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {
  const beforeSubmit = async (data: any, actions: any) => {
    const nPredictionTypes = data.predictionTypes.map((i: any) => Number(i));
    data.predictionTypes = nPredictionTypes;
    if (data.prize === '') {
      data.prize = null;
    }
  };

  return (
    <BaseForm
      apiService={adminContestApiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_CONTEST_FORM)}
      editableFields={editableFields}
      beforeSubmit={beforeSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <div className="container-fluid g-0">
            <div className="row">
              <div className="col-12 col-md-6">
                <FieldWithError errors={errors} touched={touched} fieldName="name" placeholder="Contest Name" label="Contest Name" />

                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName="description"
                  placeholder="Description"
                  label="Description"
                />

                <ContestTypeDropDown name="typeId" errors={errors} touched={touched} label="Contest Type" />

                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName="prize"
                  placeholder="0.00"
                  label="Prize Amount (Optional)"
                />

                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName="minPredictions"
                  placeholder="# of Predictions"
                  label="Minimum Required Predictions"
                />

                <div className="d-flex flex-column align-items-start my-2">
                  <span>Start Date</span>
                  <DatePicker
                    showTimeSelect
                    dateFormat="MM/dd/yyyy hh:mm aa"
                    onChange={(date: Date) => {
                      setFieldValue("startTime", date.getTime() / 1000);
                    }}
                    selected={new Date(values.startTime * 1000)}
                  />
                </div>

                <div className="d-flex flex-column align-items-start my-2">
                  <span>End Date</span>
                  <DatePicker
                    showTimeSelect
                    dateFormat="MM/dd/yyyy hh:mm aa"
                    onChange={(date: Date) => {
                      setFieldValue("endTime", date.getTime() / 1000);
                    }}
                    selected={new Date(values.endTime * 1000)}
                  />
                </div>

                <div className="d-flex flex-column align-items-start my-2">
                  <span>Result Time</span>
                  <DatePicker
                    showTimeSelect
                    dateFormat="MM/dd/yyyy hh:mm aa"
                    onChange={(date: Date) => {
                      setFieldValue("resultTime", date.getTime() / 1000);
                    }}
                    selected={new Date(values.resultTime * 1000)}
                  />
                </div>

              </div>
              <div className="col-12 col-md-6">

                <div>
                  <FieldArray name="predictionTypes">
                    {({ insert, remove, push }: { insert: any, remove: any, push: any }) => (
                      <div>
                        {values && values.predictionTypes.length > 0 &&
                          values.predictionTypes.map((team: any, index: number) => (
                            <div className="d-flex justify-content-start align-items-center">
                              <PredictionTypesDropdown name={`predictionTypes.${index}`} errors={errors} touched={touched} />
                              <button type="button" className="btn btn-secondary align-self-end mx-3" onClick={() => remove(index)}>X</button>
                            </div>
                          ))
                        }
                        <div className="my-3">
                          <button type="button" className="btn btn-primary" onClick={() => push(null)}>Add Prediction Type</button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </div>

                <div>
                  <FieldArray name="stockSymbols">
                    {({ insert, remove, push }: { insert: any, remove: any, push: any }) => (
                      <div>
                        {values && values.stockSymbols.length > 0 &&
                          values.stockSymbols.map((team: any, index: number) => (
                            <div className="d-flex justify-content-start align-items-center">
                              <SymbolsDropdown name={`stockSymbols.${index}`} errors={errors} touched={touched} />
                              <button type="button" className="btn btn-secondary align-self-end mx-3" onClick={() => remove(index)}>X</button>
                            </div>
                          ))
                        }
                        <div className="my-3">
                          <button type="button" className="btn btn-primary" onClick={() => push(null)}>Add Symbol</button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
            </div>

          </div>
        );
      }}
    </BaseForm>
  );
};


