import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ITradegramSecurity } from 'services/TradegramApiService'
import { Trades } from './Trades'
import { Trade } from 'components/user/dashboard/Trade'
import { ShareTradegram } from 'components/common/ShareTradegram'
import { AccountContext } from 'components/common'
import { ModalWizard } from './ModalWizard'
import { UserInfoOverallProfit } from 'components/user/dashboard/UserInfoOverallProfit'
import { RoundCloseButton } from 'components/common/buttons/RoundCloseButton'
import { TradegramHelper, CardContext } from '_utils/TradegramHelper'
import { Disclaimer } from './Disclaimer'
import { Copy } from 'components/common/Icons'

interface IProps {
  show: any,
  handleClose: any,
  security: ITradegramSecurity,
  onSuccess: () => void,
}

export const ModalTrades: React.FunctionComponent<IProps> = ({ show, handleClose, security: s, onSuccess }) => {

  const acctContext = useContext(AccountContext);
  const showAddCloseButtons = acctContext?.account.id === s.userId;
  const [showTradeWizard, setShowTradeWizard] = useState<boolean>(false);
  const [security, setSecurity] = useState<ITradegramSecurity | undefined>(undefined);
  const [cardContext, setCardContext] = useState<CardContext | undefined>(undefined);

  const addPosition = (security: ITradegramSecurity) => {
    setSecurity(security);
    setCardContext(CardContext.ADD);
    setShowTradeWizard(true);
  }

  const closePosition = (security: ITradegramSecurity) => {
    setSecurity(security);
    setCardContext(CardContext.CLOSE);
    setShowTradeWizard(true);
  }

  const _onSuccess = () => {
    handleClose();
    onSuccess();
  }

  return (
    <Modal className="modal-trades" scrollable={true} show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="d-flex justify-content-center flex-column p-3">
        <ModalWizard show={showTradeWizard} handleClose={() => setShowTradeWizard(false)} cardContext={cardContext} onSuccess={_onSuccess} security={security} />

        <div className="align-self-end"><div role="button" onClick={handleClose}><RoundCloseButton /></div></div>
        <div className="d-flex justify-content-start align-items-center mb-1 mt-0">
          <UserInfoOverallProfit userId={s.userId} security={s} />
        </div>

        <Trade security={s} isWide={false} showUserProfile={false} showLastTrade={false} detailed={true}>
          <Trades security={s} onload={() => { }} />
        </Trade>

        {showAddCloseButtons ?
          <div className="d-flex gap-3 my-3 align-items-center justify-content-between">
            <div className="flex-shrink-0 d-flex gap-2">
              {TradegramHelper.getQuantityBalance(s) === 0 ?
                <div onClick={() => addPosition(s)} role="button" className="btn btn-rounded border-white text-white px-3 text-12 d-flex justify-content-center align-items-center fw-bold gap-2"><Copy />Copy</div>
                :
                <button className="btn btn-primary text-14" onClick={() => addPosition(s)}>Add</button>
              }

              {TradegramHelper.getQuantityBalance(s) !== 0 && <div><button className="btn border-white bg-white text-black text-14" onClick={() => closePosition(s)}>Close</button></div>}
            </div>

            <div className="btn btn-curved-side bg-charcoal text-white flex-grow-1 text-14">
              <ShareTradegram security={s} title="Share" />
            </div>

          </div>
          :
          <div className="btn btn-curved-side bg-charcoal text-white my-2 text-14">
            <ShareTradegram security={s} title="Share" />
          </div>
        }

        <Disclaimer />

      </Modal.Body>
    </Modal>
  )
}

