import { useApiSignalPlans } from '_hooks/useApiSignalPlans'
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import React from 'react'
import { SignalPlan } from './SignalPlan';
import { RoundCloseButton } from 'components/common/buttons/RoundCloseButton';
import { ISignalPlan } from 'services/UserSignalApiService';
import { IApiResponse } from '_hooks/useApi';



export const Signup: React.FunctionComponent<{ onContinue: ()=>void, onClose: ()=>void, api: IApiResponse<ISignalPlan[], undefined> }> = ({ onContinue, onClose, api }) => {

  const _onContinue = () => {
    
    onContinue();
  }

  const render = () => {
    if (api.apiState.isError) {
      return <ErrorMessage>{api.error}</ErrorMessage>
    }

    if (api.apiState.isLoading) {
      return <Spinner minHeight={20} />
    }

    if (api.apiState.isLoaded) {
      return (
        <div>
          {api.data.map((s, i) => <div key={`signals-key-${i}`}>
            <SignalPlan signalPlan={s} />
          </div>)}
        </div>

      );
    }

    return <></>
  }

  return (
    <div className="signals-signup">
      <div className="d-flex flex-column justify-content-between" style={{ minHeight: "37.5rem" }}>
        <div className="d-flex justify-content-between align-items-center">
        <div className="text-18 fw-bold">Sign up for Signals</div>
        <RoundCloseButton onClick={onClose} />
        </div>
        <div>
          {render()}
        </div>
        <div className="btn btn-secondary" onClick={_onContinue}>Continue</div>

      </div>

    </div>
  )
}
