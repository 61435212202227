import React, { useContext, useEffect } from 'react';
import { AccountContext } from 'components/common';

export const Referrals:React.FunctionComponent = () => {

  const accountContext = useContext(AccountContext);

  useEffect(()=>{
    const _load = async () => {
      await accountContext?.refreshAccount();
    }
    _load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="user-referrals container mb-5 mx-3">
      <div className="page-title mt-3">Your Referrals</div>
        <div className="row mt-3">
          <div className="col">Registrations:</div>
          <div className="col">{accountContext?.account.referrers}</div>
        </div>
    </div>
  );
}