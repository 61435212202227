
import React, { useContext } from 'react'
import { FieldWithError, StateField } from 'components/common'
import { ErrorMessage, Form, Formik, getIn } from 'formik'
import { IPaymentProfile } from 'services/PaymentService'
import { validationMap, Validator } from 'schemas'
import { MessengerContext } from 'components/common/messenger'
import { subscriptionService } from 'services/SubscriptionService'
import { RoundCloseButton } from 'components/common/buttons/RoundCloseButton'
import { ISignalPlan } from 'services/UserSignalApiService'
import { Helper } from '_utils'


export const Payment: React.FunctionComponent<{ onSuccess: () => void, onClose: ()=>void, plan: ISignalPlan }> = ({ onSuccess, onClose, plan }) => {

  const msgrContext = useContext(MessengerContext);

  const initialValues: IPaymentProfile = {
    creditCard: {
      cardNumber: '' as any,
      cardCode: '',
      expirationDate: '' as any,
      // cardNumber: 4007000000027,
      // cardCode: '123',
      // expirationDate: 202510,
    },
    billTo: {
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: 'us',
      phoneNumber: '' as any,
    }
  }

  const onSubmit = async (data: any, actions: any) => {
    try {
      await subscriptionService.subscribeAndPay(data as IPaymentProfile, 1);
      onSuccess();
    } catch (error: any) {
      console.error({error});
      msgrContext.setMessage({body: `Error occurred: ${error.message}`})
    }
  }

  return (
    <div className="signals-payment">
      <Formik initialValues={initialValues as any} onSubmit={onSubmit} validationSchema={validationMap.get(Validator.SIGNAL_PAYMENT_FORM)}>
        {({ values, errors, touched }) => {
          return (
            <div>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              <div className="d-flex justify-content-between">
                <div className="fw-bold text-18 mb-3">Enter your payment info</div>
                <RoundCloseButton onClick={onClose} />
              </div>
              <Form>
                <div className="d-flex flex-wrap justify-content-between">
                  <FieldWithError size={10} fieldName='billTo.firstName' errors={errors} touched={touched} label="First Name" />
                  <FieldWithError size={10} fieldName='billTo.lastName' errors={errors} touched={touched} label="Last Name" />
                </div>

                <FieldWithError fieldName='creditCard.cardNumber' errors={errors} touched={touched} label="Card Number" />
                <div className="d-flex flex-wrap justify-content-between">
                  <FieldWithError size={5} fieldName='creditCard.expirationDate' errors={errors} touched={touched} label="Expiration Date YYYYMM" placeholder="YYYYMM" />
                  <FieldWithError size={5} fieldName='creditCard.cardCode' errors={errors} touched={touched} label="CVV/CVC" />
                </div>

                {/* billind address  */}
                <div className="d-flex flex-column gap-1 p-2 rounded bg-charcoal no-hover mt-3">
                  <FieldWithError fieldName='billTo.address1' errors={errors} touched={touched} label="Address 1" />
                  <FieldWithError fieldName='billTo.address2' errors={errors} touched={touched} label="Address 2" />
                  <div className="d-flex flex-wrap justify-content-between gap-2">
                    <FieldWithError size={8} fieldName='billTo.city' errors={errors} touched={touched} label="City" />
                    <div className="form-group">
                      <div className={`bg-charcoal no-hover`} style={{border: "none"}}>
                        <label>State</label>
                        <StateField
                          name="billTo.state"
                          className={"form-control" + (getIn(errors,'billTo.state') && getIn(touched, 'billTo.state') ? " is-invalid" : "")}
                        />
                        {errors && <ErrorMessage name={'billTo.state'} component="div" className="invalid-feedback" />}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between flex-wrap gap-2">
                  <FieldWithError size={5} fieldName='billTo.zipcode' errors={errors} touched={touched} label="Zip Code" />
                  <FieldWithError size={6} fieldName='billTo.phoneNumber' errors={errors} touched={touched} label="Billing Phone Number" />
                  </div>
                  </div>

                {/* <FieldWithError fieldName='billTo.country' errors={errors} touched={touched} /> */}

                <button type="submit" className="btn btn-secondary px-3 py-2 w-100 text-14 mt-3">Make Payment of{Helper.formatCurrency(plan.price, 2)}</button>
              </Form>
            </div>
          )
        }}
      </Formik>

    </div>
  )
}
