import React from 'react'
import { ISignalPlan } from 'services/UserSignalApiService'

export const SignalPlan: React.FunctionComponent<{ signalPlan: ISignalPlan }> = ({ signalPlan }) => {
  return (
    <div className="signals-plan">
      <div className="rounded bg-lavender text-white d-flex flex-column gap-2 p-3 align-items-center">
        <div className="text-18 fw-bold">{signalPlan.name}</div>
        <div className="text-white text-16 d-flex gap-2">
          <span className="text-decoration-line-through">${signalPlan.priceOld.toFixed(2)}</span>
          <span className="">${signalPlan.price.toFixed(2)}</span>
        </div>
      </div>
    </div>
  )
}
