import {AnalysisScoreCounter} from "../interface";

export class AnalysisReportData {
    public static calcOverallNumbers(scores: AnalysisScoreCounter) {
        const amount = scores.profitAmountSum
                       + scores.lossAmountSum
                       + scores.atCloseAmountSum
                       + scores.atExitSignalAmountSum
                       + scores.atExitStepSizeAmountSum;
        return {
            overallAmount: amount,
        }
    }
}
