export enum AutoTradeStatusEnum {
    ACTIVE              = 1,
    PENDING_OPEN        = 2,
    CLOSED              = 3,
    PENDING_CLOSE       = 4,
    CANCELED            = 5,
    FAILED              = 6,
}

export class AutoTradeStatusEnumHelper {

    public static readonly PENDING_STATUSES = [AutoTradeStatusEnum.PENDING_OPEN, AutoTradeStatusEnum.PENDING_CLOSE];
    // public static readonly OPEN_STATUSES = [];
    public static readonly CLOSED_STATUSES = [AutoTradeStatusEnum.CLOSED, AutoTradeStatusEnum.CANCELED, AutoTradeStatusEnum.FAILED];

    static names: Map<AutoTradeStatusEnum, string> = new Map<AutoTradeStatusEnum, string>([
        [AutoTradeStatusEnum.ACTIVE, 'Active'],
        [AutoTradeStatusEnum.PENDING_OPEN, 'Pending Open'],
        [AutoTradeStatusEnum.PENDING_CLOSE, 'Pending Close'],
        [AutoTradeStatusEnum.CLOSED, 'Closed'],
        [AutoTradeStatusEnum.CANCELED, 'Canceled'],
        [AutoTradeStatusEnum.FAILED, 'Failed'],
    ])

    static isPending(statusId:AutoTradeStatusEnum) {
        return this.PENDING_STATUSES.includes(statusId);
    }

    static isOpen(statusId:AutoTradeStatusEnum) {
        return !this.CLOSED_STATUSES.includes(statusId);
    }
}
