import React, { useState } from 'react'
import { Validator, validationMap } from 'schemas';
import { Formik, Form } from 'formik';
import { FieldWithError } from 'components/common';
import { ModalSupportedFormats } from './ModalSupportedFormat';
import { Info } from 'components/common/Icons';

export const OptionShortCut: React.FunctionComponent<{ shortName: string, setShortName: (shortName: string) => void, onSet: ()=>void } > = ({ shortName, setShortName, onSet }) => {

  const initialValues = {
    shortName: shortName
  }

  const onSubmit = (data: any, actions: any) => {
    setShortName(data.shortName);
    onSet();
  }

  const [showSupported, setShowSupported] = useState<boolean>(false);

  return (
    <>
    <ModalSupportedFormats show={showSupported} handleClose={()=>setShowSupported(false)} />
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationMap.get(Validator.OPTION_SHORTCUT_FORM)}>
      {({ values, errors, touched, isSubmitting }) => {
        return <Form>
          <div className="container">
            <div className="d-flex flex-column justify-content-center align-items-center gap-3 my-2">
              <div className="text-14 fw-bold">Paste Option Shortcut</div>

              <div className="d-flex align-items-center gap-1">
                <Info />
                <div className="text-12 fw-bold text-lavender text-decoration-underline" role="button" onClick={()=>setShowSupported(true)}>
                  View Supported Shortcut Formats
                </div>
              </div>
              <div>
              <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="shortName"
                    maxLength={50}
                  />
              </div>
              <div>
                <button type="submit" disabled={isSubmitting} className="btn btn-secondary w-100 py-2 px-5">Continue</button>
              </div>
            </div>
          </div>
        </Form>
      }}
    </Formik>
    </>
  );

}