import { StorageKey } from "_constants";
import { ModalDiscountPromo } from "components/marketing/discount-promo/ModalDiscountPromo";
import { useState } from "react";

export function useDiscountPromo (initialValue: string | null) {

  const [ show, setShow ] = useState<boolean>(initialValue === null);

  const handleClose = () => {
    setShow(false);
    localStorage.setItem(StorageKey.SEEN_DISCOUNT_PROMO, '1');
  }

  const modalElement = () => {
    return <ModalDiscountPromo show={show} handleClose={handleClose} />
  }

  return {
    modalElement
  }
  
}