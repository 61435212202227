import React, { useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useRecentActivity } from '_hooks/useRecentActivity'
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import { IRecentActivity } from 'interfaces/IRecentActivity';
import { UserNotificationTypeEnum } from 'predictagram-lib';
import { Bell } from 'components/common/Icons';

import { NewFollower } from './NewFollower';
import { FollowedTrades } from './FollowedTrades';
import { FollowedPredictions } from './FollowedPredictions';
import { PredictionCompleted } from './PredictionCompleted';
import { Ticket } from './Ticket';
import { useLastFollowedPrediction } from '_hooks/useLastFollowedPrediction';
import { Helmet } from 'react-helmet';
import { AccountContext } from 'components/common';


export const RecentActivityPage: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const acctContext = useContext(AccountContext);

  useEffect(()=>{
    acctContext?.setSeenLast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const api = useRecentActivity(searchParams.get('test'));
  const apiLastFollowed = useLastFollowedPrediction();

  if (api.apiState.isLoading || apiLastFollowed.apiState.isLoading)  {
    return <Spinner />
  }

  if (api.apiState.isError) {
    return <ErrorMessage>{api.error}</ErrorMessage>
  }

  if (apiLastFollowed.apiState.isError) {
    return <ErrorMessage>{apiLastFollowed.error}</ErrorMessage>
  }


  const renderActivity = (activity: IRecentActivity): React.ReactNode => {
    switch (activity.typeId) {
      case UserNotificationTypeEnum.NEW_FOLLOWER:
        return <NewFollower activity={activity} />

      case UserNotificationTypeEnum.FOLLOWEE_NEW_SECURITY_TRANSACTION:
        return <FollowedTrades activity={activity} />

      case UserNotificationTypeEnum.FOLLOWEE_NEW_PREDICTION:
        return <FollowedPredictions activity={activity} />

      case UserNotificationTypeEnum.PREDICTION_COMPLETED:
        return <PredictionCompleted activity={activity} />

      case UserNotificationTypeEnum.ACHIEVEMENT_TICKET:
        return <Ticket activity={activity} />

      default:
        return <></>
    }
  }

  if (api.apiState.isLoaded && apiLastFollowed.apiState.isLoaded) {

    return (
      <>
      <Helmet>
        <title>Predictagram: Notifications</title>
      </Helmet>
      <div className="">
        <div className="d-flex justify-content-start gap-1 mb-4 fw-bold mx-3"><Bell /> Notifications</div>

        {api.data.length > 0 ?
          <div className="d-flex flex-column">
            {api.data.sort((a, b) => {
              if ((a.items === undefined || b.items === undefined) || (a.items === undefined && b.items === undefined)) {
                return 0;
              }

              if (a.typeId === UserNotificationTypeEnum.FOLLOWEE_NEW_PREDICTION) {
                return apiLastFollowed.data[0].createdAt as number < b.items[0].createdAt ? 1 : -1
              }

              if (b.typeId === UserNotificationTypeEnum.FOLLOWEE_NEW_PREDICTION) {
                return apiLastFollowed.data[0].createdAt as number > b.items[0].createdAt ? 1 : -1
              }

              return a.items[0].createdAt < b.items[0].createdAt ? 1 : -1
            }).map((activity, idx) => <div className="activity-row bg-hover-off-black" key={`activity-key-${idx}`}>
              <div className="p-3 pt-3">{renderActivity(activity)}</div>
            </div>
            )}
          </div>
          :
          <ErrorMessage>No notifications found.</ErrorMessage>
        }
      </div>
      </>
    )
  }
  return <ErrorMessage>Unexpected State</ErrorMessage>
}
