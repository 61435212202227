import { AdminBrokerTransactionsApiService, IBrokerTransaction } from "services/AdminBrokerTransactionsApiService";
import { useApi } from "./useApi";
import { config } from "config";
import { adminAuthenticationService } from "services";
import { AbortableHttpService } from "services/AbortableHttpService";
import { BrokerTransactionVerificationStatusEnum } from "_constants/BrokerTransactionVerificationStatusEnum";

const apiUrlBase = `${config.apiDomain}${config.apiBasePath}/admin`;
const httpService = new AbortableHttpService(adminAuthenticationService);
export const adminBrokerTransactionsApiService = new AdminBrokerTransactionsApiService(apiUrlBase, adminAuthenticationService, httpService);

export type SearchFilter = {
  brokerTransactionVerificationStatusId: BrokerTransactionVerificationStatusEnum,
  usernameLike?:string,
  symbolNameLike?:string,
};
export function useAdminBrokerTransactions (f:SearchFilter) {

  const load = async (filter:SearchFilter, abortSignal?: AbortSignal) => {
    return await adminBrokerTransactionsApiService.getTransactions(filter, abortSignal) as any;
  };
  return useApi<(IBrokerTransaction&{username:string})[], SearchFilter>(load, f);
}

