import React, {useEffect, useState} from 'react'
import {Form, Formik} from "formik";
import {defaultCumeSearchOptions, ICumeDataSearchOptions} from '../../common/helper';
import {StrategyFilterOptions} from '../strategy-profit/Filter';

import {CumulativeFormDateRange} from '../strategy-profit/CumulativeForm';
import {Checkbox} from './LinesAndOptions';
import {
  Analysis,
  CumulativeChartLines,
  CumulativeChartLines as ChartLines,
  DateHelper,
  LinePoints,
  MinMaxStatsAtr
} from 'predictagram-lib';
import {ExpandContract} from 'components/common/ExpandContract';
import {ChartLineBase} from "../../common/CumulativeChart";
import {SymbolIdsDropdown} from "../../../common/SymbolIdsDropdown";
import {adminApiServiceCommon} from "../../../../services/AdminApiService";
import {PredictionChart} from "../../../public/ListingsPage/components/Prediction/PredictionChart";
import {IChart} from "../../../../interfaces/chart/IChart";
import {ChartModel as ChartOptions, PlotCandleType} from "../../../../models/chart.model";
import * as Plotly from "plotly.js";
import {StockHelper} from "../../../../_utils/stock.helper";
import CumulativeChartDataDay = Analysis.CumulativeChartDataDay;

interface ChartLineEx extends ChartLineBase {
  name: string;
  get:(d:Analysis.CumulativeChartDataDay)=>LinePoints|PlotCandleType,
  hoverinfo?: 'text'|'info',
}
export interface ChartLineSetup {
  lines: ChartLineEx[],
  isRender: boolean,
  type: ChartLines,
  // color: string;
  // isRender: boolean;
}

const end = StockHelper.findTradingDay(new Date(), -1, false);
const defSearch = {
  startTime: end.prevNext(-60).openAt(),
  endTime: end.closeAt(),
  stockSymbolId: defaultCumeSearchOptions.stockSymbolId,
} as StrategyFilterOptions;
export const AdminCumeDetailPageDay: React.FunctionComponent = () => {

  const getDefaultCumeOptions = () => {
    return defSearch;
  }
  const [cumulData, setCumulData] = useState<Analysis.CumulativeChartDataDay>();
  const [chartData, setChartData] = useState<IChart | undefined>(undefined);

  const createLineSetup = (lineType:ChartLines, linesIn: Partial<ChartLineEx>[]):ChartLineSetup=>{
    const lines = linesIn.map((l)=>{
      return Object.assign({name:lineType, color: '#577b6d', mode:'lines', get: ()=>undefined}, l) as ChartLineEx;
    })
    return {
      type: lineType, isRender:false,
      lines:lines,
    }
  }
  const stockLine = ()=>{
    const getStockLine = (d:CumulativeChartDataDay)=> {
      const line: LinePoints = {x: [], y: []};
      d.stats.stock.forEach((q)=>{
        line.x.push(q.t);
        line.y.push(q.c);
      })
      return line;
    };
    const l = createLineSetup('stock1d' as any, [{color:ChartOptions.plotLineColor,get: getStockLine,hoverinfo: 'text'}]);
    l.isRender = false;
    return l;
  }
  const stockCandle = ()=>{
    const getStockLine = (d:CumulativeChartDataDay)=> {
      const data = {
        x: [],
        close: [],
        high: [],
        low: [],
        open: [],
        volume: [],
      } as PlotCandleType;

      d.stats.stock.forEach((q)=>{
        data.x.push(q.t);
        data.close.push(q.c);
        data.high.push(q.h);
        data.low.push(q.l);
        data.open.push(q.o);
        data.volume.push(q.v);
      })
      return data;
    };
    const l = createLineSetup('stock1dCdl' as any, [{color:ChartOptions.plotLineColor,get: getStockLine, hoverinfo: 'text'}]);
    l.isRender = true;
    return l;
  }

  const minMaxLines = (atrType:ChartLines, points:number,atrMinMax: (d:CumulativeChartDataDay)=>MinMaxStatsAtr)=>{
    return   createLineSetup(atrType, [

      {name:`max${points}pVd1d4000pct`,color:'#fe9595',get:(d)=>atrMinMax(d).pct4000.max},
      {name:`max${points}pVd1d2000pct`,color:'#45983b',get:(d)=>atrMinMax(d).pct2000.max},
      {name:`max${points}pVd1d1000pct`,color:'#b17272',get:(d)=>atrMinMax(d).pct1000.max},
      {name:`max${points}pVd1d400pct`,color:'#416f3b',get:(d)=>atrMinMax(d).pct400.max},
      {name:`max${points}pVd1d220pct`,color:'#c58787',get:(d)=>atrMinMax(d).pct220.max},
      {name:`max${points}pVd1d160pct`,color:'#6ec223',get:(d)=>atrMinMax(d).pct160.max},

      {name:`min${points}pVd1d160pct`,color:'#ea9fd1',get:(d)=>atrMinMax(d).pct160.min},
      {name:`min${points}pVd1d220pct`,color:'#7da478',get:(d)=>atrMinMax(d).pct220.min},
      {name:`min${points}pVd1d400pct`,color:'#813b3b',get:(d)=>atrMinMax(d).pct400.min},
      {name:`min${points}pVd1d1000pct`,color:'#6c9367',get:(d)=>atrMinMax(d).pct1000.min},
      {name:`min${points}pVd1d2000pct`,color:'#e24e4e',get:(d)=>atrMinMax(d).pct2000.min},
      {name:`min${points}pVd1d4000pct`,color:'#5edf4f',get:(d)=>atrMinMax(d).pct4000.min},

    ]);
  }

  const [lines, setLines] = useState<ChartLineSetup[]>([
    stockLine(),
    stockCandle(),
    createLineSetup(ChartLines.VWAP, [{color:'#1f60bb',get: (d)=>d.cumulativeData.cumulStats.vwap}]),
    createLineSetup(ChartLines.EMA6, [{color:'#ccec76',get: (d)=>d.cumulativeData.cumulStats.ema6}]),
    createLineSetup(ChartLines.EMA12, [{color:'#536917',get: (d)=>d.cumulativeData.cumulStats.ema12}]),
    createLineSetup(ChartLines.EMA26, [{color:'#bb1f2f',get: (d)=>d.cumulativeData.cumulStats.ema26}]),
    createLineSetup(ChartLines.EMA120, [{color:'#6f2c33',get: (d)=>d.cumulativeData.cumulStats.ema120}]),
    createLineSetup(ChartLines.SMA120, [{color:'#79bbe3',get: (d)=>d.cumulativeData.cumulStats.sma120}]),

    minMaxLines(ChartLines.MIN_MAX_120P_ATR, 120, (d)=>d.cumulativeData.minMaxStatsAtr),
    minMaxLines(ChartLines.MIN_MAX_50P_ATR, 50, (d)=>d.cumulativeData.minMaxStatsAtr50p),
    createLineSetup(ChartLines.SUP_RES_LINES, []), // @TODO: it has custom render, difficult to integrate
  ]);

  const [cumeSearchOptions, setCumeSearchOptions] = useState<ICumeDataSearchOptions>(getDefaultCumeOptions() as any);
  const [initialValues, setInitialValues] = useState<StrategyFilterOptions>(defSearch);
  const [loading, setLoading] = useState<boolean>(false);



  const load = async () => {
    try {
      setLoading(true);
      // @TODO: refactor, need to send all params from input object
      // BUT the object includes entire analysis, with elements from strategy page
      const cumulData = await adminApiServiceCommon.analysisCumulativeChartDataDay({
        startTime: cumeSearchOptions.startTime,
        endTime: cumeSearchOptions.endTime,
        stockSymbolId: cumeSearchOptions.stockSymbolId,
      } as Analysis.InputParams);


      setCumulData(cumulData);

    } catch (error: any) {
      setLoading(false);
      // msgrContext.setMessage({body: `${error.message}`})
    }
  }

  useEffect(()=>{
    load();
  }, [cumeSearchOptions]);

  const onSubmit = (data: StrategyFilterOptions&Analysis.InputParamsEx) => {
    setCumeSearchOptions(Object.assign({},data) as any);
  }

  useEffect(() => {
    const data = cumulData as CumulativeChartDataDay;
    if (!data) {
      return;
    }

    const d = ChartOptions.prebuildChartDay({
      stats: data.stats.stock,
      endFillTimeSec: cumeSearchOptions.endTime as number,
      chartWidth: 400,
      xAxisGapInSeconds: Number.MAX_VALUE,
    });
    //const cData = ChartOptions.plotData('stock1d', d.plotLines.during, ChartOptions.plotLineColor);

    const chartLines = lines.filter(s=>s.isRender).flatMap((s)=>{
      return s.lines.map(v=>{
        let plotData = v.get(data) as any;
        if (!plotData) {
          return { x: [], y: []};
        }
        // create new copy and convert times
        plotData = JSON.parse(JSON.stringify(plotData));
        plotData.x = (plotData?.x||[]).map((v:number)=>new Date(v*1000));
        if ('volume' in plotData) {
           const d = plotData as PlotCandleType;
           const l = ChartOptions.plotCandleData(v.name, d);
           if (v.hoverinfo) {
             // @ts-ignore
             l.hoverinfo = v.hoverinfo;
           }
           ChartOptions.setCustomPlotCandleInfo(l,(v)=>DateHelper.dateFormatUtc(v as any));
           return l;
        } else {
          const d = plotData as LinePoints;
          const l = { x: d.x, y: d.y, mode: v.mode, name: v.name, line: { width: 1, dash: v.lineMode }, marker: { size: v?.markerSize || 4, color: v.color }, hoverinfo: v.hoverinfo||'none' } as Plotly.PlotData;
          ChartOptions.setCustomPlotStockInfo(l, (v)=>DateHelper.dateFormatUtc(v as any));
          return l;
        }
      });
    });

    let yMin = data.cumulativeData.minPrice*0.95;
    let yMax = data.cumulativeData.maxPrice*1.05;

    // console.debug({cData});
    const chart: IChart = ChartOptions.dailyChartTemplate({
      tickVals: [0], tickText: undefined, formatStr: ChartOptions.formatPriceStr(yMax),
      yMin: yMin, yMax: yMax,
      plotData: [
        // cData,
        ...chartLines
      ],
      rangeBreaks: d.chartDataPlotlyXGaps, rangeBreaksLines: d.chartDatePlotlyXGapsLines
    }) as IChart;

    const d3 = 86400*3;
    const range = [
      new Date((data.cumulativeData.minTime-d3)*1000||0) as any,
      new Date((data.cumulativeData.maxTime+d3)*1000||0) as any,
    ];
    chart.layout.xaxis = {
      type: 'date',
      showgrid: false,
      tickmode: "auto", /*range: [xMin, xMax],*/
      // tickvals: p.tickVals,
      // ticktext: p.tickText,
      // tick0: new Date(cumulData?.stats.stock[0]?.t*1000) as any,
      dtick: 1,
      // autorange: true,
      range: range,
      rangeslider: {visible: false}, // hide bottom range slider
    };


    const stock = cumulData?.stats.stock;
    if (lines.findIndex(s=>s.isRender&&s.type===CumulativeChartLines.SUP_RES_LINES)>=0 && stock) {
      console.debug({shape:chart.layout.shapes});
      const end = stock[stock.length-1];
      for (const l of cumulData?.cumulativeData.cumulStats.supResLinesDays) {
        [l.r1, l.s1, l.r2, l.s2].forEach(v => {
          chart.layout.shapes.push({ // curr value horizontal line
            type: 'line', x0: new Date(l.tLast*1000), y0: v, x1: new Date(end.t*1000), y1: v,
            line: {color: '#7873B066', width: 2, dash: 'dash'}
          } as Partial<Plotly.Shape>);
        });
      }
    }

    ChartOptions.setCumulativeChartOpts(chart, 1);
    setChartData(chart);

  }, [cumulData, lines]);

  return (<div className="strategy-profit">

    <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit}>
      {({ values, touched, errors, setFieldValue }) => {
        return <Form>
          <div className="d-flex flex-row gap-1 align-items-start">
            <ExpandContract title="Chart Options">
              <div className="d-flex flex-column">
                {lines.map((v,i)=>{
                  return (
                    <Checkbox name={v.type} label={v.type} checked={v.isRender} key={`line-${i}`} onChange={(key, value)=>{
                      lines[i].isRender = value;
                      setLines([...lines]);
                    }} />
                  )
                })}
              </div>
            </ExpandContract>

            <div className="d-flex flex-column gap-1">
            </div>

            <div className="d-flex flex-column">
              <SymbolIdsDropdown name="stockSymbolId" errors={errors} touched={touched} />
              <CumulativeFormDateRange
                setFieldValue={setFieldValue}
                values={values} />
              <div className="d-flex align-self-end mt-2 gap-1">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </div>
          </div>

        </Form>
      }}
    </Formik>

    {chartData &&
     <div style={{ display: 'block', clear: 'both', }}>
       <div><PredictionChart chartData={chartData} /></div>
     </div>
    }
  </div>)
}
