import { useLastQuote } from "_hooks/useLastQuote";
import { QuoteAsOf } from "components/public/Stock/QuoteAsOf";
import { predictionService } from "services";

export const LastQuote: React.FunctionComponent<{ symbol: string }> = ({ symbol }) => {
  const lastQuote = useLastQuote(predictionService, symbol);
  if (lastQuote.state.isLoading()) {
    return <div className="text-12 shimmer" style={{ width: "200px", height: "15px", background: "#111111"}}>{"           "}</div>
  }

  if (lastQuote.state.isError()) {
    return <div className="text-12">Could not get quote.</div>
  }

  return (
    <div className="text-12"><QuoteAsOf quote={lastQuote.item} /></div>
  );
}