import { IUserTicketResponse } from 'interfaces/IUserTicket'
import React from 'react'
import { CircleProgress } from './CircleProgress'
import { TicketSvg } from './TicketSvg'

export const TicketProgress: React.FunctionComponent<{ ticket: IUserTicketResponse, radius?: number, strokeWidth?: number }> = ({ ticket, radius=80, strokeWidth=15 }) => {
  return (
    <div style={{ display: "inline" }}>
      <div style={{ position: "relative", display: "inline" }} >
        <CircleProgress
          strokeWidth={strokeWidth}
          radius={radius}
          percent={ticket.predictionsCompleted / ticket.predictionsCompletedThreshold * 100} />
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <TicketSvg width={radius / 0.9}/>
        </div>
      </div>
    </div>

  )
}
