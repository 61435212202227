import React from 'react'
import { DropdownFilter, IDropdownFilterItem } from 'components/common/DropDownFilter/DropdownFilter'
import { PredictionTypeEnum, PredictionTypeHelper } from 'predictagram-lib';

import * as Icons from 'components/common/Icons';
import { IconLabel } from '../IconLabel';
import { useDashboardSettings } from '_hooks/useDashboardSettings';
import { StorageKey } from '_constants';


export enum PredictionTypeFilterEnum {
  ALL = 1,
  PRICE_MOVE = 2,
  HIGH_LOW = 3,
  MULTI_DAY = 4,
}

export type PredictionTypeDropDownItemType = IDropdownFilterItem<PredictionTypeFilterEnum, PredictionTypeEnum[]>;

export const predictionTypeDropDownItemsMap: Map<PredictionTypeFilterEnum, PredictionTypeDropDownItemType> = new Map<PredictionTypeFilterEnum, PredictionTypeDropDownItemType>(
  [
    [PredictionTypeFilterEnum.ALL, { key: PredictionTypeFilterEnum.ALL, value: PredictionTypeHelper.allList(), label: <IconLabel icon={<Icons.ChartAll />} label='All' /> }],
    [PredictionTypeFilterEnum.PRICE_MOVE, { key: PredictionTypeFilterEnum.PRICE_MOVE, value: PredictionTypeHelper.getPriceMove(), label: <IconLabel icon={<Icons.ChartPriceMove />} label='Price Move' /> }],
    [PredictionTypeFilterEnum.HIGH_LOW, { key: PredictionTypeFilterEnum.HIGH_LOW, value: PredictionTypeHelper.getHighLow().filter(t=> t !== PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D), label: <IconLabel icon={<Icons.ChartHighLow />} label='High / Low' /> }],
    [PredictionTypeFilterEnum.MULTI_DAY, { key: PredictionTypeFilterEnum.MULTI_DAY, value: [PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D], label: <IconLabel icon={<Icons.ChartPriceMultiDay />} label='Multi-Day' /> }],  ]
);

export const predictionTypeDropDownItems: PredictionTypeDropDownItemType[] = Array.from(predictionTypeDropDownItemsMap).map((item) => item[1]);

export const getPredictionTypeDropDownItems  = (predictionTypeFilter: PredictionTypeFilterEnum) => {
  return predictionTypeDropDownItemsMap.get(predictionTypeFilter) || predictionTypeDropDownItems[0];
}

interface IProps {
  initialSelectedKey: PredictionTypeFilterEnum,
  setValue: (p: PredictionTypeFilterEnum) => void,
  className?: string,
}

export const PredictionTypeDropDownFilter:React.FunctionComponent<IProps> = ({initialSelectedKey, setValue, className}) => {

  const [ dashboardSettings, setDashboardSettings] = useDashboardSettings(localStorage, StorageKey.DASHBOARD_SETTINGS);

  const _setValue = (p: PredictionTypeFilterEnum) => {
    setDashboardSettings({
      ...dashboardSettings,
      predictionTypeFilter: p
    })
    setValue(p);
  }

  return (
    <DropdownFilter<PredictionTypeFilterEnum, PredictionTypeEnum[]>
        className={className}
        items={predictionTypeDropDownItems}
        initialSelectedKey={initialSelectedKey} onSelect={(item)=>_setValue(item)}/>
  )
}
