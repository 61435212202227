import React, { useContext } from 'react';
import { Url } from '_constants';
import { MessengerContext, Severity } from 'components/common/messenger';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { userLeagueApiService } from 'services/UserLeagueApiService';
import { IProfileTeam, IPublicProfile } from 'interfaces/IPublicProfile';


const TeamPrompt: React.FunctionComponent<{ team: IProfileTeam, handleClose: any }> = ({ team, handleClose }) => {
  const navigate = useNavigate();
  const msgrContext = useContext(MessengerContext);

  const onClickJoin = async (team: IProfileTeam) => {
    if (team) {
      try {
        await userLeagueApiService.joinTeam(team.id);
        //msgrContext.setMessage({ body: `Thank you for joining the team!` }, true, Severity.NORMAL);
        handleClose();
      } catch (error: any) {
        const errBody = error.message;
        if (String(errBody).includes('is already belongs to a team')) {
          msgrContext.setMessage({ body: "You already belong to another team." }, true, Severity.FATAL);
        } else {
          msgrContext.setMessage({ body: `${error.message}` }, true, Severity.FATAL);
        }
      }

    }
  }

  return (
    <div className="team-prompt p-3">
      <div className="team-prompt-head d-flex justify-content-center align-items-center gap-3 mx-3">
        <div className="team-prompt-head-image"><img src={team.logoUrl} alt={team.name} /></div>
        <div className="team-prompt-head-text">
          <div className="fw-bold text-25 text-left">There's more to win</div>
          <div className="text-16 my-2 text-left">Join {team.name} and make more predictions for a chance to win $500</div>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center my-3 gap-3">
        <button className="btn bg-charcoal text-16 text-white" onClick={() => navigate(Url.LEADERBOARD_TEAMS)}><span className="my-2">View all teams</span></button>
        <button className="btn btn-primary text-16" onClick={() => onClickJoin(team)}><span className="my-2">Join {team.name}</span></button>
      </div>

      <div className="text-center">
      <div role="link" className="cursor-pointer text-decoration-underline text-14 text-lighter-charcoal fw-bold" onClick={()=>handleClose()}>I'm not interested in joining a team</div>
      </div>

    </div>
  )
}


const NoTeamPrompt: React.FunctionComponent<{ handleClose: any }> = ({ handleClose }) => {
  const navigate = useNavigate();

  return (
    <div className="my-3 mx-3 d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center my-3">
        <div className='fw-bold text-25'>There's more to win</div>
        <div className="text-16 my-2 text-center">Join a team and make more predictions for a chance to win $500</div>
      </div>

      <div className="my-3">
        <button className="btn bg-charcoal text-white text-16" onClick={()=>{
          handleClose();
          navigate(Url.LEADERBOARD_TEAMS);
        }}>View all teams</button>
      </div>

      <div className="my-3">
        <div role="link" className="cursor-pointer text-decoration-underline text-14 text-lighter-charcoal fw-bold" 
          onClick={()=>handleClose()}>I'm not interested in joining a team</div>
      </div>
    </div>
  )
}

export const TheresMoreModal: React.FunctionComponent<{ referrerProfile?: IPublicProfile, team: IProfileTeam, show: boolean, handleClose: any }> = ({ referrerProfile, team, show, handleClose }) => {


  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        {!team && referrerProfile?.team ? <TeamPrompt team={referrerProfile.team} handleClose={handleClose}/> : <NoTeamPrompt handleClose={handleClose} />}
      </Modal.Body>
    </Modal>

  );

}