export enum APIState {
  IDLE = 0,
  LOADING = 1,
  LOADED = 2,
  ERROR = 3
}

export interface IApiState {
  isIdle():boolean;
  isLoaded():boolean;
  isError():boolean;
  isLoading():boolean;
  error():Error|null;
}


export class ApiStateImpl implements IApiState {

  public static IDLE = new ApiStateImpl(APIState.IDLE);
  public static LOADING = new ApiStateImpl(APIState.LOADING);
  public static LOADED = new ApiStateImpl(APIState.LOADED);
  public static error(e:any) {
    return new ApiStateImpl(APIState.ERROR, e);
  }

  public constructor(protected st:APIState, protected err:Error|null=null) {

  }

  error() {
    return this.err;
  }

  isError() {
    return this.st===APIState.ERROR;
  }

  isIdle() {
    return this.st===APIState.IDLE;
  }

  isLoaded() {
    return this.st===APIState.LOADED;
  }

  isLoading() {
    return this.st===APIState.LOADING;
  }

  state() {
    return this.st;
  }
}
