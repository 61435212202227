import React, { useEffect, useContext, useState, useCallback, useRef } from 'react';
import { APIState } from '_constants/APIState';
import { usePublicPrediction } from '_hooks/usePublicPrediction';
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect';
import { PredictionListLazy } from 'components/public/ListingsPage/components/PredictionListLazy';
import { userPredictionApiService, ISearchOptions } from 'services/UserPredictionApiService';
import { Spinner } from 'components/common/Spinner';
import { useOutletContext } from 'react-router-dom';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { AccountContext, ScrollToTop } from 'components/common';
import { ModalSignupLogin } from './ModalSignUpLogin';
import { AvatarById } from 'components/common/AvatarById';
import { AvatarSizeEnum } from 'components/common/Avatar';
import { Helmet } from 'react-helmet';

export const PredictorPredictionsPage = () => {

  const { user, isMyProfile } = useOutletContext<{ user: IPublicProfile, isMyProfile: boolean }>();
  const { items: predictions, apiState, options, setOptions, setSort, error, refresh } = usePublicPrediction(userPredictionApiService, { userId: user.userId, orderBy: sortTypeEnum.LATEST } as ISearchOptions);
  const acctContext = useContext(AccountContext);
  const [showLogin, setShowLogin] = useState<boolean>(acctContext?.isLoggedIn() === false);

  const optionsRef = useRef(options);
  const setOptionsRef = useRef(setOptions);

  useEffect(()=>{
    const options = optionsRef.current;
    const setOptions = setOptionsRef.current;
    
    const opt: ISearchOptions = {
      ...options,
      userId: user.userId
    }
    setOptions(opt);
  },[optionsRef, setOptionsRef, user.userId]);

  const isApiState = (s: APIState): boolean => {
    return s === apiState;
  }

  if (!isApiState(APIState.LOADED)) {
    return <Spinner />
  }

  if (isApiState(APIState.ERROR)) {
    return <div className="alert alert-critical">Something went wrong: Error {error?.message}</div>
  }

  const preds = acctContext?.isLoggedIn() ? predictions : predictions.filter((p, i) => i < 3); // limit when anon

  return (
    <>
      <ScrollToTop />
      {!!isMyProfile ?
        <Helmet>
          <title>Predictagram: My Profile</title>
          <meta property="og:title" content={`Predictagram: My Profile`} />
        </Helmet>
        :
        <Helmet>
          <title>Predictagram: User Profile - {user.username}</title>
          <meta property="og:title" content={`Predictagram: ${user.username}'s Profile`} />
        </Helmet>
      }

      {user.userId &&
        <ModalSignupLogin
          show={showLogin}
          tagLine={<>See exclusive stock market predictions by <span className="fw-bold">{user.username}</span> and other top traders.</>}
          handleClose={() => setShowLogin(false)}
          head={<div className="followee-card-image align-self-center my-3 d-flex justify-content-center align-items-center">
            <AvatarById userId={user.userId} size={AvatarSizeEnum.XXL} />
          </div>}
        />
      }
      <div className="my-3"><PredictionListLazy showTitle={false} showHelpIcon={false} predictions={preds} showProfile={true} onSelectSort={setSort} orderBy={options?.orderBy || sortTypeEnum.LATEST} /></div>
    </>
  )
}

