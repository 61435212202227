
import { BrokerTransactionVerificationStatusEnum } from "_constants/BrokerTransactionVerificationStatusEnum";
import { AbortableHttpService } from "./AbortableHttpService";
import { AuthenticationService } from "./AuthenticationService";
import {BrokerSecurityStatusEnum, BrokerSecurityTypeEnum, BrokerTransactionTypeEnum} from "predictagram-lib";


export interface IAdminBrokerSecurity {
  id: number,
  typeId: BrokerSecurityTypeEnum,
  statusId: BrokerSecurityStatusEnum,
  userId: number,
  stockSymbolOption: string,
  stockSymbol: string,
}

export interface IBrokerTransaction {
  id: number,
  typeId: BrokerTransactionTypeEnum,
  verificationStatusId: BrokerTransactionVerificationStatusEnum,
  description: string,
  quantity: number,
  price: number,
  stockPrice: number,
  stockSymbolName: string,
  stockSymbolOption: string,
  securityId: number,
  userId: number,
  securityTypeId: BrokerSecurityTypeEnum,
  createdAt: number
}

export class AdminBrokerTransactionsApiService {

  constructor(
    protected apiBaseUrl:string, 
    protected authenticationService: AuthenticationService,
    protected httpService: AbortableHttpService,
  ) {}

  async getTransactions(params?: {
    brokerTransactionVerificationStatusId?: BrokerTransactionVerificationStatusEnum,
    brokerSecurityId?:number,
    userId?: number,
  },
                        abortSignal?: AbortSignal): Promise<IBrokerTransaction[]>{
    const url = `${this.apiBaseUrl}/broker/transactions`;
    const payload = params ? {filters: params} : {}
    return await this.httpService.getMany(url, payload, false, abortSignal)
  }

  // async getPendingTransactions(abortSignal?: AbortSignal): Promise<IBrokerTransaction[]> {
  //   const url = `${this.apiBaseUrl}/broker/transactions`;
  //   return await this.httpService.getMany(url, {}, false, abortSignal)
  // }

  async updateVerificationStatus(transactionid: number, status: BrokerTransactionVerificationStatusEnum, abortSignal?: AbortSignal): Promise<{success: boolean}> {
    const url = `${this.apiBaseUrl}/broker/transaction/${transactionid}`;
    const payload = {
      verificationStatusId: status
    }
    return await this.httpService.put(url, payload, abortSignal )

  }

  async getSecurity(securityId:number): Promise<IAdminBrokerSecurity> {
    const url = `${this.apiBaseUrl}/broker/security/${securityId}`;
    return await this.httpService.getSingle(url,  false, undefined, true);
  }

  async updateSecurityBulk(securityId: number, data:any) {
    const url = `${this.apiBaseUrl}/broker/security/bulk-update/${securityId}`;
    return await this.httpService.put(url, data, undefined, false);
  }
}
