import { APIState } from '_constants/APIState';
import { useSeasons } from '_hooks/useSeasons'
import { SeasonHelper } from '_utils/SeasonHelper';
import { LeftArrow } from 'components/common/Icons';
import { ILeagueSeason } from 'interfaces/league';
import React, { useState } from 'react'
import { userLeagueApiService } from 'services/UserLeagueApiService';


interface IProps {
  onSelect: (season: ILeagueSeason) => void,
  initialValue?: ILeagueSeason,
}

export const SeasonFilter: React.FunctionComponent<IProps> = ({ onSelect, initialValue }) => {

  const { seasons, apiState, error } = useSeasons(userLeagueApiService);
  const [selected, setSelected] = useState<ILeagueSeason | undefined>(initialValue);
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const onClick = (season: ILeagueSeason) => {
    setCollapsed(true);
    onSelect(season);
    setSelected(season);
  }

  if (apiState === APIState.LOADING) {
    return <>Loading...</>
  }

  if (apiState === APIState.ERROR) {
    return <>Error: {error?.message}</>
  }

  const isCurrent = (season: ILeagueSeason, date: Date = new Date()) => {
    if (SeasonHelper.isDuringSeason(new Date(), season)) {
      return "(current)";
    }
    return "";
  }

  return (
    <div role="button" className="season-filter py-1 px-3">
        <div role="button" onClick={() => setCollapsed(!collapsed)} className="season-filter-selected d-flex justify-content-center gap-3">
          <div>{selected?.name || `Select Season`} {selected && isCurrent(selected)}</div>
          <div className="season-filter-arrow"><LeftArrow /></div>
        </div>

      {!collapsed && 
        <div className="season-filter-items">
          {
            seasons.sort((a, b) => a.startTime < b.startTime ? 1 : -1).slice(0,2).map((s: ILeagueSeason, key: number) => <div key={`season-filter-key-${key}`}>
              <div role="button" onClick={() => onClick(s)} className={`my-2 fw-normal season-filter-item ${s.id === selected?.id ? 'season-filter-item-selected' : ''}`}>{s.name} {isCurrent(s)}</div>
            </div>)
          }
        </div>
      }
    </div>
  )
}
