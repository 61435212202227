import React from "react";
import { useNavigate } from "react-router-dom";
import { Url } from "_constants";

const Footer: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid g-0 footer p-4">

      <div className="row mt-1 mt-md-3">
        <div className="col-12 col-md-8">
          <div className="footer-logo-image m-auto mx-md-0 mb-2">
            <img src="/images/gray-logo.png" alt="Predictagram" />
          </div>
        </div>

        <div className="col-12 col-md-4 m-auto">
          <div className="d-flex flex-column flex-md-row gap-3 justify-content-end">
            <div role="button" className="text-white text-smaller text-center text-md-end" onClick={() => navigate(Url.USER_TERMS)}>Terms and Conditions</div>
            <div role="button" className="text-white text-smaller text-center text-md-end" onClick={() => navigate(Url.USER_PRIVACY_POLICY)}>Privacy Policy</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-center text-md-start">

          <div className="text-smaller text-lighter-charcoal mt-3">&copy; {new Date().getFullYear()} Predictagram.com</div>

          <div className="text-smaller text-lighter-charcoal mt-3">
            17 Reaville Ave #1006
            Flemington, NJ 08822
            United States
          </div>
          <div className="text-smaller text-lighter-charcoal mt-3">
            contact@predictagram.com
          </div>
          <div className="text-smaller text-lighter-charcoal mt-3">
            Disclaimer: The Content is to be used for informational and entertainment purposes only and the Service does not provide investment advice for any individual.
            Trading in such securities can result in immediate and substantial losses of the capital invested. You should only invest risk capital, and not capital required for other purposes. See full <a href={Url.USER_TERMS} className="text-lighter-charcoal" target="_blank" rel="noreferrer">Terms and Conditions</a>.
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
