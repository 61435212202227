import React from 'react'
import { Helmet } from 'react-helmet';
import { Terms } from './Terms';

export const TermsPage: React.FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>Predictagram: Terms and Conditions</title>
      </Helmet>
      <Terms />
    </>
  )
}
