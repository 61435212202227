import React, { useEffect, useState } from 'react'
import { usePublicPrediction } from '_hooks/usePublicPrediction';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { sortTypeEnum } from '../ListingsPage/components/Prediction/SortTypeSelect';
import { StockHelper } from 'predictagram-lib';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { Helper } from '_utils';
import { AvatarLink, UserLink } from 'components/user/dashboard/UserInfo';
import { AvatarSizeEnum } from 'components/common/Avatar';

interface IProps {
  stockSymbol: string
}

export const SymbolPredictor: React.FunctionComponent<{ profile: IPublicProfile }> = ({ profile }) => {

  return (
    <div className="symbol-predictors-item">
      <div className="symbol-predictors-item-image">
        {profile.userId &&
        <AvatarLink profile={profile} size={AvatarSizeEnum.MEDIUM}/>
        }
      </div>
    </div>
  );
}

export const SymbolPredictors: React.FunctionComponent<IProps> = ({ stockSymbol }) => {

  const LIMIT = 3

  const { items: predictions, /* apiState, setSort, options */ } = usePublicPrediction(userPredictionApiService, {
    orderBy: sortTypeEnum.LATEST,
    symbolNames: [stockSymbol.toUpperCase()],
    startTime: StockHelper.workingHours(StockHelper.findPreviousTradingDay(StockHelper.findPreviousTradingDay(new Date()))).start.getTime() / 1_000,
    endTime: StockHelper.workingHours().end.getTime() / 1_000,
  } as ISearchOptions);


  const [users, setUsers] = useState<IPublicProfile[] | undefined>(undefined);
  const [uniqueIds, setUniqueIds] = useState<Number[] | undefined>(undefined);

  useEffect(() => {

    const _load = async () => {

      if (predictions.length > 0) {

        // get unique names
        const uniqueIds = predictions.map(p => Number(p.userId)).filter((v: number, index: number, arr: number[]) => arr.indexOf(v) === index);

        const d = [];
        const limit = uniqueIds.length > LIMIT ? LIMIT : uniqueIds.length;
        for (let i: number = 0; i < limit; i++) {
          d.push(userPredictionApiService.getPublicProfile(Number(uniqueIds[i]), true));
        }
        const results = await Promise.all(d);
        setUniqueIds(uniqueIds);
        setUsers(results);
      }
    }

    _load();

  }, [predictions])


  return (
    <div className="symbol-predictors d-flex flex-column justify-content-center gap-2">
      <div className="d-flex justify-content-start gap-2">
        {users && users.map((p: IPublicProfile, i: number) => <div key={`sp-key-${i}`}>
          <SymbolPredictor profile={p} />
        </div>
        )}
      </div>

      {users &&
        <div className="text-12">
          Recent predictions from <span className="fw-bold">{users.map((p: IPublicProfile, i: number) => <span key={`link-key${i}`}>
            {p.userId && <><UserLink id={p.userId} username={p.username}/>
            <span>{i !== users.length - 1 && <>, </>}</span>
            </>}
          </span> )}</span>
          {uniqueIds && uniqueIds?.length > LIMIT &&
            <> and <span className="fw-bold">{uniqueIds.length - LIMIT} other top {Helper.pluralize('predictor', uniqueIds.length - LIMIT)}</span></>
          }
        </div>
      }


    </div>

  )
}

