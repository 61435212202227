import React from 'react'
import { Modal } from 'react-bootstrap';
import { TicketPage } from '../tickets/TicketPage';
import { RoundCloseButton } from 'components/common/buttons/RoundCloseButton';


export const ModalTickets: React.FunctionComponent<{
  show: boolean,
  handleClose: any,
}> = ({ show, handleClose }) => {

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <div className="d-flex flex-column mt-3 me-2">
          <RoundCloseButton onClick={handleClose} className="align-self-end"/>
          <TicketPage />
        </div>
      </Modal.Body>
    </Modal>
  )
}

