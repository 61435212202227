import {Analysis, IQuoteFull, LinePoints, PointUpDown} from "../interface";
import {MathEx} from "../utils";
import {StockSignalsEnum} from "../dict";
import {LinePointsMinMaxAtr, MinMaxStatsAtr, MinMaxStatsAtrWithSignals} from "./min-max.data";

interface MinMaxValue {
    min: number, max: number
}


export class MinMaxAtrData {
    protected minMax = MinMaxAtrData.emptyMinMaxAtr(this.points);

    public constructor(protected points=120) {

    }

    public calcAtr(currPos: number, stats: readonly IQuoteFull[]) {
        const minMax = this.minMax;
        const posCurrQuote = stats[currPos];
        const curPosTime = posCurrQuote.t;

        // @TODO: might be possible to use "moving" aggregation to reduce loop count
        // const ok = (i:number)=>{
        //     return stats[i].t > posCurrQuote.t - 3600 * 2;
        // }
        // @NOTE: it was by time before, but it's not flexible for different intervals
        const ok = (i:number)=>{
            return currPos-i<this.points; // 120 points - 2h for 1m intervals
        }
        let sumLower100 = 0;
        let sumUpper100 = 0;

        let sumLower160 = 0;
        let sumUpper160 = 0;

        let sumLower220 = 0;
        let sumUpper220 = 0;

        let sumLower400 = 0;
        let sumUpper400 = 0;

        let sumLower1000 = 0;
        let sumUpper1000 = 0;

        let sumLower2000 = 0;
        let sumUpper2000 = 0;

        let sumLower4000 = 0;
        let sumUpper4000 = 0;

        let count = 0;
        for (let i = currPos; i >= 0; i--) {
            const q = stats[i];
            if (!ok(i)) {
                break;
            }
            const d30 = q.d30 as number;
            if (!d30) {
                continue;
            }

            const close = q.c;
            sumUpper100 += (close + d30);
            sumLower100 += (close - d30);

            sumUpper160 += (close + 1.6 * d30);
            sumLower160 += (close - 1.6 * d30);

            sumUpper220 += (close + 2.2 * d30);
            sumLower220 += (close - 2.2 * d30);

            sumUpper400 += (close + 4 * d30);
            sumLower400 += (close - 4 * d30);

            sumUpper1000 += (close + 10 * d30);
            sumLower1000 += (close - 10 * d30);

            sumUpper2000 += (close + 20 * d30);
            sumLower2000 += (close - 20 * d30);

            sumUpper4000 += (close + 40 * d30);
            sumLower4000 += (close - 40 * d30);

            count++;

        }
        if (!count) {
            return;
        }

        const addValue = (l: LinePointsMinMaxAtr, minSum: number, maxSum: number) => {
            const min = MathEx.round(minSum / count, 4);
            l.min.x.push(curPosTime);
            l.min.y.push(min);

            const max = MathEx.round(maxSum / count, 4);
            l.max.x.push(curPosTime);
            l.max.y.push(max);
            return {min: min, max: max};
        }

        addValue(minMax.pct100, sumLower100, sumUpper100);
        const res160 = addValue(minMax.pct160, sumLower160, sumUpper160);
        const res220 = addValue(minMax.pct220, sumLower220, sumUpper220);
        const res400 = addValue(minMax.pct400, sumLower400, sumUpper400);
        const res1000 = addValue(minMax.pct1000, sumLower1000, sumUpper1000);
        const res2000 = addValue(minMax.pct2000, sumLower2000, sumUpper2000);
        const res4000 = addValue(minMax.pct4000, sumLower4000, sumUpper4000);

        const currMinMaxPos = minMax.pct100.min.x.length - 1;
        const prevMinMaxPos = currMinMaxPos - 1;

        return {
            currMinMaxPos: currMinMaxPos,
            prevMinMaxPos: prevMinMaxPos,
            curPosTime: curPosTime,
            res160, res220, res400,
        }
    }


    public data() {
        return this.minMax;
    }


    protected static emptyMinMaxAtr(points:number) {
        const buildEmpty = ()=>{return {
            max: {x: [] as number[], y: [] as number[]},
            min:{x: [] as number[], y: [] as number[]}
        }};
        const minMaxStatsAtr: MinMaxStatsAtr = {
            points: points,
            pct100: buildEmpty(),
            pct160: buildEmpty(),
            pct220: buildEmpty(),
            pct400: buildEmpty(),
            pct1000: buildEmpty(),
            pct2000: buildEmpty(),
            pct4000: buildEmpty(),
        }
        return minMaxStatsAtr;
    }
}


// tslint:disable-next-line:max-classes-per-file
export class MinMaxAtrDataWithSignals extends MinMaxAtrData {
    protected minMax = MinMaxAtrDataWithSignals.emptyMinMaxAtrWithSignals(this.points);

    public data() {
        return this.minMax;
    }

    public calcSignals(params:{
        prevMinMaxPos: number,
        currMinMaxPos: number,
        prevStockClose: number,
        stockClose: number,

        curPosTime: number,
        res160: MinMaxValue,
        res220: MinMaxValue,
        res400: MinMaxValue,
        last3predsAvg: LinePoints,
        predStats: readonly Analysis.Prediction[]
    }) {
        const minMax = this.minMax;
        const {prevMinMaxPos, prevStockClose, curPosTime, stockClose, currMinMaxPos, res160, res220, res400, last3predsAvg, predStats} = params;
        const addUpDownIns = (sPoints: PointUpDown[], line: LinePointsMinMaxAtr) => {
            if (prevMinMaxPos < 0) {
                return;
            }
            if (prevStockClose < line.min.y[prevMinMaxPos] && stockClose >= line.min.y[currMinMaxPos]) {
                sPoints.push({x: curPosTime, y: stockClose, isSellSignal: false,});
            }
            if (prevStockClose >= line.max.y[prevMinMaxPos] && stockClose < line.max.y[currMinMaxPos]) {
                sPoints.push({x: curPosTime, y: stockClose, isSellSignal: true,});
            }
        };
        addUpDownIns(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE], minMax.pct160);
        addUpDownIns(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE], minMax.pct220);
        addUpDownIns(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE], minMax.pct400);

        const addUpDownSimple = (sPoints: PointUpDown[], data: { min: number, max: number }) => {
            if (stockClose > data.max) {
                sPoints.push({x: curPosTime, y: stockClose, isSellSignal: true,});
            }
            if (stockClose < data.min) {
                sPoints.push({x: curPosTime, y: stockClose, isSellSignal: false,});
            }
        }

        addUpDownSimple(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK], res160);
        addUpDownSimple(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK], res220);
        addUpDownSimple(minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK], res400);

        const addLast3Stock = (pY: number, signal: StockSignalsEnum, l: LinePointsMinMaxAtr) => {
            // 220%
            const signals = (minMax.signals as any)[signal];
            if (pY > stockClose && stockClose > l.max.y[currMinMaxPos]) {
                signals.push({x: curPosTime, y: pY, isSellSignal: true,});
            }
            if (pY < stockClose && stockClose < minMax.pct220.min.y[currMinMaxPos]) {
                signals.push({x: curPosTime, y: pY, isSellSignal: false,});
            }
        }
        // find last3 point and compare below/above other lines
        // tslint:disable-next-line:prefer-for-of
        for (let y = 0; y < last3predsAvg.x.length; y++) {
            const pTime = last3predsAvg.x[y];
            if (pTime > curPosTime) {
                break;
            }
            if (pTime === curPosTime) {
                const pY = last3predsAvg.y[y];
                // UP 160% signal
                addLast3Stock(pY, StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED, minMax.pct160);

                // 220%
                addLast3Stock(pY, StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED, minMax.pct220);
                addLast3Stock(pY, StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED, minMax.pct400);
            }
        }

        // tslint:disable-next-line:prefer-for-of
        for (let z = 0; z < predStats.length; z++) {
            const pred = predStats[z];
            const predTime = pred.valueTime;
            const predY = pred.value;
            if (predTime > curPosTime) {
                break;
            }
            if (predTime === curPosTime) {
                if (predY > minMax.pct220.max.y[currMinMaxPos]) {
                    minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE].push({
                        x: curPosTime,
                        y: predY,
                        isSellSignal: true
                    });
                }
                if (predY < minMax.pct220.min.y[currMinMaxPos]) {
                    minMax.signals[StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE].push({
                        x: curPosTime,
                        y: predY,
                        isSellSignal: false
                    });
                }
                break;
            }
        }
    }

    public calc(
            currPos: number, stats: readonly IQuoteFull[],
            last3predsAvg: LinePoints,
            predStats: readonly Analysis.Prediction[]
    )  {

        const data = this.calcAtr(currPos, stats);
        if (!data) {
            return;
        }
        this.calcSignals({
            res160: data.res160,
            res220: data.res220,
            res400: data.res400,
            currMinMaxPos: data.currMinMaxPos,
            prevMinMaxPos: data.prevMinMaxPos,
            prevStockClose:  stats[data.prevMinMaxPos]?.c,
            curPosTime: data.curPosTime,
            stockClose: stats[currPos].c,
            last3predsAvg: last3predsAvg,
            predStats: predStats,
        });

    }

    protected static emptyMinMaxAtrWithSignals(points:number) {
        const data = this.emptyMinMaxAtr(points);
        return {
            points: data.points,
            pct100: data.pct100,
            pct160: data.pct160,
            pct220: data.pct220,
            pct400: data.pct400,
            pct1000: data.pct1000,
            pct2000: data.pct2000,
            pct4000: data.pct4000,
            signals: {
                [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED]: [],

                [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK]: [],

                [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE]: [],
                [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE]: [],

                [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE]: [],
            },
        } as MinMaxStatsAtrWithSignals;
    }
}
