import { SecuritiesDatasetEnum } from "_hooks/useTradegramSecurities";
import { TraderTypeFilterEnum } from "components/user/dashboard/filters/TraderTypeDropDownFilter";
import {
  BrokerSecurityTypeEnum,
  BrokerTransactionTypeEnum,
  BrokerTransactionVerificationStatusEnum,
  NewYorkTz,
  StockHelper
} from "predictagram-lib";
import { ITradegramSecurity, ITradegramTransaction } from "services/TradegramApiService";
import { Helper } from "./Helper";
import { OptionPatternOsi } from "./OptionPatternOsi";
import { OptionPatternAbbv } from "./OptionPatternAbbv";
import { OptionPatternSymbolDate } from "./OptionPatternSymbolDate";
import { OptionPatternThinkSwim } from "./OptionPatternThinkSwim";
import { OptionPatternSymbolPrice } from "./OptionPatternSymbolPrice";
import { OptionPatternThinkSwim2 } from "./OptionPatternThinkSwim2";
import { OptionPatternThinkSwim3 } from "./OptionPatternThinkSwim3";
import { IPublicProfile } from "interfaces/IPublicProfile";
import { searchSymbolService } from "services/SearchSymbolService";
import {IParsedOption} from "./OptionPattern";

export enum CardContext {
  ADD = 'Add',
  CLOSE = 'Close',
}

export interface IProfit {
  perShare: number,
  pctChange: number
}

export const securitiesDatasetTraderTypeMap:Map<TraderTypeFilterEnum, SecuritiesDatasetEnum | null> = new Map<TraderTypeFilterEnum, SecuritiesDatasetEnum | null>([
  [TraderTypeFilterEnum.TOP, SecuritiesDatasetEnum.TOP],
  [TraderTypeFilterEnum.FOLLOWING, SecuritiesDatasetEnum.FOLLOWING],
  [TraderTypeFilterEnum.EVERYONE, SecuritiesDatasetEnum.ALL],
])


/**
 * description used in Trade level line item (card body)
 */
export const brokerTransactionTypeTradeActionNames: Map<BrokerTransactionTypeEnum, [string, string]> = new Map<BrokerTransactionTypeEnum, [string, string]>([
  [BrokerTransactionTypeEnum.BUY_TO_OPEN, ['Bought to open', 'Bought to add']],
  [BrokerTransactionTypeEnum.BUY_TO_CLOSE, ['Bought to close', 'Bought to reduce']],
  [BrokerTransactionTypeEnum.SELL_TO_OPEN, ['Sold to open', 'Sold to add']],
  [BrokerTransactionTypeEnum.SELL_TO_CLOSE, ['Sold to close', 'Sold to reduce']],
])


/**
 * description used in Position Level header (card head)
 * use index 0 if opening trade, otherwise use index 1
 */
export const brokerTransactionTypePositionActionNames: Map<BrokerTransactionTypeEnum, [string, string]> = new Map<BrokerTransactionTypeEnum, [string, string]>([
  [BrokerTransactionTypeEnum.BUY_TO_OPEN, ['Position Opened', 'Position Increased']],
  [BrokerTransactionTypeEnum.BUY_TO_CLOSE, ['Position Closed', 'Position Reduced']],
  [BrokerTransactionTypeEnum.SELL_TO_OPEN, ['Position Opened', 'Position Increased']],
  [BrokerTransactionTypeEnum.SELL_TO_CLOSE, ['Position Closed', 'Position Reduced']],
])

export class TradegramHelper {

  public static patternOsi = new OptionPatternOsi();
  public static patternAbbv = new OptionPatternAbbv();
  public static patternSymbolDate = new OptionPatternSymbolDate();
  public static patternThinkSwim = new OptionPatternThinkSwim();
  public static patternSymbolPrice = new OptionPatternSymbolPrice();
  public static patternThinkSwim2 = new OptionPatternThinkSwim2();
  public static patternThinkSwim3 = new OptionPatternThinkSwim3();

  public static isValidPattern(contractName: string): boolean {
    if (contractName === "") return false;

    return this.patternOsi.isValid(contractName)
    || this.patternSymbolDate.isValid(contractName)
    || this.patternThinkSwim.isValid(contractName)
    || this.patternSymbolPrice.isValid(contractName)
    || this.patternThinkSwim2.isValid(contractName)
    || this.patternThinkSwim3.isValid(contractName)
    || this.patternAbbv.isValid(contractName)
  }

  public static parseOptionContractName(contractName: string): IParsedOption | null {
    if (!this.isValidPattern(contractName)) {
      return null;
    }

    // check which pattern matches
    if (this.patternOsi.isValid(contractName)) {
      return this.patternOsi.parse(contractName);
    }

    if (this.patternAbbv.isValid(contractName)) {
      return this.patternAbbv.parse(contractName);
    }

    if (this.patternSymbolDate.isValid(contractName)) {
      return this.patternSymbolDate.parse(contractName);
    }

    if (this.patternThinkSwim.isValid(contractName)) {
      return this.patternThinkSwim.parse(contractName);
    }

    if (this.patternSymbolPrice.isValid(contractName)) {
      return this.patternSymbolPrice.parse(contractName);
    }

    if (this.patternThinkSwim2.isValid(contractName)) {
      return this.patternThinkSwim2.parse(contractName);
    }

    if (this.patternThinkSwim3.isValid(contractName)) {
      return this.patternThinkSwim3.parse(contractName);
    }

    return null;
  }



  public static formatOptionDate(date: Date) {
    // TNA Aug 18 '23 $33 Call
    const mdyhms = NewYorkTz.mdyhms(date);
    return `${mdyhms.monthName} ${mdyhms.day} '${mdyhms.year.substring(2)}`
  }

  public static formatYYMMDD(date: Date) {
    const mdyhms = NewYorkTz.mdyhms(date);
    return `${mdyhms.year.substring(2)}${mdyhms.month.padStart(2, '0')}${mdyhms.day.padStart(2, '0')}`;
  }

  public static formatOptionToOSI(parsedOption: IParsedOption) {
    // NVDA 230818 C 00050000
    const { date, optionType, price, symbol } = parsedOption
    const paddedPrice = (price * 1000).toString().padStart(8, '0');
    const yyyymmdd = this.formatYYMMDD(new Date(date * 1000));
    return `${symbol}${yyyymmdd}${optionType.substring(0, 1)}${paddedPrice}`;
  }

  /**
   * format to AAPL Oct 13 '23 $180.00 Call from OSI string
   * @param s 
   */
  public static formatContractNameFromOSI(s: string, noSymbol:boolean = false) {
    const parsed = this.patternOsi.parse(s);
    return `${noSymbol ? "" : parsed.symbol} ${this.formatOptionDate(new Date(parsed.date * 1000))} $${parsed.price.toFixed(2)} ${parsed.optionType}`
  }

  public static formatMoney(n: number, signed: boolean = false): string {
    if (n < 0) {
      return `-$${Math.abs(n).toFixed(2)}`
    }
    return `${signed ? "+" : ""}$${n.toFixed(2)}`;
  }

  public static getAverageCost(expenses: number, shares: number) {
    const n = expenses / shares;
    return `$${n.toFixed(2)}`
  }

  public static isEquitySecurity(s: BrokerSecurityTypeEnum) {
    return s === BrokerSecurityTypeEnum.EQUITY;
  }

  public static isOptionSecurity(s: BrokerSecurityTypeEnum) {
    return s === BrokerSecurityTypeEnum.OPTION;
  }

  public static getProfit(s: ITradegramSecurity): IProfit {
    const pctChange = s.profitPct;
    const perShare = s.closeQuantity === 0 ? 0 : s.profit / Math.abs(s.closeQuantity) / (this.isOptionSecurity(s.typeId) ? 100 : 1);

    return {
      pctChange,
      perShare: perShare
    }
  }

  public static getTransactionProfitPerShare(s:ITradegramSecurity, t: ITradegramTransaction) {
    const perShare = Math.abs(t.profit / t.quantity / (this.isOptionSecurity(s.typeId) ? 100 : 1));
    return t.profit < 0 ? perShare * -1 : perShare;
  }

  public static getPositionActionName(s: ITradegramSecurity ): string {
    if (s.transactions === 0) {
      return ""
    }
    const [open, change] = brokerTransactionTypePositionActionNames.get(s.lastTransaction.typeId) as [string, string];
    if (s.transactions > 1) {
      return change;
    }
    return open;
  }

  public static getTransactionActionDescription(t: ITradegramTransaction, isFirstOrLastTransaction: boolean, s: ITradegramSecurity ): string {
    if (!t) {
      return '';
    }
    const [open, change] = brokerTransactionTypeTradeActionNames.get(t.typeId) as [string, string];
    if (isFirstOrLastTransaction || (this.getQuantityBalance(s) === 0 && s.lastTransaction.createdAt === t.createdAt)) {
      return open;
    }
    return change;
  }

  public static getQuantityBalance(s: ITradegramSecurity): number  {
    return s.openQuantity + s.closeQuantity;
  }

  public static getQuantityBalancedSigned(s: ITradegramSecurity): string {
    const balance = TradegramHelper.getQuantityBalance(s);
    if ([BrokerTransactionTypeEnum.SELL_TO_OPEN, BrokerTransactionTypeEnum.BUY_TO_CLOSE].includes(s.lastTransaction.typeId)) {
      return TradegramHelper.signedNumber(-balance);
    }

    return TradegramHelper.signedNumber(balance);
  }

  public static isClosed(s: ITradegramSecurity): boolean {
    return this.getQuantityBalance(s) === 0;
  }

  public static shareOrContractName (t: BrokerSecurityTypeEnum): string {
    return this.isOptionSecurity(t) ? "contract" : "share";    
  }

  public static profitOrLossName (amount: number): string {
    return amount < 0 ? "Loss" : "Profit"
  }

  public static signedNumber (n: number): string {
    if (n < 0) {
      return `-${Math.abs(n)}`
    }
    return `+${Math.abs(n)}`
  }

  public static getExpiryText(s: ITradegramSecurity): string {
    const option = TradegramHelper.parseOptionContractName(s.stockSymbolOption);

    if (!option) {
      throw Error('invalid contract name');
    }

    const expDate = new Date(option.date * 1000);
    const today = new Date();
    if (expDate > today) {
      return 'Expired';
    }
    return `Expires in ${Helper.formatDuration(expDate, today)}`;
  }

  public static getBrokerTransactionTypeFromContext(cardContext: CardContext, s: ITradegramSecurity): BrokerTransactionTypeEnum {

    const t: ITradegramTransaction = s.lastTransaction;

    // if balance is 0 all is valid, but set default to BTO
    if (this.getQuantityBalance(s) === 0) {
      return BrokerTransactionTypeEnum.BUY_TO_OPEN
    }

    // user clicked ADD
    if (cardContext === CardContext.ADD) {
      if ([BrokerTransactionTypeEnum.SELL_TO_OPEN].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.SELL_TO_OPEN
      }

      if ([BrokerTransactionTypeEnum.BUY_TO_OPEN].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.BUY_TO_OPEN
      }

      if ([BrokerTransactionTypeEnum.SELL_TO_CLOSE].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.BUY_TO_OPEN
      }

      if ([BrokerTransactionTypeEnum.BUY_TO_CLOSE].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.SELL_TO_OPEN
      }

    }

    // user clicked CLOSE
    if (cardContext === CardContext.CLOSE) {
      if ([BrokerTransactionTypeEnum.BUY_TO_OPEN, BrokerTransactionTypeEnum.SELL_TO_CLOSE].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.SELL_TO_CLOSE
      }

      if ([BrokerTransactionTypeEnum.SELL_TO_OPEN, BrokerTransactionTypeEnum.BUY_TO_CLOSE].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.BUY_TO_CLOSE
      }
    }

    return BrokerTransactionTypeEnum.BUY_TO_OPEN

  }

  static getBrokerTransactionTypeFromExistingSecurity(s: ITradegramSecurity | null, action: "buy" | "sell",): BrokerTransactionTypeEnum {

    if (!s || (s && TradegramHelper.getQuantityBalance(s) === 0)) {
      // security doesn't exist or closed, so default is _TO_OPEN
      if (action === "buy") {
        return BrokerTransactionTypeEnum.BUY_TO_OPEN
      }
      return BrokerTransactionTypeEnum.SELL_TO_OPEN
    }

    const t: ITradegramTransaction = s.lastTransaction;

    if (action === "sell") {
      if ([BrokerTransactionTypeEnum.SELL_TO_OPEN].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.SELL_TO_OPEN
      }

      if ([BrokerTransactionTypeEnum.BUY_TO_OPEN].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.SELL_TO_CLOSE
      }

      if ([BrokerTransactionTypeEnum.SELL_TO_CLOSE].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.SELL_TO_CLOSE
      }

      if ([BrokerTransactionTypeEnum.BUY_TO_CLOSE].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.SELL_TO_OPEN
      }

    }

    if (action === "buy") {
      if ([BrokerTransactionTypeEnum.BUY_TO_OPEN, BrokerTransactionTypeEnum.SELL_TO_CLOSE].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.BUY_TO_OPEN
      }

      if ([BrokerTransactionTypeEnum.SELL_TO_OPEN, BrokerTransactionTypeEnum.BUY_TO_CLOSE].includes(t.typeId)) {
        return BrokerTransactionTypeEnum.BUY_TO_CLOSE
      }
    }

    return BrokerTransactionTypeEnum.BUY_TO_OPEN
  }

  static getSecurityProfitabilityPct(s: ITradegramSecurity, profile: IPublicProfile): number {
    return TradegramHelper.isEquitySecurity(s.typeId) ? profile.broker.realizedProfitPctEquity : profile.broker.realizedProfitPctOption;
  }


  static isVerifiedTransaction(t: ITradegramTransaction) {
    return t.verificationStatusId === BrokerTransactionVerificationStatusEnum.APPROVED
  }

  static hasSold(s: ITradegramSecurity) {
    return !(s.transactions === 1 || (s.transactions > 1 && s.closeCost === 0))
  }

  static async isValidExpirationDate(symbol: string, expirationDate: Date): Promise<boolean> {
    try {
      const symbolLookup = await searchSymbolService.search({symbolName: symbol});
      if (symbolLookup.length === 0) {
          throw Error(`could not find symbol ${symbol}`);
      }
      return StockHelper.isValidOptionExpirationDate(expirationDate, symbolLookup[0].optionExpirationDays);
    } catch (error: any) {
      console.error('error in trading date validation', (error as Error).message);
      return false;
    }
  }

  static getNextFriday = (date: Date): Date => {
    let currentDate = new Date(date);

    if (currentDate.getDay() === 5) {
      const nydate = StockHelper.workingHours(NewYorkTz.getDateMidnight(currentDate)).end;
      if (currentDate > nydate) {
        const d = new Date(date);
        currentDate.setDate(d.getDate() + 1);
      }
    }

    const dayOfWeek = currentDate.getDay();
    const daysUntilNextFriday = (5 - dayOfWeek + 7) % 7;
    const nextFriday = new Date(currentDate);
    nextFriday.setDate(currentDate.getDate() + daysUntilNextFriday);
    return nextFriday;
  };  

}
