import React from 'react';
import { Spinner } from 'components/common/Spinner';
import { IContest } from 'interfaces/IContest';
import * as Icons from 'components/common/Icons';
import { useNavigate } from 'react-router-dom';
import { ContestItem } from './ContestItem';
import { useContests } from '_hooks/useContests';
import { ErrorMessage } from 'components/common/ErrorMessage';

export const ContestListPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const contests = useContests();
  const renderer = (contest: IContest, i: number) => <div key={`contest-key-${i}`}><ContestItem contest={contest} /><hr /></div>

  if (contests.apiState.isLoading) {
    return <Spinner />
  }

  if (contests.apiState.isLoaded) {
    return (
      <div className="container">
        <div className="d-flex flex-justify-start gap-3">
          <div className="flex-grow-0 me-4" role="button" onClick={() => { navigate(-1) }}>
            <Icons.LeftArrow />
          </div>
          <div>Contests</div>
        </div>
  
        <div className="my-4">
          {contests.data !== null  && contests.data.length > 0 ? (<>
            {contests.data.map(renderer)}
          </>) : (
            <>No contests found</>
          )}
        </div>
      </div>
    );
  }

  if (contests.error) {
    return <ErrorMessage>{contests.error}</ErrorMessage>
  }

  return <ErrorMessage><>Unexpected error</></ErrorMessage>
}