import React from 'react';
import { Modal } from "react-bootstrap";
import { ForgotPassword } from './ForgotPassword';

const ModalForgotPassword: React.FunctionComponent<{ show: any; handleClose: any; onReset?: ()=>void }> = ({
  show,
  handleClose,
  onReset,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <ForgotPassword />
      </Modal.Body>
    </Modal>
  );
};

export { ModalForgotPassword }