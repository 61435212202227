import React from 'react';
import { usePublicProfile } from "_hooks/usePublicProfile";
import { AvatarSizeEnum } from "components/common/Avatar";
import { Spinner } from "react-bootstrap";
import { userPredictionApiService } from "services/UserPredictionApiService";
import { AvatarLink, UserLink } from "./UserInfo";
import * as Icons from 'components/common/Icons';
import { ITradegramSecurity } from 'services/TradegramApiService';
import { TradegramHelper } from '_utils/TradegramHelper';

export const UserInfoOverallProfit: React.FunctionComponent<{ userId: number, security:ITradegramSecurity }> = ({ userId, security}) => {

  const profile = usePublicProfile(userId, userPredictionApiService, true);


  if (!profile.loaded) {
    return <Spinner />
  }

  const profileProfitPct = TradegramHelper.getSecurityProfitabilityPct(security, profile.item);

  return (
    <div className="profile-profit d-flex align-items-center gap-2">
      <div className="profile-profit-image">
        <AvatarLink profile={profile.item} size={AvatarSizeEnum.SMALL_MEDIUM} />
      </div>

      <div>
        <div className="d-flex justify-content-center gap-2">
          <div className="text-12 fw-bold"><UserLink id={userId} username={profile.item.username} /></div>
          <div className="text-12 d-flex justify-content-start gap-1 pill bg-charcoal px-2">{(profileProfitPct || 0) >= 0 ?
            <><Icons.GraphUpArrow /><span className="fw-bold text-bright-green">+{((profileProfitPct || 0)).toFixed(2)}%</span></>
            :
            <><Icons.GraphDnArrow /><span className="fw-bold text-bright-red">{((profileProfitPct || 0)).toFixed(2)}%</span></>
          }
          </div>
        </div>
      </div>
    </div>
  );
}