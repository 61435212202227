import React, { useState } from 'react';

import { APIState } from '_constants/APIState';
import { useQuoteAtPredictionTime } from '_hooks/useQuoteAtPredictionTime';
import { useStockSymbolLastQuote } from '_hooks/useStockSymbolLastQuote';
import { Helper } from '_utils';
import { predictionMovement, valueRange, symbolLink } from 'components/public/ListingsPage/components/Prediction/PredictionDetail';
import { IPrice } from 'components/public/ListingsPage/components/Prediction/PredictionLazy';
import { QuoteAsOf } from 'components/public/Stock/QuoteAsOf';
import { IPrediction } from 'interfaces/IPrediction';
import { NewYorkTz as tz } from 'predictagram-lib';
import { PredictionTypeEnum, PredictionTypeHelper } from 'predictagram-lib';
import { Spinner } from 'components/common/Spinner'; 
import { predictionService } from 'services';
import { UserInfo } from './UserInfo';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';
import { UrlHelper } from '_utils/UrlHelper';


export const HappeningLoadingCard = () => {
  return <div>
      <div className="happening-item-loading shimmer"><Spinner /></div>
      <div className="happening-item-loading happening-item-loading-profile shimmer my-2"></div>
    </div>  
}

export const Happening:React.FunctionComponent<{prediction: IPrediction, isWide?: boolean}> = ({prediction, isWide=false}) => {

  const lastQuote = useStockSymbolLastQuote(prediction.stockSymbol, predictionService);
  const quoteAtPrediction = useQuoteAtPredictionTime(prediction, predictionService)
  const navigate = useNavigate();

  if (lastQuote.error) {
    return <div>{lastQuote.error.message}</div>
  }

  if (quoteAtPrediction.error) {
    return <div>{quoteAtPrediction.error.message}</div>
  }

  if (!prediction.createdAt || !prediction.typeId || !prediction.value || !prediction.valueDeviation) {
    return <div>Invalid Prediction</div>
  }

  if (lastQuote.apiState === APIState.LOADING || quoteAtPrediction.apiState === APIState.LOADING ) {
    return <HappeningLoadingCard />
  }

  if (!quoteAtPrediction.quote) {
    return <></>
  }

  const price: IPrice = {
    closingPrice : lastQuote.quote.c ? lastQuote.quote.c.toFixed(2) : '',
    openPrice : lastQuote.quote.o ? lastQuote.quote.o.toFixed(2) : '',
    highPrice : lastQuote.quote.h ? lastQuote.quote.h.toFixed(2) : '',
    lowPrice : lastQuote.quote.l ? lastQuote.quote.l.toFixed(2) : '',
    lastPriceEpoch: lastQuote.quote.t,
    range: {
      high: prediction.value + (prediction.valueDeviation),
      low: prediction.value - prediction.valueDeviation,
      mid: prediction.value },
    isGreen: prediction.value > quoteAtPrediction.quote.c,
  }

  
  const PredictionDateFormat = (date: Date) => {
      const dt = tz.mdyhms(date);
      const dst = tz.getTimezoneOffset(date) === -5 ? 'EST' : 'EDT';
      const movement = predictionMovement(prediction.typeId as PredictionTypeEnum, price.isGreen);
      const priceClassName = `fw-bold ${price.isGreen ? 'text-bright-green' : 'text-bright-red'}`;

      return (
        <>
          <span className="text-14">Will {movement} <span className={priceClassName}>{valueRange(prediction, price)}</span> {PredictionTypeHelper.getPreposition(Number(prediction.typeId))} {Helper.roundUpToClosing(tz.format(date).hour24MinuteAmpm())} <span className="text-11 text-light-gray">{dst}</span> </span>        
          <span className="text-11 text-light-gray">on {dt.month}/{dt.day}/{dt.year.substring(2)}</span>
        </>
      );
  }


  const SymbolDirection = () => {
    return (
      <div className="" >
        <div className="happening-item-symbol-direction happening-item-symbol-direction-top" 
              role="button" 
              onClick={()=>navigate(Url.PUBLIC_STOCK_PREDICTIONS.replace(':stockSymbol', prediction.stockSymbol))}>
          {symbolLink(prediction)}
          <QuoteAsOf quote={lastQuote.quote} />
        </div>

        <div className={`happening-item-symbol-direction happening-item-symbol-direction-bottom happening-item-symbol-direction-bottom-${price.isGreen ? 'green' : 'red'}`}
          role="button"
          onClick={()=>navigate(UrlHelper.getExpandedPrediction(prediction.id as number))}
          >
          <div className="mb-2">{PredictionDateFormat(new Date(prediction.valueAt * 1_000))}</div>
        </div>
     </div>
    );
  }

  return (
    <>
    <div className={`happening-item ${isWide ? 'happening-item-wide' : ''}`}>
      <div className="my-2"><UserInfo prediction={prediction} /></div>
      <SymbolDirection />
    </div>
    </>
  );
}