import React, { useReducer, useState } from "react";
import { IMessengerContext, IMessage, MessengerContext } from ".";
import { ModalMessage } from './ModalMessage';
import { ToastMessage } from "./ToastMessage";


export enum Severity {
  NORMAL = 1,
  WARNING = 2,
  FATAL = 3
}

interface IMessengerState {
  message: IMessage;
  isModal: boolean;
  severity: Severity;
}

enum MessengerAction {
  SET = 1,
  CLEAR = 2,
}

const messageReducer = (state: any, action: any) => {
  switch (action.type) {
    case MessengerAction.CLEAR:
      return {
        message: "",
      };
    case MessengerAction.SET:
      return {
        message: action.payload.message,
        isModal: action.payload.isModal,
        severity: action.payload.severity,
      };
    default:
      throw Error("Invalid action in messageReducer");
  }
};

const MessengerProvider: React.FunctionComponent<{ children: any }> = ({ children }) => {
  const defaultMessengerState: IMessengerState = {message: {} as IMessage, isModal: true, severity: Severity.NORMAL};

  const [show, setShow] = useState<boolean>(false);
  const [messengerState, dispatchMessengerAction] = useReducer(messageReducer, defaultMessengerState);

  const setMessageHandler = (message: IMessage, isModal: boolean = true, severity?: Severity) => {
    dispatchMessengerAction({ type: MessengerAction.SET, payload: { message, isModal, severity: severity ? severity : Severity.NORMAL} });
    setShow(true);
  };

  const clearMessageHandler = () => {
    setShow(false);
    dispatchMessengerAction({ type: MessengerAction.CLEAR, payload: { message: {} as IMessage, isModal: false } });
  };

  const messengerContext: IMessengerContext = {
    message: messengerState.message,
    setMessage: setMessageHandler,
    clearMessage: clearMessageHandler,
  };

  return (
    <MessengerContext.Provider value={messengerContext}>
      {messengerState.isModal &&
      <ModalMessage show={show} handleClose={() => setShow(false)} message={messengerState.message} />
      }

      {!messengerState.isModal && 
      <ToastMessage show={show} handleClose={()=>setShow(false)} message={messengerState.message} severity={messengerState.severity}/>
      }
      {children}
    </MessengerContext.Provider>
  );
};

export { MessengerProvider };
