import { DateEx, NewYorkTz as tz } from "predictagram-lib";

class Helper {

  /**
   * Clones an object with only the given fields as its properties
   * @param fields 
   * @param object 
   * @returns 
   */
  public static cloneObject<T>(fields: string[], object: Object) {
    const clone: any = Object.assign({}, object);
    // eslint-disable-next-line array-callback-return
    Object.keys(clone).map(k => {
      if (fields.indexOf(k) < 0) {
        delete clone[k];
      }
    })
    return clone as T;
  }

  public static removeBlanksFromObject<T>(object: Object) {
    const clone: any = Object.assign({}, object);
    // eslint-disable-next-line array-callback-return
    Object.keys(clone).map(k => {
      if (clone[k] === null || clone[k] === '') {
        delete clone[k];
      }
    })
    return clone as T;
  }

  public static getSecondsRemaining(targetDate: Date): number {
    const today = new Date();
    const diffInMs = targetDate.getTime() - today.getTime();

    if (diffInMs < 0) {
      return 0;
    }

    // @TODO: localize the date
    return diffInMs / 1000;
  }

  public static getTimeRemaining(toDate: Date, fromDate: Date): {
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  } {

    const diffInMs = toDate.getTime() - fromDate.getTime();

    if (diffInMs < 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
    }

    const seconds = diffInMs / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    // localize the date
    return {
      days: Math.floor(days),
      hours: Math.floor(hours % 24),
      minutes: Math.floor(minutes % 60),
      seconds: Math.floor(seconds % 60),
    }
  }

  /**
   * determine whether the given from and to timestamp is inside the day
   * @param fromDateTime 
   * @param toDateTime 
   * @param date 
   */
  public static isInDay(fromDateTime: Date, toDateTime: Date, date: Date) {

    const startDate = this.getFormattedDateMDY(fromDateTime);
    const endDate = this.getFormattedDateMDY(toDateTime);
    const calendarDate = this.getFormattedDateMDY(date);
    return (startDate === calendarDate && endDate === calendarDate);

  }

  public static getLastDateOfMonth(date?: Date): Date {
    if (!date) date = new Date();

    let year = date.getFullYear();
    let month = date.getMonth();

    const lastDate = new Date(year, month + 1, 0);
    return lastDate;
  }

  public static getDaysInMonth(date?: Date): number {
    if (!date) date = new Date();
    const lastDate = this.getLastDateOfMonth(date);
    return lastDate.getDate();
  }

  public static getDaysFromNow(date: Date): string {
    const today: Date = new Date();
    const dayInSeconds: number = 1000 * 60 * 60 * 24;
    const seconds: string = ((date.getUTCSeconds() - today.getUTCSeconds()) / dayInSeconds).toFixed(0);
    return seconds;
  }

  public static getUtcDate(date: Date): string {
    if (!date) date = new Date();

    let year = date.getUTCFullYear();
    let month = (1 + date.getUTCMonth()).toString().padStart(2, '0');
    let day = date.getUTCDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  }

  public static getFormattedDateTime(date: Date): string {
    if (!date) date = new Date();

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let hour = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hour}:${minute}`;
  }

  public static getYMDFromEpoch(seconds: number | null): string {
    const date = seconds ? new Date(seconds * 1000) : new Date();
    return date.toLocaleDateString('en-US');
  }

  public static getLocalDateTimeFromUTC(date: Date): string {
    const _date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return _date.toLocaleString();

  }

  public static getFormattedDate(date: Date): string {
    if (!date) date = new Date();

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  }

  public static getFormattedDateMDY(date: Date): string {
    if (!date) {
      date = new Date();
    }

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  public static getFormattedDayMDY(date: Date): string {
    if (!date) {
      date = new Date();
    }

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${days[date.getDay()]}, ${month}/${day}/${year}`;
  }

  /**
   * for example, if given 01/01/2020 12:30:26:
   * return 01/01/2020 00:00:00 if isEnd === false (in milliseconds)
   * return 01/01/2020 23:59:59 if isEnd === true  (in milliseconds)
   * @param timestamp 
   */
  public static getAbsoluteDateTime(date: Date, isEnd: boolean = false): number {
    if (isEnd) {
      return new Date(`${this.getFormattedDate(date)} 23:59:59`).getTime()
    } else {
      return new Date(`${this.getFormattedDate(date)} 00:00:00`).getTime()
    }
  }

  /**
   * return the date in integer format
   * @param date 
   * @returns 
   */
  public static getEpochTime(date: Date): number {
    return date.getTime() / 1000;
  }

  public static restoreDash(code: string): string {
    return code.slice(0, 4) + '-' + code.slice(4);
  }

  public static async getPreviewFile(file: any) {
    return new Promise((resolved, reject) => {
      const reader = new window.FileReader();
      reader.onload = () => {
        resolved(reader.result);
      }
      reader.onerror = () => {
        reject('could not upload');
      }
      reader.readAsDataURL(file);
    })
  }


  public static removeCharsFromPhone(phoneNumber: string): number {
    return parseInt(`${phoneNumber.replaceAll(/[^\d]/g, '')}`);
  }

  public static formatPhoneAsUS(phoneNumber: string): number {
    if ((phoneNumber as string).charAt(0) === '1') {
      return parseInt(`${phoneNumber.replaceAll(/[^\d]/g, '')}`);
    }
    return parseInt(`1${phoneNumber.replaceAll(/[^\d]/g, '')}`);

  }

  public static formattedPhoneNumber = (s: string) => {
    if (!s) return s;
    if (s === "1") return "1";
    const phone = s.replace(/[^\d]/g, '').replace(/^1/g, ''); // remove non digits and "1"
    if (phone.length < 4) return `${phone}`;
    if (phone.length < 7) {
      return `1 (${phone.slice(0, 3)}) ${phone.slice(3)}`
    }
    return `1 (${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
  }


  /**
   * takes a date formatted as a number like 20110501 (which is presumed YYYYMMDD)
   * and formats it to MM/DD/YYYY
   * @param n : number
   */
  public static formattedMDY = (n: number) => {
    const s: string = n.toString();
    const month = s.substring(4, 6);
    const day = s.substring(6);
    const year = s.substring(0, 4);
    return `${month}/${day}/${year}`;
  }

  /**
   * formats a phone number like 19085551212 to 1 (908) 555-1212
   * @param n 
   */
  public static formattedPhoneFromNumber = (n: number) => {
    const s = n.toString();
    const b = s.substring(1, 4);
    const c = s.substring(4, 7);
    const d = s.substring(7);

    return `${1} (${b}) ${c}-${d}`;
  }

  public static timeSecQuery() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: string) => searchParams.get(prop),
    }) as any;
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    if (params.overwriteTime) {
      return params.overwriteTime;
    }
    return undefined;
  }

  public static numberSuffix(n: number) {
    return ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th"
  }

  /**
   * returns the last part of a given path
   * for example:
   * /folder1/folder2/folder3 returns /folder1/folder2
   * /folder1/folder2/file1.txt return /folder1/folder2
   * @param path 
   * @returns 
   */
  public static lastPath(path: string): string {
    return path.substring(0, path.lastIndexOf('/'))
  }

  public static timeElapsed(past: Date, present: Date) {
    const ms = present.getTime() - past.getTime();
    const secs = ms / 1000;
    const minutes = secs / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    if (minutes < 60) {
      return `${Math.round(minutes)} min${Math.round(minutes) > 1 ? 's' : ''}`;
    }

    if (hours < 24) {
      return `${Math.round(hours)} hr${Math.round(hours) > 1 ? 's' : ''}`;
    }

    return `${Math.round(days)} day${Math.round(days) > 1 ? 's' : ''}`;
  }

  public static roundUpToClosing(s: string) {
    // replace 03:59 PM with 04:00 PM
    return s.replaceAll('3:59 PM', '4:00 PM').replaceAll('7:59 PM', '8:00 PM').replaceAll('3:55 PM', '4:00 PM');
  }

  public static roundUpToClosingHour(s: string) {
    // replace 03:59 PM with 04:00 PM
    return s.replaceAll('12:59 PM', '1 PM')
      .replaceAll('3:59 PM', '4 PM')
      .replaceAll('7:59 PM', '8 PM')
      .replaceAll('3:55 PM', '4 PM');
  }


  /**
   *
   * @param element: the element being checked if in view
   * @param yOffsetPx: use 0 if element is exactly in viewport; provide a positive number to consider
   *  in-view to "look ahead". For example, providing 1000 px means element is considered in view + 1000 px vertically.
   * @returns
   */
  public static isInViewPort = (element: HTMLDivElement, yOffsetPx: number = 0): boolean => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + yOffsetPx) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  public static pluralize(word: string, count: number) {
    return `${word}${count === 1 ? '' : 's'}`;
  }

  public static uniqueFilter(value: string, index: number, array: string[]) {
    return array.indexOf(value) === index;
  }


  public static quoteDateFormat = (date: Date): string => {
    const dt = tz.mdyhms(date);
    const dst = tz.getTimezoneOffset(date) === -5 ? 'EST' : 'EDT';
    const s = `${dt.month}/${dt.day}/${dt.year.substring(2)} ${tz.format(date).hour24MinuteAmpm()} ${dst}`;
    return this.roundUpToClosing(s);
  }

  public static enumIs<T>(s: T, v: T) {
    return s === v;
  }

  public static formatDuration(date1: Date, date2: Date): string {
    const diffInMs = date2.getTime() - date1.getTime();
    const diffInSeconds = Math.floor(diffInMs / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} ${Helper.pluralize('second', diffInSeconds)}`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);

    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${Helper.pluralize('minute', diffInMinutes)}`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInHours < 24) {
      return `${diffInHours} ${Helper.pluralize('hour', diffInHours)}`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} ${Helper.pluralize('day', diffInDays)}`;
  }

  public static getStartDateOfWeek(desiredDay: number, currentDate: Date = new Date(), weeksAgo: number = 0): Date {
    const currentDay = currentDate.getDay();
    const difference = currentDay - desiredDay;

    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - difference - (weeksAgo * 7));

    // Reset hours, minutes, seconds, and milliseconds to get the beginning of the desired day
    startDate.setHours(0, 0, 0, 0);

    return startDate;
  };

  /**
   * 
   * @param date 
   * @returns mm/yy format example 01/23
   */
  public static dateFormatMMDD(date: Date) {
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${(date.getDate()).toString().padStart(2, '0')}`;
  }

  /**
   * sorts an array of objects by given property
   * @param arr 
   * @param property 
   * @param ascending 
   * @returns 
   */
  public static sortByProperty<T>(
    arr: T[],
    property: keyof T,
    ascending: boolean
  ): number {
    const compareValue = ascending ? 1 : -1;
    const [a, b] = arr;
    if (a[property] < b[property]) {
      return -compareValue;
    } else if (a[property] > b[property]) {
      return compareValue;
    } else {
      return 0;
    }
  }

  /*
  * returns the next n
  * e.g. next10 : input 5, output 10
  * @param n 
  */
  public static getNextN(n: number, input: number): number {
    const remainder = input % n;
    return remainder === 0 ? input : input + (n - remainder);
  }

  public static roundDownEpochToMinute(epoch: number): DateEx {
    const date = new DateEx(epoch * 1000);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }


  public static toIntOrDefault(v: any, def = null) {
    return v !== '' ? parseInt(v, 10) : def;
  }

  public static toIntArrayOrDefault(vals: any[], def = []) {
    const data = [];
    for (const v of vals || []) {
      data.push(parseInt(v, 10));
    }
    return data.length ? data : def;
  }

  /**
   * Fisher-Yates shuffle algorithm
   * @param array 
   * @returns shuffled array
   */
  public static shuffleArray<T>(array: T[]): T[] {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

  public static objectToQueryString(params: { [key: string]: any }): string {
    const queryString = Object.keys(params)
      .map(key => {
        const value = params[key];
        if (value !== undefined && value !== null) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
        return '';
      })
      .filter(Boolean)
      .join('&');

    return queryString.length ? `?${queryString}` : '';
  }

  public static isDateInRange(currentDate: Date, startDate: Date, endDate: Date) {
    return currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime();
  }

  public static formatCurrency(n: number, decimals: number) {
    return `$${n.toFixed(decimals)}`
  }

  public static formatPercent(n: number, decimals: number) {
    return `${n.toFixed(decimals)}`
  }


  public static getFirstOfMonth(date: Date = new Date()): Date {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  }

  public static formatUSCurrency(amount: number): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }

  public static addDays(timeMs: number, numDays: number): Date {
    const dt = new Date(timeMs);
    dt.setDate(dt.getDate() + numDays);
    return dt;
  }

  public static isNumber(value: any): boolean {
    const num = Number(value);
    return !isNaN(num);
  }

  public static stringToArray (field: any) {

    if (!field) {
      return undefined;
    }
    // check if string
    if (typeof field === "string") {
      if (field === "") return undefined;
      return (field as string).split(',').map(v => +v) 
    }

    if (field.length && field.length > 0) {
      return field;
    }

    return undefined;
  }

  public static dedupeArray<T>(array: T[]): T[] {
    return array.filter((item, index) => array.indexOf(item) === index);
  }

}

export { Helper }
