import { IPublicProfile } from "interfaces/IPublicProfile";
import { userPredictionApiService } from "services/UserPredictionApiService";

export class NotificationHelper {

  static getUserNames = async (ids: number[]): Promise<IPublicProfile[]> => {
    const promises = ids.map((userId) => userPredictionApiService.getPublicProfile(userId || 0))
    const results = await Promise.all(promises);
    return results;
  }

}