import React, { useEffect, useState } from "react";

import { IKeyValue } from "interfaces";
import { DropDown } from "components/common/form/DropDown";
import { IStockSymbol } from "interfaces/IStockSymbol";
import { predictionService } from "services";

export const SymbolsDropdown: React.FunctionComponent<{ errors: any, touched: any, name: string, defaultDescription?: string, defaultKey?: any }> = ({ errors, touched, name, defaultDescription = 'Select Stock' , defaultKey = 0}) => {
  const [ symbols, setSymbols ] = useState<IStockSymbol[] | undefined>(undefined);
  const getMap = (items: IStockSymbol[]) => new Map<string, string>(
    items.map((i: IStockSymbol) => [i.symbolName, i.symbolDescription as string])
  );

  useEffect(()=>{
    const _load = async () => {
      const symbols = await predictionService.getSymbols();
      setSymbols(symbols);
    }
    _load();
  }, [])

  return (
    <>
      {symbols  && <DropDown enumMap={getMap(symbols)}
        defaultItem={{ key: defaultKey, value: defaultDescription } as IKeyValue<number>}
        errors={errors}
        touched={touched}
        label="Symbols" name={name} />}

      {!symbols && <div>Loading Symbols...</div>}
    </>
  );
};

