import React, { useContext } from 'react';
import { IFilteredPredictions, useFilteredPredictions } from '_hooks/useFilteredPredictions'
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect'
import { PredictionListLazy } from 'components/public/ListingsPage/components/PredictionListLazy'
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService'
import { UserTypeFilterEnum } from '../dashboard/filters/UserTypeDropDownFilter'
import { APIState } from '_constants/APIState'
import { Spinner } from 'components/common/Spinner'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { AccountContext } from 'components/common'
import { NotificationHeader } from './NotificationHeader';
import { Helmet } from 'react-helmet';

export const RecentPredictionsPage: React.FunctionComponent = () => {
  return (
    <NotificationHeader title="My Prediction Results">
      <RecentPredictions />
    </NotificationHeader>
  );
}


const RecentPredictions = () => {
  const acctContext = useContext(AccountContext);

  const searchOptions: ISearchOptions = {
    orderBy: sortTypeEnum.LATEST,
    limit: 50,
    userId: acctContext?.account.id,
    endTime: Date.now() / 1000
  }
  const apiFiltered: IFilteredPredictions = useFilteredPredictions(userPredictionApiService, UserTypeFilterEnum.EVERYONE, searchOptions)

  if (apiFiltered.apiState === APIState.LOADING) {
    return <Spinner />
  }

  if (apiFiltered.apiState === APIState.ERROR) {
    return <ErrorMessage>{apiFiltered.error?.message}</ErrorMessage>
  }

  if (apiFiltered.apiState === APIState.LOADED) {
    return (
      <>
        <Helmet>
          <title>Predictagram: Notifications - My Recent Predictions</title>
        </Helmet>
        <PredictionListLazy predictions={apiFiltered.items} onSelectSort={() => { }} orderBy={sortTypeEnum.LATEST} showMenu={false} />
      </>
    )
  }

  return <ErrorMessage>Unknown State</ErrorMessage>

}