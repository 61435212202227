import { SymbolsContext } from 'components/common/SymbolsContext';
import { IStockSymbol } from 'interfaces/IStockSymbol';
import React, { useContext } from 'react';
import { SymbolToggleButton } from '../SymbolToggleButton';

interface IProps {
  selectedSymbols: string[] | null,
  onSymbolClick: (symbol: string, selected: boolean) => void,
}

export const SymbolButtons: React.FunctionComponent<IProps> = ({ selectedSymbols, onSymbolClick}) => {

  const symbolsContext = useContext(SymbolsContext);

  const _onSymbolClick = (symbol: string, selected: boolean) => {
    onSymbolClick(symbol, selected);
  }

  return (
    <div className="d-flex flex-wrap gap-2 justify-content-start mt-3">
      {
        symbolsContext?.symbols.map((s: IStockSymbol, i: number) => <div key={`symbol-key-${i}`} title={s.symbolDescription}>
          <SymbolToggleButton stockSymbol={s} initialSelected={selectedSymbols?.includes(s.symbolName) || false} onClick={_onSymbolClick} />
        </div>)
      }
    </div>
  );
}


