import {AccountContext} from 'components/common';
import {ModalHelp} from 'components/public/Help/ModalHelp';
import {ModalStockPrediction} from 'components/public/Stock/ModalStockPrediction';
import {IPrediction} from 'interfaces/IPrediction';
import {NewYorkTz, PredictionTypeEnum} from 'predictagram-lib';
import React, {useContext, useEffect, useRef, useState} from 'react';
import useResize from '_hooks/useResize';
import { PredictionCountHelper } from '_utils/PredictionCountHelper';
import {PredictionLazy} from './Prediction/PredictionLazy';
import {sortTypeEnum, SortTypeSelect} from './Prediction/SortTypeSelect';
import { PixelHelper } from '_utils/PixelHelper';
import { Help } from './Help';
import { PredictionTypeDropDownFilter, PredictionTypeFilterEnum, predictionTypeDropDownItemsMap } from 'components/user/dashboard/filters/PredictionTypeDropDownFilter';
import {
  LeaderboardCard as InfeedLeaderboard,
  DashboardCard as InfeedDashboard,
  RankingCard as InfeedRanking
} from 'components/public/ListingsPage/components/InFeed';
import { LoadingMoreSpinner } from 'components/common/LoadingMoreSpinner';
import { useIntersectionObserver } from '_hooks/useIntersectionObserver';
import { useApiPixel } from '_hooks/useApiPixel';


interface IProps {
  predictions: IPrediction[],
  onSelectSort: (sortType: sortTypeEnum) => void,
  orderBy: sortTypeEnum,
  showProfile?: boolean,
  showMenu?: boolean,
  onClickFilterTab?: (predictionTypes: PredictionTypeEnum[]) => void,
  hasPredictionTypeTabs?: boolean,
  showTitle?: boolean,
  showHelpIcon?: boolean,
  onScrollBottom?: ()=>void,
  title?: JSX.Element | string,
  showWalkthrough?: boolean,
  setShowWalkthrough?: (value: boolean) => void,
}
export const PredictionListLazy: React.FunctionComponent<IProps> = ({ 
  predictions,
  onSelectSort,
  orderBy,
  onClickFilterTab,
  showProfile = true,
  showMenu = true,
  hasPredictionTypeTabs = true,
  showTitle = true,
  showHelpIcon = true,
  onScrollBottom,
  title = <div className="align-self-start fw-bold">Predictions</div>,
  showWalkthrough,
  setShowWalkthrough
  }) => {

  const refCardParent = useRef<HTMLDivElement>(null);
  const [cardParentWidth] = useResize(refCardParent);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const acctContext = useContext(AccountContext);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [currPredictions, setCurrPredictions] = useState<IPrediction[]>(predictions);
  const [showHelp, setShowHelp] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [currIndex, setCurrIndex] = useState<number>(0);
  
  const inFeedCardInterval = 5;

  // const onScrollBottom = useCallback(() => {
  //   // temporarily disable for Twitter ads.
  //   // see ticket #384
  //   // if ((!acctContext?.isLoggedIn()) || (acctContext.isLoggedIn() && acctContext.account.limitedAccess === true && showDialog === false)) {
  //   //   setShowDialog(true);
  //   // }
  //   //setShowRegister(true);
    
  // }, []);

  const [predictionTypes, setPredictionTypes] = useState<PredictionTypeEnum[]>([]);
  const [predictionTypeFilter, setPredictionTypeFilter] = useState<PredictionTypeFilterEnum>(PredictionTypeFilterEnum.ALL);
  const apiPixel = useApiPixel();

  useIntersectionObserver(containerRef, ()=>{

    if (currIndex > predictions.length) {
      return;
    }

    setLoading(true);
    setCurrIndex(currIndex + 5);
    setLoading(false);

    if (currIndex % 5 === 0 && currIndex !== 0) {
      PixelHelper.trackScrollN(currIndex);
      apiPixel.getPixel(`scroll_${currIndex}`);
    }

  }, {threshold: 1});

  useEffect(() => {
    if (predictionTypes.length > 0) {
      setCurrPredictions(predictions.filter((p) => predictionTypes.includes(p.typeId as number)));
    } else {
      setCurrPredictions(predictions);
    }
  }, [predictionTypes, predictions]);

  const _onClickTabCallback = (predictionTypes: PredictionTypeEnum[]) => {
    if (onClickFilterTab) {
      onClickFilterTab(predictionTypes);
    }
    return setPredictionTypes(predictionTypes);
  }

  const onClickHelp = () => {
    setShowHelp(true);
  }

  const showReminder = (): boolean | undefined => {
    const predictionsToday = PredictionCountHelper.get(NewYorkTz.getDateMidnight(new Date()).getTime(), localStorage);
    const hasNoPredictionsToday = predictionsToday === undefined || predictionsToday === 0;
    return (acctContext !== null && acctContext.account && acctContext.account.limitedAccess && hasNoPredictionsToday)
  }

  const _setSelectedPredictionTypes = (predictionTypeFilter: PredictionTypeFilterEnum) => {
    const predTypes = predictionTypeDropDownItemsMap.get(predictionTypeFilter)?.value;
    setPredictionTypeFilter(predictionTypeFilter);
    if (predTypes) {
      _onClickTabCallback(predTypes);
    }
  }

  const infeeds = [
    <InfeedLeaderboard className="mb-4"/>,
    <InfeedRanking className="mb-4" />,
    <InfeedDashboard className="mb-4" />,
  ]
  
  const pagedPredictions = () => {
    return currPredictions.slice(0, currIndex);
  }

  return (
    <>
      <ModalStockPrediction showDialog={showDialog} setShowDialog={setShowDialog} showReminder={showReminder()} />
      <ModalHelp show={showHelp} handleClose={()=>setShowHelp(false)} />

      {showMenu &&
        <>
          <div className="d-flex justify-content-between m-2">
            {showTitle && <>{title}</>}
            {showHelpIcon &&
              <div className="align-self-end d-flex flex-column justify-content-end align-self-center ms-auto"><Help onClick={onClickHelp} /></div>
            }
          </div>
          <div className="d-flex justify-content-between align-items-start flex-wrap">
            <div className="d-flex my-2 align-self-start"><SortTypeSelect onSelectCallback={onSelectSort} selected={orderBy || sortTypeEnum.LATEST} /></div>
            {hasPredictionTypeTabs &&
              <div className="d-flex my-2 align-self-end prediction-type-filter">
                {/* <PredictionFilters onClickTab={_onClickTabCallback} /> */}
                <PredictionTypeDropDownFilter setValue={_setSelectedPredictionTypes} initialSelectedKey={predictionTypeFilter} className="prediction-type-filter"/>
              </div>
            }
          </div>
        </>
      }

      {currPredictions.length === 0 ? (
        <div className="d-flex flex-row justify-content-start my-5">No Results</div>
      ) : (
        <>
        <div ref={refCardParent}>
          <>
            {pagedPredictions().map((prediction: IPrediction, i: number) => {

              if (!acctContext?.isLoggedIn() && i > 0) {
                return <></>
              }

              const predictionCard = <PredictionLazy setShowWalkthrough={setShowWalkthrough} showWalkthrough={showWalkthrough} showProfile={showProfile} prediction={prediction} key={`prediction-key-${i}`} onScrollBottom={onScrollBottom} cardWidth={cardParentWidth} />

              // for debugging to view all at once
              // if (i === 0) {
              //   return <div key={`p-key-${prediction.id}`}>
              //     <>
              //     {infeeds[0]}
              //     {infeeds[1]}
              //     {infeeds[2]}
              //     {predictionCard}
              //     </>
              //   </div>
              // }

              if (i % inFeedCardInterval === 0 && i > 1 && i <= inFeedCardInterval * 3 ) {
                return <div key={`p-key-${prediction.id}`}>
                  <>
                  {infeeds[(i / 5)-1]}
                  {predictionCard}
                  </>
                </div>
              }

              return <div key={prediction.id}>{predictionCard}</div>
            })}
          </>
        </div>
        <div style={{height: '10px'}} ref={containerRef}><LoadingMoreSpinner loading={loading} /></div>        
        </>
      )}
    </>
  )
}
