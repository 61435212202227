import React, { useContext, useEffect, useState } from 'react'
import { HappeningsFull } from './HappeningsFull'
import { useDashboardSettings } from '_hooks/useDashboardSettings';
import { StorageKey } from '_constants';
import { PredictionTypeFilterEnum, predictionTypeDropDownItemsMap } from './filters/PredictionTypeDropDownFilter';
import { UserTypeFilterEnum } from './filters/UserTypeDropDownFilter';
import { SymbolFilterTypeEnum } from './filters/SymbolFilter';
import { useAccountSettings } from '_hooks/useAccountSettings';
import { IFilteredPredictions, useFilteredPredictions } from '_hooks/useFilteredPredictions';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect';
import { SymbolsContext } from 'components/common/SymbolsContext';
import { Helmet } from 'react-helmet';

export const ShowMorePage: React.FunctionComponent = () => {

  const [dashboardSettings] = useDashboardSettings(localStorage, StorageKey.DASHBOARD_SETTINGS);
  const symbolsContext = useContext(SymbolsContext);

  const { settings: userSettings, apiState: settingsApiState, updateSettings, /* settingsError */ } = useAccountSettings();

  const searchOptions: ISearchOptions = {
    symbolNames: dashboardSettings.selectedSymbols.length === 0 ? undefined : dashboardSettings.selectedSymbols,
    orderBy: sortTypeEnum.LATEST,
    predictionTypes: predictionTypeDropDownItemsMap.get(dashboardSettings.predictionTypeFilter)?.value,
    limit: 50,
  }

  // dashboard settings
  const [isExpanded, setIsExpanded] = useState<boolean>(dashboardSettings.isExpanded);
  const [predictionTypeFilter, setPredictionTypeFilter] = useState<PredictionTypeFilterEnum>(dashboardSettings.predictionTypeFilter);
  const [userTypeFilter, setUserTypeFilter] = useState<UserTypeFilterEnum>(dashboardSettings.userTypeFilter);
  const [selectedSymbols, setSelectedSymbols] = useState<string[]>(dashboardSettings.selectedSymbols);
  const [symbolFilterType, setSymbolFilterType] = useState<SymbolFilterTypeEnum>(dashboardSettings.symbolFilterType);

  const filteredPredictions: IFilteredPredictions = useFilteredPredictions(userPredictionApiService, userTypeFilter, searchOptions)

  useEffect(() => {
    if (symbolsContext && symbolsContext.status === 3 && dashboardSettings.selectedSymbols.length === 0) {
      setSelectedSymbols(symbolsContext.symbols.map((s) => s.symbolName));
    }
  }, [symbolsContext, dashboardSettings.selectedSymbols.length])

  return (
    <>
      <Helmet>
        <title>Predictagram: Show More Recent Predictions</title>
      </Helmet>

      <HappeningsFull
        userTypeFilterState={[userTypeFilter, setUserTypeFilter]}
        isExpandedState={[isExpanded, setIsExpanded]}
        predictionTypeFilterState={[predictionTypeFilter, setPredictionTypeFilter]}
        selectedSymbolsState={[selectedSymbols, setSelectedSymbols]}
        symbolFilterTypeState={[symbolFilterType, setSymbolFilterType]}
        settings={userSettings}
        filteredPredictions={filteredPredictions}
      />
    </>
  )
}

