import { SecuritiesDatasetEnum, useTradegramSecurities } from '_hooks/useTradegramSecurities';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { ITradegramSecuritiesFilter, ITradegramSecurity, tradegramApiService } from 'services/TradegramApiService';
import { useNavigate } from 'react-router-dom'
import { TraderTypeDropDownFilter, TraderTypeFilterEnum } from './filters/TraderTypeDropDownFilter';
import { useDashboardSettings } from '_hooks/useDashboardSettings';
import { StorageKey, Url } from '_constants';
import { securitiesDatasetTraderTypeMap } from '_utils/TradegramHelper';
import { ModalTrades } from '../tradegram/ModalTrades';
import { TradeWide } from './TradeWide';
import { BrokerSecurityTypeEnum } from 'predictagram-lib';
import { Helper } from '_utils';
import { SecurityTypeDropDownFilter, SecurityTypeFilterEnum } from './filters/SecurityTypeDropDownFilter';
import { TextDropDown } from 'components/common/TextDropDown/TextDropDown';
import { Helmet } from 'react-helmet';
import { LoadingMoreSpinner } from 'components/common/LoadingMoreSpinner';
import { useModalHelp } from '_hooks/useModalHelp';

export const TradesFeed: React.FunctionComponent = () => {
  const modalHelp = useModalHelp();
  const [dashboardSettings] = useDashboardSettings(localStorage, StorageKey.DASHBOARD_SETTINGS);
  const securitiesApi = useTradegramSecurities(tradegramApiService, undefined, { limit: 25 } as ITradegramSecuritiesFilter);
  const [traderTypeFilter, setTraderTypeFilter] = useState<TraderTypeFilterEnum>(dashboardSettings.traderTypeFilter); // @TODO: change this to dashboard settings
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalSecurity, setModalSecurity] = useState<ITradegramSecurity>({} as ITradegramSecurity);
  const [symbolFilter, setSymbolFilter] = useState<string>('');
  const [securityTypeFilter, setSecurityTypeFilter] = useState<SecurityTypeFilterEnum | BrokerSecurityTypeEnum>(SecurityTypeFilterEnum.ALL);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const setDataset = useCallback((traderTypeFilter: TraderTypeFilterEnum) => {
    securitiesApi.setCurrentDataset(securitiesDatasetTraderTypeMap.get(traderTypeFilter) as SecuritiesDatasetEnum)
  }, [securitiesApi])


  useEffect(() => {
    setDataset(traderTypeFilter)
  }, [setDataset, traderTypeFilter])


  const symbolNames = useCallback(() => {
    const items = securitiesApi.items
      .map(s => s.stockSymbol)
      .filter(s => s !== null)
      .filter(Helper.uniqueFilter).sort((a, b) => {
        return a.localeCompare(b)
      });
    return items;
  }, [securitiesApi.items])


  if (securitiesApi.apiState.isLoading) {
    return <Spinner />
  }

  const onClickTrades = (s: ITradegramSecurity) => {
    setShowModal(true);
    setModalSecurity(s);
  }

  const onSuccess = () => {
    securitiesApi.reload();
  }

  const filteredResults = () => {
    const filteredSecurities = securitiesApi.items
      .filter(s => symbolFilter === "" || symbolFilter === 'All Symbols' || (symbolFilter.toLowerCase() === s.stockSymbol.toLowerCase()))
      .filter(s => securityTypeFilter === 0 || s.typeId === securityTypeFilter)

    return (
      <div className="my-3 my-md-1 d-flex gap-1 align-items-stretch flex-column mx-1 mx-md-3">

        {filteredSecurities.length === 0 && securitiesApi.apiState.isLoaded &&
          <div className="d-flex justify-content-center align-items-center my-3">No trades found.</div>
        }

        {filteredSecurities.length > 0 && filteredSecurities
          .map((s: ITradegramSecurity, i: number) => <div key={`prediction-key-${i}`} className="my-1">
            <TradeWide security={s} onClick={() => onClickTrades(s)} />
          </div>)}
      </div>
    );
  }


  const MenuTabs = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex justify-content-start gap-3 ms-1 ms-md-3 my-3 my-md-1">
          <div className="opacity-70 dashboard-tab" role="button" onClick={() => navigate(Url.USER_DASHBOARD_PREDICTIONS)}>Predictions</div>
          <div className="dashboard-tab dashboard-tab-selected">Trades</div>
        </div>
        <div className='d-block d-md-none'>
          <div className="d-block">{modalHelp.helpIcon()}</div>
        </div>
      </div>
    );
  }

  const Filters = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="m-md-3 d-flex justify-content-start align-items-center gap-1 gap-md-3 trade-feed-filters">
          <div className="trade-feed-filter" ><TraderTypeDropDownFilter initialValue={traderTypeFilter} setValue={setTraderTypeFilter} className="trader-type" /></div>
          <div className="trade-feed-filter"><TextDropDown items={symbolNames()} onSelect={setSymbolFilter} className="symbol-search" /></div>
          <div className="trade-feed-filter"><SecurityTypeDropDownFilter initialValue={SecurityTypeFilterEnum.ALL} setValue={setSecurityTypeFilter} className="security-type" /></div>
        </div>

        <div className='d-none d-md-block'>
          <div className="d-block">{modalHelp.helpIcon()}</div>
        </div>

      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Predictagram: Recent Trades</title>
      </Helmet>

      <div className="dashboard">
        <ModalTrades onSuccess={onSuccess} show={showModal} security={modalSecurity} handleClose={() => setShowModal(false)} />
        <>{modalHelp.modalElement()}</>
        <div className="mx-3 my-1 mx-md-3 my-md-5 d-flex gap-1 align-items-start flex-column">
          <MenuTabs />
          <Filters />
          {filteredResults()}
          <div style={{ height: '10px' }} ref={containerRef}><LoadingMoreSpinner loading={securitiesApi.apiState.isLoading} /></div>
        </div>
      </div>
    </>
  );
}