import { IContest } from "interfaces/IContest";
import { userContestApiService } from "services/common/ContestApiService";
import { useApi } from "./useApi";

export function useContests() {
  const load = async () => {
    return await userContestApiService.getAllPublic();
  }
  return useApi<IContest[], undefined>(load, undefined);
}
