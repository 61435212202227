import React from 'react';

import { FileUpload } from 'components/common';
import { Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { adminStorageService } from 'services';


export const AdminModalLogoUpload: React.FunctionComponent<{show: boolean,
  handleClose: any,
  onSave: any, }> = ({ show, handleClose, onSave }) => {

  const initialValues = {
    logo: '',
    logoUrl: '',
  }

  const onSubmit = async (data: any, actions: any) => {

    const { setSubmitting, setStatus } = actions;

    if (data.logo) {
      try {
        data.logoUrl = await adminStorageService.uploadPublicImage(data.logo);
      } catch (error: any) {
        setSubmitting(false);
        setStatus("Error uploading file");
        console.error({ error });
        throw Error(error.message);
      }
      onSave(data.logoUrl);
    } else {
      delete data.logo;
    }

    handleClose();
  }

  return (
      <Modal className="admin-modal" show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header>
        <Modal.Title>Upload Contest Logo</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body >

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue, isSubmitting, status }) => {
          return <Form>
            <FileUpload
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              name="image-upload"
              targetField="logo"
              touched={touched}
              initialUrl={values["logoUrl"]}
              buttonLabel='Choose Logo Image'
            />

            <button type="submit" disabled={isSubmitting} className="btn btn-primary">Upload</button>

            {status && 
            <div className="alert alert-warning">{status}</div>
            }
          </Form>
        }}
      </Formik>

      </Modal.Body>
    </Modal>
  );
}