import React from 'react';
import { Emoji } from 'components/common/Emoji';
import { IPrediction } from "interfaces/IPrediction"

const predictionScore = (prediction: IPrediction) => {
  if (!prediction.score) return '';

  return (prediction.score * 100).toFixed(0);
}

export const PredictionResult:React.FunctionComponent<{prediction: IPrediction}> = ({prediction}) => {

  if (prediction.score === null) {
    return <div className="result result-active"></div>
  }

  if (!prediction.score || prediction.score === 0) {
    return <div className="result result-whoops">
      <Emoji symbol="😅" text={`Whoops! 0% accurate`} label="whoops!" />
    </div>
  }

  if (prediction.score < 0.2) {
    return <div className="result result-near-miss">
      <Emoji symbol="🤏" text={`Unlucky! ${predictionScore(prediction)}% accurate`} label="near-miss" />
    </div>
  }

  if (prediction.score < 0.4) {
    return <div className="result result-not-bad">
      <Emoji symbol="👍" text={`Keep Trying! ${predictionScore(prediction)}% accurate`} label="not-bad" />
    </div>
  }

  if (prediction.score < 0.6) {
    return <div className="result result-good-call">
      <Emoji symbol="🙌" text={`Decent ${predictionScore(prediction)}% accurate`} label="good-call" />
    </div>
  }


  if (prediction.score < 0.8) {
    return <div className="result result-impressive">
      <Emoji symbol="👏" text={`Impressive! ${predictionScore(prediction)}% accurate`} label="impressive" />
    </div>
  }

  if (prediction.score < 0.9) {
    return <div className="result result-wow">
      <Emoji symbol="🔥" text={`Wow! ${predictionScore(prediction)}% accurate`} label="wow" />
    </div>
  }

  return <div className="result result-bullseye">
    <Emoji symbol="🎯" text={`Bullseye! ${predictionScore(prediction)}% accurate`} label="bullseye" />
  </div>
}