import { predictionService } from "services";
import { IStockSymbol } from "interfaces/IStockSymbol";
import { useApi } from "./useApi";

export function useTopSymbols () {
  const load = async () => {
    return await predictionService.getTopSymbols()
  }
  return useApi<IStockSymbol[], undefined>(load, undefined);
}

