import React from 'react'
import { DropdownFilter, IDropdownFilterItem } from 'components/common/DropDownFilter/DropdownFilter'
import { BrokerSecurityTypeEnum } from 'predictagram-lib';

export type SecurityTypeDropdownItemType = IDropdownFilterItem<SecurityTypeFilterEnum, SecurityTypeFilterEnum>;

export enum SecurityTypeFilterEnum  {
  ALL = 0,
  EQUITY = BrokerSecurityTypeEnum.EQUITY,
  OPTION = BrokerSecurityTypeEnum.OPTION,
}

export const securityTypeDropDownItemsMap: Map<SecurityTypeFilterEnum, SecurityTypeDropdownItemType> = new Map<SecurityTypeFilterEnum, SecurityTypeDropdownItemType>([
  [SecurityTypeFilterEnum.ALL, { key: SecurityTypeFilterEnum.ALL, value: SecurityTypeFilterEnum.ALL, label: "All" }],
  [SecurityTypeFilterEnum.EQUITY, { key: SecurityTypeFilterEnum.EQUITY, value: SecurityTypeFilterEnum.EQUITY, label: "Shares"}],
  [SecurityTypeFilterEnum.OPTION, { key: SecurityTypeFilterEnum.OPTION, value: SecurityTypeFilterEnum.OPTION, label: "Options"}],
]
);

export const securityTypeDropDownItems: SecurityTypeDropdownItemType[] = Array.from(securityTypeDropDownItemsMap).map((item) => {
  return item[1]
})

interface IProps {
  initialValue: SecurityTypeFilterEnum
  setValue: (value: SecurityTypeFilterEnum) => void,
  className?: string,
}

export const SecurityTypeDropDownFilter:React.FunctionComponent<IProps> = ({initialValue, setValue, className}) => {
  const items = securityTypeDropDownItems;
  const _setValue = (value: SecurityTypeFilterEnum) => {
    setValue(value);
  }
  return (
    <DropdownFilter<SecurityTypeFilterEnum, SecurityTypeFilterEnum> items={items} initialSelectedKey={initialValue} onSelect={(item)=>_setValue(item)} className={className}/>
  )
}
