import React, { useReducer } from 'react';
import { FolloweeContext } from './FolloweeContext';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { IFolloweeContext } from 'interfaces/IFolloweeContext';
import { IFollowee } from 'interfaces/IFollow';

enum Action {
  GET = 1,
  UPDATE = 2,
  DELETE = 3,
  ERROR = 99,
}

interface IFolloweeState {
  followees: IFollowee[];
  error: any;
}

interface IFolloweeAction {
  type: Action,
  payload?: any,
}

const followeeReducer = (state: IFolloweeState, action: IFolloweeAction): IFolloweeState => {

  switch(action.type) {
    case Action.GET:
      // console.log(action.payload.followees);
      return {
          error: null,
          followees: action.payload.followees.sort((a:IFollowee, b: IFollowee) => b.followeeId >= a.followeeId ? 1 : -1),
      }

    case Action.ERROR: {
      return {
        followees: state.followees,
        error: action.payload.error,
      }
    }

    default:
      throw new Error(`Invalid Action Type: ${action.type}`);
  }

}

const FolloweeProvider: React.FunctionComponent<{children: any}> = ({children}) => {
  
  const defaultFolloweeState: IFolloweeState = {
    followees: [],
    error: null,
  }
  
  const [ followeeState, dispatchFolloweeAction ] = useReducer( followeeReducer, defaultFolloweeState);

  const getHandler = async (): Promise<IFollowee[]> => {
    try {
      const followees = await userPredictionApiService.getFollowees();
      dispatchFolloweeAction({type: Action.GET, payload: {followees}});
      return followees;
    } catch (error: any) {
      console.error('in getHandler error', error);
      dispatchFolloweeAction({type: Action.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const deleteHandler = async (id: number): Promise<IFollowee[]> => {
    try {
      await userPredictionApiService.deleteFollow(id);
      const follows = followeeState.followees.filter((f: IFollowee) => f.followeeId !== id);
      dispatchFolloweeAction({type: Action.GET, payload: {followees: follows}});
      return follows;
    } catch (error: any) {
      dispatchFolloweeAction({type: Action.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const addHandler = async (id: number): Promise<IFollowee[]> => {
    try {
      await userPredictionApiService.addFollow(id);
      const f: IFollowee = {
        followeeId: id,
        alertEnable: false,
        userId: 0,
      }
      const follows = [...followeeState.followees, f];
      dispatchFolloweeAction({type: Action.GET, payload: {followees: follows}})
      return follows as IFollowee[];
    } catch (error: any) {
      dispatchFolloweeAction({type: Action.ERROR, payload: {error}});
      throw Error(error);
    }

  }

  const disableNotifyHandler = async (id: number): Promise<IFollowee[]> => {
    try {
      await userPredictionApiService.disableNotify(id);
      const f = followeeState.followees.filter((f: IFollowee) => f.followeeId !== id);
      const followee: IFollowee = {
        followeeId: id,
        alertEnable: false,
        userId: 0,
      }
      const follows = [
        ...f,
        followee
      ]
      dispatchFolloweeAction({type: Action.GET, payload: {followees: follows}});
      return follows;
    } catch (error: any) {
      dispatchFolloweeAction({type: Action.ERROR, payload: {error}});
      throw Error(error);
    }    
  }

  const enableNotifyHandler = async (id: number): Promise<IFollowee[]> => {
    try {
      await userPredictionApiService.enableNotify(id);
      const f = followeeState.followees.filter((f: IFollowee) => f.followeeId !== id);
      const followee: IFollowee = {
        followeeId: id,
        alertEnable: true,
        userId: 0,
      }
      const follows = [
        ...f,
        followee
      ]
      dispatchFolloweeAction({type: Action.GET, payload: {followees: follows}});
      return follows;
    } catch (error: any) {
      dispatchFolloweeAction({type: Action.ERROR, payload: {error}});
      throw Error(error);
    }    
  }

  const followeeContext: IFolloweeContext = {
    followees: followeeState.followees,
    getFollowees: getHandler,
    addFollowee: addHandler,
    deleteFollowee: deleteHandler,
    disableNotify: disableNotifyHandler,
    enableNotify: enableNotifyHandler,
  }

  return (
    <FolloweeContext.Provider value={followeeContext}>
      {children}
    </FolloweeContext.Provider>
  );

}


export { FolloweeProvider }
