import React, { useState } from 'react';
import { APIState } from '_constants/APIState';
import { usePublicPrediction } from '_hooks/usePublicPrediction';
import { Spinner } from 'components/common/Spinner';
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect';
import { PredictionListLazy } from 'components/public/ListingsPage/components/PredictionListLazy';
import { useOutletContext } from 'react-router-dom';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { ILeagueSeason, ITeam } from 'interfaces/league';
import { PredictionTypeEnum } from 'predictagram-lib';
import { Helmet } from 'react-helmet';

export const TeamPredictionsPage: React.FunctionComponent = () => {

  const { team, season } = useOutletContext<{
    team: ITeam,
    season: ILeagueSeason
  }>();

  const [, setPredTypes] = useState<PredictionTypeEnum[]>([]);

  const { items: predictions, apiState, setSort, options } = usePublicPrediction(userPredictionApiService, {
    orderBy: sortTypeEnum.BEST,
    teamId: Number(team.id),
    startTime: season.startTime,
    endTime: season.endTime,
  } as ISearchOptions);


  return (
    <div className="team-predictions">

      {apiState === APIState.LOADING &&
        <Spinner />
      }

      {apiState === APIState.LOADED &&
        <Helmet>
          <title>Predictagram: Predictions - {team.name}</title>
        </Helmet>
      }

      {apiState === APIState.LOADED && predictions.length > 0 &&
        <div className="my-3"><PredictionListLazy predictions={predictions} onClickFilterTab={setPredTypes} onSelectSort={setSort} orderBy={options?.orderBy || sortTypeEnum.BEST} /></div>
      }

      {apiState === APIState.LOADED && predictions.length === 0 &&
        <div className="d-flex justify-content-center align-items-center m-5 contest-anon">No predictions.</div>
      }
    </div>
  )
}