import React, { useState } from 'react'
import { ArrowsAngleContract, ArrowsAngleExpand } from 'react-bootstrap-icons';

export const ExpandContract:React.FunctionComponent<{children: any, title: string | JSX.Element, initialShow?: boolean}> = ({children, title, initialShow=true}) => {
  const [show, setShow] = useState<boolean>(initialShow);

  return (
    <div className="border rounded p-2">
      <div className="d-flex justify-content-end align-items-center gap-2">
        {!show && <div className="fw-bold text-12 border-bottom">{title}</div>}
        <div role="button" onClick={()=>setShow(!show)}>{show ? <ArrowsAngleContract /> : <ArrowsAngleExpand />}</div>
      </div>
      <div className={`d-flex justify-content-center align-items-center ${show ? "d-block" : "d-none"}`}>
        {children}
      </div>
    </div>
  )
}
