import React from 'react'
import { SearchBadges } from './SearchBadges';
import { Outlet } from 'react-router-dom';

export const PredictionTradesLayout: React.FunctionComponent = () => {
  return (
    <>
      <SearchBadges />
      <Outlet />
    </>
  )
}

