import React, { useReducer } from 'react';
import { PredictionContext } from './PredictionContext';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { IPredictionContext } from 'interfaces/IPredictionContext';
import { IPrediction } from 'interfaces/IPrediction';

enum Action {
  GET = 1,
  UPDATE = 2,
  DELETE = 3,
  CREATE = 4,
  ERROR = 99,
}

interface IPredictionState {
  predictions: IPrediction[];
  error: any;
}

interface IPredictionAction {
  type: Action,
  payload?: any,
}

const predictionReducer = (state: IPredictionState, action: IPredictionAction): IPredictionState => {

  switch(action.type) {
    case Action.GET:
      return {
          error: null,
          predictions: action.payload.predictions,
      }
    case Action.CREATE:
      return {
          error: null,
          predictions: [
            ...state.predictions,
            action.payload.prediction,
          ]
      }
    case Action.ERROR: {
      return {
        predictions: state.predictions,
        error: action.payload.error,
      }
    }

    default:
      throw new Error(`Invalid Action Type: ${action.type}`);
  }

}

const PredictionProvider: React.FunctionComponent<{children: any}> = ({children}) => {
  
  const defaultFolloweeState: IPredictionState = {
    predictions: [],
    error: null,
  }
  
  const [ predictionState, dispatchPredictionAction ] = useReducer( predictionReducer, defaultFolloweeState);


  const getPredictionsHandler = async (s?: ISearchOptions): Promise<IPrediction[]> => {
    try {
      const predictions = await userPredictionApiService.getAllPublic(s);
      dispatchPredictionAction({type: Action.GET, payload: {predictions}});
      return predictions;
    } catch (error: any) {
      console.error('in getHandler error', error);
      dispatchPredictionAction({type: Action.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const addPredictionHandler = async (p: IPrediction, uniqueUserId?: string): Promise<IPrediction> => {
    try {
      let newPrediction: IPrediction;
      if (uniqueUserId) {
        newPrediction = await userPredictionApiService.create({...p, fg: uniqueUserId}, false);
      } else {
        newPrediction = await userPredictionApiService.create({...p, fg: uniqueUserId});
      }
      dispatchPredictionAction({type: Action.GET, payload: {prediction: newPrediction}})
      return newPrediction;
    } catch (error: any) {
      dispatchPredictionAction({type: Action.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const predictionContext: IPredictionContext = {
    predictions: predictionState.predictions,
    getPredictions: getPredictionsHandler,
    addPrediction: addPredictionHandler,
  }

  return (
    <PredictionContext.Provider value={predictionContext}>
      {children}
    </PredictionContext.Provider>
  );

}


export { PredictionProvider }
