import React, { useCallback, useEffect, useRef, useState } from 'react';
import { predictionService } from 'services/PredictionService';
import { IQuote } from 'components/public/Stock/IQuote';
import { IPrediction } from 'interfaces/IPrediction';
import { PredictionUser } from './PredictionUser';
import { PredictionChart } from './PredictionChart';
import { ChartModel } from 'models/chart.model';
import { PredictionTime } from './PredictionTime';
import {
  DateEx,
  IQuoteFull,
  NewYorkTz,
  PredictionStatusEnum,
  PredictionTypeEnum,
  PredictionTypeHelper,
  StockHelper,
  StockStatsIntervalEnum
} from 'predictagram-lib';
import { SharePrediction } from 'components/common/SharePrediction';
import { PredictionDetail } from './PredictionDetail';
import { PredictionResult } from './PredictionResult';
import { IChart } from 'interfaces/chart/IChart';
import { Calendar } from "../../../../../core/calendar";
import { Helper } from '_utils';
import { Spinner } from 'components/common/Spinner';
import { IWalkthrough, Walkthrough } from 'components/user/walkthrough/Wallkthrough';
import { WalkthroughPredictionsModel } from 'models/walkthrough.predictions.model';
import { StorageKey } from '_constants';
import { UrlHelper } from '_utils/UrlHelper';
import { ChartTypeEnum, ChartTypeToggle } from './ChartTypeToggle';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';

export interface IPrice {
  closingPrice: string,
  openPrice: string,
  highPrice: string,
  lowPrice: string,
  lastPriceEpoch: number,
  range: {
    high: number,
    low: number,
    mid: number,
  },
  isGreen: boolean,
}

interface IProps {
  prediction: IPrediction,
  onScrollBottom?: any,
  showProfile?: boolean,
  cardWidth?: number,
  showWalkthrough?: boolean,
  setShowWalkthrough?: (value: boolean) => void,
  isAltStyle?: boolean,
  [key: string]: any
}

interface ILabelCoords {
  labelCoordX: number,
  labelCoordIntradayX: number,
  xanchor: "center" | "left" | "right" | "auto"
}

export const PredictionLazy: React.FunctionComponent<IProps> = ({ prediction: initialPrediction, onScrollBottom, cardWidth, showProfile = true, showWalkthrough = false, setShowWalkthrough, isAltStyle, ...rest }) => {

  const [chartData, setChartData] = useState<IChart>(undefined as any);

  const refCard = useRef<HTMLDivElement>(null);

  const [lastWidth, setLastWidth] = useState<number | undefined>(undefined);
  const [price, setPrice] = useState<IPrice>({} as IPrice);

  const [walkthroughIndex, setWalkthroughIndex] = useState<number>(0);
  const walkthroughRef = useRef<HTMLDivElement | null>(null);
  const [plotlyDiv, setPlotlyDiv] = useState<any>(null);

  const predictionUserRef = useRef<HTMLDivElement | null>(null);
  const [chartType, setChartType] = useState<ChartTypeEnum>(isAltStyle === undefined ? ChartTypeEnum.CANDLE : !!isAltStyle ? ChartTypeEnum.CANDLE : ChartTypeEnum.LINE); 
  const [lastChartType, setLastChartType] = useState<ChartTypeEnum | undefined>(undefined);

  const [prediction, setPrediction] = useState<IPrediction>(initialPrediction);

  const [predictionStatus, setPredictionStatus] = useState<PredictionStatusEnum>(initialPrediction.statusId as PredictionStatusEnum)

  const isPending = useCallback((): boolean => {
    return predictionStatus === PredictionStatusEnum.PENDING;
  }, [predictionStatus]);

  const hasToggle = ():boolean => {
    return ChartModel.hasToggle(prediction, new Date());
  }

  const isCandle = () => {
    if (!hasToggle()) {
      return false;
    }
    return chartType === ChartTypeEnum.CANDLE;
  }

  const _setChartType = (c: ChartTypeEnum) => {
    setLastChartType(chartType);
    setChartType(c);
  }
  
  const _load = useCallback(async () => {
    if (!prediction.createdAt) {
      return;
    }
    //const now = new Date();//
    const now = new Calendar().newDate();
    // const profiler = new ProfilerTime().start();
    const predTimeSec = prediction.valueTime as number;
    const predType = prediction.typeId as number;
    const predValueAt = prediction.valueAt as number;
    const isPredValueAt = predType === PredictionTypeEnum.VALUE_AT;
    const isRangePrediction = [PredictionTypeEnum.VALUE_AT, PredictionTypeEnum.VALUE_AT_8PM, PredictionTypeEnum.VALUE_CLOSE].includes(predType as PredictionTypeEnum);
    const isHighPred = predType === PredictionTypeEnum.VALUE_HIGH;
    const isLowPred = predType === PredictionTypeEnum.VALUE_LOW;
    const isHighLowPred = isHighPred || isLowPred;
    const isNext30 = predType === PredictionTypeEnum.VALUE_30_MIN;

    const isNext30UpDn = [PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE, PredictionTypeEnum.VALUE_30_MIN_UP_DOWN].includes(predType);
    const isNext1HrUpDn = [PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE, PredictionTypeEnum.VALUE_1H_UP_DOWN].includes(predType);

    const isCloseUpDn = predType === PredictionTypeEnum.VALUE_CLOSE_UP_DOWN;
    const is3DCloseUpDn = predType === PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D;
    const isCloseUpDnBig = predType === PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE;

    const predictionTime = new Date(predTimeSec * 1000);
    const predValueAtTime = new Date(predValueAt * 1000);
    const dates = ChartModel.calcRangeDatePrediction(predTimeSec, predValueAt);

    const tradeDay = StockHelper.workingHours(predValueAtTime).start;
    const isOffHourPref = tradeDay > predictionTime;
    const predRangeStartSec = isOffHourPref ? tradeDay.getTimeSec() : predTimeSec;

    // @TODO: decide about cache for today,

    const chartWidth = cardWidth || (refCard.current ? refCard.current.offsetWidth : undefined); // not likely to get undefined because this is only called after load.

    const interval = isCandle() ? StockStatsIntervalEnum.MIN_5 : PredictionTypeHelper.predictionInterval(predType);
    const endTime = predType === PredictionTypeEnum.VALUE_AT_8PM ? dates.end8PM : dates.end;

    const candleStartEnd = ChartModel.getCandleStartEnd(prediction, new Date(), chartWidth);
    const startCandle = new DateEx(Math.max(dates.start.getTime() , candleStartEnd.startTimeSecs * 1000));
    const endCandle = new DateEx(Math.min(dates.end.getTime() , candleStartEnd.endTimeSecs * 1000));

    const start = isCandle() ? startCandle : dates.start;
    const end = isCandle() ?  endCandle : endTime;

    const stats: ReadonlyArray<IQuote> = await predictionService.getBarsData(prediction.stockSymbol, start, end, false, interval);

    const d = ChartModel.prebuildChartDay({
      stats: stats as IQuoteFull[],
      endFillTimeSec: end.getTimeSec(), //isCandle() && !is3DCloseUpDn ? 0 : dates.end.getTimeSec(),
      predictionTime: predTimeSec,
      chartWidth,
      predictionValueAtTime: predValueAt,
      predictionTypeId: predType,
      xAxisLabelMonthDayOnly: is3DCloseUpDn && (chartWidth !== undefined && chartWidth < 406),
      xAxisGapInSeconds: isCandle() ? 30 * 60 : undefined,
    });

    const deviation = prediction.valueDeviation as number;
    const predictionValue = prediction.value as number;
    const predMiddle = predictionValue;
    const predHigh = predictionValue + (deviation) / 2;
    const predLow = predHigh - deviation;

    const actualHighValue = d.dataHighAfterPrediction;
    const actualLowValue = d.dataLowAfterPrediction;

    setLastWidth(cardWidth);

    const chartYMin = Math.min(d.dataLowDay, predLow) - deviation;
    const chartYMax = Math.max(d.dataHighDay, predHigh) + deviation;

    const chart = ChartModel.dailyChartTemplate({
      yMin: chartYMin,
      yMax: chartYMax,
      tickVals: d.tickVals,
      tickText: d.tickText,
      // stockSymbol: prediction.stockSymbol,
      formatStr: d.formatStr,
      plotData: isCandle() ?
      [
        ChartModel.plotCandleData('before', d.plotCandles.before, [ChartModel.candlestickGreenFaded, ChartModel.candlestickRedFaded]),
        ChartModel.plotCandleData('during', d.plotCandles.during, [ChartModel.rectLineGreenColor, ChartModel.rectLineRedColor]),
        ChartModel.plotCandleData('after', d.plotCandles.after, [ChartModel.candlestickGreenFaded, ChartModel.candlestickRedFaded]),
      ]
      :
      [
        ChartModel.plotData('before', d.plotLines.before, ChartModel.plotLineDarkColor),
        ChartModel.plotData('during', d.plotLines.during, ChartModel.plotLineColor),
        ChartModel.plotData('after', d.plotLines.after, ChartModel.plotLineDarkColor)
      ],
      rangeBreaks: d.chartDataPlotlyXGaps,
      rangeBreaksLines: d.chartDatePlotlyXGapsLines,
    }) as IChart;

    const rectPredEndTime = predValueAt;

    const isGreenColor = (() => {
      if (isRangePrediction || isNext30 || isNext30UpDn || isNext1HrUpDn || isCloseUpDn || is3DCloseUpDn || isCloseUpDnBig) {
        return predictionValue >= (prediction.valueStock as number);
      }
      return predType === PredictionTypeEnum.VALUE_HIGH;
    })();


    setPrice({
      closingPrice: d.priceFormat(d.lastStats.c),
      openPrice: d.priceFormat(d.lastStats.o),
      highPrice: d.priceFormat(d.lastStats.h),
      lowPrice: d.priceFormat(d.lastStats.l),
      lastPriceEpoch: d.lastStats.t,
      range: {
        high: predHigh,
        low: predLow,
        mid: predMiddle,
      },
      isGreen: isGreenColor,
    });

    const rectColor = isGreenColor ? ChartModel.rectangleGreenColor : ChartModel.rectangleRedColor;
    const rectLineColor = isGreenColor ? ChartModel.rectLineGreenColor : ChartModel.rectLineRedColor;

    const redBox = { bgcolor: 'rgba(0, 0, 0, 0.9)', bordercolor: 'rgba(255, 0, 0, 1)' }
    const greenBox = { bgcolor: 'rgba(0, 0, 0, 0.9)', bordercolor: 'rgba(16, 240, 0, 1)' }
    const highLowAnnotationColor = isGreenColor ? greenBox : redBox;

    const isNarrowScreen = chartWidth && chartWidth <= ChartModel.narrowWidth;
    const isUpDown = PredictionTypeHelper.isUpDown(predType);

    const addLabels = () => {

      /**
       * 
       * calculate the positioning of the labels
       */
      const getCoords = (): ILabelCoords => {
        // @TODO: Roy - move this to model and add unit test.
        const duringLen = d.plotLines.during.x.length;
        const afterLen = d.plotLines.after.x.length;
        const rightLen = duringLen + afterLen;
        const totalXTicks = d.plotLines.before.x.length + rightLen + d.chartDataPlotlyXGaps.length;
        const pctRightSide = rightLen / totalXTicks;
        const plotArea = (chartWidth || 1) - ChartModel.yAxisWidth - (ChartModel.xAxisLabelWidth / 2)
        const rightAreaPx = (plotArea || 1) * pctRightSide;
        const labelPx = 130; // approx label width in px

        const pxPerMinute = (plotArea || 1) / totalXTicks;
        const padPx = (rightAreaPx - labelPx) / 2;
        const padInMin = Math.floor(padPx / pxPerMinute);

        // for intraday labels, calculate the number of units
        const intradayLabelPx = 145;
        const intradayTicksInMin = Math.floor((predValueAt - predTimeSec) / 60);
        const intradayInPx = intradayTicksInMin * pxPerMinute;
        const intradayPadInMin = Math.floor((intradayInPx - intradayLabelPx) / 2);
        const intradayX = intradayInPx < labelPx || intradayPadInMin > 60 ? predRangeStartSec : predRangeStartSec + (intradayPadInMin * 60);


        const labelCoordX = isNarrowScreen && isUpDown ? predRangeStartSec : predRangeStartSec + (padInMin * 60);

        const result: ILabelCoords = {
          labelCoordX,
          labelCoordIntradayX: intradayX,
          xanchor: isNarrowScreen && isUpDown ? 'center' : 'left',
        }

        if (rightAreaPx < labelPx) { // not enough room
          result.labelCoordX = predRangeStartSec;
          result.xanchor = isNarrowScreen && isUpDown ? 'center' : 'right';
          return result;
        }

        return result;
      }

      const coords = getCoords();
      const { labelCoordX: x, labelCoordIntradayX: xIntraday, xanchor } = coords;

      if (predType === PredictionTypeEnum.VALUE_CLOSE) {
        chart.layout.annotations?.push(annotationYAxis(predHigh, {
          text: 'Predicted Closing Price',
          xref: 'x',
          x,
          xshift: -1,
          yshift: 8,
          xanchor,
          font: { color: isGreenColor ? ChartModel.rectLineGreenColor : ChartModel.rectLineRedColor }
        }));
      }

      if (predType === PredictionTypeEnum.VALUE_AT) {
        chart.layout.annotations?.push(annotationYAxis(predHigh, {
          text: `Predicted Price`,
          xref: 'x',
          x: xIntraday,
          xshift: -1,
          yshift: 23,
          xanchor,
          font: { color: isGreenColor ? ChartModel.rectLineGreenColor : ChartModel.rectLineRedColor }
        }));
      }

      if (predType === PredictionTypeEnum.VALUE_AT) {
        chart.layout.annotations?.push(annotationYAxis(predHigh, {
          text: `for ${NewYorkTz.format(new Date(predValueAt * 1000)).hour24MinuteAmpm()}`,
          xref: 'x',
          x: xIntraday,
          xshift: -1,
          yshift: 8,
          xanchor,
          font: { color: isGreenColor ? ChartModel.rectLineGreenColor : ChartModel.rectLineRedColor }
        }));
      }

      if (predType === PredictionTypeEnum.VALUE_30_MIN) {
        chart.layout.annotations?.push(annotationYAxis(isGreenColor ? predHigh : predLow, {
          text: `Predicted move ${isGreenColor ? 'up' : 'down'}`,
          xref: 'x',
          x: xIntraday,
          yshift: isGreenColor ? 17 : -1,
          xshift: -1,
          xanchor,
          font: { color: isGreenColor ? ChartModel.rectLineGreenColor : ChartModel.rectLineRedColor }
        }));

        chart.layout.annotations?.push(annotationYAxis(isGreenColor ? predHigh : predLow, {
          text: `by ${NewYorkTz.format(new Date(predValueAt * 1000)).hour24MinuteAmpm()}`,
          xref: 'x',
          x: xIntraday,
          yshift: isGreenColor ? 3 : -15,
          xshift: -1,
          xanchor,
          font: { color: isGreenColor ? ChartModel.rectLineGreenColor : ChartModel.rectLineRedColor }
        }));

      }

      if ([
        PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
        PredictionTypeEnum.VALUE_1H_UP_DOWN,
        PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
        PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
        PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
        PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
        PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
      ].includes(Number(predType))) {

        let x = xIntraday;
        let textWithDate = `${isGreenColor ? 'above' : 'below'} ${d.priceFormat(predMiddle)} by ${Helper.roundUpToClosing(NewYorkTz.format(new Date(predValueAt * 1000)).hour24MinuteAmpm())}`;

        if (is3DCloseUpDn && (chartWidth && chartWidth < 700)) {
          const previousTradingDay = StockHelper.findPreviousTradingDay(new Date(predValueAt * 1000));
          x = StockHelper.workingHours(previousTradingDay).start.getTimeSec() // move it to the middle day
          // adding 5 minutes so it shows the next hour. closing minute can be :55 or :59 so need to round up. we only show the hour anyway.
          const ymds = NewYorkTz.mdyhms(new Date((predValueAt + (60 * 5)) * 1000));
          const dateStr = `${ymds.monthName} ${ymds.day} at ${ymds.hour12}`
          textWithDate = `${isGreenColor ? 'above' : 'below'} ${d.priceFormat(predMiddle)} by ${dateStr}`;
        }

        if (isPending()) {
          chart.layout.annotations?.push(annotationYAxis(isGreenColor ? predHigh : predLow, {
            text: `Waiting for target price...`,
            xref: 'x',
            x: x,
            yshift: isGreenColor ? 17 : -1,
            xshift: -1,
            xanchor,
            font: { color: isGreenColor ? ChartModel.rectLineGreenColor : ChartModel.rectLineRedColor }
          }));

        } else {
          chart.layout.annotations?.push(annotationYAxis(isGreenColor ? predHigh : predLow, {
            text: `Predicted move`,
            xref: 'x',
            x: x,
            yshift: isGreenColor ? 17 : -1,
            xshift: -1,
            xanchor,
            font: { color: isGreenColor ? ChartModel.rectLineGreenColor : ChartModel.rectLineRedColor }
          }));

          chart.layout.annotations?.push(annotationYAxis(isGreenColor ? predHigh : predLow, {
            text: textWithDate,
            xref: 'x',
            x: x,
            yshift: isGreenColor ? 3 : -15,
            xshift: -1,
            xanchor,
            font: { color: isGreenColor ? ChartModel.rectLineGreenColor : ChartModel.rectLineRedColor }
          }));
        }

      }

      if (predType === PredictionTypeEnum.VALUE_LOW) {
        chart.layout.annotations?.push(annotationYAxis(predMiddle, {
          text: 'Predicted Low Price',
          xref: 'x',
          x,
          yshift: -8,
          xshift: -1,
          xanchor,
          font: { color: ChartModel.rectLineRedColor, }
        }));
      }

      if (predType === PredictionTypeEnum.VALUE_HIGH) {
        chart.layout.annotations?.push(annotationYAxis(predMiddle, {
          text: 'Predicted High Price',
          xref: 'x',
          yshift: 8,
          x,
          xshift: -1,
          xanchor,
          font: { color: ChartModel.rectLineGreenColor, }
        }));
      }

      if (predType === PredictionTypeEnum.VALUE_AT_8PM) {
        chart.layout.annotations?.push(annotationYAxis(predHigh, {
          text: 'Predicted Price at 8PM',
          xref: 'x',
          x,
          xshift: -1,
          yshift: 8,
          xanchor,
          font: { color: isGreenColor ? ChartModel.rectLineGreenColor : ChartModel.rectLineRedColor }
        }));
      }

    }

    const buildLine = (x0: number, x1: number, y: number, dash: Plotly.Dash = 'dash', lineColor: string = rectLineColor) => {
      return { // high prediction line
        type: 'line', x0: x0, x1: x1, y0: y, y1: y, layer: "below",
        line: { color: lineColor, width: 2, dash: dash }
      } as Plotly.Shape;
    };
    const buildRect = (x0: number, y0: number, x1: number, y1: number, color: string) => {
      return { type: 'rect', x0: x0, y0: y0, x1: x1, y1: y1, fillcolor: color, layer: "below", line: { width: 0 } } as Plotly.Shape
    };

    // current price purple horizontal line annotation
    chart.layout.shapes.push(...[
      ChartModel.shapeVerticalLinePredictionTime(predTimeSec, 0, 1, 'rgba(209, 204, 255, 0.67)'),
      ChartModel.shapeHorizontalLineValue(d.lastStats.c),
    ]);

    const greyRectColor = 'rgba(41, 41, 41, 0.8)';
    const greyRect = buildRect(d.firstStats.t, 0, predTimeSec, 1, greyRectColor);
    greyRect.yref = 'paper';
    chart.layout.shapes.push(greyRect);

    // right gray for intraday
    if ([PredictionTypeEnum.VALUE_AT,
    PredictionTypeEnum.VALUE_30_MIN,
    PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
    PredictionTypeEnum.VALUE_1H_UP_DOWN,
    PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
    PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE].includes(Number(predType))) {
      const greyRectRight = buildRect(predValueAt, 0, isCandle() ? endCandle.getTimeSec() : dates.end.getTimeSec(), 1, greyRectColor);
      greyRectRight.yref = 'paper';
      chart.layout.shapes.push(greyRectRight);
    }

    const ls = d.lastStats;
    // only for active time
    if (Math.abs(ls.t - now.getTimeSec()) < 5 * 60 && !isCandle()) {
      const circle = ChartModel.plotData('circle', { x: [ls.t], y: [ls.c] }, '#7F52FF');
      circle.line.width = 3;
      circle.mode = 'markers';
      chart.data.push(circle);
    }

    if (isRangePrediction) {
      // selector rect
      chart.layout.shapes.push(
        buildLine(predRangeStartSec, rectPredEndTime, predHigh), // high line
        buildLine(predRangeStartSec, rectPredEndTime, predLow), // low line
        buildRect(predRangeStartSec, predLow, rectPredEndTime, predHigh, rectColor),
      );
    } else if (isHighLowPred) {
      const middleLine = buildLine(predRangeStartSec, rectPredEndTime, predMiddle);
      chart.layout.shapes.push(middleLine);

      const lineY = isHighPred ? actualHighValue : actualLowValue;
      // show high/low annotation if market is closed or prediction has passed
      const showHighLow: boolean = true; //!DateHelper.isSameDay(new Date(prediction.createdAt * 1000), new Date()) || !StockHelper.isMarketOpen(new Date());

      if (showHighLow) {
        // actual high/low value horizontal line
        chart.layout.shapes.push(
          buildLine(predRangeStartSec, rectPredEndTime, lineY, 'solid', ChartModel.plotLineLessDarkColor),
        );

        // draw gradient rectangle
        chart.layout.shapes.push(
          buildRect(middleLine.x0 as number, middleLine.y0 as number, middleLine.x1 as number, d.actualPriceAtPredictedTime || lineY, rectColor)
        );

      }
    } else if (isNext30) {
      // draw gradient rectangle
      const middleLine = buildLine(predRangeStartSec, rectPredEndTime, predMiddle);
      chart.layout.shapes.push(middleLine);

      // draw gradient rectangle
      chart.layout.shapes.push(
        buildRect(middleLine.x0 as number, middleLine.y0 as number, middleLine.x1 as number, Number(d.actualPriceAtPredictedTime), rectColor)
      );
    } else if (isNext30UpDn || isNext1HrUpDn || isCloseUpDn || isCloseUpDnBig) {

      // draw dashed line
      if (!isPending()) {
        const middleLine = buildLine(predRangeStartSec, rectPredEndTime, predMiddle);
        chart.layout.shapes.push(middleLine);
  
        // draw gradient rectangle
        if (isGreenColor) {
          chart.layout.shapes.push(
            buildRect(middleLine.x0 as number, chartYMax, middleLine.x1 as number, predMiddle, rectColor)
          );
        } else {
          chart.layout.shapes.push(
            buildRect(middleLine.x1 as number, chartYMin, middleLine.x0 as number, middleLine.y0 as number, rectColor)
          );
        }
      }

    } else if (is3DCloseUpDn) {
      const middleLine = buildLine(predTimeSec, rectPredEndTime, predMiddle);
      chart.layout.shapes.push(middleLine);

      // draw gradient rectangle
      if (isGreenColor) {
        chart.layout.shapes.push(
          buildRect(middleLine.x0 as number, chartYMax, middleLine.x1 as number, predMiddle, rectColor)
        );
      } else {
        chart.layout.shapes.push(
          buildRect(middleLine.x1 as number, chartYMin, middleLine.x0 as number, middleLine.y0 as number, rectColor)
        );
      }
    }

    chart.divId = `prediction-plot-${prediction.id}`;

    // const margins = chart.layout.margin;
    // const marginTop = margins?.t as number;
    // const marginBottom = margins?.b as number;
    // const fontSize = chart.layout.font?.size as number;
    // const totalPx = CHART_HEIGHT - marginTop - marginBottom;
    // const pixelsInTick = totalPx / (chartYMax - chartYMin);
    // const minIntersectionPx = fontSize * 0.8;
    // const isYIntersect = (a: Partial<Plotly.Annotations>, b: Partial<Plotly.Annotations>) => {
    //   return Math.abs(pixelsInTick * (a.y as number - (b.y as number))) <= minIntersectionPx;
    // };

    const annotationYAxis = (y: number, extra?: Partial<Plotly.Annotations>) => {
      return Object.assign({
        x: 1,
        align: "right",
        xref: 'paper',
        y: y,
        borderpad: 1,
        borderwidth: 1,
        xshift: 8,
        xanchor: 'left',
        yanchor: 'middle',
        text: ` ${d.priceFormat(y)} `,
        showarrow: false,
        font: { color: '#fff', },
      }, extra) as Partial<Plotly.Annotations>;
    }

    const aCloseValue = annotationYAxis(d.lastStats.c, { bgcolor: 'rgba(138,126,247,0.8)', bordercolor: 'rgba(138,126,247,0.8)' });
    const bgColorLabel = { bgcolor: 'rgba(11, 9, 32, 0.5)' };

    // close value annotation
    chart.layout.annotations?.push(aCloseValue);

    if ((isPredValueAt || [PredictionTypeEnum.VALUE_CLOSE, PredictionTypeEnum.VALUE_AT_8PM].includes(Number(prediction.typeId)))) {
      const aPredHigh = annotationYAxis(predHigh, bgColorLabel);
      chart.layout.annotations?.push({ ...aPredHigh, ...highLowAnnotationColor });

      const aPredLow = annotationYAxis(predLow, highLowAnnotationColor);
      chart.layout.annotations?.push(aPredLow);
    }

    const aPredMiddle = annotationYAxis(predMiddle, bgColorLabel);
    if (PredictionTypeEnum.VALUE_HIGH === prediction.typeId) {
      chart.layout.annotations?.push({ ...aPredMiddle, ...highLowAnnotationColor });
    }

    if (PredictionTypeEnum.VALUE_LOW === prediction.typeId) {
      chart.layout.annotations?.push({ ...aPredMiddle, ...highLowAnnotationColor });
    }

    if ([
      PredictionTypeEnum.VALUE_30_MIN,
      PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
      PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
      PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
      PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
      PredictionTypeEnum.VALUE_1H_UP_DOWN,
      PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
      PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
    ].includes(Number(prediction.typeId))) {
      if (!isPending()) {
        chart.layout.annotations?.push({ ...aPredMiddle, ...highLowAnnotationColor });
      }
    }

    if ([
      PredictionTypeEnum.VALUE_30_MIN,
      PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
      PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
      PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
      PredictionTypeEnum.VALUE_1H_UP_DOWN,
      PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
      PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
      PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,

    ].includes(Number(prediction.typeId))) {
      if (!isPending()) {
       chart.layout.annotations?.push({ ...aPredMiddle, ...highLowAnnotationColor });
      }
    }

    addLabels();


    // draw hourly vertical lines
    // It should be added after all other shapes to be below all elements, grid lines doesn't support layers
    for (const t of d.tickVals) {
      const line = ChartModel.shapeVerticalLinePredictionTime(t, 0, 1, ChartModel.gridLinesColor);
      line.layer = 'below';
      chart.layout.shapes.push(line);
    }
    // const setChartTime = new ProfilerTime().start();
    chart.onInitialized = (figure, plotlyDiv: Readonly<HTMLElement>) => {
      const p = ChartModel.parseColor;
      setPlotlyDiv(plotlyDiv);
      ChartModel.setSvgGradients(plotlyDiv, [
        { gradient: { rotate: (isNext30UpDn || isNext1HrUpDn || isCloseUpDn || is3DCloseUpDn || isCloseUpDnBig) ? 90 : -90, color: p('rgb(53, 14, 14)') }, replaceColor: p(ChartModel.rectangleRedColor) },
        { gradient: { rotate: (isNext30UpDn || isNext1HrUpDn || isCloseUpDn || is3DCloseUpDn || isCloseUpDnBig) ? -90 : 90, color: p('rgb(14, 53, 47)') }, replaceColor: p(ChartModel.rectangleGreenColor) },
        { gradient: { rotate: 90, color: p(greyRectColor) }, replaceColor: p(greyRectColor) },
      ]);
      // ChartModel.setSvgGradient(div, ChartOptions.rectangleRedColor, {
      //   rotate: -90, startColor: 'rgba(38, 40, 40, 0.4)', stopColor: 'rgb(53, 14, 14)'
      // });
      // ChartModel.setSvgGradient(div, ChartOptions.rectangleGreenColor, {
      //   rotate: 90, startColor: 'rgba(38, 40, 40, 0.4)', stopColor: 'rgb(14, 53, 47)'
      // });
      // ChartModel.setSvgGradient(div, greyRectColor, {
      //   rotate: 90, startColor: greyRectColor, stopColor: greyRectColor
      // });
    };

    setChartData(chart);

  }, [prediction, cardWidth, chartType, isPending]);

  useEffect(() => {

    if (refCard.current &&
      Helper.isInViewPort(refCard.current, 1000) &&
      (        
       cardWidth !== lastWidth                                            // load if resized
      )
    ) {
      _load();
    }

    // if (onScrollBottom && hasReachedBottom()) {
    //   onScrollBottom();
    // }

  }, [_load, prediction, cardWidth, lastWidth])

  useEffect(()=>{

    if( chartType !== lastChartType) {
      _load();
    }

  }, [chartType, _load, prediction.id, lastChartType, predictionStatus])

  /* interval to re-check pending prediction */
  useEffect(()=>{

    if (predictionStatus !== PredictionStatusEnum.PENDING) {
      // exit if prediction is not pending
      return;
    }

    let recheckTimer: NodeJS.Timer;
    const timeoutMs = 5_000;

    recheckTimer = setInterval(async ()=> {
      const s: ISearchOptions = {
        predictionId: prediction.id,
      }
      const p = await userPredictionApiService.getAllPublic(s);
      if (p.length > 0) {
        const s = p[0].statusId;
        if (s !== predictionStatus) {
          // changed
          setPredictionStatus(s as PredictionStatusEnum);
          setPrediction(p[0]);

          // clear the timer
          clearTimeout(recheckTimer);
        } 
      } 
    }, timeoutMs)

    // clean up
    return () => {
      if (recheckTimer) {
        clearTimeout(recheckTimer);
      }
    }

  },[])

  useEffect(()=>{
    _load();
  },[predictionStatus, _load])


  const getWalkthrough = (): IWalkthrough => {
    const predictionUserDiv = predictionUserRef.current?.getElementsByClassName('predictor-detail')[0] as HTMLDivElement;
    const model = new WalkthroughPredictionsModel(plotlyDiv, predictionUserDiv);
    return model.getWalkthrough();
  }

  const removeFade = () => {
    const predictionUserDiv = predictionUserRef.current?.getElementsByClassName('predictor-detail')[0] as HTMLDivElement;
    const model = new WalkthroughPredictionsModel(plotlyDiv, predictionUserDiv);
    return model.removeFade();
  }

  const onClickChart = () => {
    if (!isAltStyle) {
      // cannot use navigate. Doing so does not update the first chart
      // navigate(UrlHelper.getExpandedPrediction(prediction.id as number))
      window.location.href = UrlHelper.getExpandedPrediction(prediction.id as number);
    }
  }

  return (
    <div className={`mt-2 ${isAltStyle ? 'alternate-card' : ''}`}>
      {chartData && prediction &&
        <PredictionResult prediction={prediction} />
      }

      <div className={`predictor-card ${isAltStyle ? '' : 'bg-off-black'} container g-0 mb-4`} ref={refCard} {...rest} >
        <div className="predictor d-flex m-3 align-items-start">

          {showProfile && chartData &&
            <div ref={predictionUserRef}>
              <PredictionUser prediction={prediction} />
            </div>
          }
          <div className="d-flex flex-column justify-content-end ms-auto">
            <div className="d-flex flex-row my-1">
              {chartData &&
                <PredictionTime prediction={prediction} />
              }
            </div>
          </div>
        </div>

        {prediction &&
          <div className="predictor-card-chart" >
            {!chartData && <Spinner minHeight={50} />}
            {chartData &&
              <>
                {/* walkthrough */}
                {!!showWalkthrough && plotlyDiv && <div ref={walkthroughRef}>
                <Walkthrough onClose={()=>{
                    if (setShowWalkthrough) {
                      setShowWalkthrough(false);
                      localStorage.setItem(StorageKey.SEEN_WALKTHROUGH_PREDICTION, '1');
                    }
                    removeFade();
                  }} onIndexChange={setWalkthroughIndex} walkthrough={getWalkthrough()} />
                </div>}

                <div className="mx-3" style={{ position: "relative" }}>
                  <div className="d-flex flex-column flex-column-reverse flex-md-row  justify-content-between align-items-center">
                    <div>
                  <PredictionDetail prediction={prediction} price={price} />
                  </div>
                  {isPending() &&
                    <div className="pill px-3 py-2 bg-darkest-gray no-hover align-self-end w-sm-100 mb-2 mb-md-0">
                      <div className="d-flex justify-content-center flex-nowrap gap-2 align-items-center">
                        <Spinner isSmall={true} minHeight={10} /><div className="opacity-70 text-14">Calculating...</div>
                      </div>
                    </div>
                  }
                </div>

                  {hasToggle() && <div className="my-2"><ChartTypeToggle chartType={chartType} setChartType={_setChartType}/></div>}

                  <div className={`d-flex flex-row justify-content-center align-items-stretch ${isAltStyle ? '' : 'cursor-pointer'}`} style={{ "height": `${ChartModel.chartHeight}px`, "width": `100%` }} role={isAltStyle ? undefined : "button"} onClick={onClickChart}>
                    <PredictionChart chartData={chartData} />
                  </div>
                  <div className="watermark" style={{ position: 'absolute', bottom: `${ChartModel.chartHeight * .30}px`, left: "0", opacity: "0.30" }}>
                    &copy; {new Date().getFullYear()} predictagram.com
                  </div>
                  <SharePrediction prediction={prediction} />
                </div>
              </>
            }
          </div>
        }
      </div>
    </div>
  );
}
