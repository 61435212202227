import React, { useContext, useState } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from "react-bootstrap";
import { Url } from "_constants";

interface IFaq {
  question: string;
  answer: any;
}

// function CustomToggle({ children, eventKey, setActiveKey }: { children: any, eventKey: any, setActiveKey: any }) {
//   const decoratedOnClick = useAccordionButton(eventKey, () =>
//     setActiveKey(eventKey)
//   );

//   return (
//     <div
//       onClick={decoratedOnClick}
//     >
//       {children}
//     </div>
//   );
// }

function ContextAwareToggle({ children, eventKey, callback }: { children: any, eventKey: any, callback: any }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div role="button" className="d-flex flex-row align-items-start" onClick={decoratedOnClick}>
      <div className={`m-3 fw-bold ${isCurrentEventKey ? 'orange' : 'primary-dark'}`}>{isCurrentEventKey ? "-" : "+"}</div>
      <div
        className={`my-3  ${isCurrentEventKey ? 'orange' : 'primary-dark'}`}
      >
        {children}
      </div>

    </div>
  );
}

const Faq: React.FunctionComponent = () => {
  const questions: IFaq[] = [
    {
      question: "What is included in a Virtual Preventive Health Visit?",
      answer: (
        <p>Your visit includes an online video visit with a US board-certified physician who will ask you several questions about your health and family history, perform a directed online physical exam, and will order any recommended screening tests, immunizations, labs and imaging studies. </p>
      ),
    },
    {
      question: "How can you do a health visit without physically examining me?",
      answer: <p>We can obtain the vast majority of relevant physical exam information from a video exam, where the physician can direct you to assist in feeling and moving different parts of your body. If the physician determines that you require an in-person exam, they will advise you accordingly.</p>,
    },
    {
      question: "What happens if there are tech issues that prevent me from using video?",
      answer: <p>Our staff can call you by phone to complete the virtual visit.</p>,
    },
    {
      question: "How will I get lab tests done?",
      answer: <p>We will order lab tests at a lab local and convenient to you where you can go to get your blood drawn and provide any other ordered test specimens. We use the two largest labs in the country. </p>,
    },
    {
      question: "How will I get imaging tests done?",
      answer: <p>You will be asked for the contact information for your preferred imaging center where you can be sent for testing.</p>,
    },
    {
      question: "How much does this cost me?",
      answer: <p>An annual Preventive health visit is usually zero cost to patients, with no co-pay or deductible if your health plan is contracted with Convena Care. You must have not had another annual physical or wellness visit less than 12 months prior to qualify. If you do not have a participating insurance plan, you can view our self-pay price list on our home page.</p>
    },
    {
      question: "How do I know if my insurance will cover this visit?",
      answer: <p>You will be asked to enter your insurance plan information during the registration process and our staff will check to make sure you are eligible. We may not be able to determine if you have had a similar annual exam less than 12 months ago, however, so you should check that to make sure you are not responsible for any uncovered costs. </p>,
    },
    {
      question: "If I am self-pay, when do I have to pay?",
      answer: <p>We typically require self pay payments just prior to the visit. You will be given a payment link by our staff when you come online to start your visit.</p>,
    },
    {
      question: "Does Convena Care also handle other types of medical issues?",
      answer: <p>Yes, we handle various minor urgent care complaints such as cough, UTI, sore throats, allergies, etc. You can make an appointment by clicking the Make Appointment button.</p>,
    },
    {
      question: "Do I need special video software to use Convena Care?",
      answer: <p>No. Any standard web browser should work. We will send you a link for the online telehealth visit before the scheduled time. Just click the link to begin the visit. </p>,
    },
    {
      question: "How do I reschedule my appointment?",
      answer: <p>You can login to your account at ConvenaCare.com and go to the My Appointments page where you can find your appointment. Just click on the Reschedule button and select another appointment from the screen.</p>,
    },
    {
      question: "How do I get my lab and imaging results?",
      answer: <p>You will be given access to our User Care Portal, which is separate from the ConvenaCare.com site. Your results will be reviewed by our staff and sent there for your review. You will receive an email once results are made available to you. It’s a good idea to check the portal periodically on your own, as sometimes emails may go to spam.</p>,
    },


  ];

  const [activeKey, setActiveKey] = useState<string>("");

  return (
    <div className="faq">
      <Accordion>
        {questions.map((q: IFaq, i: number) => (
          <Accordion.Item key={`faq-item-${i}`} className="small-text-4 primary-dark" eventKey={i.toString()}>
            {/* <CustomToggle eventKey={i.toString()} setActiveKey={setActiveKey}>
                <div className={`small-text-3 fw-bold ${activeKey === i.toString() ? "orange" : "primary-dark"}`}>
                {q.question}
                </div>
              </CustomToggle> */}
            <ContextAwareToggle eventKey={i.toString()} callback={setActiveKey}>
              <div className={`small-text-3 fw-bold ${activeKey === i.toString() ? "orange" : "primary-dark"}`}>
                {q.question}
              </div>
            </ContextAwareToggle>
            <Accordion.Body>
              <div className="small-text-2 mx-4">{q.answer}</div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export { Faq }