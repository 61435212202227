import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';



export const RequestAccessButton:React.FunctionComponent<{color?: "white" | "platinum"}> = ({color="white"}) => {

  const navigate = useNavigate();

  const onClick = async () => {
    navigate(Url.USER_TRADEGRAM);
  }

  if (color === "platinum") {
    return (
      <div role="button" className="bg-gradient-platinum px-4 py-2 text-black text-12 fw-bold pill d-inline text-center" onClick={onClick}>Request Access</div>
    );
  }

  return (
    <div role="button" className="pill bg-white fw-bold text-black d-inline p-2 px-4 text-14" onClick={onClick}>Request Access</div>
  )

}

