import { Url } from "_constants";
import { IStockSymbol } from "interfaces/IStockSymbol";
import { DateEx, PredictionTypeEnum, PredictionTypeHelper } from "predictagram-lib";

import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PredictionWizard, WizardStep } from "../prediction/PredictionWizard";
import { IContest } from "interfaces/IContest";
import { StockHelper } from "predictagram-lib";
import { sortTypeEnum } from "components/public/ListingsPage/components/Prediction/SortTypeSelect";
import { IProfileTeam } from "interfaces/IPublicProfile";
import { useReferral } from "_hooks/useReferral";
import { usePublicProfile } from "_hooks/usePublicProfile";
import { userPredictionApiService } from "services/UserPredictionApiService";

export const ModalWizard: React.FunctionComponent<{ team?: IProfileTeam, show: boolean, setShow: any, contest: IContest }> = ({ team, show, setShow, contest }) => {

  const initialSymbol: IStockSymbol = {
    symbolName: contest.stockSymbols ? contest.stockSymbols[0] : '',
  } as IStockSymbol

  const navigate = useNavigate();
  const referral = useReferral();
  const {item: referrerProfile, loaded: profileLoaded, error: profileError}  = usePublicProfile(Number(referral?.userId), userPredictionApiService, false);


  const onClickClose = () => { };
  const onComplete = async () => {
    navigate(Url.PUBLIC_STOCK_PREDICTIONS.replace(':stockSymbol', initialSymbol.symbolName), {
      state: {
        contest,
        sortType: sortTypeEnum.LATEST,
        team, // user's team
        referrerProfile
      }
    });
    setShow(false);
  };
  const onRetrySignup = () => { };

  const handleClose = () => { setShow(false) }

  const getContestValueAt = (contest: IContest): DateEx => {
    if (!contest.predictionTypes || contest.predictionTypes.length === 0) {
      throw Error('Missing Contest Prediction Type');
    }

    const predictionTypeId = Number(contest.predictionTypes[0]);

    // default to closing time
    const t = predictionTypeId === PredictionTypeEnum.VALUE_AT_8PM ? PredictionTypeEnum.VALUE_AT_8PM : PredictionTypeEnum.VALUE_CLOSE;
    return PredictionTypeHelper.predictionValueAt(t, new Date(contest.endTime * 1000));
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>

      {!contest.predictionTypes && 
      <div className="m-5 p-5 d-flex justify-content-center align-items-center">Error. No prediction type.</div>
      }

      {contest.predictionTypes && 
        <PredictionWizard
          onClickClose={onClickClose}
          onComplete={onComplete}
          onRetrySignup={onRetrySignup}
          showShareOnSavePrediction={team !== undefined}
          initialSymbol={initialSymbol}
          initialPredictionType={Number(contest.predictionTypes[0])}
          initialWizardStep={WizardStep.SET_PREDICTION}
          initialValueAt={getContestValueAt(contest)}
        />
      }
      </Modal.Body>
    </Modal>
  );
}
