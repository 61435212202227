import React, { useEffect, useRef } from 'react'
import { ITradegramSecurity, tradegramApiService } from 'services/TradegramApiService'
import { useTradegramSecurityTransactions } from '_hooks/useTradegramSecurityTransactions';
import { TradeLineItem } from './TradeLineItem';

export const Trades: React.FunctionComponent<{ security: ITradegramSecurity, onload: ()=>void}> = ({ security, onload}) => {

  const trades = useTradegramSecurityTransactions(tradegramApiService, security.id, false);

  const ref = useRef(onload);

  useEffect(()=>{
    if(trades.state.isLoaded()) {
      if (ref.current) {
        ref.current();
      }
    }
  },[trades.state])

  if (trades.state.isError()) {
    return <div className="alert alert-danger">Error: {trades.state.error()?.message}</div>
  }

  
  return (
    <div className="tradegram-card-trades"  style={{maxHeight: "300px", overflowY: "auto"}}>
        {trades.items.map((t, i) => <div className="d-flex tradegram-card-trades-items" key={`trades-key-${i}`}>
          <TradeLineItem tradegramTransaction={t} security={security} index={i}/>
        </div>
      )}
    </div>

  );
}