import React from 'react';
import {  FieldWithError } from 'components/common';
import { Modal } from 'react-bootstrap';
import { Form, Formik } from 'formik';


export const AdminModalInvite: React.FunctionComponent<{
  show: boolean,
  handleClose: any,
  onSave: any,
}> = ({ show, handleClose, onSave }) => {


  const initialValues = {
    emails: '',
  }

  const onSubmit = (data: any, actions: any) => {
    onSave(data.emails);
    handleClose();
  }

  return (
    <Modal className="admin-modal" show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header>
        <Modal.Title>Invite Users to Contest</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body >
        <div className="row justify-content-center">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, errors, touched, isSubmitting }) => {
              return (
                <Form>
                <div className="container">
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="emails"
                    placeholder=""
                    label="Email Addresses separated by new line"
                    type="textarea"
                    as="textarea"
                    rows={5}
                  />

                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Send Invitations</button>
                </div>
                </Form>
              )
            }}
          </Formik>

        </div>
      </Modal.Body>
    </Modal>

  );
}


