import React, {useEffect, useState} from "react";
import {DateHelper, IAdminReport} from "predictagram-lib";
import {adminApiServiceCommon} from "../../../../services/AdminApiService";
import {ExportToCsv} from "export-to-csv-fix-source-map";
import {AdminReportTypeHelper} from "predictagram-lib/dist/dict/admin-report-type.enum";
import {ListContainer} from "../../../common";
import {IApiService} from "../../../../interfaces";

export const AdminReportSavedReports: React.FunctionComponent = () => {
  const [fieldsList, setFieldList] = useState<any>([]);

  useEffect(() => {
    const fields: Map<string, [string, Function | undefined]> = new Map([
      ['id', ['ID', undefined]],
      ['typeId', ['Name', (v:IAdminReport)=>AdminReportTypeHelper.getNames().get(v.typeId)]],
      ['createdAt', ['Created EST', (v:any)=>DateHelper.dateTimeFormatUs(v.createdAt)]],
      ['download', ['Download', (v:any)=>{
        return <button type="button" className="btn btn-primary" onClick={()=>generateReport(v.id)}>
          Download
        </button>
      }]],
    ]);
    setFieldList(fields);
  }, []);

  const generateReport = async(reportId: number) => {
    const result = await adminApiServiceCommon.getSavedReport(reportId);
    if (!result) {
      return;
    }
    const name = AdminReportTypeHelper.getNames().get(result.typeId) as string || '';
    const dateStr = (time:number)=>DateHelper.dateFormatUtc(time);
    const filename = `report_${name.toLowerCase().replaceAll(' ', '_')}-${dateStr(result.createdAt)}`;
    const csv = new ExportToCsv({useKeysAsHeaders: true, quoteStrings: '"', filename});
    csv.generateCsv(result.data);
  }

  return (
    <>
      <ListContainer
        apiService={
          {
            getAll: async()=>adminApiServiceCommon.getSavedReports({}),
          } as IApiService<IAdminReport>
        }
        emptyValues={[] as any}
        fieldList={fieldsList}
        editableFields={[]}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }) => {
          return (
            <></>
          );
        }}
      </ListContainer>


    </>
  );
};


