import { userAccountService } from "services";
import { useApi } from "./useApi";
import { IRecentActivity } from "interfaces/IRecentActivity";

export function useRecentActivity (isTest: null | string) {
  const load = async () => {
    if (isTest !== null) {
      const testData = require('../_data/recent-activity.json');
      return testData;
    }
    return await userAccountService.getRecentActivity();
  }
  return useApi<IRecentActivity[], undefined>(load, undefined);
}

