import React, { useContext, useEffect, useState } from 'react';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/common';
import { ModalSettings } from 'components/user/account/ModalSettings';
import { ModalProfile } from 'components/user/account/ModalProfile';
import * as Icons from "components/common/Icons";
import { IAccount } from 'interfaces';
import { FollowButton } from '../ListingsPage/components/Prediction/FollowButton';
import { Url } from '_constants';
import { TeamInfo } from './TeamInfo';
import { IAward } from 'interfaces/IAward';
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar';
import { PredictorAccuracy } from './predictor/PredictorAccuracy';
import { PredictorProfitability } from './predictor/PredictorProfitability';
import { RoundBackButton } from 'components/common/buttons/RoundBackButton';
import { ShareProfile } from 'components/common/ShareProfile';
import { Stats } from 'components/common/Icons';
import { StatsDateFilterEnum } from 'components/user/stats/StatsDateDropDownFilter';
import { UrlHelper } from '_utils/UrlHelper';
import { StockHelper } from 'predictagram-lib';
import { usePublicProfile } from '_hooks/usePublicProfile';
import { userPredictionApiService } from 'services/UserPredictionApiService';

export const PredictorCard: React.FunctionComponent<{ user: IPublicProfile, userId: number, loggedInUserId: number }> = ({ user, userId, loggedInUserId }) => {
  const navigate = useNavigate();
  const acctContext = useContext(AccountContext);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IPublicProfile>(user);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const publicProfileApi = usePublicProfile(userId, userPredictionApiService, false);
  const [hover, setHover] = useState<boolean>(false);
  
  const getStatsUrl = (loggedInUserId: number, statsUserId: number) => {
    if (loggedInUserId === statsUserId) {
      return `${UrlHelper.getUserStatSymbol(statsUserId)}/?df=${StockHelper.isMarketOpen(new Date()) ? StatsDateFilterEnum.TODAY : StatsDateFilterEnum.YESTERDAY}`;
    }
    return UrlHelper.getUserStatSymbol(statsUserId);
  }

  useEffect(() => {
    const { nickname, avatarUrl, aboutMe, twitterUsername } = user;

    const acct = {
      ...user,
      nickname: nickname || '',
      avatarUrl: avatarUrl || '',
      aboutMe: aboutMe || '',
      twitterUsername: twitterUsername || '',
    }
    setCurrentUser(acct)
  }, [user])

  const onSaveCallback = (account: IAccount) => {
    // update the user profile
    const { nickname, avatarUrl, aboutMe, twitterUsername } = account;
    setCurrentUser({
      ...user,
      nickname: nickname || '',
      avatarUrl: avatarUrl || '',
      aboutMe: aboutMe || '',
      twitterUsername: twitterUsername || '',
    })
    setShowProfile(false);
  }


  const FollowerSection = () => {
    return (
      <div>
        {acctContext && acctContext.account !== null && acctContext?.account.id === userId ? (
          <div className="d-flex gap-3">
            <div><a href={Url.USER_DASHBOARD_FOLLOWERS} className="text-white text-underline-hover fw-bold">{acctContext.account.followers} <span className="text-white opacity-70 fw-normal">Followers</span></a></div>
            <div><a href={Url.USER_DASHBOARD_FOLLOWS} className="text-white text-underline-hover fw-bold">{acctContext.account.followees} <span className="text-white opacity-70 fw-normal">Following</span></a></div>
          </div>
        ) : (
          <div className="d-flex gap-3">
            {publicProfileApi.loaded &&
              <>
                <div>{publicProfileApi.item.followerCount || 0} <span className="text-white opacity-70">Followers</span></div>
                <div>{publicProfileApi.item.followeesCount || 0} <span className="text-white opacity-70">Following</span></div>
              </>
            }
          </div>
        )}
      </div>
    );
  }

  const AboutMe = () => {
    return (
      <div className="my-3 predictor-card-footer-about-me">{currentUser.aboutMe?.split("\n").map((p: string, i: number) => <p key={`p-key-${i}`}>{p}</p>)}</div>
    );
  }

  const TwitterPill = () => {
    return (<>
      {currentUser.twitterUsername &&
        <div>
          <a href={`https://twitter.com/${currentUser.twitterUsername}`} target="_blank" rel="noreferrer">
            <div className="btn-tabs p-2 px-3 btn-tabs-active bg-black d-inline-flex flex-row justify-content-start align-items-center gap-2">
              <img src="/images/x.svg" style={{width:"0.9375rem", height: "0.9375rem" }} alt="" />
              <div className="fw-normal text-14">{`@${currentUser.twitterUsername}`}</div>
            </div>
          </a>
        </div>
      }
      </>
    );
  }


  const TwitterTeam = () => {
    return (
      <div className="d-flex justify-content-start gap-2">
        <TwitterPill />
        <div>{currentUser.team &&
          <TeamInfo profileTeam={currentUser.team} className="bg-black"/>
        }
        </div>
      </div>
    );
  }


  const UserAwards = () => {
    return <>
      {user.awards && user.awards.length > 0 &&
        <div className="d-flex gap-1 justify-content-end align-items-center">
          {
            user.awards.map((award: IAward, i: number) => <div className="predictor-award" key={`predictor-card-key-${i}`}><img src={award.imageUrl} alt="award" title={award.description} /></div>)
          }
        </div>
      }
    </>;
  }

  return (
    <>
      {acctContext?.isLoggedIn() &&
        <>
          <ModalSettings handleClose={() => setShowSettings(false)} show={showSettings} />
          <ModalProfile handleClose={() => setShowProfile(false)} show={showProfile} onSaveCallback={onSaveCallback} />
        </>
      }
      <div className="predictor-card-head d-flex flex-row justify-content-center align-items-center pb-2 gap-4">
        <div className="flex-grow-0" role="button" onClick={() => { navigate(-1) }}>
          <RoundBackButton />
        </div>

        <div className="predictor-card-head-nickname flex-grow-1">{currentUser.nickname}</div>
        <div className="flex-grow-0">
          {acctContext?.account && acctContext?.account.id === userId ? (
            <div className="d-flex flex-row justify-content-center align-items-center gap-2">
              <div className="btn btn-tertiary text-14 flex-shrink-0" onClick={() => setShowProfile(true)} role="button">Edit Profile</div>
              <div className="btn-circle border-white d-flex justify-content-center align-items-center p-3"><ShareProfile user={currentUser} /></div>
              <div onClick={() => setShowSettings(true)} role="button"><Icons.SettingsDark /></div>
            </div>
          ) : (
            <>{acctContext?.isLoggedIn() && <FollowButton userId={userId} />}</>
          )}
        </div>

      </div>

      <div className="">
        <div className="predictor-card-body d-flex flex-row ">
          <div className="predictor-card-body-image followee-card-image">
            <a className="predictor-link" href={`${Url.PUBLIC_STOCK_PREDICTOR}/${userId}`}>{currentUser.userId && <Avatar profile={currentUser} size={AvatarSizeEnum.XXL} />}</a>
          </div>

          <div className="d-flex flex-column justify-content-center flex-fill ms-3">
            <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
              <div className="predictor-card-body-nickname flex-fill">
                <div className="d-flex gap-2">{currentUser.nickname} {!!currentUser.isVerified && <Icons.PurpleCheck />}</div>
                <div className="predictor-card-body-at">@{currentUser.username}</div>
              </div>
              <UserAwards />
            </div>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between justify-content-md-start my-3 gap-1 gap-md-3">
          <PredictorAccuracy profile={currentUser} />
          <PredictorProfitability profile={currentUser} />
        </div>

        {currentUser.userId &&
          <div onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}
              className="btn-all-stats my-3 text-12" role="button" onClick={() => navigate(getStatsUrl(loggedInUserId, currentUser.userId as number))}>
            <div className="d-flex align-items-end">
             <div style={{height:"1.1875rem"}}><Stats on={hover} /></div><div className="ms-2 ">See All Stats</div>
            </div>
          </div>
        }

        <div className="bg-off-black no-hover border-radius-15 p-3">
          <FollowerSection />
          <AboutMe />
          <TwitterTeam />
        </div>


      </div>
    </>
  );
}