import React, { useEffect, useState } from "react";
import { Spinner } from "components/common/Spinner";
import { Form, Formik } from "formik";
import ReactDatePicker from "react-datepicker";
import { adminSignalAlertApiService } from "../../../../services/AdminApiService";
import { StockHelper } from "../../../../_utils/stock.helper";
import { DropDown } from "../../../common/form/DropDown";
import { SymbolIdsDropdown } from "../../../common/SymbolIdsDropdown";
import { FilterOptions } from "./alert-summary.model";
import { AlertSummaryModel as model } from "./alert-summary.model";

const Filter: React.FunctionComponent<{
  initialValues?: Partial<FilterOptions>,
  onClick: (search: any) => void,
}> = ({ initialValues, onClick }) => {

  const stockDay = StockHelper.findTradingDay(new Date(), -1, false);
  const todayStart = stockDay.openAt();
  const todayEnd = stockDay.closeAt();

  const [alerts, setAlerts] = useState<any>([]);

  const defVals: FilterOptions = {
    startTime: todayStart,
    endTime: todayEnd,
    signalAlertIds: [],
  };
  const initVals: FilterOptions = Object.assign(defVals, initialValues) as FilterOptions;

  useEffect(() => {
    (async () => {
      const signalAlerts = await adminSignalAlertApiService.getAll(true);
      const mp = new Map<number, string>();
      for (const s of signalAlerts) {
        mp.set(s.id, `${s.id} - ${s.name}`);
      }
      setAlerts(mp);

    })();
  }, []);


  return (
    <div>
      <Formik initialValues={initVals} onSubmit={onClick}>
        {({ values, touched, errors, setFieldValue }) => {
          return <Form>
            <div className="d-flex justify-content-start align-items-center gap-3 my-3">
              <DropDown enumMap={alerts}
                errors={errors}
                touched={touched}
                label="Signal" name={'signalAlertIds'}
                multiple={true}
              />

              <SymbolIdsDropdown errors={errors} touched={touched} name="stockSymbolId" />
              <div className="d-flex flex-column">
                <span className="fw-bold">Start Date</span>
                <ReactDatePicker
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("startTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.startTime * 1000)}
                />
              </div>

              <div className="d-flex flex-column">
                <span className="fw-bold">End Date</span>
                <ReactDatePicker
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("endTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.endTime * 1000)}
                />
              </div>
              <div className="align-self-end">
                <button type="submit" className="btn btn-primary p-2">Generate Report</button>
              </div>
            </div>

          </Form>
        }}

      </Formik>
    </div>
  )
}





export const AdminReportAlertCombined: React.FunctionComponent = () => {


  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');

  const generateReportWrap = async (searchOptions: FilterOptions) => {
    try {
      await model.generateReport(searchOptions, setStatus, setIsLoading);
    } catch (e: any) {
      console.error(e);
      setStatus(e.toString());
    }
  }


  return (
    <>
      <div>
        <div className="d-flex justify-content-center align-items-end gap-3">
          <Filter onClick={generateReportWrap} />
        </div>
      </div>

      {isLoading && <Spinner />}

      {status && <div>{status}</div>}
    </>
  );
};


