import React, { useContext, useEffect, useState } from 'react';
import { MessengerContext, Severity } from 'components/common/messenger';
import { IContest } from 'interfaces/IContest';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { userContestApiService } from 'services/common/ContestApiService';
import { Url } from '_constants';
import { AccountContext } from 'components/common';
import { ModalLogin } from '../account/ModalLogin';
import { useContestRankings } from '_hooks/useContestStats';
import { ContestHead } from './components/ContestHead';
import { ContestDetails } from './components/ContestDetails';
import { ModalWizard } from './ModalWizard';
import { ButtonOrStatus } from './components/ButtonOrStatus';
import { ContestNavigation } from './components/ContestNavigation';
import { ContestState } from '_constants/ContestState';
import { ContestHelper } from '_utils/ContestHelper';
import { Helmet } from 'react-helmet';
import { config } from 'config';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { ModalPhoneConfirmationWizard } from '../account/ModalPhoneConfirmationWizard';


export const ContestLayout: React.FunctionComponent = () => {

  const [contest, setContest] = useState<IContest | undefined>(undefined);
  const { contestId } = useParams();
  const [cid, setCid] = useState<number>(0);
  const navigate = useNavigate();
  const msgrContext = useContext(MessengerContext);
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const acctContext = useContext(AccountContext);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showWizard, setShowWizard] = useState<boolean>(false);

  const [status, setStatus] = useState<string | undefined>(undefined);

  const rankings = useContestRankings(Number(contestId), userContestApiService);

  const [userProfile, setUserProfile] = useState<IPublicProfile | undefined>(undefined);
  const [showPhoneModal, setShowPhoneModal] = useState<boolean>(false);

  useEffect(() => {
    const _load = async () => {
      try {
        if (!contestId) {
          throw Error('Invalid contest id');
        }
        const cId = parseInt(contestId)
        setCid(cId);
        const contest: IContest = await userContestApiService.getContestPublic(cId);
        if (acctContext?.isLoggedIn()) {
          const contests = await userContestApiService.getAll(true, true);
          setIsJoined(contests.filter((c) => c.id === cId).length > 0);
          if (acctContext?.account.id) {
            const userProfile = await userPredictionApiService.getPublicProfile(acctContext?.account.id);
            setUserProfile(userProfile);
          }
        }
        setContest(contest);
      } catch (error: any) {
        setStatus(error.message);
        console.error({ error });
      }
    }
    _load();

  }, [contestId, msgrContext, acctContext, isJoined])

  const onLogin = () => {
    setShowLogin(false);
    const _join = async () => {
      try {
        navigate(Url.USER_CONTEST.replaceAll(':contestId', cid.toString()));
      } catch (error) {
        msgrContext.setMessage({ body: 'Unexpected login error. Please try again later.' }, true, Severity.FATAL);
        console.error({ error });
      }
    }
    _join();
  }

  const hasPredictions = acctContext?.isLoggedIn() ? rankings.items.findIndex(obj => obj.userId === acctContext?.account.id && obj.predictions > 0) >= 0 : false;
  const isVerified = acctContext?.isLoggedIn() && !!acctContext.account.phoneNumberConfirmed;
  const location = useLocation();

  return (
    <div className="container">
      {status && <div className="m-5 d-flex justify-content-center align-items-center">{status}</div>}

      {!status &&
        <>
          <ModalLogin handleClose={() => setShowLogin(false)} show={showLogin} onLogin={onLogin} />

          {contest &&
            <div className="contest">
              <Helmet>
                <title>Predictagram: {contest.name} </title>
                <meta property="og:title" content={`Predictagram: ${contest.name} `} />
                <meta property="og:description" content={`${contest.description}`} />
                <meta property="og:image" content={`${contest.logoUrl}`} />
                <meta property="og:url" content={`${config.wwwDomain}${location.pathname}`} />
              </Helmet>

              <ModalPhoneConfirmationWizard handleClose={()=>setShowPhoneModal(false)} show={showPhoneModal} onSuccess={()=>{}} />
              <ModalWizard setShow={setShowWizard} show={showWizard} contest={contest} team={userProfile?.team} />
              <ContestHead contest={contest} />
              <ContestDetails contest={contest} />

              {isVerified ? <>
                <ButtonOrStatus
                  contest={contest}
                  hasPredictions={hasPredictions}
                  isLoggedIn={acctContext?.isLoggedIn() || false}
                  isJoined={isJoined}
                  setShowWizard={setShowWizard}
                />
              </>
                :
                <ErrorMessage>
                  <div className="d-flex flex-column">
                    <div className="mb-3 text-16 fw-bold">
                    You must verify your phone number in order to qualify for contests.
                    </div>
                    <div>
                      <button className="btn btn-primary px-4 py-3" type="button" onClick={()=>setShowPhoneModal(true)}><span className="text-16">Verify Phone Number</span></button>
                    </div>
                    </div>
                </ErrorMessage>
              }

              {isJoined &&
                ContestHelper.getContestState(contest, new Date()) !== ContestState.PRE_OPEN &&
                <ContestNavigation contest={contest} />}
              <div className="px-md-3 px-1">
                <Outlet context={{ contest, isJoined, rankings }} />
              </div>
            </div>
          }
        </>
      }
    </div>
  );
}