import React, { useEffect, useState } from 'react'
import Card from './Card'
import { LineChart } from 'components/common/Icons'
import { useNavigate } from 'react-router-dom'
import { Url } from '_constants'
import { UrlHelper } from '_utils/UrlHelper'
import { useAccountSettings } from '_hooks/useAccountSettings'
import { APIState } from '_constants/APIState'
import { Helper } from '_utils'
import { useSymbols } from '_hooks/useSymbols'
import { ConsensusChartSmall } from 'components/user/dashboard/WatchList'

// const ConsensusChart: React.FunctionComponent<{ stockSymbol: string }> = ({ stockSymbol }) => {
//   return <>
//     {stockSymbol &&
//       <BubbleChart showPredictors={false} predictionTypes={undefined} stockSymbol={stockSymbol} heightOverride={200} title={
//         <div className="d-flex align-item-center gap-2">
//           <span className="prediction-detail-symbol"><Link to={Url.PUBLIC_STOCK_PREDICTIONS.replace(':stockSymbol', stockSymbol)}>${stockSymbol}</Link></span>
//           {(StockHelper.isMarketOpen(new Date()) || StockHelper.isPostMarketHours(new Date())) && <StockPriceChange stockSymbol={stockSymbol} />}
//         </div>
//       } />
//     }
//   </>
// }

export const DashboardCard: React.FunctionComponent<{ className?: string }> = ({ className = '' }) => {

  const navigate = useNavigate();
  const [watchedSymbols, setWatchedSymbols] = useState<string[]>([]);
  const [hasWatched, setHasWatched] = useState<boolean>(false);
  const settingsApi = useAccountSettings();
  const symbolsApi = useSymbols();

  const title = <div className="d-flex justify-content-start align-items-center gap-2">
    <LineChart />
    <div>Dashboard</div>
  </div>;

  const defaultSubtitle = 'See where the community thinks top symbols are going today!';

  const onClick = () => {
    navigate(Url.USER_DASHBOARD);
  }

  const onClickChart = (e: React.MouseEvent<HTMLDivElement>, stockSymbol: string) => {
    e.preventDefault();
    navigate(UrlHelper.getSymbolUrl(stockSymbol))
  }

  useEffect(()=>{
    if (settingsApi.apiState === APIState.LOADED && symbolsApi.apiState.isLoaded) {
      let watched: string[] = [];
      if (settingsApi.settings.symbolsWatchlist !== null) {
        watched = [...Helper.shuffleArray(settingsApi.settings.symbolsWatchlist).slice(0,2)];
        setHasWatched(true);
      }
      if (watched.length < 2) {
        if (watched.length === 0) {
          // fill it
          watched = ['SPY', 'QQQ']
        } else {
          watched = [...watched, ...Helper.shuffleArray(symbolsApi.data.filter(s=>s.symbolName!==watched[0]).slice(0,1).map(i=>i.symbolName))]
        }
      }
      setWatchedSymbols(watched);
    }
  }, [settingsApi.apiState, settingsApi.settings.symbolsWatchlist, symbolsApi.apiState.isLoaded, symbolsApi.data])

  const getSubtitle = () => {
    if (hasWatched) {
      return <div>{defaultSubtitle}</div>
    }
    return <div>{defaultSubtitle} <span className="text-decoration-underline">Set up dashboard</span></div>
  } 

  return (
    <Card hoverClassName='bg-lavender' name="dashboard" title={title} subtitle={getSubtitle()} onClick={onClick} className={`${className} lavender-gradient`}>
      <div className="d-flex gap-3 flex-column flex-md-row justify-content-md-between flex-md-nowrap mt-3 align-items-center">
        {watchedSymbols.map((stockSymbol: string, i: number) => <div key={`chart-${i}`} className={`${i > 0 ? 'd-none d-md-block' : ''} border-radius-15 infeed-card-dashboard-chart`}>
          <div className="m-3" role="button" onClick={(e: React.MouseEvent<HTMLDivElement>) => onClickChart(e, stockSymbol)}>
          <ConsensusChartSmall stockSymbol={stockSymbol} />
          </div>
        </div>)}
      </div>
    </Card>
  )
}
