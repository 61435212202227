import React, { useEffect, useState } from 'react';
import { PixelHelper } from '_utils/PixelHelper';
import { RoundFowardButton } from 'components/common/buttons/RoundForwardButton';


interface IProps {
  title: string | JSX.Element,
  subtitle: string | JSX.Element, 
  hoverClassName: string,
  name?: string,
  onClick: ()=>void,
  className?: string,
  children: any,
}
const Card:React.FunctionComponent<IProps> = ({title, subtitle, onClick, children, name, hoverClassName, className = ""}) => {

  const [hover, setHover] = useState<boolean>(false);

  useEffect(()=>{
    if (name) {
      PixelHelper.viewCard(name)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const hoverOn = () => {
    setHover(true);
  }

  const hoverOff = () => {
    setHover(false);
  }

  const _onClick = () => {
    if (name) {
      PixelHelper.clickCard(name)
    }
    onClick();
  }

  return (
    <div className={`infeed-card ${className} p-3 border-radius-15`} onMouseEnter={hoverOn} onMouseLeave={hoverOff}>
      <div className="infeed-card-head" role="button" onClick={_onClick}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column justify-content-start align-items-start gap-2">
            <div className="infeed-card-title text-16 fw-bold">{title}</div>
            <div className="infeed-card-subtitle text-14">{subtitle}</div>
          </div>

          <div className="flex-shrink-0">
          <RoundFowardButton className={`infeed-card-button`} hover={hover} hoverFillClassName={hoverClassName}/>
          </div>

        </div>
      </div>
      <div className="infeed-card-body">
        {children}
      </div>
    </div>
  )
}

export default Card