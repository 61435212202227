import { adminSignalAlertApiService } from "services/AdminApiService";
import { IApiResponse, useApi } from "./useApi";
import { useState } from "react";
import { ISignalAlert } from "components/admin/analysis/strategy-profit/ISignalAlert";

export interface IAdminSignalAlertApi {
  api: IApiResponse<ISignalAlert[], null>,
  getById: (id: number) => ISignalAlert | undefined,
}

export function useAdminSignalAlerts () {
  const [data, setData] = useState<ISignalAlert[]>([]);
  
  const load = async () => {
    const data = await adminSignalAlertApiService.getAll()
    setData(data);
    return data;
  }

  const getById = (id: number) => {
    return data.find(v=>v.id===id);
  }

  return {
    api: useApi<ISignalAlert[], null>(load, null),
    getById
  } as IAdminSignalAlertApi

}

