import { useApi } from "./useApi";
import { userAchievementService } from "services/UserAchievementService";
import { IUserTicketResponse } from "interfaces/IUserTicket";

export function useUserTickets (filename: string | null) {
  const load = async (abortSignal?: AbortSignal) => {
    if (filename !== null) {
      const testData = require('../_data/' + filename + '.json');
      return testData;
    }
    return await userAchievementService.getTickets(abortSignal);
  }
  return useApi<IUserTicketResponse[], undefined>(load, undefined);
}

