import { IStockSymbol } from 'interfaces/IStockSymbol';
import React, { useContext } from 'react';
import { DateHelper, PredictionTypeEnum, StockHelper, IntradayHelper, NewYorkTz, PredictionTypeHelper } from 'predictagram-lib';
import { IPrediction } from 'interfaces/IPrediction';
import { MessengerContext, Severity } from 'components/common/messenger';
import { Helper } from '_utils';

type DateString = {
  d: Date,
  s: string,
};

export const getWhen = (today: Date = new Date()): DateString => {
  //const mToday = moment.tz(TimeZone.AMERICA_NEW_YORK);
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  if (StockHelper.isMarketOpen(today) || (
    StockHelper.isTradingDay(today) && StockHelper.isPreMarketHours(today)
  )) {
    return { d: StockHelper.workingHours(today).end, s: 'Today' };
  }

  const nextTradingDay = StockHelper.getNextTradingDay(today);
  if (DateHelper.isSameDay(nextTradingDay, tomorrow)) {
    return { d: StockHelper.workingHours(tomorrow).end, s: 'Tomorrow' };
  }

  // it's not tomorrow. it could be weekend or holiday. 
  return { d: nextTradingDay, s: NewYorkTz.getWeekday(nextTradingDay) };
}


export const getMidTime = (today: Date = new Date()): DateString => {
  const plus2 = new Date();
  plus2.setHours(today.getHours() + 2);
  const plus2tm = NewYorkTz.format(plus2).hour24MinuteNumber();

  if (getWhen().s === 'Today') {
    if (plus2tm < 1500) {
      const halfHour = NewYorkTz.nearestHalfHour(plus2);
      return { d: halfHour, s: NewYorkTz.format(halfHour).hour24MinuteAmpm() };
    } else {
      return { d: plus2, s: NewYorkTz.format(plus2).hour24MinuteAmpm() };
    }
  }
  const nextDay = StockHelper.workingHours(plus2);
  const pred = new Date(nextDay.start.getTime());
  pred.setHours(pred.getHours() + 2); // + 2 hours to opening - 11:30
  return { d: plus2, s: NewYorkTz.format(pred).hour24MinuteAmpm() };
}

export const isIntradayAllowed = (now = new Date()): boolean => {
  const mTime: number = NewYorkTz.format(now).hour24MinuteNumber();
  return (StockHelper.isMarketOpen(now) && mTime < 1300) || (StockHelper.isPostMarketHours(now) || StockHelper.isPreMarketHours(now)) || NewYorkTz.isWeekend(now);
}

export const threeDaysFrom = (today: Date = new Date(), withTime: boolean = false) => {
  const valueAt = PredictionTypeHelper.predictionValueAt(PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D, today)
  const f = NewYorkTz.mdyhms(valueAt);
  const roundedHour = StockHelper.workingHours(valueAt).end;
  if (withTime) {
    return `${f.monthNameFull} ${f.day} @ ${Helper.roundUpToClosingHour(NewYorkTz.format(roundedHour).hour24MinuteAmpm())}`;
  }
  return `${f.monthNameFull} ${f.day}`;
}

export const isNextHourAllowed = (now = new Date()): boolean => {
  const mTime: number = NewYorkTz.format(now).hour24MinuteNumber();
  return (StockHelper.isMarketOpen(now) && mTime <= 1457);
}

export const isCloseUpDnAllowed = (now = new Date()): boolean => {
  return (StockHelper.isMarketOpen(now) && !StockHelper.isLastHalfHour(now));
}

export const PredictionTypeTitle: React.FunctionComponent<{ predictionTypeEnum: PredictionTypeEnum, symbol: IStockSymbol, today?: Date, withTime?: boolean }> = ({ predictionTypeEnum, symbol, today = new Date(), withTime = false }) => {
  switch (predictionTypeEnum) {
    case PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE:
      return <>Will <>{symbol?.symbolName}</> go up/down by {NewYorkTz.format(NewYorkTz.nextNMinutes(60, new Date())).hour24MinuteAmpm()} </>
    case PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE:
      return <>Will <>{symbol?.symbolName}</> go up/down by {NewYorkTz.format(NewYorkTz.nextNMinutes(30, new Date())).hour24MinuteAmpm()} </>
    case PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE:
      return <>Will <>{symbol?.symbolName}</> go up/down by {Helper.roundUpToClosing(NewYorkTz.format(StockHelper.workingHours().end).hour24MinuteAmpm())}</>
    case PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D:
      return <>Will <>{symbol?.symbolName}</> go up/down by {threeDaysFrom(today, withTime)}</>
    case PredictionTypeEnum.VALUE_30_MIN:
      return <>Where <>{symbol?.symbolName}</> will move by {NewYorkTz.format(NewYorkTz.nextNMinutes(30, new Date())).hour24MinuteAmpm()}</>
    case PredictionTypeEnum.VALUE_1H_UP_DOWN:
      return <>Will <>{symbol?.symbolName}</> go up/down by {NewYorkTz.format(NewYorkTz.nextNMinutes(60, new Date())).hour24MinuteAmpm()} </>
    case PredictionTypeEnum.VALUE_30_MIN_UP_DOWN:
      return <>Will <>{symbol?.symbolName}</> go up/down by {NewYorkTz.format(NewYorkTz.nextNMinutes(30, new Date())).hour24MinuteAmpm()} </>
    case PredictionTypeEnum.VALUE_AT:
      return <>Where <>{symbol?.symbolName}</> will be at {IntradayHelper.getMidTime(today).s} {IntradayHelper.getWhen(today).s}</>
    case PredictionTypeEnum.VALUE_LOW:
      return <>{symbol?.symbolName}'s low for {IntradayHelper.getWhen(today).s === 'Today' ? 'the rest of the day' : IntradayHelper.getWhen(today).s}</>
    case PredictionTypeEnum.VALUE_HIGH:
      return <>{symbol?.symbolName}'s high for {IntradayHelper.getWhen(today).s === 'Today' ? 'the rest of the day' : IntradayHelper.getWhen(today).s}</>
    case PredictionTypeEnum.VALUE_AT_8PM:
      return <>Where <>{symbol?.symbolName}</> will be at 8 PM {IntradayHelper.getWhen(today).s} </>
    default:
      return <>Where <>{symbol?.symbolName}</> will close {IntradayHelper.getWhen(today).s}</>
  }
}

interface IProps {
  symbol: IStockSymbol,
  onSelect: ((predictionTypeEnum: PredictionTypeEnum, valueAt: Date) => void),
  todaysPredictions?: IPrediction[],
  today?: Date
}
export const PredictionTypeSelect: React.FunctionComponent<IProps> = ({ symbol, onSelect, todaysPredictions, today = new Date() }) => {

  const msgrContext = useContext(MessengerContext);

  const _onSelect = (predictionType: PredictionTypeEnum) => {
    // check if already
    if (todaysPredictions) {
      const a = todaysPredictions?.filter((p) => p.typeId === predictionType);
      // console.log('have it', { a, predictionType })
      if (a.length > 0) {
        msgrContext.setMessage({ body: "You've already made a prediction of this type for this symbol today. We only allow one prediction type per symbol for each day" }, true, Severity.NORMAL);
        return;
      }
    }

    onSelect(predictionType, PredictionTypeHelper.predictionValueAt(predictionType, today));
  }

  const isDisabled = (predictionType: PredictionTypeEnum) => {
    if (!todaysPredictions) {
      return false;
    }
    const a = todaysPredictions?.filter((p) => p.typeId === predictionType);
    return a.length > 0;
  }

  const isShowUpDn = (symbol: string, date: Date = new Date()) => {
    const tm = NewYorkTz.format(date).hour24MinuteNumber();
    return tm >= 932
    // if (tm > 933) {
    //   return true;
    // }

    // // check if last quote is available today
    // const lastQuote = await predictionService.getLastQuote(symbol);
    // return (NewYorkTz.isSameDay(date, new Date(lastQuote.t * 1000)));

  }
  
  return (
    <div>
      <div className="mx-3 my-3">Select what type of prediction you'd like to make</div>
      <div className="d-flex flex-fill flex-column">

        {StockHelper.isMarketOpen(today) && isShowUpDn(symbol.symbolName, today) && <>

          {isCloseUpDnAllowed(today) && <div className="d-flex justify-content-start flex-column mb-2">
            <div className="text-lavender fw-bold text-12 mx-3">FEATURED</div>
            <button type="button" disabled={isDisabled(PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D)} data-testid="btn-3d-min-updn" className="btn-ptype btn btn-curved-side text-white my-2 bg-charcoal mx-3 text-sm-14" onClick={() => _onSelect(PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D)}><PredictionTypeTitle today={today} symbol={symbol} predictionTypeEnum={PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D} /></button>
          </div>
          }

          {/* <button type="button" disabled={isDisabled(PredictionTypeEnum.VALUE_30_MIN_UP_DOWN)} data-testid="btn-30-min-updn" className="btn-ptype btn btn-curved-side text-white my-2 bg-charcoal mx-3 text-sm-14" onClick={() => _onSelect(PredictionTypeEnum.VALUE_30_MIN_UP_DOWN)}><PredictionTypeTitle today={today} symbol={symbol} predictionTypeEnum={PredictionTypeEnum.VALUE_30_MIN_UP_DOWN} /></button> */}
          <button type="button" disabled={isDisabled(PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE)} data-testid="btn-30-min-updn-dist" className="btn-ptype btn btn-curved-side text-white my-2 bg-charcoal mx-3 text-sm-14" onClick={() => _onSelect(PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE)}><PredictionTypeTitle today={today} symbol={symbol} predictionTypeEnum={PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE} /></button>


          {isNextHourAllowed(today) &&
            // <button type="button" disabled={isDisabled(PredictionTypeEnum.VALUE_1H_UP_DOWN)} data-testid="btn-1hr-updn" className="btn-ptype btn btn-curved-side text-white my-2 bg-charcoal mx-3 text-sm-14" onClick={() => _onSelect(PredictionTypeEnum.VALUE_1H_UP_DOWN)}><PredictionTypeTitle today={today} symbol={symbol} predictionTypeEnum={PredictionTypeEnum.VALUE_1H_UP_DOWN} /></button>
            <button type="button" disabled={isDisabled(PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE)} data-testid="btn-1hr-updn-dist" className="btn-ptype btn btn-curved-side text-white my-2 bg-charcoal mx-3 text-sm-14" onClick={() => _onSelect(PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE)}><PredictionTypeTitle today={today} symbol={symbol} predictionTypeEnum={PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE} /></button>

          }

          {isCloseUpDnAllowed(today) && <>
            <button type="button" disabled={isDisabled(PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE)} data-testid="btn-30-min-updn" className="btn-ptype btn btn-curved-side text-white my-2 bg-charcoal mx-3 text-sm-14" onClick={() => _onSelect(PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE)}><PredictionTypeTitle today={today} symbol={symbol} predictionTypeEnum={PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE} /></button>
          </>
          }
        </>
        }
        {isIntradayAllowed(today) &&
          <button type="button" disabled={isDisabled(PredictionTypeEnum.VALUE_AT)} data-testid="btn-intraday" className="btn-ptype btn btn-curved-side text-white my-2 bg-charcoal mx-3 text-sm-14" onClick={() => _onSelect(PredictionTypeEnum.VALUE_AT)}><PredictionTypeTitle today={today} symbol={symbol} predictionTypeEnum={PredictionTypeEnum.VALUE_AT} /></button>
        }
        <button type="button" disabled={isDisabled(PredictionTypeEnum.VALUE_CLOSE)} data-testid="btn-close" className="btn-ptype btn btn-curved-side text-white my-2 bg-charcoal mx-3 text-sm-14" onClick={() => _onSelect(PredictionTypeEnum.VALUE_CLOSE)}><PredictionTypeTitle today={today} symbol={symbol} predictionTypeEnum={PredictionTypeEnum.VALUE_CLOSE} /></button>
        <button type="button" disabled={isDisabled(PredictionTypeEnum.VALUE_HIGH)} data-testid="btn-high" className="btn-ptype btn btn-curved-side text-white my-2 bg-charcoal mx-3 text-sm-14" onClick={() => _onSelect(PredictionTypeEnum.VALUE_HIGH)}><PredictionTypeTitle today={today} symbol={symbol} predictionTypeEnum={PredictionTypeEnum.VALUE_HIGH} /></button>
        <button type="button" disabled={isDisabled(PredictionTypeEnum.VALUE_LOW)} data-testid="btn-low" className="btn-ptype btn btn-curved-side text-white my-2 bg-charcoal mx-3 text-sm-14" onClick={() => _onSelect(PredictionTypeEnum.VALUE_LOW)}><PredictionTypeTitle today={today} symbol={symbol} predictionTypeEnum={PredictionTypeEnum.VALUE_LOW} /></button>
      </div>
    </div>
  );
}
