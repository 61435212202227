import React, { useState } from 'react';
import { ILinesAndAnnotations, checkboxNames } from '../../common/helper';

export const Checkbox: React.FunctionComponent<{
  name: string,
  checked: boolean,
  label: string,
  onChange: (key: string, val: boolean) => void
}> = ({ name, checked, label, onChange }) => {

  const [currentChecked, setCurrentChecked] = useState<boolean>(checked);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentChecked(e.target.checked);
    onChange(name, e.target.checked);
  };

  return (
    <div className="d-flex gap-1 align-items-center">
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={currentChecked}
        onChange={handleChange}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
}

export const LinesAndOptions: React.FunctionComponent<{
  linesAndAnnots: ILinesAndAnnotations,
  setLinesAndAnnots: (v: ILinesAndAnnotations) => void,
}> = ({
  linesAndAnnots,
  setLinesAndAnnots,
 }) => {

  const currentLinesAndAnnots = linesAndAnnots;

  const renderMap = (mp: Map<string|any,boolean>) => {
    const renderCheckbox = (k:string, v:boolean, index: number) => {
      return (
        <Checkbox key={index} label={checkboxNames.get(k) as string} checked={v} name={`${k}`} onChange={(key, value) => {
          mp.set(key, value);
          setLinesAndAnnots({
            ...currentLinesAndAnnots,
          })
        }} />
      )
    }
    return Array.from(mp.entries()).map((v,index)=>renderCheckbox(v[0],v[1], index));
  }

  const render = () => {
    return (
      <div className="d-flex flex-column gap-2">
        <div className="d-flex flex-columns gap-2">
          <div className="d-flex flex-row gap-2">
            <div>
              {renderMap(currentLinesAndAnnots.lines)}
            </div>
            <div>
              {renderMap(currentLinesAndAnnots.lastNHour)}
            </div>
          </div>


          <div>
            {renderMap(currentLinesAndAnnots.chartOptions)}
            {renderMap(currentLinesAndAnnots.extraLines)}
          </div>
        </div>

      </div>

    );
  }

  return (
    <div className="d-flex gap-3">
      {render()}
    </div>
  )
}
