import { IStockSymbol } from 'interfaces/IStockSymbol';
import React, { useEffect, useReducer } from 'react';
import { PredictionService } from 'services';
import { ISymbolsContext, SymbolsContext  } from './SymbolsContext';

export const SymbolsProvider: React.FunctionComponent<{predictionService: PredictionService, children: any}> = ({predictionService, children}) => {

  enum ACTION_TYPE {
    IDLE = 1,
    FETCHING = 2,
    FETCHED = 3,
    FETCH_ERROR = 4,
  }
  
  interface IState {
    status: ACTION_TYPE,
    error: string | null,
    data: IStockSymbol []
  }
  
  interface IAction {
    type: ACTION_TYPE,
    payload?: any
  }

  const initialState: IState = {
    status: ACTION_TYPE.IDLE,
    error: null,
    data: []
  }

  const defaultState: IState = initialState;
  const reducer = (state: IState, action: IAction) => {
    switch (action.type) {
      case ACTION_TYPE.FETCHING:
        return { ...initialState, status: ACTION_TYPE.FETCHING, data: null}
      case ACTION_TYPE.FETCHED:
        return { ...initialState, status: ACTION_TYPE.FETCHED, data: action.payload}
      case ACTION_TYPE.FETCH_ERROR:
        return { ...initialState, status: ACTION_TYPE.FETCH_ERROR, data:action.payload}
      default:
        return state;
    }
  }

  const [state, dispatch ] = useReducer(reducer, defaultState);

  useEffect(()=>{
    const _load = async () => {
      try {
        const data = await predictionService.getSymbols();
        dispatch({type: ACTION_TYPE.FETCHED, payload: data})
      } catch (error: any) {
        dispatch({type: ACTION_TYPE.FETCH_ERROR, payload: error.message})
      }
    }
    // console.log('in symbols provider useeffect')
    _load();
  },[]) // only call it once. Symbols list don't change often.

  const getHandler = (symbol: string): IStockSymbol => {
    const item: IStockSymbol[] = state.data.filter((s: IStockSymbol) => s.symbolName.toUpperCase() === symbol.toUpperCase());
    if (item.length > 0 ) {
      return item[0]
    }
    return {} as IStockSymbol;
  }

  const context: ISymbolsContext = {
    status: state.status,
    symbols: state.data,
    get: getHandler,
  }
  
  return (
  <SymbolsContext.Provider  value={context}>
    {children}
  </SymbolsContext.Provider>
  );

}