export enum SignalAlertPerfTypeEnum {
    COMBO                  = 1, // combo report
    YESTERDAY              = 2, // yesterday rep
    APERF                  = 3, // good alert score
    STDAY                  = 4,
}

export class SignalAlertPerfTypeEnumHelper {
    protected static readonly namesMap: Map<SignalAlertPerfTypeEnum, string> = new Map([
        [SignalAlertPerfTypeEnum.COMBO,         'COMBO'],
        [SignalAlertPerfTypeEnum.YESTERDAY,     'YEST'],
        [SignalAlertPerfTypeEnum.APERF,         'APERF'],
        [SignalAlertPerfTypeEnum.STDAY,         'STDAY'],
    ]);

    public static names() {
        return this.namesMap;
    }
}
