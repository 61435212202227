import { Url } from '_constants';
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const Banner:React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="discount-promo-banner rounded bg-mint-green d-flex justify-content-center align-items-center">
      <div className="discount-promo-banner-img me-1 me-md-2"><img src="/images/discount-promo/small-logo.png" alt="Predictagram"/></div>
      <div className="fw-bold text-14 text-black me-2 me-md-4">Reserve Pro Signals Access!</div>
      <div className="pill bg-white text-11 text-black fw-bold px-3 py-1" role="button" onClick={()=>navigate(Url.DISCOUNT_PROMO)}>Learn More -&gt;</div>
    </div>
  )
}
