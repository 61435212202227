import { AbortableHttpService } from "./AbortableHttpService";
import { AuthenticationService } from "./AuthenticationService";

export class BaseSearchService<T, P> {

  private abortController: AbortController | null  = null;

  constructor(
    protected url: string,
    protected authenticationService: AuthenticationService,
    protected httpService: AbortableHttpService,
  ) { }

  async search(params: P): Promise<T[]> {

    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();

    const payload = {"filters": params};

    try {
      return await this.httpService.httpPost<T[]>(this.url, payload, this.abortController.signal);
    } catch (error: any) {
      if (error.name === 'AbortError') {
        return Promise.reject();
      }
      throw new Error((error as Error).message);
    }
  }

  cancel () {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }
  }
}


