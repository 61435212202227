import React, {useState} from 'react';

export const SymbolLinks: React.FunctionComponent<{ symbols: string[], onClickCallback: (s: string | undefined) => void, activeSymbol?: string }> = ({ symbols, onClickCallback, activeSymbol }) => {

  const [active, setActive] = useState<string | undefined>(activeSymbol);

  const _onClick = (s: string) => {
    if (active === s) {
      setActive(undefined);
      onClickCallback(undefined);
    } else {
      onClickCallback(s);
      setActive(s);
    }
  }

  const a = symbols.filter((item, index) => symbols !== null && symbols.indexOf(item) === index);
  return <div className="d-flex flex-row flex-wrap justify-content-start">{a.map((s: string, i: number) =>
    <div className={`me-2 my-2 btn-tabs text-center ${activeSymbol === s ? "btn-tabs-active" : ""}`} key={`symbol-key-${i}}`} onClick={() => _onClick(s)} role="button">{s}</div>
  )}</div>
}