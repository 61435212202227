import React from 'react';
import { Url } from '_constants';
import { IUserStatistic } from 'interfaces/IUserStatistic';
import { useNavigate } from 'react-router-dom';
import { Helper } from '_utils';
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import * as Icons from 'components/common/Icons';

export const UserStatistic: React.FunctionComponent<{ statistic: IUserStatistic, index: number, norank?: boolean, isDisplayPredictions?: boolean }> = ({ statistic: item, index, norank = false, isDisplayPredictions = false }) => {

  const navigate = useNavigate();
  const url = `${Url.PUBLIC_STOCK_PREDICTOR}/${item.userId}`;
  const profile: IPublicProfile = {
    userId: item.userId,
    avatarUrl: item.avatarUrl,
    username: item.username,
  } as IPublicProfile


  const NoPredictions = () => {
    return (<div className="container g-0">
      <div className="row mx-1 flex-nowrap">
        <div className="col d-flex align-items-center">{norank ? '' : index}</div>
        <div className="col-7 d-flex align-items-center">
          <div className="d-flex align-items-center" role="button" onClick={() => navigate(url)}>
            <div className="team-roster-item-image">
              {item.avatarUrl ? (<img src={item.avatarUrl} alt={item.username} />) : (<Avatar profile={profile} size={AvatarSizeEnum.SMALL} />)}
            </div>
            <div className="team-roster-item-full-name ms-2">{item.username}</div>
            <div className="ms-1 mb-1">{!!item.isVerified && <Icons.PurpleCheck />}</div>
          </div>
        </div>
        <div className="col-auto d-flex align-items-center team-roster-item-accuracy">
          <span role="img" aria-label="accuracy">🎯</span> {item.score !== null ? `${(item.score * 100).toFixed(0)}%` : "TBD"}
        </div>
      </div>
    </div>
    );
  }

  const HasPredictions = () => {
    return (<>
      <div className="col-1 d-flex align-items-center">{norank ? '' : index}</div>
      <div className="col-5 d-flex align-items-center">
        <div className="d-flex align-items-center" role="button" onClick={() => navigate(url)}>
          <div className="team-roster-item-image">
            {item.avatarUrl ? (<img src={item.avatarUrl} alt={item.username} />) : (<Avatar profile={profile} size={AvatarSizeEnum.SMALL} />)}
          </div>
          <div className="team-roster-item-name ms-2">{item.username}</div>
          <div className="ms-1 mb-1">{!!item.isVerified && <Icons.PurpleCheck />}</div>
        </div>
      </div>
      <div className="col-2 d-flex align-items-center team-roster-item-accuracy"><span role="img" aria-label="accuracy">🎯</span> {item.score !== null ? `${(item.score * 100).toFixed(0)}%` : "TBD"}</div>
      {
        isDisplayPredictions && item.predictionsCompleted &&
        <div className="col-3 d-flex align-items-center gap-1">{item.predictionsCompleted}
          <div className="d-none d-md-block fw-normal">{Helper.pluralize('prediction', item.predictionsCompleted)}</div>
          <div className="d-block d-md-none fw-normal">{Helper.pluralize('pred', item.predictionsCompleted)}</div>
        </div>
      }
    </>
    );
  }

  return (
    isDisplayPredictions ? <HasPredictions /> : <NoPredictions />
  );

}