import {StockDirectionType} from "../../interface";

export class HelperSignal {
    /**
     * 1 - UP, -1 - DOWN
     * @param predictedValue
     * @param stockValueAtPredMadeTime
     */
    public static direction(predictedValue:number, stockValueAtPredMadeTime:number) {
        return predictedValue>=stockValueAtPredMadeTime ? StockDirectionType.UP : StockDirectionType.DOWN;
    }
}
