import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { PriceLookup } from './PriceLookup';
import { useAdminAutoTradePriceLookup } from '_hooks/useAdminAutoTradePriceLookup';
import { AutoTradeStatusEnum, IAutoTrade } from 'predictagram-lib';
import { ColoredOptionType, calcUnrealizedProfitLossOptionFormatted, formatUSCurrency } from './Trades';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import { useAdminAutoTradeSetupSingle } from '_hooks/useAdminAutoTradeSetupSingle';

export const ManualTradeAddModal: React.FunctionComponent<{
  show: boolean,
  handleClose: any,
  onConfirm: () => void,
  title: string,
  trade: IAutoTrade,
  isSubmitting: boolean,
}> = ({ show, handleClose, onConfirm, title, trade, isSubmitting }) => {


  const priceApi = useAdminAutoTradePriceLookup(trade.id);
  const tradeSetup = useAdminAutoTradeSetupSingle(trade.setupId).api;

  useEffect(()=>{
    priceApi.setFilter(trade.id);
  },[priceApi, trade.id])

  const renderPrice = () => {
    if (priceApi.apiState.isLoaded) {
      const p = priceApi.data;
      return <tr>
        <td className="text-center">{formatUSCurrency(p.bidPrice)}</td>
        <td className="text-center">{formatUSCurrency(p.askPrice)}</td>
        <td className="text-center">{formatUSCurrency(p.lastPrice)}
          {[AutoTradeStatusEnum.ACTIVE, AutoTradeStatusEnum.CLOSED].includes(trade.statusId) && p.lastPrice && <div className="text-10 my-1">
            {calcUnrealizedProfitLossOptionFormatted(trade, p.lastPrice, true)}
          </div>
          }
        </td>
      </tr>
    }

    if (priceApi.apiState.isError) {
      return <tr><td><ErrorMessage className="bg-white text-black">{priceApi.error || ''}</ErrorMessage></td></tr>
    }

    if (priceApi.apiState.isLoading) {
      return <tr><td><Spinner minHeight={25} /></td></tr>
    }

    return <></>
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered keyboard={false}>
      <Modal.Body className="bg-white">

        {isSubmitting ?
          <div className="d-flex gap-2 bg-white text-black justify-content-center align-items-center p-3">Processing...<Spinner minHeight={25} /></div>
          :
          <div className="d-flex flex-column gap-1 mt-3 me-2 align-items-center px-3 text-black text-12" title="Show Order Details">

            <div className="fw-bold text-16">Add New Trade</div>
            <div className="text-14">NOTE: Confirming this trade will create a new trade order based on this Trade Setup:

              <div className="d-flex gap-1 text-14 fw-bold align-items-center  my-3">
              {trade.setupName}
              <span >{tradeSetup.apiState.isLoaded && <ColoredOptionType optionType={tradeSetup.data.tradeTemplate.optionType} />}</span>
              </div>
            </div>

            <div className="fw-bold">Underlying Stock</div>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th className="text-end">Bid</th>
                  <th className="text-end">Ask</th>
                  <th className="text-end">Last</th>
                  <th className="text-end">Best Price</th>
                </tr>
              </thead>
              <tbody>
                <PriceLookup symbolId={trade.stockSymbolId} />
              </tbody>
            </table>

            <div className="d-flex flex-column gap-2 align-items-center justify-content-center mb-3">
              <div className="fw-bold">Are you sure?</div>
              <div className="d-flex gap-2">
                <button type="button" className="btn btn-primary text-12" onClick={() => { onConfirm();}}>Confirm</button>
                <button type="button" className="btn btn-secondary text-12" onClick={handleClose}>Cancel</button>
              </div>
            </div>
          </div>
        }
      </Modal.Body>
    </Modal>
  )
}

