import {NewYorkTz, StockHelper as SH} from 'predictagram-lib';

export class StockHelper extends SH {

  /**
   * return the number of market days passed in given date's month
   * @param dt date
   */
  static getMarketDaysElapsed (startDate: Date, endDate: Date) {

    let marketDays = 0;
    const offset = StockHelper.isPostMarketHours(endDate) ? 1 : 0;
    const daysDiff = NewYorkTz.dayDiff(endDate, startDate ) + offset;
    const testDate = new Date(startDate.getTime());
    for (let n=0; n < daysDiff; n++) {
      testDate.setDate(startDate.getDate() + n);
      if (StockHelper.isTradingDay(testDate)) {
        marketDays++;
      } 
    }
    return marketDays;
  }

}
