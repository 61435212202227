import React from 'react'
import { Helmet } from 'react-helmet';
import { PrivacyPolicy } from './_static';

export const PrivacyPolicyPage: React.FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>Predictagram: Privacy Policy</title>
      </Helmet>
      <PrivacyPolicy />
    </>

  )
}
