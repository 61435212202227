import React, { useState } from 'react'
import { Eye, EyeSlash } from 'react-bootstrap-icons';

export const EyeToggle: React.FunctionComponent<{value:boolean, onEnable: () => void, onDisable: ()=>void}> = ({value, onEnable, onDisable}) => {
  const [currentValue, setCurrentValue] = useState<boolean>(value);

  const clickHandler = () => {
    setCurrentValue(!currentValue);
    if (!currentValue) {
      onEnable();
    } else {
      onDisable();
    }
  }

  return (
    <div>
      <div role="button" onClick={clickHandler}>{currentValue ? <Eye /> : <EyeSlash />}</div>
    </div>
  )
}
