import { Helper } from '_utils';
import { UserPlanStatusEnum } from 'predictagram-lib';
import React from 'react'
import { IUserSignalPlan } from 'services/SubscriptionService';

export const ManageSubscription: React.FunctionComponent<{ onCancel: any, onStart: any, onRenew: any, plans: IUserSignalPlan[] }> = ({ onCancel, onStart, onRenew, plans }) => {


  const renderCurrent = () => {
    // only get the first plan
    const p = plans[0];

    return (<>
      <div className="align-self-start mt-5">
        <div>
          <div className="d-flex flex-column gap-2">
            <div>
              <div className="d-flex flex-column gap-2">
                <div className="fw-bold">You are currently subscribed to {p.planName}</div>
                <div>Your next payment of {Helper.formatCurrency(p.planPrice, 2)} will be made on {Helper.getFormattedDateMDY(new Date(p.expireAt * 1000))}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="btn btn-secondary align-self-center mb-5" onClick={onCancel}>Cancel Subscription</button>
    </>)
  }

  const renderRenew = () => {
    // only get the first plan
    const p = plans[0];

    return (<>
      <div className="align-self-start mt-5">
        <div>
          <div className="d-flex flex-column gap-2">
            <div>
              <div className="d-flex flex-column gap-2">
                <div className="fw-bold">Your subscription expires on {Helper.getFormattedDateMDY(new Date(p.expireAt * 1000))}</div>
                <div>You will have acces to {p.planName} until then.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="btn btn-secondary align-self-center mb-5" onClick={onRenew}>Renew Subscription</button>
    </>)
  }

  const renderNoSubs = () => {
    return (<>
      <div className="align-self-start mt-5">
        <div>
          <div className="d-flex flex-column gap-2">
            <div>
              <div className="d-flex flex-column gap-2">
                <div className="fw-bold">You do not have any subscriptions. Click button below to subscribe!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="btn btn-secondary align-self-center mb-5" onClick={onStart}>Start Subscription</button>
    </>)
  }


  const render = () => {
    if (plans.length === 0) {
      return renderNoSubs();
    }

    if (plans[0].userStatusId === UserPlanStatusEnum.ACTIVE) {
      return renderCurrent();
    }

    return renderRenew();
  }


  return (
    <div className="d-flex flex-column justify-content-between h-100">
      {render()}
    </div>
  )
}
