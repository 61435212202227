import { TeamStatusEnum } from "predictagram-lib";

export interface ITeam {
  id: number;
  ownerId: number;
  statusId: TeamStatusEnum;
  leagueId: number;
  name: string;
  description: string;
  logo: string;
  logoUrl: string; // dynamic field
  creationTimestamp: any;
  score: number; // dynamic field
  predictions: number;
  predictionsCompleted: number;
  latestSeasonId: number;
  createdAt: number; // dynamic field
  members: number;
}

export const teamStatusNames: Map<TeamStatusEnum, string> = new Map([
  [TeamStatusEnum.ACTIVE, 'Active'],
  [TeamStatusEnum.NOT_ACTIVE, 'Inactive'],
]);