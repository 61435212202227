export enum StorageKey {
  ADMIN_ACCT = 'aacct',
  ADMIN_TOKEN = 'atoken',

  USER_ACCT = 'pacct',
  USER_TOKEN = 'ptoken',
  USER_TOKEN_LAST_CHECKED = 'ptokenlast',

  PRACTITIONER_ACCT = 'dacct',
  PRACTITIONER_TOKEN = 'dtoken',

  APPT = 'appt',

  TAPNATIVE_TXID = 'tapTxId',

  LANDING_PAGE = 'lp',
  LANDING_PAGE_EXPIRES = 'lpexp',

  UNIQUE_USER_ID = 'qd',

  SHOW_HELP_ANON = 'sh',

  PREDICTION_COUNTER = 'pc',

  REFERRAL_USER_ID = 'refId',
  REFERRAL = 'rfl',

  DISCLAIMER = 'dsc',
  SEEN_TEAM_CONTEST_RULES = 'tcr',
  SEEN_SEASON_WINNER = 's12w',
  SEEN_DASHBOARD = 'sd',
  DASHBOARD_SETTINGS = 'ds',

  UTM_SOURCE = 'utm',

  COOKIES_VERIFY_REMINDER_TEAMLB = 'lb',
  COOKIES_VERIFY_REMINDER_DASHBOARD = 'ar',
  COOKIES_DISCLAIMER_REMINDER = 'cdr',
  COOKIES_CONTEST_REMINDER = 'ccr',

  LAST_NOTIFICATION_KEY = 'lnk',
  LAST_NOTIFICATION_SEEN = 'lnkv',

  SEEN_WALKTHROUGH_CONSENSUS = 'swc',
  SEEN_WALKTHROUGH_PREDICTION = 'swp',

  SEEN_CROWDFUND = 'scf',
  SEEN_LEAGUE_PRIZE = 'slp',
  SEEN_LEAGUE_DISCLAIMER = 'sld',
  SEEN_DISCOUNT_PROMO = 'sdp',

  CUME_CONFIG = 'cudata',
  
}