import React from 'react'
import { TradegramHelper } from '_utils/TradegramHelper'
import { ITradegramSecurity } from 'services/TradegramApiService'
import { Lock } from 'react-bootstrap-icons'
import { BrokerSecurityTypeEnum } from 'predictagram-lib'


export const SymbolName: React.FunctionComponent<{ security: ITradegramSecurity, boldName?: boolean }> = ({ security, boldName = false }) => {
  const parsedOption = security.stockSymbolOption ? TradegramHelper.patternOsi.parse(security.stockSymbolOption) : undefined;

  return <>
    {parsedOption && TradegramHelper.isOptionSecurity(security.typeId) &&
      <div className={`fw-bold text-18`}>{parsedOption.symbol} <span className={`${boldName ? "text-18 fw-bold" : "text-14 fw-normal"}`}>{TradegramHelper.formatOptionDate(new Date(parsedOption.date * 1000))} ${parsedOption.price.toFixed(2)} {parsedOption.optionType}</span></div>
    }
    {TradegramHelper.isEquitySecurity(security.typeId) &&
      <div className={`fw-bold text-18`}>{security.stockSymbol}</div>
    }
  </>
}

export const ProfitPct: React.FunctionComponent<{ security: ITradegramSecurity, size?: "sm" | "lg" }> = ({ security, size = "lg" }) => {
  const textColor = security.profit < 0 ? "text-bright-red" : "text-bright-green";
  const profit = TradegramHelper.getProfit(security);

  return <div className="d-flex flex-column justify-content-start">
    <div className={`fw-bold text-${size==="lg" ? "18" : "14"} ${textColor} line-height-${size==="lg" ? "22" : "18"}`}>{security.profit < 0 ? "-" : "+"}{(Math.abs(profit.pctChange) ).toFixed(2)}%</div>
    <div className="text-10 text-lighter-charcoal text-center">{TradegramHelper.profitOrLossName(security.profit).toLowerCase()}</div>
  </div>
}


export const ProfitPerShare: React.FunctionComponent<{ security: ITradegramSecurity }> = ({ security }) => {
  const profit = TradegramHelper.getProfit(security);
  return <div className="d-flex flex-column justify-content-start">
    <div className={`fw-bold text-18 line-height-22`}>{TradegramHelper.formatMoney(profit.perShare, true)}</div>
    <div className="text-10 text-lighter-charcoal text-center">per share</div>
  </div>
}

export const NetBalance: React.FunctionComponent<{ security: ITradegramSecurity, small?:boolean }> = ({ security, small=false }) => {
  return <>
    {TradegramHelper.getQuantityBalance(security) === 0 ?
      <div className="text-12 text-lighter-charcoal d-flex justify-content-start gap-1 align-items-center"><Lock /><span className="fw-bold">Closed</span></div>
      :
      <div className="d-flex flex-column">
        <div className={`${small ? "text-10 line-height-12" : "text-15 line-height-17"}`}>
          <span className="fw-bold ">{TradegramHelper.getQuantityBalancedSigned(security)}</span>
          {" "} {TradegramHelper.getQuantityBalance(security) !== 0 && <span className="fw-bold">@ {TradegramHelper.formatMoney(security.openCost / security.openQuantity / (security.typeId === BrokerSecurityTypeEnum.OPTION ? 100 : 1))}</span>}
        </div>
        <div className="text-10 text-lighter-charcoal text-center">net {TradegramHelper.shareOrContractName(security.typeId)}s</div>
      </div>
    }
  </>
}


export const ProfitOrAction: React.FunctionComponent<{ security: ITradegramSecurity }> = ({ security }) => {

  return <>
    {( TradegramHelper.hasSold(security) || TradegramHelper.isClosed(security) )?
      <ProfitPct security={security} size="sm" />
      :
      <div className="text-10 text-lighter-gray">{TradegramHelper.getPositionActionName(security)}</div>
    }
  </>
}


interface IProps {
  security: ITradegramSecurity,
  children: any,
  className?: string,
  onClick?: () => void,
  detailed?: boolean,
}

export const SecurityCard: React.FunctionComponent<IProps> = ({ security, children, className, onClick, detailed = false }) => {

  const role = onClick ? "button" : "cell";
  const cardColor = security.transactions === 1 ? "" : security.profit < 0 ? "red" : "green";

  return (
    <div className={`tradegram-card ${cardColor} ${className || ""}`} role={role} onClick={onClick}>
      <div className="tradegram-card-detail">
        <div className="tradegram-card-detail-header">

          {detailed ?
            <div className="d-flex flex-column justify-content-start align-items-start">
              <SymbolName security={security} boldName={false}/>
              <div className="d-flex justify-content-between align-items-center gap-1 w-100 my-2">
                {(TradegramHelper.hasSold(security) || TradegramHelper.isClosed(security)) ?
                  <>
                    <ProfitPct security={security} />
                    <ProfitPerShare security={security} />
                  </>
                  :
                  <div className="opacity-70 fw-bold text-12">
                    {TradegramHelper.getPositionActionName(security)}
                  </div>
                }
                <NetBalance security={security} />
              </div>
            </div>
            :
            <div className="d-flex justify-content-between mt-2 align-items-center">
              <SymbolName security={security} />
              <ProfitOrAction security={security} />
            </div>
          }

        </div>
      </div>

      <div className="tradegram-card-detail-footer">
        {children}
      </div>
    </div>
  )
}
