import React from 'react'
import { IPrediction } from 'predictagram-lib'
import { IFilteredPredictions, useFilteredPredictions } from '_hooks/useFilteredPredictions'
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService'
import { sortTypeEnum } from '../ListingsPage/components/Prediction/SortTypeSelect'
import { UserTypeFilterEnum } from 'components/user/dashboard/filters/UserTypeDropDownFilter'
import { PredictionListLazy } from '../ListingsPage/components/PredictionListLazy'
import { APIState } from '_constants/APIState'
import { Spinner } from 'components/common/Spinner'
import { ErrorMessage } from 'components/common/ErrorMessage'

export const MorePredictionsSymbol:React.FunctionComponent<{prediction:IPrediction, className?: string}> = ({prediction, className}) => {

  const searchOptions: ISearchOptions = {
    symbolNames: [prediction.stockSymbol],
    orderBy: sortTypeEnum.LATEST,
    limit: 50,
  }

  const filteredPredictions: IFilteredPredictions = useFilteredPredictions(userPredictionApiService, UserTypeFilterEnum.EVERYONE, searchOptions)

  if (filteredPredictions.apiState === APIState.LOADING) {
    return <Spinner />
  }

  if (filteredPredictions.apiState === APIState.LOADED) {
    return (
      <div className={className}>
      <div className="fw-bold">More predictions for ${prediction.stockSymbol} </div>

      <PredictionListLazy 
        predictions={filteredPredictions.items.filter(i => i.id !== prediction.id)} 
        onSelectSort={()=>{}}
        orderBy={sortTypeEnum.LATEST}
        showMenu={false}
      />
    </div>

    );
  }

  if (filteredPredictions.error) {
    return (
      <ErrorMessage>{filteredPredictions.error.message}</ErrorMessage>
    );
  }

  return (
    <ErrorMessage>Unexpected State</ErrorMessage>
  )
}
