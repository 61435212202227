import { ScrollToTop } from "components/common";
import { JoinWaitListButton } from "./JoinWaitListButton";

export const TradegramLockedContent = () => {
  return (
    <>
    <ScrollToTop />
    <div className="mx-3">
      <div className="mt-3 mb-5">
        <div className="fw-bold mb-2 mt-5">Trades Private Beta gives you the ability to:</div>
        <ul>
          <li>Follow and get alerts for real-time trades from <span className="fw-bold">top traders</span></li>
          <li>Enter your own trades and track your profitability</li>
        </ul>
      </div>

      <div className="my-5">
        <JoinWaitListButton color="white"/>
      </div>

      <div className="d-flex justify-content-start align-items-center gap-3 mt-2 flex-wrap flex-md-nowrap">
        <div className="tradegram-card"><img style={{ width: "100%" }} src="/images/trade1.png" alt="Trade Sample 1" /></div>
        <div className="tradegram-card"><img style={{ width: "100%" }} src="/images/trade2.png" alt="Trade Sample 2" /></div>
        <div className="tradegram-card d-none d-md-block"><img style={{ width: "100%" }} src="/images/trade3.png" alt="Trade Sample 3" /></div>
      </div>

    </div>
    </>
  );
}
