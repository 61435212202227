import { IAccount } from "interfaces";

export enum UtmSourcesEnum {
  ADBLADE = 'adblade',
  OUTBRAIN = 'ob',
  META = 'facebook',
  TABOOLA = 'taboola',
  TWITTER = 'twitter',
  TIKTOK = 'tiktok'
}

export enum VerificationEventEnum {
  ENTER_PHONE_NUMBER = "vc-get-code",
  SUBMIT_VERCODE = "vc-submit-code",
  RETRY_VERCODE = "vc-retry",
  CLICK_LATER = "vc-later",
  SMS_FAILED = "vc-sms-failed",
  SHOW_REMINDER = "vc-show-reminder",
}

export class PixelHelper {

  public static trackScrollN(n: number) {
    window.gtag('event', `scroll_${n}`);
  }

  public static clickMakePrediction(utmSource: string | undefined) {
    
    const eventName = 'click_make_prediction';
    // always fire ga4 regardless of source
    window.gtag('event', eventName);

    if (!utmSource) {
      return;
    }
  }

  public static clickMakeTrade(utmSource: string | undefined) {
    
    const eventName = 'click_make_trade';
    // always fire ga4 regardless of source
    window.gtag('event', eventName);

    if (!utmSource) {
      return;
    }
  }

  public static clickZoom(mode: 'out' | 'in') {
    
    const eventName = 'zoom';
    // always fire ga4 regardless of source
    window.gtag('event', `${eventName}-${mode}`);

  }

  public static clickHelp() {
    window.gtag('event', 'click_help');
  }

  public static viewCard(name: string) {
    window.gtag('event', `view_infeed_${name}`)
  }

  public static clickCard(name: string) {
    window.gtag('event', `click_infeed_${name}`)
  }

  public static trackGAEvent(name: string) {
    window.gtag('event', name);
  }

  public static trackVerificationEvent(eventName: VerificationEventEnum) {
    // ga4
    if (window.gtag) {
      window.gtag('event', eventName, {
        'page_location' : window.location.href
      });
    } else {
      console.error('could not find gtag');
    }
  }


  public static submittedPrediction(utmSource: string | undefined) {

    // ga4
    if (window.gtag) {
      window.gtag('event', 'made_prediction', {
        'page_location' : window.location.href
      });
    } else {
      console.error('could not find gtag');
    }

    if (!utmSource) {
      return;
    }

    // twitter prediction conversion
    if (utmSource === UtmSourcesEnum.TWITTER) {
      if (window.twq) {
        window.twq('event', 'tw-of09w-off6n', {});
      } else {
        console.error('could not find twq');
      }
    }

    // // tik-tok
    // if (utmSource === UtmSourcesEnum.TIKTOK) {
    //   if (window.ttq) {
    //     window.ttq.track('SubmittedPrediction');
    //   } else {
    //     console.error('could not find ttq.track');
    //   }
    // }

  }

  public static firePageView(utmSource: string | undefined) {
    if (!utmSource) {
      return;
    }

    // if (utmSource === UtmSourcesEnum.TIKTOK) {
    //   if (window.ttq) {
    //     window.ttq.page();
    //   }
    // }

    // do not call ga4 page_view. Called by default.
  }

  public static registered(utmSource: string | undefined, user: IAccount) {
    // ga4
    window.gtag('event', 'registered')

    if (!utmSource) {
      return;
    }

    // twitter
    if (utmSource === UtmSourcesEnum.TWITTER) {
      if (window.twq) {
        window.twq('event', 'tw-of09w-ofeia', {
          value: user.id
        });
      } else {
        console.log('could not find twq');
      }
      return;
    }

    // // tiktok
    // if (utmSource === UtmSourcesEnum.TIKTOK) {
    //   if (window.ttq) {
    //     window.ttq.track('CompleteRegistration');
    //   } else {
    //     console.error('could not find twq');
    //   }
    //   return;
    // }

  }
}