import React from 'react'
import Card from './Card'
import { BarChart } from 'components/common/Icons'
import { useUserStats } from '_hooks/useUserStats';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { filterConfigMap } from 'components/user/league/leaderboards/LeaderboardUsersPage';
import { DateFilterEnum } from 'components/user/league/leaderboards/components/DateFilters';
import { APIState } from '_constants/APIState';
import { Spinner } from 'components/common/Spinner';
import { UserStatistic } from 'components/user/contest/components/UserStatistic';
import { NewYorkTz as tz } from 'predictagram-lib';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { IUserStatistic } from 'interfaces/IUserStatistic';
import { StatsHelper } from 'components/user/league/leaderboards/StatsHelper';

export const LeaderboardCard: React.FunctionComponent<{className?:string}> = ({className = ''}) => {

  const navigate = useNavigate();

  const initialStartTime = () => {
    const today = tz.getDateMidnight(new Date()).getTime();
    const dayInMs = 24 * 60 * 60 * 1000;
    const daysAgo = today - (dayInMs * 7);
    return daysAgo;
  }

  const initialFilterConfig = filterConfigMap.get(DateFilterEnum.SEVEN_DAYS);
  const { items, apiState, error } = useUserStats(userPredictionApiService, {
    startTime: initialStartTime() / 1000,
    minScore: initialFilterConfig?.minScore,
    minPredictions: initialFilterConfig?.minPredictions
  });


  const title = <div className="d-flex justify-content-start align-items-center gap-2">
        <BarChart fill="none"/>
        <div>Leaderboards</div>
      </div>;

  const subtitle = <div>Find and follow our most accurate predictors!</div>
  
  const onClick = () => {
    navigate(Url.LEADERBOARD_USERS);
  }


  const getSortedStats = (stats: IUserStatistic[]) => {
    return stats.sort((a: IUserStatistic, b: IUserStatistic) => {
      const daysRange = 7;
      return StatsHelper.usersLeadBoardSortId(b as any, daysRange) - StatsHelper.usersLeadBoardSortId(a as any, daysRange);
    }).slice(0, 30);
  }

  const Leaderboard = () => {
    return <div className="leaderboard-card mt-3">
      {getSortedStats(items).slice(0, 3).map((item, i) => <div className="row mx-1 mx-md-3 my-2 py-2 leaderboard-card-item" key={`roster-key-${item.userId}`}>
            <UserStatistic statistic={item} index={i + 1} isDisplayPredictions={false} />
          </div>)}
    </div>
  }

  if (apiState === APIState.ERROR) {
    return <ErrorMessage>Unexpected error: {error}</ErrorMessage>
  }

  return (
    <Card hoverClassName='bg-lavender' name="leaderboard" title={title} subtitle={subtitle} onClick={onClick} className={`${className} purple-gradient`}>
      {apiState === APIState.LOADING && <Spinner minHeight={50} />}
      {apiState === APIState.LOADED && <Leaderboard />}
    </Card>
  )
}
