import React from 'react';
import { IPrediction, IQuote } from 'predictagram-lib';
import { PostSubmitChartModel as model } from 'models/post-submit-chart.model';
import { IPostSubmitChartResponse } from 'services/UserPredictionApiService';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { AvatarSizeEnum } from 'components/common/Avatar';
import { HandThumbsUpFill, HandThumbsDownFill } from 'react-bootstrap-icons';
import { AvatarLink } from '../dashboard/UserInfo';
import { UrlHelper } from '_utils/UrlHelper';

const Avatars: React.FunctionComponent<{
  members: IPublicProfile[],
  thumbCount?: number,
}> = ({ members, thumbCount = 3 }) => {
  return (
    <div className="team-avatars-thumbs d-flex flex-row justify-content-start align-items-center gap-1">
      {members.slice(0, thumbCount).map((m: IPublicProfile, i: number) => <div title={m.username} className="team-avatars-thumbs-img" key={`avatar-img-key-${i}`}>
        <AvatarLink profile={m} size={AvatarSizeEnum.MEDIUM_LARGE} />
      </div>
      )}
    </div>
  );
}

export const ConsensusSummary: React.FunctionComponent<{ postPredictions: ReadonlyArray<IPostSubmitChartResponse>, prediction: IPrediction, stockQuotes: ReadonlyArray<IQuote> }> = ({ postPredictions, prediction, stockQuotes }) => {

  const consensus = model.getConsensus(postPredictions, prediction, stockQuotes[stockQuotes.length - 1]);
  const pctAgree = consensus.agree.length / (consensus.agree.length + consensus.disagree.length) * 100;
  if (isNaN(pctAgree)) {
    return <div className="my-3">
      You are the first to make this type of prediction for {prediction.stockSymbol}
    </div>
  }
  return (
    <div>
      <div className="mb-3"><span className="text-26 fw-bold text-lavender">{Math.round(pctAgree)}%</span> <br/>of predictors in past hour agree with you</div>

      {consensus.agree.length > 0 && 
      <div className="my-3">
        {consensus.agree.slice(0,2).map((i, k, arr)=> <span key={`consensus-key-${k}`}><a className="text-white" href={`${UrlHelper.getProfileUrl(i.userId as number)}`}>{i.username}</a>{arr.length > 1 && k < arr.length - 1 ? ', ' : ''}</span>)} {consensus.agree.length > 2 && <>and {consensus.agree.length - 2} others</>}
      </div>
      }

      <div className="d-flex gap-3">
        <div className="border border-color-gray pill d-flex justify-content-start align-items-center px-3 py-1 gap-1"><HandThumbsUpFill fill="#ABA5FC" /><Avatars members={consensus.agree} /></div>
        <div className="border border-color-gray pill d-flex justify-content-start align-items-center px-3 py-1 gap-1"><HandThumbsDownFill fill="#A6A6A6" /><Avatars members={consensus.disagree} /></div>
      </div>

      {/* <pre>{JSON.stringify({stockQuotes, prediction, postPredictions}, null, 2)}</pre> */}
      {/* <pre>debug{JSON.stringify({ consensus }, null, 2)}</pre> */}
    </div>
  );
}