import React from 'react';
import { Outlet } from 'react-router-dom';
import { CollapsedNav, ExpandedNav } from 'components/common';
import { MessengerProvider } from 'components/common/messenger';
import { AccountProvider } from 'components/common/AccountProvider';
import { userAccountService } from 'services/AccountService';
import { userAuthenticationService } from 'services/AuthenticationService';
import { FolloweeProvider } from 'components/common/FolloweeProvider';
import { SymbolsProvider } from 'components/common/SymbolsProvider';
import { predictionService } from 'services';
import { PredictionProvider } from 'components/common/PredictionProvider';
import { useReferral } from '_hooks/useReferral';
import { config } from 'config';
import { ApiPixel } from 'components/common/ApiPixel';
import { SeasonProvider } from 'components/common/SeasonProvider';

export const PublicLayout: React.FunctionComponent<{hasDisclaimer?: boolean}> = ({hasDisclaimer = false}) => {

  useReferral(); // sets the referral info

  return (
    <MessengerProvider>
      <AccountProvider accountService={userAccountService} authService={userAuthenticationService}>
        <SymbolsProvider predictionService={predictionService}>
          <FolloweeProvider>
            <PredictionProvider>
              {config.environment === 'development' && <div className="devex-indicator">DEVEX SITE</div>}
              <div className={`d-flex flex-row public-layout-container`}>
                <div className="d-flex flex-column align-items-center navigation d-none d-md-block navigation-expanded border-end border-color-darkest-gray min-vh-100">
                  <ExpandedNav />
                </div>

                <div className="public-layout-container-content">
                  <div className="d-block d-md-none navigation navigation-collapsed">
                    <CollapsedNav />
                  </div>
                  <div className="mt-1 mt-md-5 pt-2">
                    <ApiPixel />
                    <SeasonProvider>
                    <Outlet />
                    </SeasonProvider>
                  </div>
                </div>
              </div>
              {/* <div className={`d-flex flex-column align-items-center ${hasDisclaimer ? '' : 'd-none d-sm-block' }`}>
                <FooterSignupLogin isFixed={!hasDisclaimer} hasDisclaimer={hasDisclaimer}/>
              </div> */}
            </PredictionProvider>
          </FolloweeProvider>
        </SymbolsProvider>
      </AccountProvider>
    </MessengerProvider>
  );
}