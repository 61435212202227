import React, { useState } from 'react'
import { Signup } from './Signup'
import { Payment } from './Payment'
import { Done } from './Done';
import { ISignalPlan } from 'services/UserSignalApiService';
import { useApiSignalPlans } from '_hooks/useApiSignalPlans';

export const Wizard:React.FunctionComponent<{onClose: ()=>void}> = ({onClose}) => {

  enum Step {
    SIGNUP = 1,
    PAYMENT_INFO = 2,
    DONE = 3,
    ERROR = 99
  }

  const [currentStep, setCurrentStep] = useState<Step>(Step.SIGNUP);
  const [plan, setPlan] = useState<ISignalPlan>({} as ISignalPlan);

  const api = useApiSignalPlans();

  //@TODO: initial only supports single plan. If future requires multiple plans, have to fix this.
  const stepMap: Map<Step, React.ReactNode> = new Map([
    [Step.SIGNUP, <Signup onContinue={()=>{
      setPlan(api.data[0]);
      setCurrentStep(Step.PAYMENT_INFO);
    }} onClose={onClose} api={api}/>],
    [Step.PAYMENT_INFO, <Payment onSuccess={()=>{setCurrentStep(Step.DONE)}} onClose={onClose} plan={plan}/>],
    [Step.DONE, <><Done onClick={onClose} /></>]
  ]);


  const render = () => {
    const element = stepMap.get(currentStep);
    return <>{element}</>
  }

  const backHandler = () => {
    if (currentStep === Step.SIGNUP) return;

    setCurrentStep(currentStep - 1);

  }

  const nextHandler = () => {
    setCurrentStep(currentStep + 1);
  }

  return (
    <div className="signals-wizard container py-3">

    {/* {![Step.SIGNUP, Step.DONE].includes(currentStep) && <RoundBackButton onClick={backHandler} />} */}

    {render()}

    

    </div>
  )
}
