import React from 'react';
import { UrlHelper } from '_utils/UrlHelper';
import { Link } from 'react-router-dom';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

export const LinkAdminTradeSetup: React.FunctionComponent<{ tradeSetupId: number }> = ({ tradeSetupId }) => {
  return (
    <Link target="_blank" to={UrlHelper.getAdminAutoTradeSetupEdit(tradeSetupId)}><BoxArrowUpRight /></Link>
  )
}
