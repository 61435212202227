export class ErrorCodes {
    // static readonly SMS_NOT_VALID_PHONE_NUMBER                              = 21211;
    // static readonly SMS_NOT_ENABLED_REGION                                  = 21408;
    // static readonly SMS_ATTEMPT_TO_SEND_TO_UNSUBSCRIBED_RECIPIENT           = 21610;


    static readonly STOCK_OUTDATED_STATS                                    = 31000;


}
