import React from 'react';
import { IAccount } from 'interfaces';
import { Modal } from "react-bootstrap";
import { UserProfile } from './UserProfile';

interface IProps {
  show: any;
  handleClose: any;
  onSaveCallback?: (profile: IAccount) => void;
}

const ModalProfile: React.FunctionComponent<IProps> = ({
  show,
  handleClose,
  onSaveCallback
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>

        <div className="row justify-content-center mx-3">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="page-title">Edit Account</div>
            <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
          </div>
        </div>

        <UserProfile onSaveCallback={onSaveCallback} />

      </Modal.Body>
    </Modal>
  );
};

export { ModalProfile }