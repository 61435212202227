import React from 'react'
import { IFilteredPredictions, useFilteredPredictions } from '_hooks/useFilteredPredictions'
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect'
import { PredictionListLazy } from 'components/public/ListingsPage/components/PredictionListLazy'
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService'
import { UserTypeFilterEnum } from '../dashboard/filters/UserTypeDropDownFilter'
import { APIState } from '_constants/APIState'
import { Spinner } from 'components/common/Spinner'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { NotificationHeader } from './NotificationHeader';
import { Helmet } from 'react-helmet';

export const RecentFollowedPredictionsPage: React.FunctionComponent = () => {

  return (
    <NotificationHeader title="Recent Predictions">
      <RecentFollowedPredictions />
    </NotificationHeader>

  );
}

export const RecentFollowedPredictions: React.FunctionComponent = () => {

  const searchOptions: ISearchOptions = {
    orderBy: sortTypeEnum.LATEST,
    limit: 50,
  }
  const apiFiltered: IFilteredPredictions = useFilteredPredictions(userPredictionApiService, UserTypeFilterEnum.FOLLOWING, searchOptions)

  if (apiFiltered.apiState === APIState.LOADING) {
    return <Spinner />
  }

  if (apiFiltered.apiState === APIState.ERROR) {
    return <ErrorMessage>{apiFiltered.error?.message}</ErrorMessage>
  }

  if (apiFiltered.apiState === APIState.LOADED) {
    return (
      <>
        <Helmet>
          <title>Predictagram: Notifications - Followed Predictors</title>
        </Helmet>
        <PredictionListLazy predictions={apiFiltered.items} onSelectSort={() => { }} orderBy={sortTypeEnum.LATEST} showMenu={false} />
      </>
    )
  }

  return <></>

}
