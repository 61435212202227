import React from 'react'
import { Badge } from './Badge'
import { useUserTicketsActive } from '_hooks/useUserTicketsActive';
import { Spinner } from 'components/common/Spinner';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { UserTicketStatusEnum } from 'predictagram-lib';

export const TicketsBadge: React.FunctionComponent= () => {
  const ticketApi = useUserTicketsActive(null);

  if (ticketApi.apiState.isLoading) {
    return <Spinner minHeight={62}/>
  }

  if (ticketApi.apiState.isLoaded) {

    return (
      <div className="badge-black-hover rounded" role="button">
        <Badge footerText={`x${ticketApi.data.filter(t=>t.statusId === UserTicketStatusEnum.PASSED).length}`}>
          {ticketApi.renderTicket(13,4)}
        </Badge>
      </div>
    )
  }

  if (ticketApi.apiState.isError) {
    return <ErrorMessage>{ticketApi.error}</ErrorMessage>
  }

  return <ErrorMessage>Unknown API State</ErrorMessage>
}
