import React, { useContext } from 'react'
import Card from './Card'
import { SeasonContext } from 'components/common/SeasonContext';
import { IUserAchievementUserStatSearchFilter } from 'services/UserAchievementService';
import { AccountContext } from 'components/common';
import { useApiAchievementUserStats } from '_hooks/useApiAchievementUserStats';
import { BadgeHelper } from 'components/user/badges/Helper';
import { Spinner } from 'components/common/Spinner';
import { LeagueUserList } from 'components/user/rankings/LeagueUserList';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';

export const RankingCard: React.FunctionComponent<{className?:string}> = ({className = ''}) => {

  const navigate = useNavigate();
  const seasonContext = useContext(SeasonContext);
  const acctContext = useContext(AccountContext);

  const initialFilter: IUserAchievementUserStatSearchFilter = {
    endTime: seasonContext?.season?.endDateSec,
    startTime: seasonContext?.season?.startDateSec,
    userId: acctContext?.account.id
  };

  const userStatsApi = useApiAchievementUserStats(initialFilter);
  const subtitle = <div>Make predictions to reach higher leagues and earn rewards!</div>
  
  const onClick = () => {
    navigate(Url.USER_LEAGUE_RANKINGS);
  }

  if (userStatsApi.apiState.isLoading) {
    return (
      <Spinner minHeight={75} />
    )
  }

  if (userStatsApi.apiState.isLoaded) {

    const {levelInfo } = BadgeHelper.getLevelProgress(userStatsApi.data[0]);
    const badge = <div className={`mt-1 league-rank-image rank-${levelInfo.current.rank}`}></div>;
    const title = <div className="d-flex justify-content-start align-items-center gap-2">
      {badge}
      <div>Level up and earn rewards</div>
    </div>;

    return (
      <Card hoverClassName={`bg-rank-${levelInfo.current.rank}`}  name="ranking" title={title} subtitle={subtitle} onClick={onClick} className={`${className} rank-${levelInfo.current.rank}-gradient`}>
        <div className="mx-1 mx-md-4">
        <LeagueUserList leagueTab={levelInfo.current.rank} top={3}/>
        </div>
      </Card>
    )
  }

  return <></>  
}
