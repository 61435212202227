import {IQuoteFull} from "../interface";
import {MathEx} from "../utils";

export interface SupResLineData {
    high: number,
    low: number,
    close: number
}

interface SupRes {
    r1: number,
    r2: number,
    s1: number,
    s2: number,
}

interface AggrData  {
    low: number;
    high: number;
    close: number;
    count: number;
    tFirst: number;
    tLast: number;
}

export interface SupResLine extends SupRes {
    tFirst: number, // first point time
    tLast: number, // last point time which was used to calc the sup res line
}

export class SupResLinesData {

    protected static aggrAdd(s: Readonly<IQuoteFull>, aggr: AggrData) {
        if (aggr.count===0) {
            aggr.low = s.l;
            aggr.high = s.h;
            aggr.tFirst = s.t;
        } else {
            aggr.low = Math.min(aggr.low, s.l);
            aggr.high = Math.max(aggr.high, s.h);
        }
        aggr.close = s.c;
        aggr.tLast = s.t;
        aggr.count++;
    }

    public static calcSupRes(data: SupResLineData): SupRes {
        // console.debug({supResData});
        // Pivot is calculated with the formula P = (H+L+C)/3, where P=Pivot, H=High, L=Low and C=Close
        // To calculate Support / Resistance:
        // R1 = 2P - L, S1 = 2P - H
        // R2 = (P - S1) +R1, S2 = P - (R2 - S1)
        const p = (data.high+data.low+data.close)/3;
        const r1 = MathEx.round(2*p-data.low, 4);
        const s1 = MathEx.round(2*p-data.high, 4);
        const r2 = MathEx.round(p+(data.high-data.low), 4);
        const s2 = MathEx.round(p-(data.high-data.low), 4);
        return {r1, r2, s1, s2};
    }

    public static calcSupResLineForPoints(stockStats: readonly IQuoteFull[]) {
        if (!stockStats.length) {
            return null;
        }
        const first = stockStats[0];
        const line = {count: 0} as AggrData;
        stockStats.forEach((s)=>{
            this.aggrAdd(s, line);
        });
        return this.supResToLine(line);
    }

    public static dailyLines(stockStats: readonly IQuoteFull[], days=3): SupResLine[] {
        const lines:AggrData[] = [];
        if (!stockStats.length) {
            return [];
        }
        let currTime = stockStats[0].t;
        let line = {count: 0} as AggrData;
        // use reverse order
        // tslint:disable-next-line:prefer-for-of
        for (let i=0;i<stockStats.length;i++) {
            const s = stockStats[i];
            if (line.count<days) {
                this.aggrAdd(s, line);
            } else {
                lines.push(line);
                currTime = s.t;
                line = {count: 0} as AggrData;
                this.aggrAdd(s, line);
            }
        }
        if (line.count>=days) {
            lines.push(line);
        }

        const final: SupResLine[] = []
        lines.forEach(l=>{
            final.push(this.supResToLine(l));
        });
        return final;
    }

    public static hourlyLines(stockStats: readonly IQuoteFull[]): SupResLine[] {
        const lines:AggrData[] = [];
        if (!stockStats.length) {
            return [];
        }
        const first = stockStats[0];
        let currHour = first.t;
        let line = {count: 0} as AggrData;
        const prev = currHour;
        for (const s of stockStats) {
            if (s.t<currHour+3600) {
                this.aggrAdd(s, line);
            } else {
                lines.push(line);
                currHour = s.t;
                line = {count: 0} as AggrData;
                this.aggrAdd(s, line);
            }
        }
        if (line.count>=50) {
            lines.push(line);
        }

        const final: SupResLine[] = []
        lines.forEach(l=>{
            final.push(this.supResToLine(l));
        });
        return final;
    }

    protected static supResToLine(l: AggrData) {
        const sr = this.calcSupRes(l) as SupResLine;
        sr.tFirst = l.tFirst;
        sr.tLast = l.tLast;
        return sr;
    }
}
