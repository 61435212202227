import React, { useState } from 'react';
import { ModalStockPrediction } from 'components/public/Stock/ModalStockPrediction';
import * as Icons from 'react-bootstrap-icons';
import { PixelHelper } from '_utils/PixelHelper';
import { useUtmSource } from '_hooks/useUtmSource';


export const MakeAPredictionButton:React.FunctionComponent<{iconOnly?: boolean, onClick?: ()=>void}> = ({iconOnly, onClick}) => {

  const [ showDialog, setShowDialog ] = useState<boolean>(false);
  const utmSource = useUtmSource();

  const _onClick = async () => {
    PixelHelper.clickMakePrediction(utmSource);
    if (onClick) {
      onClick();
    } else {
      setShowDialog(true);
    }
  }

  return (
    <div className="make-prediction">

      <ModalStockPrediction setShowDialog={setShowDialog} showDialog={showDialog} />

      {iconOnly && !!iconOnly  &&
      <button className="btn-make-prediction" onClick={_onClick}><Icons.Magic /></button>
      }

      {!iconOnly &&
      <button className="btn-make-prediction" onClick={_onClick}>Make Prediction </button>
      }
    </div>
  );
}