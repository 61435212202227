export enum CumulativeChartLines {
    PREDICTION_VALUES = 'predVals', //   const [predValsChecked, setPredValsChecked] = useState(initialData ? true : false);
    LAST_5PRED_AVG = 'last5predsAvg', // const [last5AvgChecked, setLast5AvgChecked] = useState(false);
    LAST_3PRED_AVG = 'last3predsAvg', // const [last3AvgChecked, setLast3AvgChecked] = useState(false);
    LAST_15MIN_AVG = 'last15minAvg', // const [last15minAvgChecked, setLast15minAvgChecked] = useState(false);
    LAST_30MIN_AVG = 'last30minAvg', // const [last30minAvgChecked, setLast30minAvgChecked] = useState(false);
    SUP_RES_LINES = 'supResLines', // const [supResLinesChecked, setSupResLinesChecked] = useState(false);
    TREND_LINES = 'trendLinesChecked', // const [trendLinesChecked, setTrendLinesChecked] = useState(false);
    EXTRA_SUP_RES_LINES = 'lastPricesSupRes', // const [lastPricesSupRes, setLastPricesSupRes] = useState(false);
    LAST_1H_AVG = 'last1hAvg', // const [last1hAvgChecked, setLast1hAvgChecked] = useState(false);
    LAST_2H_AVG = 'last2hAvg', // const [last2hAvgChecked, setLast2hAvgChecked] = useState(initialData ? true : false);
    LAST_3H_AVG = 'last3hAvg', // const [last3hAvgChecked, setLast3hAvgChecked] = useState(false);
    LAST_4H_AVG = 'last4hAvg', // const [last4hAvgChecked, setLast4hAvgChecked] = useState(false);
    // // const [cumulativeMinMaxChecked, setCumulativeMinMaxChecked] = useState(false);
    MIN_MAX_4H = 'minMax4h', // const [cumulativeMinMax4hChecked, setCumulativeMinMax4hChecked] = useState(false);
    MIN_MAX_2H = 'minMax2h', // const [cumulativeMinMax2hChecked, setCumulativeMinMax2hChecked] = useState(initialData ? true : false);
    MIN_MAX_2H_ATR = 'minMax2hAtr',// const [cumulativeMinMax2hAtrChecked, setCumulativeMinMax2hAtrChecked] = useState(false);
    MIN_MAX_120P_ATR = 'minMax120pAtr', // 120 points atr, it's same as 2H minute, but more abstract name
    MIN_MAX_50P_ATR = 'minMax50pAtr', // 120 points atr, it's same as 2H minute, but more abstract name

    MACD = 'macd', // const [macdChecked, setMacdChecked] = useState(false);
    EMA6 = 'ema6',
    EMA12 = 'ema12',
    EMA26 = 'ema26',
    EMA120 = 'ema120', // const [ema120Checked, setEma120Checked] = useState(false);
    SMA120 = 'sma120', // const [sma120Checked, setSma120Checked] = useState(false);
    VWAP = 'vwap', // const [vwapChecked, setVwapChecked] = useState(initialData ? true : false);
    PIVOT_POINT = 'pivotPoint',

    // EMA12_DAY       = 'ema12day',
    // EMA26_DAY       = 'ema26day',
    // EMA120_DAY      = 'ema120day',

    // PREV_CLOSE_TODAY_OPEN = 'prevCloseTodayOpen', it's under EXTRA_SUP_RES_LINES
}
