import React from "react";

import { IKeyValue } from "interfaces";
import { ILeague } from "interfaces/league";
import { DropDown } from "components/common/form/DropDown";
import { adminLeagueApiService } from "services/AdminApiService";
import { useApiService } from "_hooks/useApiService";

const LeagueDropDown: React.FunctionComponent<{ errors: any; touched: any; name: string }> = ({ errors, touched, name }) => {

  const league = useApiService<ILeague>(adminLeagueApiService, undefined, true, false);
  const getMap = (items: ILeague[]) => new Map<number, string>(
    items.map((i: ILeague) => [i.id as number, i.description as string])
  );

  return (
    <>

      {league.loaded && league.items && <DropDown enumMap={getMap(league.items)}
        defaultItem={{ key: 0, value: 'Select League' } as IKeyValue<number>}
        errors={errors}
        touched={touched}
        label="League" name={name} />}

      {!league.loaded && <div>Loading Leagues...</div>}
    </>
  );
};

export { LeagueDropDown };
