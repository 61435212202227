import React, { useEffect, useState } from "react";
import { ListContainer } from "components/common";
import { adminLeagueSeasonApiService, adminLeagueTeamApiService } from "services/AdminApiService";
import { FormButtons } from "_constants";
import { IDataField } from "interfaces";

import { ILeagueSeason } from "interfaces/league";
import { AdminLeagueSeasonForm } from "./Form";

export const AdminLeagueSeasonList: React.FunctionComponent = () => {
  const emptyValues = {
    name: '',
    description: '',
    startTime: (new Date()).getTime() / 1000,
    endTime: (new Date()).getTime() / 1000,
    teams: [0],
  } as ILeagueSeason

  const [teamMap, setTeamMap ] = useState<Map<number, string> | undefined>(undefined);

  useEffect(()=>{
    const _loadTeamMap = async () => {
      const teams = await adminLeagueTeamApiService.getAll();
      const map = new Map(teams.map(t=>[t.id, t.name]));
      setTeamMap(map);
    }
    _loadTeamMap();
  }, [])

  const editableFields: IDataField[] = [
    { name: 'id', title: 'ID', editable: false },
    { name: 'leagueId', title: 'League ID', editable: true },
    { name: 'name', title: 'Name', editable: true },
    { name: 'description', title: 'Description', editable: true },
    { name: 'startTime', title: 'Start Time', editable: true },
    { name: 'endTime', title: 'End Time', editable: true },
    { name: 'teams', title: 'Teams', editable: true },
  ];



  const getStartTime = (row: ILeagueSeason) => <>{(new Date(row.startTime * 1000)).toLocaleString()}</>
  const getEndTime = (row: ILeagueSeason) => <>{(new Date(row.endTime * 1000)).toLocaleString()}</>
  const getTeamNames = (row: ILeagueSeason) => {
    if (!row.teams) return '';
    return row.teams.map(teamId => teamMap?.get(teamId)).map(teamName => <div>{teamName}</div>);
  }

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['name', ['Name', undefined]],
    ['description', ['Description', undefined]],
    ['leagueId', ['League ID', undefined]],
    ['startTime', ['Start TIme', getStartTime]],
    ['endTime', ['End Time', getEndTime]],
    ['teams', ['teams', getTeamNames]]
  ]);

  return (
    <>
      <ListContainer
        title="Seasons"
        apiService={adminLeagueSeasonApiService}
        emptyValues={emptyValues}
        fieldList={fieldList}
        buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
        editableFields={editableFields}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }) => {
          return (
            <AdminLeagueSeasonForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick} editableFields={editableFields} />
          );
        }}
      </ListContainer>
    </>
  );
};


