import React from 'react'
import { Url } from '_constants';
import { PixelHelper } from '_utils/PixelHelper';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { config } from 'config';

export const MintGreenLogo = () => {
  return (
    <div className="discount-promo-logo my-2 my-md-5">
      <img src="/images/discount-promo/predictagram-logo-mint.png" alt="Predictagram" />
    </div>

  );
}

export const Footer = () => {
  return (
    <div className="p-5 text-center">
      Copyright &copy; {new Date().getFullYear()} Predictagram.com
    </div>
  );
}


export const DiscountPromoPage: React.FunctionComponent = () => {

  const navigate = useNavigate();

  const scrollToSection = (id: string): void => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const MainMessage = () => {
    return (
      <div className="discount-promo-main-msg">
        <div className="discount-promo-headline fw-bold">Get access to our <span className="text-mint-green">Top 30</span> proprietary trade signal algorithms.</div>

        <div className="my-3 text-center text-md-start">
          After a year of intense research, we're making our best back-tested signals available to the public.
        </div>

        <div className="d-none d-md-block">
          <div className="d-flex justify-content-center justify-content-md-start align-items-start">
            <div className="border-rounded-21 border-white p-3 fw-bold my-3 bg-hover-white text-hover-black" role="button" onClick={() => scrollToSection('results')}>
              Browse historical algorithm results
            </div>
          </div>
        </div>
      </div>

    );
  }

  const Highlights = () => {

    const highlights = [
      ['icon-dynamic-signal.png', 'Dynamic Signals', 'Our algorithms continuously adjust to market movements, ensuring accurate and up-to-date signals.'],
      ['icon-crowdsourced-data.png', 'Crowdsourced Data', 'Leverage top predictions from highly ranked traders to gain unique insights into price movement.'],
      ['icon-instant-updates.png', 'Instant Updates', 'Get text and email notifications for our Up or Down price signals to minimize any delays.'],
    ]

    return (
      <div className="discount-promo-highlights d-flex flex-column gap-3 rounded bg-black p-3">
        {highlights.map(([icon, title, subtitle], i) => (
          <div className='item d-flex align-items-center' key={`key-${i}`}>

            <div className="discount-promo-highlights-item-icon align-self-start m-2 flex-shrink-0">
              <img src={`/images/discount-promo/${icon}`} alt={title} />
            </div>
            <div className="mx-3">
              <div className="fw-bold text-mint-green">{title}</div>
              <div>{subtitle}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  const MobileImage = () => {
    return (
      <div className="discount-promo-mobile-preview">
        <div className="d-flex justify-content-center align-items-center">
          <div className="discount-promo-mobile-preview-image">
            <img src="/images/discount-promo/purple-mobile-screen.png" alt="Trading Signals" />
          </div>

        </div>
        <div className="rounded border-white fw-bold p-3 text-center bg-darkest-gray">
          Easy-to-use Crowdsourced Trading Signals
        </div>
      </div>

    );
  }

  const ComplexAlgorithm = () => {
    return (
      <div className="discount-promo-complex-algo bg-darkest-gray d-flex justify-content-center align-items-start align-items-md-center pt-3">
        <div className="d-flex justify-content-center align-items-center">
          <div className="discount-promo-complex-algo-card d-flex flex-column justify-content-center align-items-center py-3 px-5 p-md-5">
            <div className="discount-promo-headline"><span className="text-lavender">Complex algorithms</span><br /> for traders of all levels</div>

            <div className="sub-headline mt-3">Our unique signals combine aggregate predictions from our top predictors with leading indicators to provide transparent, high-performance trading insights.</div>

            <div className="sub-headline mt-3">Each signal comes with its historical profit performance and charts as tracked at Predictagram.com. </div>
          </div>

        </div>
      </div>
    );
  }

  const ReceiveAlerts = () => {
    return (
      <div className="discount-promo-receive-alerts pt-5 bg-black-to-green">
        <div className="d-flex justify-content-center align-items-center">
          <div className="discount-promo-headline fw-bold text-center">Receive alerts <span className="text-mint-green">immediately</span><br /> via text message and/or email.</div>
        </div>

        <div className="d-flex justify-content-center align-items-center pt-3">
          <div className="discount-promo-receive-alerts-preview">
            <img src="/images/discount-promo/white-mobile-screen.png" alt="Instant Alerts" />
          </div>

        </div>
      </div>
    );
  }

  const onClick = () => {
    PixelHelper.trackGAEvent('click_reserve_discount');
    navigate(Url.DISCOUNT_PROMO_SIGNUP);
  }

  const ProvenResults = () => {

    const data = [
      ['AMD', 57, 21],
      ['NVDA', 122, 24],
      ['AAPL', 51, 35],
      ['AMZN', 20, 14],
      ['COIN', 48, 20],
      ['META', 101, 10],
      ['TSLA', 53, 25],
      ['MSFT', 63, 29],
    ]

    return (
      <div id="results" className="discount-promo-proven-results">
        <div className="d-flex justify-content-center align-items-center mt-3 mb-2 mb-md-4 mt-md-5">
          <div className="discount-promo-headline"><span className="text-mint-green">Proven results</span> for each supported symbol.</div>
        </div>

        <div className="d-flex justify-content-center align-items-center">

          <table className="discount-promo-proven-results-table">
            <thead><tr>
              <th>symbol</th>
              <th className="text-center">avg. # of signals/month</th>
              <th className="text-center">avg. net profit/trade</th>
            </tr></thead>
            <tbody>
              {data.map(([symbol, avgSignals, avgProfit], i) => (
                <tr key={`row-${i}`}>
                  <td><div className="m-1">{symbol}</div></td>
                  <td className="text-center"><div className="m-1 bg-darkest-gray p-1">{avgSignals}</div></td>
                  <td className="text-center text-bright-green"><div className="m-1 bg-darkest-gray p-1">{avgProfit}%</div></td>
                </tr>
              ))}
            </tbody>
          </table>


        </div>

        {/* <div className="d-flex justify-content-center align-items-center">
          <div className="rounded bg-charcoal text-mint-green text-14 p-3 my-3">
            <div className="">Our test $10,000 account generated over $8,000 in profits over 1 month.*</div>
          </div>
        </div> */}

        <div className="d-flex justify-content-center align-items-center my-3">
          <div className="text-14 discount-promo-proven-results-text opacity-70">*Average net profit based on 1 day to expiration at the money options. Past performance is no guarantee of future performance. Not financial advice</div>
        </div>

      </div>

    );
  }


  const ReserveDiscount = () => {
    return (
      <div className="discount-promo-reserve-discount bg-black py-3 d-flex justify-content-center align-items-center mb-3 mt-4">
        <div className="border rounded border-color-mint-green p-4">
          As a thanks to our predictor community, we are launching our <span className="fw-bold text-mint-green">Pro Package</span> at a <span className="fw-bold text-mint-green">50% discount</span> for the first 500 signups.
          <div className="d-flex justify-content-center align-items-center">
            <div className="bg-mint-green px-5 mt-3 pill py-2 text-18 text-black fw-bold bg-hover-white" role="button" onClick={onClick}>Reserve Discount</div>
          </div>
        </div>
      </div>

    );
  }

  return (
    <>
      <Helmet>
        <title>Predictagram: Pro Signals Package</title>
        <meta property="og:title" content={`Predictagram: Pro Signals Package`} />
        <meta property="og:description" content={`Get access to our Top 30 proprietary trade signal algorithms.`} />
        <meta property="og:url" content={`${config.wwwDomain}/pro-signals-package`} />
      </Helmet>

      <div className="discount-promo justify-content-center align-items-start p-3 p-md-0">

        <div className="discount-promo-main d-flex justify-content-center align-items-center">
          <div className="discount-promo-main-body p-md-3">
            <div className="d-flex justify-content-center justify-content-md-start">
              <MintGreenLogo />
            </div>

            <div className="d-block d-md-none">

              <div className="d-flex flex-column flex-md-row mb-3">
                <div className="container-fluid g-0">
                  <MainMessage />
                  <MobileImage />
                  <div className="my-3">
                    <Highlights />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block">
              <div className="d-flex flex-column flex-md-row mb-3">
                <div className="container-fluid g-0">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <MainMessage />
                      <div className="my-3">
                        <Highlights />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <MobileImage />
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

        <ComplexAlgorithm />

        <ReceiveAlerts />

        <ProvenResults />

        <ReserveDiscount />


        <Footer />
      </div>

    </>



  )
}
