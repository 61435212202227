import { Url } from '_constants';
import { IProfileTeam } from 'interfaces/IPublicProfile'
import React from 'react'
import { useNavigate } from 'react-router-dom';

export interface ITeamInfo {
  id: number,
  name: string,
  logoUrl: string,
}

export const TeamInfo:React.FunctionComponent<{profileTeam: IProfileTeam, className?: string}> = ({profileTeam, className}) => {

  const navigate = useNavigate();

  return (
    <div className={`d-flex justify-content-start align-items-center`}>
    <div className={`profile-team px-3  ${className || ''}`} role="button" onClick={()=>navigate(Url.USER_TEAM_DETAIL.replaceAll(':teamId', profileTeam.id.toString()))}>
      <div className="profile-team-logo"><img src={profileTeam.logoUrl} alt={profileTeam.name}/></div>
      <div className="profile-team-name">{profileTeam.name}</div>
    </div>
    </div>
  );

}
