import React from 'react'
import { Settings } from './Settings'

export const SettingsPage = () => {
  return (
    <>
      <Settings />
    </>
  )
}
