import React from 'react';
import Plot from 'react-plotly.js';
import {IChart} from "../../../../../interfaces/chart/IChart";

export const PredictionChart: React.FunctionComponent<{ chartData: IChart}> = ({ chartData }) => {
  return (
    <>
      {chartData &&
       <Plot
         data={chartData.data}
         layout={chartData.layout}
         config={chartData.config}
         onInitialized={chartData.onInitialized}
         divId={chartData.divId}
         // onDoubleClick={()=>false}
         // onClick={()=>false}
         // onLegendDoubleClick={() => false}
         style={{
          width:"100%",
          cursor: "pointer"
        }}
         // revision={chartData.revision}
         onClick={chartData.onClick}
       />
      }
    </>
  );
}
