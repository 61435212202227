import React, { useCallback, useEffect } from 'react';

export default function useClickOutside  (divRef: React.RefObject<HTMLDivElement>, handler: ()=>void)  {

  const handlerCallback = useCallback(()=> {
    handler();
  }, [handler]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        handlerCallback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef, handlerCallback])
}

