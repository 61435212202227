import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { predictionService } from "services";
import { IChart } from "interfaces/chart/IChart";
import { IPrediction, IQuote, PredictionStatusEnum, PredictionTypeEnum, StockHelper } from 'predictagram-lib';
import { PostSubmitChartModel as model } from 'models/post-submit-chart.model';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { useBarsApi } from '_hooks/useBarsApi';
import { usePostSubmitApi } from '_hooks/usePostSubmitApi';
import { ConsensusSummary } from './ConsensusSummary';
import { PredictionDirectionQuote } from 'components/public/ListingsPage/components/Prediction/PredictionDirectionQuote';
import { SharePostPrediction } from 'components/common/SharePostPrediction';
import { UrlHelper } from '_utils/UrlHelper';
import Plot from 'react-plotly.js';
import { Figure } from 'react-plotly.js';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { PredictionArrow } from 'components/common/arrows/PredictionArrow';

export const PostPredictionChart: React.FunctionComponent<{ prediction: IPrediction, onClose?: ()=>void }> = ({ prediction, onClose }) => {
  const [chartData, setChartData] = useState<IChart>(undefined as any);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const chartRef= useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const arrowRefs = useRef<Array<HTMLDivElement | null>>([]);

  const handleInitialize = (figure: Figure, chartElement: any) => {
    model.initializeHandler(figure, chartElement, arrowRefs);
  }

  const symbolName = prediction.stockSymbol;
  const lastHourTime = (prediction.createdAt as number) - (60 * 60 * 1); // last hour
  const endTime = prediction.createdAt as number;

  const workStart = StockHelper.workingHours(new Date(lastHourTime * 1000)).start.getTimeSec();
  const startTime = Math.max(workStart, lastHourTime);

  const barsApi = useBarsApi(predictionService, symbolName, startTime, endTime);
  const postSubmitApi = usePostSubmitApi(userPredictionApiService, prediction.typeId as PredictionTypeEnum, startTime, endTime, symbolName);
  const [barsWithPrediction, setBarsWithPrediction] = useState<IQuote[]>([]);
  const [hovered, setHovered] = useState<boolean[]>([]);

  const _load = useCallback(async () => {
    if (barsApi.apiStatus.isLoaded && postSubmitApi.apiStatus.isLoaded && prediction) {
      // stock plot
      const barsWithPrediction = model.getBars(barsApi.items, prediction);
      const stockPlotData = model.getStockPlotData(barsWithPrediction);
      const postPredictionsPlotData = model.getPostPredictionsPlotData2(postSubmitApi.items, barsWithPrediction);
      const annotations = model.getAnnotations(postSubmitApi.items, prediction, barsWithPrediction);
      const shapes = model.getShapes(postSubmitApi.items, barsWithPrediction);
      const layout = model.getLayout(annotations, shapes, stockPlotData, postPredictionsPlotData);
      const chart: IChart = {
        data: [
          stockPlotData,
          postPredictionsPlotData,
        ],
        layout,
        config: {displayModeBar: false, autosizable: false, editable: false}
      } as IChart

      const hovered: boolean[] = Array(postSubmitApi.items.length-1).fill(false);
      hovered[hovered.length] = true;
      setHovered(hovered);
      setBarsWithPrediction(barsWithPrediction);
      setChartData(chart);
      setIsLoaded(true);
    }
  }, [barsApi.apiStatus.isLoaded, barsApi.items, postSubmitApi.apiStatus.isLoaded, postSubmitApi.items, prediction])

  useEffect(() => {
    _load();
  }, [_load])

  const chartContainerStyle: React.CSSProperties = {
    //background: 'linear-gradient(to bottom, rgb(67 67 67 / 28%), rgb(24 24 24 / 44%))',
    width: '100%',
    height: '100%',
    position: 'relative',
  };

  const _setHovered = (idx: number, v: boolean) => {
    const _hovered = Array(hovered.length).fill(false);
    _hovered[idx] = v;
    setHovered(_hovered);
  }
  
  if (!chartData || barsApi.apiStatus.isLoading || postSubmitApi.apiStatus.isLoading) {
    return <div className="predictor-card-chart shimmer bg-dark-gray w-100" ref={chartRef} style={{height: "37.5rem", maxWidth: "25rem"}}></div>
  }

  return (
    <div className="predictor-card-chart" ref={chartRef} style={{maxWidth: "400px"}}>
      {chartData &&
        <div>
          {/* <pre>{JSON.stringify(prediction, null, 2)}</pre> */}
          <PredictionDirectionQuote prediction={prediction} />

          <div className="d-flex justify-content-start align-items-center gap-2 mb-2">
            <div style={{width: "100%", maxWidth: "18.75rem"}}className="pill bg-charcoal px-5" role="button"><SharePostPrediction prediction={prediction} /></div>
          </div>

          <div style={chartContainerStyle}>
            <Plot
              data={chartData.data}
              layout={chartData.layout}
              config={chartData.config}
              style={{ width: '100%', height: '100%' }}
              //onHover={handleHover}
              onInitialized={handleInitialize}
              
            />

            {postSubmitApi.items.map((item, i, arr) => <div key={`arrow-key-${i}`}>
              <div ref={(el) => (arrowRefs.current[i] = el)}>
                <PredictionArrow profile={{
                      userId: item.userId,
                      username: `${item.username}${item.id === prediction.id ? " -(You)" : ""} `,
                      avatarUrl: item.avatarUrl,
                      predictions: { averageScore: item.userScoreLast30 }
                    } as IPublicProfile}
                      isUpward={model.isPredictedDirectionUp(item)}
                      onHover={()=>_setHovered(i, true)}
                      onLeave={()=>_setHovered(i, false)}
                      hovered={hovered[i]}
                />
              </div>
            </div>)}
          </div>

          {isLoaded &&
          <div className="mb-3">
            <ConsensusSummary stockQuotes={barsWithPrediction} postPredictions={postSubmitApi.items} prediction={prediction} />
          </div>
          }

          <div className="fw-bold text-decoration-underline" role="button" onClick={()=>{
              if (onClose) {
                onClose();
              }
              navigate(UrlHelper.getSymbolUrl(prediction.stockSymbol))
            }
          }>View Recent {prediction.stockSymbol} Predictions -&gt;</div>
        </div>
      }
    </div>
  );

}
