import { IWalkthrough, WalkthroughCardType } from "components/user/walkthrough/Wallkthrough"

export class WalkthroughConsensusModel {

  private FADED_OPACITY = '0.15';
  private mainSvg0: any;
  private layer: any; //HTMLCollectionOf<HTMLElement>

  private steps: WalkthroughCardType[] = [];

  private fadeElements = () => {
    [
    this.layer.querySelector('.trace:nth-child(1)'),
    this.layer.querySelector('.trace:nth-child(2)'),
    this.layer.querySelector('.trace:nth-child(3)'),
    this.mainSvg0.querySelector('.cartesianlayer .xaxislayer-above'),
    this.mainSvg0.querySelector('.cartesianlayer .yaxislayer-above'),
    ].forEach((item: HTMLElement)=> {
      if (item !== null) {
        item.style.opacity = this.FADED_OPACITY;
      }
    })
  }

  private page1Style = (a: HTMLDivElement, b: HTMLDivElement): React.CSSProperties => {
    this.mainSvg0.style.zIndex = '101';
    this.layer.querySelector('.trace:nth-child(5)').style.opacity = this.FADED_OPACITY;
    this.layer.querySelector('.trace:nth-child(4)').style.opacity = '1';
    const rect = b.getBoundingClientRect();
    this.fadeElements();

    const left = rect.x;
    const center = (window.innerWidth - a.offsetWidth) / 2;
    const bottom = rect.bottom + 10;

    return {
      left: `${left + a.offsetWidth > window.innerWidth ? center : left }px`,
      top: `${bottom + a.offsetHeight > window.innerHeight ? rect.top - a.offsetHeight : bottom}px`,
    }
  }

  private page2Style = (a: HTMLDivElement, b: HTMLDivElement): React.CSSProperties => {
    const rect = b.getBoundingClientRect();
    this.mainSvg0.style.zIndex = '101';
    this.layer.querySelector('.trace:nth-child(4)').style.opacity = this.FADED_OPACITY;
    this.layer.querySelector('.trace:nth-child(5)').style.opacity = '1';

    const left = rect.x;
    const center = (window.innerWidth - a.offsetWidth) / 2;
    const bottom = rect.bottom + 10;

    return {
      left: `${left + a.offsetWidth > window.innerWidth ? center : left }px`,
      top: `${bottom + a.offsetHeight > window.innerHeight ? rect.top - a.offsetHeight : bottom}px`,
    }
  }

  public constructor(
    protected plotlyDiv: any,
  ) { 
    this.mainSvg0 = this.plotlyDiv.getElementsByClassName("main-svg")[0];
    this.layer = this.mainSvg0.querySelector('.cartesianlayer .plot .scatterlayer.mlayer');

    if (this.layer.querySelector('.trace:nth-child(5)') !== null) {
      this.steps.push([
        <>The solid <span className="text-bright-green">green</span> line shows where our community thinks the symbol is <span className="text-bright-green">overbought</span></>,
        this.layer.querySelector('.trace:nth-child(4)'),
        this.page1Style
      ]);
  
      this.steps.push([
        <>The solid <span className="text-bright-red">red</span> line shows where our community thinks the symbol is <span className="text-bright-red">oversold</span></>,
        this.layer.querySelector('.trace:nth-child(5)'),
        this.page2Style
      ])
    }
  }

  public removeFade () {
    [
      this.layer.querySelector('.trace:nth-child(1)'),
      this.layer.querySelector('.trace:nth-child(2)'),
      this.layer.querySelector('.trace:nth-child(3)'),
      this.layer.querySelector('.trace:nth-child(4)'),
      this.layer.querySelector('.trace:nth-child(5)'),
      this.mainSvg0.querySelector('.cartesianlayer .xaxislayer-above'),
      this.mainSvg0.querySelector('.cartesianlayer .yaxislayer-above'),
    ].forEach((item: HTMLElement) => {
      if (item) {
        item.style.opacity = '1';
      }
    })
    this.mainSvg0.style.zIndex = 'unset';
  }  

  public getWalkthrough(): IWalkthrough {

    const walkthrough: IWalkthrough = {
      title: <>How to read a consensus chart</>,
      steps: [
        ...this.steps
      ]
    }
    return walkthrough;
  }


}
