import React from "react";
import { BaseForm, FieldWithError } from "components/common";

import { validationMap, Validator } from "schemas";
import { adminLeagueTeamApiService } from "services/AdminApiService";
import { IDataField } from "interfaces";
import { LeagueDropDown } from "../LeagueDropDown";
import { ITeam } from "interfaces/league";
import { StatusDropDown } from "../StatusDropDown";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: ITeam) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

export const AdminLeagueTeamForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {
  return (
    <BaseForm
      apiService={adminLeagueTeamApiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_CONTEST_FORM)}
      editableFields={editableFields}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <>

            <LeagueDropDown name="leagueId" errors={errors} touched={touched} />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="ownerId"
              placeholder="Owner ID"
              label="Team Owner ID" />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="name"
              placeholder="Team Name"
              label="Team Name" />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="description"
              placeholder="Description"
              label="Description"
            />

            <StatusDropDown name={`statusId`} errors={errors} touched={touched} />

          </>
        );
      }}
    </BaseForm>
  );
};


