import React from 'react'
import { SelectDropDown } from 'components/common/form/SelectDropDown'

interface IProps {
  value: number | null,
  setValue: (value: number | null) => void,
  className?: string,
}

export const OpenMaxDropdown:React.FunctionComponent<IProps> = ({setValue, value, className}) => {

  const mapWithTuples: Map<number, number> = new Map(
    Array.from({ length: 20 }, (_, i) => [i + 1, i + 1] as [number, number])
  );

  return (
    <SelectDropDown<number, number> 
      onSelect={setValue}
      options={mapWithTuples}
      selected={value || undefined}
      title={undefined}
      className={className}
      hasAll={true}
      allTitle="Unset"
    />
  )
}
