import { getAnalysisInputParams } from "components/admin/common/Criteria";
import { IApiResponse, useApi } from "./useApi";
import { IAutoTradeSetup, StockSignalsEnum } from "predictagram-lib";
import { adminAutoTradeSetupService } from "services/AdminApiService";
import { useEffect, useState } from "react";
import { TradeSetupFilterOptions } from "components/admin/autotrade/TradeSetupForm";



type Signals = {
  openSignals: StockSignalsEnum[];
  closeSignals: StockSignalsEnum[];
}

export interface IAdminAutoTradeSetupSingle {
  api: IApiResponse<IAutoTradeSetup, number>,
  signals: Signals | undefined
}

export function useAdminAutoTradeSetupSingle (id: number): IAdminAutoTradeSetupSingle {

  const [data, setData] = useState<IAutoTradeSetup | undefined>(undefined);
  const [signals, setSignals] = useState<Signals | undefined>(undefined);

  useEffect(()=>{
    if (data) {
      const analysisInputParams = getAnalysisInputParams(data.analysisSetup as TradeSetupFilterOptions);
      const openSignals = analysisInputParams?.openSignalSetup?.signals.map(s=>s.type) || [];
      const closeSignals = analysisInputParams?.closeSignalSetup?.signals.map(s=>s.type) || [];
      setSignals({
        openSignals,
        closeSignals
      });
    } else {
      setSignals(undefined);
    }
  },[data])

  const load = async (id: number) => {
    const data = await adminAutoTradeSetupService.getById(id);
    setData(data);
    return data;
  }


  return {
    api:  useApi<IAutoTradeSetup, number>(load, id),
    signals
  }
}

