import { APIState, ApiStateImpl, IApiState } from "_constants/APIState";
import { useCallback, useEffect, useRef, useState } from "react";
import { ITradegramTransaction, TradegramApiService } from "services/TradegramApiService";


export function useTradegramSecurityTransactions (service: TradegramApiService, securityId: number, ownedOnly: boolean = false) {

  const [ items, setItems ] = useState<ITradegramTransaction[]>([]);
  const [ state, setState ] = useState<IApiState>(ApiStateImpl.IDLE);

  const serviceRef = useRef(service);

  const load = useCallback(async (abortSignal: AbortSignal)=>{
    try {
      setState(ApiStateImpl.LOADING);
      if (ownedOnly) {
        const items = await serviceRef.current.getMySecurityTrades(securityId, abortSignal);
        setItems(items.sort((a, b) => b.createdAt - a.createdAt)); // sort descending
      } else {
        const items = await serviceRef.current.getSecurityTrades(securityId, abortSignal);
        setItems(items.sort((a, b) => b.createdAt - a.createdAt)); // sort descending
      }
      setState(ApiStateImpl.LOADED);
    } catch (error) {
      setState(new ApiStateImpl(APIState.ERROR, error as Error));
    }
  }, [securityId, ownedOnly])

  useEffect(()=>{
    const abortController = new AbortController();
    load(abortController.signal);

    // clean up
    return () => {
      if (abortController) {
        abortController.abort();
      }
    }    
  }, [load])

  return {items, state};

}