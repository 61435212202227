import React from 'react'
import { useParams } from 'react-router-dom';
import { PostPredictionChart } from './PostPredictionChart';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { usePublicPrediction } from '_hooks/usePublicPrediction';
import { Spinner } from 'components/common/Spinner';
import { ErrorMessage } from 'components/common/ErrorMessage';

export const PostPredictionPage: React.FunctionComponent = () => {

  const {predictionId} = useParams();
  const { items: predictions, status } = usePublicPrediction(userPredictionApiService, { predictionId: predictionId ? parseInt(predictionId, 10) : 0 } as ISearchOptions)
  
  if (status.loading) {
    return <Spinner />
  }

  if (status.loaded) {
    return (
      <div>
        <PostPredictionChart prediction={predictions[0]} />
      </div>
    )
  }

  if (status.error) {
    return <ErrorMessage>{status.error}</ErrorMessage>
  }

  return <></>

}
