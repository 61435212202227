import React, { useState } from "react";
import { Formik, Form } from "formik";
import { FieldWithError } from "components/common";
import { validationMap, Validator } from "schemas";
import { userAccountService } from "services";
import { useParams } from "react-router";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { ShowHidePassword } from "components/common/form/ShowHidePassword";
import { Url } from "_constants";

export const SetPassword: React.FunctionComponent = () => {
  const [isComplete, setIsComplete] = useState<Boolean>(false);
  const { authID } = useParams();

  const [show, setShow] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const toggleShowHidePassword = () => {
    setShow(!show);
  }

  const toggleShowHideConfirmPassword = () => {
    setShowConfirm(!showConfirm);
  }

  const initialValues: any = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = validationMap.get(Validator.RESET_PASSWORD_FORM);

  const onSubmit = async (data: any, actions: any) => {
    // @TODO: change to AccountProvider
    const { setSubmitting, setStatus } = actions;
    setSubmitting(true);
    const payload = {
      authId: authID || "",
      password: data.password,
      repeatPassword: data.confirmPassword,
    };
    try {
      await userAccountService.setPassword(payload);
      setIsComplete(true);
    } catch (err) {
      setSubmitting(false);
      setStatus(`Password reset failed: ${err}`);
    }
  };

  return (
    <div className="container my-3 set-password-form">
      <div className="page-title">Set Your Password</div>
      {isComplete ? (
        <div className="text-center my-5 small-text-2">
          <p>Your password has been succesfully set.</p>
          <p>You may trying logging on again <a href={Url.USER_LOGIN} className="text-lavender">here</a>.</p>
        </div>
      ) : (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ isSubmitting, errors, touched, status, setSubmitting }) => {
            return (
              <Form className="form d-flex flex-row justify-content-center">
                <div className="my-3">

                  <ShowHidePassword
                    errors={errors}
                    touched={touched}
                    fieldName="password"
                    placeholder="Enter Password"
                    label="Password"
                    readOnly={false}
                  />


                  <ShowHidePassword
                    errors={errors}
                    touched={touched}
                    fieldName="confirmPassword"
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    readOnly={false}
                  />

                  <div className="my-3">
                    <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                      Set Password
                    </button>
                  </div>

                </div>


                {status && (
                  <div className="alert">{status}</div>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

