import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConsensusChart } from 'components/public/Stock/ConsensusChart';
import { ModalSymbols } from './ModalSymbols';
import { IAccountSettings } from 'interfaces/account/IAccountSettings';
import { Link } from 'react-router-dom';
import { Url } from '_constants';
import { SymbolButtons } from './filters/SymbolButtons';
import { MessengerContext, Severity } from 'components/common/messenger';
import { StockPriceChange } from 'components/public/Stock/StockPriceChange';
import { StockHelper } from 'predictagram-lib';
import { ButtonOffBlack } from 'components/common/buttons/CharcoalButton';
import { Pencil } from 'react-bootstrap-icons';
import { useModalHelp } from '_hooks/useModalHelp';
import { UrlHelper } from '_utils/UrlHelper';
import { useApiSignals } from '_hooks/useApiSignals';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import { IUserSignalAlertSearch } from 'services/UserSignalApiService';
import { Signals } from './signals/Signals';
import { SignalsPage } from './signals/SignalsPage';

export const ConsensusChartSmall: React.FunctionComponent<{ stockSymbol: string }> = ({ stockSymbol }) => {
  const search: IUserSignalAlertSearch = {
    symbolName: stockSymbol
  } as IUserSignalAlertSearch

  const api = useApiSignals(search);
  if (api.apiState.isError) {
    return <ErrorMessage>{api.error}</ErrorMessage>
  }

  if (api.apiState.isLoading) {
    return <Spinner minHeight={50} />
  }

  if (api.apiState.isLoaded) {
    return <>
      {stockSymbol &&
        <ConsensusChart showSignalDetail={false} signalAlerts={api.data} predictionTypes={undefined} stockSymbol={stockSymbol} heightOverride={200} title={
          <div className="d-flex align-item-center gap-2">
            <span className="prediction-detail-symbol"><Link to={Url.PUBLIC_STOCK_PREDICTIONS.replace(':stockSymbol', stockSymbol)}>${stockSymbol}</Link></span>
            {(StockHelper.isMarketOpen(new Date()) || StockHelper.isPostMarketHours(new Date())) && <StockPriceChange stockSymbol={stockSymbol} />}
          </div>
        } />
      }
    </>

  }

  return <></>

}


interface IProps {
  settings: IAccountSettings,
  updateSettings: (settings: IAccountSettings | unknown) => Promise<void>
}

export const WatchList: React.FunctionComponent<IProps> = ({ settings, updateSettings }) => {

  const [showWatch, setShowWatch] = useState<boolean>(false);
  const msgrContext = useContext(MessengerContext);
  const symbols = settings.symbolsWatchlist;
  const modalHelp = useModalHelp();
  const navigate = useNavigate();

  const [showSignalDetail, setShowSignalDetail] = useState<boolean>(false);

  const onSymbolClick = async (stockSymbol: string, selected: boolean) => {
    const watched = settings.symbolsWatchlist;

    if (selected) {
      // remove
      const updatedWatched = watched?.filter((s: string) => s !== stockSymbol);
      if (updatedWatched && updatedWatched.length === 0) {
        await updateSettings({ symbolsWatchlist: null })
      } else {
        await updateSettings({ symbolsWatchlist: updatedWatched })
      }
      return;
    }

    // add
    if (watched === null) {
      await updateSettings({ symbolsWatchlist: [stockSymbol] })
    } else {
      await updateSettings({ symbolsWatchlist: [...watched, stockSymbol] })
    }

  }


  const WatchListSetup = () => {
    const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]);
    const onSymbolClickHandler = (symbol: string, selected: boolean) => {
      if (selected) {
        setSelectedSymbols(selectedSymbols.filter((s) => s !== symbol));
      } else {
        setSelectedSymbols([
          ...selectedSymbols,
          symbol,
        ])
      }
    }

    const _updateSettings = async () => {
      if (selectedSymbols.length === 0) {
        msgrContext.setMessage({ body: 'Please select at least 1 symbol' }, true, Severity.NORMAL);
        return;
      }
      await updateSettings({ symbolsWatchlist: [...selectedSymbols] })
    }

    return (
      <div className="bg-off-black rounded p-3">
        <div className="fw-bold text-18">Let's get you set up</div>
        <div className="my-3">Choose at least 1 symbol to get started.</div>
        <SymbolButtons onSymbolClick={onSymbolClickHandler} selectedSymbols={selectedSymbols} />

        <button className="btn btn-primary text-14 px-5 my-3" onClick={() => { _updateSettings() }}>All Set!</button>
      </div>
    );
  }

  const onClickChart = (e: React.MouseEvent<HTMLDivElement>, stockSymbol: string) => {
    e.preventDefault();
    navigate(UrlHelper.getSymbolUrl(stockSymbol))
  }

  return (
    <div className="watchlist">
      <ModalSymbols show={showWatch} handleClose={() => setShowWatch(false)} onSymbolClick={onSymbolClick} selectedSymbols={settings.symbolsWatchlist}></ModalSymbols>
      {modalHelp.modalElement()}
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center my-3 gap-2">
          <div className="fw-bold ms-1 ms-md-4 ps-md-2">Dashboard</div>
          {
            settings.symbolsWatchlist && settings.symbolsWatchlist.length > 0 &&
            <ButtonOffBlack onClick={() => setShowWatch(true)} className="text-12">
              <div className="d-flex justify-content-center align-items-center gap-2"><Pencil /> Edit</div>
            </ButtonOffBlack>
          }
        </div>
        <div className="me-3">{modalHelp.helpIcon()}</div>
      </div>

      {symbols === null ? (
        <WatchListSetup />
      )
        : (

          <div>
            {/* <div className="d-flex gap-3 ms-1 ms-md-4 ps-md-2 mb-3">
              <div role="button" onClick={()=>setShowSignalDetail(false)} className={`${!showSignalDetail ? 'fw-bold' : ''}`}>Charts</div>
              <div role="button" onClick={()=>setShowSignalDetail(true)} className={`${showSignalDetail ? 'fw-bold' : ''}`}>Signals</div>
            </div> */}

            <div className="d-flex flex-wrap">
              {showSignalDetail ? <div className="ms-md-4 ms-1">
                <SignalsPage symbols={symbols}/>
              </div> : <>
                {symbols.map((stockSymbol: string, i: number) => <div className="watchlist-chart" key={`chart-${i}`}>
                  <div className="m-3" role="button" onClick={(e: React.MouseEvent<HTMLDivElement>) => onClickChart(e, stockSymbol)}>
                    <ConsensusChartSmall stockSymbol={stockSymbol} />
                  </div>
                </div>)}

              </>}

            </div>
          </div>
        )}


    </div>
  )
}



