import { IPublicProfile } from "interfaces/IPublicProfile";
import { IPrediction, IQuote, PredictionTypeEnum } from "predictagram-lib";
import { IPostSubmitChartResponse, IPostSubmitChartSearchOptions, userPredictionApiService } from "services/UserPredictionApiService";
import { Figure } from 'react-plotly.js';
import { ChartModel } from "./chart.model";

export class PostSubmitChartModel {

  /**
   * get data from api
   * @param prediction 
   * @returns 
   */
  static async getPostSubmitData(prediction: IPrediction) {
    const options: IPostSubmitChartSearchOptions = {
      endTimePredMade: prediction.createdAt as number,
      startTimePredMade: (prediction.createdAt as number) - (60 * 60 * 1), // only get 1 hour
      predictionTypes: [prediction.typeId as PredictionTypeEnum],
      symbolName: prediction.stockSymbol
    }
    return await userPredictionApiService.getPostSubmitChartData(options);
  }

  /**
   * stock data reducer
   * @param acc 
   * @param quote 
   * @returns 
   */
  static reduceQuotes(acc: { x: number[], y: number[] }, quote: IQuote) {
    acc.x.push(quote.t * 1000);
    acc.y.push(quote.c);
    return acc;
  }

  /**
   * get the stock PlotData
   * @param quotes 
   * @returns 
   */
  static getStockPlotData(quotes: ReadonlyArray<IQuote>) {
    const { x, y } = quotes.reduce(this.reduceQuotes, { x: [], y: [] })
    const plotData: Plotly.PlotData = {
      x,
      y,
      hoverinfo: 'none',
      marker: {
        color: '#A771FF'
      }
    } as Plotly.PlotData
    return plotData
  }

  /**
   * prediction reducer
   * @param acc 
   * @param postPrediction 
   * @returns 
   */
  static reducePostPredictions(acc: { x: number[], y: number[], customdata: any[] }, postPrediction: IPostSubmitChartResponse) {
    //acc.x.push(ChartModel.timeFormatHour(postPrediction.valueTime as number));
    acc.x.push(postPrediction.valueTime as number * 1000);
    acc.y.push(postPrediction.value as number);
    acc.customdata.push(postPrediction);
    return acc;
  }

  /**
   * get the user prediction plotdata
   * @param postPredictions 
   * @returns 
   */
  // static getPostPredictionsPlotData(postPredictions: ReadonlyArray<IPostSubmitChartResponse>) {
  //   const { x, y, customdata } = postPredictions.reduce(this.reducePostPredictions, { x: [], y: [], customdata: [] })
  //   const plotData: Plotly.PlotData = {
  //     x,
  //     y,
  //     mode: "markers",
  //     type: "scatter",
  //     hoverinfo: 'none',
  //     customdata,
  //   } as Plotly.PlotData
  //   return plotData
  // }

  static getPostPredictionsPlotData2(postPredictions: ReadonlyArray<IPostSubmitChartResponse>, quotes: ReadonlyArray<IQuote>) {

    // find the quotes where x matches postPredictions.x
    const qx = [];
    const qy = [];
    const colors: any[] = [];
    const customdata = [];
    for(let i=0; i < postPredictions.length; i++) {
      const p = postPredictions[i];
      //qx.push(ChartModel.timeFormatHour(p.valueTime));
      qx.push(p.valueTime * 1000);
      const q = quotes.find(q=>q.t === p.valueTime );
      if (q) {
        qy.push(q.c);
        colors.push(this.isPredictedDirectionUp(p) ? '#5EFF44' : '#FF6060');
        customdata.push(p);
      }
    }

    const plotData: Plotly.PlotData = {
      x: qx,
      y: qy,
      mode: "markers",
      hoverinfo: 'none',
      marker: {color: colors},
      type: "scatter",
      customdata: customdata as any,
    } as Plotly.PlotData
    return plotData
  }

  static isPredictedDirectionUp (postPredictionResponse: IPostSubmitChartResponse): boolean {
    if (postPredictionResponse.value > postPredictionResponse.valueStock) {
      return true;
    }
    return false;
  }

  static predictionDirection (postPredictionResponse: IPostSubmitChartResponse): "up" | "down" {
    if (postPredictionResponse.value > postPredictionResponse.valueStock) {
      return "up"
    }
    return "down";
  }

  static getShapes(postPredictions: ReadonlyArray<IPostSubmitChartResponse>, quotes: ReadonlyArray<IQuote>): Partial<Plotly.Shape>[] {
    const shapes: Partial<Plotly.Shape>[] = [];
    const lastPrice = quotes[quotes.length-1].c;
    shapes.push(ChartModel.shapeHorizontalLineValue(lastPrice));
    // for (let i = 0; i < postPredictions.length; i++) {
    //   const x0 = (postPredictions[i].valueTime as number);
    //   const y0 = (quotes.find(q => q.t === x0)?.c as number)
    //   const color  = postPredictions[i].valueStock < postPredictions[i].value ? "green" : "red";
    //   shapes.push({
    //     type: 'line',
    //     x0: x0 * 1000, // x priceline
    //     y0: y0, // y priceline
    //     x1: x0 * 1000,  // x postPrediction
    //     y1: postPredictions[i].value, // y postPrediction
    //     line: {
    //       color,
    //       width: 4,
    //       dash: 'solid',
    //     },
    //   } as Plotly.Shape)
    // }
    return shapes;
  }

  static getColor = (valueStock: number, predictedValue: number) => {
    return valueStock < predictedValue ? "green" : "red";
  }

  static getAnnotations(postPredictions: ReadonlyArray<IPostSubmitChartResponse>, prediction: IPrediction, quotes: ReadonlyArray<IQuote>): Partial<Plotly.Annotations>[] {

    const annotations: Partial<Plotly.Annotations>[] = [];

    const lastPrice = quotes[quotes.length-1].c;
    annotations.push({
      x: 1,
      align: "right",
      xref: 'paper',
      y: lastPrice,
      borderpad: 1,
      borderwidth: 1,
      xshift: 8,
      xanchor: 'left',
      yanchor: 'middle',
      text: lastPrice.toFixed(2),
      showarrow: false,
      font: { color: '#fff', },
      bgcolor: 'rgba(138,126,247,0.8)',
      bordercolor: 'rgba(138,126,247,0.8)',
    })

    // for (let i = 0; i < postPredictions.length; i++) {

    //   const color = this.getColor(postPredictions[i].valueStock, postPredictions[i].value);

    //   username/arrow annotations
    //   @note: not using annotation because it cannot support object in custom data
    //   annotations.push({
    //     x: (postPredictions[i].valueTime as number) * 1000,
    //     y: postPredictions[i].value as number,
    //     xref: 'x',
    //     yref: 'y',
    //     showarrow: false,
    //     //arrowhead: 2,
    //     //arrowsize: 2,
    //     yshift: 5 * (color === "green" ? 1 : -1),
    //     ax: 0,
    //     //ay: 40 * (color === "green" ? 1 : -1),
    //     align: 'center',
    //     //arrowcolor: color,
    //     //arrowwidth: 2,
    //     hovertext: postPredictions[i].username,
    //     hoverinfo: 'text',
    //     //source: avatars[i],
    //   } as Partial<Plotly.Annotations>);
    // }

    // you
    // const myQuote = quotes.find(q=>q.t === prediction.valueTime as number);
    // const youColor = this.getColor(prediction.value as number, myQuote?.c as number);
    // annotations.push({
    //   x: prediction.valueTime as number * 1000,
    //   y: (quotes.find(q => q.t === prediction.valueTime as number)?.c as number),
    //   xref: 'x',
    //   yref: 'y',
    //   ax: 0,
    //   ay: -10 * (youColor === "green" ? 1 : -1),
    //   text: "You",
    //   font: { 
    //     size: 12,
    //     color: 'rgba(255, 255, 255, 1)' },
    //   bgcolor: 'rgba(255, 255, 255, 0)',
    //   bordercolor: 'rgba(255, 255, 255, 0)',
    //   borderpad: 4,
    //   opacity: 0.8,
    //   align: 'center',
    //   arrowcolor: youColor,
    // } as Partial<Plotly.Annotations>);      

    return annotations
  }

  /**
   * 
   * @param postPredictions 
   * @returns 
   * RT: this doesn't work as of 12/29. Images do not show up despite solving CORS error. 
   * Also tried a public file that works on sample data, but doesn't work here.
   * Keeping this function for reference.
   */
  // static getAvatarImages(postPredictions: ReadonlyArray<IPostSubmitChartResponse>): Partial<Plotly.Image>[] {

  //   const images: Partial<Plotly.Image>[] = [];
  //   for (let i = 0; i < postPredictions.length; i++) {
  //     const image: Partial<Plotly.Image> = {
  //       source: postPredictions[i].avatarUrl, //"https://images.plot.ly/language-icons/api-home/js-logo.png", // public file
  //       xref: "x",
  //       yref: "y",
  //       x: (postPredictions[i].valueTime as number) * 1000,
  //       y: postPredictions[i].value as number,
  //       sizex: 1,
  //       sizey: 1,
  //       xanchor: "right",
  //       yanchor: "bottom"
  //     }
  //     images.push(image);
  //   }
  //   return images;
  // }

  /**
   * 
   * @returns Plotly.Layout
   */
  static getLayout(
    annotations: Partial<Plotly.Annotations>[], 
    // images?: Partial<Plotly.Image>[], 
    shapes: Partial<Plotly.Shape>[],
    quotes: Plotly.PlotData,
    postPredictions: Plotly.PlotData,
    ): Plotly.Layout {

    const minY = Math.min(...quotes.y as [], ...postPredictions.y as []);
    const maxY = Math.max(...quotes.y as [], ...postPredictions.y as []);

    const padding = maxY - minY;
    const minYRange = minY - (0.75 * padding);
    const maxYRange = maxY + (0.75 * padding);

    return {
      showlegend: false,
      dragmode: false,
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      yaxis: { 
        showgrid: false, 
        color: "#A6A6A6",
        gridcolor: "rgba(255, 255, 255, 0.2)",
        side: "right",
        range: [minYRange, maxYRange],
        fixedrange: true,
      },
      xaxis: {
        type: 'date',
        color: "#A6A6A6",
        showgrid: false,
        tickmode: "array", /*range: [xMin, xMax],*/
        dtick: 1,
        tickformat: '%I:%M %p',
        fixedrange: true,
        // tickvals,
        // ticktext
        },
      annotations,
      shapes,
      // images: testImages,
      hovermode: 'closest',
      margin: { l: 0, t: 30, b: 50},

    } as Plotly.Layout
  }


  /**
   * 
   * @param postPredictions 
   * @param prediction 
   * @param quoteAtPrediction 
   * @returns {
    agree: IPublicProfile[];
    disagree: IPublicProfile[];
    }
   */
  static getConsensus(postPredictions: ReadonlyArray<IPostSubmitChartResponse>, prediction: IPrediction, quoteAtPrediction: IQuote) {
    const userConsensus: {
      agree: IPublicProfile[],
      disagree: IPublicProfile[]
    } = {
      agree: [],
      disagree: []
    }

    const mydirection = quoteAtPrediction.c < (prediction.value as number) ? "up" : "down";

    for (let i=0; i < postPredictions.filter(p=>p.id !== prediction.id).length; i++) {
      const profile: IPublicProfile = {
        username: postPredictions[i].username,
        avatarUrl: postPredictions[i].avatarUrl,
        userId: postPredictions[i].userId,
      } as IPublicProfile
      if (this.predictionDirection(postPredictions[i]) === mydirection) {
        userConsensus.agree.push(profile)
      } else {
        userConsensus.disagree.push(profile);
      }
    }
    return userConsensus;
  }

  static initializeHandler (figure: Figure, chartElement: any, arrowRefs: React.MutableRefObject<(HTMLDivElement | null)[]> ) {

    // Find all data points
    if (chartElement) {
      const scatterLayer = chartElement.getElementsByClassName('scatterlayer')[0];
      const dataPoints = scatterLayer?.getElementsByTagName('path');
      const calcData = chartElement.calcdata[1]; // custom data here

      // Get data coordinates
      const xData = chartElement.data[1].x;
      const yData = chartElement.data[1].y;
      const xScale = chartElement._fullLayout.xaxis;
      const yScale = chartElement._fullLayout.yaxis;

      // Attach overlay div to each data point
      if (dataPoints) {
        for (let i = 0; i < dataPoints.length; i++) {
          const dataPointPath = dataPoints[i] as SVGPathElement
          const pointCoordinates = dataPointPath?.getBoundingClientRect();

          if (pointCoordinates) {
            
            const xPixel = xScale.d2p(xData[i]);
            const yPixel = yScale.d2p(yData[i]);

            const arrowRef = arrowRefs.current[i];
            if (arrowRef) {
              //position it directly
              arrowRef.style.position = 'absolute';

              const customData = calcData[i].data;

              if (customData.value < customData.valueStock) {
                arrowRef.style.top = `${yPixel+35}px`;
              } else {
                arrowRef.style.top = `${yPixel-75}px`;
              }
              arrowRef.style.left = `${xPixel}px`;
            } else {
              //console.error('no arrow ref')
            }
          } else {
            console.error('no point coordinates')
          }
        }
      } else {
        console.error('no datapoints')
      }
    }
  }

  static getBars (quotes: Readonly<IQuote[]>, prediction: IPrediction): IQuote[] {
    const q = quotes.find(i=>i.t === prediction.valueTime);

    if (q) {
      return [
        ...quotes
      ];
    }

    // q not found; add it 
    return ([
      ...quotes,
      {
        c: prediction.valueStock,
        t: prediction.valueTime  
      } as IQuote
      ]
    );
   
  }

}