import React, { useContext, useState } from "react";
import { ListContainer } from "components/common";
import { IContest } from "interfaces/IContest";
import { AdminContestForm } from "./Form";
import { adminContestApiService } from "services/AdminApiService";
import { FormButtons, Url } from "_constants";
import { IDataField } from "interfaces";
import { AdminModalInvite } from "./ModalInvite";
import { MessengerContext, Severity } from "components/common/messenger";
import { contestStatusNames } from "_constants/ContestStatusEnum";
import { contestTypeNames } from "_constants/ContestTypeNames";
import { ContestTypeEnum } from "predictagram-lib"
import { AdminModalLogoUpload } from "./ModalLogoUpload";
import { useNavigate } from "react-router-dom";
import { predictionTypeNames } from "_constants/PredictionTypeEnum";
import * as XLSX from 'xlsx';
import { IAdminContestApiItem, adminApiReportService } from "services/AdminApiReportService";


export const AdminContestList: React.FunctionComponent = () => {
  const emptyValues: IContest = {
    name: '',
    description: '',
    typeId: ContestTypeEnum.PUBLIC,
    startTime: (new Date()).getTime() / 1000,
    endTime: (new Date()).getTime() / 1000,
    resultTime: (new Date()).getTime() / 1000,
    stockSymbols: [''],
    predictionTypes: [''],
  } as IContest

  const [showInvite, setShowInvite] = useState<boolean>(false);
  const [contest, setContest] = useState<IContest | undefined>(undefined);
  const [showLogoForm, setShowLogoForm] = useState<boolean>(false);

  const navigate = useNavigate();

  const msgrContext = useContext(MessengerContext);
  const editableFields: IDataField[] = [
    { name: 'id', title: 'ID', editable: false },
    { name: 'name', title: 'Name', editable: true },
    { name: 'description', title: 'Description', editable: true },
    { name: 'prize', title: 'Prize', editable: true },
    { name: 'typeId', title: 'type ID', editable: true },
    { name: 'statusId', title: 'Status ID', editable: false },
    { name: 'startTime', title: 'Start Time', editable: true },
    { name: 'endTime', title: 'End Time', editable: true },
    { name: 'resultTime', title: 'Result Time', editable: true },
    { name: 'minPredictions', title: 'Min Predictions', editable: true},
    { name: 'stockSymbols', title: 'Symbols Predictions', editable: true},
    { name: 'predictionTypes', title: 'Prediction Types', editable: true},
  ];

  const getStatusName = (row: IContest) => <>{contestStatusNames.get(row.statusId)}</>
  const getTypeName = (row: IContest) => <>{contestTypeNames.get(row.typeId)}</>
  // const getStartTime = (row: IContest) => <>{(new Date(row.startTime * 1000)).toLocaleString()}</>
  // const getEndTime = (row: IContest) => <>{(new Date(row.endTime * 1000)).toLocaleString()}</>
  // const getResultTime = (row: IContest) => <>{(new Date(row.resultTime * 1000)).toLocaleString()}</>
  const getPredictionTypes = (row: IContest) => <>{row.predictionTypes?.map((p: any) => predictionTypeNames.get(p)).join(',')}</>

  const getTimes = (row: IContest) => {
    return <>
    <div className="mb-2"><span className="fw-bold">Start Time:</span> {(new Date(row.startTime * 1000)).toLocaleString()}</div>
    <div className="mb-2"><span className="fw-bold">End Time:</span> {(new Date(row.endTime * 1000)).toLocaleString()}</div>
    <div className="mb-2"><span className="fw-bold">Result Time:</span> {(new Date(row.resultTime * 1000)).toLocaleString()}</div>
    </>
  }

  const sendInvites = (row: IContest) => {
    return <button className="btn btn-secondary" type="button" onClick={() => { setContest(row); setShowInvite(true) }}>
      Invite Users
    </button>
  }

  const uploadLogo = (row: IContest) => {
    return <button className="btn btn-secondary" type="button" onClick={() => { setContest(row); setShowLogoForm(true) }}>
      Upload Logo
    </button>
  }

  const getUrl = (row: IContest) => {
    const url = Url.USER_CONTEST.replaceAll(':contestId', row.id.toString());
    const path = `${window.location.protocol}//${window.location.hostname}${url}`
    return <div role="link" style={{inlineSize: "200px", overflow: "auto"}} onClick={()=>{navigate(url)}}>{path}</div>
  }

  const getSymbols = (row: IContest) => {
    return row.stockSymbols?.join(', ')
  }

  const [ downloading, setDownloading ] = useState<boolean>(false);

  const downloadToExcel = (contestId: number) => {

    const mapper = (item: IAdminContestApiItem) => {
      return {
        ...item
      }
    }

    const _load = async () => {
      setDownloading(true);
      try {
        const contestants = await adminApiReportService.getContestants(contestId);
        const jsonData =  contestants.map(mapper);
        const worksheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'contestants');
        XLSX.writeFile(workbook, `contestants-${contestId}.xlsx`);
      } catch (error) {
        alert('Unexpected error.');
        console.error({error});
      } finally {
        setDownloading(false);
      }
    }

    _load();

  }

  const downloadContestants = (row: IContest) => {
    return <button className="btn btn-secondary" type="button" disabled={downloading} onClick={() => {downloadToExcel(row.id)}}>
      Download Contestants
    </button>
  }


  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['link', ['URL', getUrl]],
    ['name', ['Name', undefined]],
    ['description', ['Description', undefined]],
    ['prize', ['Prize Amount', undefined]],
    ['stockSymbols', ['Symbols', getSymbols]],
    ['predictionTypes', ['Prediction Types', getPredictionTypes]],
    ['typeId', ['Type', getTypeName]],
    ['statusId', ['Status', getStatusName]],
    ['times', ['Times', getTimes]],
    // ['startTime', ['Start Time', getStartTime]],
    // ['endTime', ['End Time', getEndTime]],
    // ['resultTime', ['Result Time', getResultTime]],
    ['invite', ['Send Invites', sendInvites]],
    ['download',['Download Contestants', downloadContestants]],
    ['logo', ['Upload Logo', uploadLogo]],
  ]);

  const onSave = async (emails: string) => {
    const s = emails.replace(/\n/g, ',');
    const payload = s.split(',').map((s) => { return { email: s.trim() } })

    try {
      if (!contest) {
        throw Error('missing contest id');
      }
      await adminContestApiService.addUsers(contest.id, payload)
      msgrContext.setMessage({ body: 'Invitations Sent' }, true, Severity.NORMAL);
    } catch (error: any) {
      console.error(error);
      msgrContext.setMessage({ body: `${error.message}` }, true, Severity.FATAL);
    }
    setShowInvite(false);
  }

  const onSaveLogo = async (url: string) => {
    const payload: IContest = {
      logo: url
    } as IContest;

    try {
      if (!contest) {
        throw Error('Unexpected error. Missing Contest');
      }
      adminContestApiService.update(payload, contest.id);
      setShowLogoForm(false)
    } catch (error: any) {
      console.error(error);
      msgrContext.setMessage({ body: `${error.message}` }, true, Severity.FATAL);
    }
  }

  return (
    <>
      <AdminModalLogoUpload handleClose={()=>setShowLogoForm(false)} show={showLogoForm} onSave={onSaveLogo} />
      <AdminModalInvite handleClose={() => setShowInvite(false)} show={showInvite} onSave={onSave} />
      <ListContainer
        title="Contests"
        apiService={adminContestApiService}
        emptyValues={emptyValues}
        fieldList={fieldList}
        buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
        editableFields={editableFields}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }) => {
          return (
            <AdminContestForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick} editableFields={editableFields} />
          );
        }}
      </ListContainer>
    </>
  );
};


