import { StorageKey } from "_constants";
import { SearchParams } from "_constants/SearchParams";
import { useSearchParams } from "react-router-dom";

export interface IReferralParams {
  userId?: number,
  predictionId?: number,
  teamId?: number,
  cuid?: number,
  securityId?: number,
}

/**
 * sets the referral parameters; returns as params for api
 * @returns string 
 */
export const useReferral = (teamId?: number): IReferralParams | undefined => {
  const [ searchParams ] = useSearchParams();
  const userId = searchParams.get(SearchParams.UI_REFERRAL_USER_ID) || undefined;
  const predictionId = searchParams.get(SearchParams.UI_REFERRAL_PREDICTION_ID) || undefined;
  const qTeamId = searchParams.get(SearchParams.UI_REFERRAL_TEAM_ID) || undefined;
  const cuid = searchParams.get(SearchParams.UI_REFERRAL_CONTEST_ID) || undefined;
  const securityId = searchParams.get(SearchParams.UI_REFERRAL_SECURITY_ID) || undefined;

  const referral: IReferralParams = {
    userId: userId ? Number(userId) : undefined,
    predictionId: predictionId ? Number(predictionId) : undefined,
    teamId: (qTeamId ? Number(qTeamId) : undefined) || teamId || undefined,
    cuid: cuid ? Number(cuid) : undefined,
    securityId: securityId ? Number(securityId) : undefined,
  }

  // rules
  // most recent referral gets logged.

  if (userId || predictionId || teamId || securityId) {
    localStorage.setItem(StorageKey.REFERRAL, JSON.stringify(referral));
  }

  const r = localStorage.getItem(StorageKey.REFERRAL);

  if (r) {
    const parsed = JSON.parse(r) as IReferralParams;
    return parsed;
  } 

  return undefined;
}