import {IParsedOption, OptionPattern} from "./OptionPattern";
import {OptionType} from "predictagram-lib";
import {TradegramDateHelper} from "./TradegramDateHelper";

export class OptionPatternThinkSwim extends OptionPattern {

  protected pattern = /^\.?([A-Za-z]+)(\d{6})(c|p)(\d+(?:\.\d{1,3})?)?$/i; // .NVDA231110C480
  
  parse(optionName: string) {
    const match = optionName.match(this.pattern);
    if (!match) {
      throw new Error('Invalid option contract name format');
    }

    const [, symbol, date, optionType, price] = match;
    const parsedOption: IParsedOption = {
      symbol: symbol.toUpperCase(),
      date: TradegramDateHelper.convertYYMMDDToDate(date).getTime() / 1000,
      optionType: optionType.toLocaleUpperCase().slice(0,1) === 'C' ? OptionType.CALL : OptionType.PUT,
      price: parseFloat(price),
    }
    return parsedOption;

  }
}
