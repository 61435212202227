import React, { useState } from 'react';
import { usePublicProfile } from "_hooks/usePublicProfile";
import { Helper } from "_utils";
import { AvatarSizeEnum } from "components/common/Avatar";
import { Spinner } from "react-bootstrap";
import { ITradegramTransaction, ITradegramSecurity } from "services/TradegramApiService";
import { userPredictionApiService } from "services/UserPredictionApiService";
import { AvatarLink, UserLink } from "./UserInfo";
import * as Icons from 'components/common/Icons';
import { TradegramHelper } from '_utils/TradegramHelper';

export const UserInfoProfit: React.FunctionComponent<{ trade: ITradegramTransaction, security: ITradegramSecurity }> = ({ trade, security }) => {

  const profile = usePublicProfile(security.userId, userPredictionApiService, true);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  if (!trade) return <></>

  if (!profile.loaded) {
    return <Spinner />
  }

  const Rating = () => {

    const profileProfitPct = TradegramHelper.getSecurityProfitabilityPct(security, profile.item);

    return (
      <div className="text-12 d-flex justify-content-start gap-1 pill bg-charcoal px-2">{(profileProfitPct || 0) >= 0 ?
        <><Icons.GraphUpArrow /><span className="fw-bold text-bright-green">+{((profileProfitPct || 0)).toFixed(2)}%</span></>
        :
        <><Icons.GraphDnArrow /><span className="fw-bold text-bright-red">{((profileProfitPct || 0)).toFixed(2)}%</span></>
      }
      </div>
    );
  }

  return (
    <div className="profile d-flex justify-content-between align-items-center gap-2">
      <div className="d-flex justify-content-start align-items-center gap-2">
        <div className="profile-image">
          <AvatarLink profile={profile.item} size={AvatarSizeEnum.SMALL_MEDIUM} />
        </div>
        <div className="text-12 fw-bold"><UserLink id={security.userId} username={profile.item.username} /></div>
        <div role="button" onMouseEnter={()=>setShowTooltip(true)} onMouseLeave={()=>setShowTooltip(false)} onClick={() => setShowTooltip(!showTooltip)} style={{ position: 'relative', cursor: 'pointer' }}>
          <Rating />
          {showTooltip &&
            <div style={{
              position: 'absolute',
              top: "-1.5625rem",
              left: 5,
            }} className="bg-charcoal text-white text-10 text-nowrap p-1 rounded">
              {`Average Profit Per ${TradegramHelper.isOptionSecurity(security.typeId) ? 'Options' : 'Shares'} Traded`}
            </div>
          }
        </div>
      </div>
      <div className="text-12 fst-italic opacity-70">{Helper.timeElapsed(new Date(Number(trade.createdAt) * 1000), new Date())} ago</div>
    </div>
  );
}