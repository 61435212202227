import React from 'react'
import { DropdownFilter, IDropdownFilterItem } from 'components/common/DropDownFilter/DropdownFilter'

export type CancelReasonItemType = IDropdownFilterItem<CancelReasonFilterEnum, CancelReasonFilterEnum>;

export enum CancelReasonFilterEnum  {
  NO_REASON = 1,
  DONT_USE_ENOUGH = 2,
  TOO_EXPENSIVE = 3,
  PRODUCT_NOT_SATISFACTORY = 4
}

export const cancelReasonDropDownItemsMap: Map<CancelReasonFilterEnum, CancelReasonItemType> = new Map<CancelReasonFilterEnum, CancelReasonItemType>([
  [CancelReasonFilterEnum.NO_REASON, { key: CancelReasonFilterEnum.NO_REASON, value: CancelReasonFilterEnum.NO_REASON, label: "Select a reason" }],
  [CancelReasonFilterEnum.DONT_USE_ENOUGH, { key: CancelReasonFilterEnum.DONT_USE_ENOUGH, value: CancelReasonFilterEnum.DONT_USE_ENOUGH, label: "I Don't Use It Enough" }],
  [CancelReasonFilterEnum.TOO_EXPENSIVE, { key: CancelReasonFilterEnum.TOO_EXPENSIVE, value: CancelReasonFilterEnum.TOO_EXPENSIVE, label: "Too Expensive" }],
  [CancelReasonFilterEnum.PRODUCT_NOT_SATISFACTORY, { key: CancelReasonFilterEnum.PRODUCT_NOT_SATISFACTORY, value: CancelReasonFilterEnum.PRODUCT_NOT_SATISFACTORY, label: "The product quality is not satisfactory" }],
]
);

export const cancelReasonDropDownItems: CancelReasonItemType[] = Array.from(cancelReasonDropDownItemsMap).map((item) => {
  return item[1]
})

export const getUserTypeDropDownItem = (traderTypeFilter: CancelReasonFilterEnum) => {
  return cancelReasonDropDownItemsMap.get(traderTypeFilter) || cancelReasonDropDownItems[0];
}

interface IProps {
  initialValue: CancelReasonFilterEnum
  setValue: (value: CancelReasonFilterEnum) => void,
  className?: string,
}

export const CancelSubscriptionReasonDropDown:React.FunctionComponent<IProps> = ({initialValue, setValue, className}) => {
  const items = cancelReasonDropDownItems;
  const _setValue = (value: CancelReasonFilterEnum) => {
    setValue(value);
  }
  return (
    <DropdownFilter<CancelReasonFilterEnum, CancelReasonFilterEnum> items={items} initialSelectedKey={initialValue} onSelect={(item)=>_setValue(item)} className={className}/>
  )
}
