import { ErrorMessage, Field } from "formik";
import React from "react";

const ConfirmCheck: React.FunctionComponent<{ errors: any; touched: any; name: string; label?: any; readOnly?: boolean, className?: string, children?: any }> = ({
  errors,
  touched,
  name,
  label,
  readOnly = false,
  children,
  className = 'bg-black text-white'
}) => {
  return (
    <div>{label}
      <div role="group" className={`form-control d-flex align-items-center flex-row border-0 ${className} ${(errors && errors[name] && touched && touched[name] ? " is-invalid" : "")}`}>
        <Field disabled={readOnly} key={`confirm-${name}`} id={`confirm-${name}`} className="form-check-input mx-1 flex-shrink-0" name={name} type="checkbox" />
        <label className="form-class-label mx-2 " htmlFor={`confirm-${name}`}>
          {children}
        </label>
      </div>
      {errors && <ErrorMessage className="mt-2 invalid-feedback" name={name} component="div" />}
    </div>
  );
};

export { ConfirmCheck };
