import React from 'react'
import { useLocation } from 'react-router-dom'
import { ChartOverlay } from './ChartOverlay';
import { IAutoTrade } from 'predictagram-lib';

export interface IConsensusState {
  title: string,
  trades: IAutoTrade[],
  stockSymbolId: number,
  tradeSetupId: number,
  isConsensusChart?: boolean,
}

export const ConsensusOrdersGroupedPage: React.FunctionComponent = () => {

  const state: IConsensusState = useLocation().state;
  if (!state) {
    return <div>No Data Found.</div>
  }

  const {title, trades, stockSymbolId, tradeSetupId, isConsensusChart } = state;

  return <>
    <div className="page-title">{title}</div>

    <ChartOverlay isConsensusChart={isConsensusChart} tradeSetupId={tradeSetupId} trades={trades} symbolId={stockSymbolId} onEscape={() => { }} />
  </>
}
