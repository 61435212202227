import React from 'react'
import { RightChevron } from 'components/common/Icons'

interface IProps {
  width?: number,
  height?: number,
  hover?: boolean,
  className?: string,
  hoverFillClassName?: string,
  onClick?:()=>void,
  [propName: string]: any
}
export const RoundFowardButton:React.FunctionComponent<IProps> = ({width=14, height=14, className, hover=false, onClick, hoverFillClassName, ...rest}) => {

  const getFillClassname = () => {
    return hover ? hoverFillClassName : 'bg-white'
  }

  return (
    <div
      role="button"
      onClick={onClick}
      {...rest}
      className={`btn-circle ${getFillClassname()} d-flex justify-content-center align-items-center ${className}-container`}>
        <RightChevron color='black' className="d-inline" width={width} height={height}/></div>
  )
}

