import {PredictionService} from "../services";
import {IStockStat} from "../components/public/Stock/IStockStat";
import {DateEx, PredictionTypeEnum} from "predictagram-lib";
import {ChartModel as ChartOptions} from "./chart.model";

export class CumulativeChartModel {

  public constructor(protected service: PredictionService) {
  }

  public async preBuildStats(stockSymbol:string, start:DateEx, end:DateEx, predictionTypes?: PredictionTypeEnum[]) {
    const aggrStats = await this.service.getStatsCumulative(stockSymbol, start, end, predictionTypes);
    let chartYMin:number | null = null;
    let chartYMax:number | null = null;
    const dMaxX: number[] = [];
    const dMaxY: number[] = [];
    const dMinX: number[] = [];
    const dMinY: number[] = [];

    const dMaxX_60: number[] = [];
    const dMaxY_60: number[] = [];
    const dMinX_60: number[] = [];
    const dMinY_60: number[] = [];

    let prev:IStockStat = null as any;
    aggrStats.forEach((v:IStockStat)=>{
      dMaxX.push(v.time);
      dMaxY.push(v.max);

      const maxY_60 = v.max + ((v.max - v.min) * .60);
      dMaxX_60.push(v.time);
      dMaxY_60.push(maxY_60);

      dMinX.push(v.time);
      dMinY.push(v.min);

      const minY_60 = v.min - ((v.max - v.min) * .60);
      dMinX_60.push(v.time);
      dMinY_60.push(minY_60);

      if (!chartYMin || minY_60 < chartYMin) {
        chartYMin = minY_60;
      }
      if (!chartYMax || maxY_60 > chartYMax) {
        chartYMax = maxY_60;
      }

      prev = v;
    });
    return {
      dMaxX,
      dMaxY,
      dMinX,
      dMinY,
      dMaxX_60,
      dMaxY_60,
      dMinX_60,
      dMinY_60,
      chartYMin,
      chartYMax,
      maxLine: ChartOptions.plotData('max', {x:dMaxX, y:dMaxY}, ChartOptions.rectLineGreenColor, "dash"),
      minLine: ChartOptions.plotData('min', {x:dMinX, y:dMinY}, ChartOptions.rectLineRedColor, "dash"),
      max60Line: ChartOptions.plotData('max60', {x:dMaxX_60, y:dMaxY_60}, ChartOptions.rectLineGreenColor ),
      min60Line: ChartOptions.plotData('min60', {x:dMinX_60, y:dMinY_60}, ChartOptions.rectLineRedColor),
    }
  }

}
