export class SearchHelper {

    // https://stackoverflow.com/a/29018745/7833647
    /**
     * if returns negative - insert position, numeration from 1,
     * otherwise array current position
     * @param arr
     * @param comparator
     * @returns number
     */
    public static binarySearch<T>(arr:T[], comparator:(a:T)=>number) {
        let m = 0;
        let n = arr.length - 1;
        while (m <= n) {
            // tslint:disable-next-line:no-bitwise
            const k = (n + m) >> 1;
            // const openT = this.hours[k][0];
            // // const closeT = this.hours[k][1];
            // const cmp = timeSecs<openT+this.dayStartShiftOpenSecs ? -1 :
            //             (timeSecs>openT+this.dayEndShiftOpenSecs?1: 0);
            const cmp = comparator(arr[k]);
            if (cmp > 0) {
                m = k + 1;
            } else if(cmp < 0) {
                n = k - 1;
            } else {
                return k;
            }
        }
        // tslint:disable-next-line:no-bitwise
        return ~m;
    }
}
