import { StorageKey, Url } from '_constants';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from './AccountContext';

const TermsAndConditionsLink = () => {
  const navigate = useNavigate();

  return (
    <span role="button" className="text-decoration-underline" onClick={() => navigate(Url.USER_TERMS)}>Terms and Conditions</span>
  );
}

export const Disclaimer: React.FunctionComponent = () => {
  const hasBeenSeen = String(localStorage.getItem(StorageKey.DISCLAIMER)) === '1';

  const acctContext = useContext(AccountContext);
  const [ seen, setSeen ] = useState<boolean>(hasBeenSeen || (acctContext !== null && acctContext.isLoggedIn()));

  const onClick = () => {
    localStorage.setItem(StorageKey.DISCLAIMER, '1');
    setSeen(true);
  }

  if (seen) {
    return <></>
  }

  return (
    <div className="disclaimer">
      <div className="disclaimer-text">
      <span className="fw-bold">Disclaimer: </span>All Predictagram Content is to be used for informational and entertainment purposes only and the Predictagram Service does not provide investment advice for any individual. Trading in these securities can result in immediate and substantial losses of the capital invested. You should only invest risk capital, and not capital required for other purposes. See full <TermsAndConditionsLink />.
      </div>
      <div className="disclaimer-button mt-3" role="button" onClick={()=>onClick()}>
        <span className="disclaimer-button-text">👍 Got it!</span>
      </div>
    </div>
  );
}