import React from 'react';
import { useNavigate } from 'react-router-dom';

export const AbsoluteLink: React.FunctionComponent<{ to: string, className?: string, children: any }> = ({ to, className, children }) => {
  const navigate = useNavigate();
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    navigate(to);
    e.stopPropagation();
  }
  return (
    <span role="button" className={className} onClick={onClick}>{children}</span>
  )
}

