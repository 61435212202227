import { Helper } from '_utils';
import { UrlHelper } from '_utils/UrlHelper';
import { NewYorkTz } from 'predictagram-lib';
import React from 'react'
import { Link } from 'react-router-dom';
import { IUserSignalAlert, UserSignalAlertDirectionType } from 'services/UserSignalApiService';




const DirectionPill: React.FunctionComponent<{ direction: "up" | "down", time: string }> = ({ direction, time }) => {
  return (
    <div className={`d-flex justify-content-start align-items-center px-2 pe-3 gap-1 `}>
      <div className={`d-flex gap-2 align-items-center p-2`}>
        <div className={`arrow-${direction}`}></div>
        <div className="text-14 fw-bold">{direction.toUpperCase()}</div>
      </div>
    </div>
  );
}

const StatCell: React.FunctionComponent<{ title: string, value: string | JSX.Element }> = ({ title, value }) => {
  return (
    <div className="d-flex flex-column align-items-start">
      <div className="text-14 fw-bold">{value}</div>
      <div className="text-10 opacity-70">{title}</div>
    </div>
  );
}

export const Signal: React.FunctionComponent<{ signal: IUserSignalAlert }> = ({ signal }) => {


  const {
    symbolName,
    directionType,
    stockTime,
    winRateLat30,
    profitRateLast30
  } = signal;

  const direction = directionType === UserSignalAlertDirectionType.UP ? "up" : "down";
  const nytime = NewYorkTz.format(new Date(stockTime * 1000)).hour24MinuteAmpm();
  const winRate = Helper.formatPercent(winRateLat30 * 1000, 0);
  const avgProfit = Helper.formatPercent(profitRateLast30 * 1000, 0);

  return (
    <div className={`signal-stat p-2 d-flex flex-column`}>
      <div className="text-11 opacity-70">{nytime}</div>
      <div className="d-flex justify-content-between gap-2 align-items-center">
        <div className="text-14 fw-bold"><Link className="text-white" to={UrlHelper.getSymbolUrl(symbolName)}>{symbolName}</Link></div>
        <DirectionPill direction={direction as any} time={nytime} />
        <StatCell title="win rate" value={`${winRate}%`} />
        <StatCell title="avg profit" value={`${avgProfit}%`} />
      </div>
    </div>
  )
}
