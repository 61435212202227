import React, { useState } from 'react';
import { APIState } from '_constants/APIState';
import { usePublicPrediction } from '_hooks/usePublicPrediction';
import { Spinner } from 'components/common/Spinner';
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect';
import { PredictionListLazy } from 'components/public/ListingsPage/components/PredictionListLazy';
import { useOutletContext } from 'react-router-dom';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { IContest } from 'interfaces/IContest';
import { PredictionTypeEnum } from 'predictagram-lib';

export const ContestPredictions: React.FunctionComponent = () => {

  const { contest } = useOutletContext<{
    contest: IContest,
  }>();

  const [, setPredTypes] = useState<PredictionTypeEnum[]>([]);

  const { items: predictions, apiState, setSort, options } = usePublicPrediction(userPredictionApiService, {
    contestId: Number(contest.id),
    symbolNames: contest.stockSymbols,
    orderBy: sortTypeEnum.BEST
  } as ISearchOptions);


  return (
    <div className="team-predictions">

      {apiState === APIState.LOADING &&
        <Spinner />
      }

      {apiState === APIState.LOADED && predictions.length > 0 &&
        <div className="my-3"><PredictionListLazy hasPredictionTypeTabs={false} predictions={predictions} onClickFilterTab={setPredTypes} onSelectSort={setSort} orderBy={options?.orderBy || sortTypeEnum.BEST} /></div>
      }

      {apiState === APIState.LOADED && predictions.length === 0 &&
        <div className="d-flex justify-content-center align-items-center flex-row my-3 contest-mesage">No Predictions Found.</div>
      }

    </div>
  )
}