import {DateEx, StockHelper} from 'predictagram-lib';

export class ConsensusHelper {

  static getConsensusDate = (dt: DateEx) => {
    if (StockHelper.isPostMarketHours(dt) || StockHelper.isMarketOpen(dt)) {
      return dt;
    }
    return StockHelper.workingHours(StockHelper.findPreviousTradingDay(dt)).end;
  }
}

