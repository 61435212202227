import React from 'react'
import { SelectDropDown } from 'components/common/form/SelectDropDown';

interface IProps {
  value: PresetDateRange | undefined,
  setValue: (value: PresetDateRange) => void,
  className?: string,
}

export enum PresetDateRange {
  TODAY = 1,
  YESTERDAY = 2,
  THIS_WEEK = 3,
  LAST_WEEK = 4,
  THIS_MONTH = 5,
  LAST_30 = 6,
}

export const presetDateRangeMap = new Map<PresetDateRange, string>([
  [PresetDateRange.TODAY, 'Today'],
  [PresetDateRange.YESTERDAY, 'Yesterday'],
  [PresetDateRange.THIS_WEEK, 'This Week'],
  [PresetDateRange.LAST_WEEK, 'Last Week'],
  [PresetDateRange.THIS_MONTH, 'This Month'],
  [PresetDateRange.LAST_30, 'Last 30 Days']
])

export const PresetDateRangeFilter:React.FunctionComponent<IProps> = ({value, setValue, className}) => {

  return (
    <SelectDropDown<PresetDateRange, string> 
      onSelect={setValue}
      options={presetDateRangeMap}
      selected={value}
      title="Date Preset"
      className={className}
    />
  )
}



