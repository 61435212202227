import { sortTypeEnum } from "components/public/ListingsPage/components/Prediction/SortTypeSelect";
import { IPrediction } from "interfaces/IPrediction";
import { useCallback, useEffect, useState } from "react";
import { ISearchOptions, UserPredictionApiService, UserPredictionServiceError } from "services/UserPredictionApiService";
import { APIState } from "_constants/APIState";

export function usePublicPrediction(service: UserPredictionApiService, search?: ISearchOptions) {

  const [items, setItems] = useState<IPrediction[]>([]);
  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [error, setError] = useState<UserPredictionServiceError | undefined>(undefined);

  const [options, setOptions] = useState<ISearchOptions | undefined>(search || {orderBy: sortTypeEnum.LATEST} as ISearchOptions);

  const setSort = useCallback((sortType: sortTypeEnum) => {
    if (!options) {
      setOptions({
        orderBy: sortType
      })
      return;
    }
    const {orderBy, ...rest} = options;
    setOptions({
      orderBy: sortType,
      ...rest
    })
  }, [options]);


  const load = useCallback(async () => {
    try {
      setApiState(APIState.LOADING);
      const items = await service.getAllPublic(options);
      setItems(items);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as UserPredictionServiceError);
    }
  }, [service, options])

  const refresh = () => {
    load();
  }

  useEffect(() => {
    load();
  }, [load, options])  
  
  
  return { items, apiState, error, options, setOptions, setSort, refresh, status: {
    loading: apiState === APIState.LOADING,
    loaded:  apiState === APIState.LOADED,
    idle: apiState === APIState.IDLE,
    error: apiState === APIState.ERROR
  } };

}