import { useCallback, useState } from "react";
import { IApiResponse, useApi } from "./useApi";
import { IIbkrTradePosition, adminApiServiceCommon } from "services/AdminApiService";


export interface IAdminIbkrPositionsApi {
  api: IApiResponse<IIbkrTradePosition[], null>,
  getById: (contractId: number) => IIbkrTradePosition | undefined,
}

export function useAdminAutoTradeIbkrPositions () {

  const [data, setData] = useState<IIbkrTradePosition[]>([]);

  const load = async () => {
    const data = await adminApiServiceCommon.getIbkrPositions();
    setData(data);
    return data;
  }

  const getById = useCallback((contractId: number) => {
    return data.find(v=>v.contractId===contractId);
  }, [data])

  return {
    api: useApi<IIbkrTradePosition[], null>(load, null),
    getById
  } as IAdminIbkrPositionsApi
}
