import { AccountContext, ConfirmCheck, FieldWithError } from 'components/common';
import { ShowHidePassword } from 'components/common/form/ShowHidePassword';
import { MessengerContext } from 'components/common/messenger';
import { Form, Formik } from 'formik';
import { IAccount } from 'interfaces';
import React, { useContext } from 'react';
import { validationMap, Validator } from 'schemas';


export const DeleteAccount: React.FunctionComponent<{ acct: IAccount, onDeleteCallback: ()=>void }> = ({ acct, onDeleteCallback }) => {
  const initialValues = {
    email: acct.email || '',
    passwordfordelete: '',
    confirmDelete: false,
  }

  const msgrContext = useContext(MessengerContext);
  const acctContext = useContext(AccountContext);

  const onSubmit = async (values: any, actions: any) => {
    try {
      await acctContext?.delete(values.passwordfordelete, onDeleteCallback);
    } catch (error: any) {
      msgrContext.setMessage({body: `Error deleting account: ${error.message}`});
    }
  }

  return (
    <div className="mx-3 my-3">
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationMap.get(Validator.DELETE_ACCOUNT_FORM)}>
        {({ errors, touched, isSubmitting }) => {
          return <Form>
            <div className="my-3">Delete My Data</div>
            <p>
              IMPORTANT: Deleting your account is permanent. All your account information
              will be removed from our database. They cannot be recovered once you submit.
            </p>

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="email"
              label="Email Address"
              placeholder="Enter E-Mail Address"
            />

            <ShowHidePassword
              errors={errors}
              touched={touched}
              fieldName="passwordfordelete"
              placeholder="Enter Password"
              label="Password"
              readOnly={false}
            />

            <ConfirmCheck name="confirmDelete" errors={errors} touched={touched}>
              <div className="agree-terms ">Yes, I understand that clicking the Delete Account button below
                will remove my account information permanently.</div>
            </ConfirmCheck>


            <button type="submit" disabled={isSubmitting} className="mt-3 btn bg-red text-white">DELETE MY ACCOUNT</button>
          </Form>
        }}
      </Formik>

    </div>
  );
}