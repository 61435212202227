import React from 'react'
import { Fire } from 'components/common/Icons';
import { GiftFill } from "react-bootstrap-icons";

export const HelpLeveling: React.FunctionComponent<{ hasTitle?: boolean }> = ({ hasTitle = true }) => {
  return (
    <div className="league-help text-center my-3 mx-4 d-flex flex-column justify-content-center gap-2">
      {hasTitle &&
        <div className="fw-bold text-14">Levels and Leagues</div>
      }

      <div className="d-flex flex-column gap-1 my-3 align-items-center">
        <div className="league-help-image">
          <img src="/images/level-help.png" alt='Help' />
        </div>
        <div className="text-12">Make predictions to level up and progress through each league.</div>
      </div>

      <div className="d-flex flex-column gap-1 my-3 align-items-center">
        <div>
          <Fire fillColor="#ffffff" />
        </div>
        <div className="text-12">Maintain a streak to level up faster!</div>
      </div>

      <div className="d-flex flex-column gap-1 my-3 align-items-center">
        <div>
          <GiftFill />
        </div>
        <div className="text-12">Receive a special reward at the end of the season based on the league you finish in!</div>
      </div>
    </div>
  )
}
