import { useEffect } from "react";

export function useIntersectionObserver(
  ref: React.MutableRefObject<HTMLDivElement | null>, 
  onIntersect: ()=>void,
  options?: IntersectionObserverInit,
  ) {

  useEffect(()=>{
    if (ref.current) {
      const container = ref.current;
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            onIntersect();
          }
        },
        { threshold: 1}
      )
      observer.observe(container);
      return () => {
        observer.disconnect();
      }
    }
  }, [onIntersect, ref])

}

