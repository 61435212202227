import React, { useContext, useState } from 'react';
import { Modal } from "react-bootstrap";
import { RegistrationForm } from './FullRegistration';
import { ModalPhoneConfirmationWizard } from './ModalPhoneConfirmationWizard';
import { MessengerContext } from 'components/common/messenger';
import { useLocation } from 'react-router-dom';
import { Url } from '_constants';

const ModalRegister: React.FunctionComponent<{ show: any; handleClose: any; onRegister?: any, onClickLogin?: any }> = ({
  show,
  handleClose,
  onRegister,
  onClickLogin
}) => {

  const [showPhone, setShowPhone] = useState<boolean>(false);
  const msgrContext = useContext(MessengerContext);
  const location = useLocation();

  const _onRegister = () => {
    onRegister();
    if (![Url.LEADERBOARD_TEAMS, Url.LEADERBOARD_ROOT, Url.LEADERBOARD_TEAMS].includes(location.pathname as Url)) { // @TODO: clean this up.
      setShowPhone(true);
    }
  }

  return (
    <>
    <ModalPhoneConfirmationWizard show={showPhone} handleClose={()=>{setShowPhone(false)}} onSuccess={()=>{msgrContext.setMessage({body: 'Thank you for confirming your phone number!'})}} />
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <div className="row justify-content-center mx-2">

        <div className="row justify-content-center login-form mx-3 mb-2">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="page-title">Register</div>
            <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
          </div>
        </div>

          <RegistrationForm onSuccess={_onRegister} />

          <div className="mx-3 my-3">
            <div className="d-flex flex-row gap-1 mx-2">
              <div>Already have an account? </div>
              <div role="button" className="text-decoration-underline" onClick={onClickLogin}>Log in</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
};

export { ModalRegister }