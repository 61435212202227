import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const RedirectComponent: React.FunctionComponent<{ to: string }> = ({ to }) => {
  
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to, { replace: true });
  }, [navigate, to]);

  return <></>; 
};

