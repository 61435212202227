import { Helper } from "_utils";
import { AccountContext } from "components/common";
import { IUserStatistic } from "interfaces/IUserStatistic";
import { useContext } from "react";

export const Ranking: React.FunctionComponent<{ stats: IUserStatistic[] }> = ({ stats }) => {
  const acctContext = useContext(AccountContext);
  if (!acctContext?.account.id) {
    return <>no account</>
  }

  const rank = stats.filter(obj => obj.predictionsCompleted > 0).findIndex(obj => obj.userId === acctContext.account.id);

  return <>

    {rank >= 0 && 
      <div className="contest-user-rank my-3">
        <span className="ms-3">You're in {`${rank + 1}${Helper.numberSuffix(rank + 1)}`} place of {stats.length} predictors.</span>
      </div>
    }
  </>;
}