import { MessengerContext } from 'components/common/messenger';
import { StreakLevels } from 'predictagram-lib';
import React, { useState, useContext } from 'react'
import { IUserAchievementUserStatResponse } from 'services/UserAchievementService';

export enum LeagueTabEnum {
  BRONZE = 1,
  SILVER = 2,
  GOLD = 3,
  PLATINUM = 4,
  MASTER = 5
}

export const leagueTabNames: Map<LeagueTabEnum, { label: string, value: [number, number] }> = new Map<LeagueTabEnum, { label: string, value: [number, number] }>([
  [LeagueTabEnum.BRONZE, { label: 'Bronze', value: [0, 3_099] }],
  [LeagueTabEnum.SILVER, { label: 'Silver', value: [3_100, 33_899] }],
  [LeagueTabEnum.GOLD, { label: 'Gold', value: [33_900, 196_839] }],
  [LeagueTabEnum.PLATINUM, { label: 'Diamond', value: [196_840, 585_611] }],
  [LeagueTabEnum.MASTER, { label: 'Master', value: [585_612, 999_999_999] }],
]
);

interface IProps {
  leagueTab: LeagueTabEnum,
  setLeagueTab: (tab: LeagueTabEnum) => void,
  userStat: IUserAchievementUserStatResponse,
  className?: string,
  [name: string]: any,
}

export const LeagueTabs: React.FunctionComponent<IProps> = ({ leagueTab, setLeagueTab, userStat, className, ...rest }) => {
  const [currentTab, setCurrentTab] = useState<LeagueTabEnum>(leagueTab);
  const msgrContext = useContext(MessengerContext);
  const onClick = (t: LeagueTabEnum) => {
    setLeagueTab(t);
    setCurrentTab(t);
  }

  const userLevel = StreakLevels.levelsInfo(userStat ? userStat.points : 0 ).current;

  const getNextLevel = (lvl: number): {value: number, name: string} => {
    const nextlevel = lvl * 5 - 4;
    
    return {
      value: nextlevel,
      name: leagueTabNames.get(lvl as LeagueTabEnum)?.label || ''
    }
  }

  const getNextLevelMessage = (rank: number) => {
    const nextLevel = getNextLevel(rank);
    return `Reach Lvl ${nextLevel.value} to unlock ${nextLevel.name} League`
  }

  return (
    <div className="league-tabs mb-3 gap-2" {...rest}>
      {Array.from(leagueTabNames).map(([key, value], i) =>
        <div
          title={`${userLevel.rank < key ? getNextLevelMessage(key) : ''}`}
          onClick={() => {
            if(userLevel.rank === key) {
              onClick(key)
            } else {
              if (userLevel.rank < key) {
                msgrContext.setMessage({body: getNextLevelMessage(key)})
              } else {
                // do nothing
              }
            }
          }}
          role={userLevel.rank >= key ? undefined : "button"}
          key={`tab-key-${i}`}
          className={`${className || ""} league-tabs-item ${currentTab === key ? 'active' : ''} ${value.label.toLowerCase()}`}>

          {currentTab === key ? 
          <div className={`league-rank-image rank-${key}`}></div>
          :
          <div className={`league-rank-image rank-${key}`}></div>
          }
        </div>
      )}
    </div>
  )
}
