import { APIState } from "_constants/APIState";
import { IUserStatistic } from "interfaces/IUserStatistic";
import { useCallback, useEffect, useRef, useState } from "react";
import { UserPredictionApiService, UserPredictionServiceError } from "services/UserPredictionApiService";

export interface IUserStatSearchOptions  {
  startTime: number, // in seconds
  endTime?: number, // in seconds
  minScore?: number,
  minPredictions?: number,
}

export interface IUserStatistics {
  items: IUserStatistic[];
  apiState: APIState;
  error: UserPredictionServiceError | undefined;
  options: IUserStatSearchOptions;
  setOptions: React.Dispatch<React.SetStateAction<IUserStatSearchOptions>>;
  getById: (userId: number) => IUserStatistic | undefined;
}

export function useUserStats (service: UserPredictionApiService, searchOptions: IUserStatSearchOptions) {

  const [ items, setItems ] = useState<IUserStatistic[]>([]);
  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<UserPredictionServiceError | undefined>(undefined);

  const serviceRef = useRef(service);

  const [ options, setOptions ] = useState<IUserStatSearchOptions>(searchOptions);

  const load = useCallback(async ()=>{
    try {
      setApiState(APIState.LOADING);
      const items = await serviceRef.current.getUserStats(options);
      setItems(items);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR)
      setError(error as UserPredictionServiceError);
    }
  }, [serviceRef, options])

  const getById = (userId: number) => {
    return items.find( item => item.userId === userId);
  }

  useEffect(()=>{
   load();
  }, [load])

  return {items, apiState, error, options, setOptions, getById};

}