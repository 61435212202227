import {IParsedOption, OptionPattern} from "./OptionPattern";
import {OptionType} from "predictagram-lib";
import {TradegramDateHelper} from "./TradegramDateHelper";

export class OptionPatternSymbolDate extends OptionPattern {

  protected pattern = /([A-Za-z]+)\s((?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)-\d{2}-\d{2}) \$?(\d+(?:\.\d{1,3})?) (puts|calls|p|c|put|call)$/i;  // TNA Nov-10-23 $24.5 Puts

  parse(optionName: string) {
    const match = optionName.match(this.pattern);
    if (!match) {
      throw new Error('Invalid option contract name format');
    }
    const [, symbol, date, price, optionType] = match;
    const parsedOption: IParsedOption = {
      symbol: symbol.toUpperCase(),
      date: TradegramDateHelper.convertMMMDDYYToDateUs(date.replaceAll('-', ' ')).getTime() / 1000,
      optionType: optionType.toLocaleUpperCase().slice(0,1) === 'C' ? OptionType.CALL : OptionType.PUT,
      price: parseFloat(price)
    }
    return parsedOption;

  }
}
