import { useLastQuote } from "_hooks/useLastQuote";
import { QuoteAsOf } from "components/public/Stock/QuoteAsOf";
import { useEffect, useRef } from "react";
import { predictionService } from "services";

export const LastQuoteLive: React.FunctionComponent<{ symbol: string, setPrice: (price: number) => void }> = ({ symbol, setPrice }) => {
  const lastQuote = useLastQuote(predictionService, symbol, true);

  const setPriceRef = useRef<(price: number)=>void>(setPrice);

  useEffect(()=>{
    setPriceRef.current(lastQuote.item.c);
  }, [lastQuote.item.c])

  if (lastQuote.state.isLoading()) {
    return <div className="text-12 shimmer" style={{ width: "200px", height: "15px", background: "#111111"}}>{"           "}</div>
  }

  if (lastQuote.state.isError()) {
    return <div className="text-12">Could not get quote.</div>
  }

  return (
    <div className="text-12"><QuoteAsOf quote={lastQuote.item} /></div>
  );
}