export enum AutoTradeSecurityTypeEnum {
    EQUITY = 1,
    OPTION = 2,
}

export class AutoTradeSecurityTypeEnumHelper {
    static names: Map<AutoTradeSecurityTypeEnum, string> = new Map<AutoTradeSecurityTypeEnum, string>([
        [AutoTradeSecurityTypeEnum.EQUITY, 'Equity'],
        [AutoTradeSecurityTypeEnum.OPTION, 'Option'],
    ])
}