import React from 'react'

export const GrayTicketSvg = () => {
  return (

    <svg width="89" height="78" viewBox="0 0 89 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.5679 61.1567C35.2519 58.1133 31.0401 55.1494 26.8333 52.18C20.5543 47.748 14.2754 43.316 7.99648 38.8841C6.58285 37.8901 6.46809 37.1763 7.4864 35.7671C9.25604 33.3194 11.0275 30.8734 12.8008 28.429C13.6778 27.2257 14.4014 27.0941 15.6491 27.873C17.2342 28.8851 19.1452 29.2578 20.9939 28.9153C22.8425 28.5729 24.4902 27.5409 25.602 26.0291C26.7139 24.5173 27.2065 22.6391 26.9799 20.7762C26.7533 18.9132 25.8243 17.2052 24.3818 15.9993C24.1557 15.8051 23.9028 15.6341 23.6914 15.4235C23.4057 15.1641 23.2248 14.8095 23.1827 14.4265C23.1406 14.0436 23.2403 13.6587 23.4629 13.3443C25.4129 10.5566 27.3683 7.77381 29.3519 5.01228C30.0389 4.0558 30.9028 3.99649 31.9812 4.7597L50.7387 18.0339L63.0791 26.7841C63.2525 26.9096 63.4202 27.0436 63.6679 27.2336L39.5679 61.1567Z" fill="#343434"/>
      <path d="M43.1973 63.6566C51.2262 52.3398 59.252 41.0266 67.2744 29.7171C69.8833 31.5572 72.4122 33.3415 74.9583 35.1373C77.0304 36.5999 79.0802 38.0937 81.1745 39.5249C82.1465 40.1912 82.5502 41.2182 81.6807 42.3319C79.8113 44.7376 78.1277 47.2858 76.3681 49.7758C75.3892 51.1651 74.7162 51.3106 73.2954 50.43C71.7074 49.4832 69.8222 49.1637 68.0116 49.5346C66.201 49.9055 64.5963 50.9398 63.5138 52.4337C62.4313 53.9276 61.9497 55.7726 62.1638 57.6051C62.3779 59.4375 63.2722 61.1244 64.6704 62.3332C64.9006 62.5251 65.1494 62.6984 65.36 62.9121C65.6577 63.1791 65.8447 63.5473 65.8842 63.9445C65.9237 64.3417 65.8129 64.7391 65.5735 65.0585C63.6204 67.8453 61.6582 70.624 59.6868 73.3946C59.5735 73.5757 59.4238 73.7314 59.2471 73.852C59.0704 73.9725 58.8706 74.0553 58.6602 74.0951C58.4499 74.1348 58.2334 74.1307 58.0246 74.0831C57.8158 74.0354 57.619 73.9451 57.4467 73.818C52.7438 70.4957 48.0505 67.1761 43.354 63.8556C43.2948 63.795 43.2423 63.7283 43.1973 63.6566Z" fill="#343434"/>
    </svg>


    // <svg width="95" height="92" viewBox="0 0 95 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M35.7987 65.1906C32.6744 60.9327 29.6237 56.7833 26.5794 52.6303C22.0357 46.4318 17.4919 40.2333 12.9482 34.0348C11.924 32.6429 12.0422 31.9296 13.4557 30.9174C15.9118 29.1595 18.3692 27.4037 20.8277 25.6501C22.0418 24.788 22.7697 24.8934 23.705 26.0286C24.8859 27.4923 26.5792 28.4533 28.4408 28.7164C30.3025 28.9796 32.1927 28.5251 33.7276 27.4453C35.2625 26.3656 36.3268 24.7415 36.7043 22.9032C37.0818 21.0649 36.7442 19.1501 35.7601 17.5481C35.6074 17.292 35.4221 17.0495 35.2886 16.7827C35.1002 16.4458 35.0414 16.0521 35.1233 15.6757C35.2051 15.2992 35.422 14.966 35.7331 14.7387C38.4683 12.7158 41.2071 10.6992 43.9659 8.71174C44.9213 8.02334 45.7592 8.24182 46.5389 9.30833L60.102 27.8582L69.0194 40.0782C69.1439 40.2523 69.2602 40.4327 69.4347 40.6915L35.7987 65.1906Z" fill="#343434"/>
    //   <path d="M38.4461 68.7165C49.6569 60.5402 60.8634 52.3662 72.0657 44.1947C73.9541 46.7689 75.7843 49.2647 77.6273 51.777C79.1267 53.8225 80.5952 55.8906 82.1256 57.9135C82.8353 58.8543 82.8915 59.9564 81.713 60.7358C79.1756 62.4222 76.7692 64.3027 74.3091 66.1039C72.9392 67.1099 72.2549 67.0338 71.1878 65.7472C69.9833 64.3445 68.2976 63.4422 66.463 63.2181C64.6284 62.9939 62.7781 63.4643 61.2768 64.5365C59.7755 65.6086 58.7321 67.2047 58.3524 69.0101C57.9727 70.8155 58.2842 72.6992 59.2255 74.2899C59.3827 74.545 59.5635 74.7884 59.6952 75.058C59.8926 75.4058 59.9527 75.8144 59.8639 76.2036C59.775 76.5927 59.5436 76.9342 59.2151 77.1609C56.4772 79.1821 53.7333 81.1926 50.9833 83.1925C50.8182 83.3282 50.6267 83.4282 50.4209 83.4863C50.2151 83.5444 49.9993 83.5594 49.7872 83.5302C49.5751 83.501 49.3712 83.4283 49.1884 83.3167C49.0055 83.205 48.8477 83.0569 48.7247 82.8816C45.3224 78.2363 41.9282 73.5966 38.5313 68.955C38.4946 68.8787 38.466 68.7988 38.4461 68.7165Z" fill="#343434"/>
    // </svg>
  )
}





