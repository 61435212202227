import React, {useState} from "react";
import {ListContainer} from "components/common";
import {AdminStockHistoryForm} from "./Form";
import {adminStockHistoryApiService} from "services/AdminApiService";
import {FormButtons} from "_constants";
import {IDataField} from "interfaces";
import {IStockHistory} from "interfaces/IStockHistory";
import {DateHelper, StockHelper} from "predictagram-lib";
import {SearchFilter, SearchFilterOptions} from "./SearchFilter";
import {Spinner} from "../../../common/Spinner";
import {ExportToCsv} from "export-to-csv-fix-source-map";

export const AdminStockHistoryList: React.FunctionComponent = () => {
  const emptyValues: any | IStockHistory = {
    valueAt: StockHelper.workingHours(new Date()).end8PM,
    valueClose: 0, // closing price
    stockSymbol: '',
    valueOpen: 0,
    valueHigh: 0,
    valueLow: 0,
  } 

  const editableFields: IDataField[] = [
    { name: 'id', title: 'ID', editable: false },
    { name: 'valueAt', title: 'Value At', editable: true },
    { name: 'stockSymbolId', title: 'Stock Symbold ID', editable: true },
    { name: 'valueClose', title: 'Closing Price', editable: true },
  ];

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['stockSymbolId', ['Stock Symbol ID', undefined]],
    ['valueClose', ['Value Close', undefined]],
    ['valueTimestamp', ['Closing Date and Time', undefined]],
    ['valueAt', ['Value At', undefined]],
  ]);

  const getter = async (): Promise<IStockHistory[]> => {
    // const today = new Date();
    // const fiveDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 5);
    // const data = await adminStockHistoryApiService.getAll8PM({
    //   startTime: fiveDaysAgo.getTime() / 1000,
    //   endTime: today.getTime() / 1000,
    // });
    // return data
    return [];
  }

  const [isLoading, setIsLoading] = useState(false);

  const generateReport = async(options:SearchFilterOptions) => {
    setIsLoading(true);
    const symbolName = options.symbolName;
    const dataRaw = await adminStockHistoryApiService.getAll8PM({
      startTime: options.startTime,
      endTime: options.endTime,
      symbolName: options.symbolName,
    });
    const data:{date_time:string;value_close:number;value_open:number;value_high:number;value_low:number,volume:number}[] = [];
    for (const r of dataRaw) {
      data.push({
        date_time: DateHelper.dateTimeFormatUs(r.valueAt),
        value_close: r.valueClose,
        value_open: r.valueOpen as number,
        value_high: r.valueHigh as number,
        value_low: r.valueLow as number,
        volume: r.volume as number,
      })
    }

    const csv = new ExportToCsv({ useKeysAsHeaders: true, quoteStrings: '', filename:`stock-history-${symbolName}`});
    csv.generateCsv(data);

    setIsLoading(false);
  }

  return (
    <>
      <div>
        <div className="d-flex justify-content-center align-items-end gap-3">
          <SearchFilter onClick={generateReport} searchTitle={'Export CSV'}/>
          {isLoading && <Spinner />}
        </div>
      </div>
      <ListContainer
        title="Stock History"
        apiService={adminStockHistoryApiService}
        emptyValues={emptyValues}
        fieldList={fieldList}
        buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
        editableFields={editableFields}
        getter={getter}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }) => {
          return (
            <AdminStockHistoryForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick} editableFields={editableFields} />
          );
        }}
      </ListContainer>
    </>
  );
};


