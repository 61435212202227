import useEscape from '_hooks/useEscape';
import React, { useState, useRef, useEffect } from 'react'

export type WalkthroughCardType = [React.ReactNode, HTMLDivElement, ((a: HTMLDivElement, b: HTMLDivElement) => React.CSSProperties)];

export interface IWalkthrough {
  title: string | React.ReactNode,
  steps: WalkthroughCardType[],
}

export const Walkthrough: React.FunctionComponent<{ 
  walkthrough: IWalkthrough, 
  onIndexChange: (index: number) => void,
  onClose: any,
}> = ({ walkthrough, onIndexChange, onClose }) => {

  const [stepIndex, setStepIndex] = useState<number>(0);
  const [show, setShow] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement | null>(null);
  const [contentStyle, setContentStyle] = useState<React.CSSProperties>({} as React.CSSProperties);

  useEscape(()=> {setShow(false)});

  useEffect(() => {
    const getContentStyle: React.CSSProperties | null = (() => {
      if(walkthrough.steps.length === 0) {
        return null;
      }
      const step = walkthrough.steps[stepIndex];
      const fn = step[2];

      if (fn && ref.current) {
        return {
          display: show ? "inline" : "none",
          ...fn(ref.current, step[1])
        }
      }

      // default
      const rect = step[1].getBoundingClientRect();
      return {
        left: `${rect.top}px`,
        bottom: `${rect.bottom}px`
      }
    })()

    if (ref.current && getContentStyle !== null) {
      setContentStyle(getContentStyle);
      document.body.classList.add('noscroll');
    }

    return ()=> {
      document.body.classList.remove('noscroll');
    }
  }, [show, stepIndex, walkthrough.steps])

  const onClickBack = () => {
    setStepIndex(stepIndex - 1);
    onIndexChange(stepIndex - 1);
  }

  const onClickNext = () => {
    if (stepIndex === walkthrough.steps.length - 1) {
      // close modal
      _setShow(false);
      return;
    }
    setStepIndex(stepIndex + 1);
    onIndexChange(stepIndex + 1);
  }

  const _setShow = (value: boolean) => {
    setShow(value)
    if (value === false && onClose) {
      onClose();
    }
  }

  if (walkthrough.steps.length === 0) {
    return <></>
  }

  return (
    <>
      <div className="walkthrough" style={{ display: show ? "flex" : "none" }}>
      </div>
      <div className="walkthrough-content" style={contentStyle} ref={ref}>

        <div className="text-12 text-gray ms-3 mb-2" role="button" onClick={() => _setShow(false)}>Skip walkthrough</div>

        <div className="walkthrough-container">
          <div className="walkthrough-head bg-dark-gray">
            <div className="d-flex justify-content-between align-items-center">
              <div className="walkthrough-title text-12 fw-bold text-lavender">{walkthrough.title}</div>
              <div className="walkthrough-step text-12">{stepIndex + 1}/{walkthrough.steps.length}</div>
            </div>
          </div>
          <div className="walkthrough-body m-3">{walkthrough.steps[stepIndex][0]}</div>
        </div>

        <div className="walkthrough-footer m-3 d-flex justify-content-end align-items-center gap-2">
          {stepIndex > 0 && <button type="button" className="btn bg-darkest-gray no-hover text-white fw-bold px-3" onClick={onClickBack}>&lt;- Back</button>}
          <button type="button" className="btn btn-primary fw-bold px-3" onClick={onClickNext}>{stepIndex < walkthrough.steps.length - 1 ? <>Next -&gt;</> : 'Got It!'}</button>
        </div>
      </div>
    </>

  )
}
