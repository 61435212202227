import React, { useRef, useState } from 'react'
import styles from './DropdownFilter.module.scss';
import * as Icons from 'components/common/Icons';
import useClickOutside from '_hooks/useClickOutside';

export interface IDropdownFilterItem<K, V> {
  key: K,
  value: V,
  label: string | JSX.Element,
}

interface IProps<K, V> {
  items: IDropdownFilterItem<K, V>[],
  initialSelectedKey: K,
  onSelect: (key: K) => void,
  className?: string,
  // getLabel: (item: IDropdownFilterItem<T>) => string | JSX.Element,
}

export const DropdownFilter = <K, V extends unknown>({ items, initialSelectedKey, onSelect, className }: IProps<K, V>) => {
  
  const [selectedKey, setSelectedKey] = useState<K>(initialSelectedKey);
  const [expanded, setExpanded] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);

  useClickOutside(divRef, ()=>setExpanded(false));

  const handleClick = (key: K) => {
    setSelectedKey(key);
    onSelect(key);
    setExpanded(false);
  }

  const getValue = (key: K) => {
    const matches = items.filter( (item) => item.key === key);
    return matches[0];
  }
    
  return (
    <div className={`${styles.filter} ${className}`} ref={divRef}>
      <div className={`${styles.filterSelection} ${ expanded ? styles.filterSelectionExpanded : ''}`} role="button" onClick={()=>setExpanded(!expanded)}>
        <div className={`${styles.filterSelected} ${className ? `${className}-selected` : ''}`}>{getValue(selectedKey).label}</div>
        <div><Icons.DownArrow /></div>
      </div>
      {expanded && <div className={`${styles.filterItems} ${styles.filterItemsExpanded}`}>
        {items.map((item, idx) => <div
              role="button"
              key={`dropdown-key-${idx}`}
              className={`${styles.filterItem} ${item.key === selectedKey ? styles.filterItemSelected : ''} ${className ? `${className}-item` : ''}`}
              onClick={()=>handleClick(item.key)}
              >
          {item.label}
        </div>)}
      </div>}
    </div>
  )
}
