import {DateHelper, NewYorkTz} from "predictagram-lib";

export class TradegramDateHelper {
  /**
   * convert string Aug 18 '23 to US Date Midnight
   * @param dateString
   */
  public static convertMMMDDYYToDateUs(dateString: string): Date {
    const parts = dateString.split(" ");
    if (parts.length !== 3) {
      throw Error(`Unrecognized date string pattern ${dateString}`);
    }

    const shortMonth = parts[0];
    const day = parseInt(parts[1]);
    const year = parseInt(parts[2].replaceAll("'", '')); // add 2000 if short year (i.e '23)

    const monthMap: { [key: string]: number } = {
      jan: 0,
      feb: 1,
      mar: 2,
      apr: 3,
      may: 4,
      jun: 5,
      jul: 6,
      aug: 7,
      sep: 8,
      oct: 9,
      nov: 10,
      dec: 11,
    };

    const month = monthMap[shortMonth.toLowerCase()];
    if (isNaN(day) || isNaN(year) || isNaN(month)) {
      throw Error('Some parts of date string is invalid');
    }

    // create a new york date
    // 2023-06-30T16:00:00-04:00
    // return midnight Eastern
    // @NOTE: +5h is workaround to make sure UTC won't lead to previous day
    //  it won't work fine for days with clock switches, it requires separate handling
    const y = (year < 2000 ? year + 2000 : year)
    const offsetH = 5; // EST - 5h, so EST midnight will be UTC midnight+5h
    const dateStr = `${y}-${DateHelper.zeroPad((month+1).toString())}-${DateHelper.zeroPad(day.toString())}`;
    const dt = DateHelper.parseDateTimeUtc(`${dateStr} 0${offsetH}:00:00`); // new Date(y, month, day, 5);

    const usData = DateHelper.extractDateTimeUs(dt);

    // adjust by 1h if necessary for EDT tz
    return new Date(dt.getTime() - (usData.utcOffset + offsetH)*3600_000);
    // const to2 = (v:number)=>{
    //   return (v < 10) ? (v < 0 ? '-' : '+') + '0' + (v < 0 ? v * -1 : v) : v;
    // }
    // return new Date(`${dateStr}T00:00:00${to2(usData.utcOffset)}:00`);
  }

  /**
   *
   * @param yymmdd - example: 231011 - oct 11 2023
   * @returns Date
   */
  public static convertYYMMDDToDate(yymmdd: string): Date {
    const year = parseInt(yymmdd.substring(0, 2), 10) + 2000;
    const month = parseInt(yymmdd.substring(2, 4), 10);
    const day = parseInt(yymmdd.substring(4, 6), 10);

    // Create a new Date object using the extracted components
    // return NewYorkTz.getDateMidnight(new Date(year, month, day)); // unstable on samsung/android chrome
    //'2023-11-09T00:00:00-05:00'
    const offset = NewYorkTz.getTimezoneOffset(new Date(year, month - 1, day));
    const offsetFormatted = `${offset < 0 ? "-" : "+"}${Math.abs(offset).toString().padStart(2, '0')}:00`;
    const dateString = `${year}-${month.toString().padStart(2,'0')}-${day.toString().padStart(2, '0')}T00:00:00${offsetFormatted}`;
    return new Date(dateString);
  }
}
