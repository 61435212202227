export class MathEx {
    public static median(arr: any[], sorted=false) {
        if (!sorted) {
            arr.sort((a, b) => a - b);
        }
        const mid = arr.length / 2;
        return mid % 1 ? arr[mid - 0.5] : (arr[mid - 1] + arr[mid]) / 2;
    }

    public static average(arr: any[]) {
        if (arr.length===0) {
            return undefined;
        }
        let sum = 0;
        arr.forEach(v=>sum+=v);
        return sum/arr.length;
    }

    static round(val: number, decimal: number) {
        const n = Math.pow(10, decimal);
        return Math.round((val + Number.EPSILON) * n) / n;
    }
}
