import { Route, Routes } from 'react-router-dom';
import { ProtectedAdminRoute, } from 'components/common';
import { AdminLogin } from 'components/admin/AdminLogin';
import { AdminLayout } from 'components/admin/layout/Layout';
import { AdminDashboard } from 'components/admin/Dashboard';
import { AdminContestList } from 'components/admin/contests/List';

import { AdminLeagueList } from 'components/admin/league/List';
import { AdminLeagueSeasonList } from 'components/admin/league/seasons/List';
import { AdminLeagueTeamList } from 'components/admin/league/teams/List';
import { AdminStockHistoryList } from 'components/admin/stock/history/List';

import { AdminPredictionsSearch } from 'components/admin/predictions/AdminPredictionsSearch';
import { AdminAwardList } from 'components/admin/award/List';
import { AdminRegistrationsList } from 'components/admin/registrations/List';
import { AdminReportUsersStatsList } from "components/admin/report/users/List";
// import { AdminReportSignals } from "components/admin/report/signals/List";

import { AdminPendingVerificationsPage } from 'components/admin/tradegram/AdminPendingVerificationsPage';
import { AdminTicketsList } from 'components/admin/tickets/List';
import { AdminTradeBulkEdit } from "components/admin/tradegram/AdminTradeBulkEdit";
import { AdminSignalAlertList } from "components/admin/analysis/alerts/List";
import { AdminAnalysisStrategyProfit } from 'components/admin/analysis/strategy-profit/AdminAnalysisStrategyProfit';
import { NotFoundPage } from 'components/NotFoundPage';
import { AdminUsersRankingPage } from 'components/admin/report/users/RankingsPage';
import {AdminReportAlertCombined} from "../components/admin/analysis/alert-summary-report/List";
import { TradeSetupPage } from 'components/admin/autotrade/TradeSetupPage';
import { TradeSetupListPage } from 'components/admin/autotrade/TradeSetupListPage';
import { TradesListGroupBySetupPage } from 'components/admin/autotrade/TradesListGroupBySetupPage';
import { TradesListGroupBySecurityPage } from 'components/admin/autotrade/TradesListGroupBySecurityPage';
import { TradesListPage } from 'components/admin/autotrade/TradesListPage';
import { AdminCumeHistoryPage } from 'components/admin/analysis/cume-history/AdminCumeHistoryPage';
import { AdminStockLimitList } from 'components/admin/stock/limit/List';
import { ConsensusOrdersPage } from 'components/admin/autotrade/ConsensusOrdersPage';
import { ConsensusOrdersGroupedPage } from 'components/admin/autotrade/ConsensusOrdersGroupedPage';
import { AdminCumeDetailPage } from 'components/admin/analysis/cumulative-chart/AdminCumeDetailPage';
import {AdminReportSavedReports} from "../components/admin/report/saved-reports/List";
import {AdminTradesByScore} from "../components/admin/autotrade/TradesByScore";
import {AdminCumeDetailPageDay} from "../components/admin/analysis/cumulative-chart/AdminCumeDetailPageDay";


export const AdminRoutes: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route index element={<AdminLogin />} />
        <Route path="tradegram">
          <Route path="pending-verification" element={<ProtectedAdminRoute><AdminPendingVerificationsPage /></ProtectedAdminRoute>} />
          <Route path="edit-security-bulk/:securityId" element={<ProtectedAdminRoute><AdminTradeBulkEdit /></ProtectedAdminRoute>} />
        </Route>
        <Route path="predictions" element={<ProtectedAdminRoute><AdminPredictionsSearch /></ProtectedAdminRoute>} />
        <Route path="dashboard" element={<ProtectedAdminRoute><AdminDashboard /></ProtectedAdminRoute>} />
        <Route path="contests" element={<ProtectedAdminRoute><AdminContestList /></ProtectedAdminRoute>} />
        <Route path="badges" element={<ProtectedAdminRoute><AdminAwardList /></ProtectedAdminRoute>} />
        <Route path="leagues">
          <Route index element={<ProtectedAdminRoute><AdminLeagueList /></ProtectedAdminRoute>} />
          <Route path='seasons' element={<ProtectedAdminRoute><AdminLeagueSeasonList /></ProtectedAdminRoute>} />
          <Route path='teams' element={<ProtectedAdminRoute><AdminLeagueTeamList /></ProtectedAdminRoute>} />
        </Route>
        <Route path="stock">
          <Route path="history" element={<ProtectedAdminRoute><AdminStockHistoryList /></ProtectedAdminRoute>} />
        </Route>
        <Route path="registrations" element={<ProtectedAdminRoute><AdminRegistrationsList /></ProtectedAdminRoute>} />
        <Route path="report">
          <Route path="users-stats" element={<ProtectedAdminRoute><AdminReportUsersStatsList /></ProtectedAdminRoute>} />
          {/*<Route path="signals" element={<ProtectedAdminRoute><AdminReportSignals /></ProtectedAdminRoute>} />*/}
          <Route path="users-streak-stats" element={<ProtectedAdminRoute><AdminUsersRankingPage /></ProtectedAdminRoute>} />
          <Route path="saved-reports" element={<ProtectedAdminRoute><AdminReportSavedReports /></ProtectedAdminRoute>} />
        </Route>
        <Route path="analysis">
          <Route path="strategy-profit" element={<ProtectedAdminRoute><AdminAnalysisStrategyProfit /></ProtectedAdminRoute>} />
          <Route path="signal">
            <Route path="alerts" element={<ProtectedAdminRoute><AdminSignalAlertList /></ProtectedAdminRoute>} />
            <Route path="alert-combined-report" element={<ProtectedAdminRoute><AdminReportAlertCombined /></ProtectedAdminRoute>} />
          </Route>
          <Route path="cume-history/:signalAlertId" element={<ProtectedAdminRoute><AdminCumeHistoryPage /></ProtectedAdminRoute>} />
          <Route path="cumulative-detail" element={<ProtectedAdminRoute><AdminCumeDetailPage /></ProtectedAdminRoute>} />
          <Route path="cumulative-detail-day" element={<ProtectedAdminRoute><AdminCumeDetailPageDay /></ProtectedAdminRoute>} />
        </Route>
        <Route path="tickets" element={<ProtectedAdminRoute><AdminTicketsList /></ProtectedAdminRoute>} />

        <Route path="autotrade">
            {/* <Route path="dashboard" element={<ProtectedAdminRoute><TradeDashboardPage /></ProtectedAdminRoute>} /> */}
            <Route path="trade-setups" element={<ProtectedAdminRoute><TradeSetupListPage/></ProtectedAdminRoute>} />
            <Route path="trade-setup" element={<ProtectedAdminRoute><TradeSetupPage /></ProtectedAdminRoute>} />
            <Route path="trades" element={<ProtectedAdminRoute><TradesListPage/></ProtectedAdminRoute>} />
            <Route path="trades-groupby-setup" element={<ProtectedAdminRoute><TradesListGroupBySetupPage/></ProtectedAdminRoute>} />
            <Route path="trades-groupby-security" element={<ProtectedAdminRoute><TradesListGroupBySecurityPage/></ProtectedAdminRoute>} />
            <Route path="symbol/limits" element={<ProtectedAdminRoute><AdminStockLimitList/></ProtectedAdminRoute>} />
            <Route path="consensus-orders/:tradeId" element={<ProtectedAdminRoute><ConsensusOrdersPage/></ProtectedAdminRoute>} />
            <Route path="consensus-orders-grouped" element={<ProtectedAdminRoute><ConsensusOrdersGroupedPage /></ProtectedAdminRoute>} />
            <Route path="trades-by-score" element={<ProtectedAdminRoute><AdminTradesByScore /></ProtectedAdminRoute>} />
        </Route>
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
