export enum IBKROrderType {
  // LMT = 'LMT',
  MKT = 'MKT',
  // MIDPRICE = 'MIDPRICE',

  // custom bidding types
  LMT_PB = 'LMTPB', // LIMIT+ProgressiveBidding
  REL_PB = 'RELPB', // Pegged to Primary https://ibkrcampus.com/ibkr-api-page/order-types/#pegged-to-primary-orders
}

export class IBKROrderTypeHelper {
  static names: Map<IBKROrderType, string> = new Map<IBKROrderType, string>([
    // [IBKROrderType.LMT, 'Limit'],
    // [IBKROrderType.MIDPRICE, 'MidPrice'],
    [IBKROrderType.MKT, 'Market'],
    [IBKROrderType.LMT_PB, 'Limit+Pr.Bidding'],
    [IBKROrderType.REL_PB, 'Relative+Pr.Bidding'],
  ])
}
