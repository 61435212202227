import { ITokenPayload } from "interfaces/ITokenPayload";
import jwt_decode from 'jwt-decode';

export class TokenHelper {

  static getDecodedToken(token: string): ITokenPayload  {
    try {
      const {p, iat, exp} = jwt_decode(token) as ITokenPayload;
      return {p, iat, exp};
    } catch (error: any) {
      console.error('token error', {error});
      return {} as ITokenPayload;
    }
  }

  static setToken(token: string, name: string, storage: Storage) {
    //console.log('setting token to ', token);
    storage.setItem(name, token);
  }

  static getToken(name: string, storage: Storage): string {
    const token = localStorage.getItem(name) || '';
    return token;
  }

  static isTokenAboutToExpire(token: ITokenPayload, now: Date): boolean {
    const timeleft = (token.exp * 1000) - now.getTime();
    // 60 minutes
    return (timeleft / 1000 / 60 ) <= 60;
  }

  static isTokenExpired(token: ITokenPayload, now: Date): boolean {
    const timeleft = (token.exp * 1000) - now.getTime();
    return timeleft <= 0;
  }

}