import axios from "axios";
import { config } from "config";
import { IAccount, IUser } from "interfaces";
import { adminAuthenticationService, AuthenticationService, userAuthenticationService } from "./AuthenticationService";
import { IReferralParams } from "_hooks/useReferral";
import { StorageKey } from "_constants";
import { IAccountSettings } from "interfaces/account/IAccountSettings";
import { MessageHelper } from "_utils/MessageHelper";
import { IRecentActivity } from "interfaces/IRecentActivity";
import { ILastNotification } from "interfaces/account/ILastNotification";

class AccountService {
  constructor (
    protected apiBaseUrl: string,
    protected authenticationService: AuthenticationService
  ) {}

  async register(user: IUser, ctoken: string | null, referral?: IReferralParams, utmSource?: string): Promise<any> {

    const url = `${this.apiBaseUrl}/registration`;

    const payload = {
      ...user,
      ctoken,
      referralPredictionId: referral?.predictionId,
      referralUserId: referral?.userId,
      referralTeamId: referral?.teamId,
      referralContestId: referral?.cuid,
      utmSource,
    }

    try {
      const response: any = await axios.post(url, payload);
      localStorage.removeItem(StorageKey.REFERRAL); // clear referral data
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      this.authenticationService.setToken(response.data.token);
      await this.authenticationService.setLocalAccount();
      return this.authenticationService.getLocalAccount();
    } catch (error: any) {
      console.log('erro2r', (error as Error).message)
      throw( MessageHelper.translate((error as Error).message));
    }
  } 

  isLoggedIn(): boolean {
    return this.authenticationService.getLocalAccount() !== null;
  }

  getAccount(): IAccount | null {
    return this.authenticationService.getLocalAccount();
  }

  async updateAccount(accountData: IAccount): Promise<any> {
    const token = this.authenticationService.getToken();
    if (token === '' || token === null) {
      throw Error('Cannot update without a token');
    }
    const url: string = `${this.apiBaseUrl}/account`;
    try {
      const account = await axios.put(url, accountData, await this.authenticationService.getAuthHeader());
      if (!account.data || account.data.error) {
        throw Error(`error updating: ${account.data.error.message}`);
      }
      await this.authenticationService.setLocalAccount();
      return this.authenticationService.getLocalAccount();
    } catch (err: any) {
      throw Error(err);
    }
  }

  async requestResetPassword(emailAddress: string): Promise<any> {
    const url = `${this.apiBaseUrl}/request-reset-password`;
    const data = {
      email: emailAddress,
      ip: 0,
    }

    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      console.error({err});
      throw Error(err.message);
    }
  }

  async resetPassword(data: {authId: string, password: string, repeatPassword: string}) {
    const url = `${this.apiBaseUrl}/reset-password`;
    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async setPassword(data: {authId: string, password: string, repeatPassword: string}) {
    const url = `${this.apiBaseUrl}/set-password`;
    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async confirmEmail(data: {authId: string}) {
    const url = `${this.apiBaseUrl}/confirm-email`;
    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async registerPhoneNumber(): Promise<boolean> {
    const url = `${this.apiBaseUrl}/account/send-sms-phone-number`;
    try {
      const response: any = await axios.post(url, {}, await this.authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.success === true;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async verifyMobile(phoneNumber: number, code: string ): Promise<boolean> {
    const url = `${this.apiBaseUrl}/account/confirm-sms-phone-number`;
    try {
      const response: any = await axios.post(url, {phoneNumber, code}, await this.authenticationService.getAuthHeader());
      await this.authenticationService.setLocalAccount();
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return (response as {success:boolean}).success === true;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async checkRegisteredPhoneNumber(phoneNumber: number, phoneNumberCode: string, uniqueUserId: string): Promise<boolean> {
    const url = `${this.apiBaseUrl}/check-registered-phone-number?fg=${uniqueUserId}`;
    try {
      const response: any = await axios.post(url, {phoneNumber, phoneNumberCode});
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return (response.data.success === true);
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async deleteAccount(data: {password: string}) {
    const url = `${this.apiBaseUrl}/account/delete`;
    try {
      const response = await axios.post(url, data, await this.authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      // logout
      await this.authenticationService.logout();
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }

  }

  async getSettings (): Promise<IAccountSettings> {
    const url = `${this.apiBaseUrl}/account/settings`;
    try {
      const response = await axios.get(url, await this.authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as IAccountSettings;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async saveSettings (data: IAccountSettings | unknown) : Promise<IAccountSettings> {
    const url = `${this.apiBaseUrl}/account/settings`;
    try {
      const response = await axios.put(url, data, await this.authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as IAccountSettings;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async refreshLocalAccount (): Promise<IAccount> {
    try {
      await this.authenticationService.setLocalAccount(); // get the most recent from db
      const acct = this.authenticationService.getLocalAccount();
      return acct as IAccount;
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async getRecentActivity(): Promise<IRecentActivity[]> {
    const url = `${this.apiBaseUrl}/account/recent-activity`;
    try {
      const response = await axios.post(url, {}, await this.authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as IRecentActivity[];
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async getLastNotification(): Promise<ILastNotification> {
    const url = `${this.apiBaseUrl}/account/last-notification`;
    try {
      const response = await axios.post(url, {}, await this.authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as ILastNotification;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

}

const userAccountService = new AccountService(`${config.apiDomain}${config.apiBasePath}/user`, userAuthenticationService);
const adminAccountService = new AccountService(`${config.apiDomain}${config.apiBasePath}/admin`, adminAuthenticationService);

export { AccountService, userAccountService, adminAccountService }