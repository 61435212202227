import React from 'react'
import { IBKROrderType, IBKROrderTypeHelper } from 'predictagram-lib';
import { SelectDropDown } from 'components/common/form/SelectDropDown';


interface IProps {
  value: IBKROrderType | undefined,
  setValue: (value: IBKROrderType) => void,
  className?: string,
}

export const IbkrOrderTypeDropDown:React.FunctionComponent<IProps> = ({value, setValue, className}) => {

  return (
    <SelectDropDown<IBKROrderType, string> 
      onSelect={setValue}
      options={IBKROrderTypeHelper.names}
      selected={value}
      className={className}
      hasAll={false}
    />
  )
}



