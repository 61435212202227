import { Route, Routes } from 'react-router-dom';
import { PublicLayout } from 'components/public/layout/PublicLayout';
import { DashboardFollows } from 'components/user/dashboard/follows/DashboardFollows';
import { DashboardFollowers } from 'components/user/dashboard/followers/DashboardFollowers';
import { FollowedPredictions } from 'components/user/prediction/FollowedPredictions';
import { ContestDetailPage } from 'components/user/contest/ContestDetailPage';
import { ContestLayout } from 'components/user/contest/ContestLayout';
import { TeamsHomePage } from 'components/user/league/TeamsHomePage';
import { TeamDetailPage } from 'components/user/league/TeamDetailPage';
import { TeamDetailLayout } from 'components/user/league/TeamDetailLayout';
import { TeamRoster } from 'components/user/league/TeamRoster';
import { TeamPredictionsPage } from 'components/user/league/TeamPredictionsPage';
import { ContestPredictions } from 'components/user/contest/ContestPredictions';
import { ShowMorePage } from 'components/user/dashboard/ShowMorePage';
import { TicketPage } from 'components/user/tickets/TicketPage';
import { UserStatsPage } from 'components/user/stats/UserStatsPage';
import { UserStatsStockSymbol } from 'components/user/stats/UserStatsStockSymbol';
import { UserStatsPredictionType } from 'components/user/stats/UserStatsPredictionType';
import { LeagueRankingsPage } from 'components/user/rankings/LeagueRankingsPage';
import { RecentActivityPage } from 'components/user/notification/RecentActivityPage';
import { RecentFollowedTradesPage } from 'components/user/notification/RecentFollowedTradesPage';
import { RecentFollowedPredictionsPage } from 'components/user/notification/RecentFollowedPredictionsPage';
import { RecentPredictionsPage } from 'components/user/notification/RecentPredictionsPage';
import { ProtectedRoute } from 'components/common';
import { UserRoleEnum } from '_constants/UserRoleEnum';
import { UserProfile } from 'components/user/account';
import { FloatingMakeAPredictionButton } from 'components/common/FloatingMakeAPredictionButton';
import { NotFoundPage } from 'components/NotFoundPage';
import { ContestJoinPage } from 'components/user/contest/ContestJoinPage';
import { PredictionsPage } from 'components/user/dashboard/PredictionsPage';
import { DashboardPage2 } from 'components/user/dashboard/DashboardPage2';
import { PredictionTradesLayout } from 'components/user/dashboard/PredictionTradesLayout';
import { TradesFeedPage } from 'components/user/dashboard/TradesFeedPage';
import { SignalsLayout } from 'components/user/dashboard/signals/SignalsLayout';
import { Charts } from 'components/user/dashboard/signals/Charts';
import { SignalsPage } from 'components/user/dashboard/signals/SignalsPage';

export const UserRoutes: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicLayout />}>

        <Route path="predictions" element={<PredictionTradesLayout />}>
          <Route index element={<ProtectedRoute roles={[UserRoleEnum.USER]}><><PredictionsPage /><FloatingMakeAPredictionButton /></></ProtectedRoute>}></Route>
        </Route>

        <Route path="trades" element={<PredictionTradesLayout />}>
          <Route index element={<TradesFeedPage />} />
        </Route>

        <Route path="dashboard" element={<PredictionTradesLayout />}>
          <Route index element={<ProtectedRoute roles={[UserRoleEnum.USER]}><><DashboardPage2 /><FloatingMakeAPredictionButton /></></ProtectedRoute>} />

          <Route path="signals" element={<SignalsLayout />}>
            <Route path="charts" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><Charts /></ProtectedRoute>} />
            <Route path="signals" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><SignalsPage /></ProtectedRoute>} />
          </Route>
        </Route>

        <Route path="profile" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><UserProfile /></ProtectedRoute>} />
        <Route path="followed/:userId" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><><FollowedPredictions /></></ProtectedRoute>}></Route>
        <Route path="followed" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><><FollowedPredictions /></></ProtectedRoute>}></Route>
        <Route path="follows" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><><DashboardFollows /><FloatingMakeAPredictionButton /></></ProtectedRoute>}></Route>
        <Route path="followers" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><><DashboardFollowers /><FloatingMakeAPredictionButton /></></ProtectedRoute>}></Route>

        <Route path="notifications">
          <Route index element={<ProtectedRoute roles={[UserRoleEnum.USER]}><RecentActivityPage /></ProtectedRoute>} />
          <Route path="recent-predictions" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><RecentPredictionsPage /></ProtectedRoute>} />
          <Route path="followed-user-trades" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><RecentFollowedTradesPage /></ProtectedRoute>} />
          <Route path="followed-user-predictions" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><RecentFollowedPredictionsPage /></ProtectedRoute>} />
        </Route>

        <Route path="show-more" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><ShowMorePage /></ProtectedRoute>}></Route>
        <Route path="tickets" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><TicketPage /></ProtectedRoute>} />
        <Route path="league/rankings" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><LeagueRankingsPage /></ProtectedRoute>} />

        <Route path="stats" element={<UserStatsPage />}>
          <Route path="symbol/:userId" element={<UserStatsStockSymbol />} />
          <Route path="prediction-type/:symbol/:userId" element={<UserStatsPredictionType />} />
          <Route index element={<NotFoundPage />} />
        </Route>

        <Route path="contest" element={<ContestLayout />}>
          <Route index element={<ProtectedRoute roles={[UserRoleEnum.USER]}><NotFoundPage /></ProtectedRoute>} />
          <Route path="join/:contestId" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><ContestJoinPage /></ProtectedRoute>} />
          <Route path="predictions/:contestId" element={<ProtectedRoute roles={[UserRoleEnum.USER]}><ContestPredictions /></ProtectedRoute>} />
          <Route path=":contestId" element={<ContestDetailPage />} />
        </Route>

        <Route path="teams" element={<TeamDetailLayout />}>
          <Route index element={<TeamsHomePage />} />
          <Route path="roster/:teamId" element={<TeamRoster />} />
          <Route path="predictions/:teamId" element={<TeamPredictionsPage />} />
          <Route path="invitation/:teamId" element={<TeamDetailPage isInvitation={true} />} />
          <Route path=":teamId" element={<TeamDetailPage />} />
        </Route>
 
        <Route path="*" element={<NotFoundPage />} />

      </Route>
    </Routes>
  );
}