import React, { useContext, useState } from 'react'
import { IStockSymbol } from 'interfaces/IStockSymbol';
import { Formik, Form } from 'formik';
import { FieldWithError } from 'components/common';
import { Validator, validationMap } from 'schemas';
import { ITradegramParams, ITradegramSecurity, tradegramApiService } from 'services/TradegramApiService';
import { BrokerSecurityTypeEnum, BrokerTransactionTypeEnum, BrokerTransactionTypeHelper, StockHelper } from 'predictagram-lib';
import { MessengerContext } from 'components/common/messenger';
import { Helper } from '_utils';
import { MessageHelper } from '_utils/MessageHelper';
import { BrokerTransactionTypeDropdown } from './BrokerTransactionTypeDropdown';
import { CloseButton } from 'react-bootstrap';
import { SearchStock } from './SearchStock';
import { LastQuoteLive } from 'components/common/LastQuoteLive';
import { TradegramHelper, CardContext} from '_utils/TradegramHelper';
import { RoundBackButton } from '../../common/buttons/RoundBackButton';


interface IProps {
  onSuccess: () => void,
  onCancel: () => void,
  security?: ITradegramSecurity,
  cardContext?: CardContext
}

export const AddEquity: React.FunctionComponent<IProps> = ({ onSuccess, onCancel, security, cardContext }) => {

  enum Step {
    PICK_SYMBOL = 1,
    ENTER_PRICE = 2,
    REVIEW = 3,
    DONE = 4
  }

  type IInitialWizardValues = {
    currentStep: Step,
    stockSymbol: IStockSymbol,
    brokerTransactionType: BrokerTransactionTypeEnum,
    transaction?: ITradegramParams,
  }
  
  const initialWizardValues: IInitialWizardValues = security ? {
    currentStep: Step.ENTER_PRICE,
    brokerTransactionType: cardContext ? TradegramHelper.getBrokerTransactionTypeFromContext(cardContext, security) : BrokerTransactionTypeEnum.BUY_TO_OPEN,
    stockSymbol: { symbolName: security.stockSymbol } as IStockSymbol,
    transaction: cardContext === CardContext.CLOSE ? {
      quantity: Math.abs(TradegramHelper.getQuantityBalance(security))
    } as ITradegramParams : undefined
  } : {
    currentStep: Step.PICK_SYMBOL,
    brokerTransactionType: BrokerTransactionTypeEnum.BUY_TO_OPEN,
    stockSymbol: {} as IStockSymbol,
  }

  const [currentStep, setCurrentStep] = useState<Step>(initialWizardValues.currentStep);
  const [stockSymbol, setStockSymbol] = useState<IStockSymbol>(initialWizardValues.stockSymbol);
  const [brokerTransactionType, setBrokerTransactionType] = useState<BrokerTransactionTypeEnum>(initialWizardValues.brokerTransactionType);
  const msgrContext = useContext(MessengerContext);

  const [transaction, setTransaction] = useState<ITradegramParams >({
    quantity: initialWizardValues.transaction?.quantity,
  } as ITradegramParams);


  const setInitialPrice = (price: number) => {
    setTransaction({
      ...transaction,
      price
    })
  }

  const stepIs = (s: Step) => {
    return Helper.enumIs<Step>(s, currentStep);
  }

  const onSubmitNotes = async (data: any, actions: any) => {
    if ((data.transactionDescription || "") !== "") {
      const trans = {
        ...transaction,
        transactionDescription: data.transactionDescription
      } as ITradegramParams
      // console.log(trans);
      setTransaction(trans);
      await saveTransaction(trans);
    } else {
      const trans = {
        ...transaction,
      } as ITradegramParams
      setTransaction(trans);
      await saveTransaction(trans);
    }
  }

  const onSubmit = async (data: any, actions: any) => {

    if (stepIs(Step.PICK_SYMBOL)) {
      setCurrentStep(Step.REVIEW);
      return;
    }

    if (stepIs(Step.ENTER_PRICE)) {

      const isPriceValid = await tradegramApiService.validatePrice({symbolName: stockSymbol.symbolName, price: Number(data.price)});
      if (isPriceValid.success === false) {
        if (StockHelper.isMarketOpen(new Date())) {
          msgrContext.setMessage({body: 'You entered an invalid price.'});
        } else {
          msgrContext.setMessage({body: 'Market is already close. Please enter your trade during market hours.'});
        }
        return;
      }

      setTransaction({
        ...transaction,
        transactionTypeId: brokerTransactionType,
        securityTypeId: BrokerSecurityTypeEnum.EQUITY,
        stockSymbol: stockSymbol.symbolName,
        quantity: Number(data.quantity) * (BrokerTransactionTypeHelper.isBuy(brokerTransactionType) ? 1 : -1),
        price: Number(data.price)
      } as ITradegramParams)
      setCurrentStep(Step.REVIEW)
      return;
    }

  }

  const saveTransaction = async (transaction: ITradegramParams) => {
    if (transaction) {
      try {
        await tradegramApiService.addTransaction(transaction);
      } catch (error: any) {
        setCurrentStep(Step.ENTER_PRICE)
        msgrContext.setMessage({ body:  `Error during saving Equity: ${MessageHelper.translate((error as Error).message)}` });
        return;
      }
      msgrContext.setMessage({ body: "Transaction Saved" });
      onSuccess();
    } else {
      setCurrentStep(Step.ENTER_PRICE)
      throw Error('unexpected error. Empty transaction');
    }
  }

  const onStockSymbolSelect = (s: IStockSymbol) => {
    setStockSymbol(s);
    setCurrentStep(Step.ENTER_PRICE);
  }

  const onClickBack = () => {
    if (currentStep === 1) {
      // we're at first step, so cancel out
      onCancel();
      return;
    }

    if (cardContext && stepIs(Step.ENTER_PRICE)) {
      onCancel();
      return;
    }
    setCurrentStep(currentStep - 1);
  }

  return (
    <div className="container h-100 d-flex flex-column align-items-stretch">
      <div className="d-flex justify-content-between align-items-center">
        <div role="button" onClick={onClickBack}><RoundBackButton /></div>
        <div role="button" title="Close" className="fw-bold ms-auto text-white"><CloseButton variant="white" onClick={onCancel} /></div>
      </div>

      {stepIs(Step.PICK_SYMBOL) &&
        <div>
          <SearchStock onSelect={onStockSymbolSelect} />
        </div>
      }

      {stepIs(Step.ENTER_PRICE) && stockSymbol &&
        <div>
          <div className="mt-3 m-auto text-18 text-center">Post <span className="fw-bold">{stockSymbol.symbolName}</span> Trade</div>
          <div className="d-flex justify-content-center"><LastQuoteLive symbol={stockSymbol.symbolName} setPrice={setInitialPrice}/></div>
          <div className="text-14 text-center mt-3">Trade</div>
          <div>
            <BrokerTransactionTypeDropdown initialValue={brokerTransactionType} setValue={setBrokerTransactionType} isNewPosition={cardContext === undefined || (security && TradegramHelper.isClosed(security))}/>
          </div>

          <Formik initialValues={transaction} onSubmit={onSubmit} enableReinitialize validationSchema={validationMap.get(Validator.ADD_EQUITY_FORM)}>
            {({ values, errors, touched, isSubmitting }) => {
              return (
                <Form>
                  <div className="text-center mt-5">
                    <span className="text-12"># of Shares</span>
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName="quantity"
                      placeholder="# of shares"
                    />
                  </div>

                  <div className="text-center">
                    <span className="text-12">Market Price</span>
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName="price"
                      placeholder="Market Price"
                    />
                  </div>

                  <button type="submit" className="btn btn-secondary my-5 w-100 p-3" disabled={isSubmitting}>Review</button>

                </Form>
              )
            }}
          </Formik>
        </div>
      }

      {stepIs(Step.REVIEW) && stockSymbol && transaction &&

        <Formik initialValues={{transactionDescription: transaction.transactionDescription}} enableReinitialize onSubmit={onSubmitNotes}>
          {({ values, errors, touched, isSubmitting }) => {
            return (
              <div className="d-flex flex-column justify-content-center align-items-center my-3">
                <div className="text-16 my-3">{BrokerTransactionTypeHelper.getName(brokerTransactionType)}</div>
                <div className={`card bg-${BrokerTransactionTypeHelper.isBuy(brokerTransactionType) ? "bright-green" : "crimson"} w-100 px-5 py-2 text-center d-flex flex-column justify-content-center text-white`}>
                  <div className="fw-bold">{transaction.quantity} {Math.abs(transaction.quantity || 0) === 1 ? "share" : "shares"} of {stockSymbol.symbolName}</div>
                  <div>for</div>
                  <div className="fw-bold">${transaction.price.toFixed(2)}</div>
                </div>

                <Form className="w-100">
                  <div className="text-center mt-3">
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      type="textarea"
                      as="textarea"
                      fieldName="transactionDescription"
                      rows={5}
                      placeholder="Add trade notes here (stop loss, target price, etc)"
                    />
                  </div>

                  <button type="submit" className="btn btn-secondary mt-3 w-100 mt-5" disabled={isSubmitting}>Post</button>

                </Form>
              </div>

            )
          }}
        </Formik>


      }

    </div>
  )
}


