import React from 'react'

import { usePublicProfile } from '_hooks/usePublicProfile'
import { Emoji } from 'components/common/Emoji';
import { Spinner } from 'components/common/Spinner';
import { userPredictionApiService } from 'services/UserPredictionApiService'

export const AccuracyScore: React.FunctionComponent<{ userId: number, classNames: string, [x: string]: any }> = ({ userId, classNames, ...rest }) => {

  const { item: profile, loaded } = usePublicProfile(userId, userPredictionApiService, false);

  if (!loaded) {
    return <Spinner />
  }

  return (
    <div className={`predictor-accuracy ${classNames}`} {...rest} title="Overall Prediction Accuracy Rate"><Emoji symbol="🎯" text={""} label="accuracy" />
      {((profile.predictions.averageScore || 0) * 100).toFixed(0)}%
    </div>

  )
}
