import { useCallback, useEffect, useRef, useState } from "react";
import { APIState } from "_constants/APIState";
import { IUserProfileStatsResponse, IUserProfileStatsSearchOptions, UserPredictionApiService } from "services/UserPredictionApiService";

export interface IUserProfileStats {
  item: IUserProfileStatsResponse,
  apiState: {
    isLoading: () => boolean,
    isLoaded: () => boolean,
    isIdle: () => boolean,
    isError: () => boolean
  },
  error: Error | null,
  reload: () => void,
}

export function useUserProfileStats (service: UserPredictionApiService, userId: number, startTime?: number, endTime?: number): IUserProfileStats {

  const [ item, setItem ] = useState<IUserProfileStatsResponse>({} as IUserProfileStatsResponse);
  const [ apiState, setApiState ] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<Error | null>(null);
  const [refresh, setRefresh] = useState<number>(0);
  
  const serviceRef = useRef(service);

  const load = useCallback(async ()=>{
    try {
      setApiState(APIState.LOADING);
      const params:IUserProfileStatsSearchOptions = {
        userId,
        endTime,
        startTime
      }
      const item = await serviceRef.current.getUserProfileStats(params);
      setItem(item);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as Error);
    } 
  }, [userId, endTime, startTime])

  useEffect(()=>{

    load();

    // clean up
  }, [load, refresh])

  const reload = () => {
    setRefresh(refresh + 1);
  }

  const isLoaded = () => apiState === APIState.LOADED;
  const isLoading = () => apiState === APIState.LOADING;
  const isError = () => apiState === APIState.ERROR;
  const isIdle = () => apiState === APIState.IDLE;

  return {item, apiState: {isLoaded, isLoading, isError, isIdle}, error, reload};

}
