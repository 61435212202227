import React, { CSSProperties } from 'react'
import { Modal } from 'react-bootstrap';
import { Fire } from 'components/common/Icons';
import { RoundCloseButton } from 'components/common/buttons/RoundCloseButton';


export const ModalStreak: React.FunctionComponent<{
  show: boolean,
  handleClose: any,
  streakCount: number,
}> = ({ show, handleClose, streakCount }) => {


  const streakStyle: CSSProperties = {
    position: 'absolute',
    transform: 'translate(2.8125rem, 2.1875rem)',
    width: '2.0625rem',
    height: '2.0625rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const boostPct = (()=>{
    return streakCount * 5;
  })()

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <div className="d-flex flex-column mb-5">
        <RoundCloseButton onClick={handleClose} className='align-self-end m-3'/>
        <div className="d-flex flex-column justify-content-center align-items-center">


          <div className="rounded-circle bg-darkest-gray no-hover p-3 d-flex justify-content-center" style={{position:"relative", width: "5.9375rem"}}>
            <Fire width={52} height={59} fillColor={streakCount > 0 ? undefined : '#ffffff'}/>
            <div className={`fw-bold text-white my-3 rounded-circle bg-${streakCount > 0 ? 'fire' : 'white'}`} style={streakStyle}><span className="text-black text-14 fw-bold">x{streakCount}</span></div>
          </div>
          {streakCount === 0 ? 
          <>
          <div className="fw-bold text-white my-3">No Streak</div>
          <div className="text-14 my-3 text-white px-5 text-center">Make a prediction that scores above 🎯50% to start a streak and boost your XP!</div>
          </>
          : 
          <>
          <div className="d-flex flex-column justify-content-center align-items-center gap-3">
            <div className="fw-bold text-white mt-4 pt-1">You're on a {streakCount} day streak!</div>
            <div className="pill bg-charcoal px-3 py-1 no-hover fw-bold text-white">{boostPct.toFixed(0)}% XP boost</div>
          </div>
          <div className="text-14 my-3 text-white px-5 text-center">Make a prediction that scores above 🎯50% to keep your streak going!</div>
          </>
          }

        </div>

        </div>
      </Modal.Body>
    </Modal>
  )
}

