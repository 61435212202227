import { useApiSignals } from '_hooks/useApiSignals'
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import React from 'react'
import { IUserSignalAlert } from 'services/UserSignalApiService';
import { ConsensusChart } from 'components/public/Stock/ConsensusChart';

export const Charts:React.FunctionComponent = () => {

  const api = useApiSignals();

  const getGroupedSignals = (signals: IUserSignalAlert[]) => {
    const grouped: {[key: string]: IUserSignalAlert[]} = signals.reduce((acc: {[key: string]: IUserSignalAlert[]}, s) => {
      if (acc[s.symbolName]) {
        acc[s.symbolName] = [
          ...acc[s.symbolName],
          s
        ];
      } else {
        acc[s.symbolName] = [s];
      }
      return acc;
    }, {} )
    return grouped;
  }

  const render = () => {
    if (api.apiState.isError) {
      return <ErrorMessage>{api.error}</ErrorMessage>
    }

    if (api.apiState.isLoading) {
      return <Spinner minHeight={20} />
    }

    if (api.apiState.isLoaded) {

      const grouped = getGroupedSignals(api.data);

      return <>
      {Object.entries(grouped).map((group, i) => <div key={`group-${i}`}>
        <ConsensusChart signalAlerts={group[1]} stockSymbol={group[0]} predictionTypes={[]} showWalkthrough={false}/>
      </div>)}
      </>


    }

    return <></>
  }

  return (
    <div>
      {render()}
    </div>
  )
}
