import {IParsedOption, OptionPattern} from "./OptionPattern";
import {OptionType} from "predictagram-lib";
import {TradegramDateHelper} from "./TradegramDateHelper";

export class OptionPatternThinkSwim3 extends OptionPattern {

  // BOT NFLX 100 19 JAN 24 500 CALL @9.90
  protected pattern = /^(BOT|SOLD) ([A-Z]+) \d+ (\d+ (?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec) \d{2}) (\d+(?:\.\d{1,3})?) (Call|Put|c|p) @?\s?(\.?\d+(?:\.\d{1,3})?)/i;

  parse(optionName: string) {
    const match = optionName.match(this.pattern);
    if (!match) {
      throw new Error('Invalid option contract name format');
    }
    const [, action, symbol, ddmmmyy, price, optionType, executionPrice] = match;
    const [d, m, y] = ddmmmyy.split(" ");
    const parsedOption: IParsedOption = {
      quantity: 0,
      action: action.toUpperCase().slice(0,1) === 'B' ? "buy" : "sell",
      symbol: symbol.toUpperCase(),
      date: TradegramDateHelper.convertMMMDDYYToDateUs(`${m} ${d} ${y}`).getTime() / 1000,
      optionType: optionType.toLocaleUpperCase().slice(0,1) === 'C' ? OptionType.CALL : OptionType.PUT,
      price: parseFloat(price),
      executionPrice: parseFloat(executionPrice)
    }
    return parsedOption;

  }
}
