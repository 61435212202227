export enum AutoTradeSetupStatusEnum {
    ACTIVE = 1,
    PAUSED = 2, // pause entry and exit everything
    PAUSED_ENTRY = 3, // pause new trades creation
    PAUSED_EXIT = 4, // use "manual" for not closed trades
    PAUSED_PARENT = 5, // paused because there is a new child setup, @NOTE: it's a dummy status really for now
}

export class AutoTradeSetupStatusEnumHelper {
    static names: Map<AutoTradeSetupStatusEnum, string> = new Map<AutoTradeSetupStatusEnum, string>([
        [AutoTradeSetupStatusEnum.ACTIVE, 'Active'],
        [AutoTradeSetupStatusEnum.PAUSED, 'Paused'],
        [AutoTradeSetupStatusEnum.PAUSED_ENTRY, 'Paused Entry'],
        [AutoTradeSetupStatusEnum.PAUSED_EXIT, 'Paused Exit'],
        [AutoTradeSetupStatusEnum.PAUSED_PARENT, 'Paused Parent'],
    ])
}
