import React, { useCallback, useState, useRef, useEffect } from 'react'
import { TradegramCard } from './TradegramCard'
import { ModalWizard } from './ModalWizard'
import { SecuritiesDatasetEnum, useTradegramSecurities } from '_hooks/useTradegramSecurities'
import { ITradegramSecuritiesFilter, ITradegramSecurity, tradegramApiService } from 'services/TradegramApiService'
import { BrokerSecurityTypeEnum } from 'predictagram-lib'
import { useTradegramSecurityTotals } from '_hooks/useTradegramSecurityTotals'
import { TradegramHelper } from '_utils/TradegramHelper'
import { BetaText } from 'components/common/BetaText'
import { CardContext } from '_utils/TradegramHelper'
import { TextDropDown } from 'components/common/TextDropDown/TextDropDown'
import { SecurityTypeDropDownFilter, SecurityTypeFilterEnum } from 'components/user/dashboard/filters/SecurityTypeDropDownFilter';
import { Helper } from '_utils'
import { PositionStatusEnum, PositionStatusFilterTabs } from './PositionStatusFilterTabs'
import { TradeSortDropdown, TradeSortEnum } from './TradeSortDropdown'
import { Helmet } from 'react-helmet';
import { useIntersectionObserver } from '_hooks/useIntersectionObserver'
import { LoadingMoreSpinner } from 'components/common/LoadingMoreSpinner'

export const TradegramPage: React.FunctionComponent = () => {
  const [showTradeWizard, setShowTradeWizard] = useState<boolean>(false);
  const [security, setSecurity] = useState<ITradegramSecurity | undefined>(undefined);
  const [cardContext, setCardContext] = useState<CardContext | undefined>(undefined);
  
  const containerRef = useRef<HTMLDivElement | null>(null);

  const ROWS_PER_CALL = 25; // @TODO: set this higher after testing

  const [securitiesFilter, setSecuritiesFilter] = useState<ITradegramSecuritiesFilter>({
    limit: ROWS_PER_CALL, 
  } as ITradegramSecuritiesFilter);

  // data
  const tradegramSecuritiesTotals = useTradegramSecurityTotals(tradegramApiService);
  const securitiesApi = useTradegramSecurities(tradegramApiService, SecuritiesDatasetEnum.OWNED, securitiesFilter);

  // filters
  const [symbolFilter, setSymbolFilter] = useState<string>('');
  const [securityTypeFilter, setSecurityTypeFilter] = useState<SecurityTypeFilterEnum | BrokerSecurityTypeEnum>(SecurityTypeFilterEnum.ALL);
  const [positionStatusFilter, setPositionStatusFilter] = useState<PositionStatusEnum>(PositionStatusEnum.ALL);
  const [tradeSort, setTradeSort] = useState<TradeSortEnum>(TradeSortEnum.MOST_RECENT);

  useIntersectionObserver(containerRef, ()=>{
    if (securitiesApi.apiState.isLoaded) {
      securitiesApi.getNextPage();
    }
  }, {threshold: 1});

  const symbolNames = useCallback(() => {
    const items = securitiesApi.items
      .map(s => s.stockSymbol)
      .filter(Helper.uniqueFilter)
      .sort((a, b) => a.localeCompare(b));
    return [
      ...items
    ]
  }, [securitiesApi.items])

  const [ filteredData, setFilteredData ] = useState<ITradegramSecurity[]>([])
  useEffect(()=>{
    if (securitiesApi.apiState.isLoaded) {
      const filteredData = securitiesApi.items
      .filter(item => (securityTypeFilter === SecurityTypeFilterEnum.ALL || securityTypeFilter === item.typeId ))
      .filter(item => symbolFilter === "" || symbolFilter === 'All Symbols' || (symbolFilter.toUpperCase() === item.stockSymbol.toUpperCase()))
      .filter(item => positionStatusFilter === PositionStatusEnum.ALL
          || (positionStatusFilter === PositionStatusEnum.CLOSED && TradegramHelper.getQuantityBalance(item) === 0)
          || (positionStatusFilter === PositionStatusEnum.OPEN && TradegramHelper.getQuantityBalance(item) !== 0)
      )      
      setFilteredData(filteredData);
    } 
  }, [symbolFilter, securityTypeFilter, positionStatusFilter, tradeSort, securitiesApi.apiState.isLoaded, securitiesApi.items])

  const AddPositionButton = () => {
    return <button className="btn btn-primary text-14" onClick={() => {
      setSecurity(undefined);
      setCardContext(undefined);
      setShowTradeWizard(true);
    }}>Make A Trade</button>
  }

  const addPosition = (security: ITradegramSecurity) => {
    setSecurity(security);
    setCardContext(CardContext.ADD);
    setShowTradeWizard(true);
  }

  const closePosition = (security: ITradegramSecurity) => {
    setSecurity(security);
    setCardContext(CardContext.CLOSE);
    setShowTradeWizard(true);
  }

  const onSuccess = () => {
    tradegramSecuritiesTotals.reload();
    securitiesApi.reload();
  }

  const tradeSorter = (a: ITradegramSecurity, b: ITradegramSecurity) => {
    switch (tradeSort) {
      case TradeSortEnum.PROFIT_ASC:
        return (TradegramHelper.getProfit(a).perShare > TradegramHelper.getProfit(b).perShare) ? 1 : -1;
      case TradeSortEnum.PROFIT_DESC:
        return (TradegramHelper.getProfit(a).perShare > TradegramHelper.getProfit(b).perShare) ? -1 : 1;
      case TradeSortEnum.SYMBOL_A_Z:
        return Helper.sortByProperty([a, b], "stockSymbol", true);
      case TradeSortEnum.SYMBOL_Z_A:
        return Helper.sortByProperty([a, b], "stockSymbol", false);
      default:
        if (a.lastTransaction && b.lastTransaction && a.lastTransaction.createdAt !== null && b.lastTransaction.createdAt !== null) {
          return (a.lastTransaction.createdAt > b.lastTransaction.createdAt) ? -1 : 1
        }
        return 0;
    }
  }

  return (
    <>
      <Helmet>
        <title>Predictagram: My Trades</title>
      </Helmet>

      <ModalWizard show={showTradeWizard} handleClose={() => setShowTradeWizard(false)} cardContext={cardContext} onSuccess={onSuccess} security={security} />
      <div className="tradegram my-trades">
        <div className="mx-3">
          {tradegramSecuritiesTotals.apiState.isLoaded() && tradegramSecuritiesTotals.items.length === 0 &&
            <>
              <div className="fw-bold text-18">My Trades <BetaText /></div>
              <div className="my-3 d-flex flex-column justify-content-start align-items-center">
                <div className="my-3">You have no trades.</div>
                <AddPositionButton />
              </div>
            </>
          }

          {tradegramSecuritiesTotals.apiState.isLoaded() && tradegramSecuritiesTotals.items.length > 0 &&
            <div className="d-flex justify-content-between align-items-center">
              <div className="fw-bold text-18">My Trades <BetaText /></div>
              {/* <div style={{ width: "150px" }}><SymbolFilter initialValue='All' setValue={setSymbol} symbols={uniqueSymbols} /></div> */}
              <AddPositionButton />
            </div>
          }
          {/* <SecurityTypeFilterTabs securityTypeFilterState={[securityTypeFilter, setSecurityFilter]} /> */}

          <div className="mt-2"><PositionStatusFilterTabs filterState={[positionStatusFilter, setPositionStatusFilter]} /></div>

          <div className="my-3 d-flex flex-wrap justify-content-start align-items-center gap-3">
            <div style={{ width: "180px", zIndex: 1000 }}><TextDropDown items={symbolNames()} onSelect={setSymbolFilter} /></div>
            <div style={{ width: "150px", zIndex: 1000 }}><SecurityTypeDropDownFilter initialValue={SecurityTypeFilterEnum.ALL} setValue={setSecurityTypeFilter} className="security-drop-down" /></div>
            <div className="flex-grow-1 flex-md-grow-0"><TradeSortDropdown initialValue={tradeSort} setValue={setTradeSort} /></div>
          </div>
        </div>

        <div className="tradegram-list mt-3" >
          {securitiesApi.apiState.isLoaded && securitiesApi.items.length > 0 &&
            <>
              {
                filteredData
                  .sort(tradeSorter)
                  .map((security: ITradegramSecurity, i) => <div key={`sec-key-${security.id}-${i}`} className="tradegram-list-symbol-trade">
                    <TradegramCard onSuccess={onSuccess} security={security} addPosition={() => addPosition(security)} closePosition={() => closePosition(security)} showAddCloseButtons={true} />
                  </div>)
              }
            </>
          }
        </div>
        <div style={{height: '10px'}} ref={containerRef}><LoadingMoreSpinner loading={securitiesApi.apiState.isLoading} /></div>
      </div>
    </>)
}

