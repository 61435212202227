export enum IBKRSide {
  BUY = 'BUY',
  SELL = 'SELL',
}

export class IBKRSideHelper {
  static names: Map<IBKRSide, string> = new Map<IBKRSide, string>([
    [IBKRSide.BUY, 'Buy'],
    [IBKRSide.SELL, 'Sell'],
  ])
}