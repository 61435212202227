import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { usePublicPrediction } from '_hooks/usePublicPrediction';
import { Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { MorePredictionsSymbol } from './MorePredictionsSymbol';
import { RoundBackButton } from 'components/common/buttons/RoundBackButton';
import { IPrediction } from 'predictagram-lib';
import { usePublicProfile } from '_hooks/usePublicProfile';
import { PredictionLazy } from '../ListingsPage/components/Prediction/PredictionLazy';
import useResize from '_hooks/useResize';


const PageTitle: React.FunctionComponent<{ prediction: IPrediction, symbol: string }> = ({ prediction, symbol }) => {
  const api = usePublicProfile(prediction.userId as number, userPredictionApiService, true);
  if (api.loaded) {
    return <div className="text-16" ><span className="fw-bold">{api.item.username}</span>'s <span className="fw-bold">{symbol}</span> Prediction </div>
  }
  return <Spinner />
}

export const StockExpandedPage: React.FunctionComponent = () => {
  const { predictionId } = useParams();
  const api = usePublicPrediction(userPredictionApiService, { predictionId: predictionId ? parseInt(predictionId, 10) : 0 } as ISearchOptions)
  const navigate = useNavigate();
  const refCardParent = useRef<HTMLDivElement>(null);
  const [cardParentWidth] = useResize(refCardParent);

  return (
    <>
      <div className="container">
        {api.status.loaded && api.items.length === 0 &&
          <div>No Predictions</div>
        }

        {api.status.loading &&
          <Spinner />
        }

        {api.status.loaded && api.items.length > 0 &&
          <>
            <Helmet>
              <title>Predictagram: {api.items[0].stockSymbol} Details </title>
            </Helmet>

            <div className="d-flex justify-content-start gap-2 align-items-center">
              <RoundBackButton onClick={() => navigate(-1)} />
              <PageTitle prediction={api.items[0]} symbol={api.items[0].stockSymbol} />
            </div>

            <div className="my-3" ref={refCardParent}>
              <PredictionLazy
                isAltStyle={true}
                prediction={api.items[0]} cardWidth={cardParentWidth}
                refresh={1}
              />
            </div>

            <div>
              <MorePredictionsSymbol prediction={api.items[0]} />
            </div>
          </>
        }
      </div>
    </>
  );
}

