import axios from "axios";
import { config } from "config";

class PublicApiService {
  constructor (
    protected domainPath: string
  ) {}


  async logUserEvent (data: Object, uniqueUserId: string): Promise<any> {
    const url = `${this.domainPath}/log-event?fg=${uniqueUserId}`;
    try {
      const response = await axios.post(url, data );
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error(error.message);
    }    
  }
}

const publicApiService = new PublicApiService(`${config.apiDomain}${config.apiBasePath}/user`);

export { publicApiService }