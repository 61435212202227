import { createContext } from 'react';
import { IStockSymbol } from 'interfaces/IStockSymbol';

export interface ISymbolsContext {
  status: number,
  symbols: IStockSymbol[],
  get: (symbol: string) => IStockSymbol
}

const SymbolsContext = createContext<ISymbolsContext | null>(null);

export { SymbolsContext }






