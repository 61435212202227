import { RoundCloseButton } from 'components/common/buttons/RoundCloseButton'
import React from 'react'

export const Disclaimer: React.FunctionComponent<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <>
      <div className="border border-color-lavender border-radius-15 p-4 bg-off-black no-hover" style={{ minHeight: "17rem" }}>

        <div className="d-flex justify-content-between align-items-center">
          <div className="fw-bold text-14 text-lavender">NEW!</div>
          <div onClick={onClose}><RoundCloseButton /></div>
        </div>

        <div className="d-flex flex-column">
          <div className="fw-bold">League Season 2 has begun!</div>
        </div>

        <div className="text-14 my-4 opacity-70">
          <p>
            Q1 has come to an end, and it's time to predict your way through the leagues once more!
          </p>
          <p>
            Participate in Season 2 to earn more cash prizes, as well as other rewards, which we will reveal soon.
          </p>
          <p>
            Good luck!
          </p>
        </div>
      </div>
    </>

  )
}
