import React, { useCallback, useEffect, useState } from 'react'
import { useAdminStockSymbols } from '_hooks/useAdminStockSymbols'
import useEscape from '_hooks/useEscape';
import { Helper } from '_utils';
import { ConsensusHelper } from '_utils/ConsensusHelper';
import { Spinner } from 'components/common/Spinner';
import { DateEx, StockDirectionType as DirectionType, FilterUserAverageScoreRange, IAutoTrade, OptionType, PointUpDown, StockHelper, StockSignalsEnum } from 'predictagram-lib';
import { TradegramHelper } from '_utils/TradegramHelper';
import { useAdminAutoTradeSetupSingle } from '_hooks/useAdminAutoTradeSetupSingle';
import { getAnalysisInputParams, getLast30dScore } from '../common/Criteria';
import { StrategyFilterOptions } from '../analysis/strategy-profit/Filter';
import { TradeSetupFilterOptions } from './TradeSetupForm';
import { ConsensusChart } from 'components/public/Stock/ConsensusChart';
import { CumulativeChartSignal, ICumeDataSearchOptions, getChartOptions, getSignalDefaultLines, linesAndAnnotationsOptions } from '../common/helper';
import { ChartModel } from 'models/chart.model';
import { OrderTable } from './OrderTable';
import {CumulativeChart} from "../common/CumulativeChart";


export type PointUpDownOrders = PointUpDown & {
  actualOrderDate: number,
  contractPrice: number,
  action: "OPEN" | "CLOSE",
  optionName: string,
  optionType: OptionType
}

interface IProps {
  symbolId: number,
  onEscape: () => void,
  trades?: IAutoTrade[],
  tradeSetupId: number,
  isConsensusChart?: boolean,
}

export const ChartOverlay: React.FunctionComponent<IProps> = ({ symbolId, trades, onEscape, tradeSetupId, isConsensusChart = false }) => {
  const { api, getById } = useAdminStockSymbols();

  const { api: tradeSetupApi, signals } = useAdminAutoTradeSetupSingle(tradeSetupId);

  const [orders, setOrders] = useState<PointUpDownOrders[] | undefined>(undefined);
  const [symbolName, setSymbolName] = useState<string>('');

  const consensusDate = StockHelper.workingHours(ConsensusHelper.getConsensusDate(new DateEx()));
  const consensusStartSec = consensusDate.start.getTimeSec();
  const consensusEndDateSec = consensusDate.end.getTimeSec();

  const [cumeOptions, setCumeOptions] = useState<ICumeDataSearchOptions | undefined>(undefined);

  useEffect(() => {
    if (tradeSetupApi.apiState.isLoaded) {
      const tradeSetup = tradeSetupApi.data;
      const analysisInputParams = getAnalysisInputParams(tradeSetup.analysisSetup as TradeSetupFilterOptions);
      const strategyFilterOptions: Partial<StrategyFilterOptions> = {
        ...analysisInputParams,
      }
      const cumeOptions: ICumeDataSearchOptions = {
        startTimePredMade: consensusStartSec,
        endTimePredMade: consensusEndDateSec,
        extraSymbolIds: [],
        predictionTypes: strategyFilterOptions?.predictionTypes,
        stockSymbolId: strategyFilterOptions?.stockSymbolId as number,
        userAverageScore: strategyFilterOptions?.userAverageScore,
        // signals: []
      }
      setCumeOptions(cumeOptions);
    }
  }, [consensusEndDateSec, consensusStartSec, tradeSetupApi.apiState.isLoaded, tradeSetupApi.data, signals])


  const duringConsensus = useCallback((timesec: number) => {
    return timesec >= consensusStartSec && timesec <= consensusEndDateSec
  }, [consensusEndDateSec, consensusStartSec]);

  const getAnnotationData = useCallback(async (symbolName: string) => {
    if (!trades || symbolName === '') {
      return;
    }

    const openOrders: PointUpDownOrders[] = [];
    const closeOrders: PointUpDownOrders[] = [];

    const openOrdersPromises = trades.map(async (trade: IAutoTrade) => {
      const filledOpenOrders = trade.ibkr.openOrders
        .filter(o => o.orderStatus === 'Filled' && duringConsensus(o.createdAt));

      const orders = await Promise.all(filledOpenOrders.map(async o => {
        const minute = Helper.roundDownEpochToMinute(trade.openAt);
        const option = TradegramHelper.parseOptionContractName(o.optionName);
        const optionType = option?.optionType || OptionType.CALL;
        return {
          x: minute.getTimeSec(),
          y: trade.openPrice, // quote.length > 0 ? quote[0].c : 0,
          isSellSignal: false,
          actualOrderDate: o.createdAt,
          contractPrice: o.orderPrice,
          action: "OPEN",
          optionName: o.optionName,
          optionType
        } as PointUpDownOrders;
      }));
      openOrders.push(...orders);
    });


    const closeOrderPromises = trades.map(async (trade: IAutoTrade) => {
      const filledOrders = trade.ibkr.closeOrders
        .filter(o => o.orderStatus === 'Filled' && duringConsensus(o.createdAt));

      const filledOpenOrders = trade.ibkr.openOrders
        .filter(o => o.orderStatus === 'Filled' && duringConsensus(o.createdAt));

      const orders = await Promise.all(filledOrders.map(async o => {
        const minute = Helper.roundDownEpochToMinute(trade.closeAt);
        const optionName = filledOpenOrders.find(openOrder => openOrder.orderContractId === o.orderContractId)?.optionName || 'n/a';
        const option = TradegramHelper.parseOptionContractName(optionName);
        const optionType = option?.optionType || OptionType.CALL;
        return {
          x: minute.getTimeSec(),
          y: trade.closePrice, //quote.length > 0 ? quote[0].c : 0,
          isSellSignal: true,
          actualOrderDate: o.createdAt,
          contractPrice: o.orderPrice,
          action: "CLOSE",
          optionName: optionName,
          optionType
        } as PointUpDownOrders;
      }));
      closeOrders.push(...orders);
    });

    await Promise.all([...openOrdersPromises, ...closeOrderPromises]);

    return [
      ...openOrders,
      ...closeOrders
    ];
  }, [trades, duringConsensus]);

  const load = useCallback(async (symbolName: string) => {
    const annots = await getAnnotationData(symbolName);
    setSymbolName(symbolName);
    setOrders(annots);
  }, [getAnnotationData])

  useEffect(() => {
    if (api.apiState.isLoaded) {
      const symbol = getById(symbolId);
      load(symbol?.name || '');
    }
  }, [load, api.apiState.isLoaded, symbolId])


  useEscape(() => {
    onEscape()
  });

  const render = () => {
    if (api.apiState.isLoading) {
      return <Spinner minHeight={25} />
    }

    if (api.apiState.isLoaded) {
      if (symbolName) {
        const sigs = [
            ...(signals && signals.openSignals ? [...signals.openSignals] : []),
            ...(signals && signals.closeSignals ? [...signals.closeSignals] : []),
        ];
        // render lines which are related to the signals
        const showLines = getSignalDefaultLines(Helper.dedupeArray<StockSignalsEnum>(sigs));
        const extraSignals: CumulativeChartSignal[] = !orders ? [] : orders.map(order => {
          const arrow = ChartModel.getArrow(order.isSellSignal, order.optionType);
          const cumeSignal: CumulativeChartSignal = {
            direction: arrow.direction === "up" ? DirectionType.UP : DirectionType.DOWN,
            x: order.x,
            y: order.y,
            color: arrow.color === "green" ? "#0165fc" : "#FFEA00"
          }
          return cumeSignal;
        })

        return <div>
          <div className="p-3 text-white" >
            {isConsensusChart ? <>
              <ConsensusChart
                heightOverride={450}
                stockSymbol={symbolName}
                predictionTypes={[]}
                showPredictors={false}
                isCandle={true}
                moreAnnotationData={orders}
              />
            </> : <>
              {cumeOptions && <>
                <div style={{ width: "100%", height: "500px" }}>
                  <CumulativeChart
                    cumeDataSearchOptions={cumeOptions}
                    params={{
                      // showBaseSignals:[], //showBaseSignals,
                      showLines,
                      extraSignals,
                      showOptions: getChartOptions(linesAndAnnotationsOptions),
                    }}
                  />
                </div>
              </>}
            </>}
          </div>
          <div className="mt-3" >
            <div className="d-flex gap-3">
              <div className="d-flex flex-column gap-2">
                <div className="fw-bold text-16 bg-green p-2">Open</div>
                <OrderTable orders={orders?.filter(o=>o.isSellSignal===false)} />
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="fw-bold text-16 bg-red p-2">Close</div>
                <OrderTable orders={orders?.filter(o=>o.isSellSignal===true)} />
                </div>

            </div>
          </div>
        </div>
      }
    }


  }

  return (
    <div>
      <div className="bg-black" style={{ width: '100%', height: '525px' }}>
        {render()}
      </div>

    </div>
  )
}
