import React, { useContext, useState } from 'react';
import { AccountContext, ConfirmCheck, FieldWithError, LoginForm, ModalDoc } from 'components/common';
import { IMessage, MessengerContext, Severity } from 'components/common/messenger';
import { Form, Formik } from 'formik';
import { IAccount, ICredentials } from 'interfaces';
import { validationMap, Validator } from 'schemas';
import { useUniqueUserId } from '_hooks/useUniqueUserId';
import { ModalForgotPassword } from './ModalForgotPassword';
import { MessageHelper } from '_utils/MessageHelper';
import { useSearchParams } from 'react-router-dom';
import { Terms } from 'components/common/Terms';
import { useReferral } from '_hooks/useReferral';
import ReCAPTCHA from 'react-google-recaptcha';
import { config } from 'config';
import { RegistrationStep } from './FullRegistration';

const Login: React.FunctionComponent<{
  loginCallback: () => void,
  onClickRegister: () => void,
  onLoginFail: (message: IMessage) => void,
}> = ({ loginCallback, onClickRegister, onLoginFail }) => {

  const acctContext = useContext(AccountContext);
  const [showForgot, setShowForgot] = useState<boolean>(false);
  const uniqueUserId = useUniqueUserId();

  const initialFormState: ICredentials = {
    email: "",
    password: "",
  };

  const validationSchema = validationMap.get(Validator.LOGIN_FORM);

  const onSubmit = async (data: any, actions: any) => {
    const { email, password } = data;

    try {
      const account: IAccount | undefined = await acctContext?.login({ email, password, fg: uniqueUserId });
      if (!account) {
        throw Error('Could not get account');
      }
      loginCallback();
    } catch (error: any) {
      console.error({ error });
      onLoginFail({ body: `Login failed: ${error.message}` });
    }
  };

  return (
    <div className="mx-3">
      <ModalForgotPassword show={showForgot} handleClose={() => setShowForgot(false)} />
      <LoginForm
        onClickRegister={onClickRegister}
        initialFormState={initialFormState}
        validationSchema={validationSchema}
        onClickForgot={() => setShowForgot(true)}
        onSubmit={onSubmit} />
    </div>
  );
}


export const ShortRegisterForm: React.FunctionComponent<{
  onRegisterCallback: () => void,
  onLoginCallback: () => void,
  onLoginClick?: () => void,
  buttonLabel?: string,
  hasLogin?: boolean,
  skipFg?: boolean,
  darkMode?: boolean,
  initialIsLogin?: boolean,
  loginTitle?: JSX.Element | string,
  registerTitle?: JSX.Element | string
 
}> = ({ 
  onRegisterCallback,
  onLoginCallback,
  onLoginClick,
  buttonLabel = 'Create Account and Save',
  hasLogin = true,
  skipFg = false,
  darkMode = true,
  initialIsLogin = false,
  loginTitle,
  registerTitle
  }) => {

  const initialValues = {
    email: '',
    username: '',
    agreeTerms: false,
    recaptcha: '',
    ctoken: '',
  }

  const acctContext = useContext(AccountContext);
  const msgrContext = useContext(MessengerContext);

  const [showLogin, setShowLogin] = useState<boolean>(initialIsLogin);

  const validationSchema = validationMap.get(Validator.SHORT_REGISTER_FORM);
  const uniqueUserId = useUniqueUserId();
  const [searchParams] = useSearchParams();
  const adbTransId = searchParams.get('tid'); // adblade transaction id
  const referral = useReferral();
  const [regStep, setRegStep] = useState<RegistrationStep>(RegistrationStep.ENTER_REG_DATA);

  const _setShowLogin = (value: boolean) => {
    setShowLogin(value);
    if (onLoginClick) {
      onLoginClick();
    }

  }

  const onSubmit = async (data: any, actions: any) => {
    if (regStep === RegistrationStep.ENTER_REG_DATA) {
      setRegStep(RegistrationStep.RECAPTCHA);
      return;
    }

    try {
      // register
      if (acctContext) {
        await acctContext.register({
          email: data.email,
          nickname: data.username,
          username: data.username,
          fg: skipFg ? undefined : uniqueUserId,
          adbTransId: adbTransId === null ? undefined : adbTransId,
        }, data.ctoken, referral)
        onRegisterCallback();
      } else {
        throw Error('Missing Account Context');
      }
    } catch (error: any) {
      setRegStep(RegistrationStep.ENTER_REG_DATA);
      msgrContext.setMessage({ body: `${MessageHelper.translate(error.message)}` }, true, Severity.FATAL);
    }
  }

  const onLoginFail = (message: IMessage) => {
    msgrContext.setMessage(message, true, Severity.FATAL);
  }

  const [showToC, setShowToC] = useState<boolean>(false);

  const onClickToC = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowToC(true);
  };

  return (
    <>
      {!showLogin && <>
        <>{registerTitle && <>{registerTitle}</>}</>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ errors, touched, values, status, isSubmitting, setFieldValue }) => {
            return <Form>
              <div className="d-flex flex-column justify-content-start align-items-center mx-4">
                {regStep === RegistrationStep.ENTER_REG_DATA &&
                  <>
                    <div className="mt-3 w-100">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="email"
                        placeholder="email@domain.com"
                        label="Email Address"
                        labelClassName="register-label"
                      />
                    </div>
                    <div className="mt-3 w-100">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="username"
                        placeholder="username"
                        label="@username"
                        labelClassName="register-label"
                        maxLength={15}
                      />
                    </div>

                    <div className="my-3">
                      <ModalDoc show={showToC} handleClose={() => setShowToC(false)} title="Terms and Conditions">
                        <Terms isModal={true} />
                      </ModalDoc>
                      <ConfirmCheck name="agreeTerms" errors={errors} touched={touched} className={`${darkMode ? 'bg-black text-white' : 'bg-white text-black'}`}>
                        <div className="agree-terms ">
                          I understand that this is not financial advice and I have read and accept the{" "} <a role="button" className="btn-link" onClick={onClickToC}>
                            Terms and Conditions
                          </a>
                        </div>
                      </ConfirmCheck>
                    </div>

                    <button className="w-100 btn btn-light-purple btn-curved-side text-white fw-bold p-2 mt-4" type="submit">Sign up</button>
                  </>
                }

                {regStep === RegistrationStep.RECAPTCHA &&
                  <div className="mt-3">
                    <ReCAPTCHA theme="dark" sitekey={config.recaptchaSiteKey} onChange={(token: string | null) => setFieldValue('ctoken', token)} />
                    <button type="submit" className={`w-100 btn btn-light-purple btn-curved-side text-white fw-bold p-2 mt-4 align-self-stretch ${(JSON.stringify(values) === '{}' || JSON.stringify(errors) !== '{}') ? ' register-btn-create-account-disabled' : ''}`} disabled={isSubmitting}>{buttonLabel}</button>
                  </div>
                }

                {hasLogin &&
                  <div className="mt-3 mb-2 align-self-start">
                    <div className="d-flex flex-row gap-1 mx-2">
                      <div className="register-action">Already have an account? </div>
                      <div role="button" className="register-link" onClick={() => _setShowLogin(true)}>Log in</div>
                    </div>
                  </div>
                }
              </div>
            </Form>
          }}
        </Formik>
        </>
      }

      {showLogin &&
        <>
        {loginTitle && <>{loginTitle}</>}
        <Login onClickRegister={() => _setShowLogin(false)} loginCallback={onLoginCallback} onLoginFail={onLoginFail} />
        </>
      }

    </>
  );

}