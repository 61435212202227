import React from 'react';
import { Modal } from "react-bootstrap";
import { Settings } from './Settings';

interface IProps {
  show: any;
  handleClose: any;
}

export const ModalSettings: React.FunctionComponent<IProps> = ({
  show,
  handleClose,
}) => {


  const _handleClose = () => {
    handleClose();
  }


  return (
    <>
      <Modal show={show} onHide={_handleClose} size="lg" centered>
        <Modal.Body>
          <Settings closeHandler={_handleClose}/>
        </Modal.Body>
      </Modal>
    </>
  );
};

