import { RightArrow } from 'components/common/Icons';
import React from 'react'
import { BellFill, BellSlashFill } from 'react-bootstrap-icons'
import { ISignalSettings } from 'services/UserSignalApiService';

const RoundBellFill = () => {
  return (
    <div className="btn-circle bg-white d-flex justify-content-center align-items-center">
      <BellFill fill="#000" />
    </div>
  );
}

const RoundBellSlashFill = () => {
  return (
    <div className="btn-circle border bg-black d-flex justify-content-center align-items-center">
      <BellSlashFill fill="#fff" />
    </div>
  );

}

export const isEnabled = (signalSettings: ISignalSettings) => {
  return signalSettings.signalSendEmail ||
    signalSettings.signalSendNotification || 
    signalSettings.signalSendSms
}

export const SignalsSettings: React.FunctionComponent<{
  onClick: (signalSetting: ISignalSettings) => void,
  signalSettings: ISignalSettings[],
  setAll: (enabled: boolean) => void,
}> = ({ onClick, signalSettings, setAll }) => {

  return (
    <div className="user-signal-settings">
      <div className="d-flex justify-content-between my-3 gap-3 pb-2">
        <button className="btn btn-tertiary" onClick={()=>setAll(false)}>Disable All</button>
        <button className="btn btn-secondary" onClick={()=>setAll(true)}>Enable All</button>
      </div>

      <div className="user-signal-settings-symbols">
      {signalSettings.map((item, key) => <div key={`signal-item-${key}`}>
        <div className="d-flex justify-content-between my-3 pb-2 border-bottom border-color-gray"
          role="button"
          onClick={() => onClick(item)}
        >
          <div className="">{item.stockSymbol}</div>
          <div className="d-flex gap-2 align-items-center">
            <div className="">{item.signalEnable ? <RoundBellFill /> : <RoundBellSlashFill />}</div>
            <div className=""><RightArrow /></div>
          </div>

        </div>
      </div>)}
      </div>
    </div>
  )
}

