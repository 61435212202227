import React, { useContext, useEffect, useState } from 'react';
import { IPrediction } from 'interfaces/IPrediction';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { Emoji } from 'components/common/Emoji';
import { AccountContext } from 'components/common';
import { IAccount } from 'interfaces';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar';

export const PredictionUser: React.FunctionComponent<{ prediction: IPrediction }> = ({ prediction }) => {

  const [profile, setProfile] = useState<IPublicProfile>({} as IPublicProfile)
  const acctContext = useContext(AccountContext);
  const [acctPrev, setAcctPrev] = useState<IAccount | undefined>(acctContext?.account);
  const navigate = useNavigate();

  useEffect(() => {
    const _load = async () => {
      const isCacheOk = acctPrev === acctContext?.account;
      const profile = await userPredictionApiService.getPublicProfile(prediction.userId || 0, isCacheOk);
      if (!isCacheOk) {
        setAcctPrev(acctContext?.account);
      }
      setProfile(profile);
    }
    _load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prediction.userId, acctContext])

  if (!profile.userId) {
    return <div className="predictor-thumb shimmer"></div>
  }

  return (
    <div className="d-flex predictor-detail">
      <div className="predictor-thumb flex-shrink-0">
        <a className="text-decoration-none" href={`/stock/predictor/${prediction.userId}`}>{profile && <Avatar size={AvatarSizeEnum.MEDIUM} profile={profile}/>}</a>
      </div>
      <div className="d-flex flex-column justify-content-start mx-3">
        <div className="d-flex flex-row flex-wrap justify-content-start align-items-center gap-1">
          <div className="predictor-name"><a href={`/stock/predictor/${prediction.userId}`}>{profile?.nickname}</a> </div>
          <div className="predictor-at"><a href={`/stock/predictor/${prediction.userId}`}>@{profile?.username}</a> </div>
        </div>

        <div className="d-flex justify-content-start align-items-center gap-2">
          {profile?.predictions && (profile.predictions.averageScore || 0) > 0 &&
            <div className="predictor-accuracy" title="Overall Prediction Accuracy Rate"><Emoji symbol="🎯" text={""} label="accuracy" />
              {((profile?.predictions.averageScore || 0) * 100).toFixed(0)}%
            </div>
          }

          {profile && profile.team &&
            <div className="predictor-team" role="button" onClick={() => navigate(Url.USER_TEAM_DETAIL.replaceAll(':teamId', String(profile.team?.id)))}>
              <img src={profile.team.logoUrl} alt={profile.team.name} title={profile.team.name} />
            </div>
          }

          {profile.awards && profile.awards.length > 0 && <div className="predictor-award"><img src={profile.awards[0].imageUrl} alt="award" title={profile.awards[0].description} /></div>}

        </div>

      </div>
    </div>
  )
}