import { IContest } from "interfaces/IContest";
import * as Icons from "components/common/Icons";
import { ContestHelper } from "_utils/ContestHelper";
import { ContestState } from "_constants/ContestState";
import { Helper } from "_utils";

export const ContestStats: React.FunctionComponent<{ contest: IContest }> = ({ contest }) => {

  const daysLeft = (contest: IContest): string => {

    const today = new Date();
    const contestState = ContestHelper.getContestState(contest, today);
  
    if (contestState === ContestState.OPEN) {
      return `${ContestHelper.getTimeUntilClose(contest, today)} left`;
    }
  
    if (contestState === ContestState.PRE_OPEN) {
      return `starts in ${ContestHelper.getTimeUntilOpen(contest, today)}`;
    }
  
    return 'Ended';
  
  
  }

  return (
    <div className="contest-details-stats my-3">
      <div className="contest-details-stats-daysleft"><Icons.Clock />{daysLeft(contest)}</div>
      <div className="contest-details-stats-contestants"><Icons.User />{contest.contestants} {Helper.pluralize('contestant', contest.contestants || 0)}</div>
      {contest.prize && contest.prize > 0 &&
      <div className="contest-details-stats-prize"><Icons.MoneyBag width={15} height={18} fill="#ffffff"/> ${contest.prize} Prize</div>
      }
    </div>
  );
}