import React, { useContext, useState } from 'react'
import { useStreakStats } from '_hooks/useStreakStats';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import { LevelProgress } from './LevelProgress';
import { StreakBadge } from './StreakBadge';
import { TicketsBadge } from './TicketsBadge';
import { MyStatsBadge } from './MyStatsBadge';
import { AccountContext } from 'components/common';
import { usePublicProfile } from '_hooks/usePublicProfile';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { ModalStreak } from './ModalStreak';
import { useApiAchievementUserStats } from '_hooks/useApiAchievementUserStats';
import { IUserAchievementUserStatSearchFilter } from 'services/UserAchievementService';
import { SeasonContext } from 'components/common/SeasonContext';


export const LevelBadges: React.FunctionComponent = () => {

  const streakApi = useStreakStats();
  const acctContext = useContext(AccountContext);
  const publicProfile = usePublicProfile(Number(acctContext?.account.id), userPredictionApiService);
  const seasonContext = useContext(SeasonContext);

  const initialFilter: IUserAchievementUserStatSearchFilter = {
    endTime: seasonContext?.season?.endDateSec,
    startTime: seasonContext?.season?.startDateSec,
    userId: acctContext?.account.id
  };

  const userStatsApi = useApiAchievementUserStats(initialFilter);

  const [showStreak, setShowStreak] = useState<boolean>(false);

  if (streakApi.apiState.isLoading || userStatsApi.apiState.isLoading) {
    return <Spinner minHeight={62} />
  }

  if (streakApi.apiState.isLoaded && userStatsApi.apiState.isLoaded) {

    return (
      <>
      <ModalStreak show={showStreak} handleClose={()=>setShowStreak(false)} streakCount={streakApi.data.days}/>
      <div className="d-flex justify-content-between gap-2 bg-black">
        <div className="d-flex justify-content-center gap-3 ms-md-2">
          <LevelProgress userStat={userStatsApi.data[0]} />
          <StreakBadge streakStat={streakApi.data} onClick={()=>setShowStreak(true)}/>
          <TicketsBadge />
        </div>

        <div>
          {publicProfile.loaded ?
            <MyStatsBadge profile={publicProfile.item} />
            :
            <Spinner minHeight={62} />
          }
        </div>
      </div>
      </>
    );
  }

  if (streakApi.error) {
    return <ErrorMessage>{streakApi.error}</ErrorMessage>
  }

  if (userStatsApi.error) {
    return <ErrorMessage>{userStatsApi.error}</ErrorMessage>
  }

  return (<></>)


}
