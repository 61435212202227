import { config } from "config";
import { AuthenticationService, userAuthenticationService } from "./AuthenticationService";
import { BrokerSecurityTypeEnum, BrokerTransactionTypeEnum } from "predictagram-lib";
import { AbortableHttpService } from "./AbortableHttpService";
import { BrokerTransactionVerificationStatusEnum } from "_constants/BrokerTransactionVerificationStatusEnum";
import { IAPIPagedResponse } from "interfaces/IAPIPagedResponse";

export class TradegramApiServiceError extends Error {}

export interface ITradegramResponseItem {
  securityId: number,
  price: number,
  quantity: number,
  id: number
}

export interface ITradegramParams {
  securityTypeId: BrokerSecurityTypeEnum,
  stockSymbol: string,
  stockSymbolOption: string,
  transactionTypeId: BrokerTransactionTypeEnum,
  price: number,
  quantity?: number,
  transactionDescription?: string,
}

export interface ITradegramSecurity {
  id: number,
  typeId: BrokerSecurityTypeEnum,
  userId: number,
  stockSymbolOption: string,
  stockSymbol: string,
  openCost: number,
  openQuantity: number,
  closeCost: number,
  closeQuantity: number,
  profit: number,
  profitPct: number,
  transactions: number,
  transactionsRejected: number,
  lastTransaction: ITradegramTransaction,

}

export interface ITradegramTransaction {
  typeId: BrokerTransactionTypeEnum,
  quantity: number,
  price: number,
  createdAt: number,
  profit: number,
  profitPct: number,
  stockPrice: number,
  description: string,
  verificationStatusId: BrokerTransactionVerificationStatusEnum
}

export interface ITradegramTotalsParam {
  symbolName?: string,
  userId?: number,
}

export interface ITradegramSymbolTotal {
  stockSymbol: string,
  openCost: number,
  openQuantity: number,
  closeCost: number,
  closeQuantity: number,
  profit: number,
  transactions: number
  securities?: number,
}

export interface ITradegramTotalsResponseItem extends ITradegramSymbolTotal {
  typeId: BrokerSecurityTypeEnum,
}

export interface ITradegramSecuritiesFilter {
  userId: number,
  limit: number,
  pages: {
    current: number
  }
}

export class TradegramApiService {
  constructor(
    protected apiBaseUrl:string, 
    protected authenticationService: AuthenticationService,
    protected httpService: AbortableHttpService
  ) { }


  /** paginated responses */
  async getMySecurities(filter?: ITradegramSecuritiesFilter, abortSignal?: AbortSignal): Promise<IAPIPagedResponse<ITradegramSecurity, number>> {
    const url = `${this.apiBaseUrl}/management/broker/securities`;
    return await this.httpService.fetchPost<ITradegramSecurity, number>(url, {filters: filter, pages: filter?.pages}, abortSignal);
  }

  async getMySecuritiesByTop(filter?: ITradegramSecuritiesFilter, abortSignal?: AbortSignal): Promise<IAPIPagedResponse<ITradegramSecurity, number>> {
    const url = `${this.apiBaseUrl}/management/broker/securities/top`;
    return await this.httpService.fetchPost<ITradegramSecurity, number>(url, {filters: filter, pages: filter?.pages}, abortSignal);
  }


  async getMySecuritiesByFollowed(filter?: ITradegramSecuritiesFilter, abortSignal?: AbortSignal): Promise<IAPIPagedResponse<ITradegramSecurity, number>> {
    const url = `${this.apiBaseUrl}/management/broker/securities/following`;
    return await this.httpService.fetchPost<ITradegramSecurity, number>(url, {filters: filter, pages: filter?.pages}, abortSignal);
  }

  async getSecurities(filter?: ITradegramSecuritiesFilter, abortSignal? : AbortSignal): Promise<IAPIPagedResponse<ITradegramSecurity, number>> {
    const url = `${this.apiBaseUrl}/broker/securities`;
    return await this.httpService.fetchPost<ITradegramSecurity, number>(url, {filters: filter, pages: filter?.pages}, abortSignal);
  }


  /** non-paginated responses  */

  async addTransaction(t: ITradegramParams): Promise<ITradegramResponseItem> {
    const url = `${this.apiBaseUrl}/management/broker/security/transaction`;
    return await this.httpService.httpPost<ITradegramResponseItem>(url, t);
  }

  async getMySecurityTrades(id: number, abortSignal?: AbortSignal): Promise<ITradegramTransaction[]> {
    const url = `${this.apiBaseUrl}/management/broker/security/transactions/${id}`;
    return await this.httpService.httpPost<ITradegramTransaction[]>(url, {}, abortSignal, false);
  }

  async getSecurityTrades(id: number, abortSignal?: AbortSignal): Promise<ITradegramTransaction[]> {
    const url = `${this.apiBaseUrl}/broker/security/transactions/${id}`;
    return await this.httpService.httpPost<ITradegramTransaction[]>(url, {}, abortSignal, false);
  }



  async getMySecuritiesTotals(params?: ITradegramTotalsParam, abortSignal?: AbortSignal): Promise<ITradegramTotalsResponseItem[]> {
    const url = `${this.apiBaseUrl}/management/broker/security/summary`;
    return await this.httpService.httpPost<ITradegramTotalsResponseItem[]>(url, params ? {"filter": params} : {}, abortSignal, false);
  }

  async getSecuritiesTotals(params: ITradegramTotalsParam, abortSignal?: AbortSignal): Promise<ITradegramTotalsResponseItem[]> {
    const url = `${this.apiBaseUrl}/broker/security/summary/${params.userId}`;
    return await this.httpService.httpPost<ITradegramTotalsResponseItem[]>(url, params ? {"filter": params} : {}, abortSignal, false);
  }

  async validatePrice(params: {symbolName: string, price: number}, abortSignal?: AbortSignal): Promise<{success:boolean}> {
    const url = `${this.apiBaseUrl}/management/broker/security/validate-price`;
    return await this.httpService.httpPost<{success: boolean}>(url, params, abortSignal, false)
  }

}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
export const tradegramApiService: TradegramApiService = new TradegramApiService(apiBaseUrl, userAuthenticationService, new AbortableHttpService(userAuthenticationService));

