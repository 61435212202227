import { BrokerSecurityTypeEnum, StockHelper } from 'predictagram-lib'
import React, { useContext, useState } from 'react'
import { AddEquity } from './AddEquity';
import { AddOption } from './AddOption';
import { ITradegramSecurity } from 'services/TradegramApiService';
import { CardContext } from '_utils/TradegramHelper';
import { CloseButton } from 'react-bootstrap';
import { MessengerContext } from 'components/common/messenger';
import { FileTextFill, Coin } from 'react-bootstrap-icons';
import { OptionShortCut } from './OptionShortCut';

interface IProps {
  handleClose: () => void,
  onSuccess: () => void,
  security?: ITradegramSecurity,
  cardContext?: CardContext
}
export const Wizard: React.FunctionComponent<IProps> = ({ handleClose, onSuccess, security, cardContext }) => {

  enum Step {
    SELECT_TYPE = 1,
    CHOOSE_SECURITY_TYPE = 2,
  }

  const msgrContext = useContext(MessengerContext);

  const [typeId, setTypeId] = useState<BrokerSecurityTypeEnum>(security?.typeId || BrokerSecurityTypeEnum.EQUITY);
  const [currentStep, setCurrentStep] = useState<Step>(Step.SELECT_TYPE);
  const [shortName, setShortName] = useState<string>('');

  const brokerTypeIs = (t: BrokerSecurityTypeEnum) => {
    return t === typeId;
  }

  const onClickEquity = () => {
    if (!StockHelper.isMarketOpen(new Date())) {
      msgrContext.setMessage({ body: <div className="mx-3">Trades cannot be made when market is closed. Please come back when market is open.</div> })
      return;
    }
    setTypeId(BrokerSecurityTypeEnum.EQUITY); setCurrentStep(currentStep + 1)
  }

  const SelectType = () => {
    return (
      <div className="mx-4 my-1">

        <div className="d-flex justify-content-between">
          <div className="text-18 fw-bold">Post a Trade</div>
          <div role="button" title="Close" className="fw-bold ms-auto text-white"><CloseButton variant="white" onClick={handleClose} /></div>
        </div>

        <div className="d-flex flex-column gap-3 my-3">
          <div role="button" className="d-flex justify-content-center align-items-center rounded bg-charcoal" onClick={onClickEquity}>
            <div className="d-flex justify-content-center flex-column align-items-center" style={{ height: "5.625rem" }}>
              <Coin />
              <div className="text-white fw-bold text-16">Shares</div>
            </div>
          </div>
          <div role="button" className="d-flex justify-content-center align-items-center rounded bg-charcoal" onClick={() => { setTypeId(BrokerSecurityTypeEnum.OPTION); setCurrentStep(currentStep + 1) }}>
            <div className="d-flex justify-content-center flex-column align-items-center" style={{ height: "5.625rem" }}>
              <FileTextFill />
              <div className="text-white fw-bold text-16">Options</div>
            </div>
          </div>
        </div>

        <div style={{ width: "100%", position: "relative" }}>
          <div style={{ borderTop: "solid 1px white", position: "absolute", top: "50%", width: "100%" }}></div>
          <div className="d-flex justify-content-center"><div className="text-center bg-off-black d-inline px-3" style={{ zIndex: 100 }}>or</div></div>
        </div>

        <OptionShortCut shortName={shortName} setShortName={setShortName} onSet={()=>{
          setTypeId(BrokerSecurityTypeEnum.OPTION); 
          setCurrentStep(Step.CHOOSE_SECURITY_TYPE)
        }}/>

      </div>


);
  }

  const _onSuccess = () => {
    onSuccess();
    handleClose();
  }

  const ChooseSecurityType = () => {
    return (
      <div className="h-100">
        {brokerTypeIs(BrokerSecurityTypeEnum.EQUITY) &&
          <AddEquity onSuccess={_onSuccess} onCancel={() => { setCurrentStep(Step.SELECT_TYPE) }} cardContext={cardContext} security={security} />
        }

        {brokerTypeIs(BrokerSecurityTypeEnum.OPTION) &&
          <AddOption onSuccess={_onSuccess} onCancel={() => { setCurrentStep(Step.SELECT_TYPE); setShortName(''); }} cardContext={cardContext} security={security} shortName={shortName}/>
        }

      </div>
    );
  }

  const wizard: Map<Step, JSX.Element> = new Map<Step, JSX.Element>([
    [Step.SELECT_TYPE, <SelectType />],
    [Step.CHOOSE_SECURITY_TYPE, <ChooseSecurityType />],
  ])

  if (cardContext && security) {
    if (brokerTypeIs(BrokerSecurityTypeEnum.EQUITY)) {
      return <div className="trade-wizard"><AddEquity onSuccess={_onSuccess} onCancel={handleClose} cardContext={cardContext} security={security}/></div>
    }
    return <div className="trade-wizard"><AddOption onSuccess={_onSuccess} onCancel={handleClose} cardContext={cardContext} security={security} shortName={shortName}/></div>
  }


  return (
    <div className="trade-wizard">
      {wizard.get(currentStep)}
    </div>

  )
}

