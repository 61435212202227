import React from 'react'
import { IPrediction } from 'interfaces/IPrediction'
import { Happening } from './Happening'

export const Happenings: React.FunctionComponent<{ predictions: IPrediction[] }> = ({ predictions }) => {

  return (
    <div className="happening mt-3">
      <div className="d-flex gap-3 happening-container-items flex-column">
        {predictions.map((p: IPrediction, i: number) => <div key={`prediction-key-${i}`}>
          <Happening prediction={p} isWide={true} />
        </div>)}
      </div>
    </div>
  )
}
