import { ILeagueSeason } from "interfaces/league";
import { useCallback, useEffect, useState } from "react";
import { UserLeagueApiService, UserLeagueApiServiceError } from "services/UserLeagueApiService";

export function usePreviousSeason (service: UserLeagueApiService) {

  const [ item, setItem ] = useState<ILeagueSeason | undefined>({} as ILeagueSeason);
  const [ loaded, setLoaded ] = useState<boolean>(false);
  const [ error, setError ] = useState<UserLeagueApiServiceError | undefined>(undefined);

  const load = useCallback(async ()=>{
    try {
      const item = await service.getPreviousSeason();
      setItem(item);
      setLoaded(true);
    } catch (error) {
      setError(error as UserLeagueApiServiceError);
    }
  }, [service])

  useEffect(()=>{
   load();
  }, [load])

  return {item, loaded, error};

}