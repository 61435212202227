import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ILeagueSeason, ITeam } from 'interfaces/league';
import { ITeamMemberStat } from 'interfaces/ITeamMemberStat';
import { ITeamMemberStatSearchOptions, useTeamMemberStats } from '_hooks/useTeamMemberStats';
import { userLeagueApiService } from 'services/UserLeagueApiService';
import { Spinner } from 'react-bootstrap';
import { Url } from '_constants';
import { Helper } from '_utils';
import { APIState } from '_constants/APIState';
import { IDateFilterTimeRange } from './leaderboards/components/DateFilters';
import { DateFilterEnum, TeamDateFilters } from './leaderboards/components/TeamDateFilter';
import { StockHelper } from '_utils/stock.helper';
import { config } from 'config';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar';
import { Helmet } from 'react-helmet';

export const MemberProfile: React.FunctionComponent<{ teamMemberStat: ITeamMemberStat, index: number }> = ({ teamMemberStat: item, index }) => {

  const navigate = useNavigate();
  const url = `${Url.PUBLIC_STOCK_PREDICTOR}/${item.userId}`;

  const { userId, avatarUrl, username } = item;
  const profile: IPublicProfile = {
    userId,
    avatarUrl,
    username
  } as IPublicProfile

  return (
    <>
      <div className="col-1 d-flex align-items-center">{index}</div>
      <div className="col-5 d-flex align-items-center">
        <div className="d-flex gap-2 align-items-center" role="button" onClick={() => navigate(url)}>
          <div className="team-roster-item-image"><Avatar profile={profile} size={AvatarSizeEnum.SMALL} /></div>
          <div className="team-roster-item-name">{item.username}</div>
        </div>
      </div>
      <div className="col-2 d-flex align-items-center team-roster-item-accuracy"><span role="img" aria-label="accuracy">🎯</span> {item.score === null ? "N/A" : `${(item.score * 100).toFixed(0)}%`}</div>
      <div className="col-4 d-flex align-items-center gap-1">{item.predictionsCompleted} <span className='text-smaller fw-normal'>{Helper.pluralize('prediction', Number(item.predictionsCompleted))}</span></div>
    </>
  );
}





export const TeamRoster: React.FunctionComponent = () => {

  const { season, team } = useOutletContext<{
    team: ITeam,
    season: ILeagueSeason
  }>();

  const searchOptions: ITeamMemberStatSearchOptions = {
    startTime: season.startTime,
    endTime: season.endTime,
    minPredictionsCompleted: 1,
  }

  const [dateFilter, setDateFilter] = useState<DateFilterEnum>(DateFilterEnum.THIS_SEASON);
  const [selectedSeason, setSelectedSeason] = useState<ILeagueSeason>(season);

  const { items: members, apiState, error, options, setOptions: setSearchOptions } = useTeamMemberStats(team.id, userLeagueApiService, searchOptions);

  const onClickCallback = (timeRange: IDateFilterTimeRange, dateFilter: DateFilterEnum, selectedSeason: ILeagueSeason) => {

    setSearchOptions({
      startTime: timeRange.startTime / 1000,
      endTime: timeRange.endTime ? timeRange.endTime / 1000 : undefined,
      minPredictionsCompleted: 1,
    })

    setDateFilter(dateFilter);
    setSelectedSeason(selectedSeason);

  }

  const byScore = (a: ITeamMemberStat, b: ITeamMemberStat) => {

    const res = (b.score||0) - (a.score||0);

    // returned score is between [0.00,1.00] range with 2 decimal places
    // score is rendered as score*100 with 0 decimal places
    if (Math.abs(res)>0.001) {
      return res;
    }
    return (b.predictionsCompleted||0)-(a.predictionsCompleted||0);
  }


  const getMinimumPredictions = () => {

    if (dateFilter === DateFilterEnum.SEVEN_DAYS) return 10;

    if ([DateFilterEnum.TODAY, DateFilterEnum.YESTERDAY].includes(dateFilter)) return 2;

    const startDate = new Date(options.startTime * 1000);
    if (options.endTime) {
      let endDate = new Date(options.endTime * 1000);
      if (options.endTime * 1000 >= Date.now()) { // current season
        endDate = new Date();
        const marketDaysElapsed = StockHelper.getMarketDaysElapsed(startDate, endDate);
        return marketDaysElapsed === 0 ? 2 : marketDaysElapsed * 2;
      }
      return 50; // previous seasons
    } 

    return 2; // default to 2
  }

  const getTiers = (members: ITeamMemberStat[], dateFilter: DateFilterEnum, season: ILeagueSeason) => {

    let minimumPredictions = getMinimumPredictions();

    const top = members
      .filter((t: ITeamMemberStat) =>
        t.predictionsCompleted !== null
        && t.score !== null
        && t.score >= (season.teamMemberMinScore || 0)
        && t.predictionsCompleted >= minimumPredictions)
      .sort(byScore);

    const bottom = members
      .filter((t: ITeamMemberStat) =>
        t.predictionsCompleted !== null
        && t.score !== null
        && t.score >= (season.teamMemberMinScore || 0)
        && t.predictionsCompleted < minimumPredictions)
      .sort(byScore);

    const unmet = members
    .filter((t: ITeamMemberStat) =>
      t.predictionsCompleted !== null
      && t.score !== null
      && t.score < (season.teamMemberMinScore || 0)
      )
    .sort(byScore);

    return [top, bottom, unmet];
  }

  const [ top, bottom, unmet ] = getTiers(members, dateFilter, selectedSeason);


  const Debugger = () => {
    if (config.environment !== 'development') return <></>
    return  <div style={{position: 'absolute', left: "0", bottom: "0", width: "200px", height: "auto"}}>
        <div>Tier 1 Requirement: {getMinimumPredictions()}</div> 
        <div>dateFilter: {dateFilter}</div>
        <div>selectedSeason: <pre>{JSON.stringify(selectedSeason, null, 2)}</pre></div>
    </div>
  }

  const getOpacity = (season: ILeagueSeason, m: ITeamMemberStat) => {
    if (![DateFilterEnum.THIS_SEASON, DateFilterEnum.SEASON].includes(dateFilter)) return;

    return season.teamMemberMinScore && (m.score || 0) < season.teamMemberMinScore ? "opacity-40" : "";
  }


  return (

    <>
      <div className="my-3">
        <Debugger />
        <TeamDateFilters season={season} onClick={onClickCallback} initialDateRange={DateFilterEnum.THIS_SEASON} showSeasonFilter={false}/>
      </div>

      <div className="team-roster container">

        {apiState === APIState.LOADING && <Spinner />}

        {apiState === APIState.LOADED &&
          <Helmet>
            <title>Predictagram: Roster - {team.name}</title>
          </Helmet>
        }

        {apiState === APIState.LOADED && <>{members.length === 0 && <div className="d-flex justify-content-center align-items-center m-5 contest-anon">Nothing to display.</div>}</>}

        {apiState === APIState.LOADED && top.length > 0 && top.map((m: ITeamMemberStat, i: number) => <div className={`row my-1 py-2 team-roster-item ${getOpacity(selectedSeason, m)}`} key={`roster-key-${i}`}>
          <MemberProfile teamMemberStat={m} index={i + 1} />
        </div>)
        }

        {apiState === APIState.LOADED && bottom.length > 0 && bottom.map((m: ITeamMemberStat, i: number) => <div className={`row my-1 py-2 team-roster-item ${getOpacity(selectedSeason, m)}`} key={`roster-key-${i}`}>
          <MemberProfile teamMemberStat={m} index={i + top.length + 1} />
        </div>)
        }

        {apiState === APIState.LOADED && unmet.length > 0 && unmet.map((m: ITeamMemberStat, i: number) => <div className={`row my-1 py-2 team-roster-item ${getOpacity(selectedSeason, m)}`} key={`roster-key-${i}`}>
          <MemberProfile teamMemberStat={m} index={i + top.length + bottom.length + 1} />
        </div>)
        }


        <div className="bg-charcoal p-2 rounded my-3 text-lighter-gray">Grayed-out users have not yet met the season requirements.</div>

        {error && <div>{error.message}</div>}

      </div>
    </>)
}
