import { Emoji } from 'components/common/Emoji'
import { IPublicProfile } from 'interfaces/IPublicProfile'
import React from 'react'

export const PredictorAccuracy:React.FunctionComponent<{profile: IPublicProfile}> = ({profile}) => {
  return (
    <div className="predictor-accuracy d-flex justify-content-center align-items-center gap-3">
      <div>
        <div className={`fw-bold text-16`}><Emoji symbol="🎯" text={""} label="accuracy" /><span>{((profile.predictions.averageScore || 0) * 100).toFixed(0)}%</span></div>
        <div className="text-lighter-gray text-10">accuracy</div>
      </div>

      <div>
        <div className={`fw-bold text-16`}>{profile.predictions.completed}</div>
        <div className="text-lighter-gray text-10">predictions</div>
      </div>

    </div>
  )
}

