import React, { useState } from 'react'
import { ProgressBar, getRgb } from './ProgressBar'
import { Emoji } from 'components/common/Emoji'
import { IUserStatsStockSymbolResponse } from 'services/UserPredictionApiService'
import { RightChevron } from 'components/common/Icons'
import { useNavigate } from 'react-router-dom'
import { UrlHelper } from '_utils/UrlHelper'

export const SymbolStat: React.FunctionComponent<{ stat: IUserStatsStockSymbolResponse, userId: number}> = ({ stat, userId}) => {
  const score = parseInt((stat.scoreAvg * 100).toFixed(0));
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const barStyle: React.CSSProperties = {
    backgroundColor: isHovered ? getRgb(Math.round(stat.scoreAvg * 100), 0.04) : "#000000",
    borderColor: isHovered ? "#343434" : "#111111"
  }

  const outerStyle:React.CSSProperties = {
    borderColor: isHovered ? "#343434" : "#111111",
  }

  const buttonStyle:React.CSSProperties = {
    backgroundColor: isHovered ? "#343434" : "#111111"
  }

  return (
    <div role="button" 
        style={outerStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={()=>navigate(UrlHelper.getUserStatTypes(userId, stat.stockSymbol))} 
        className="user-stat-card d-flex justify-content-between align-items-center w-100">

      <div className="user-stat-card-bar flex-grow-1 w-100 p-3" style={barStyle}>
        <div className="d-flex justify-content-start align-items-center user-stat-card-bar-progress gap-3">
          <div className="fw-bold">{stat.stockSymbol}</div>
          <div className="fw-bold"><Emoji symbol="🎯" text={""} label="accuracy" />{score}%</div>
          <div className="text-gray text-11">{stat.predictionsCompleted} predictions</div>
        </div>
        <div style={{ height: "0.625rem" }} className="user-stat-card-bar-progress"><ProgressBar progress={score} /></div>
      </div>

      <div className="user-stat-card-button" style={buttonStyle}>
          <RightChevron color={isHovered ? "black" : "white"}/>
      </div>
    </div>
  )
}
