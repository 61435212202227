import React, { useEffect } from 'react';
import { Toast, ToastContainer } from "react-bootstrap";
import { IMessage } from './IMessage';
import { Severity } from './MessengerProvider';

export const ToastMessage: React.FunctionComponent<{ show: any; handleClose: any; message: IMessage; severity?: Severity}> = ({
  show,
  handleClose,
  message,
  severity = Severity.NORMAL
}) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [message])

  const severityColorMap = new Map<Severity, string>([
    [Severity.FATAL, "bg-red"],
    [Severity.NORMAL, "bg-medium-purple"],
    [Severity.WARNING, "bg-yellow"],
  ])

  return (
    <ToastContainer position="top-center">
      <Toast onClose={handleClose} show={show} bg="dark" className={`${severityColorMap.get(severity)} text-black fw-bold`} delay={10000} autohide>
        {message.title &&
          <Toast.Header closeButton={false} >
            {message.title}
          </Toast.Header>
        }
        <Toast.Body>
          <div className="d-flex flex-row align-items-center">
            <div className="flex-grow-1">{message.body}</div>
            <div className="flex-grow-0"><div role="button" className="fw-bold ms-3" onClick={handleClose}>X</div></div>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

