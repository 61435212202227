import { StreakLevels } from "predictagram-lib";
import { IUserAchievementUserStatResponse } from "services/UserAchievementService";

export class BadgeHelper {
  
  static getLevelProgress (userStat: IUserAchievementUserStatResponse) {
    const points = userStat ? userStat.points : 0;
    const levelInfo = StreakLevels.levelsInfo( points);
    const currentLevel = levelInfo.current.level;
    const levelMin = levelInfo.current.minPoints;
    const levelMax = levelInfo.next ? levelInfo.next.minPoints - 1 : null;
    const progress = levelMax === null ? 100 : points / levelMax * 100;

    return {
      progress,
      levelMin,
      levelMax,
      currentLevel,
      levelInfo
    }
  
  }


}