import { APIState } from "_constants/APIState";
import { ITeamMemberStat } from "interfaces/ITeamMemberStat";
import { useCallback, useEffect, useRef, useState } from "react";
import { UserLeagueApiService, UserLeagueApiServiceError } from "services/UserLeagueApiService";


export interface ITeamMemberStatSearchOptions {
  startTime: number,
  endTime?: number,
  minPredictionsCompleted?: number,
}

export function useTeamMemberStats (teamId: number, service: UserLeagueApiService, searchOptions: ITeamMemberStatSearchOptions) {

  const [ items, setItems ] = useState<ITeamMemberStat[]>([]);
  const [ apiState, setApiState ] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<UserLeagueApiServiceError | undefined>(undefined);

  const serviceRef = useRef(service);
  const [ options, setOptions ] = useState<ITeamMemberStatSearchOptions>(searchOptions);

  const load = useCallback(async ()=>{
    try {
      setApiState(APIState.LOADING);
      const items = await serviceRef.current.getTeamMembersStats(teamId, options);
      setItems(items);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR)
      setError(error as UserLeagueApiServiceError);
    }
  }, [serviceRef, options, teamId])

  useEffect(()=>{
   load();
  }, [load])

  return {items, apiState, error, options, setOptions};

}