import { useEffect, useState } from 'react';

const useResize = (refElement: React.RefObject<HTMLDivElement>) => {

    const [size, setSize] = useState<number>(0);

    useEffect(() => {
        const handleResize = (event: any) => {
            if (refElement.current) {
                setSize(refElement.current.offsetWidth);
            }
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [refElement]);

    return [size];
}

export default useResize