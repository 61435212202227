import { useCallback, useEffect, useState } from "react";
import { BaseApiService } from "services";

export function useApiService<T> (service: BaseApiService<T>, 
  id?: number, withAuth: boolean = true, isPost: boolean = true) {

  const [ items, setItems ] = useState<T[]>([])
  const [ item, setItem ] = useState<T>({} as T)
  const [ loaded, setLoaded ] = useState<boolean>(false);
  const [ error, setError ] = useState<Error | undefined>(undefined);

  const load = useCallback(async ()=>{
    try {
      if (id) {
        const item = await service.getById(id)
        setItem(item);
      } else {
        const items = await service.getAll(withAuth, isPost);
        setItems(items);
      }
      setLoaded(true);
    } catch (error) {
      setError(error as Error);
    }
  }, [id, service, isPost, withAuth])

  useEffect(()=>{
   load();
  }, [load, id])

  if (id) {
    return {item, loaded, error};
  }

  return {items, loaded, error};

}