import React from 'react'
import { LevelBadges } from '../badges/LevelBadges'
import { AutoSuggestSearch } from 'components/public/ListingsPage/components/AutoSuggestSearch'
import { Helper } from '_utils'
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService'
import { IFilteredPredictions, useFilteredPredictions } from '_hooks/useFilteredPredictions'
import { useDashboardSettings } from '_hooks/useDashboardSettings'
import { StorageKey } from '_constants'
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect'
import { Banner } from 'components/marketing/discount-promo/Banner'

export const SearchBadges = () => {

  const [dashboardSettings] = useDashboardSettings(localStorage, StorageKey.DASHBOARD_SETTINGS);

  const searchOptions: ISearchOptions = {
    symbolNames: dashboardSettings.selectedSymbols.length === 0 ? undefined : dashboardSettings.selectedSymbols,
    orderBy: sortTypeEnum.LATEST,
    limit: 12,
  }

  const filteredPredictions: IFilteredPredictions = useFilteredPredictions(userPredictionApiService, dashboardSettings.userTypeFilter, searchOptions)

  const onSearch = (search: ISearchOptions) => {
    const { usernames: searchNames, symbolNames: searchSymbols } = search;
    const { options, setOptions } = filteredPredictions;
    if (!options) {
      setOptions({
        usernames: searchNames,
        symbolNames: searchSymbols?.filter(Helper.uniqueFilter),
      })
      return;
    }

    const { usernames, symbolNames, ...rest } = options;
    setOptions({
      usernames: searchNames,
      symbolNames: searchSymbols?.filter(Helper.uniqueFilter),
      ...rest
    })
  }

  return (
    <div>
      <div className="mx-2 mb-3"><Banner /></div>
      <div className="bg-off-black no-hover d-flex flex-column-reverse flex-md-row justify-content-start justify-content-md-between align-items-center">
        <div className="flex-grow-1 w-100 search-container bg-off-black">
          <div className="ms-md-4">
            <AutoSuggestSearch onSearchCallback={onSearch} />
          </div>
        </div>
        <div className="bg-black w-100 px-1 pb-1"><LevelBadges /></div>
      </div>
      <div className="w-100 d-none d-md-block" style={{ borderTop: 'solid 1px #111111' }}></div>
    </div>

  )
}
