import React from 'react'
import {SignalItem as ISignalItem, StockSignalsEnum, StockSignalsHelper} from 'predictagram-lib'
import { TradeModel } from 'models/trade.model'

export const SignalItem:React.FunctionComponent<{signalItem: ISignalItem}> = ({signalItem}) => {


  return (
    <div className="signal-item">
      <dl>
        <dt>Type</dt>
        <dd>{StockSignalsHelper.signalsMap().get(signalItem.type)}</dd>

        {signalItem.repeatTypes && <>
        <dt>Repeat Types</dt>
        <dd>{signalItem.repeatTypes.map( (r) => <div>{TradeModel.signalRepeatType.get(r)}</div>)}</dd>
        </>}

        {signalItem.directionTypes && <>
        <dt>Direction Types</dt>
        <dd>{signalItem.directionTypes.map( (d) => <div>{TradeModel.signalDirectionType.get(d)}</div>)}</dd>
        </>}

        {signalItem.repeatSecs && <>
        <dt>Repeat Seconds</dt>
        <dd>{TradeModel.signalRepeatIntervalSecs.get(signalItem.repeatSecs)}</dd>
        </>}

        {signalItem.cancellationSecs && <>
        <dt>Cancellation Seconds</dt>
        <dd>{TradeModel.signalRepeatIntervalSecs.get(signalItem.cancellationSecs)}</dd>
        </>}

      </dl>
    </div>
  )
}
