import React from "react";
import {DropDownGeneric, DropDownType} from "./form/DropDownGeneric";
import {SignalAlertCategoryEnum, SignalAlertCategoryEnumHelper} from "predictagram-lib";



export const SignalAlertCategoriesDropDown: React.FunctionComponent<Partial<DropDownType<SignalAlertCategoryEnum,string>>> = ({
  name = 'signalAlertCategories',
  label = 'Alert Cats',
    ...rest
})=> {
  const options = Array.from(SignalAlertCategoryEnumHelper.names().entries()).map((v,t)=>{
    return {
      label: v[0],
      value: v[1],
    }
  });
  const labelText = ()=>{
    return (
      <div className="fw-bold">{label}</div>
    )
  }
  return (
    <DropDownGeneric {...rest} options={options} label={labelText()} name={name} /*onChange={onChange}*/ multiple={true} />
  );
}

