import { useCallback, useEffect, useRef, useState } from "react";
import { APIState } from "_constants/APIState";
import { PredictionTypeEnum } from "predictagram-lib";
import { IPostSubmitChartResponse, IPostSubmitChartSearchOptions, UserPredictionApiService } from "services/UserPredictionApiService";


export interface IPostSubmitApi {
  items: IPostSubmitChartResponse[],
  apiState: APIState,
  apiStatus: {
    isLoading: boolean,
    isLoaded: boolean,
    isIdle: boolean,
    isError: boolean
  },
  error: Error | null,
  reload: () => void,
}

export function usePostSubmitApi (
  service: UserPredictionApiService, 
  predictionTypeId: PredictionTypeEnum, 
  startTime: number, 
  endTime: number, symbolName: string ): IPostSubmitApi {

  const [ items, setItems ] = useState<IPostSubmitChartResponse[]>([]);
  const [ apiState, setApiState ] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<Error | null>(null);
  const [ refresh, setRefresh ] = useState<number>(0);
  
  const serviceRef = useRef(service);

  const load = useCallback(async ()=>{
    try {
      setApiState(APIState.LOADING);
              
      const items = await serviceRef.current.getPostSubmitChartData({
        endTimePredMade: endTime,
        predictionTypes: [predictionTypeId],
        startTimePredMade: startTime,
        symbolName
        } as IPostSubmitChartSearchOptions)
      setItems(items);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as Error);
    } 
  }, [endTime, predictionTypeId, startTime, symbolName])

  useEffect(()=>{

    load();

    // clean up
  }, [load, refresh])

  const reload = () => {
    setRefresh(refresh + 1);
  }

  const isLoaded = apiState === APIState.LOADED;
  const isLoading = apiState === APIState.LOADING;
  const isError = apiState === APIState.ERROR;
  const isIdle = apiState === APIState.IDLE;

  return {items, apiState, apiStatus: {isLoaded, isLoading, isError, isIdle}, error, reload};

}
