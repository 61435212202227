// tslint:disable-next-line:no-var-requires
const talib = require('talib');
// console.debug({talib});
// console.log("TALib Version: " + talib.version);

// https://ta-lib.org/api/

export interface TaLibStockInput {
    close: number[],
    open: number[],
    high: number[],
    low: number[],
}

export interface TaLibOutput {
    begIndex: number;
    nbElement: number,
    "result": {"outInteger": number[]},
}
export interface TaLibInput {
    name:  TaLibFunctions|string,
    startIdx: number,
    endIdx: number,
    open:  number[],
    close: number[],
    low:   number[],
    high:  number[],
    optInPenetration?: number, // CDLABANDONEDBABY,CDLDARKCLOUDCOVER might be other too
    // optInTimePeriod: 9

}

export enum TaLibFunctions {
    CDL3LINESTRIKE = 'CDL3LINESTRIKE',
}

export class TaLibData {
    public static version() {
        return talib.version;
    }

    public static functions() {
        return talib.functions;
    }

    public static explain(name:string) {
        return talib.explain(name);
    }

    public static execute(params:TaLibInput):TaLibOutput {
        // doesn't work without it, something is wrong with default params

        if (params.name==='CDLABANDONEDBABY' && !params.optInPenetration) {
            params.optInPenetration = 0.3; // 3.0;
        }
        if (params.name==='CDLDARKCLOUDCOVER' && !params.optInPenetration) {
            params.optInPenetration = 0.5; // 5.0;
        }
        if (params.name==='CDLEVENINGDOJISTAR' && !params.optInPenetration) {
            params.optInPenetration = 0.3; // 3.0;
        }
        if (params.name==='CDLEVENINGSTAR' && !params.optInPenetration) {
            params.optInPenetration = 0.3; // 3.0;
        }
        if (params.name==='CDLMATHOLD' && !params.optInPenetration) {
            params.optInPenetration = 0.5; // 5.0;
        }
        if (params.name==='CDLMORNINGDOJISTAR' && !params.optInPenetration) {
            params.optInPenetration = 0.3; // 3.0;
        }
        if (params.name==='CDLMORNINGSTAR' && !params.optInPenetration) {
            params.optInPenetration = 0.3; // 3.0;
        }

        // The output will be calculated only for the range specified by startIdx to endIdx.

        //

        return talib.execute(params);
    }
}
