import React from 'react'
import { ISeasonContext, SeasonContext } from './SeasonContext'
import { Season } from 'predictagram-lib'

export const SeasonProvider: React.FunctionComponent<{children: any}> = ({children}) => {
  const currentSeason = Season.getSeasonFromDate(new Date());
  const seasonContext: ISeasonContext = {
    season: currentSeason
  }

  return (
    <SeasonContext.Provider value={seasonContext}>
      {children}
    </SeasonContext.Provider>
  )
}


