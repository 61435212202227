import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Icons from "components/common/Icons";
import { HelpTypeSelect } from './HelpTypeSelect';
import { ModalPrivacyPolicy } from 'components/common/ModalPrivacyPolicy';
import { ModalTerms } from 'components/common/ModalTerms';
import { HelpSlider } from './HelpSlider';
import { CameraVideo } from 'react-bootstrap-icons';
import { HelpLeveling } from './HelpLeveling';


const WatchVideoLink = () => {
  return <button type="button" className="btn btn-primary p-2 text-14" onClick={()=>window.open('https://twitter.com/Predictagram_/status/1692253087044444614', '_blank')}>
    <div className="d-flex justify-content-start align-items-center gap-2 p-1 px-3">
      <CameraVideo/> Watch Video Tutorial
    </div>
  </button>
}

const RecentActivity = () => {
  return (
    <div className="my-2 mt-3 text-14">
      <p><span className="fw-bold">Recent Activity</span> is a feed of incoming predictions as they are made. You can filter these predictions by Top Predictors (predictors with the highest historical accuracy), Following (those users that you follow), and Everyone. Click on a prediction to see it's full chart.</p>
      <p>To further configure this feed, and to see more predictions, and their prediction charts, click the Show More button under this section. You can then select different stock symbols, prediction types to further filter this feed for you. Your selections will be saved when you go back to your dashboard until you configure them again.</p>
      <p><WatchVideoLink /></p>
    </div>
  )
}

const WatchList = () => {
  return (
    <div className="my-2 mt-3 text-14">
      <p><span className="fw-bold">Watchlist</span> is where consensus charts for each of the tickers that you follow are displayed. Click Edit Watchlist to add or remove tickers from your watchlist.</p>
      <p><WatchVideoLink /></p>
    </div>
  )
}

const HowToReadConsensusBands = () => {
  return (
    <div className="my-2 mt-3">

      <div className="text-13 fw-normal common-outline">
        <p>Our Consensus bands are based on the average prices of all users' predictions.</p>
        <p>When the purple price line goes above the green overbought lines, as shown below, the stock is likely to go back down.</p>
        <p>Similarly, when the price is below the oversold lines, it's likely to come back up.</p>
        <p>There is no guarantee that consensus bands will correctly predict price movements.</p>
      </div>

      <div className="w-100 mt-3">
        <img src="/images/help/how-to-read-consensus-band.png" alt="how-to" style={{width: "100%"}}/>
      </div>

    </div>
  )

}



export enum HelpPageEnum {
  MAIN = 1,
  HOW_PREDICTION_WORKS = 2,
  PREDICTION_TYPES = 3,
  RECENT_ACTIVITY = 4,
  WATCHLIST = 5,
  HOW_CONSENSUS_WORKS = 6,
  LEVELING = 7,
}

interface IHelpItem  {
  key: HelpPageEnum,
  title: string | JSX.Element,
  content: string | JSX.Element,
}


export const ModalHelp: React.FunctionComponent<{ show: boolean; handleClose: () => void; initialPage?: HelpPageEnum }> = ({
  show,
  handleClose,
  initialPage = HelpPageEnum.MAIN
}) => {

  const [currentPage, setCurrentPage] = useState<HelpPageEnum>(initialPage);
  const [showPolicy, setShowPolicy] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);

  const _handleClose = () => {
    setCurrentPage(HelpPageEnum.MAIN);
    handleClose();
  }

  const helpItems: Map<HelpPageEnum, IHelpItem> = new Map<HelpPageEnum, IHelpItem>([
    [HelpPageEnum.HOW_PREDICTION_WORKS, {key: HelpPageEnum.HOW_PREDICTION_WORKS, title: 'How To Interpret Predictagrams', content: <HelpSlider />}],
    [HelpPageEnum.HOW_CONSENSUS_WORKS, {key: HelpPageEnum.HOW_CONSENSUS_WORKS, title: 'How To Read Consensus Bands', content: <HowToReadConsensusBands />}],
    [HelpPageEnum.PREDICTION_TYPES, {key: HelpPageEnum.PREDICTION_TYPES, title: 'Prediction Types', content: <HelpTypeSelect handleClose={_handleClose} withHeader={false} />}],
    [HelpPageEnum.RECENT_ACTIVITY, {key: HelpPageEnum.RECENT_ACTIVITY, title: 'How To Use Recent Activity', content: <RecentActivity />}],
    [HelpPageEnum.WATCHLIST, {key: HelpPageEnum.WATCHLIST, title: 'How To Use Watchlist', content: <WatchList />}],
    [HelpPageEnum.LEVELING, {key: HelpPageEnum.LEVELING, title: <div className="text-center">Leveling Up and Rewards</div>, content: <HelpLeveling hasTitle={false} />}],
  ])
  
  const renderItem = (item: IHelpItem) => {
    return (
      <>
        <div className="help-slide-title mt-3 fw-bold">{item.title}</div>
        {item.content}
      </>
    );
  }
  
  const renderTitle = (item: IHelpItem) => {
    return (
      <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setCurrentPage(item.key)}>
      <div>{item.title}</div>
      <div><Icons.RightArrow /></div>
    </div>      
    );
  }

  return (
    <>
      <ModalPrivacyPolicy show={showPolicy} handleClose={() => setShowPolicy(false)} />
      <ModalTerms show={showTerms} handleClose={() => setShowTerms(false)} />

      <Modal show={show} onHide={_handleClose} size="lg" centered>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center m-4">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                {currentPage !== HelpPageEnum.MAIN &&
                  <div role="button" onClick={() => setCurrentPage(HelpPageEnum.MAIN)} className="me-2"><Icons.LeftArrow /></div>
                }
                <div className="help-icon"></div>
                <div>Help</div>
              </div>

              <div>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={_handleClose}></button>
              </div>
            </div>

            {currentPage !== HelpPageEnum.MAIN &&
              <>
              { renderItem(helpItems.get(currentPage) as IHelpItem) }              
              </>
            }

            {currentPage === HelpPageEnum.MAIN &&
              <div className="help-items mt-5">
                {Array.from(helpItems).map(([, value], i: number) => <div key={`title-key-${i}`}>{renderTitle(value)}</div>)}

                <div className="d-block d-md-none my-3 help-items-item d-flex flex-row justify-content-between" role="button">
                  <div><a href="mailto:contact@predictagram.com" className="contact-us text-white text-decoration-none">Contact Us</a></div>
                </div>

                <div className="d-block d-md-none my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setShowTerms(true)}>
                  <div>Terms and Conditions</div>
                  <div><Icons.RightArrow /></div>
                </div>

                <div className="d-block d-md-none my-3 help-items-item d-flex flex-row justify-content-between" role="button" onClick={() => setShowPolicy(true)}>
                  <div>Privacy Policy</div>
                  <div><Icons.RightArrow /></div>
                </div>
              </div>
            }

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};