import React, { useState } from "react";
import { Formik, Form } from "formik";
import { FieldWithError } from "components/common";
import { validationMap, Validator } from "schemas";
import { userAccountService } from "services";


const ForgotPassword: React.FunctionComponent = () => {

  enum Stage {
    FORM = 1,
    SUCCESS = 2
  };

  const [ currentStage, setCurrentStage ] = useState<Stage>(Stage.FORM);

  const initialValues = {
    emailAddress: "",
  };

  const onSubmit = async (data: any, actions: any) => {
    // @TODO: change to AccountProvider
    const { setStatus } = actions;
    try {
      await userAccountService.requestResetPassword(data.emailAddress);
      setCurrentStage(Stage.SUCCESS);
    } catch (err: any) {
      alert(err.message);
      setStatus(err.message);
    }

  };

  return (
    <>
    {currentStage === Stage.SUCCESS &&
    <div className="container-md">
      <div className="row justify-content-center medium-text-1 my-2">
        <div className="col-md-8 text-primary">Forgotten Password</div>
      </div>

      <div className="row justify-content-center small-text-1 my-2">
        <div className="col-md-8">A unique link was sent to the email address you provided. Check your email and click on that link to proceed with resetting your password.</div>
      </div>

    </div>
    }

    {currentStage === Stage.FORM &&
    <Formik initialValues={initialValues} validationSchema={validationMap.get(Validator.FORGOT_PASSWORD)} onSubmit={onSubmit}>
    {({ errors, status, setStatus, touched, isSubmitting }) => {
      return (
        <div className="container">
          <Form>
            <div className="row justify-content-center medium-text-1 my-2">
              <div className="col-md-8 text-primary">Forgotten Password</div>
            </div>
            <div className="row justify-content-center small-text-1 my-2">
              <div className="col-md-8">You can reset your password by entering your e-mail address below.</div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <FieldWithError label="E-Mail Address" errors={errors} touched={touched} fieldName="emailAddress" placeholder="Enter E-Mail Address" />
              </div>
            </div>
            <div className="row justify-content-center my-2">
              <div className="col-md-8">
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
              </div>
            </div>

            {status &&
            <div className="row justify-content-center my-2">
              <div className="col-md-8">
                {status}
              </div>
            </div>
            }


          </Form>
        </div>
      );
    }}
  </Formik>
    }

    </>
  );
};

export { ForgotPassword };
