import { ILeagueSeason } from "interfaces/league";
import { NewYorkTz as tz } from "predictagram-lib";

export class SeasonHelper  {

  static seasonStartEnd (currentDay: Date, season: ILeagueSeason) {

    const currentDayInSec = currentDay.getTime() / 1000;

    // is currentDay past the season end
    if (currentDayInSec > season.endTime) {
      return `${season.name} has ended.`;
    }

    // is current day the first day
    if (!!tz.isSameDay(currentDay, new Date(season.startTime * 1000))) {
      return `${season.name} starts today!`;
    }

    // is current day before the season start
    if (currentDayInSec < season.startTime) {
      return `${season.name} starts on ${tz.format(new Date(season.startTime * 1000)).monthDayWithSuffix()}`;
    }
  
    // is current day between the season range
    if (currentDayInSec > season.startTime && currentDayInSec < season.endTime) {
      return `${season.name} ends on ${tz.format(new Date(season.endTime * 1000)).monthDayWithSuffix()}`;
    }
  }

  static isDuringSeason(currentDay: Date, season: ILeagueSeason) {
    const currentDayInSec = currentDay.getTime() / 1000;
    return currentDayInSec > season.startTime && currentDayInSec < season.endTime
  }
}