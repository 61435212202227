import React from 'react';

import { IAccount } from 'interfaces';
import { useModalLoginRegisterPassword } from '_hooks/useModalLoginRegisterPassword';

const AccessDenied: React.FunctionComponent<{ account?: IAccount, loginUrl?: string, message?: string | JSX.Element }> = ({ account, loginUrl='/login', message="Get access to hundreds of real-time stock predictions from top predictors." }) => {

  const loginRegisterPassword = useModalLoginRegisterPassword();

  return <div className="container my-5">
    {loginRegisterPassword.render()}
    <div className="row justify-content-center">
      <div className="col-12 col-md-6 text-center">

        <div className="mx-2 mx-md-3">
          {account ? <>
            <div className="fw-bold">Insufficient Access</div>
            <div>Logged in as: {account.email}</div>

          </> : <div className="">
            <div className="my-4">{message}</div>
              <div role="button" className="btn btn-primary my-3" onClick={()=>loginRegisterPassword.register.show(true)}>Sign Up - it's free</div>
              <div role="button" className="btn btn-secondary" onClick={()=>loginRegisterPassword.login.show(true)}>Login</div>
          </div>}
        </div>
      </div>
    </div>
  </div>
}

export { AccessDenied }