import React from 'react';
import { ContestState } from '_constants/ContestState';
import { ContestHelper } from '_utils/ContestHelper';
import { IContest } from 'interfaces/IContest';
import { NewYorkTz as tz } from 'predictagram-lib';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';
import { ContestWinner } from './ContestWinner';

export const ContestItem: React.FunctionComponent<{ contest: IContest }> = ({ contest }) => {
  const contestState = ContestHelper.getContestState(contest, new Date());
  const today = new Date();
  const navigate = useNavigate();

  return (
    <div className="contest-item d-flex flex-justify-center align-items-center gap-3 my-3">
      <div className="contest-item-image align-self-start flex-shrink-0"><img src={contest.logoUrl} alt={contest.name} /></div>
      <div className="contest-item-details d-flex flex-wrap gap-3 justify-content-start align-items-start"
        role="button"
        onClick={()=>navigate(Url.USER_CONTEST.replace(':contestId', contest.id.toString()))}
        >

        <div className="contest-item-detail-name">
          {contest.name}

          {contestState === ContestState.CLOSED &&

              <>
              {contest.resultTime > (new Date()).getTime() / 1000 ? 
                (<div className="contest-item-detail-name-text contest-item-detail-name-closed">Entries Closed {(tz.getDaysAgo(new Date(contest.endTime * 1000)))}</div>) 
                : 
                (<div className="contest-item-detail-name-text contest-item-detail-name-closed">Ended {(tz.getDaysAgo(new Date(contest.endTime * 1000)))}</div>) 
              }
              </>
          }

          {contestState !== ContestState.CLOSED &&
            <div className="contest-item-detail-name-text">
            {ContestHelper.getDateRange(contest)}            
            </div>
          }

        </div>

        <div className="contest-item-detail-time">
          {contestState === ContestState.CLOSED &&
            <>
            {
              contest.resultTime < (new Date()).getTime() / 1000  ? 
              (<ContestWinner contest={contest} />) 
              : 
              (<div className="contest-item-detail-time-until-start">Results in {ContestHelper.getTimeUntilResults(contest, today)}</div>)
            }
            </>
          }

          {contestState !== ContestState.CLOSED &&
            <>
              {contestState === ContestState.OPEN &&
                <div className="contest-item-detail-time-remaining">{ContestHelper.getTimeUntilClose(contest, today)} remaining</div>
              }

              {contestState === ContestState.PRE_OPEN &&
                <div className="contest-item-detail-time-until-start">
                  Starts in {ContestHelper.getTimeUntilOpen(contest, today)}
                </div>
              }
            </>
          }
        </div>


      </div>
    </div>
  );
}