import React, { useEffect } from 'react';

const PrivacyPolicy: React.FunctionComponent<{ isModal?: boolean }> = ({ isModal = false }) => {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, [])

  return (
    <div className="privacy-policy">

      {!isModal &&
        <h2 className="mt-5">Privacy Policy </h2>
      }

      <p>Protecting your private information is our priority. This Statement of Privacy applies to www.predictagram.com, and any Predictagram branded apps and services. Predictagram and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Predictagram include www.predictagram.com. The Predictagram website is a social network where users may post their stock predictions and investing and trading notesBy using the Predictagram website, you consent to the data practices described in this statement. </p>

      <div className="fw-bold mt-3 mb-2">Collection of your Personal Information </div>
      <p>In order to better provide you with products and services offered, Predictagram may collect personally identifiable information, such as your: </p>
      <ul>
        <li>First and Last Name</li>
        <li>E-mail Address</li>
        <li>Phone Number</li>
        <li>Username</li>
      </ul>
      <p>If you purchase Predictagram's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction. </p>
      <p>Predictagram may also collect anonymous demographic information, which is not unique to you, such as your: </p>
      <ul>
        <li>Age</li>
        <li>Gender</li>
        <li>Household Income</li>
      </ul>

      <p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Predictagram's public message boards, this information may be collected and used by others. </p>
      <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future. </p>

      <div className="fw-bold mt-3 mb-2">Use of your Personal Information </div>
      <p>Predictagram collects and uses your personal information to operate and deliver the services you have requested. </p>
      <p>Predictagram may also use your personally identifiable information to inform you of other products or services available from Predictagram and its affiliates. </p>

      <div className="fw-bold mt-3 mb-2">Sharing Information with Third Parties </div>
      <p>Predictagram does not sell, rent or lease its customer lists to third parties. </p>
      <p>Predictagram may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is transferred to the third party. Predictagram may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Predictagram, and they are required to maintain the confidentiality of your information. </p>
      <p>Predictagram may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Predictagram or the site; (b) protect and defend the rights or property of Predictagram; and/or (c) act under exigent circumstances to protect the personal safety of users of Predictagram, or the public. </p>

      <div className="fw-bold mt-3 mb-2">Opt-Out of Disclosure of Personal Information to Third Parties </div>
      <p>In connection with any personal information we may disclose to a third party for a business purpose, you have the right to know: </p>

      <ul><li>The categories of personal information that we disclosed about you for a business purpose.</li></ul>

      <p>You have the right under the California Consumer Privacy Act of 2018 (CCPA) and certain other privacy and data protection laws, as applicable, to opt-out of the disclosure of your personal information. If you exercise your right to opt-out of the disclosure of your personal information, we will refrain from disclosing your personal information, unless you subsequently provide express authorization for the disclosure of your personal information. To opt-out of the disclosure of your personal information, visit www.predictagram.com, go to your user profile, click on the Settings gear icon, and then click on Delete My Data. </p>

      <div className="fw-bold mt-3 mb-2">Tracking User Behavior </div>
      <p>Predictagram may keep track of the websites and pages our users visit within Predictagram, in order to determine what Predictagram services are the most popular. This data is used to deliver customized content and advertising within Predictagram to customers whose behavior indicates that they are interested in a particular subject area. </p>

      <div className="fw-bold mt-3 mb-2">Automatically Collected Information </div>
      <p>Information about your computer hardware and software may be automatically collected by Predictagram. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Predictagram website. </p>

      <div className="fw-bold mt-3 mb-2">Use of Cookies </div>
      <p>The Predictagram website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. </p>
      <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Predictagram pages, or register with Predictagram site or services, a cookie helps Predictagram to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Predictagram website, the information you previously provided can be retrieved, so you can easily use the Predictagram features that you customized. </p>
      <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Predictagram services or websites you visit. </p>

      <div className="fw-bold mt-3 mb-2">Links </div>
      <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information. </p>

      <div className="fw-bold mt-3 mb-2">Security of your Personal Information </div>
      <p>Predictagram secures your personal information from unauthorized access, use, or disclosure. Predictagram uses the following methods for this purpose: </p>
      <ul>
        <li>SSL Protocol </li>
      </ul>

      <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. </p>
      <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed. </p>

      <div className="fw-bold mt-3 mb-2">Right to Deletion </div>
      <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will: </p><ul>
        <li>Delete your personal information from our records; and </li>
        <li>Direct any service providers to delete your personal information from their records. </li>
      </ul>

      Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:
      <ul>
        <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; </li>
        <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; </li>
        <li>Debug to identify and repair errors that impair existing intended functionality; </li>
        <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; </li>
        <li>Comply with the California Electronic Communications Privacy Act; </li>
        <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent; </li>
        <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; </li>
        <li>Comply with an existing legal obligation; or </li>
        <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information. </li>
      </ul>


      <div className="fw-bold mt-3 mb-2">Children Under Eighteen </div>
      <p>Predictagram does not knowingly collect personally identifiable information from children under the age of eighteen. If you are under the age of eighteen, you may not use this website. </p>

      <div className="fw-bold mt-3 mb-2">Opt-Out & Unsubscribe from Third Party Communications </div>
      <p>We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving any or all communications from third-party partners of Predictagram by going to their Account profile Settings page.</p>

      <div className="fw-bold mt-3 mb-2">E-mail Communications </div>
      <p>From time to time, Predictagram may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Predictagram or click on a link therein. </p>
      <p>If you would like to stop receiving marketing or promotional communications via email from Predictagram, you may opt out of such communications by clicking on the UNSUBSCRIBE button. </p>

      <div className="fw-bold mt-3 mb-2">External Data Storage Sites </div>
      <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted. </p>

      <div className="fw-bold mt-3 mb-2">Changes to this Statement </div>
      <p>Predictagram reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy. </p>

      <div className="fw-bold mt-3 mb-2">Contact Information </div>
      <p>Predictagram welcomes your questions or comments regarding this Statement of Privacy. If you believe that Predictagram has not adhered to this Statement, please email Predictagram at: </p>

      <p>Predictagram Email Address:</p>
      <p>privacy@predictagram.com</p>

      <p>
        Effective as of March 30, 2023
      </p>


    </div>
  );
}

export { PrivacyPolicy }