import React, {useState } from 'react';
import { ProcessingModal } from 'components/admin/analysis/alerts/ProcessingModal';

export const useProcessingModal = (showByDefault: boolean = false) => {

  const [show, setShow] = useState<boolean>(showByDefault);
  const [processing, setProcessing] = useState<boolean>(false);
  const [content, setContent] = useState<any>('');
  const render = () => {
    return <ProcessingModal show={show} handleClose={()=>setShow(false)} content={content} isProcessing={processing} />
  }

  return {
    render,
    setShow,
    setContent,
    setProcessing
  }

}