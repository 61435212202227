import { SelectDropDown } from 'components/common/form/SelectDropDown'
import { SignalAlertStatusEnum, SignalAlertStatusEnumHelper } from 'predictagram-lib'
import React from 'react'


interface IProps {
  value: SignalAlertStatusEnum | undefined,
  setValue: (value: SignalAlertStatusEnum) => void,
  className?: string,
}

export const StatusDropDown:React.FunctionComponent<IProps> = ({value, setValue, className}) => {

  return (
    <SelectDropDown<SignalAlertStatusEnum, string> 
      onSelect={setValue}
      options={SignalAlertStatusEnumHelper.names}
      selected={value}
      className={className}
      hasAll={false}
    />
  )
}



