import React, { useEffect, useRef } from 'react'
import { ITradegramSecurity } from "services/TradegramApiService";
import { NetBalance, ProfitPct, SymbolName } from '../tradegram/SecurityCard';
import { TradeLineItem } from '../tradegram/TradeLineItem';
import { Helper } from '_utils';
import { UserInfoProfit } from './UserInfoProfit';
import { ShareTradegram } from 'components/common/ShareTradegram';
import { TradegramHelper } from '_utils/TradegramHelper';

interface IProps {
  security: ITradegramSecurity,
  onClick?: () => void,
  showByDefault?: boolean,
}


export const ProfitPctOrAction: React.FunctionComponent<{ security: ITradegramSecurity }> = ({ security }) => {

  const profit = TradegramHelper.getProfit(security);

  return <>
    {TradegramHelper.getQuantityBalance(security) === 0 || profit.perShare !== 0 ?
      <>
        <ProfitPct security={security} />
      </>
      :
      <div className="text-10 text-lighter-gray d-flex justify-content-end">{TradegramHelper.getPositionActionName(security)}</div>
    }
  </>
}

export const ShareTradegramButton: React.FunctionComponent<{ security: ITradegramSecurity }> = ({ security }) => {
  const onClickShare = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }

  return <div className="share-rounded rounded-circle bg-charcoal text-white text-14 d-flex align-items-center" onClick={onClickShare}>
    <ShareTradegram security={security} title="" />
  </div>

}

export const MoreTradesButton: React.FunctionComponent<{ security: ITradegramSecurity, onClick: () => void, ref?: React.LegacyRef<HTMLDivElement> }> = ({ security, onClick, ref }) => {
  return <div role="button" ref={ref}
    className="btn-more-trades text-nowrap flex-shrink-0 text-14 fw-bold pill text-center py-2 px-3 px-md-5"
    onClick={onClick}
  >
    <span className="m-2">+{security.transactions - 1} {Helper.pluralize("trade", security.transactions - 1)} </span>
  </div>
}

export const TradeWide: React.FunctionComponent<IProps> = ({ security, onClick, showByDefault = false }) => {

  const bgColor = security.profit < 0 ? "red" : "green";
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showByDefault && divRef.current) {
      divRef.current.click();
    }
  }, [])


  return (
    <div>
      <div className="m-1 mt-2 happening">
        <UserInfoProfit security={security} trade={security.lastTransaction} />
      </div>

      <div role="button" onClick={onClick} ref={divRef}>
        <div className="tradegram-card-wide d-flex flex-column justify-content-between">
          <div className={`tradegram-card-wide-top ${bgColor} align-items-center`}>
            <div className="d-flex justify-content-between mt-2">
              <SymbolName security={security} />
              <div className="d-flex gap-3 align-items-center">
                <ProfitPctOrAction security={security} />
                {security.transactions > 1 && <div className="d-none d-md-block"><NetBalance security={security} /></div>}
              </div>
            </div>
          </div>

          <div className="tradegram-card-wide-bottom w-100 w-md-50 d-flex justify-content-between align-items-end">
            <div className="container-fluid g-0">
              <div className="row align-items-end">
                <div className="col-12 col-md-8">
                  <div className="d-flex justify-content-between align-items-start">
                    <TradeLineItem security={security} tradegramTransaction={security.lastTransaction} index={0} showProfit={true} />
                    {security.transactions > 1 && <div className="d-block d-md-none text-nowrap"><NetBalance security={security} small={true}/></div>}
                  </div>
                </div>

                <div className="col-12 col-md-4 align-bottom">
                  <div className="d-flex justify-content-end gap-2 align-items-center px-3">
                    {onClick && (security.transactions > 1) &&
                      <div className="my-2 flex-fill"><MoreTradesButton security={security} onClick={onClick} /></div>
                    }
                    <ShareTradegramButton security={security} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}