export class AnalysisFilters {
  public static readonly UsersScore = new Map([
    [null as any, 'All'],
    // [0.30, '>=30%'],
    // [0.35, '>=35%'],
    // [0.40, '>=40%'],
    [0.45, '>=45%'],
    [0.55, '>=55%'],
    [0.60, '>=60%'],
    [0.65, '>=65%'],
    [0.70, '>=70%'],
    [0.75, '>=75%'],
    [0.80, '>=80%'],
    [0.85, '>=85%'],
    [0.90, '>=90%'],

    [-0.10, '<=10%'],
    [-0.20, '<=20%'],
    [-0.30, '<=30%'],
    [-0.40, '<=45%'],
    [-0.50, '<=50%'],
  ]);

  public static readonly SignalRepeatTypes = new Map([
    [0, 'Off'],
    [1*60, '1m'],
    [2*60, '2m'],
    [3*60, '3m'],
    [5*60, '5m'],
    [10*60, '10m'],
    [15*60, '15m'],
    [20*60, '20m'],
    [30*60, '30m'],
  ]);
}
