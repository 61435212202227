import React from 'react'
import { LeftArrow } from 'components/common/Icons'

interface IProps {
  width?: number,
  height?: number,
  className?: string,
  onClick?:()=>void,
  [propName: string]: any
}
export const RoundBackButton:React.FunctionComponent<IProps> = ({width=14, height=14, className, onClick, ...rest}) => {
  return (
    <div
      role="button"
      onClick={onClick}
      {...rest}
      className={`btn-circle bg-off-black d-flex justify-content-center align-items-center ${className}`}><LeftArrow className="d-inline" width={width} height={height}/></div>
  )
}

