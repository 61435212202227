import React from "react";
import { BaseForm, FieldWithError } from "components/common";

import { validationMap, Validator } from "schemas";
import { adminStockHistoryApiService } from "services/AdminApiService";
import { IDataField } from "interfaces";
import { IStockHistory } from "interfaces/IStockHistory";
import ReactDatePicker from "react-datepicker";
import { StockHelper } from "predictagram-lib";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IStockHistory) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

export const AdminStockHistoryForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {

  const beforeSubmit = async (data: any, actions: any) => {
      data.valueAt = StockHelper.workingHours(new Date(data.valueAt)).end8PM.getTimeSec();

      // per Andrei, set these fields to save as valueClose for 8PM.
      data.valueOpen = data.valueClose;
      data.valueHigh = data.valueClose;
      data.valueLow = data.valueClose;
      data.volume = 0;
  };

  return (
    <BaseForm
      apiService={adminStockHistoryApiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_STOCK_HISTORY_FORM)}
      editableFields={editableFields}
      beforeSubmit={beforeSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <>

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="stockSymbol"
              placeholder="Stock Symbol"
              label="Stock Symbol" />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="valueClose"
              placeholder="Price"
              label="Price" />

            <div>
              <span>Closing Date</span>
              <ReactDatePicker
                dateFormat="MM/dd/yyyy"
                onChange={(date: Date) => {
                  setFieldValue("valueAt", date.getTime());
                }}
                selected={new Date(values.valueAt)}
              />            
            </div>

          </>
        );
      }}
    </BaseForm>
  );
};


