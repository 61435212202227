import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import { ReceiveViaNotification, ReceiveViaTextMessage } from './Settings';
import { ISignalSettings } from 'services/UserSignalApiService';

export const SignalSetting: React.FunctionComponent<{ 
  symbolName: string, 
  signalSettings: ISignalSettings, 
  handleCheckToggle: any,
  isEnabled: boolean
}> = ({ symbolName, signalSettings, handleCheckToggle, isEnabled }) => {

  const isChecked = (setting: keyof ISignalSettings) => {
    return currentSetting[setting] === true;
  }

  const [currentSetting, setCurrentSetting] = useState<ISignalSettings>(signalSettings);
  const [currentEnabled, setEnabled] = useState<boolean>(isEnabled);

  const _handleCheckToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    handleCheckToggle(event);
    const setting = {
      ...currentSetting,
      [event.target.name]: event.target.checked
    }
    setCurrentSetting(setting);
  }

  const handleEnableToggle =  async (event: React.ChangeEvent<HTMLInputElement>) => {
    _handleCheckToggle(event);
    setEnabled(!currentEnabled);
  }

  return (
    <div className="my-5">
      <div className="my-3 help-items-item d-flex flex-row justify-content-between">
        <div>Enable Signal Alerts</div>
        <div><Form.Check
          type="switch"
          id="signal-enable"
          name="signalEnable"
          checked={currentEnabled}
          onChange={handleEnableToggle}
        />
        </div>
      </div>

      {currentEnabled && <>
        <div className="my-3 help-items-item d-flex flex-row justify-content-between">
          <div className="ms-3">Receive Via EMail</div>
          <div><Form.Check
            type="switch"
            id="signal-send-email"
            name="signalSendEmail"
            checked={isChecked('signalSendEmail')}
            onChange={_handleCheckToggle}
          />
          </div>
        </div>

        <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button">
          <div className="ms-3"><ReceiveViaTextMessage /></div>
          <div><Form.Check
            // aria-disabled
            // disabled
            type="switch"
            id="signal-send-sms"
            name="signalSendSms"
            checked={isChecked('signalSendSms')}
            onChange={_handleCheckToggle}
          />
          </div>
        </div>

        <div className="my-3 help-items-item d-flex flex-row justify-content-between" role="button">
          <div className="ms-3"><ReceiveViaNotification /></div>
          <div><Form.Check
            aria-disabled
            disabled
            type="switch"
            id="signal-send-notification"
            name="signalSendNotification"
            checked={isChecked('signalSendNotification')}
            onChange={_handleCheckToggle}
          />
          </div>
        </div>


      </>}
    </div>
  )
}
