import React from 'react'
import { UserTypeFilterEnum, userTypeDropDownItems } from './UserTypeDropDownFilter';
import { useDashboardSettings } from '_hooks/useDashboardSettings';
import { StorageKey } from '_constants';


interface IProps {
  userTypeFilterState: [UserTypeFilterEnum, 
    React.Dispatch<React.SetStateAction<UserTypeFilterEnum>>],
}

export const UserTypeFilterTabs: React.FunctionComponent<IProps> = ({userTypeFilterState}) => {

  const [userTypeFilter, setUserTypeFilter] = userTypeFilterState;
  const items = userTypeDropDownItems;
  const [dashboardSettings, setDashboardSettings] = useDashboardSettings(localStorage, StorageKey.DASHBOARD_SETTINGS);

  const _setUserTypeFilter = (key: UserTypeFilterEnum) => {
    setDashboardSettings({
      ...dashboardSettings,
      userTypeFilter: key,
    })
    setUserTypeFilter(key);
  }

  return (
    <div className="dashboard-filter d-flex justify-content-between align-items-center gap-3 gap-md-5 px-2 w-100">
        {items.map((item, idx) => <div
              role="button"
              key={`dropdown-key-${idx}`}
              className={`d-flex justify-content-center align-items-center dashboard-filter-item ${userTypeFilter === item.key ? "dashboard-filter-item-selected" : ""}`}
              onClick={()=>_setUserTypeFilter(item.key)}
              >
            <div className="mx-0 mx-md-4">{item.label}</div>
        </div>)}
    </div>    
  );

}

