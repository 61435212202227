import React from 'react';
import { Footer } from 'components/common';
import { PredictionWizard, WizardStep } from 'components/user/prediction/PredictionWizard';
import { IStockSymbol } from 'interfaces/IStockSymbol';
import { FooterSignupLogin } from 'components/public/layout/FooterSignupLogin';

const LandingPageText = (): JSX.Element => {
  return (
    <div className="landing-page-text">
      <div className="landing-page-title">Where do you think the <span className="landing-page-highlight">S&P 500</span> will close?</div>
      <div className="landing-page-subtitle">Make your prediction below, and see what others are predicting!</div>
    </div>
  );
}

export const LandingPageV2: React.FunctionComponent<{hasLoginSignup?: boolean, hasDisclaimer?: boolean}> = ({hasLoginSignup = false, hasDisclaimer = false}) => {

  const initialSymbol: IStockSymbol = {
    symbolName: 'SPY'
  } as IStockSymbol


  const onClickClose = () => { };
  const onComplete = () => { };
  const onRetrySignup = () => { };
  const renderFooter = (): JSX.Element => {
    if (hasDisclaimer && hasLoginSignup) {
      return <FooterSignupLogin />
    }
    if (hasDisclaimer && !hasLoginSignup) {
      return <Footer />
    }
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="text-smaller text-lighter-charcoal mt-3">&copy; {new Date().getFullYear()} Predictagram.com</div>
      </div>
    );
  }

  return (
    <div className="container-fluid g-0 landing-page min-vh-100">

      <div className="container landing-v2 d-flex flex-column justify-content-center align-items-center">

        <div className="landing-v2-content d-flex flex-column justify-content-center align-items-center">
          <div className="landing-v2-logo d-flex">
            <img src="/images/logo.png" alt="Predictagram Stock Predictions" />
          </div>

          <div className="landing-v2-text">
            <LandingPageText />
          </div>

          <div className="landing-v2-form py-3">
            <PredictionWizard
              onClickClose={onClickClose}
              onComplete={onComplete}
              onRetrySignup={onRetrySignup}
              initialSymbol={initialSymbol}
              initialWizardStep={WizardStep.SET_PREDICTION}
            />
          </div>
        </div>

      </div>

      <div className="mt-5">
      {renderFooter()}
      </div>
    </div >
  );
}