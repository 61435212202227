import React, { useState } from 'react'
import { ActivityStat } from './ActivityStat'
import { IRecentActivity } from 'interfaces/IRecentActivity'
import { ModalTickets } from '../badges/ModalTickets'
import { Emoji } from 'components/common/Emoji'
import { TicketSvg } from '../tickets/TicketSvg'

interface IProps {
  activity: IRecentActivity,
}

export const Ticket: React.FunctionComponent<IProps> = ({ activity }) => {
  const [showTicket, setShowTicket] = useState<boolean>(false);

  if (!activity.items || (activity.items && activity.items.length === 0)) {
    return <></>
  }

  const TicketIcon = () => {
    return (
      <div style={{width: "2.1875rem", height: "2.1875rem"}}>
        <TicketSvg width={35} height={35}/>      
      </div>
    );
  }

  return (
    <>
      <ModalTickets show={showTicket} handleClose={() => setShowTicket(false)}  />
      <ActivityStat onClick={()=>setShowTicket(true)} icon={<TicketIcon />} activity={activity}>
        You've received an <span className="fw-bold">entry ticket</span> for earning <Emoji symbol="🎯" label="bullseye" text="" /><span className="fw-bold">{(activity.items[0].scoreAvg as number * 100).toFixed(0)}% accuracy</span> on <span className="fw-bold">{activity.items[0].predictionsCompleted} predictions!</span>
      </ActivityStat>
    </>

  )
}
