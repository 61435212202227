import { publicApiService } from './PublicApiService';

class TrackingService {

  constructor(protected logToConsole: boolean) {

  }

  public fireGoogleEvent = (event: { action: string, category: string, label: string }): void => {
    const { action, category, label } = event;
    if (this.logToConsole) {
      // console.log('from fireGoogleEvent', { event });
      return;
    }

    // ga-4
    window.gtag('event', action, {
      'send_to': 'G-5MSCKEXNVE',
      'event_category': category,
      'event_label': label
    });

    // UA
    window.gtag('event', action, {
      'send_to': 'UA-202899508-2',
      'event_category': category,
      'event_label': label
    });
  }

  public fireGoogleUnload(event: {category: string, label: string}) {
    const { category, label } = event;
    window.gtag('event', 'page_unload', {
      'event_category': category,
      'event_label': label,
      'send_to': 'G-5MSCKEXNVE',
      'transport_type': 'beacon',
    })

    window.gtag('event', 'page_unload', {
      'event_category': category,
      'event_label': label,
      'send_to': 'UA-202899508-2',
      'transport_type': 'beacon',
    })

  }

  public fireConvenaTracking = async (path: string, data: Object, uniqueUserId: string): Promise<any> => {
    try {
      await publicApiService.logUserEvent({ path, data }, uniqueUserId);
    } catch (error: any) {
      console.error({ error });
    }
  }
}

const trackingService = new TrackingService(process.env.NODE_ENV !== 'production');
export { trackingService }