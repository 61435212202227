import React from 'react';
import { Spinner } from 'components/common/Spinner';
import { Modal } from 'react-bootstrap';

export const ProcessingModal: React.FunctionComponent<{ show: boolean, handleClose: () => void, isProcessing: boolean, content?: any }> = ({ show, handleClose, isProcessing, content }) => {

  return (

    <Modal show={show} onHide={handleClose} size="lg" centered keyboard={false}>
      <Modal.Body>
        {isProcessing ?
          <>
            <div className="d-flex gap-2 text-14 bg-white text-black p-3">Processing <Spinner minHeight={25} /></div>
          </>
          :
          <>
            {content}
          </>}
      </Modal.Body>
    </Modal>


  )
}
