import { ITeam } from 'interfaces/league';
import React, { useEffect, useState } from 'react';
import { userLeagueApiService } from 'services/UserLeagueApiService';

export const TeamsHomePage: React.FunctionComponent = () => {

  const [teams, setTeams] = useState<ITeam[]>([]);

  useEffect(()=>{
    const _load = async () => {
      const teams = await userLeagueApiService.getTeamsPublic();
      setTeams(teams);
    }
    _load();
  }, [])

  return (
    <div>
      Team Home Page Placeholder

      {teams && 
      <pre>{JSON.stringify(teams, null, 2)}</pre>
      }

    </div>
  );
}