import React from 'react'
import { ProgressBar } from '../stats/ProgressBar'
import { IUserAchievementUserStatResponse } from 'services/UserAchievementService';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';
import { barColor } from '../rankings/LeagueRankingsPage';
import { LeagueTabEnum } from '../rankings/LeagueTabs';
import { BadgeHelper } from './Helper';

export const LevelProgress: React.FunctionComponent<{ userStat: IUserAchievementUserStatResponse }> = ({ userStat }) => {

  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(Url.USER_LEAGUE_RANKINGS)} role="button" className="ps-1 px-1 badge-black-hover rounded d-flex flex-column justify-content-center align-items-center">
      <LevelProgressNoLink userStat={userStat} />
    </div>
  )
}

export const LevelProgressNoLink: React.FunctionComponent<{ userStat: IUserAchievementUserStatResponse }> = ({ userStat }) => {
  const { currentLevel, levelMin, levelMax, progress, levelInfo } = BadgeHelper.getLevelProgress(userStat);
  const label = levelMax === null ? <span className="fw-bold">Max Level {levelMin}</span> : <><span className="fw-bold text-12">Lvl {currentLevel}</span></>

  return (
    <div className="d-flex gap-2">
    <div className={`mt-1 league-rank-image rank-${levelInfo.current.rank}`}></div>
    <div className="d-flex flex-column">
      <div className="align-self-start">{label}</div>
      <div style={{ height: "0.3125rem", width: "3.4375rem" }} className="user-stat-card-bar-progress"><ProgressBar progress={progress} colorFn={() => barColor[levelInfo.current.rank as LeagueTabEnum]} /></div>
    </div>
  </div>
  );
}
