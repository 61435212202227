import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useQuoteAtPredictionTime } from '_hooks/useQuoteAtPredictionTime';
import { useStockSymbolLastQuote } from '_hooks/useStockSymbolLastQuote';
import { IPrediction, PredictionTypeEnum, PredictionTypeHelper } from 'predictagram-lib';
import { predictionService } from 'services';
import { IPrice } from './PredictionLazy';
import { predictionMovement, symbolLink, valueRange } from './PredictionDetail';
import { NewYorkTz as tz } from 'predictagram-lib';
import { Helper } from '_utils';
import { QuoteAsOf } from 'components/public/Stock/QuoteAsOf';
import { UrlHelper } from '_utils/UrlHelper';

const LoadingCard = () => {
  return  <div className="shimmer bg-dark-gray" style={{height: "2.5rem", width: "18.75rem"}}></div>
}

export const PredictionDirectionQuote: React.FunctionComponent<{ prediction: IPrediction }> = ({ prediction }) => {

  const lastQuote = useStockSymbolLastQuote(prediction.stockSymbol, predictionService);
  const quoteAtPrediction = useQuoteAtPredictionTime(prediction, predictionService)
  const navigate = useNavigate();

  if (lastQuote.error) {
    return <div>{lastQuote.error.message}</div>
  }

  if (quoteAtPrediction.error) {
    return <div>{quoteAtPrediction.error.message}</div>
  }

  if (!prediction.createdAt || !prediction.typeId || !prediction.value || !prediction.valueDeviation) {
    return <div>Invalid Prediction</div>
  }

  if (lastQuote.status.loading || quoteAtPrediction.status.loading) {
    return <LoadingCard />
  }

  if (!quoteAtPrediction.quote) {
    return <></>
  }

  const price: IPrice = {
    closingPrice: lastQuote.quote.c ? lastQuote.quote.c.toFixed(2) : '',
    openPrice : lastQuote.quote.o ? lastQuote.quote.o.toFixed(2) : '',
    highPrice : lastQuote.quote.h ? lastQuote.quote.h.toFixed(2) : '',
    lowPrice : lastQuote.quote.l ? lastQuote.quote.l.toFixed(2) : '',
    lastPriceEpoch: lastQuote.quote.t,
    range: {
      high: prediction.value + (prediction.valueDeviation),
      low: prediction.value - prediction.valueDeviation,
      mid: prediction.value
    },
    isGreen: prediction.value > quoteAtPrediction.quote.c,
  }


  const PredictionDateFormat = (date: Date) => {
    const dt = tz.mdyhms(date);
    const dst = tz.getTimezoneOffset(date) === -5 ? 'EST' : 'EDT';
    const movement = predictionMovement(prediction.typeId as PredictionTypeEnum, price.isGreen);
    const priceClassName = `fw-bold ${price.isGreen ? 'text-bright-green' : 'text-bright-red'}`;
    return (
      <>
        <span className="text-14">Will {movement} <span className={priceClassName}>{valueRange(prediction, price)}</span> {PredictionTypeHelper.getPreposition(Number(prediction.typeId))} {Helper.roundUpToClosing(tz.format(date).hour24MinuteAmpm())} <span className="text-11 text-light-gray">{dst}</span> </span>
        <span className="text-11 text-light-gray">on {dt.month}/{dt.day}/{dt.year.substring(2)}</span>
      </>
    );
  }

  return (
    <div className="" >
      <div className=""
        role="button"
        onClick={() => navigate(UrlHelper.getSymbolUrl(prediction.stockSymbol))}>
        <div className="d-flex gap-2 align-items-center">
        {symbolLink(prediction)}
        <div className="align-self-end py-1"><QuoteAsOf quote={lastQuote.quote} /></div>
        </div>
      </div>

      <div className="mb-2">{PredictionDateFormat(new Date(prediction.valueAt * 1_000))}</div>
    </div>
  );
}
