import React from 'react'
import { GraphUpArrow, GraphDnArrow, RightArrow } from 'components/common/Icons'
import { Emoji } from 'components/common/Emoji'
import { IPublicProfile } from 'interfaces/IPublicProfile'
import { useNavigate } from 'react-router-dom'
import { UrlHelper } from '_utils/UrlHelper'

export const MyStatsBadge: React.FunctionComponent<{ profile: IPublicProfile }> = ({ profile }) => {
  const text = `${profile.broker.realizedProfitPct >= 0 ? "+" : ""}${((profile.broker.realizedProfitPct || 0)).toFixed(0)}%`;
  const navigate = useNavigate();

  return (
    <div
      role="button"
      onClick={()=>navigate(UrlHelper.getUserStatSymbol(profile.userId as number))}
      className="d-flex justify-content-center align-items-end gap-2 bg-off-black rounded px-2 py-2">
      <div className="d-flex flex-column px-2">
        <Emoji symbol="🎯" text={""} label="accuracy" />
        <div className="fw-bold text-12">{((profile.predictions.averageScore || 0) * 100).toFixed(0)}%</div>
      </div>

      <div className="badge-profitability">
        <div className="d-flex flex-column gap-1 align-items-center">{profile.broker.realizedProfitPct >= 0 ?
          <><GraphUpArrow /><div className="fw-bold text-bright-green text-12">{text}</div></>
          :
          <><GraphDnArrow /><div className="fw-bold text-bright-red text-12">{text}</div></>
        }
        </div>
      </div>

      <div className="align-self-center ps-1">
      <RightArrow />
      </div>

    </div>
  )
}
