import { APIState } from "_constants/APIState";
import { IQuote } from "components/public/Stock/IQuote";
import { StockHelper } from "predictagram-lib";
import { useCallback, useEffect, useRef, useState } from "react";
import { PredictionService } from "services";


export interface IStockDetail {
  yesterday: IQuote, today: IQuote
}

export function useStockDetail(symbolName: string, currentDate: Date, service: PredictionService) {

  const [stockDetail, setStockDetail] = useState<IStockDetail>({} as IStockDetail)
  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [error, setError] = useState<Error | undefined>(undefined);

  const serviceRef = useRef(service);
  const dateRef = useRef(currentDate);

  const load = useCallback(async () => {
    try {
      setApiState(APIState.LOADING);

      const previousDate = StockHelper.findPreviousTradingDay(dateRef.current);
      const [yesterday, today] = await Promise.all([
        serviceRef.current.getCloseQuote(symbolName, previousDate),
        serviceRef.current.getLastQuote(symbolName)
      ])
      setStockDetail({
        yesterday: yesterday,
        today: today
      });
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as Error);
    }
  }, [serviceRef, dateRef, symbolName])

  useEffect(() => {
    load();
  }, [load])

  return { item: stockDetail, apiState, error };

}