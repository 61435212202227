import React, {useRef, useState} from 'react'
import {Form, Formik} from "formik";
import {
  defaultCumeSearchOptions,
  getChartOptions,
  getShowLines,
  ICumeDataSearchOptions,
  ILinesAndAnnotations,
  linesAndAnnotationsOptions
} from '../../common/helper';
import {StrategyProfitModel as model} from 'models/strategy-profit.model';
import {StrategyFilterOptions} from '../strategy-profit/Filter';

import {CumulativeForm} from '../strategy-profit/CumulativeForm';
import {LinesAndOptions} from './LinesAndOptions';
import {SignalSetup} from '../strategy-profit/SignalSetup';
import {Debug} from 'components/common/Debug';
import {Analysis, AnalysisInputParams, FilterUserAverageScoreRange, StockTriggerType} from 'predictagram-lib';
import {ExpandContract} from 'components/common/ExpandContract';
import {StorageKey, Url} from '_constants';
import {useLocation, useNavigate} from 'react-router-dom';
import {Helper} from '_utils';
import {CumulativeChart} from "../../common/CumulativeChart";

export const AdminCumeDetailPage: React.FunctionComponent = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const useCache = queryParams.get('cached');

  const getDefaultCumeOptions = () => {
    const cached = localStorage.getItem(StorageKey.CUME_CONFIG);
    if (useCache && cached) {
      // default to one day chart.
      const parsed = JSON.parse(cached);
      const startTimePredMade = model.todayStart;
      const endTimePredMade = model.todayEnd;

      return {
        ...defaultCumeSearchOptions,
        ...parsed,
        startTimePredMade,
        endTimePredMade,
        //startTimePredMade
      }
    }
    return defaultCumeSearchOptions;
  }

  const getDefaultInitialValues = () => {
    const cached = localStorage.getItem(StorageKey.CUME_CONFIG);
    if (useCache && cached) {
      return {
        ...model.defVals,
        ...JSON.parse(cached),
        startTime: model.todayStart,
        endTime: model.todayEnd,
      }
    }
    // const vals = JSON.parse(JSON.stringify(model.defVals)) as AnalysisInputParams;
    // // @ts-ignore
    // vals.openSignalSetup.signals = [];
    // return vals;
    return model.defVals;

  }

  const [cumeSearchOptions, setCumeSearchOptions] = useState<ICumeDataSearchOptions>(getDefaultCumeOptions());
  const [initialValues, setInitialValues] = useState<StrategyFilterOptions>(getDefaultInitialValues());
  const [linesAndAnnots, setLinesAndAnnots] = useState<ILinesAndAnnotations>(linesAndAnnotationsOptions);
  const [loading, setLoading] = useState<boolean>(false);

  type SubmitMode = 'ANALYSIS'|'SUBMIT';
  const submitType = useRef<SubmitMode>();
  const navigate = useNavigate();


  const onSubmit = (data: StrategyFilterOptions&Analysis.InputParamsEx) => {
    const predictionIds = Helper.stringToArray(data.predictionIds);
    const predictionUserIds = Helper.stringToArray(data.predictionUserIds);
    // @TODO: refactor, send all submitted params
    const params = {
      ...cumeSearchOptions,
      openSignalSetup: data.openSignalSetup,
      stockSymbolId: data.stockSymbolId,
      startTimePredMade: data.startTime,
      endTimePredMade: data.endTime,
      extraSymbolIds: data.extraSymbolIds || [],
      predictionTypes: data.predictionTypes,
      predictionIds: predictionIds,
      predictionUserIds: predictionUserIds,
      startHourEst: data.startHourEst,
      endHourEst: data.endHourEst,
      userAverageScore: {
        days: 30,
        minScoreRaw: (data as any).last30dScore
      } as FilterUserAverageScoreRange,
      inputOptions: data.inputOptions,
      openTriggerType: StockTriggerType.SIGNAL,
    } as ICumeDataSearchOptions;
    if (submitType.current==='ANALYSIS') {
      navigate(Url.ADMIN_ANALYSIS_STRATEGY, {state:{analysisInputParams: params}});
    } else {
      setCumeSearchOptions(params);
    }
  }

  return (<div className="strategy-profit">

    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
      {({ values, touched, errors, setFieldValue, submitForm }) => {
        return <Form>
          <Debug><pre>{JSON.stringify(cumeSearchOptions, null, 2)}</pre></Debug>

          <div className="d-flex flex-row gap-1 align-items-start">
            <ExpandContract title="Chart Options">
              <LinesAndOptions linesAndAnnots={linesAndAnnots} setLinesAndAnnots={setLinesAndAnnots} />
            </ExpandContract>

            <div className="d-flex flex-column gap-1">

              <SignalSetup
                errors={errors}
                setFieldValue={setFieldValue}
                setupName='openSignalSetup'
                title='Open Signals'
                touched={touched}
                values={values}
                submitForm={submitForm}>

                {/*<DropDown enumMap={model.signalIntresectionInterval}*/}
                {/*  errors={errors}*/}
                {/*  touched={touched}*/}
                {/*  label="Intersec. Interval" name={`openSignalSetup.intersectionSecs`}*/}
                {/*  onChange={(v: any) => { setFieldValue(`openSignalSetup.intersectionSecs`, Helper.toIntOrDefault(v.target.value)) }}*/}
                {/*/>*/}

              </SignalSetup>

                {/* <div className="text-10 alert alert-info">* Uses "OR" logic. At least one of the Open Signals must be met.</div> */}
            </div>

            <div className="d-flex flex-column">
              <CumulativeForm
                errors={errors}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values} />

              <div className="d-flex align-self-end mt-2 gap-1">
                <button type="submit" onClick={()=>{submitType.current="SUBMIT"}} className="btn btn-primary">Submit</button>
                <button type="submit" onClick={()=>{submitType.current="ANALYSIS"}} className="btn btn-primary">Go To Analysis</button>
              </div>
            </div>
          </div>

          <CumulativeChart
            cumeDataSearchOptions={cumeSearchOptions}
            setIsLoading={setLoading}
            params={{
              // showBaseSignals: [],// values.openSignalSetup ? values.openSignalSetup.signals.map(s => s.type) : [],
              showLines: getShowLines(linesAndAnnots),
              extraSignals: [],
              showOptions: getChartOptions(linesAndAnnots),
            }} />

        </Form>
      }}
    </Formik>
  </div>)
}
