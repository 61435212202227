import React, { useEffect, useState } from 'react'
import { SelectDropDown } from 'components/common/form/SelectDropDown';
import { useAdminStockSymbols } from '_hooks/useAdminStockSymbols';

interface IProps {
  value: number | undefined,
  symbolIds?: number[],
  setValue: (value: number) => void,
  className?: string,
}

export const StockSymbolFilter:React.FunctionComponent<IProps> = ({value, setValue, className, symbolIds}) => {

  const api = useAdminStockSymbols().api;
  const [names, setNames] = useState<Map<number, string>>(new Map<number, string>());

  useEffect(()=>{
    if (api.apiState.isLoaded) {
      const names = new Map<number, string>();
      api.data.forEach(d=>{
        names.set(d.id, d.name);
      })
      setNames(names);
    }
  }, [symbolIds, api.apiState.isLoaded, api.data]);

  return (
    <>
    {api.apiState.isLoaded ? 
    <SelectDropDown<number, string> 
      onSelect={setValue}
      options={names}
      selected={value}
      title="Stock Symbol"
      className={className}
    />
    :
    <>...</>
    }
    </>
  )
}



