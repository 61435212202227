import React from "react";
import { IKeyValue } from "interfaces";
import { DropDown } from "./DropDown";

export const DropDownNumber: React.FunctionComponent<{
  errors: any;
  touched: any;
  enumMap: Map<number | string, string>;
  defaultItem?: IKeyValue<number>;
  defaultKey?: number | string,
  label: string | JSX.Element;
  name: string;
  setFieldValue: any;
  [key: string]: any;
}> = ({ errors, touched, enumMap, label, name, defaultItem, defaultKey, setFieldValue, ...rest }) => {

  const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = e.target.value;
    setFieldValue(name, selected === "" ? undefined : Number(selected));
  }

  return (
    <DropDown 
    enumMap={enumMap}
    errors={errors}
    label={label}
    name={name}
    touched={touched}
    defaultItem={defaultItem}
    defaultKey={defaultKey} 
    onChange={onChangeHandler}
    />
   
  );
};


