import { APIState } from "_constants/APIState";
import { useEffect, useRef, useState } from "react";

export interface IApiResponse<T, F> {
  data: T,
  error: string | null,
  apiState: {
    isLoading: boolean,
    isLoaded: boolean,
    isIdle: boolean,
    isError: boolean,
  },
  setFilter: (filter: F) => void,
  reload: () => void,
}
export function useApi<T, F>(loadfunction: (filter: F, abortSignal?: AbortSignal)=>Promise<T>, filter: F ): IApiResponse<T, F> {

  const [data, setData] = useState<T | null>(null);
  const [apiState, setApiState] = useState<APIState>(APIState.IDLE);
  const [error, setError] = useState<string | null>(null);
  const [currentFilter, setCurrentFilter] = useState<F>(filter);
  const [refresh, setRefresh] = useState<number>(0);

  const loadRef = useRef(async (filter:F, abortSignal: AbortSignal)=> {

    try {
      setApiState(APIState.LOADING);
      const data = await loadfunction(filter, abortSignal);
      setData(data as T);
      setApiState(APIState.LOADED);
    } catch (error: any) {
      setApiState(APIState.ERROR);
      setError((error as Error).message);
    }
  })

  useEffect(()=>{
    const abortController = new AbortController();
    if (loadRef.current) {
      loadRef.current(currentFilter, abortController.signal);
    }

    return ()=>{
      if (abortController) {
        abortController.abort();
      }
    }
  },[loadRef, currentFilter, refresh])

  const reload = () => {
    setRefresh(refresh + 1);
  }

  return {
    data: data as T,
    error,
    apiState: {
      isIdle: apiState === APIState.IDLE,
      isLoaded: apiState === APIState.LOADED,
      isLoading: apiState === APIState.LOADING,
      isError: apiState === APIState.ERROR
    },
    setFilter: setCurrentFilter,
    reload
  }

}
