import React from 'react'
import { SelectDropDown } from 'components/common/form/SelectDropDown';

interface IProps {
  value: number | undefined,
  setValue: (value: number) => void,
  className?: string,
  names: Map<number, string>
}

export const StockSymbolFilter2:React.FunctionComponent<IProps> = ({value, setValue, className, names}) => {

  return (
    <SelectDropDown<number, string> 
      onSelect={setValue}
      options={names}
      selected={value}
      title="Stock Symbol"
      className={className || ''}
    />
  )
}



