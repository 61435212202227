import React, { useEffect, useRef, useState } from 'react';
import { predictionService } from 'services/PredictionService';
import ReactApexChart from 'react-apexcharts';
import { ChartHelper } from 'components/public/Stock/ChartHelper';
import { IQuote } from 'components/public/Stock/IQuote';
import * as moment from 'moment-timezone';
import { ChartFilters } from 'components/public/Stock/ChartFilters';
import Form from 'react-bootstrap/Form'
import { TimeZone } from '_constants/TimeZone';
import { Helper } from '_utils';
import { StockHelper } from 'predictagram-lib';


interface IProps { 
  symbol: string, 
  scaled: boolean, 
  saved: boolean, 
  currentDate: Date, onToggle: (date: string, isOn: boolean) => void, 
  previousDate?: Date, 
  fullXAxis?: boolean,
  lazyLoad?: boolean,
  refresh: number,
  onLoad?: (name: string) => void,
 }


const isInViewPort = (element: HTMLDivElement): boolean => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >=0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}


export const SingleChart: React.FunctionComponent<IProps> = (
  { 
    symbol, scaled, saved, currentDate, onToggle, previousDate, fullXAxis = false, lazyLoad = true, refresh, onLoad
  }) => {

  const ref = useRef<HTMLDivElement>(null);
  const svgRef = useRef<HTMLDivElement>(null);

  const [options, setOptions] = useState<any>(undefined);
  const [save, setSave] = useState<boolean>(false);
  const [ loaded, setLoaded] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ loadCount, setLoadCount ] = useState<number>(0);
  const [ priceChartData, setPriceChartData] = useState<any>(undefined);
  const [ pctChartData, setPctChartData] = useState<any>(undefined);
  
  useEffect(()=>{
    // if (!loading && ref.current && isInViewPort(ref.current)) {
      //console.log('calling loadData');
      const _load = async () => {
        await loadData();
        // if (svgRef.current) {
        //   console.log(svgRef.current.innerHTML);
        // }
      }
      _load();

    // }
  }, [refresh] );

  useEffect(()=>{
    const options: ApexCharts.ApexOptions = {
      colors: ['#000'],
      stroke: {
        // dashArray: [5, 10, 10],
        colors: ['#000'],
        width: 1,
      },
      xaxis: {
        labels: {
          show: true,
          hideOverlappingLabels: true,
          // formatter: function (value, timestamp, opts) {
          //   return [0, 15, 30, 45].includes(new Date(value).getMinutes()) ? value : '';
          // }
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        labels: {
          colors: ['#458ff6'],
          useSeriesColors: false
        }
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value: number) => {
            return `${parseFloat(value.toString()).toFixed(3)}`;
          }
        }
      },
      plotOptions: {
        candlestick: {
          colors: scaled ? {
            upward: '#3C90EB',
            downward: '#3C90EB'
          } : {
            upward: '#3C90EB',
            downward: '#DF7D46'
          }
        } 
      },
      annotations: {
        xaxis: [
          {
            x: `${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')} 09:30`,
            borderColor: '#000000',
          },
          {
            x: `${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')} 12:00`,
            borderColor: '#000000',
          },
          {
            x: `${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')} 14:30`,
            borderColor: '#000000',
          },
        ]
      }
    }
    setOptions(options);    
  }, [scaled])

  const loadData = async () => {
    setLoading(true);
    const p = StockHelper.workingHours(previousDate);
    const c = StockHelper.workingHours(currentDate);
    const priorDay: ReadonlyArray<IQuote> = previousDate ? await predictionService.getBarsData(symbol, p.start, p.end, true) : [];
    const _today: ReadonlyArray<IQuote> = await predictionService.getBarsData(symbol, c.start, c.end, false);

    const today = _today.filter(ChartFilters.tradingHours);
    const prior2Hours = priorDay.filter(ChartFilters.last2Hours);
    setPriceChartData([{
      name: 'Today',
      type: 'line',
      data: !!!today ? ChartHelper.mergeFullXAxis([...prior2Hours, ...today].map(ChartHelper.toLineData), 1) :
        scaled ? [...prior2Hours, ...today].map(ChartHelper.deltaPct) : [...prior2Hours, ...today].map(ChartHelper.toLineData),
    }])

    setPctChartData([
      {
        name: 'Today',
        type: 'line',
        data: !!!today ? ChartHelper.mergeFullXAxis([...prior2Hours, ...today].map(ChartHelper.deltaPct), 1) : [...prior2Hours, ...today].map(ChartHelper.deltaPct)
      }
    ])

    setLoaded(true);
    setLoading(false);
    if (onLoad) {
      onLoad(`${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')}`);
    }
  }

  // useEffect(() => {

  //   if (!lazyLoad) {
  //     if (!loading && !loaded && ref.current && isInViewPort(ref.current)) {
  //       console.log(`loading ${currentDate.toLocaleDateString()}`);
  //       loadData();
  //     }
  //   }

  //   setLoadCount(loadCount + 1);

  // }, [])

  const toggle = (isOn: boolean) => {
    const date = moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD');
    onToggle(date, isOn);
  }
  
  const getSeries = () => {
    return scaled ? pctChartData : priceChartData
  }

  return (
    <>
      {/* <h1>{loadCount}</h1> */}
      <div className='d-flex justify-content-left' style={{backgroundColor: '#007c7c38'}} ref={ref}>
        <div className="fw-bold py-3 px-3 align-self-center" style={{backgroundColor: '#007c7c38'}} >{symbol} - {moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('ddd, YYYY-MM-DD')}</div>
        <div className="align-self-center mx-3">
          <Form.Check 
              type="switch"
              id="save-candidate"
              label={`Save as Candidate`}
              defaultChecked={saved}
              onClick={()=>{toggle(!save); setSave(!save);}}
            /></div>
      </div>

      {!getSeries() && !loaded && lazyLoad && 
        <div style={{ width: "900px", height: "650px" }} className="border d-flex justify-content-center flex-column">
          <div className="spinner-border align-self-center" role="status">
          </div>
          <span className="align-self-center">Loading {moment.tz(currentDate,TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')}</span>
        </div>
      }

      {!getSeries() && loading && 
        <div style={{ width: "900px", height: "650px" }} className="border d-flex justify-content-center flex-column">
          <div className="spinner-border align-self-center" role="status">
            <span className="visually-hidden">Loading {moment.tz(currentDate,TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')}</span>
          </div>
        </div>
      }

      {getSeries() && options && loaded && <div ref={svgRef}>
        <div id={`${symbol}-${moment.tz(currentDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')}`}>
        <ReactApexChart options={options} series={getSeries()} type="line" height={650} width="1000px"/>
        </div>
      </div>
      }

    </>
  );
}
