import React from 'react'
import { SymbolStat } from './SymbolStat'
import { useUserStatsStockSymbol } from '_hooks/useUserStatsStockSymbol'
import { useOutletContext } from 'react-router-dom'
import { IPublicProfile } from 'interfaces/IPublicProfile'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { Spinner } from 'react-bootstrap'
import { userPredictionApiService } from 'services/UserPredictionApiService'
import { DarkCard } from 'components/common/DarkCard'
import { Emoji } from 'components/common/Emoji'
import { ShareSymbolStats } from 'components/common/ShareSymbolStats'
import { useUserProfileStats } from '_hooks/useUserProfileStats'


export const ProfileStats: React.FunctionComponent<{ userId: number, startTime?: number, endTime?: number }> = ({ userId, startTime, endTime }) => {

  const profileStats = useUserProfileStats(userPredictionApiService, userId, startTime, endTime)
  
  if (profileStats.apiState.isLoading()) {
    return <Spinner />
  }

  if (profileStats.apiState.isLoaded()) {

    const stats = profileStats.item;
    return (
      <div>
        <div className="fw-bold text-25 text-end"><Emoji symbol="🎯" text={""} label="accuracy" />{((stats.averageScore || 0) * 100).toFixed(0)}%</div>
        <div className="text-12 text-light-gray text-end">{stats.predictionsCompleted} predictions</div>
      </div>
    );
  }

  if (profileStats.apiState.isError()) {
    return <ErrorMessage>{profileStats.error?.message}</ErrorMessage>
  }

  return <></>
}

export const UserStatsStockSymbol: React.FunctionComponent = () => {

  const { userId, startTime, profile, endTime } = useOutletContext<{ userId: number, profile: IPublicProfile, startTime: number, endTime: number }>()

  const stats = useUserStatsStockSymbol(userPredictionApiService, userId, undefined, startTime, endTime);

  if (stats.apiState.isLoading()) {
    return <Spinner />
  }

  if (stats.apiState.isLoaded()) {
    if (stats.items === null || stats.items.length === 0) {
      return <ErrorMessage className="my-3">No data</ErrorMessage>
    }

    return (
      <>
            {/* {startTime ? <pre>{new Date(startTime * 1000).toLocaleString()} {startTime}</pre> : <pre>No startTime</pre>}
            {endTime ? <pre>{new Date(endTime * 1000).toLocaleString()} {endTime}</pre> : <pre>No endTime</pre>} */}
        <DarkCard className="my-3">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <div className="fw-bold">Prediction Accuracy</div>
                {/* <div className="text-12 text-light-gray">in the top {`{rankpct}`} of predictors</div> */}
              </div>

              <ProfileStats userId={profile.userId as number} startTime={startTime} endTime={endTime}/>
            </div>
            <div className="pill bg-black px-3"><ShareSymbolStats userId={profile.userId as number} username={profile.username}/></div>
          </div>
        </DarkCard>
        <div className="user-stats-items d-flex justify-content-center flex-column align-items-center gap-2 w-100">
          {stats.items.map((s, i) => <div key={`symbol-stat-key${i}`} className="w-100"><SymbolStat stat={s} userId={userId} /></div>)}
        </div>

      </>
    )
  }

  if (stats.error) {
    return <ErrorMessage>{stats.error}</ErrorMessage>
  }

  return <></>
}
