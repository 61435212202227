import React, { useEffect, useState, useCallback } from 'react'
import { useAdminStockSymbols } from '_hooks/useAdminStockSymbols';
import { IAutoTradePrice, adminAutoTradesService } from 'services/AdminApiService'

export const PriceLookup: React.FunctionComponent<{ symbolId: number }> = ({ symbolId }) => {

  const [price, setPrice] = useState<IAutoTradePrice | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const {getById, api} = useAdminStockSymbols();

  const _load = useCallback(async () => {
    setLoaded(false);
    setError(undefined);
    try {
      const price = await adminAutoTradesService.getLiveSymbolPrices(symbolId)
      setPrice(price);
      setLoaded(true);
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, [symbolId]);


  useEffect(() => {
    _load();
    const interval = setInterval(_load, 30000);
    return () => clearInterval(interval);
  }, [])


  // if (error) {
  //   return <></>
  // }

  if (loaded) {
    return (price ? <tr key={`key-${symbolId}`}>
      <td>{api.apiState.isLoaded ? getById(symbolId)?.name : '...'}</td>
      <td align="right">{price.bidPrice ? price.bidPrice.toFixed(2) : ""}</td>
      <td align="right">{price.bidPrice ? price.bidPrice.toFixed(2) : ""}</td>
      <td align="right">{price.lastPrice ? price.lastPrice.toFixed(2) : ""}</td>
      <td align="right">{price.bestTradePrice ? price.bestTradePrice.toFixed(2) : ""}</td>
    </tr> : <></>)

  }

  return <></>
}