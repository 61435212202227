import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ISearchOptions, userPredictionApiService } from 'services/UserPredictionApiService';
import { PredictionListLazy } from '../ListingsPage/components/PredictionListLazy';
import { ConsensusChart } from './ConsensusChart';
import { NewYorkTz, PredictionTypeEnum, StockHelper } from 'predictagram-lib';
import { usePublicPrediction } from '_hooks/usePublicPrediction';
import { APIState } from '_constants/APIState';
import { Spinner } from 'react-bootstrap';
import { sortTypeEnum } from '../ListingsPage/components/Prediction/SortTypeSelect';
import { PredictionCountHelper } from '_utils/PredictionCountHelper';
import { TheresMoreModal } from './TheresMoreModal';
import { useAccountSettings } from '_hooks/useAccountSettings';
import * as Icons from 'components/common/Icons';
import { StockPriceChange } from './StockPriceChange';
import { Helmet } from 'react-helmet';
import { StorageKey } from '_constants';
import { useApiSignals } from '_hooks/useApiSignals';
import { IUserSignalAlertSearch } from 'services/UserSignalApiService';

export const hasPredictionsToday = (): boolean => {
  const predictionsToday = PredictionCountHelper.get(NewYorkTz.getDateMidnight(new Date()).getTime(), localStorage) || 0;
  return predictionsToday > 0;
}

export const StockListingPage: React.FunctionComponent = () => {

  const { stockSymbol: _stockSymbol } = useParams();
  const stockSymbol = _stockSymbol?.toUpperCase();
  const location = useLocation();
  const [predTypes, setPredTypes] = useState<PredictionTypeEnum[]>([]);
  const { settings: userSettings, apiState: settingsApiState, updateSettings } = useAccountSettings();
  const { sortType, team, referrerProfile } = location.state || { sortType: sortTypeEnum.LATEST };
  const [showTeamPrompt, setShowTeamPrompt] = useState<boolean>(team === undefined && referrerProfile); // show if user team is not defined and has a refererr
  const navigate = useNavigate();

  const signalApi = useApiSignals({symbolName: stockSymbol} as IUserSignalAlertSearch);

  const [showWalkthrough, setShowWalkthrough] = useState<boolean>(localStorage.getItem(StorageKey.SEEN_WALKTHROUGH_CONSENSUS) === null);

  const { items: predictions, apiState, setSort, options } = usePublicPrediction(userPredictionApiService, {
    orderBy: sortType || sortTypeEnum.LATEST,
    symbolNames: [stockSymbol?.toUpperCase()],
  } as ISearchOptions);

  const onClickWatch = async (exists: boolean) => {
    const watched = userSettings.symbolsWatchlist;
    if (exists) {
      // remove
      const updatedWatched = watched?.filter((s: string) => s !== stockSymbol);
      if (updatedWatched && updatedWatched.length === 0) {
        await updateSettings({symbolsWatchlist: null})
      } else {
        await updateSettings({symbolsWatchlist: updatedWatched })
      }
      return;
    }
    // add
    if (watched === null) {
      await updateSettings({symbolsWatchlist: [stockSymbol]})
    } else {
      await updateSettings({symbolsWatchlist: [...watched, stockSymbol]})
    }
  }

  const AddToWatchlistButton = () => {
    const exists = userSettings.symbolsWatchlist?.includes(stockSymbol as string) || false;
    return (
      <>
        {settingsApiState === APIState.LOADED &&
          <button className="btn btn-secondary text-14" type="button" onClick={()=>onClickWatch(exists)}>{`${exists ? "- Remove from Watchlist" : "+ Add To Watchlist"}`}</button>
        }
      </>
    );
  }

  const StockDetail = () => {
    return (
      <div className="stock-detail d-flex flex-row align-items-center">
        <div className="me-3 stock-detail-symbol">{stockSymbol}</div>
        {stockSymbol && (StockHelper.isMarketOpen(new Date()) || StockHelper.isPostMarketHours(new Date())) && <StockPriceChange stockSymbol={stockSymbol}/>}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Predictagram: {`${stockSymbol} Consensus Predictions`}</title>
      </Helmet>

    <div className="container">
      {showTeamPrompt &&
        <TheresMoreModal referrerProfile={referrerProfile} team={team} show={showTeamPrompt} handleClose={() => setShowTeamPrompt(false)} />
      }

      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-start align-items-center gap-3 mb-2">
          <div role="button" onClick={()=>navigate(-1)}><Icons.LeftArrow /></div>
          <StockDetail />
        </div>
        <AddToWatchlistButton />
      </div>

      {stockSymbol && signalApi.apiState.isLoaded && <ConsensusChart signalAlerts={signalApi.data} stockSymbol={stockSymbol} predictionTypes={predTypes} showWalkthrough={showWalkthrough} setShowWalkthrough={setShowWalkthrough}/>}

      {apiState === APIState.LOADING &&
        <Spinner />
      }

      {apiState === APIState.LOADED && predictions.length > 0 &&
        <div className="my-3"><PredictionListLazy predictions={predictions} onClickFilterTab={setPredTypes} onSelectSort={setSort} orderBy={options?.orderBy || sortTypeEnum.BEST} /></div>
      }
    </div>
    </>

  );
}
