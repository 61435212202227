import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFollowers } from '_hooks/useFollowers';
import { Spinner } from 'components/common/Spinner';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar';
import { IFollower, userPredictionApiService } from 'services/UserPredictionApiService';
import { usePublicProfile } from '_hooks/usePublicProfile';
import { Helper } from '_utils';
import { RoundBackButton } from 'components/common/buttons/RoundBackButton';
import { Helmet } from 'react-helmet';


export const Follower: React.FunctionComponent<{ follower: IFollower }> = ({ follower }) => {
  const api = usePublicProfile(follower.userId, userPredictionApiService);
  if (api.error) {
    return <>Cannot load profile</>
  }
  if (api.loaded) {
    const profile = api.item;
    return (
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <div className="activity-avatar">
          <Avatar profile={profile} size={AvatarSizeEnum.SMALL} />
          </div>
          <div className="text-end text-gray fst-italic text-12">
          {follower.createdAt &&
            <>{Helper.timeElapsed(new Date(follower.createdAt * 1000), new Date())} ago</>
          }
          </div>
        </div>
        <div className="my-2 text-14"><span className="fw-bold">{profile.username}</span> <span className="text-gray">followed you</span></div>
      </div>
    );
  }
  return <Spinner />
}

export const DashboardFollowers: React.FunctionComponent = () => {

  const api = useFollowers();
  const navigate = useNavigate();

  if (api.apiState.isLoading) {
    return <Spinner />
  }

  if (api.apiState.isError) {
    return <ErrorMessage>{api.error}</ErrorMessage>
  }

  if (api.apiState.isLoaded) {
    const followers = api.data;

    return (
      <>
      <Helmet>
        <title>Predictagram: My Followers</title>
      </Helmet>      
      <div>
        <div className="d-flex align-items-center gap-2 mx-3">
          <RoundBackButton onClick={() => navigate(-1)} /><div className="fw-bold text-18 my-3">My Followers</div>
        </div>

        {followers && followers.length > 0 &&
          followers.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map((f, i: number) => <div className="my-3 border-bottom border-color-darkest-gray" key={`follower-key-${i}`}>
            <div className="mx-3 py-2">
              <Follower follower={f} />
            </div>
          </div>)
        }

        {followers && followers.length === 0 &&
          <div className="mt-5">You have no followers.</div>
        }
      </div>
      </>
    );

  }

  return <ErrorMessage>Unknown State</ErrorMessage>

}
