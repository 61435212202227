import React from "react";
import { Url } from "_constants";
import { ITeam } from "interfaces/league";
import { useNavigate } from "react-router-dom";
import { Helper } from "_utils";

export const TeamStatistic: React.FunctionComponent<{team: ITeam, index: number, showRank?: boolean}> = ({team, index, showRank=true}) => {
  const navigate = useNavigate();
  const url = Url.USER_TEAM_DETAIL.replaceAll(':teamId', String(team.id));

  return (
    <>
      {showRank &&
      <div className="col-1 d-flex align-items-center">{index}</div>
    }
      <div className="col-5 d-flex align-items-center">
        <div className="d-flex gap-2 align-items-center" role="button" onClick={()=>navigate(url)}>
          <div className="team-roster-item-image"><img src={team.logoUrl} alt={team.name} /></div>
          <div>{team.name}</div>
        </div>
      </div>
      <div className="col-3 d-flex align-items-center team-roster-item-accuracy"><span role="img" aria-label="accuracy">🎯</span> {team.score !== null ? `${(team.score * 100).toFixed(1)}%` : "TBD"}</div>
      <div className="col-3 d-flex align-items-center align-items-center gap-1">{team.predictionsCompleted} <span className='text-smaller fw-normal gap-1'>
        <div className="d-none d-md-block">{Helper.pluralize('prediction', team.predictionsCompleted)}</div>
        <div className="d-block d-md-none">{Helper.pluralize('pred', team.predictionsCompleted)}</div>
        </span></div>
      
    </>
  );

}