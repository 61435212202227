import {IStockDay} from "../interface";
import {DateHelper} from "../utils";
import {SearchHelper} from "../utils/search.helper";

export class NewYorkStockHelper {



    protected static start = 1641013200;
    protected static end = 1735707599;

    protected static dayStartShiftOpenSecs = -34200; // -9.4h secs
    protected static dayEndShiftOpenSecs = 52200-1; // +14.5h secs -1 sec to exclude 12:00AM
    protected static fullOpenDurationSecs = 23400; // 6.5h secs

    protected static findPos(timeSecs:number) {
        // throw error if provided timeSec is our of pre-generated range
        if ( timeSecs<this.start/*this.hours[0][0]+this.dayBeginShiftOpenSecs*/ ||
                timeSecs>this.end/*this.hours[len-1][0]+this.dayEndShiftOpenSecs*/) {
            throw new RangeError();
        }
        // let m = 0;
        // let n = this.hours.length - 1;
        // while (m <= n) {
        //     // tslint:disable-next-line:no-bitwise
        //     const k = (n + m) >> 1;
        //     const openT = this.hours[k][0];
        //     // const closeT = this.hours[k][1];
        //     const cmp = timeSecs<openT+this.dayStartShiftOpenSecs ? -1 :
        //                 (timeSecs>openT+this.dayEndShiftOpenSecs?1: 0);
        //     if (cmp > 0) {
        //         m = k + 1;
        //     } else if(cmp < 0) {
        //         n = k - 1;
        //     } else {
        //         return k;
        //     }
        // }
        // // tslint:disable-next-line:no-bitwise
        // return ~m;

        return SearchHelper.binarySearch(this.hours, (item)=>{
            const openT = item[0];
            // const closeT = this.hours[k][1];
            const cmp = timeSecs<openT+this.dayStartShiftOpenSecs ? -1 :
                        (timeSecs>openT+this.dayEndShiftOpenSecs?1: 0);
            return cmp;
        })
    }
    // protected static checkWithin = (nowSecs: number, hPos:number)=> {
    //     const openH = this.hours[hPos][0];
    //     return nowSecs>=openH+this.dayBeginShiftOpenSecs && nowSecs<=openH+this.dayEndShiftOpenSecs
    // }

    public static isTradingDay(nowSecs: number) {
        const fPos = this.findPos(nowSecs);
        return fPos>=0;
    }

    public static getIfIsTradingDay(nowSecs:number): null|IStockDay {
        const fPos = this.findPos(nowSecs);
        if (fPos<0) {
            return null;
        }
        return this.posToTradingDay(fPos, nowSecs);
    }

    protected static getHoursByPos(pos:number) {
        if (pos<0 || pos>this.hours.length-1) {
            throw new RangeError();
        }
        const d = this.hours[pos];
        return d;
    }

    protected static posToTradingDay(pos:number, nowInSecs?:number, nowInOpenShift?:number): IStockDay {
        const d = this.getHoursByPos(pos);
        const open = d[0];
        const closeInc = d[1]-1;
        const self = this;
        if (nowInSecs==null && nowInOpenShift==null) {
            throw new Error('invalid now');
        }
        nowInSecs = nowInSecs as number;
        // open is same time always, use difference to return same minute:second for nex/prev. It can be EDT/EST change
        const nowShift = nowInOpenShift !=null ? nowInOpenShift : open - nowInSecs;
        const nowSecs = open - nowShift;
        return {
            diffDays(day: IStockDay): number {
                return pos - self.findPos(day.openAt());
            },
            endAt(): number {
                return open+self.dayEndShiftOpenSecs;
            }, startAt(): number {
                return open+self.dayStartShiftOpenSecs;
            },
            isOpen(): boolean {
                return nowSecs>=open && nowSecs<=closeInc;
            },
            now(): number {
                return nowSecs;
            },
            closeAt(): number {
                return closeInc;
            },
            isPostmarket(): boolean {
                return nowSecs>closeInc && nowSecs<=open+self.dayEndShiftOpenSecs;
            },
            isPremarket(): boolean {
                return nowSecs >= open + self.dayStartShiftOpenSecs && nowSecs < open;
            },
            isHalfDay(): boolean {
                return closeInc-open < self.fullOpenDurationSecs-1;
            },
            openAt(): number {
                return open;
            },
            prevDay(): IStockDay {
                return this.prevNext(-1);
            },
            nextDay(): IStockDay {
                return this.prevNext(1);
            },
            prevNext(step:number): IStockDay {
                return self.posToTradingDay(pos+step, undefined, nowShift);
            }
        };
    }

    /**
     * Including current day, preserve provided  hh:mm:ss for found date
     * @param nowSecs
     * @param searchStepDays
     */
    static findTradingDay(nowSecs: number, searchStepDays:-1|1/*, startShiftDays: number = 0*/) {
        // if (searchStepDays===0) {
        //     throw new Error('invalid searchStepDays');
        // }

        const posInit = this.findPos(nowSecs);
        if (posInit>=0) {
            return this.posToTradingDay(posInit, nowSecs);
            // pos = -pos+searchStepDays;
        }
        const posInsert = -posInit-1;
        const posRet = searchStepDays===1 ? posInsert : posInsert-1;
        const posRetH = this.getHoursByPos(posRet);
        const posNearH = this.getHoursByPos(posRet - searchStepDays);
        // detect timezone change, open hour shift
        const diff = posRetH[0]-posNearH[0];
        const remainder = Math.abs(diff)%86400;
        const dayStart = posRetH[0] + this.dayStartShiftOpenSecs;
        if (remainder===0) {
            // nowSecs is between dayOneStart - dayTwoEnd
            const dayOpen = Math.min(posRetH[0], posNearH[0]) + this.dayStartShiftOpenSecs;
            // const max = Math.max(posRetH[0], posNearH[0]) + this.dayEndShiftOpenSecs;
            const secsShiftDayStar = (nowSecs - dayOpen) % 86400 // days(secs) difference between dayStar, find day offset secs
            // const secsShiftOpen = secsShiftDayStar + dayStart;
            return this.posToTradingDay(posRet, dayStart+secsShiftDayStar);

        } else {
            // it was a shift, need to determine hh:mm:ss for EST timezone for current point
            const usData = DateHelper.extractDateTimeUs(nowSecs);
            return this.posToTradingDay(posRet, dayStart + usData.hour*3600+usData.minute*60+usData.second);
        }
    }


    // static stockHours() {
    //     return this.hours;
    // }

    /**
     * Start-end unix secs pairs until 2025(exclusive)
     * @protected
     */
    protected static hours = [
        [1641220200, 1641243600], //  [1/3/22, 9:30 AM]-[1/3/22, 4:00 PM]
        [1641306600, 1641330000], //  [1/4/22, 9:30 AM]-[1/4/22, 4:00 PM]
        [1641393000, 1641416400], //  [1/5/22, 9:30 AM]-[1/5/22, 4:00 PM]
        [1641479400, 1641502800], //  [1/6/22, 9:30 AM]-[1/6/22, 4:00 PM]
        [1641565800, 1641589200], //  [1/7/22, 9:30 AM]-[1/7/22, 4:00 PM]
        [1641825000, 1641848400], //  [1/10/22, 9:30 AM]-[1/10/22, 4:00 PM]
        [1641911400, 1641934800], //  [1/11/22, 9:30 AM]-[1/11/22, 4:00 PM]
        [1641997800, 1642021200], //  [1/12/22, 9:30 AM]-[1/12/22, 4:00 PM]
        [1642084200, 1642107600], //  [1/13/22, 9:30 AM]-[1/13/22, 4:00 PM]
        [1642170600, 1642194000], //  [1/14/22, 9:30 AM]-[1/14/22, 4:00 PM]
        [1642516200, 1642539600], //  [1/18/22, 9:30 AM]-[1/18/22, 4:00 PM]
        [1642602600, 1642626000], //  [1/19/22, 9:30 AM]-[1/19/22, 4:00 PM]
        [1642689000, 1642712400], //  [1/20/22, 9:30 AM]-[1/20/22, 4:00 PM]
        [1642775400, 1642798800], //  [1/21/22, 9:30 AM]-[1/21/22, 4:00 PM]
        [1643034600, 1643058000], //  [1/24/22, 9:30 AM]-[1/24/22, 4:00 PM]
        [1643121000, 1643144400], //  [1/25/22, 9:30 AM]-[1/25/22, 4:00 PM]
        [1643207400, 1643230800], //  [1/26/22, 9:30 AM]-[1/26/22, 4:00 PM]
        [1643293800, 1643317200], //  [1/27/22, 9:30 AM]-[1/27/22, 4:00 PM]
        [1643380200, 1643403600], //  [1/28/22, 9:30 AM]-[1/28/22, 4:00 PM]
        [1643639400, 1643662800], //  [1/31/22, 9:30 AM]-[1/31/22, 4:00 PM]
        [1643725800, 1643749200], //  [2/1/22, 9:30 AM]-[2/1/22, 4:00 PM]
        [1643812200, 1643835600], //  [2/2/22, 9:30 AM]-[2/2/22, 4:00 PM]
        [1643898600, 1643922000], //  [2/3/22, 9:30 AM]-[2/3/22, 4:00 PM]
        [1643985000, 1644008400], //  [2/4/22, 9:30 AM]-[2/4/22, 4:00 PM]
        [1644244200, 1644267600], //  [2/7/22, 9:30 AM]-[2/7/22, 4:00 PM]
        [1644330600, 1644354000], //  [2/8/22, 9:30 AM]-[2/8/22, 4:00 PM]
        [1644417000, 1644440400], //  [2/9/22, 9:30 AM]-[2/9/22, 4:00 PM]
        [1644503400, 1644526800], //  [2/10/22, 9:30 AM]-[2/10/22, 4:00 PM]
        [1644589800, 1644613200], //  [2/11/22, 9:30 AM]-[2/11/22, 4:00 PM]
        [1644849000, 1644872400], //  [2/14/22, 9:30 AM]-[2/14/22, 4:00 PM]
        [1644935400, 1644958800], //  [2/15/22, 9:30 AM]-[2/15/22, 4:00 PM]
        [1645021800, 1645045200], //  [2/16/22, 9:30 AM]-[2/16/22, 4:00 PM]
        [1645108200, 1645131600], //  [2/17/22, 9:30 AM]-[2/17/22, 4:00 PM]
        [1645194600, 1645218000], //  [2/18/22, 9:30 AM]-[2/18/22, 4:00 PM]
        [1645540200, 1645563600], //  [2/22/22, 9:30 AM]-[2/22/22, 4:00 PM]
        [1645626600, 1645650000], //  [2/23/22, 9:30 AM]-[2/23/22, 4:00 PM]
        [1645713000, 1645736400], //  [2/24/22, 9:30 AM]-[2/24/22, 4:00 PM]
        [1645799400, 1645822800], //  [2/25/22, 9:30 AM]-[2/25/22, 4:00 PM]
        [1646058600, 1646082000], //  [2/28/22, 9:30 AM]-[2/28/22, 4:00 PM]
        [1646145000, 1646168400], //  [3/1/22, 9:30 AM]-[3/1/22, 4:00 PM]
        [1646231400, 1646254800], //  [3/2/22, 9:30 AM]-[3/2/22, 4:00 PM]
        [1646317800, 1646341200], //  [3/3/22, 9:30 AM]-[3/3/22, 4:00 PM]
        [1646404200, 1646427600], //  [3/4/22, 9:30 AM]-[3/4/22, 4:00 PM]
        [1646663400, 1646686800], //  [3/7/22, 9:30 AM]-[3/7/22, 4:00 PM]
        [1646749800, 1646773200], //  [3/8/22, 9:30 AM]-[3/8/22, 4:00 PM]
        [1646836200, 1646859600], //  [3/9/22, 9:30 AM]-[3/9/22, 4:00 PM]
        [1646922600, 1646946000], //  [3/10/22, 9:30 AM]-[3/10/22, 4:00 PM]
        [1647009000, 1647032400], //  [3/11/22, 9:30 AM]-[3/11/22, 4:00 PM]
        [1647264600, 1647288000], //  [3/14/22, 9:30 AM]-[3/14/22, 4:00 PM]
        [1647351000, 1647374400], //  [3/15/22, 9:30 AM]-[3/15/22, 4:00 PM]
        [1647437400, 1647460800], //  [3/16/22, 9:30 AM]-[3/16/22, 4:00 PM]
        [1647523800, 1647547200], //  [3/17/22, 9:30 AM]-[3/17/22, 4:00 PM]
        [1647610200, 1647633600], //  [3/18/22, 9:30 AM]-[3/18/22, 4:00 PM]
        [1647869400, 1647892800], //  [3/21/22, 9:30 AM]-[3/21/22, 4:00 PM]
        [1647955800, 1647979200], //  [3/22/22, 9:30 AM]-[3/22/22, 4:00 PM]
        [1648042200, 1648065600], //  [3/23/22, 9:30 AM]-[3/23/22, 4:00 PM]
        [1648128600, 1648152000], //  [3/24/22, 9:30 AM]-[3/24/22, 4:00 PM]
        [1648215000, 1648238400], //  [3/25/22, 9:30 AM]-[3/25/22, 4:00 PM]
        [1648474200, 1648497600], //  [3/28/22, 9:30 AM]-[3/28/22, 4:00 PM]
        [1648560600, 1648584000], //  [3/29/22, 9:30 AM]-[3/29/22, 4:00 PM]
        [1648647000, 1648670400], //  [3/30/22, 9:30 AM]-[3/30/22, 4:00 PM]
        [1648733400, 1648756800], //  [3/31/22, 9:30 AM]-[3/31/22, 4:00 PM]
        [1648819800, 1648843200], //  [4/1/22, 9:30 AM]-[4/1/22, 4:00 PM]
        [1649079000, 1649102400], //  [4/4/22, 9:30 AM]-[4/4/22, 4:00 PM]
        [1649165400, 1649188800], //  [4/5/22, 9:30 AM]-[4/5/22, 4:00 PM]
        [1649251800, 1649275200], //  [4/6/22, 9:30 AM]-[4/6/22, 4:00 PM]
        [1649338200, 1649361600], //  [4/7/22, 9:30 AM]-[4/7/22, 4:00 PM]
        [1649424600, 1649448000], //  [4/8/22, 9:30 AM]-[4/8/22, 4:00 PM]
        [1649683800, 1649707200], //  [4/11/22, 9:30 AM]-[4/11/22, 4:00 PM]
        [1649770200, 1649793600], //  [4/12/22, 9:30 AM]-[4/12/22, 4:00 PM]
        [1649856600, 1649880000], //  [4/13/22, 9:30 AM]-[4/13/22, 4:00 PM]
        [1649943000, 1649966400], //  [4/14/22, 9:30 AM]-[4/14/22, 4:00 PM]
        [1650288600, 1650312000], //  [4/18/22, 9:30 AM]-[4/18/22, 4:00 PM]
        [1650375000, 1650398400], //  [4/19/22, 9:30 AM]-[4/19/22, 4:00 PM]
        [1650461400, 1650484800], //  [4/20/22, 9:30 AM]-[4/20/22, 4:00 PM]
        [1650547800, 1650571200], //  [4/21/22, 9:30 AM]-[4/21/22, 4:00 PM]
        [1650634200, 1650657600], //  [4/22/22, 9:30 AM]-[4/22/22, 4:00 PM]
        [1650893400, 1650916800], //  [4/25/22, 9:30 AM]-[4/25/22, 4:00 PM]
        [1650979800, 1651003200], //  [4/26/22, 9:30 AM]-[4/26/22, 4:00 PM]
        [1651066200, 1651089600], //  [4/27/22, 9:30 AM]-[4/27/22, 4:00 PM]
        [1651152600, 1651176000], //  [4/28/22, 9:30 AM]-[4/28/22, 4:00 PM]
        [1651239000, 1651262400], //  [4/29/22, 9:30 AM]-[4/29/22, 4:00 PM]
        [1651498200, 1651521600], //  [5/2/22, 9:30 AM]-[5/2/22, 4:00 PM]
        [1651584600, 1651608000], //  [5/3/22, 9:30 AM]-[5/3/22, 4:00 PM]
        [1651671000, 1651694400], //  [5/4/22, 9:30 AM]-[5/4/22, 4:00 PM]
        [1651757400, 1651780800], //  [5/5/22, 9:30 AM]-[5/5/22, 4:00 PM]
        [1651843800, 1651867200], //  [5/6/22, 9:30 AM]-[5/6/22, 4:00 PM]
        [1652103000, 1652126400], //  [5/9/22, 9:30 AM]-[5/9/22, 4:00 PM]
        [1652189400, 1652212800], //  [5/10/22, 9:30 AM]-[5/10/22, 4:00 PM]
        [1652275800, 1652299200], //  [5/11/22, 9:30 AM]-[5/11/22, 4:00 PM]
        [1652362200, 1652385600], //  [5/12/22, 9:30 AM]-[5/12/22, 4:00 PM]
        [1652448600, 1652472000], //  [5/13/22, 9:30 AM]-[5/13/22, 4:00 PM]
        [1652707800, 1652731200], //  [5/16/22, 9:30 AM]-[5/16/22, 4:00 PM]
        [1652794200, 1652817600], //  [5/17/22, 9:30 AM]-[5/17/22, 4:00 PM]
        [1652880600, 1652904000], //  [5/18/22, 9:30 AM]-[5/18/22, 4:00 PM]
        [1652967000, 1652990400], //  [5/19/22, 9:30 AM]-[5/19/22, 4:00 PM]
        [1653053400, 1653076800], //  [5/20/22, 9:30 AM]-[5/20/22, 4:00 PM]
        [1653312600, 1653336000], //  [5/23/22, 9:30 AM]-[5/23/22, 4:00 PM]
        [1653399000, 1653422400], //  [5/24/22, 9:30 AM]-[5/24/22, 4:00 PM]
        [1653485400, 1653508800], //  [5/25/22, 9:30 AM]-[5/25/22, 4:00 PM]
        [1653571800, 1653595200], //  [5/26/22, 9:30 AM]-[5/26/22, 4:00 PM]
        [1653658200, 1653681600], //  [5/27/22, 9:30 AM]-[5/27/22, 4:00 PM]
        [1653917400, 1653940800], //  [5/30/22, 9:30 AM]-[5/30/22, 4:00 PM]
        [1654003800, 1654027200], //  [5/31/22, 9:30 AM]-[5/31/22, 4:00 PM]
        [1654090200, 1654113600], //  [6/1/22, 9:30 AM]-[6/1/22, 4:00 PM]
        [1654176600, 1654200000], //  [6/2/22, 9:30 AM]-[6/2/22, 4:00 PM]
        [1654263000, 1654286400], //  [6/3/22, 9:30 AM]-[6/3/22, 4:00 PM]
        [1654522200, 1654545600], //  [6/6/22, 9:30 AM]-[6/6/22, 4:00 PM]
        [1654608600, 1654632000], //  [6/7/22, 9:30 AM]-[6/7/22, 4:00 PM]
        [1654695000, 1654718400], //  [6/8/22, 9:30 AM]-[6/8/22, 4:00 PM]
        [1654781400, 1654804800], //  [6/9/22, 9:30 AM]-[6/9/22, 4:00 PM]
        [1654867800, 1654891200], //  [6/10/22, 9:30 AM]-[6/10/22, 4:00 PM]
        [1655127000, 1655150400], //  [6/13/22, 9:30 AM]-[6/13/22, 4:00 PM]
        [1655213400, 1655236800], //  [6/14/22, 9:30 AM]-[6/14/22, 4:00 PM]
        [1655299800, 1655323200], //  [6/15/22, 9:30 AM]-[6/15/22, 4:00 PM]
        [1655386200, 1655409600], //  [6/16/22, 9:30 AM]-[6/16/22, 4:00 PM]
        [1655472600, 1655496000], //  [6/17/22, 9:30 AM]-[6/17/22, 4:00 PM]
        [1655731800, 1655755200], //  [6/20/22, 9:30 AM]-[6/20/22, 4:00 PM]
        [1655818200, 1655841600], //  [6/21/22, 9:30 AM]-[6/21/22, 4:00 PM]
        [1655904600, 1655928000], //  [6/22/22, 9:30 AM]-[6/22/22, 4:00 PM]
        [1655991000, 1656014400], //  [6/23/22, 9:30 AM]-[6/23/22, 4:00 PM]
        [1656077400, 1656100800], //  [6/24/22, 9:30 AM]-[6/24/22, 4:00 PM]
        [1656336600, 1656360000], //  [6/27/22, 9:30 AM]-[6/27/22, 4:00 PM]
        [1656423000, 1656446400], //  [6/28/22, 9:30 AM]-[6/28/22, 4:00 PM]
        [1656509400, 1656532800], //  [6/29/22, 9:30 AM]-[6/29/22, 4:00 PM]
        [1656595800, 1656619200], //  [6/30/22, 9:30 AM]-[6/30/22, 4:00 PM]
        [1656682200, 1656705600], //  [7/1/22, 9:30 AM]-[7/1/22, 4:00 PM]
        [1657027800, 1657051200], //  [7/5/22, 9:30 AM]-[7/5/22, 4:00 PM]
        [1657114200, 1657137600], //  [7/6/22, 9:30 AM]-[7/6/22, 4:00 PM]
        [1657200600, 1657224000], //  [7/7/22, 9:30 AM]-[7/7/22, 4:00 PM]
        [1657287000, 1657310400], //  [7/8/22, 9:30 AM]-[7/8/22, 4:00 PM]
        [1657546200, 1657569600], //  [7/11/22, 9:30 AM]-[7/11/22, 4:00 PM]
        [1657632600, 1657656000], //  [7/12/22, 9:30 AM]-[7/12/22, 4:00 PM]
        [1657719000, 1657742400], //  [7/13/22, 9:30 AM]-[7/13/22, 4:00 PM]
        [1657805400, 1657828800], //  [7/14/22, 9:30 AM]-[7/14/22, 4:00 PM]
        [1657891800, 1657915200], //  [7/15/22, 9:30 AM]-[7/15/22, 4:00 PM]
        [1658151000, 1658174400], //  [7/18/22, 9:30 AM]-[7/18/22, 4:00 PM]
        [1658237400, 1658260800], //  [7/19/22, 9:30 AM]-[7/19/22, 4:00 PM]
        [1658323800, 1658347200], //  [7/20/22, 9:30 AM]-[7/20/22, 4:00 PM]
        [1658410200, 1658433600], //  [7/21/22, 9:30 AM]-[7/21/22, 4:00 PM]
        [1658496600, 1658520000], //  [7/22/22, 9:30 AM]-[7/22/22, 4:00 PM]
        [1658755800, 1658779200], //  [7/25/22, 9:30 AM]-[7/25/22, 4:00 PM]
        [1658842200, 1658865600], //  [7/26/22, 9:30 AM]-[7/26/22, 4:00 PM]
        [1658928600, 1658952000], //  [7/27/22, 9:30 AM]-[7/27/22, 4:00 PM]
        [1659015000, 1659038400], //  [7/28/22, 9:30 AM]-[7/28/22, 4:00 PM]
        [1659101400, 1659124800], //  [7/29/22, 9:30 AM]-[7/29/22, 4:00 PM]
        [1659360600, 1659384000], //  [8/1/22, 9:30 AM]-[8/1/22, 4:00 PM]
        [1659447000, 1659470400], //  [8/2/22, 9:30 AM]-[8/2/22, 4:00 PM]
        [1659533400, 1659556800], //  [8/3/22, 9:30 AM]-[8/3/22, 4:00 PM]
        [1659619800, 1659643200], //  [8/4/22, 9:30 AM]-[8/4/22, 4:00 PM]
        [1659706200, 1659729600], //  [8/5/22, 9:30 AM]-[8/5/22, 4:00 PM]
        [1659965400, 1659988800], //  [8/8/22, 9:30 AM]-[8/8/22, 4:00 PM]
        [1660051800, 1660075200], //  [8/9/22, 9:30 AM]-[8/9/22, 4:00 PM]
        [1660138200, 1660161600], //  [8/10/22, 9:30 AM]-[8/10/22, 4:00 PM]
        [1660224600, 1660248000], //  [8/11/22, 9:30 AM]-[8/11/22, 4:00 PM]
        [1660311000, 1660334400], //  [8/12/22, 9:30 AM]-[8/12/22, 4:00 PM]
        [1660570200, 1660593600], //  [8/15/22, 9:30 AM]-[8/15/22, 4:00 PM]
        [1660656600, 1660680000], //  [8/16/22, 9:30 AM]-[8/16/22, 4:00 PM]
        [1660743000, 1660766400], //  [8/17/22, 9:30 AM]-[8/17/22, 4:00 PM]
        [1660829400, 1660852800], //  [8/18/22, 9:30 AM]-[8/18/22, 4:00 PM]
        [1660915800, 1660939200], //  [8/19/22, 9:30 AM]-[8/19/22, 4:00 PM]
        [1661175000, 1661198400], //  [8/22/22, 9:30 AM]-[8/22/22, 4:00 PM]
        [1661261400, 1661284800], //  [8/23/22, 9:30 AM]-[8/23/22, 4:00 PM]
        [1661347800, 1661371200], //  [8/24/22, 9:30 AM]-[8/24/22, 4:00 PM]
        [1661434200, 1661457600], //  [8/25/22, 9:30 AM]-[8/25/22, 4:00 PM]
        [1661520600, 1661544000], //  [8/26/22, 9:30 AM]-[8/26/22, 4:00 PM]
        [1661779800, 1661803200], //  [8/29/22, 9:30 AM]-[8/29/22, 4:00 PM]
        [1661866200, 1661889600], //  [8/30/22, 9:30 AM]-[8/30/22, 4:00 PM]
        [1661952600, 1661976000], //  [8/31/22, 9:30 AM]-[8/31/22, 4:00 PM]
        [1662039000, 1662062400], //  [9/1/22, 9:30 AM]-[9/1/22, 4:00 PM]
        [1662125400, 1662148800], //  [9/2/22, 9:30 AM]-[9/2/22, 4:00 PM]
        [1662471000, 1662494400], //  [9/6/22, 9:30 AM]-[9/6/22, 4:00 PM]
        [1662557400, 1662580800], //  [9/7/22, 9:30 AM]-[9/7/22, 4:00 PM]
        [1662643800, 1662667200], //  [9/8/22, 9:30 AM]-[9/8/22, 4:00 PM]
        [1662730200, 1662753600], //  [9/9/22, 9:30 AM]-[9/9/22, 4:00 PM]
        [1662989400, 1663012800], //  [9/12/22, 9:30 AM]-[9/12/22, 4:00 PM]
        [1663075800, 1663099200], //  [9/13/22, 9:30 AM]-[9/13/22, 4:00 PM]
        [1663162200, 1663185600], //  [9/14/22, 9:30 AM]-[9/14/22, 4:00 PM]
        [1663248600, 1663272000], //  [9/15/22, 9:30 AM]-[9/15/22, 4:00 PM]
        [1663335000, 1663358400], //  [9/16/22, 9:30 AM]-[9/16/22, 4:00 PM]
        [1663594200, 1663617600], //  [9/19/22, 9:30 AM]-[9/19/22, 4:00 PM]
        [1663680600, 1663704000], //  [9/20/22, 9:30 AM]-[9/20/22, 4:00 PM]
        [1663767000, 1663790400], //  [9/21/22, 9:30 AM]-[9/21/22, 4:00 PM]
        [1663853400, 1663876800], //  [9/22/22, 9:30 AM]-[9/22/22, 4:00 PM]
        [1663939800, 1663963200], //  [9/23/22, 9:30 AM]-[9/23/22, 4:00 PM]
        [1664199000, 1664222400], //  [9/26/22, 9:30 AM]-[9/26/22, 4:00 PM]
        [1664285400, 1664308800], //  [9/27/22, 9:30 AM]-[9/27/22, 4:00 PM]
        [1664371800, 1664395200], //  [9/28/22, 9:30 AM]-[9/28/22, 4:00 PM]
        [1664458200, 1664481600], //  [9/29/22, 9:30 AM]-[9/29/22, 4:00 PM]
        [1664544600, 1664568000], //  [9/30/22, 9:30 AM]-[9/30/22, 4:00 PM]
        [1664803800, 1664827200], //  [10/3/22, 9:30 AM]-[10/3/22, 4:00 PM]
        [1664890200, 1664913600], //  [10/4/22, 9:30 AM]-[10/4/22, 4:00 PM]
        [1664976600, 1665000000], //  [10/5/22, 9:30 AM]-[10/5/22, 4:00 PM]
        [1665063000, 1665086400], //  [10/6/22, 9:30 AM]-[10/6/22, 4:00 PM]
        [1665149400, 1665172800], //  [10/7/22, 9:30 AM]-[10/7/22, 4:00 PM]
        [1665408600, 1665432000], //  [10/10/22, 9:30 AM]-[10/10/22, 4:00 PM]
        [1665495000, 1665518400], //  [10/11/22, 9:30 AM]-[10/11/22, 4:00 PM]
        [1665581400, 1665604800], //  [10/12/22, 9:30 AM]-[10/12/22, 4:00 PM]
        [1665667800, 1665691200], //  [10/13/22, 9:30 AM]-[10/13/22, 4:00 PM]
        [1665754200, 1665777600], //  [10/14/22, 9:30 AM]-[10/14/22, 4:00 PM]
        [1666013400, 1666036800], //  [10/17/22, 9:30 AM]-[10/17/22, 4:00 PM]
        [1666099800, 1666123200], //  [10/18/22, 9:30 AM]-[10/18/22, 4:00 PM]
        [1666186200, 1666209600], //  [10/19/22, 9:30 AM]-[10/19/22, 4:00 PM]
        [1666272600, 1666296000], //  [10/20/22, 9:30 AM]-[10/20/22, 4:00 PM]
        [1666359000, 1666382400], //  [10/21/22, 9:30 AM]-[10/21/22, 4:00 PM]
        [1666618200, 1666641600], //  [10/24/22, 9:30 AM]-[10/24/22, 4:00 PM]
        [1666704600, 1666728000], //  [10/25/22, 9:30 AM]-[10/25/22, 4:00 PM]
        [1666791000, 1666814400], //  [10/26/22, 9:30 AM]-[10/26/22, 4:00 PM]
        [1666877400, 1666900800], //  [10/27/22, 9:30 AM]-[10/27/22, 4:00 PM]
        [1666963800, 1666987200], //  [10/28/22, 9:30 AM]-[10/28/22, 4:00 PM]
        [1667223000, 1667246400], //  [10/31/22, 9:30 AM]-[10/31/22, 4:00 PM]
        [1667309400, 1667332800], //  [11/1/22, 9:30 AM]-[11/1/22, 4:00 PM]
        [1667395800, 1667419200], //  [11/2/22, 9:30 AM]-[11/2/22, 4:00 PM]
        [1667482200, 1667505600], //  [11/3/22, 9:30 AM]-[11/3/22, 4:00 PM]
        [1667568600, 1667592000], //  [11/4/22, 9:30 AM]-[11/4/22, 4:00 PM]
        [1667831400, 1667854800], //  [11/7/22, 9:30 AM]-[11/7/22, 4:00 PM]
        [1667917800, 1667941200], //  [11/8/22, 9:30 AM]-[11/8/22, 4:00 PM]
        [1668004200, 1668027600], //  [11/9/22, 9:30 AM]-[11/9/22, 4:00 PM]
        [1668090600, 1668114000], //  [11/10/22, 9:30 AM]-[11/10/22, 4:00 PM]
        [1668177000, 1668200400], //  [11/11/22, 9:30 AM]-[11/11/22, 4:00 PM]
        [1668436200, 1668459600], //  [11/14/22, 9:30 AM]-[11/14/22, 4:00 PM]
        [1668522600, 1668546000], //  [11/15/22, 9:30 AM]-[11/15/22, 4:00 PM]
        [1668609000, 1668632400], //  [11/16/22, 9:30 AM]-[11/16/22, 4:00 PM]
        [1668695400, 1668718800], //  [11/17/22, 9:30 AM]-[11/17/22, 4:00 PM]
        [1668781800, 1668805200], //  [11/18/22, 9:30 AM]-[11/18/22, 4:00 PM]
        [1669041000, 1669064400], //  [11/21/22, 9:30 AM]-[11/21/22, 4:00 PM]
        [1669127400, 1669150800], //  [11/22/22, 9:30 AM]-[11/22/22, 4:00 PM]
        [1669213800, 1669237200], //  [11/23/22, 9:30 AM]-[11/23/22, 4:00 PM]
        [1669386600, 1669410000], //  [11/25/22, 9:30 AM]-[11/25/22, 4:00 PM]
        [1669645800, 1669669200], //  [11/28/22, 9:30 AM]-[11/28/22, 4:00 PM]
        [1669732200, 1669755600], //  [11/29/22, 9:30 AM]-[11/29/22, 4:00 PM]
        [1669818600, 1669842000], //  [11/30/22, 9:30 AM]-[11/30/22, 4:00 PM]
        [1669905000, 1669928400], //  [12/1/22, 9:30 AM]-[12/1/22, 4:00 PM]
        [1669991400, 1670014800], //  [12/2/22, 9:30 AM]-[12/2/22, 4:00 PM]
        [1670250600, 1670274000], //  [12/5/22, 9:30 AM]-[12/5/22, 4:00 PM]
        [1670337000, 1670360400], //  [12/6/22, 9:30 AM]-[12/6/22, 4:00 PM]
        [1670423400, 1670446800], //  [12/7/22, 9:30 AM]-[12/7/22, 4:00 PM]
        [1670509800, 1670533200], //  [12/8/22, 9:30 AM]-[12/8/22, 4:00 PM]
        [1670596200, 1670619600], //  [12/9/22, 9:30 AM]-[12/9/22, 4:00 PM]
        [1670855400, 1670878800], //  [12/12/22, 9:30 AM]-[12/12/22, 4:00 PM]
        [1670941800, 1670965200], //  [12/13/22, 9:30 AM]-[12/13/22, 4:00 PM]
        [1671028200, 1671051600], //  [12/14/22, 9:30 AM]-[12/14/22, 4:00 PM]
        [1671114600, 1671138000], //  [12/15/22, 9:30 AM]-[12/15/22, 4:00 PM]
        [1671201000, 1671224400], //  [12/16/22, 9:30 AM]-[12/16/22, 4:00 PM]
        [1671460200, 1671483600], //  [12/19/22, 9:30 AM]-[12/19/22, 4:00 PM]
        [1671546600, 1671570000], //  [12/20/22, 9:30 AM]-[12/20/22, 4:00 PM]
        [1671633000, 1671656400], //  [12/21/22, 9:30 AM]-[12/21/22, 4:00 PM]
        [1671719400, 1671742800], //  [12/22/22, 9:30 AM]-[12/22/22, 4:00 PM]
        [1671805800, 1671829200], //  [12/23/22, 9:30 AM]-[12/23/22, 4:00 PM]
        [1672151400, 1672174800], //  [12/27/22, 9:30 AM]-[12/27/22, 4:00 PM]
        [1672237800, 1672261200], //  [12/28/22, 9:30 AM]-[12/28/22, 4:00 PM]
        [1672324200, 1672347600], //  [12/29/22, 9:30 AM]-[12/29/22, 4:00 PM]
        [1672410600, 1672434000], //  [12/30/22, 9:30 AM]-[12/30/22, 4:00 PM]
        [1672756200, 1672779600], //  [1/3/23, 9:30 AM]-[1/3/23, 4:00 PM]
        [1672842600, 1672866000], //  [1/4/23, 9:30 AM]-[1/4/23, 4:00 PM]
        [1672929000, 1672952400], //  [1/5/23, 9:30 AM]-[1/5/23, 4:00 PM]
        [1673015400, 1673038800], //  [1/6/23, 9:30 AM]-[1/6/23, 4:00 PM]
        [1673274600, 1673298000], //  [1/9/23, 9:30 AM]-[1/9/23, 4:00 PM]
        [1673361000, 1673384400], //  [1/10/23, 9:30 AM]-[1/10/23, 4:00 PM]
        [1673447400, 1673470800], //  [1/11/23, 9:30 AM]-[1/11/23, 4:00 PM]
        [1673533800, 1673557200], //  [1/12/23, 9:30 AM]-[1/12/23, 4:00 PM]
        [1673620200, 1673643600], //  [1/13/23, 9:30 AM]-[1/13/23, 4:00 PM]
        [1673965800, 1673989200], //  [1/17/23, 9:30 AM]-[1/17/23, 4:00 PM]
        [1674052200, 1674075600], //  [1/18/23, 9:30 AM]-[1/18/23, 4:00 PM]
        [1674138600, 1674162000], //  [1/19/23, 9:30 AM]-[1/19/23, 4:00 PM]
        [1674225000, 1674248400], //  [1/20/23, 9:30 AM]-[1/20/23, 4:00 PM]
        [1674484200, 1674507600], //  [1/23/23, 9:30 AM]-[1/23/23, 4:00 PM]
        [1674570600, 1674594000], //  [1/24/23, 9:30 AM]-[1/24/23, 4:00 PM]
        [1674657000, 1674680400], //  [1/25/23, 9:30 AM]-[1/25/23, 4:00 PM]
        [1674743400, 1674766800], //  [1/26/23, 9:30 AM]-[1/26/23, 4:00 PM]
        [1674829800, 1674853200], //  [1/27/23, 9:30 AM]-[1/27/23, 4:00 PM]
        [1675089000, 1675112400], //  [1/30/23, 9:30 AM]-[1/30/23, 4:00 PM]
        [1675175400, 1675198800], //  [1/31/23, 9:30 AM]-[1/31/23, 4:00 PM]
        [1675261800, 1675285200], //  [2/1/23, 9:30 AM]-[2/1/23, 4:00 PM]
        [1675348200, 1675371600], //  [2/2/23, 9:30 AM]-[2/2/23, 4:00 PM]
        [1675434600, 1675458000], //  [2/3/23, 9:30 AM]-[2/3/23, 4:00 PM]
        [1675693800, 1675717200], //  [2/6/23, 9:30 AM]-[2/6/23, 4:00 PM]
        [1675780200, 1675803600], //  [2/7/23, 9:30 AM]-[2/7/23, 4:00 PM]
        [1675866600, 1675890000], //  [2/8/23, 9:30 AM]-[2/8/23, 4:00 PM]
        [1675953000, 1675976400], //  [2/9/23, 9:30 AM]-[2/9/23, 4:00 PM]
        [1676039400, 1676062800], //  [2/10/23, 9:30 AM]-[2/10/23, 4:00 PM]
        [1676298600, 1676322000], //  [2/13/23, 9:30 AM]-[2/13/23, 4:00 PM]
        [1676385000, 1676408400], //  [2/14/23, 9:30 AM]-[2/14/23, 4:00 PM]
        [1676471400, 1676494800], //  [2/15/23, 9:30 AM]-[2/15/23, 4:00 PM]
        [1676557800, 1676581200], //  [2/16/23, 9:30 AM]-[2/16/23, 4:00 PM]
        [1676644200, 1676667600], //  [2/17/23, 9:30 AM]-[2/17/23, 4:00 PM]
        [1676989800, 1677013200], //  [2/21/23, 9:30 AM]-[2/21/23, 4:00 PM]
        [1677076200, 1677099600], //  [2/22/23, 9:30 AM]-[2/22/23, 4:00 PM]
        [1677162600, 1677186000], //  [2/23/23, 9:30 AM]-[2/23/23, 4:00 PM]
        [1677249000, 1677272400], //  [2/24/23, 9:30 AM]-[2/24/23, 4:00 PM]
        [1677508200, 1677531600], //  [2/27/23, 9:30 AM]-[2/27/23, 4:00 PM]
        [1677594600, 1677618000], //  [2/28/23, 9:30 AM]-[2/28/23, 4:00 PM]
        [1677681000, 1677704400], //  [3/1/23, 9:30 AM]-[3/1/23, 4:00 PM]
        [1677767400, 1677790800], //  [3/2/23, 9:30 AM]-[3/2/23, 4:00 PM]
        [1677853800, 1677877200], //  [3/3/23, 9:30 AM]-[3/3/23, 4:00 PM]
        [1678113000, 1678136400], //  [3/6/23, 9:30 AM]-[3/6/23, 4:00 PM]
        [1678199400, 1678222800], //  [3/7/23, 9:30 AM]-[3/7/23, 4:00 PM]
        [1678285800, 1678309200], //  [3/8/23, 9:30 AM]-[3/8/23, 4:00 PM]
        [1678372200, 1678395600], //  [3/9/23, 9:30 AM]-[3/9/23, 4:00 PM]
        [1678458600, 1678482000], //  [3/10/23, 9:30 AM]-[3/10/23, 4:00 PM]
        [1678714200, 1678737600], //  [3/13/23, 9:30 AM]-[3/13/23, 4:00 PM]
        [1678800600, 1678824000], //  [3/14/23, 9:30 AM]-[3/14/23, 4:00 PM]
        [1678887000, 1678910400], //  [3/15/23, 9:30 AM]-[3/15/23, 4:00 PM]
        [1678973400, 1678996800], //  [3/16/23, 9:30 AM]-[3/16/23, 4:00 PM]
        [1679059800, 1679083200], //  [3/17/23, 9:30 AM]-[3/17/23, 4:00 PM]
        [1679319000, 1679342400], //  [3/20/23, 9:30 AM]-[3/20/23, 4:00 PM]
        [1679405400, 1679428800], //  [3/21/23, 9:30 AM]-[3/21/23, 4:00 PM]
        [1679491800, 1679515200], //  [3/22/23, 9:30 AM]-[3/22/23, 4:00 PM]
        [1679578200, 1679601600], //  [3/23/23, 9:30 AM]-[3/23/23, 4:00 PM]
        [1679664600, 1679688000], //  [3/24/23, 9:30 AM]-[3/24/23, 4:00 PM]
        [1679923800, 1679947200], //  [3/27/23, 9:30 AM]-[3/27/23, 4:00 PM]
        [1680010200, 1680033600], //  [3/28/23, 9:30 AM]-[3/28/23, 4:00 PM]
        [1680096600, 1680120000], //  [3/29/23, 9:30 AM]-[3/29/23, 4:00 PM]
        [1680183000, 1680206400], //  [3/30/23, 9:30 AM]-[3/30/23, 4:00 PM]
        [1680269400, 1680292800], //  [3/31/23, 9:30 AM]-[3/31/23, 4:00 PM]
        [1680528600, 1680552000], //  [4/3/23, 9:30 AM]-[4/3/23, 4:00 PM]
        [1680615000, 1680638400], //  [4/4/23, 9:30 AM]-[4/4/23, 4:00 PM]
        [1680701400, 1680724800], //  [4/5/23, 9:30 AM]-[4/5/23, 4:00 PM]
        [1680787800, 1680811200], //  [4/6/23, 9:30 AM]-[4/6/23, 4:00 PM]
        [1681133400, 1681156800], //  [4/10/23, 9:30 AM]-[4/10/23, 4:00 PM]
        [1681219800, 1681243200], //  [4/11/23, 9:30 AM]-[4/11/23, 4:00 PM]
        [1681306200, 1681329600], //  [4/12/23, 9:30 AM]-[4/12/23, 4:00 PM]
        [1681392600, 1681416000], //  [4/13/23, 9:30 AM]-[4/13/23, 4:00 PM]
        [1681479000, 1681502400], //  [4/14/23, 9:30 AM]-[4/14/23, 4:00 PM]
        [1681738200, 1681761600], //  [4/17/23, 9:30 AM]-[4/17/23, 4:00 PM]
        [1681824600, 1681848000], //  [4/18/23, 9:30 AM]-[4/18/23, 4:00 PM]
        [1681911000, 1681934400], //  [4/19/23, 9:30 AM]-[4/19/23, 4:00 PM]
        [1681997400, 1682020800], //  [4/20/23, 9:30 AM]-[4/20/23, 4:00 PM]
        [1682083800, 1682107200], //  [4/21/23, 9:30 AM]-[4/21/23, 4:00 PM]
        [1682343000, 1682366400], //  [4/24/23, 9:30 AM]-[4/24/23, 4:00 PM]
        [1682429400, 1682452800], //  [4/25/23, 9:30 AM]-[4/25/23, 4:00 PM]
        [1682515800, 1682539200], //  [4/26/23, 9:30 AM]-[4/26/23, 4:00 PM]
        [1682602200, 1682625600], //  [4/27/23, 9:30 AM]-[4/27/23, 4:00 PM]
        [1682688600, 1682712000], //  [4/28/23, 9:30 AM]-[4/28/23, 4:00 PM]
        [1682947800, 1682971200], //  [5/1/23, 9:30 AM]-[5/1/23, 4:00 PM]
        [1683034200, 1683057600], //  [5/2/23, 9:30 AM]-[5/2/23, 4:00 PM]
        [1683120600, 1683144000], //  [5/3/23, 9:30 AM]-[5/3/23, 4:00 PM]
        [1683207000, 1683230400], //  [5/4/23, 9:30 AM]-[5/4/23, 4:00 PM]
        [1683293400, 1683316800], //  [5/5/23, 9:30 AM]-[5/5/23, 4:00 PM]
        [1683552600, 1683576000], //  [5/8/23, 9:30 AM]-[5/8/23, 4:00 PM]
        [1683639000, 1683662400], //  [5/9/23, 9:30 AM]-[5/9/23, 4:00 PM]
        [1683725400, 1683748800], //  [5/10/23, 9:30 AM]-[5/10/23, 4:00 PM]
        [1683811800, 1683835200], //  [5/11/23, 9:30 AM]-[5/11/23, 4:00 PM]
        [1683898200, 1683921600], //  [5/12/23, 9:30 AM]-[5/12/23, 4:00 PM]
        [1684157400, 1684180800], //  [5/15/23, 9:30 AM]-[5/15/23, 4:00 PM]
        [1684243800, 1684267200], //  [5/16/23, 9:30 AM]-[5/16/23, 4:00 PM]
        [1684330200, 1684353600], //  [5/17/23, 9:30 AM]-[5/17/23, 4:00 PM]
        [1684416600, 1684440000], //  [5/18/23, 9:30 AM]-[5/18/23, 4:00 PM]
        [1684503000, 1684526400], //  [5/19/23, 9:30 AM]-[5/19/23, 4:00 PM]
        [1684762200, 1684785600], //  [5/22/23, 9:30 AM]-[5/22/23, 4:00 PM]
        [1684848600, 1684872000], //  [5/23/23, 9:30 AM]-[5/23/23, 4:00 PM]
        [1684935000, 1684958400], //  [5/24/23, 9:30 AM]-[5/24/23, 4:00 PM]
        [1685021400, 1685044800], //  [5/25/23, 9:30 AM]-[5/25/23, 4:00 PM]
        [1685107800, 1685131200], //  [5/26/23, 9:30 AM]-[5/26/23, 4:00 PM]
        [1685453400, 1685476800], //  [5/30/23, 9:30 AM]-[5/30/23, 4:00 PM]
        [1685539800, 1685563200], //  [5/31/23, 9:30 AM]-[5/31/23, 4:00 PM]
        [1685626200, 1685649600], //  [6/1/23, 9:30 AM]-[6/1/23, 4:00 PM]
        [1685712600, 1685736000], //  [6/2/23, 9:30 AM]-[6/2/23, 4:00 PM]
        [1685971800, 1685995200], //  [6/5/23, 9:30 AM]-[6/5/23, 4:00 PM]
        [1686058200, 1686081600], //  [6/6/23, 9:30 AM]-[6/6/23, 4:00 PM]
        [1686144600, 1686168000], //  [6/7/23, 9:30 AM]-[6/7/23, 4:00 PM]
        [1686231000, 1686254400], //  [6/8/23, 9:30 AM]-[6/8/23, 4:00 PM]
        [1686317400, 1686340800], //  [6/9/23, 9:30 AM]-[6/9/23, 4:00 PM]
        [1686576600, 1686600000], //  [6/12/23, 9:30 AM]-[6/12/23, 4:00 PM]
        [1686663000, 1686686400], //  [6/13/23, 9:30 AM]-[6/13/23, 4:00 PM]
        [1686749400, 1686772800], //  [6/14/23, 9:30 AM]-[6/14/23, 4:00 PM]
        [1686835800, 1686859200], //  [6/15/23, 9:30 AM]-[6/15/23, 4:00 PM]
        [1686922200, 1686945600], //  [6/16/23, 9:30 AM]-[6/16/23, 4:00 PM]
        [1687267800, 1687291200], //  [6/20/23, 9:30 AM]-[6/20/23, 4:00 PM]
        [1687354200, 1687377600], //  [6/21/23, 9:30 AM]-[6/21/23, 4:00 PM]
        [1687440600, 1687464000], //  [6/22/23, 9:30 AM]-[6/22/23, 4:00 PM]
        [1687527000, 1687550400], //  [6/23/23, 9:30 AM]-[6/23/23, 4:00 PM]
        [1687786200, 1687809600], //  [6/26/23, 9:30 AM]-[6/26/23, 4:00 PM]
        [1687872600, 1687896000], //  [6/27/23, 9:30 AM]-[6/27/23, 4:00 PM]
        [1687959000, 1687982400], //  [6/28/23, 9:30 AM]-[6/28/23, 4:00 PM]
        [1688045400, 1688068800], //  [6/29/23, 9:30 AM]-[6/29/23, 4:00 PM]
        [1688131800, 1688155200], //  [6/30/23, 9:30 AM]-[6/30/23, 4:00 PM]
        [1688391000, 1688403600], //  [7/3/23, 9:30 AM]-[7/3/23, 1:00 PM]
        [1688563800, 1688587200], //  [7/5/23, 9:30 AM]-[7/5/23, 4:00 PM]
        [1688650200, 1688673600], //  [7/6/23, 9:30 AM]-[7/6/23, 4:00 PM]
        [1688736600, 1688760000], //  [7/7/23, 9:30 AM]-[7/7/23, 4:00 PM]
        [1688995800, 1689019200], //  [7/10/23, 9:30 AM]-[7/10/23, 4:00 PM]
        [1689082200, 1689105600], //  [7/11/23, 9:30 AM]-[7/11/23, 4:00 PM]
        [1689168600, 1689192000], //  [7/12/23, 9:30 AM]-[7/12/23, 4:00 PM]
        [1689255000, 1689278400], //  [7/13/23, 9:30 AM]-[7/13/23, 4:00 PM]
        [1689341400, 1689364800], //  [7/14/23, 9:30 AM]-[7/14/23, 4:00 PM]
        [1689600600, 1689624000], //  [7/17/23, 9:30 AM]-[7/17/23, 4:00 PM]
        [1689687000, 1689710400], //  [7/18/23, 9:30 AM]-[7/18/23, 4:00 PM]
        [1689773400, 1689796800], //  [7/19/23, 9:30 AM]-[7/19/23, 4:00 PM]
        [1689859800, 1689883200], //  [7/20/23, 9:30 AM]-[7/20/23, 4:00 PM]
        [1689946200, 1689969600], //  [7/21/23, 9:30 AM]-[7/21/23, 4:00 PM]
        [1690205400, 1690228800], //  [7/24/23, 9:30 AM]-[7/24/23, 4:00 PM]
        [1690291800, 1690315200], //  [7/25/23, 9:30 AM]-[7/25/23, 4:00 PM]
        [1690378200, 1690401600], //  [7/26/23, 9:30 AM]-[7/26/23, 4:00 PM]
        [1690464600, 1690488000], //  [7/27/23, 9:30 AM]-[7/27/23, 4:00 PM]
        [1690551000, 1690574400], //  [7/28/23, 9:30 AM]-[7/28/23, 4:00 PM]
        [1690810200, 1690833600], //  [7/31/23, 9:30 AM]-[7/31/23, 4:00 PM]
        [1690896600, 1690920000], //  [8/1/23, 9:30 AM]-[8/1/23, 4:00 PM]
        [1690983000, 1691006400], //  [8/2/23, 9:30 AM]-[8/2/23, 4:00 PM]
        [1691069400, 1691092800], //  [8/3/23, 9:30 AM]-[8/3/23, 4:00 PM]
        [1691155800, 1691179200], //  [8/4/23, 9:30 AM]-[8/4/23, 4:00 PM]
        [1691415000, 1691438400], //  [8/7/23, 9:30 AM]-[8/7/23, 4:00 PM]
        [1691501400, 1691524800], //  [8/8/23, 9:30 AM]-[8/8/23, 4:00 PM]
        [1691587800, 1691611200], //  [8/9/23, 9:30 AM]-[8/9/23, 4:00 PM]
        [1691674200, 1691697600], //  [8/10/23, 9:30 AM]-[8/10/23, 4:00 PM]
        [1691760600, 1691784000], //  [8/11/23, 9:30 AM]-[8/11/23, 4:00 PM]
        [1692019800, 1692043200], //  [8/14/23, 9:30 AM]-[8/14/23, 4:00 PM]
        [1692106200, 1692129600], //  [8/15/23, 9:30 AM]-[8/15/23, 4:00 PM]
        [1692192600, 1692216000], //  [8/16/23, 9:30 AM]-[8/16/23, 4:00 PM]
        [1692279000, 1692302400], //  [8/17/23, 9:30 AM]-[8/17/23, 4:00 PM]
        [1692365400, 1692388800], //  [8/18/23, 9:30 AM]-[8/18/23, 4:00 PM]
        [1692624600, 1692648000], //  [8/21/23, 9:30 AM]-[8/21/23, 4:00 PM]
        [1692711000, 1692734400], //  [8/22/23, 9:30 AM]-[8/22/23, 4:00 PM]
        [1692797400, 1692820800], //  [8/23/23, 9:30 AM]-[8/23/23, 4:00 PM]
        [1692883800, 1692907200], //  [8/24/23, 9:30 AM]-[8/24/23, 4:00 PM]
        [1692970200, 1692993600], //  [8/25/23, 9:30 AM]-[8/25/23, 4:00 PM]
        [1693229400, 1693252800], //  [8/28/23, 9:30 AM]-[8/28/23, 4:00 PM]
        [1693315800, 1693339200], //  [8/29/23, 9:30 AM]-[8/29/23, 4:00 PM]
        [1693402200, 1693425600], //  [8/30/23, 9:30 AM]-[8/30/23, 4:00 PM]
        [1693488600, 1693512000], //  [8/31/23, 9:30 AM]-[8/31/23, 4:00 PM]
        [1693575000, 1693598400], //  [9/1/23, 9:30 AM]-[9/1/23, 4:00 PM]
        [1693920600, 1693944000], //  [9/5/23, 9:30 AM]-[9/5/23, 4:00 PM]
        [1694007000, 1694030400], //  [9/6/23, 9:30 AM]-[9/6/23, 4:00 PM]
        [1694093400, 1694116800], //  [9/7/23, 9:30 AM]-[9/7/23, 4:00 PM]
        [1694179800, 1694203200], //  [9/8/23, 9:30 AM]-[9/8/23, 4:00 PM]
        [1694439000, 1694462400], //  [9/11/23, 9:30 AM]-[9/11/23, 4:00 PM]
        [1694525400, 1694548800], //  [9/12/23, 9:30 AM]-[9/12/23, 4:00 PM]
        [1694611800, 1694635200], //  [9/13/23, 9:30 AM]-[9/13/23, 4:00 PM]
        [1694698200, 1694721600], //  [9/14/23, 9:30 AM]-[9/14/23, 4:00 PM]
        [1694784600, 1694808000], //  [9/15/23, 9:30 AM]-[9/15/23, 4:00 PM]
        [1695043800, 1695067200], //  [9/18/23, 9:30 AM]-[9/18/23, 4:00 PM]
        [1695130200, 1695153600], //  [9/19/23, 9:30 AM]-[9/19/23, 4:00 PM]
        [1695216600, 1695240000], //  [9/20/23, 9:30 AM]-[9/20/23, 4:00 PM]
        [1695303000, 1695326400], //  [9/21/23, 9:30 AM]-[9/21/23, 4:00 PM]
        [1695389400, 1695412800], //  [9/22/23, 9:30 AM]-[9/22/23, 4:00 PM]
        [1695648600, 1695672000], //  [9/25/23, 9:30 AM]-[9/25/23, 4:00 PM]
        [1695735000, 1695758400], //  [9/26/23, 9:30 AM]-[9/26/23, 4:00 PM]
        [1695821400, 1695844800], //  [9/27/23, 9:30 AM]-[9/27/23, 4:00 PM]
        [1695907800, 1695931200], //  [9/28/23, 9:30 AM]-[9/28/23, 4:00 PM]
        [1695994200, 1696017600], //  [9/29/23, 9:30 AM]-[9/29/23, 4:00 PM]
        [1696253400, 1696276800], //  [10/2/23, 9:30 AM]-[10/2/23, 4:00 PM]
        [1696339800, 1696363200], //  [10/3/23, 9:30 AM]-[10/3/23, 4:00 PM]
        [1696426200, 1696449600], //  [10/4/23, 9:30 AM]-[10/4/23, 4:00 PM]
        [1696512600, 1696536000], //  [10/5/23, 9:30 AM]-[10/5/23, 4:00 PM]
        [1696599000, 1696622400], //  [10/6/23, 9:30 AM]-[10/6/23, 4:00 PM]
        [1696858200, 1696881600], //  [10/9/23, 9:30 AM]-[10/9/23, 4:00 PM]
        [1696944600, 1696968000], //  [10/10/23, 9:30 AM]-[10/10/23, 4:00 PM]
        [1697031000, 1697054400], //  [10/11/23, 9:30 AM]-[10/11/23, 4:00 PM]
        [1697117400, 1697140800], //  [10/12/23, 9:30 AM]-[10/12/23, 4:00 PM]
        [1697203800, 1697227200], //  [10/13/23, 9:30 AM]-[10/13/23, 4:00 PM]
        [1697463000, 1697486400], //  [10/16/23, 9:30 AM]-[10/16/23, 4:00 PM]
        [1697549400, 1697572800], //  [10/17/23, 9:30 AM]-[10/17/23, 4:00 PM]
        [1697635800, 1697659200], //  [10/18/23, 9:30 AM]-[10/18/23, 4:00 PM]
        [1697722200, 1697745600], //  [10/19/23, 9:30 AM]-[10/19/23, 4:00 PM]
        [1697808600, 1697832000], //  [10/20/23, 9:30 AM]-[10/20/23, 4:00 PM]
        [1698067800, 1698091200], //  [10/23/23, 9:30 AM]-[10/23/23, 4:00 PM]
        [1698154200, 1698177600], //  [10/24/23, 9:30 AM]-[10/24/23, 4:00 PM]
        [1698240600, 1698264000], //  [10/25/23, 9:30 AM]-[10/25/23, 4:00 PM]
        [1698327000, 1698350400], //  [10/26/23, 9:30 AM]-[10/26/23, 4:00 PM]
        [1698413400, 1698436800], //  [10/27/23, 9:30 AM]-[10/27/23, 4:00 PM]
        [1698672600, 1698696000], //  [10/30/23, 9:30 AM]-[10/30/23, 4:00 PM]
        [1698759000, 1698782400], //  [10/31/23, 9:30 AM]-[10/31/23, 4:00 PM]
        [1698845400, 1698868800], //  [11/1/23, 9:30 AM]-[11/1/23, 4:00 PM]
        [1698931800, 1698955200], //  [11/2/23, 9:30 AM]-[11/2/23, 4:00 PM]
        [1699018200, 1699041600], //  [11/3/23, 9:30 AM]-[11/3/23, 4:00 PM]
        [1699281000, 1699304400], //  [11/6/23, 9:30 AM]-[11/6/23, 4:00 PM]
        [1699367400, 1699390800], //  [11/7/23, 9:30 AM]-[11/7/23, 4:00 PM]
        [1699453800, 1699477200], //  [11/8/23, 9:30 AM]-[11/8/23, 4:00 PM]
        [1699540200, 1699563600], //  [11/9/23, 9:30 AM]-[11/9/23, 4:00 PM]
        [1699626600, 1699650000], //  [11/10/23, 9:30 AM]-[11/10/23, 4:00 PM]
        [1699885800, 1699909200], //  [11/13/23, 9:30 AM]-[11/13/23, 4:00 PM]
        [1699972200, 1699995600], //  [11/14/23, 9:30 AM]-[11/14/23, 4:00 PM]
        [1700058600, 1700082000], //  [11/15/23, 9:30 AM]-[11/15/23, 4:00 PM]
        [1700145000, 1700168400], //  [11/16/23, 9:30 AM]-[11/16/23, 4:00 PM]
        [1700231400, 1700254800], //  [11/17/23, 9:30 AM]-[11/17/23, 4:00 PM]
        [1700490600, 1700514000], //  [11/20/23, 9:30 AM]-[11/20/23, 4:00 PM]
        [1700577000, 1700600400], //  [11/21/23, 9:30 AM]-[11/21/23, 4:00 PM]
        [1700663400, 1700686800], //  [11/22/23, 9:30 AM]-[11/22/23, 4:00 PM]
        [1700836200, 1700848800], //  [11/24/23, 9:30 AM]-[11/24/23, 1:00 PM]
        [1701095400, 1701118800], //  [11/27/23, 9:30 AM]-[11/27/23, 4:00 PM]
        [1701181800, 1701205200], //  [11/28/23, 9:30 AM]-[11/28/23, 4:00 PM]
        [1701268200, 1701291600], //  [11/29/23, 9:30 AM]-[11/29/23, 4:00 PM]
        [1701354600, 1701378000], //  [11/30/23, 9:30 AM]-[11/30/23, 4:00 PM]
        [1701441000, 1701464400], //  [12/1/23, 9:30 AM]-[12/1/23, 4:00 PM]
        [1701700200, 1701723600], //  [12/4/23, 9:30 AM]-[12/4/23, 4:00 PM]
        [1701786600, 1701810000], //  [12/5/23, 9:30 AM]-[12/5/23, 4:00 PM]
        [1701873000, 1701896400], //  [12/6/23, 9:30 AM]-[12/6/23, 4:00 PM]
        [1701959400, 1701982800], //  [12/7/23, 9:30 AM]-[12/7/23, 4:00 PM]
        [1702045800, 1702069200], //  [12/8/23, 9:30 AM]-[12/8/23, 4:00 PM]
        [1702305000, 1702328400], //  [12/11/23, 9:30 AM]-[12/11/23, 4:00 PM]
        [1702391400, 1702414800], //  [12/12/23, 9:30 AM]-[12/12/23, 4:00 PM]
        [1702477800, 1702501200], //  [12/13/23, 9:30 AM]-[12/13/23, 4:00 PM]
        [1702564200, 1702587600], //  [12/14/23, 9:30 AM]-[12/14/23, 4:00 PM]
        [1702650600, 1702674000], //  [12/15/23, 9:30 AM]-[12/15/23, 4:00 PM]
        [1702909800, 1702933200], //  [12/18/23, 9:30 AM]-[12/18/23, 4:00 PM]
        [1702996200, 1703019600], //  [12/19/23, 9:30 AM]-[12/19/23, 4:00 PM]
        [1703082600, 1703106000], //  [12/20/23, 9:30 AM]-[12/20/23, 4:00 PM]
        [1703169000, 1703192400], //  [12/21/23, 9:30 AM]-[12/21/23, 4:00 PM]
        [1703255400, 1703278800], //  [12/22/23, 9:30 AM]-[12/22/23, 4:00 PM]
        [1703601000, 1703624400], //  [12/26/23, 9:30 AM]-[12/26/23, 4:00 PM]
        [1703687400, 1703710800], //  [12/27/23, 9:30 AM]-[12/27/23, 4:00 PM]
        [1703773800, 1703797200], //  [12/28/23, 9:30 AM]-[12/28/23, 4:00 PM]
        [1703860200, 1703883600], //  [12/29/23, 9:30 AM]-[12/29/23, 4:00 PM]
        [1704205800, 1704229200], //  [1/2/24, 9:30 AM]-[1/2/24, 4:00 PM]
        [1704292200, 1704315600], //  [1/3/24, 9:30 AM]-[1/3/24, 4:00 PM]
        [1704378600, 1704402000], //  [1/4/24, 9:30 AM]-[1/4/24, 4:00 PM]
        [1704465000, 1704488400], //  [1/5/24, 9:30 AM]-[1/5/24, 4:00 PM]
        [1704724200, 1704747600], //  [1/8/24, 9:30 AM]-[1/8/24, 4:00 PM]
        [1704810600, 1704834000], //  [1/9/24, 9:30 AM]-[1/9/24, 4:00 PM]
        [1704897000, 1704920400], //  [1/10/24, 9:30 AM]-[1/10/24, 4:00 PM]
        [1704983400, 1705006800], //  [1/11/24, 9:30 AM]-[1/11/24, 4:00 PM]
        [1705069800, 1705093200], //  [1/12/24, 9:30 AM]-[1/12/24, 4:00 PM]
        [1705415400, 1705438800], //  [1/16/24, 9:30 AM]-[1/16/24, 4:00 PM]
        [1705501800, 1705525200], //  [1/17/24, 9:30 AM]-[1/17/24, 4:00 PM]
        [1705588200, 1705611600], //  [1/18/24, 9:30 AM]-[1/18/24, 4:00 PM]
        [1705674600, 1705698000], //  [1/19/24, 9:30 AM]-[1/19/24, 4:00 PM]
        [1705933800, 1705957200], //  [1/22/24, 9:30 AM]-[1/22/24, 4:00 PM]
        [1706020200, 1706043600], //  [1/23/24, 9:30 AM]-[1/23/24, 4:00 PM]
        [1706106600, 1706130000], //  [1/24/24, 9:30 AM]-[1/24/24, 4:00 PM]
        [1706193000, 1706216400], //  [1/25/24, 9:30 AM]-[1/25/24, 4:00 PM]
        [1706279400, 1706302800], //  [1/26/24, 9:30 AM]-[1/26/24, 4:00 PM]
        [1706538600, 1706562000], //  [1/29/24, 9:30 AM]-[1/29/24, 4:00 PM]
        [1706625000, 1706648400], //  [1/30/24, 9:30 AM]-[1/30/24, 4:00 PM]
        [1706711400, 1706734800], //  [1/31/24, 9:30 AM]-[1/31/24, 4:00 PM]
        [1706797800, 1706821200], //  [2/1/24, 9:30 AM]-[2/1/24, 4:00 PM]
        [1706884200, 1706907600], //  [2/2/24, 9:30 AM]-[2/2/24, 4:00 PM]
        [1707143400, 1707166800], //  [2/5/24, 9:30 AM]-[2/5/24, 4:00 PM]
        [1707229800, 1707253200], //  [2/6/24, 9:30 AM]-[2/6/24, 4:00 PM]
        [1707316200, 1707339600], //  [2/7/24, 9:30 AM]-[2/7/24, 4:00 PM]
        [1707402600, 1707426000], //  [2/8/24, 9:30 AM]-[2/8/24, 4:00 PM]
        [1707489000, 1707512400], //  [2/9/24, 9:30 AM]-[2/9/24, 4:00 PM]
        [1707748200, 1707771600], //  [2/12/24, 9:30 AM]-[2/12/24, 4:00 PM]
        [1707834600, 1707858000], //  [2/13/24, 9:30 AM]-[2/13/24, 4:00 PM]
        [1707921000, 1707944400], //  [2/14/24, 9:30 AM]-[2/14/24, 4:00 PM]
        [1708007400, 1708030800], //  [2/15/24, 9:30 AM]-[2/15/24, 4:00 PM]
        [1708093800, 1708117200], //  [2/16/24, 9:30 AM]-[2/16/24, 4:00 PM]
        [1708439400, 1708462800], //  [2/20/24, 9:30 AM]-[2/20/24, 4:00 PM]
        [1708525800, 1708549200], //  [2/21/24, 9:30 AM]-[2/21/24, 4:00 PM]
        [1708612200, 1708635600], //  [2/22/24, 9:30 AM]-[2/22/24, 4:00 PM]
        [1708698600, 1708722000], //  [2/23/24, 9:30 AM]-[2/23/24, 4:00 PM]
        [1708957800, 1708981200], //  [2/26/24, 9:30 AM]-[2/26/24, 4:00 PM]
        [1709044200, 1709067600], //  [2/27/24, 9:30 AM]-[2/27/24, 4:00 PM]
        [1709130600, 1709154000], //  [2/28/24, 9:30 AM]-[2/28/24, 4:00 PM]
        [1709217000, 1709240400], //  [2/29/24, 9:30 AM]-[2/29/24, 4:00 PM]
        [1709303400, 1709326800], //  [3/1/24, 9:30 AM]-[3/1/24, 4:00 PM]
        [1709562600, 1709586000], //  [3/4/24, 9:30 AM]-[3/4/24, 4:00 PM]
        [1709649000, 1709672400], //  [3/5/24, 9:30 AM]-[3/5/24, 4:00 PM]
        [1709735400, 1709758800], //  [3/6/24, 9:30 AM]-[3/6/24, 4:00 PM]
        [1709821800, 1709845200], //  [3/7/24, 9:30 AM]-[3/7/24, 4:00 PM]
        [1709908200, 1709931600], //  [3/8/24, 9:30 AM]-[3/8/24, 4:00 PM]
        [1710163800, 1710187200], //  [3/11/24, 9:30 AM]-[3/11/24, 4:00 PM]
        [1710250200, 1710273600], //  [3/12/24, 9:30 AM]-[3/12/24, 4:00 PM]
        [1710336600, 1710360000], //  [3/13/24, 9:30 AM]-[3/13/24, 4:00 PM]
        [1710423000, 1710446400], //  [3/14/24, 9:30 AM]-[3/14/24, 4:00 PM]
        [1710509400, 1710532800], //  [3/15/24, 9:30 AM]-[3/15/24, 4:00 PM]
        [1710768600, 1710792000], //  [3/18/24, 9:30 AM]-[3/18/24, 4:00 PM]
        [1710855000, 1710878400], //  [3/19/24, 9:30 AM]-[3/19/24, 4:00 PM]
        [1710941400, 1710964800], //  [3/20/24, 9:30 AM]-[3/20/24, 4:00 PM]
        [1711027800, 1711051200], //  [3/21/24, 9:30 AM]-[3/21/24, 4:00 PM]
        [1711114200, 1711137600], //  [3/22/24, 9:30 AM]-[3/22/24, 4:00 PM]
        [1711373400, 1711396800], //  [3/25/24, 9:30 AM]-[3/25/24, 4:00 PM]
        [1711459800, 1711483200], //  [3/26/24, 9:30 AM]-[3/26/24, 4:00 PM]
        [1711546200, 1711569600], //  [3/27/24, 9:30 AM]-[3/27/24, 4:00 PM]
        [1711632600, 1711656000], //  [3/28/24, 9:30 AM]-[3/28/24, 4:00 PM]
        [1711978200, 1712001600], //  [4/1/24, 9:30 AM]-[4/1/24, 4:00 PM]
        [1712064600, 1712088000], //  [4/2/24, 9:30 AM]-[4/2/24, 4:00 PM]
        [1712151000, 1712174400], //  [4/3/24, 9:30 AM]-[4/3/24, 4:00 PM]
        [1712237400, 1712260800], //  [4/4/24, 9:30 AM]-[4/4/24, 4:00 PM]
        [1712323800, 1712347200], //  [4/5/24, 9:30 AM]-[4/5/24, 4:00 PM]
        [1712583000, 1712606400], //  [4/8/24, 9:30 AM]-[4/8/24, 4:00 PM]
        [1712669400, 1712692800], //  [4/9/24, 9:30 AM]-[4/9/24, 4:00 PM]
        [1712755800, 1712779200], //  [4/10/24, 9:30 AM]-[4/10/24, 4:00 PM]
        [1712842200, 1712865600], //  [4/11/24, 9:30 AM]-[4/11/24, 4:00 PM]
        [1712928600, 1712952000], //  [4/12/24, 9:30 AM]-[4/12/24, 4:00 PM]
        [1713187800, 1713211200], //  [4/15/24, 9:30 AM]-[4/15/24, 4:00 PM]
        [1713274200, 1713297600], //  [4/16/24, 9:30 AM]-[4/16/24, 4:00 PM]
        [1713360600, 1713384000], //  [4/17/24, 9:30 AM]-[4/17/24, 4:00 PM]
        [1713447000, 1713470400], //  [4/18/24, 9:30 AM]-[4/18/24, 4:00 PM]
        [1713533400, 1713556800], //  [4/19/24, 9:30 AM]-[4/19/24, 4:00 PM]
        [1713792600, 1713816000], //  [4/22/24, 9:30 AM]-[4/22/24, 4:00 PM]
        [1713879000, 1713902400], //  [4/23/24, 9:30 AM]-[4/23/24, 4:00 PM]
        [1713965400, 1713988800], //  [4/24/24, 9:30 AM]-[4/24/24, 4:00 PM]
        [1714051800, 1714075200], //  [4/25/24, 9:30 AM]-[4/25/24, 4:00 PM]
        [1714138200, 1714161600], //  [4/26/24, 9:30 AM]-[4/26/24, 4:00 PM]
        [1714397400, 1714420800], //  [4/29/24, 9:30 AM]-[4/29/24, 4:00 PM]
        [1714483800, 1714507200], //  [4/30/24, 9:30 AM]-[4/30/24, 4:00 PM]
        [1714570200, 1714593600], //  [5/1/24, 9:30 AM]-[5/1/24, 4:00 PM]
        [1714656600, 1714680000], //  [5/2/24, 9:30 AM]-[5/2/24, 4:00 PM]
        [1714743000, 1714766400], //  [5/3/24, 9:30 AM]-[5/3/24, 4:00 PM]
        [1715002200, 1715025600], //  [5/6/24, 9:30 AM]-[5/6/24, 4:00 PM]
        [1715088600, 1715112000], //  [5/7/24, 9:30 AM]-[5/7/24, 4:00 PM]
        [1715175000, 1715198400], //  [5/8/24, 9:30 AM]-[5/8/24, 4:00 PM]
        [1715261400, 1715284800], //  [5/9/24, 9:30 AM]-[5/9/24, 4:00 PM]
        [1715347800, 1715371200], //  [5/10/24, 9:30 AM]-[5/10/24, 4:00 PM]
        [1715607000, 1715630400], //  [5/13/24, 9:30 AM]-[5/13/24, 4:00 PM]
        [1715693400, 1715716800], //  [5/14/24, 9:30 AM]-[5/14/24, 4:00 PM]
        [1715779800, 1715803200], //  [5/15/24, 9:30 AM]-[5/15/24, 4:00 PM]
        [1715866200, 1715889600], //  [5/16/24, 9:30 AM]-[5/16/24, 4:00 PM]
        [1715952600, 1715976000], //  [5/17/24, 9:30 AM]-[5/17/24, 4:00 PM]
        [1716211800, 1716235200], //  [5/20/24, 9:30 AM]-[5/20/24, 4:00 PM]
        [1716298200, 1716321600], //  [5/21/24, 9:30 AM]-[5/21/24, 4:00 PM]
        [1716384600, 1716408000], //  [5/22/24, 9:30 AM]-[5/22/24, 4:00 PM]
        [1716471000, 1716494400], //  [5/23/24, 9:30 AM]-[5/23/24, 4:00 PM]
        [1716557400, 1716580800], //  [5/24/24, 9:30 AM]-[5/24/24, 4:00 PM]
        [1716903000, 1716926400], //  [5/28/24, 9:30 AM]-[5/28/24, 4:00 PM]
        [1716989400, 1717012800], //  [5/29/24, 9:30 AM]-[5/29/24, 4:00 PM]
        [1717075800, 1717099200], //  [5/30/24, 9:30 AM]-[5/30/24, 4:00 PM]
        [1717162200, 1717185600], //  [5/31/24, 9:30 AM]-[5/31/24, 4:00 PM]
        [1717421400, 1717444800], //  [6/3/24, 9:30 AM]-[6/3/24, 4:00 PM]
        [1717507800, 1717531200], //  [6/4/24, 9:30 AM]-[6/4/24, 4:00 PM]
        [1717594200, 1717617600], //  [6/5/24, 9:30 AM]-[6/5/24, 4:00 PM]
        [1717680600, 1717704000], //  [6/6/24, 9:30 AM]-[6/6/24, 4:00 PM]
        [1717767000, 1717790400], //  [6/7/24, 9:30 AM]-[6/7/24, 4:00 PM]
        [1718026200, 1718049600], //  [6/10/24, 9:30 AM]-[6/10/24, 4:00 PM]
        [1718112600, 1718136000], //  [6/11/24, 9:30 AM]-[6/11/24, 4:00 PM]
        [1718199000, 1718222400], //  [6/12/24, 9:30 AM]-[6/12/24, 4:00 PM]
        [1718285400, 1718308800], //  [6/13/24, 9:30 AM]-[6/13/24, 4:00 PM]
        [1718371800, 1718395200], //  [6/14/24, 9:30 AM]-[6/14/24, 4:00 PM]
        [1718631000, 1718654400], //  [6/17/24, 9:30 AM]-[6/17/24, 4:00 PM]
        [1718717400, 1718740800], //  [6/18/24, 9:30 AM]-[6/18/24, 4:00 PM]
        [1718890200, 1718913600], //  [6/20/24, 9:30 AM]-[6/20/24, 4:00 PM]
        [1718976600, 1719000000], //  [6/21/24, 9:30 AM]-[6/21/24, 4:00 PM]
        [1719235800, 1719259200], //  [6/24/24, 9:30 AM]-[6/24/24, 4:00 PM]
        [1719322200, 1719345600], //  [6/25/24, 9:30 AM]-[6/25/24, 4:00 PM]
        [1719408600, 1719432000], //  [6/26/24, 9:30 AM]-[6/26/24, 4:00 PM]
        [1719495000, 1719518400], //  [6/27/24, 9:30 AM]-[6/27/24, 4:00 PM]
        [1719581400, 1719604800], //  [6/28/24, 9:30 AM]-[6/28/24, 4:00 PM]
        [1719840600, 1719864000], //  [7/1/24, 9:30 AM]-[7/1/24, 4:00 PM]
        [1719927000, 1719950400], //  [7/2/24, 9:30 AM]-[7/2/24, 4:00 PM]
        [1720013400, 1720026000], //  [7/3/24, 9:30 AM]-[7/3/24, 1:00 PM]
        [1720186200, 1720209600], //  [7/5/24, 9:30 AM]-[7/5/24, 4:00 PM]
        [1720445400, 1720468800], //  [7/8/24, 9:30 AM]-[7/8/24, 4:00 PM]
        [1720531800, 1720555200], //  [7/9/24, 9:30 AM]-[7/9/24, 4:00 PM]
        [1720618200, 1720641600], //  [7/10/24, 9:30 AM]-[7/10/24, 4:00 PM]
        [1720704600, 1720728000], //  [7/11/24, 9:30 AM]-[7/11/24, 4:00 PM]
        [1720791000, 1720814400], //  [7/12/24, 9:30 AM]-[7/12/24, 4:00 PM]
        [1721050200, 1721073600], //  [7/15/24, 9:30 AM]-[7/15/24, 4:00 PM]
        [1721136600, 1721160000], //  [7/16/24, 9:30 AM]-[7/16/24, 4:00 PM]
        [1721223000, 1721246400], //  [7/17/24, 9:30 AM]-[7/17/24, 4:00 PM]
        [1721309400, 1721332800], //  [7/18/24, 9:30 AM]-[7/18/24, 4:00 PM]
        [1721395800, 1721419200], //  [7/19/24, 9:30 AM]-[7/19/24, 4:00 PM]
        [1721655000, 1721678400], //  [7/22/24, 9:30 AM]-[7/22/24, 4:00 PM]
        [1721741400, 1721764800], //  [7/23/24, 9:30 AM]-[7/23/24, 4:00 PM]
        [1721827800, 1721851200], //  [7/24/24, 9:30 AM]-[7/24/24, 4:00 PM]
        [1721914200, 1721937600], //  [7/25/24, 9:30 AM]-[7/25/24, 4:00 PM]
        [1722000600, 1722024000], //  [7/26/24, 9:30 AM]-[7/26/24, 4:00 PM]
        [1722259800, 1722283200], //  [7/29/24, 9:30 AM]-[7/29/24, 4:00 PM]
        [1722346200, 1722369600], //  [7/30/24, 9:30 AM]-[7/30/24, 4:00 PM]
        [1722432600, 1722456000], //  [7/31/24, 9:30 AM]-[7/31/24, 4:00 PM]
        [1722519000, 1722542400], //  [8/1/24, 9:30 AM]-[8/1/24, 4:00 PM]
        [1722605400, 1722628800], //  [8/2/24, 9:30 AM]-[8/2/24, 4:00 PM]
        [1722864600, 1722888000], //  [8/5/24, 9:30 AM]-[8/5/24, 4:00 PM]
        [1722951000, 1722974400], //  [8/6/24, 9:30 AM]-[8/6/24, 4:00 PM]
        [1723037400, 1723060800], //  [8/7/24, 9:30 AM]-[8/7/24, 4:00 PM]
        [1723123800, 1723147200], //  [8/8/24, 9:30 AM]-[8/8/24, 4:00 PM]
        [1723210200, 1723233600], //  [8/9/24, 9:30 AM]-[8/9/24, 4:00 PM]
        [1723469400, 1723492800], //  [8/12/24, 9:30 AM]-[8/12/24, 4:00 PM]
        [1723555800, 1723579200], //  [8/13/24, 9:30 AM]-[8/13/24, 4:00 PM]
        [1723642200, 1723665600], //  [8/14/24, 9:30 AM]-[8/14/24, 4:00 PM]
        [1723728600, 1723752000], //  [8/15/24, 9:30 AM]-[8/15/24, 4:00 PM]
        [1723815000, 1723838400], //  [8/16/24, 9:30 AM]-[8/16/24, 4:00 PM]
        [1724074200, 1724097600], //  [8/19/24, 9:30 AM]-[8/19/24, 4:00 PM]
        [1724160600, 1724184000], //  [8/20/24, 9:30 AM]-[8/20/24, 4:00 PM]
        [1724247000, 1724270400], //  [8/21/24, 9:30 AM]-[8/21/24, 4:00 PM]
        [1724333400, 1724356800], //  [8/22/24, 9:30 AM]-[8/22/24, 4:00 PM]
        [1724419800, 1724443200], //  [8/23/24, 9:30 AM]-[8/23/24, 4:00 PM]
        [1724679000, 1724702400], //  [8/26/24, 9:30 AM]-[8/26/24, 4:00 PM]
        [1724765400, 1724788800], //  [8/27/24, 9:30 AM]-[8/27/24, 4:00 PM]
        [1724851800, 1724875200], //  [8/28/24, 9:30 AM]-[8/28/24, 4:00 PM]
        [1724938200, 1724961600], //  [8/29/24, 9:30 AM]-[8/29/24, 4:00 PM]
        [1725024600, 1725048000], //  [8/30/24, 9:30 AM]-[8/30/24, 4:00 PM]
        [1725370200, 1725393600], //  [9/3/24, 9:30 AM]-[9/3/24, 4:00 PM]
        [1725456600, 1725480000], //  [9/4/24, 9:30 AM]-[9/4/24, 4:00 PM]
        [1725543000, 1725566400], //  [9/5/24, 9:30 AM]-[9/5/24, 4:00 PM]
        [1725629400, 1725652800], //  [9/6/24, 9:30 AM]-[9/6/24, 4:00 PM]
        [1725888600, 1725912000], //  [9/9/24, 9:30 AM]-[9/9/24, 4:00 PM]
        [1725975000, 1725998400], //  [9/10/24, 9:30 AM]-[9/10/24, 4:00 PM]
        [1726061400, 1726084800], //  [9/11/24, 9:30 AM]-[9/11/24, 4:00 PM]
        [1726147800, 1726171200], //  [9/12/24, 9:30 AM]-[9/12/24, 4:00 PM]
        [1726234200, 1726257600], //  [9/13/24, 9:30 AM]-[9/13/24, 4:00 PM]
        [1726493400, 1726516800], //  [9/16/24, 9:30 AM]-[9/16/24, 4:00 PM]
        [1726579800, 1726603200], //  [9/17/24, 9:30 AM]-[9/17/24, 4:00 PM]
        [1726666200, 1726689600], //  [9/18/24, 9:30 AM]-[9/18/24, 4:00 PM]
        [1726752600, 1726776000], //  [9/19/24, 9:30 AM]-[9/19/24, 4:00 PM]
        [1726839000, 1726862400], //  [9/20/24, 9:30 AM]-[9/20/24, 4:00 PM]
        [1727098200, 1727121600], //  [9/23/24, 9:30 AM]-[9/23/24, 4:00 PM]
        [1727184600, 1727208000], //  [9/24/24, 9:30 AM]-[9/24/24, 4:00 PM]
        [1727271000, 1727294400], //  [9/25/24, 9:30 AM]-[9/25/24, 4:00 PM]
        [1727357400, 1727380800], //  [9/26/24, 9:30 AM]-[9/26/24, 4:00 PM]
        [1727443800, 1727467200], //  [9/27/24, 9:30 AM]-[9/27/24, 4:00 PM]
        [1727703000, 1727726400], //  [9/30/24, 9:30 AM]-[9/30/24, 4:00 PM]
        [1727789400, 1727812800], //  [10/1/24, 9:30 AM]-[10/1/24, 4:00 PM]
        [1727875800, 1727899200], //  [10/2/24, 9:30 AM]-[10/2/24, 4:00 PM]
        [1727962200, 1727985600], //  [10/3/24, 9:30 AM]-[10/3/24, 4:00 PM]
        [1728048600, 1728072000], //  [10/4/24, 9:30 AM]-[10/4/24, 4:00 PM]
        [1728307800, 1728331200], //  [10/7/24, 9:30 AM]-[10/7/24, 4:00 PM]
        [1728394200, 1728417600], //  [10/8/24, 9:30 AM]-[10/8/24, 4:00 PM]
        [1728480600, 1728504000], //  [10/9/24, 9:30 AM]-[10/9/24, 4:00 PM]
        [1728567000, 1728590400], //  [10/10/24, 9:30 AM]-[10/10/24, 4:00 PM]
        [1728653400, 1728676800], //  [10/11/24, 9:30 AM]-[10/11/24, 4:00 PM]
        [1728912600, 1728936000], //  [10/14/24, 9:30 AM]-[10/14/24, 4:00 PM]
        [1728999000, 1729022400], //  [10/15/24, 9:30 AM]-[10/15/24, 4:00 PM]
        [1729085400, 1729108800], //  [10/16/24, 9:30 AM]-[10/16/24, 4:00 PM]
        [1729171800, 1729195200], //  [10/17/24, 9:30 AM]-[10/17/24, 4:00 PM]
        [1729258200, 1729281600], //  [10/18/24, 9:30 AM]-[10/18/24, 4:00 PM]
        [1729517400, 1729540800], //  [10/21/24, 9:30 AM]-[10/21/24, 4:00 PM]
        [1729603800, 1729627200], //  [10/22/24, 9:30 AM]-[10/22/24, 4:00 PM]
        [1729690200, 1729713600], //  [10/23/24, 9:30 AM]-[10/23/24, 4:00 PM]
        [1729776600, 1729800000], //  [10/24/24, 9:30 AM]-[10/24/24, 4:00 PM]
        [1729863000, 1729886400], //  [10/25/24, 9:30 AM]-[10/25/24, 4:00 PM]
        [1730122200, 1730145600], //  [10/28/24, 9:30 AM]-[10/28/24, 4:00 PM]
        [1730208600, 1730232000], //  [10/29/24, 9:30 AM]-[10/29/24, 4:00 PM]
        [1730295000, 1730318400], //  [10/30/24, 9:30 AM]-[10/30/24, 4:00 PM]
        [1730381400, 1730404800], //  [10/31/24, 9:30 AM]-[10/31/24, 4:00 PM]
        [1730467800, 1730491200], //  [11/1/24, 9:30 AM]-[11/1/24, 4:00 PM]
        [1730730600, 1730754000], //  [11/4/24, 9:30 AM]-[11/4/24, 4:00 PM]
        [1730817000, 1730840400], //  [11/5/24, 9:30 AM]-[11/5/24, 4:00 PM]
        [1730903400, 1730926800], //  [11/6/24, 9:30 AM]-[11/6/24, 4:00 PM]
        [1730989800, 1731013200], //  [11/7/24, 9:30 AM]-[11/7/24, 4:00 PM]
        [1731076200, 1731099600], //  [11/8/24, 9:30 AM]-[11/8/24, 4:00 PM]
        [1731335400, 1731358800], //  [11/11/24, 9:30 AM]-[11/11/24, 4:00 PM]
        [1731421800, 1731445200], //  [11/12/24, 9:30 AM]-[11/12/24, 4:00 PM]
        [1731508200, 1731531600], //  [11/13/24, 9:30 AM]-[11/13/24, 4:00 PM]
        [1731594600, 1731618000], //  [11/14/24, 9:30 AM]-[11/14/24, 4:00 PM]
        [1731681000, 1731704400], //  [11/15/24, 9:30 AM]-[11/15/24, 4:00 PM]
        [1731940200, 1731963600], //  [11/18/24, 9:30 AM]-[11/18/24, 4:00 PM]
        [1732026600, 1732050000], //  [11/19/24, 9:30 AM]-[11/19/24, 4:00 PM]
        [1732113000, 1732136400], //  [11/20/24, 9:30 AM]-[11/20/24, 4:00 PM]
        [1732199400, 1732222800], //  [11/21/24, 9:30 AM]-[11/21/24, 4:00 PM]
        [1732285800, 1732309200], //  [11/22/24, 9:30 AM]-[11/22/24, 4:00 PM]
        [1732545000, 1732568400], //  [11/25/24, 9:30 AM]-[11/25/24, 4:00 PM]
        [1732631400, 1732654800], //  [11/26/24, 9:30 AM]-[11/26/24, 4:00 PM]
        [1732717800, 1732741200], //  [11/27/24, 9:30 AM]-[11/27/24, 4:00 PM]
        [1732890600, 1732903200], //  [11/29/24, 9:30 AM]-[11/29/24, 1:00 PM]
        [1733149800, 1733173200], //  [12/2/24, 9:30 AM]-[12/2/24, 4:00 PM]
        [1733236200, 1733259600], //  [12/3/24, 9:30 AM]-[12/3/24, 4:00 PM]
        [1733322600, 1733346000], //  [12/4/24, 9:30 AM]-[12/4/24, 4:00 PM]
        [1733409000, 1733432400], //  [12/5/24, 9:30 AM]-[12/5/24, 4:00 PM]
        [1733495400, 1733518800], //  [12/6/24, 9:30 AM]-[12/6/24, 4:00 PM]
        [1733754600, 1733778000], //  [12/9/24, 9:30 AM]-[12/9/24, 4:00 PM]
        [1733841000, 1733864400], //  [12/10/24, 9:30 AM]-[12/10/24, 4:00 PM]
        [1733927400, 1733950800], //  [12/11/24, 9:30 AM]-[12/11/24, 4:00 PM]
        [1734013800, 1734037200], //  [12/12/24, 9:30 AM]-[12/12/24, 4:00 PM]
        [1734100200, 1734123600], //  [12/13/24, 9:30 AM]-[12/13/24, 4:00 PM]
        [1734359400, 1734382800], //  [12/16/24, 9:30 AM]-[12/16/24, 4:00 PM]
        [1734445800, 1734469200], //  [12/17/24, 9:30 AM]-[12/17/24, 4:00 PM]
        [1734532200, 1734555600], //  [12/18/24, 9:30 AM]-[12/18/24, 4:00 PM]
        [1734618600, 1734642000], //  [12/19/24, 9:30 AM]-[12/19/24, 4:00 PM]
        [1734705000, 1734728400], //  [12/20/24, 9:30 AM]-[12/20/24, 4:00 PM]
        [1734964200, 1734987600], //  [12/23/24, 9:30 AM]-[12/23/24, 4:00 PM]
        [1735050600, 1735063200], //  [12/24/24, 9:30 AM]-[12/24/24, 1:00 PM]
        [1735223400, 1735236000], //  [12/26/24, 9:30 AM]-[12/26/24, 1:00 PM]
        [1735309800, 1735333200], //  [12/27/24, 9:30 AM]-[12/27/24, 4:00 PM]
        [1735569000, 1735592400], //  [12/30/24, 9:30 AM]-[12/30/24, 4:00 PM]
        [1735655400, 1735678800], //  [12/31/24, 9:30 AM]-[12/31/24, 4:00 PM]
    ];

}
