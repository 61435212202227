import React, {useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Wizard } from 'components/user/account/signals/Wizard';

export const useModalSignalSignup = (showByDefault: boolean = false) => {

  const [show, setShow] = useState<boolean>(showByDefault);

  const render = () => {
    return (
      <Modal show={show} size="lg" centered onHide={()=>{setShow(false);}} keyboard={false} backdrop="static">
      <Modal.Body>
        <Wizard onClose={()=>setShow(false)}/>
      </Modal.Body>
    </Modal>
    )
  }

  return {
    render,
    setShow,
  }

}