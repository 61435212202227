import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ChartHelper } from 'components/public/Stock/ChartHelper';
import { SingleChart } from './SingleChart';
import * as moment from 'moment-timezone';
import { TodayChart } from './TodayChart';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Form from 'react-bootstrap/Form';
import { TimeZone } from '_constants/TimeZone';


function dragElement(elmnt: HTMLDivElement) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  elmnt.onmousedown = dragMouseDown;
  elmnt.style.cursor = 'grabbing';

  function dragMouseDown(e: any) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: any) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

export const MultiCompare: React.FunctionComponent = () => {

  const currentDate = moment.tz(TimeZone.AMERICA_NEW_YORK).format('HHmm') < "0930" ? new Date(moment.tz(TimeZone.AMERICA_NEW_YORK).subtract(1, 'day').format()) :
    new Date(moment.tz(TimeZone.AMERICA_NEW_YORK).format());
  // console.log({currentDate});
  const previousDate = ChartHelper.findPreviousTradingDay(currentDate);
  const [scaled, setScaled] = useState<boolean>(false);
  const [candidatesOnly, setCandidatesOnly] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | undefined>(previousDate);
  const [endDate, setEndDate] = useState<Date | undefined>(previousDate);
  const [candidates, setCandidates] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filterStartDate, setFilterStartDate] = useState<Date>(new Date(moment.tz(TimeZone.AMERICA_NEW_YORK).subtract(1, 'day').format()));
  const [filterEndDate, setFilterEndDate] = useState<Date>(new Date(moment.tz(TimeZone.AMERICA_NEW_YORK).subtract(1, 'day').format()));


  const [loading, setLoading] = useState<string[]>([]);

  const [stockSymbol, setStockSymbol] = useState<string>('TNA');
  const [filterSymbol, setFilterSymbol] = useState<string>('TNA');

  const onToggleCandidate = (date: string, isOn: boolean) => {
    if (isOn) {
      setCandidates([...candidates, date]);
    }

    if (!isOn) {
      setCandidates(candidates.filter((item: string) => { return item !== date }));
    }
  }

  const ref = useRef<HTMLDivElement>(null);

  const loadingRef = useRef<string[]>([]);

  useEffect(() => {
    if (ref.current) {
      dragElement(ref.current);
    }
  }, [])


  useEffect(() => {
    setRefresh(refresh + 1);
  }, [startDate, stockSymbol])

  const onScroll = (e: any) => {
    //setRefresh(refresh + 1);
  }

  const onLoad = (name: string) => {
    // console.log(`onloaded ${name}`, loadingRef.current);
    const i = loadingRef.current.indexOf(name);
    if (i >= 0) {
      loadingRef.current.splice(i, 1);
    }
    setIsLoading(loadingRef.current.length > 0);
  }

  const render = (date: string, i: number, arr: string[]) => {
    const nyDate = moment.tz(date, TimeZone.AMERICA_NEW_YORK);
    const currentDate = new Date(nyDate.format());
    const previousDate = ChartHelper.findPreviousTradingDay(new Date(nyDate.format()));
    const saved = candidates.indexOf(nyDate.format('YYYY-MM-DD')) >= 0;
    // console.log('rendering ', date);
    if (nyDate.format('YYYYMMDD') === moment.tz(TimeZone.AMERICA_NEW_YORK).format('YYYYMMDD')) {
      return <></>
    }


    return <div className="mb-5" style={{ width: '1000px' }} key={`chartkey-${i}`}>
      <pre>{JSON.stringify(date, null, 2)}</pre>
      <SingleChart
        lazyLoad={false}
        refresh={refresh}
        symbol={stockSymbol}
        saved={saved}
        currentDate={currentDate}
        previousDate={previousDate}
        fullXAxis={true}
        scaled={scaled}
        onToggle={onToggleCandidate}
        onLoad={onLoad}
      />
    </div>
  }


  const onSymbolChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterSymbol(e.target.value);
  }

  const onClickUpdate = () => {
    setIsLoading(true);

    setStartDate(filterStartDate);
    setEndDate(filterEndDate);
    setStockSymbol(filterSymbol);

    // set the loading array
    const days = ChartHelper.tradingDays(moment.tz(filterStartDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD'), moment.tz(filterEndDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD'));
    loadingRef.current = days;

    if (days.length === 0) {
      setIsLoading(false);
    }
  }

  return (
    <div className="" style={{ backgroundColor: "#ffffff" }}>
      <div className="d-flex justify-content-start align-items-center multi-stock-form " style={{ zIndex: 10000 }}>
        <div>
          <button className="btn btn-primary my-3" onClick={() => setScaled(!scaled)}>{scaled ? 'Show Price' : 'Show % Change'}</button>
        </div>

        <div className="ms-3">
          <Form.Check
            type="switch"
            id="show-all"
            label="Candidates Only"
            onClick={() => { setCandidatesOnly(!candidatesOnly) }}
          />
        </div>

        <div className="multi-stock-form-symbol mx-3">
          <div>Stock Symbol</div>
          <input type="text" value={filterSymbol} onChange={onSymbolChange} />
        </div>

        <div className="multi-stock-form-date mx-3" style={{ zIndex: 10000 }}>
          Select Start Date
          <ReactDatePicker selected={filterStartDate} onChange={(date: Date) => setFilterStartDate(date)} />
        </div>

        <div className="multi-stock-form-date mx-3" style={{ zIndex: 10000 }}>
          Select End Date
          <ReactDatePicker selected={filterEndDate} onChange={(date: Date) => setFilterEndDate(date)} />
        </div>

        <div className="align-self-center mx-3">
          <button className="btn btn-primary" onClick={() => onClickUpdate()} disabled={isLoading}>{isLoading ? <>Loading...</> : <>Update</>}</button>
        </div>

        {isLoading &&
          <div className="align-self-center ms-3">
            <div className="spinner-border " role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }

      </div>

      {/* <div onScroll={onScroll} style={{ height: '735px', overflowY: "scroll", width: '95%', minWidth: '1400px' }}> */}
      <div>
        <div ref={ref}
          style={{ position: "fixed", top: '111px', width: '1000px', zIndex: 1000 }} className="border">
          <TodayChart symbol={stockSymbol} scaled={scaled} currentDate={currentDate} previousDate={previousDate} />
        </div>


        {candidates.length > 0 && candidatesOnly &&
          <div className="list-charts" style={{ marginTop: '5px', marginBottom: '350px' }}>
            {candidates.map(render)}
          </div>
        }

        {(candidates.length === 0 || !candidatesOnly) &&
          <div className="list-charts" style={{
            marginTop: '5px',
            // marginBottom: '350px' 
          }}>
            {ChartHelper.tradingDays(moment.tz(startDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD'), moment.tz(endDate, TimeZone.AMERICA_NEW_YORK).format('YYYY-MM-DD')).map(render)}
          </div>
        }
      </div>

    </div>
  );
}
