import React, { useRef, useState } from "react";
import { Helper } from "_utils";
import * as Icons from 'components/common/Icons';

const FileUploadError: React.FunctionComponent<{ errors: any; touched: any; name: string }> = ({ errors, touched, name }) => {
  return <div className="error">{errors && errors[name] && touched && touched[name] ? errors[name] : ""}</div>;
};


/**
 * FileUpload - component to upload a single file.
 *  * 
 * @name {string} the name of the component
 * @title {string} The title displayed on the preview render
 * @setFieldValue {} from Formik
 * @errors {}  from Formik
 * @touched {} from Formik
 * @values {} from Formik
 * @targetField {string} the field that will return the path of the uploaded image
 */
const FileUpload: React.FunctionComponent<{
  name: string,
  title?: string | JSX.Element;
  setFieldValue: any;
  errors: any;
  touched: any;
  values: any;
  targetField: string;
  readOnly?: boolean;
  preview?: boolean;
  buttonLabel?: string | JSX.Element;
  showPlusIcon?: boolean;
  initialUrl?: string; // default view when first loaded
}> = ({ name, title, setFieldValue, errors, touched, values, targetField, initialUrl, buttonLabel = 'Upload', showPlusIcon = true, readOnly = false, preview = true }) => {

  const [filePreview, setFilePreview] = useState<any>(undefined);
  const refFileUpload = useRef<HTMLInputElement>(null);
  const [ status, setStatus ] = useState<string>('');

  const MAX_FILE_SIZE = 1024 * 1000;

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setStatus('');
      const file = e.currentTarget.files[0];
      const uploadedFile = await Helper.getPreviewFile(file);
      if (file.size > MAX_FILE_SIZE) {
        setStatus(`File is too large. Limit is 1 MB`);
        return;
      }
      setFieldValue(targetField, file);
      setFilePreview(uploadedFile);

      // get the dimensions
      // const img = new Image();
      // var objectUrl = window.URL.createObjectURL(file);
      // img.onload = function () {
      //     window.URL.revokeObjectURL(objectUrl);
      // };
      // img.src = objectUrl;
    }
  }

  const onClickUpload = () => {
    if (refFileUpload.current) {
      refFileUpload.current.click();
    }
  }

  return (
    <>
      <div className="form-group">
        {title && <div className="my-2 text-center">{title}</div>}
        <div className="my-2 d-flex flex-column justify-content-start align-items-start">

          <div className="image-preview">
            {!filePreview && initialUrl &&
              <img src={initialUrl} alt={values[name]} />
            }
            {filePreview &&
              <img src={filePreview} alt={values[name]} />
            }

            <div className="image-preview-icon">
              {showPlusIcon &&
                <div role="button" onClick={() => onClickUpload()}>
                  <Icons.PurplePlusCircle />
                </div>
              }
            </div>

            {!showPlusIcon &&
              <button type="button" className="btn bg-medium-purple text-white fw-bold mt-3" style={{ borderRadius: "1.5625rem" }} onClick={() => onClickUpload()}>{buttonLabel}</button>
            }
          </div>


          {status && <div className="alert alert-warning text-smaller">{status}</div>}
        </div>

        <div className="my-2">
          <input
            ref={refFileUpload}
            id={name}
            name={name}
            type="file"
            className="form-control"
            accept="image/jpeg, image/png, image/gif"
            onChange={onChange}
            readOnly={readOnly}
            disabled={readOnly}
            style={{ display: "none" }}
          />
          {errors && <FileUploadError errors={errors} touched={touched} name="image" />}
        </div>
      </div>

    </>
  );
};

export { FileUpload, FileUploadError };
