import { AuthenticationService, BaseApiService, userAuthenticationService} from 'services';
import { config } from 'config';
import { IPrediction } from 'interfaces/IPrediction';
import axios from 'axios';
import { ISymbolData, ISymbolDataLive } from 'interfaces/ISymbolData';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import {PredictionTypeEnum} from "../_constants/PredictionTypeEnum";
import LRUCache from "lru-cache";
import { IFollowee } from 'interfaces/IFollow';
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect';
import { IUserStatistic } from 'interfaces/IUserStatistic';
import { HttpService } from './HttpService';
import { IUserStatSearchOptions } from '_hooks/useUserStats';
import {Mutex, withTimeout} from 'async-mutex';

export interface ISearchOptions {
  userId?: number,
  predictionId?: number,
  symbolNames?: string [],
  usernames?: string[],
  predictionTypes?: PredictionTypeEnum[],
  orderBy?: sortTypeEnum,
  teamId?: number,
  contestId?: number,
  startTime?: number,
  endTime?: number,
  limit?: number,
}

export interface IUserStatsStockSymbolSearchOptions {
  userId: number,
  symbolName?: string,
  startTime?: number,
  endTime?: number,
}

export interface IUserStatsPredictionTypeSearchOptions extends IUserStatsStockSymbolSearchOptions {}

export interface IUserStatsStockSymbolResponse {
  stockSymbol: string,
  scoreAvg: number,
  predictionsCompleted: number,
}

export interface IUserStatsPredictionTypeResponse {
  predictionTypeId: PredictionTypeEnum,
  predictionsCompleted: number,
  scoreAvg: number,
}

export interface IUserProfileStatsSearchOptions {
  userId: number,
  startTime?: number,
  endTime?: number
}

export interface IUserProfileStatsResponse {
  averageScore: number,
  predictionsCompleted: number,
}

export interface IPostSubmitChartSearchOptions {
  startTimePredMade: number,
  endTimePredMade: number,
  predictionTypes: PredictionTypeEnum[],
  symbolName: string
}

export interface IPostSubmitChartResponse {
  id: number,
  value: number,
  typeId: PredictionTypeEnum,
  valueStock: number,
  valueTime: number,
  valueAt: number,
  userId: number,
  username: string,
  avatarUrl: string,
  userScoreLast30: number,
}

export interface IFollower {
  userId: number,
  createdAt: number,
}

class UserPredictionServiceError extends Error {}

class UserPredictionApiService extends BaseApiService<IPrediction> {

  private profileCache: LRUCache<number, {profile: IPublicProfile, mutex: Mutex}> = new LRUCache({
    max: 100,
    ttl: 120_000, // msec
  });

  constructor(
    protected apiBaseUrl:string, 
    protected singleEndpoint: string, 
    protected allEndpoint: string,
    protected authenticationService: AuthenticationService,
    protected httpService: HttpService,
  ) {
    super(apiBaseUrl, singleEndpoint, allEndpoint, authenticationService);
  }

  async getSymbolData (symbol: string, predictionType:PredictionTypeEnum, time?:number): Promise<ISymbolData> {
    const url = `${this.apiBaseUrl}/prediction/symbol-data`
    try {
      const response = await axios.post<any>(url, {symbol, time, predictionTypeId: predictionType});
      if (response.data.error) {
        throw new UserPredictionServiceError(response.data.error.message)
      }
      return response.data as ISymbolData;
    } catch (error: any) {
      throw new UserPredictionServiceError((error as Error).message);
    }
  }

  async getSymbolDataLive (symbol: string, predictionType:PredictionTypeEnum, time?:number): Promise<ISymbolDataLive> {
    const url = `${this.apiBaseUrl}/prediction/symbol-data-live`
    try {
      const response = await axios.post<any>(url, {symbol, time, predictionTypeId: predictionType});
      if (response.data.error) {
        throw new UserPredictionServiceError(response.data.error.message)
      }
      return response.data as ISymbolDataLive;
    } catch (error: any) {
      throw new UserPredictionServiceError((error as Error).message);
    }
  }


  async getAllPublic (searchOptions: ISearchOptions = {}): Promise<IPrediction[]> {
    const url = `${this.apiBaseUrl}/predictions`;
    const { 
      userId,
      symbolNames: symbols,
      predictionId,
      usernames,
      predictionTypes,
      orderBy,
      teamId,
      contestId,
      startTime,
      endTime,
      limit } = searchOptions;
    const filters = {
      userId,
      symbolNames: symbols,
      predictionId,
      usernames: usernames,
      predictionTypes,
      orderBy,
      teamId,
      contestId,
      startTime,
      endTime,
      limit
    }
    
    try {
      const response = await axios.post<any>(url, {filters}, this.authenticationService.getToken() !== '' ? await this.authenticationService.getAuthHeader() : undefined);
      if (response.data.error) {
        throw new UserPredictionServiceError(response.data.error.message)
      }
      return response.data.data as IPrediction[];
    } catch (error: any) {
      throw new UserPredictionServiceError((error as Error).message);
    }    
  }

  async enableNotify (followeeId: number): Promise<any> {
    const url = `${this.apiBaseUrl}/management/follow/${followeeId}`;
    try {
      const response = await axios.put<any>(url, {alertEnable: true}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw new UserPredictionServiceError(response.data.error.message)
      }
      if (response.data.success === false) {
        throw new UserPredictionServiceError('API rejected request. Follow failed.');
      }
      return response.data;
    } catch (error: any) {
      throw new UserPredictionServiceError((error as Error).message);
    }
  }

  async disableNotify (followeeId: number): Promise<any> {
    const url = `${this.apiBaseUrl}/management/follow/${followeeId}`;
    try {
      const response = await axios.put<any>(url, {alertEnable: false}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw new UserPredictionServiceError(response.data.error.message)
      }
      if (response.data.success === false) {
        throw new UserPredictionServiceError('API rejected request. Follow failed.');
      }
      return response.data;
    } catch (error: any) {
      throw new UserPredictionServiceError((error as Error).message);
    }
  }

  async addFollow (followeeId: number): Promise<any> {
    const url = `${this.apiBaseUrl}/management/follow/${followeeId}`;
    try {
      const response = await axios.post<any>(url, {}, await this.authenticationService.getAuthHeader());
      // console.log('addfollow', {response});
      if (response.data.error) {
        throw new UserPredictionServiceError(response.data.error.message)
      }
      if (response.data.success === false) {
        throw new UserPredictionServiceError('API rejected request. Follow failed.');
      }
      return response.data;
    } catch (error: any) {
      throw new UserPredictionServiceError((error as Error).message);
    }
  }

  async deleteFollow (followeeId: number): Promise<any> {
    const url = `${this.apiBaseUrl}/management/follow/${followeeId}`;
    try {
      const response = await axios.delete<any>(url, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw new UserPredictionServiceError(response.data.error.message)
      }
      if (response.data.success === false) {
        throw new UserPredictionServiceError('API rejected request. Unfollow failed.');
      }
      return response.data;
    } catch (error: any) {
      throw new UserPredictionServiceError((error as Error).message);
    }
  }

  async getFollowees (): Promise<IFollowee[]> {
    const url = `${this.apiBaseUrl}/management/followees`;
    try {
      const response = await axios.post<any>(url, {}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw new UserPredictionServiceError(response.data.error.message)
      }
      return response.data as IFollowee[];
    } catch (error: any) {
      throw new UserPredictionServiceError((error as Error).message);
    }
  }

  async getFollowers (): Promise<IFollower[]> {
    const url = `${this.apiBaseUrl}/management/followers`;
    try {
      const response = await axios.post<any>(url, {}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw new UserPredictionServiceError(response.data.error.message)
      }
      return response.data as IFollower[];
    } catch (error: any) {
      throw new UserPredictionServiceError((error as Error).message);
    }  }


  /**
   * 
   * @returns Promise<IPrediction[]>
   * returns the predictions made by the logged-in user's followed users
   */
  async getPredictionsByFollowed (searchOptions: ISearchOptions): Promise<IPrediction[]> {
    const url = `${this.apiBaseUrl}/management/predictions/following`;
    return await httpService.getMany<IPrediction>(url, {filters: searchOptions}, this.authenticationService);
  }

  async getPredictionsByTopPredictors(searchOptions: ISearchOptions): Promise<IPrediction[]> {
    const url = `${this.apiBaseUrl}/management/predictions/top`;
    return await httpService.getMany<IPrediction>(url, {filters: searchOptions}, this.authenticationService);
  }

  async getPublicProfile (id: number, isCacheOk: boolean = true): Promise<Readonly<IPublicProfile>> {

    let profile = this.profileCache.get(id);
    if (profile?.profile && isCacheOk) {
      // console.debug(`profile cache hit ${id}`);
      return profile?.profile;
    }
    if (!profile) {
      // console.debug(`profile cache is missing ${id}`)
      profile = {profile: null as any, mutex: new Mutex()};
      this.profileCache.set(id, profile);
    }
    if (profile.mutex.isLocked()) {
      // console.debug(`mutex is locked ${id}, waiting...`);
    }
    const release = await withTimeout(profile.mutex, 5_000).acquire();
    try {
      if (this.profileCache.get(id)?.profile) {
        // console.debug(`profile cache hit ${id}`);
        return this.profileCache.get(id)?.profile as any;
      }

      // console.debug(`acquired mutex ${id}`);
      const url = `${this.apiBaseUrl}/profile/${id}`;
      try {
        const response = await axios.post<any>(url, {}, this.authenticationService.getToken() !== '' ? await this.authenticationService.getAuthHeader() : undefined);
        if (response.data.error) {
          throw new UserPredictionServiceError(response.data.error.message)
        }
        profile.profile = response.data as IPublicProfile;
        this.profileCache.set(id, profile);
        // console.debug(`profile is updated ${id} usecache-${isCacheOk}`);
        return profile.profile;
      } catch (error: any) {
        throw new UserPredictionServiceError((error as Error).message);
      }
    } finally {
      if (release) {
        release();
        // console.debug(`released mutex lock ${id}`);
      }
    }
  }

  async getUserStats(searchOptions: IUserStatSearchOptions): Promise<IUserStatistic[]> {
    const url = `${this.apiBaseUrl}/prediction/user/stats`;
    return await httpService.getMany<IUserStatistic>(url, {filters: searchOptions});
  }

  async getUserStatsStockSymbol(searchOptions: IUserStatsStockSymbolSearchOptions): Promise<IUserStatsStockSymbolResponse[]> {
    const url = `${this.apiBaseUrl}/prediction/user/stats/${searchOptions.userId}/stock-symbol`;
    return await httpService.getMany<IUserStatsStockSymbolResponse>(url, {filters: searchOptions});
  }

  async getUserStatsPredictionType(searchOptions: IUserStatsPredictionTypeSearchOptions): Promise<IUserStatsPredictionTypeResponse[]> {
    const url = `${this.apiBaseUrl}/prediction/user/stats/${searchOptions.userId}/prediction-type`;
    return await httpService.getMany<IUserStatsPredictionTypeResponse>(url, {filters: searchOptions});
  }

  async getUserProfileStats(searchOptions: IUserProfileStatsSearchOptions): Promise<IUserProfileStatsResponse> {
    const url = `${this.apiBaseUrl}/profile/stats/${searchOptions.userId}`;
    return await httpService.httpPost<IUserProfileStatsResponse>(url, {filters: searchOptions});
  }

  async getPostSubmitChartData(searchOptions: IPostSubmitChartSearchOptions): Promise<IPostSubmitChartResponse[]> {
    const url = `${this.apiBaseUrl}/prediction/post-submit-chart-data`;
    return await httpService.getMany<IPostSubmitChartResponse>(url, {filters: searchOptions});
  }

}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const httpService = new HttpService(userAuthenticationService);
const userPredictionApiService: UserPredictionApiService = new UserPredictionApiService(apiBaseUrl, '/management/prediction', '/management/predictions', userAuthenticationService, httpService);

export { 
  UserPredictionApiService,
  UserPredictionServiceError, 
  userPredictionApiService,
}
