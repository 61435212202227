import React, { useState } from 'react'
import { StockHelper, NewYorkTz as tz } from 'predictagram-lib'
import { ContestHelper } from '_utils/ContestHelper';


export const enum DateFilterEnum {
  TODAY = 'Today',
  THIRTY_DAYS = '30 Days',
  YESTERDAY = 'Yesterday',
  SEVEN_DAYS = '7 Days',
}


export interface IDateFilterTimeRange {
  startTime: number,
  endTime?: number,
}

export const DateFilters: React.FunctionComponent<{onClick: (dateFilterEnum: DateFilterEnum, timeRange: IDateFilterTimeRange) => void}> = ({onClick}) => {


  const dayInMs = 24 * 60 * 60 * 1000;
  const todayNyDate = tz.getDateMidnight(new Date());

  const today = todayNyDate.getTime();

  const previousTradingDay = StockHelper.findPreviousTradingDay(new Date(today)).getTime();
  const yesterdayLabel = ContestHelper.getPreviousDayName(new Date(today));
  const sevenDays = today - (dayInMs * 7);
  const thirtyDays = today - (dayInMs * 30); 
  
  const [ activeTab, setActiveTab ] = useState<DateFilterEnum>(DateFilterEnum.SEVEN_DAYS);

  const filters: Map<DateFilterEnum, IDateFilterTimeRange> = new Map<DateFilterEnum, IDateFilterTimeRange>();

  if (StockHelper.isTradingDay(todayNyDate) &&  parseInt(tz.mdyhms(new Date()).hour24) >=12 ) {
    filters.set(DateFilterEnum.TODAY, {startTime: today});
  }

  filters.set(DateFilterEnum.YESTERDAY, {
      startTime: StockHelper.workingHours(new Date(previousTradingDay)).start.getTime(), 
      endTime: StockHelper.workingHours(new Date(previousTradingDay)).end.getTime()}
  );
  filters.set(DateFilterEnum.SEVEN_DAYS, {startTime: sevenDays});
  filters.set(DateFilterEnum.THIRTY_DAYS, {startTime: thirtyDays});
  
  const _onClick = (dateFilter: DateFilterEnum, timeRange: IDateFilterTimeRange) => {
    setActiveTab(dateFilter);
    onClick(dateFilter, timeRange )
  }

  const getTabLabel = (filter: string, timeRange: IDateFilterTimeRange) => {
    if (filter === DateFilterEnum.YESTERDAY) return yesterdayLabel;
    return filter;
  }

  return ( 
    <div className="date-filters d-flex justify-content-start align-items-center flex-row">  
      {Array.from(filters).map( ([filter, timeRange], i:number) => <div 
        role="button" 
        key={`${i}-date-filter`}
        className={`p-2 px-3 btn-tabs ${activeTab === filter ? 'btn-tabs-active' : ''}`} 
        onClick={()=>_onClick(filter, timeRange)}>
            {getTabLabel(filter, timeRange)}
      </div>)}
    </div>
  ) 
}
