import { IContest } from "interfaces/IContest"
import { MakePredictionButton } from "./MakePredictionButton"
import { userContestApiService } from "services/common/ContestApiService";
import { ContestHelper } from "_utils/ContestHelper";
import { ContestState } from "_constants/ContestState";
import { Helper } from "_utils";

export const ButtonOrStatus: React.FunctionComponent<{
  contest: IContest,
  hasPredictions: boolean,
  isJoined: boolean,
  isLoggedIn: boolean,
  setShowWizard: any
}> = ({ contest, hasPredictions, isJoined, setShowWizard, isLoggedIn }) => {

  const onClickMakePrediction = async (contest: IContest) => {

    if (!isJoined) {
      const result = await userContestApiService.joinContest(contest.id);
    }
    setShowWizard(true);
  }

  const contestState = ContestHelper.getContestState(contest, new Date());

  const minRequired = (contest: IContest) => {
    if (contestState === ContestState.CLOSED) {
      return <></>
    }
    return <div className="my-3">{contest.minPredictions} {Helper.pluralize('prediction', Number(contest.minPredictions))}  required to win.</div>
  }



  return <div className="px-md-3 px-1">

    {isLoggedIn && <>
      {hasPredictions && 
      <div>
        <span>You've submitted your prediction.</span> 
      </div>}

      {!hasPredictions &&
        <>
          {minRequired(contest)}
          <>
            {contestState === ContestState.PRE_OPEN &&
              <div className="contest-anon my-3">
                <div className="contest-anon-head my-2">Come back to this page in {ContestHelper.getTimeUntilOpen(contest, new Date())} to submit your prediction to this contest.</div>
              </div>
            }

            {contestState === ContestState.OPEN &&
              <>
                <div className="contest-anon my-3">
                  <div className="contest-anon-head my-2">Enter the contest & test your skills!</div>
                  <div className="contest-anon-body my-2">Click the button below to enter your price prediction and sign up. Then check back to see what others are predicting!</div>
                </div>
                <MakePredictionButton onClick={() => onClickMakePrediction(contest)} />
              </>
            }
          </>
        </>
      }

    </>}

    {!isLoggedIn && <>
      {minRequired(contest)}
    </>
    }

  </div>
}