import { PredictionTypeEnum } from "predictagram-lib";

export class PredictionFormHelper {

  public static getStepsizeMultiplier(predictionType: PredictionTypeEnum) {
    if ([
      PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
      PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
      PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
      ].includes(predictionType)) {
      return 1.3;
    }
    return 1;
  }


}