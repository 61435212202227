import { Route, Routes } from 'react-router-dom';
import { PublicLayout } from 'components/public/layout/PublicLayout';
import { TradegramPage } from 'components/user/tradegram/TradegramPage';
import { BetaTradegramRouter } from 'components/common/BetaTradegramRouter';
import { NotFoundPage } from 'components/NotFoundPage';

export const TradegramRoutes: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path='/' element={<PublicLayout />}>
        <Route index element={<BetaTradegramRouter showHead={true}><TradegramPage /></BetaTradegramRouter>} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}