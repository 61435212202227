import React, { useState } from 'react'

interface IProps<K, V> {
  options: Map<K, V>,
  title?: string | JSX.Element,
  selected: K | undefined,
  onSelect: (value: K) => void,
  className?: string,
  hasAll?: boolean,
  allTitle?: string,
}

export const SelectDropDown = <K, V extends unknown>({ options, selected, onSelect, title, className, hasAll=true, allTitle='All'}: IProps<K, V>) => {
  const [ value, setValue] = useState<K | undefined>(selected);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const v = event.target.value as K;
    const val = v === allTitle ? undefined : v;
    setValue(val as K);
    onSelect(val as K);
  };

  return (
    <div className={className}>
      {title && <div className="fw-bold">{title}</div>}
      <select onChange={handleSelectChange} 
        value={value ? value as string : undefined}
        style={{height: '27px'}}>
        {hasAll && <option value={undefined}>{allTitle}</option>}
        {Array.from(options).map(([k,v], index) => <option key={index} value={k as string}>{v as string}</option>)}
      </select>
    </div>
  )
}
