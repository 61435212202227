import React, { useContext, useEffect, useState } from 'react';
import { AccountContext } from 'components/common';
import { PredictionWizard } from 'components/user/prediction/PredictionWizard';
import { CloseButton, Modal } from 'react-bootstrap';
import { ModalLogin } from 'components/user/account/ModalLogin';
import { ModalRegister } from 'components/user/account/ModalRegister';
import { ModalForgotPassword } from 'components/user/account/ModalForgotPassword';
import { PixelHelper } from '_utils/PixelHelper';
import { useUtmSource } from '_hooks/useUtmSource';


export const ModalStockPrediction: React.FunctionComponent<{
  showDialog: boolean,
  setShowDialog: any,
  showReminder?: boolean,
}> = ({ showDialog, setShowDialog, showReminder = false }) => {

  const acctContext = useContext(AccountContext);
  const [showWizard, setShowWizard] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showSignup, setShowSignup] = useState<boolean>(false);
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const utmSource = useUtmSource();

  useEffect(() => {
    setShowWizard(acctContext && acctContext.account && !showReminder ? true : false);
  }, [showDialog, acctContext, showReminder])

  return (
    <>
      <ModalForgotPassword handleClose={() => setShowForgotPassword(false)} show={showForgotPassword} onReset={() => { setShowLogin(false); setShowForgotPassword(false) }} />
      <ModalLogin onClickForgotPassword={() => setShowForgotPassword(true)} handleClose={() => setShowLogin(false)} show={showLogin} onLogin={() => { setShowLogin(false) }} registerCallback={() => { setShowLogin(false); setShowSignup(true) }} />
      <ModalRegister handleClose={() => setShowSignup(false)} show={showSignup} onRegister={() => { setShowSignup(false) }} onClickLogin={() => { setShowSignup(false); setShowLogin(true) }} />

      <Modal show={showDialog} size="lg" centered  >
        <Modal.Body>
          {!showWizard && (!acctContext?.account || showReminder) &&
            <div className="prediction-modal-body d-flex flex-column justify-content-center align-items-center">
              <div className={`d-all ${showReminder ? '' : 'd-sm-block d-md-none'} align-self-end`}>
                <div role="button" title="Close" className="text-white text-smaller fw-bold me-2"><CloseButton variant="white" onClick={() => setShowDialog(false)} /></div>
              </div>

              {showReminder ? (
                <div className="my-3 text-center">To view more predictions, please contribute your prediction to the Predictagram community.</div>
              ) : (
                <div className="my-3 text-center">Join our community <br />by making a prediction!</div>
              )}

              <div>
                <button className="btn-make-prediction btn-make-prediction-large" onClick={() => {PixelHelper.clickMakePrediction(utmSource); setShowWizard(true)}}>+ Make a Prediction</button>
              </div>

              {!showReminder &&
                <div className="d-flex flex-row">
                  <div className="my-3">
                    <button type="button" className="btn btn-link text-white" onClick={() => { setShowDialog(false); setShowSignup(true) }}>Create an Account</button>
                  </div>
                  <div className="my-3">
                    <button type="button" className="btn btn-link text-white" onClick={() => { setShowDialog(false); setShowLogin(true) }}>Login</button>
                  </div>
                </div>
              }
            </div>
          }

          {showWizard &&
            <PredictionWizard
              onClickClose={() => setShowDialog(false)}
              onComplete={() => { setShowDialog(false); setShowWizard(false); window.scrollTo(0, 0) }}
              onRetrySignup={() => { setShowDialog(true); setShowWizard(true) }}
            />
          }

        </Modal.Body>
      </Modal>
    </>
  );
}