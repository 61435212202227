import React, { useState } from 'react';
import { PredictionTypeEnum } from 'predictagram-lib';

export const PredictionFilters: React.FunctionComponent<{ onClickTab: (predictionTypes: PredictionTypeEnum[]) => void }> = ({ onClickTab }) => {

  enum PredictionFilterTabsEnum {
    ALL = 'All',
    INTRADAY = 'Intraday',
    CLOSING = 'Closing',
    HIGH_LOW = 'High/Low'
  }

  const PredictionFilterTabs: Map<PredictionFilterTabsEnum, PredictionTypeEnum[]> = new Map([
    [PredictionFilterTabsEnum.ALL, [] ],
    [PredictionFilterTabsEnum.INTRADAY, [
        PredictionTypeEnum.VALUE_AT, 
        PredictionTypeEnum.VALUE_30_MIN, 
        PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
        PredictionTypeEnum.VALUE_1H_UP_DOWN]],
    [PredictionFilterTabsEnum.CLOSING, [PredictionTypeEnum.VALUE_CLOSE, PredictionTypeEnum.VALUE_CLOSE_UP_DOWN]],
    [PredictionFilterTabsEnum.HIGH_LOW, [PredictionTypeEnum.VALUE_HIGH, PredictionTypeEnum.VALUE_LOW]],
  ]);

  const [ currentTab, setCurrentTab ] = useState<PredictionFilterTabsEnum>(PredictionFilterTabsEnum.ALL);

  const _onClickTab = (filterTab: PredictionFilterTabsEnum, predictionTypes: PredictionTypeEnum[]) => {
    setCurrentTab(filterTab);
    onClickTab(predictionTypes);
  }

  return (
    <div className="d-flex flex-row justify-content-start align-items-center gap-1">
      {Array.from(PredictionFilterTabs.entries()).map(([searchTab, predictionTypes], i: number) => (
        <div key={`search-tab-${i}`} role="button" className={`btn-tabs p-2 px-3 ${currentTab === searchTab ? 'btn-tabs-active' : ''}`} onClick={()=>_onClickTab(searchTab, predictionTypes)}>
          {searchTab}
        </div>)
      )}
    </div>
  );
}