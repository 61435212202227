import React, { useContext } from "react";

import { AccountContext } from "../common";
import { IAccountContext } from '../../interfaces';
import { Url } from '../../_constants';
import { MakeAPredictionButton } from '../public/ListingsPage/components/MakeAPredictionButton';
import { useLocation, useNavigate, Link } from "react-router-dom";
import * as Icons from "./Icons";
import { useModalLoginRegisterPassword } from "_hooks/useModalLoginRegisterPassword";
import { Avatar, AvatarSizeEnum } from "./Avatar";
import { IPublicProfile } from "interfaces/IPublicProfile";
import { BetaText } from "./BetaText";

export const Logo = () => {
  return <div className="logo d-flex flex-row justify-content-start align-items-center">
    <img src="/images/logo.png" alt="Stock Predictions" />
  </div>
}

const AnchorLink: React.FunctionComponent<{ to: string, title?: string, className?: string, children: any }> = ({ to, className, children, title }) => {
  return (
    <a className={className} href={to} title={title}>{children}</a>
  );
}

const NotificationNavButton: React.FunctionComponent<{ on: boolean }> = ({ on }) => {
  const style: React.CSSProperties = {
    position: "relative",
  }

  const dotStyle: React.CSSProperties = {
    position: "absolute",
    right: "0",
    top: "0",
    width: "0.5rem",
    height: "0.5rem",
    backgroundColor: "#A771FF",
    borderRadius: "50%"
  }

  const accountContext: IAccountContext | null = useContext(AccountContext);

  return (
    <div style={style}>
      <Icons.Bell width={20} height={20} on={on} />
      {accountContext?.isLoggedIn() && !!accountContext.account.hasNewNotification &&
        <div style={dotStyle}></div>
      }
    </div>
  );
}

export const CollapsedNav = () => {
  const accountContext: IAccountContext | null = useContext(AccountContext);

  const location = useLocation();
  const loginRegisterPassword = useModalLoginRegisterPassword();

  let menuitems: any[] = [];


  if (accountContext?.isLoggedIn()) {

    const profile: IPublicProfile = {
      username: accountContext.account.username,
      avatarUrl: accountContext.account.avatarUrl
    } as IPublicProfile;

    menuitems = [...[
      //[Url.USER_DASHBOARD_PREDICTIONS, <Icons.LightningCharge  on={[Url.USER_DASHBOARD_PREDICTIONS].includes(location.pathname as Url)} />, 'Predictions',[Url.USER_DASHBOARD_PREDICTIONS]],
      [Url.USER_DASHBOARD_PREDICTIONS, <Icons.LightningCharge width={16} height={24} on={[Url.USER_DASHBOARD_PREDICTIONS, Url.USER_DASHBOARD_TRADES].includes(location.pathname as Url)} />, 'Predictions', [Url.USER_DASHBOARD_PREDICTIONS, Url.USER_DASHBOARD_TRADES]],
      [Url.USER_DASHBOARD, <Icons.LineChart width={16} height={24} on={location.pathname === Url.USER_DASHBOARD} />, 'Dashboard'],
      [Url.USER_NOTIFICATIONS, <NotificationNavButton on={location.pathname === Url.USER_NOTIFICATIONS} />, 'Notifications'],
      [Url.USER_TRADEGRAM, <Icons.UserWithCoin width={16} height={24} on={location.pathname === Url.USER_TRADEGRAM} />, 'Tradegram'],
      [Url.LEADERBOARD_ROOT, <Icons.BarChart width={16} height={24} on={[Url.LEADERBOARD_ROOT, Url.LEADERBOARD_USERS, Url.LEADERBOARD_TEAMS].includes(location.pathname as Url)} />, 'Leaderboard', [Url.LEADERBOARD_ROOT, Url.LEADERBOARD_USERS, Url.LEADERBOARD_TEAMS]],
      [`${Url.PUBLIC_STOCK_PREDICTOR}/${accountContext.account.id}`, <>
        <Avatar profile={profile} size={AvatarSizeEnum.EXTRA_SMALL} />
      </>, 'My Profile'],
    ]]
  }

  return <div className="">

    <>{loginRegisterPassword.render()}</>

    {accountContext?.account ?
      <div className="d-flex justify-content-between mx-1 align-items-center">
        <div className="flex-grow-1"><Link to="/" title="Home"><Logo /></Link></div>
        <div className="d-flex gap-2">
          {
            menuitems.map((m, i) => {
              const [link, icon, label, paths] = m;

              return <div key={`navitem-key-${i}`}>
                {link.includes(Url.PUBLIC_STOCK_PREDICTOR) ?
                  <AnchorLink to={link}>
                    <div className={`navigation-link-mobile ${location.pathname === link || (paths && paths.includes(location.pathname)) ? 'navigation-link-mobile-on' : ''}`}>
                      <div className="navigation-link-mobile-image">{icon}</div>
                    </div>
                  </AnchorLink>

                  :
                  <Link to={link}>
                    <div className={`navigation-link-mobile ${location.pathname === link || (paths && paths.includes(location.pathname)) ? 'navigation-link-mobile-on' : ''}`}>
                      <div className="navigation-link-mobile-image">{icon}</div>
                    </div>
                  </Link>

                }
              </div>
            })
          }
        </div>
      </div>
      :
      <div className="d-flex flex-row justify-content-start align-items-center navigation-mobile navigation-links gap-3">
        <div><Link to="/"><Logo /></Link></div>
        <div><button className="btn bg-purple btn-curved-side btn-small text-white text-smaller" onClick={() => loginRegisterPassword.login.show(true)}>Log In</button></div>
        <div><button className="btn bg-purple btn-curved-side btn-small text-white text-smaller" onClick={() => loginRegisterPassword.register.show(true)}>Sign Up</button></div>
      </div>
    }
  </div>
}

export const ExpandedNav: React.FunctionComponent = () => {

  const accountContext: IAccountContext | null = useContext(AccountContext);
  const location = useLocation();
  const loginRegisterPassword = useModalLoginRegisterPassword();
  const navigate = useNavigate();

  let menuitems: any[] = [];

  if (accountContext?.isLoggedIn()) {
    const profile: IPublicProfile = {
      username: accountContext.account.username,
      avatarUrl: accountContext.account.avatarUrl
    } as IPublicProfile;

    menuitems = [...[
      [Url.USER_DASHBOARD_PREDICTIONS, <Icons.LightningCharge width={22} height={22} on={[Url.USER_DASHBOARD_PREDICTIONS, Url.USER_DASHBOARD_TRADES].includes(location.pathname as Url)} />, 'Predictions', [Url.USER_DASHBOARD_PREDICTIONS, Url.USER_DASHBOARD_TRADES]],
      [Url.USER_DASHBOARD, <Icons.LineChart on={[Url.USER_DASHBOARD].includes(location.pathname as Url)} />, 'Dashboard', [Url.USER_DASHBOARD]],
      [Url.USER_NOTIFICATIONS, <NotificationNavButton on={location.pathname === Url.USER_NOTIFICATIONS} />, 'Notifications'],
      [Url.USER_TRADEGRAM, <Icons.UserWithCoin on={location.pathname === Url.USER_TRADEGRAM} />, <>My Trades <BetaText /></>],
      [Url.LEADERBOARD_ROOT, <Icons.BarChart on={[Url.LEADERBOARD_ROOT, Url.LEADERBOARD_USERS, Url.LEADERBOARD_TEAMS].includes(location.pathname as Url)} />, 'Leaderboard', [Url.LEADERBOARD_ROOT, Url.LEADERBOARD_USERS, Url.LEADERBOARD_TEAMS]],
      [`${Url.PUBLIC_STOCK_PREDICTOR}/${accountContext.account.id}`, <>
        <Avatar profile={profile} size={AvatarSizeEnum.EXTRA_SMALL} />
      </>, 'My Profile', [`${Url.PUBLIC_STOCK_PREDICTOR}/${accountContext.account.id}`, `${Url.PUBLIC_STOCK_PREDICTOR}/${accountContext.account.id}/positions`]],

    ]]
  }


  return <div className="d-flex flex-column align-items-center" style={{ position: "fixed", margin: "0 0.625rem" }}>

    <div className="my-5 navigation-link">
      <Link to="/" title="Home">
        <Logo />
      </Link>
    </div>

    <div className="d-flex flex-column align-items-start navigation-links w-100">
      <>{loginRegisterPassword.render()}</>

      {accountContext?.account ?
        <>
          {
            menuitems.map((m, i) => {
              const [link, icon, label, paths] = m;
              if (link.includes(Url.PUBLIC_STOCK_PREDICTOR)) {
                return <div className="my-2" key={`menu-key-${i}`}>
                  <AnchorLink to={link} className="text-decoration-none">
                    <div className={`navigation-link ${location.pathname === link || (paths && paths.includes(location.pathname)) ? 'navigation-link-on' : ''}`}>
                      <div className="navigation-links-image">{icon}</div><span className="ms-2">{label}</span>
                    </div>
                  </AnchorLink>
                </div>
              }
              return <div className="my-2" key={`menu-key-${i}`}>
                <Link to={link} className="text-decoration-none">
                  <div className={`navigation-link ${location.pathname === link || (paths && paths.includes(location.pathname)) ? 'navigation-link-on' : ''}`}>
                    <div className="navigation-links-image">{icon}</div><span className="ms-2">{label}</span>
                  </div>
                </Link>
              </div>
            })
          }
        </>
        :
        <>
          <div className="my-2"><div role="button" className="text-white" onClick={() => loginRegisterPassword.login.show(true)}>Log In</div></div>
          <div className="my-2"><div role="button" className="text-white" onClick={() => loginRegisterPassword.register.show(true)}>Sign Up</div></div>
        </>
      }
    </div>
    <div className="my-3"><MakeAPredictionButton /></div>
    {accountContext?.isLoggedIn() &&
      <div className="d-flex flex-column align-items-start gap-1 align-self-start">
        <div role="button" className="text-lighter-charcoal text-13" onClick={() => navigate(Url.USER_TERMS)}>Terms and Conditions</div>
        <div role="button" className="text-lighter-charcoal text-13" onClick={() => navigate(Url.USER_PRIVACY_POLICY)}>Privacy Policy</div>
        <div className="text-lighter-charcoal text-13">&copy;{new Date().getFullYear()} predictagram.com</div>
        <div><a href="mailto:contact@predictagram.com" className="text-lighter-charcoal text-13 contact-us">Contact Us</a></div>
      </div>
    }
  </div>
}



