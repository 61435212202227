import { adminApiServiceCommon } from "services/AdminApiService";
import { useApi } from "./useApi";
import { IStockSymbolAdmin } from "predictagram-lib";
import { useState } from "react";

export function useAdminStockSymbols () {
  const [data, setData] = useState<IStockSymbolAdmin[]>([]);
  
  const load = async () => {
    const data = await adminApiServiceCommon.getSymbols();
    setData(data);
    return data;
  }

  const getById = (id: number) => {
    return data.find(v=>v.id===id);
  }
  return {
    api: useApi<IStockSymbolAdmin[], null>(load, null),
    getById
  }

}

