import {DateHelper} from "./date.helper";
import {DateEx} from "./date.ex";
/* tslint:disable:max-classes-per-file */

// export class DateEx extends DE {}

export class Calendar {
    // protected date: Date;
    // public constructor() {
    //     this.date = new Date();
    // }

    epochSec() {
        return DateHelper.epochSec(this.newDate());
    }

    /**
     * Create new
     */
    newDate() {
        return new DateEx();
    }

    isSameDate(epochSecs: number) {
        return DateHelper.dateFormatNumber(this.newDate())===DateHelper.dateFormatUtcNumber(new Date(epochSecs*1000));
    }
}

export class CalendarStub extends Calendar {
    public constructor(protected now:Date) {
        super();
    }

    newDate(): DateEx {
        return new DateEx(this.now);
    }
}
