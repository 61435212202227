import React from 'react'
import { AutoTradeSetupStatusEnum, AutoTradeSetupStatusEnumHelper } from 'predictagram-lib';
import { SelectDropDown } from 'components/common/form/SelectDropDown';


interface IProps {
  value: AutoTradeSetupStatusEnum | undefined,
  setValue: (value: AutoTradeSetupStatusEnum) => void,
  className?: string,
}

export const TradeSetupStatusFilter:React.FunctionComponent<IProps> = ({value, setValue, className}) => {

  return (
    <SelectDropDown<AutoTradeSetupStatusEnum, string> 
      onSelect={setValue}
      options={AutoTradeSetupStatusEnumHelper.names}
      selected={value}
      title="Status"
      className={className}
    />
  )
}



