import React from 'react';
import { Outlet } from 'react-router-dom';
import { MessengerProvider } from 'components/common/messenger';

export const BlackLayout: React.FunctionComponent = () => {
  return (
    <div className="container-fluid g-0 bg-black">
      <MessengerProvider>
        <Outlet />
      </MessengerProvider>
    </div>
  );
}