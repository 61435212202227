import { useApiAchievementUserStats } from '_hooks/useApiAchievementUserStats'
import { UrlHelper } from '_utils/UrlHelper'
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { IPublicProfile } from 'interfaces/IPublicProfile'
import React, { useEffect, useContext, useCallback } from 'react'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { IUserAchievementUserStatResponse, IUserAchievementUserStatSearchFilter } from 'services/UserAchievementService'
import { LeagueTabEnum, leagueTabNames } from './LeagueTabs'
import { AccountContext } from 'components/common'
import { SeasonContext } from 'components/common/SeasonContext'

export const LeagueUserList: React.FunctionComponent<{leagueTab: LeagueTabEnum, top?: number}> = ({leagueTab, top}) => {

  const navigate = useNavigate();
  const acctContext = useContext(AccountContext);
  const seasonContext = useContext(SeasonContext);
 
  const initialFilter: IUserAchievementUserStatSearchFilter = {
    minExperiencePoints: leagueTabNames.get(leagueTab)?.value[0] as number,
    maxExperiencePoints: leagueTabNames.get(leagueTab)?.value[1] as number,
    endTime: seasonContext?.season?.endDateSec,
    startTime: seasonContext?.season?.startDateSec,
  };
  const api = useApiAchievementUserStats(initialFilter);
  
  useEffect(()=>{
    const league = leagueTabNames.get(leagueTab)
    const value = league?.value as [number, number];
    const [minExperiencePoints, maxExperiencePoints ] = value;
    if (api.apiState.isLoaded) {
      api.setFilter({
        minExperiencePoints,
        maxExperiencePoints,
        startTime: seasonContext?.season?.startDateSec,
        endTime: seasonContext?.season?.endDateSec
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leagueTab])

  const getProfile = useCallback((item: IUserAchievementUserStatResponse) => {
    return { userId: item.userId, avatarUrl: item.avatarUrl, username: item.username } as IPublicProfile;
  }, []);

  if (api.apiState.isLoading) {
    return <Spinner />
  }

  if (api.apiState.isLoaded && api.data.length === 0) {
    return <div className="league-user-list container"><ErrorMessage>Scores Not Yet Available.</ErrorMessage></div>
  }


  const isBronze = (userId: number) => {
    return acctContext?.account.id === userId && leagueTab === LeagueTabEnum.BRONZE
  }

  const getFilteredData = (data: IUserAchievementUserStatResponse[], topN?: number) => {
    if (!topN ) {
      return [...data]
    }

    const filtered = data.slice(0,topN);
    const selfInFilter = filtered.find(item=>item.userId === acctContext?.account.id);
    if (selfInFilter) {
      return [
        ...filtered,
      ]
    }

    const self = data.find(item=>item.userId === acctContext?.account.id);
    if (self) {
      return [
        ...filtered,
        self
      ]
    } else {
      return [
        ...filtered
      ]
    }
  }

  const FullList = () => {
    const top = leagueTab === LeagueTabEnum.BRONZE ? 5 : undefined;

    return (
      <div className="league-user-list container">
        <div className="fw-bold text-15">{leagueTabNames.get(leagueTab)?.label} League</div>
        {getFilteredData(api.data, top).map((item, i) => <div className={`row my-3 text-14 fw-bold ${acctContext?.account.id === item.userId ? `league-user-list-row-${leagueTabNames.get(leagueTab)?.label.toLowerCase()} d-flex align-items-center py-2` : ''}`} key={`league-list-row-${i}`}>
          <div className="col-1">{isBronze(item.userId) && i > 3 ? "" : i + 1}</div>
          <div className="col-7" onClick={()=>navigate(UrlHelper.getProfileUrl(item.userId))} role="button">
            <div className="d-flex gap-2 align-items-center">
              <div className="league-user-list-avatar">
                <Avatar profile={getProfile(item)} size={AvatarSizeEnum.SMALL} />
              </div>
              <div>{item.username}</div>
            </div>
          </div>
          <div className={`col-4 text-end text-${leagueTabNames.get(leagueTab)?.label.toLowerCase() as string}`}>{item.points.toLocaleString()} XP</div>
        </div>
        )}
      </div>
    );
  }

  const TopN = () => {
    return (
      <div className="league-topn">
        {getFilteredData(api.data, top).map((item, i) => <div className={`row my-3 text-14 fw-bold ${acctContext?.account.id === item.userId ? `league-user-list-row-${leagueTabNames.get(leagueTab)?.label.toLowerCase()} d-flex align-items-center py-2` : ''}`} key={`league-list-row-${i}`}>
          <div className="col-1">{i > 2 ? "" : i + 1}</div>
          <div className="col-7" onClick={()=>navigate(UrlHelper.getProfileUrl(item.userId))} role="button">
            <div className="d-flex gap-2 align-items-center">
              <div className="league-user-list-avatar">
                <Avatar profile={getProfile(item)} size={AvatarSizeEnum.SMALL} />
              </div>
              <div>{item.username}</div>
            </div>
          </div>
          <div className={`col-4 text-end text-${leagueTabNames.get(leagueTab)?.label.toLowerCase() as string}`}>{item.points.toLocaleString()} XP</div>
        </div>
        )}
      </div>
    );
  }

  if (api.apiState.isLoaded && api.data.length > 0) {

    if (top) {
      return <TopN/>    
  }

    return (
      <FullList />
    )
  }

  if (api.apiState.isError) {
    return (
      <ErrorMessage>{api.error}</ErrorMessage>
    )
  }

  return <ErrorMessage>Unknown state</ErrorMessage>
}
