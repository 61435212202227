import { config } from "config";
import { AuthenticationService, userAuthenticationService } from "./AuthenticationService";
import { ILeague, ILeagueSeason, ITeam } from "interfaces/league";
import { IPublicProfile } from "interfaces/IPublicProfile";
import { IReferral } from "interfaces/IReferral";
import { ITeamMemberStat } from "interfaces/ITeamMemberStat";
import { HttpService } from "./HttpService";
import { ITeamSearchOptions } from "_hooks/useTeamPublic";
import { ITeamMemberStatSearchOptions } from "_hooks/useTeamMemberStats";

class UserLeagueApiServiceError extends Error {}

class UserLeagueApiService {

  constructor(
    protected apiBaseUrl:string, 
    protected authenticationService: AuthenticationService,
    protected httpService: HttpService
  ) { }

  async getDefaultSeason(dt: Date = new Date()): Promise<ILeagueSeason> {
    const seasons = await this.getSeasons();

    if (seasons.length === 0) {
      throw new UserLeagueApiServiceError('missing season');
    }

    if (seasons.length > 0) {
      const dtMs = dt.getTime() / 1_000;
      const season = seasons.find(s => dtMs >= s.startTime && dtMs <= s.endTime );
      if (season) {
        return season;
      }
    }

    // can't find season matching date. get the last one.
    return await this.getSeasonById(seasons.sort((a, b) => b.creationTimestamp >= a.creationTimestamp ? 1 : -1)[0].id);
  }

  async getPreviousSeason(): Promise<ILeagueSeason | undefined> {
    const seasons = await this.getSeasons();
    if (seasons.length === 0) {
      throw new UserLeagueApiServiceError('missing season');
    }
    const sorted = seasons.sort((a, b) => b.creationTimestamp >= a.creationTimestamp ? 1 : -1);
    if (sorted.length > 1) {
      return await this.getSeasonById(sorted[1].id);
    }
    return undefined; // no previous season
  }


  async getSeasons(): Promise<ILeagueSeason[]> {
    const url = `${this.apiBaseUrl}/league/seasons`
    return await httpService.getMany<ILeagueSeason>(url);
  }

  async getSeasonById(seasonId: number): Promise<ILeagueSeason> {
    const url = `${this.apiBaseUrl}/league/season/${seasonId}`;
    return await httpService.getSingle<ILeagueSeason>(url);
  }

  async getTeamsPublic(searchOptions?: ITeamSearchOptions): Promise<ITeam[]> {
    const url = `${this.apiBaseUrl}/league/teams`
    return await httpService.getMany<ITeam>(url, {filters: searchOptions});
  }

  async getTeamById(teamId: number, searchOptions?: ITeamSearchOptions): Promise<ITeam> {
    const url = `${this.apiBaseUrl}/league/team/${teamId}`
    return await httpService.httpPost<ITeam>(url, {filters: searchOptions})
  }

  async getTeamMembers(teamId: number): Promise<IPublicProfile[]> {
    const url = `${this.apiBaseUrl}/league/team/members/${teamId}`;
    return await httpService.getMany<IPublicProfile>(url);
  }

  async getTeamMembersStats(teamId: number, searchOptions: ITeamMemberStatSearchOptions): Promise<ITeamMemberStat[]> {
    const url = `${this.apiBaseUrl}/league/team/members/stats/${teamId}`;
    return await httpService.getMany<ITeamMemberStat>(url, {filters: searchOptions});
  }  

  async joinTeam(teamId: number) {
    const url = `${this.apiBaseUrl}/league/team/join/${teamId}`;
    return await httpService.httpPost(url, {});
  }

  async getLeagues(): Promise<ILeague[]> {
    const url = `${this.apiBaseUrl}/leagues`;
    return await httpService.getMany<ILeague>(url);
  }

  async getLeagueById(leagueId: number): Promise<ILeague[]> {
    const url = `${this.apiBaseUrl}/leagues/${leagueId}`;
    return await httpService.getMany<ILeague>(url);
  }

  async getReferrals(): Promise<IReferral[]> {
    const url = `${this.apiBaseUrl}/management/referrers`;
    return await httpService.getMany<IReferral>(url, {}, this.authenticationService);
  }

}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const httpService = new HttpService(userAuthenticationService);
const userLeagueApiService: UserLeagueApiService = new UserLeagueApiService(apiBaseUrl, userAuthenticationService, httpService);

export { 
  userLeagueApiService,
  UserLeagueApiServiceError,
  UserLeagueApiService
}