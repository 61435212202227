import React from 'react'
import { DropdownFilter, IDropdownFilterItem } from 'components/common/DropDownFilter/DropdownFilter'

import { IconLabel } from '../IconLabel';
import { Fire, Globe, People } from 'react-bootstrap-icons';
import { useDashboardSettings } from '_hooks/useDashboardSettings';
import { StorageKey } from '_constants';

export type TraderTypeDropdownItemType = IDropdownFilterItem<TraderTypeFilterEnum, TraderTypeFilterEnum>;

export enum TraderTypeFilterEnum  {
  TOP = 1,
  FOLLOWING = 2,
  EVERYONE = 3
}

export const traderTypeDropDownItemsMap: Map<TraderTypeFilterEnum, TraderTypeDropdownItemType> = new Map<TraderTypeFilterEnum, TraderTypeDropdownItemType>([
  [TraderTypeFilterEnum.TOP, { key: TraderTypeFilterEnum.TOP, value: TraderTypeFilterEnum.TOP, label: <IconLabel icon={<Fire />} label='Top Traders' /> }],
  [TraderTypeFilterEnum.FOLLOWING, { key: TraderTypeFilterEnum.FOLLOWING, value: TraderTypeFilterEnum.FOLLOWING, label: <IconLabel icon={<People />} label='Following' /> }],
  [TraderTypeFilterEnum.EVERYONE, { key: TraderTypeFilterEnum.EVERYONE, value: TraderTypeFilterEnum.EVERYONE, label: <IconLabel icon={<Globe />} label='Everyone' /> }],
]
);

export const traderTypeDropDownItems: TraderTypeDropdownItemType[] = Array.from(traderTypeDropDownItemsMap).map((item) => {
  return item[1]
})

export const getUserTypeDropDownItem = (traderTypeFilter: TraderTypeFilterEnum) => {
  return traderTypeDropDownItemsMap.get(traderTypeFilter) || traderTypeDropDownItems[0];
}

interface IProps {
  initialValue: TraderTypeFilterEnum
  setValue: (value: TraderTypeFilterEnum) => void,
  className?: string,
}

export const TraderTypeDropDownFilter:React.FunctionComponent<IProps> = ({initialValue, setValue, className}) => {

  const items = traderTypeDropDownItems;
  const [dashboardSettings, setDashboardSettings] = useDashboardSettings(localStorage, StorageKey.DASHBOARD_SETTINGS);

  const _setValue = (value: TraderTypeFilterEnum) => {
    setDashboardSettings({
      ...dashboardSettings,
      traderTypeFilter: value,
    })
    setValue(value);
  }

  return (
    <DropdownFilter<TraderTypeFilterEnum, TraderTypeFilterEnum> items={items} initialSelectedKey={initialValue} onSelect={(item)=>_setValue(item)} className={className}/>
  )
}
