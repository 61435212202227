import React, {useContext, useState} from "react";
import {useParams} from "react-router";
import {adminBrokerTransactionsApiService} from "../../../_hooks/useAdminBrokerTransactions";
import {Spinner} from "../../common/Spinner";
import {useApiServiceState} from "../../../_hooks/useApiServiceState";
import {IAdminBrokerSecurity, IBrokerTransaction} from "../../../services/AdminBrokerTransactionsApiService";
import {SymbolName} from "../../user/tradegram/SecurityCard";
import {DateHelper, StockHelper} from "predictagram-lib";
import {optionTranTypeDropdownItemsMap} from "../../user/tradegram/BrokerTransactionTypeDropdown";
import {APIState, ApiStateImpl} from "../../../_constants/APIState";
import {MessengerContext} from "../../common/messenger";

export const AdminTradeBulkEdit: React.FunctionComponent = () => {
  const api = adminBrokerTransactionsApiService;
  const securityId = parseInt(useParams().securityId as string, 10);
  const msgrContext = useContext(MessengerContext);

  const supplier = async()=>{
    const trans = await api.getTransactions({brokerSecurityId:securityId});
    setTransactions(trans);
    const security = await api.getSecurity(securityId);
    setStockSymbolOption(security.stockSymbolOption);
    setSecurity(security);
    return {
      security: security,
      transactions: trans?.sort((a,b)=>a.id-b.id),
    }
  };
  const {items, state, setState, reload} = useApiServiceState<{security:IAdminBrokerSecurity,transactions:IBrokerTransaction[]}>(supplier);
  // const security = items?.security as IAdminBrokerSecurity;

  const [stockSymbolOption, setStockSymbolOption] = useState<any>();

  const [security, setSecurity] = useState<IAdminBrokerSecurity>(null as any);
  const [transactions, setTransactions] = useState<IBrokerTransaction[]>(null as any);

  const saveSecurity = async()=>{
    await saveReload({security:{stockSymbolOption:stockSymbolOption}});
  }

  const saveTransactions = async()=>{
    const transactions:any[] = [];
    items?.transactions.forEach(t=>{
      const tNew:any = {
        securityTypeId: t.securityTypeId,
        transactionTypeId: t.typeId,
        stockSymbol: t.stockSymbolName,
        price: t.price,
        quantity: t.quantity,
      };
      if (t.description) {
        tNew.transactionDescription = t.description;
      }
      if (t.stockSymbolOption) {
        tNew.stockSymbolOption = t.stockSymbolOption;
      }
      transactions.push(tNew);
    });
    await saveReload({transactions:transactions});
  }

  const saveReload = async(data: any)=>{
    try {
      await api.updateSecurityBulk(items?.security.id as number, data);
      reload();
      msgrContext.setMessage({ body: 'Saved.' }, false);
    } catch (error) {
      setState(new ApiStateImpl(APIState.ERROR, error as Error));
    }
  }

  return (
    <>
      {state.isLoading() && <Spinner />}

      {state.isError() && <div>{state.error()?.message}</div>}

      {state.isLoaded()  && <>
       <div>

         <SymbolName security={security as any} boldName={false} />
           {items?.security.stockSymbolOption && <>
            <div className="d-flex gap-2">
              <input value={stockSymbolOption} onChange={(e) => {
                const val = e.target.value;
                setStockSymbolOption(val);
                if (StockHelper.parseOptionSymbol(val)) {
                  security.stockSymbolOption = val;
                  setSecurity(Object.assign({}, security));
                }
              }} /*placeholder="(000) 000-0000"*/ />
              <button className="btn btn-secondary" type="button" onClick={saveSecurity}>Save Security</button>
            </div>
           </>}


       </div>
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>Id</th>
              <th>Action</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Timestamp EST</th>
            </tr>
          </thead>

          <tbody>
          {transactions?.map((item, i) => (
            <tr key={item.id}>

              <td key={'id'+item.id}>{item.id}</td>
              <td key={'typeId'+item.id}>
                {/*{item.typeId}*/}
                <select value={item.typeId} onChange={(e)=>{item.typeId=parseInt(e.target.value, 10);setTransactions([...transactions])}} >
                  {Array.from(optionTranTypeDropdownItemsMap.values()).map((v)=>(
                    <option value={v.value}>{v.label}</option>
                  ))};
                </select>
              </td>
              <td key={'quantity'+item.id}><input type='number' value={item.quantity} onChange={(e)=>{item.quantity=e.target.value?parseInt(e.target.value, 10):'' as any;setTransactions([...transactions])}}/></td>
              <td key={"price"+item.id}><input type="number" step="0.01" value={item.price} onChange={(e)=>{item.price=e.target.value?parseFloat(e.target.value):'' as any;setTransactions([...transactions])}} /></td>
              <td key={"created"+item.id}>{DateHelper.dateTimeFormatUs(item.createdAt)}</td>
            </tr>
           ))}

          </tbody>
        </table>
        <div className="d-flex gap-2">
          <button className="btn btn-secondary" type="button" onClick={saveTransactions}>Save Transactions</button>
          <button className="btn btn-secondary" type="button" onClick={reload}>Cancel & Reload Page</button>
        </div>
      </>
      }

    </>
  )
};
