import React, { useCallback, useEffect, useState } from 'react'
import { config } from 'config'
import { TopPredictor } from './TopPredictor';
import { useUserStats } from '_hooks/useUserStats';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { NewYorkTz as tz } from 'predictagram-lib';
import { IUserStatistic } from 'interfaces/IUserStatistic';
import { APIState } from '_constants/APIState';


export const TopPredictors: React.FunctionComponent = () => {

  const predictors: number[] = config.welcomePagePredictors;

  const initialStartTime = () => {
    const today = tz.getDateMidnight(new Date()).getTime();
    const dayInMs = 24 * 60 * 60 * 1000;
    const sevenDaysAgo = today - (dayInMs * 7);
    return sevenDaysAgo;
  }

  const userStats = useUserStats(userPredictionApiService, {
    startTime: initialStartTime() / 1000,
    minScore: 0.4,
  });

  const [sorted, setSorted] = useState<IUserStatistic[]>([]);

  useEffect(()=>{
    if (userStats.apiState === APIState.LOADED) {
      const sorted = userStats.items
        .filter((a) => predictors.includes(a.userId))
        .sort((a, b) => b.score - a.score);

      setSorted(sorted);
    } 

  }, [userStats.apiState])


  if (userStats.apiState === APIState.LOADING) {
    return (
      <div className="predictor top d-flex justif-content-center align-items-center gap-1">
        {new Array(7).fill("").map((a, key) => <div className="predictor-card top shimmer" key={`pcard-key-${key}`}></div>)}
    </div>      
    )
  }


  return (
    <div className="predictor top d-flex justif-content-center align-items-center gap-1">
      {sorted.map((stat: IUserStatistic, key: number) => <div key={`card-key-${key}`}>
        {userStats.apiState === APIState.LOADED && <TopPredictor userStat={stat}/>}
      </div>
      )}
    </div>
  )
}

