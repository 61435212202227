import React from 'react';
import { IPrediction } from 'interfaces/IPrediction';
import { PredictionTypeEnum, PredictionTypeHelper, NewYorkTz as tz } from 'predictagram-lib';
import { Helper } from '_utils';
import { IPrice } from './PredictionLazy';
import { PredictionFormHelper } from '_utils/PredictionFormHelper';
import { LastQuote } from 'components/common/LastQuote';

export const predictionMovement = (predictionType: PredictionTypeEnum, isGreen: boolean): string | JSX.Element => {
  const className = isGreen ? "text-bright-green" : "text-bright-red";
  if ([
    PredictionTypeEnum.VALUE_AT,
    PredictionTypeEnum.VALUE_CLOSE,
    PredictionTypeEnum.VALUE_AT_8PM,
    PredictionTypeEnum.VALUE_30_MIN
    ].includes(predictionType)) {
    return <><span className={className}>{isGreen ? 'rise' : 'fall'}</span> to</>
  }

  if (predictionType === PredictionTypeEnum.VALUE_HIGH) {
    return `have a high of`
  }

  if (predictionType === PredictionTypeEnum.VALUE_LOW) {
    return `have a low of`
  }

  if (PredictionTypeHelper.isUpDown(predictionType)) {
    return <><span className={className}>{isGreen ? 'rise by' : 'fall by'}</span></>
  }

  return "";
}

export const symbolLink = (prediction: IPrediction) => {
  return <span className="prediction-detail-symbol"><a href={`/stock/predictions/${prediction.stockSymbol}`}>${prediction.stockSymbol}</a></span>
}

export const valueRange = (prediction: IPrediction, price: IPrice): string => {
  if (PredictionTypeHelper.isRange(prediction.typeId as number)) {
    return `${price.range.low.toFixed(2)} - ${price.range.high.toFixed(2)}`
  }

  if (PredictionTypeHelper.isUpDown(prediction.typeId as number)) {
    if (!prediction.valueDeviation) {
      return ""
    }
    const stepSizeMultiplied =  PredictionFormHelper.getStepsizeMultiplier(prediction.typeId as PredictionTypeEnum);
    return `${Helper.formatCurrency(Math.abs(prediction.valueDeviation * stepSizeMultiplied), 2)}`
  }
  return price.range.mid.toFixed(2);
}

export const PredictionDetail: React.FunctionComponent<{ prediction: IPrediction, price: IPrice }> = ({ prediction, price }) => {
  const lastPriceDate = new Date(price.lastPriceEpoch * 1000);
  const dst = tz.getTimezoneOffset(lastPriceDate) === -5 ? 'EST' : 'EDT';

  const byTime = tz.format(new Date(prediction.valueAt as number * 1000)).hour24MinuteAmpm(); // '[placeholder]'; //DateHelper.newYorkTimezone(prediction.valueAt as number).formatHour24MinuteAmpm();
  const isToday = tz.isSameDay(new Date(), new Date((prediction.valueAt as number) * 1000));
  const roundedClose = Helper.roundUpToClosing(byTime);

  const predictionAction = () => {
    if (!prediction.typeId) {
      return <></>
    }
    return <span className="prediction-detail-text">will {predictionMovement(prediction.typeId, price.isGreen)}</span>
  }

  const predictionWhen = () => {
    if (!prediction.typeId) return <></>

    const preposition = PredictionTypeHelper.getPreposition(prediction.typeId);
    
    return <div className="prediction-detail-text">
      {isToday ? (
        <>{[PredictionTypeEnum.VALUE_CLOSE, PredictionTypeEnum.VALUE_HIGH, PredictionTypeEnum.VALUE_LOW].includes(prediction.typeId) 
              ? <>{preposition} closing </> 
              : <>{preposition} {roundedClose} {dst}</>} today</>
      ) : (
        <>{preposition} {roundedClose} {dst}, {tz.format(new Date(prediction.valueAt as number * 1000)).monthNameDate()} </>
      )}
    </div>
  }

  const valueRangeEl = () => {
    return (
      <div className="prediction-detail-range-price-range">
        <div className="d-flex flex-row justify-content-center align-items-center gap-1">
          <div>
            <div className={`stock-detail-arrow arrow-${price.isGreen ? 'up' : 'down'}`}></div>
          </div>
          <div className="fw-bold">
          {valueRange(prediction, price)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="d-flex flex-row flex-wrap justify-content-start align-items-center">
        <span className="me-1">{symbolLink(prediction)}</span><span className="me-1">{predictionAction()}</span><span className="me-1">{valueRangeEl()}</span> <span className="me-1">{predictionWhen()}</span>
      </div>

      {prediction.description &&
        <div className="prediction-detail-description mt-3 mb-2">{prediction.description?.split("\n").map((p: string, i: number) => <p key={`p-key-${i}`}>{p}</p>)}</div>
      }

      <div className="mt-2">
      <LastQuote symbol={prediction.stockSymbol} />
      </div>
    </div>

  );
}
