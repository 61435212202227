import React, { useContext, useState } from 'react'
import { Form, Formik } from 'formik';
import { FieldWithError } from 'components/common';
import { Validator, validationMap } from 'schemas';
import { ISurveyPayload, surveyService } from 'services/SurveyService';
import { MessengerContext } from 'components/common/messenger';

export const SignupForm: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);
  const [success, setSuccess] = useState<boolean>(false);
  const initialValues = {
    email: '',
  }

  const onSubmit = async (data: any, actions: any) => {
    const payload: ISurveyPayload = {
      surveyName: 'DISCOUNT_PROMO',
      userEmail: data.email
    }
    try {
      await surveyService.save(payload);
      setSuccess(true);

    } catch (error: any) {
      console.error(error);
      msgrContext.setMessage({ body: "An unexpected error occurred." });
    }
  }

  return (
    <>
    {success ? <>
      <div className="discount-promo-signup-success p-5 bg-darkest-gray rounded">
      Your discount has been reserved! We will contact you soon with updates.
      </div>
    </>
    :
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationMap.get(Validator.DISCOUNT_PROMO_SIGNUP)}>
        {({ errors, touched }) => {
          return <Form>
            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="email"
              placeholder="email@domain.com"
            />

            <button type="submit" className="pill bg-mint-green w-100 mt-2 border-0 text-18 fw-bold py-2 mt-2 bg-hover-white ">Reserve</button>
          </Form>
        }}
      </Formik>
    }
    </>
  )
}
