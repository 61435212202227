import React, { useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ILeagueSeason, ITeam } from 'interfaces/league';
import { MakeAPredictionButton } from 'components/public/ListingsPage/components/MakeAPredictionButton';
import { Avatars } from './Avatars';
import { useReferral } from '_hooks/useReferral';
import { ShareTeam } from 'components/common/ShareTeam';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import * as Icons from 'components/common/Icons';
import { MessengerContext } from 'components/common/messenger';
import { ContestOverview } from './leaderboards/components/ContestOverview';
import { ContestRulesLink } from './leaderboards/components/ContestRulesLink';
import { useDefaultSeason } from '_hooks/useDefaultSeason';
import { userLeagueApiService } from 'services/UserLeagueApiService';
import { Helmet } from 'react-helmet';

const ContestButton: React.FunctionComponent<{ season: ILeagueSeason }> = ({ season }) => {

  const msgrContext = useContext(MessengerContext);
  const showOverview = () => {
    msgrContext.setMessage({ body: <ContestOverview season={season} /> }, true);
  }
  return (
    <div role="button" className="btn-contest" onClick={() => showOverview()}>
      <div className="d-flex justify-content-center align-items-center gap-2"><Icons.MoneyBag />Contest Overview</div>
    </div>
  )
}



export const TeamDetailPage: React.FunctionComponent<{ isInvitation?: boolean }> = ({ isInvitation }) => {

  const { item: season, loaded: loadedSeason, error: errorSeason } = useDefaultSeason(userLeagueApiService);

  const JoinTeam = () => {
    return <div role="button" className="btn btn-tertiary w-100" onClick={onJoinClick}>+ Join Team</div>
  }

  const JoinForCash = () => {
    return (
      <div className="team-detail-promo-text my-3">
        Join {team.name} to compete for up to $1500 in cash prizes!
      </div>
    )
  }

  const AvatarSection = () => {
    return <>
      {members.length >= 3 &&
        <div className="d-flex justify-content-center align-items-start flex-column">
          <div className="team-detail-avatars">
            <Avatars members={members} team={team} />
          </div>
        </div>
      }
    </>
  }

  const AboutTeam = () => {
    return <>
      <div className="team-detail-owner-details my-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="fw-bold">About the team</div>
          <div><ShareTeam team={team} /></div>
        </div>
        <div className="my-3">
          {team.description}
        </div>
      </div>
    </>
  }
  const { team, members, onJoinClick, isJoined } = useOutletContext<{
    team: ITeam,
    members: IPublicProfile[],
    onJoinClick: any,
    isJoined: boolean,
    isDuringSeason: boolean,
    season: ILeagueSeason,
    teamPredictions: JSX.Element,
  }>();


  useReferral(!!isInvitation ? team.id : undefined);

  return (
    <div className="team-home">
      {team &&
        <>
          {loadedSeason &&
            <Helmet>
              <title>Predictagram: {team.name} Home</title>
            </Helmet>
          }
          <AboutTeam />

          <AvatarSection />

          {isJoined && <div className="mt-3 mb-4"><MakeAPredictionButton /></div>}

          {!isJoined &&
            <>
              <div className="my-3"><JoinTeam /></div>
              {/* <JoinForCash /> */}
            </>
          }

          {/* <div className="d-flex justify-content-center align-items-start flex-column">
            {loadedSeason && <ContestButton season={season} />}
            <ContestRulesLink />
          </div> */}

        </>
      }
    </div>
  );
}