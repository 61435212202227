import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { userPredictionApiService } from 'services/UserPredictionApiService';

import { IQuote } from 'components/public/Stock/IQuote';
import { dragElement, LimitVerticalDrag } from 'components/common/utils/dragElement';
import { IPrediction } from 'interfaces/IPrediction';
import { PredictionChart } from 'components/public/ListingsPage/components/Prediction/PredictionChart';
import { predictionService } from 'services';
import { IStockSymbol } from 'interfaces/IStockSymbol';
import {
  StockHelper,
  PredictionTypeEnum,
  NewYorkTz,
  PredictionTypeHelper,
  IntradayHelper,
  IQuoteFull
} from 'predictagram-lib';
import { MessengerContext, Severity } from 'components/common/messenger';
import { PredictionTypeTitle } from './PredictionType';
import { Helper } from '_utils';
import { IChart } from 'interfaces/chart/IChart';
import { ChartModel as ChartOptions, ChartParams } from "../../../models/chart.model";
import { Spinner } from 'components/common/Spinner';
import * as Icons from 'components/common/Icons';
import { StockPrice } from './StockPrice';
import { useSymbolData } from '_hooks/useSymbolData';
import { useSymbolDataLive } from '_hooks/useSymbolDataLive';
import { APIState } from '_constants/APIState';
import { PredictionFormHelper } from '_utils/PredictionFormHelper';
import { MessageHelper } from '_utils/MessageHelper';

interface IRangeYAxis {
  yMin: number,
  yMax: number,
}

interface IOverlayColors {
  priceHigh: 'red' | 'green' | 'gray';
  priceLow: 'red' | 'green' | 'gray';
  gradient: 'red' | 'green' | 'gray';
}

export interface ISliderRange {
  yHigh: number,
  yMid: number,
  yLow: number,
}

interface IProps {
  predictionTypeEnum: PredictionTypeEnum,
  valueAt: Date,
  symbol: IStockSymbol,
  onSaveCallback: (p: IPrediction) => void,
  cardWidth: number,
  forceHeight?: number,
  showTip?: boolean,
  refreshLimit?: number,
}

export const getValueAt = (predictionTypeEnum: PredictionTypeEnum, date: Date) => {
  switch (predictionTypeEnum) {
    case PredictionTypeEnum.VALUE_30_MIN_UP_DOWN:
    case PredictionTypeEnum.VALUE_30_MIN:
    case PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE:
      return NewYorkTz.nextNMinutes(30, date);
    case PredictionTypeEnum.VALUE_1H_UP_DOWN:
    case PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE:
      return NewYorkTz.nextNMinutes(60, date);
    case PredictionTypeEnum.VALUE_AT:
    case PredictionTypeEnum.VALUE_LOW:
    case PredictionTypeEnum.VALUE_HIGH:
    case PredictionTypeEnum.VALUE_CLOSE_UP_DOWN:
    case PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE:
    default:
      return IntradayHelper.getWhen(date).d
  }
}

export const MakePredictionForm: React.FunctionComponent<IProps> = ({ 
  predictionTypeEnum,
  valueAt: initialValueAt,
  symbol,
  onSaveCallback,
  cardWidth,
  forceHeight, 
  showTip = false, 
  refreshLimit = 0}) => {

  const isPredictionType = (p: PredictionTypeEnum) => {
    return predictionTypeEnum === p;
  }

  const chartHeight = forceHeight ? forceHeight : (window.innerHeight || document.documentElement.clientHeight) * 0.50;
  const plotMarginTop = 10;
  // const plotMarginTopBottom = 10;
  const plotMarginBottom = 50;
  const plotMarginRight = 60;
  const handleRef = useRef<HTMLDivElement | null>(null);
  const containerElementRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const isHighLow = predictionTypeEnum === PredictionTypeEnum.VALUE_HIGH || predictionTypeEnum === PredictionTypeEnum.VALUE_LOW;

  const rangedPredictions: PredictionTypeEnum[] = [
    PredictionTypeEnum.VALUE_AT,
    PredictionTypeEnum.VALUE_AT_8PM,
    PredictionTypeEnum.VALUE_CLOSE
  ]

  const msgrContext = useContext(MessengerContext);

  const refCard = useRef<HTMLDivElement>(null); // reference to parent container of Chart

  // const [symbolData, setSymbolData] = useState<ISymbolData | undefined>(undefined);
  // const [symbolDataLive, setSymbolDataLive] = useState<ISymbolDataLive | undefined>(undefined);
  // const [errorStatus, setErrorStatus] = useState<string>('');

  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [chartData, setChartData] = useState<IChart | undefined>(undefined);

  const [sliderRange, setSliderRange] = useState<ISliderRange>({ yHigh: 0, yLow: 0, yMid: 0 });
  const [initialSliderRange, setInitialSliderRange] = useState<ISliderRange | undefined>(undefined);

  const [rangeYAxis, setRangeYAxis] = useState<IRangeYAxis>({} as IRangeYAxis);
  const [selectorHeightPx, setSelectorHeightPx] = useState<number>(0);
  const [pricePerPixel, setPricePerPixel] = useState<number>(0);
  const [selectorStartPosPx, setSelectorStartPosPx] = useState<number>(0);
  // const [bigRangePosition, setBigRangePosition] = useState<ISliderRange>({ yHigh: 0, yLow: 0, yMid: 0 });
  const [stepSizeMultiplied, setStepSizeMultiplied] = useState<number>(0);
  const [overlayColors, setOverlayColors] = useState<IOverlayColors>(undefined as any);

  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [description, setDescription] = useState<string>('');

  const [showToolTip, setShowToolTip] = useState<boolean>(showTip);
  const [valueAt, setValueAt] = useState<Date>(initialValueAt);
  const [isSaveAllowed, setIsSaveAllowed] = useState<boolean>(true);


  const symbolData = useSymbolData(userPredictionApiService, {predictionType: predictionTypeEnum, symbol: symbol.symbolName, time: Helper.timeSecQuery()})
  const symbolDataLive = useSymbolDataLive(userPredictionApiService, {predictionType: predictionTypeEnum, symbol: symbol.symbolName, time: Helper.timeSecQuery()})

  const [dataTimerActive, setDataTimerActive] = useState<boolean>(false);
  const [dataLiveTimerActive, setDataLiveTimerActive] = useState<boolean>(false);

  const isRangeOverlapping = (range1: ISliderRange, range2: ISliderRange): boolean => {
    if (!range2) {
      return false;
    }
    return (range2.yMid >= range1.yLow && range2.yMid <= range1.yHigh)
  }


  /**
   * @param yPx top-zero
   */
  const calcSetSlider = useCallback((yPx: number) => {
    const h = rangeYAxis.yMax - (yPx * pricePerPixel) + (PredictionTypeHelper.isUpDown(predictionTypeEnum) ? (stepSizeMultiplied / 2) : 0);
    const l = h - (selectorHeightPx * pricePerPixel);
    const range = {
      yHigh: h,
      yLow: l,
      yMid: h - (h - l) / 2,
    } as ISliderRange;
    setSliderRange(range);

    const colour = isRangeOverlapping(range, initialSliderRange as ISliderRange) && predictionTypeEnum === PredictionTypeEnum.VALUE_30_MIN ? 'gray' : yPx <= selectorStartPosPx ? 'green' : 'red';
    setOverlayColors({
      priceHigh: colour,
      priceLow: colour,
      gradient: colour,
    });
  }, [rangeYAxis, pricePerPixel, selectorHeightPx, selectorStartPosPx, initialSliderRange, predictionTypeEnum]);

  const beforeDrag = useCallback(() => {
    if (showToolTip === true) {
      setShowToolTip(false);
    }
  }, [showToolTip])

  const afterDrag = useCallback((y: number) => {
    calcSetSlider(y);
  }, [calcSetSlider]);

  useEffect(() => {
    calcSetSlider(selectorStartPosPx);
  }, [selectorStartPosPx, calcSetSlider]);

  useEffect(() => {
    if (handleRef.current && containerElementRef.current && overlayRef.current) {
      dragElement({
        elmnt: handleRef.current,
        containerElement: containerElementRef.current,
        overlay: overlayRef.current,
        onDragCallback: afterDrag,
        beforeDragCallback: beforeDrag,
        overlayYShiftTop: -plotMarginTop,
        limitVerticalDrag: [PredictionTypeEnum.VALUE_HIGH, PredictionTypeEnum.VALUE_LOW].includes(predictionTypeEnum) ?
          (predictionTypeEnum === PredictionTypeEnum.VALUE_HIGH ? LimitVerticalDrag.UP : LimitVerticalDrag.DOWN) : undefined,
      });
    }
  }, [afterDrag, beforeDrag, predictionTypeEnum]);

  const loadChart = useCallback(async (symbol: IStockSymbol, predictionType: PredictionTypeEnum) => {
    const timeSecsLatest = symbolData.item.time;
    const chartXDates = ChartOptions.calcStatsRangeMakePrediction(timeSecsLatest, symbolData.item.statsLatestTime, predictionType);
    // query stats up to latest symbol's time
    const chartEndSec = chartXDates.chartEndSec;
    const interval = PredictionTypeHelper.predictionInterval(predictionType);
    const stats: ReadonlyArray<IQuote> = await predictionService.getBarsData(symbol.symbolName || '', chartXDates.start, chartXDates.end, false, interval);
    const chartWidth = cardWidth || (refCard.current ? refCard.current.offsetWidth : undefined); // not likely to get undefined because this is only called after load.

    const predictionToolWidth = 50;
    const d = ChartOptions.prebuildChartDay({
      stats: stats as IQuoteFull[],
      endFillTimeSec: chartEndSec, // fill up to with emtpy space
      chartWidth: chartWidth ? chartWidth - predictionToolWidth : undefined,
      predictionTypeId: predictionType,
      xAxisLabelMonthDayOnly: predictionType === PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
    });
    const last = d.lastStats;

    const stepSizeMultiplied = (symbolData.item.stepSize as number) * PredictionFormHelper.getStepsizeMultiplier(predictionTypeEnum);
    setStepSizeMultiplied(stepSizeMultiplied);

    const minMaxMultiplier = PredictionTypeHelper.isUpDown(predictionTypeEnum) ? symbolData.item.stepSize as number * 2 : symbolData.item.stepSize as number
    // +    const stepSize = [
    //   +      PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
    //   +      PredictionTypeEnum.VALUE_1H_UP_DOWN,
    //   +      PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
    //   +      PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D
    //   +    ].includes(Number(predictionType)) ? symbolData.item.stepSize as number * 2 : symbolData.item.stepSize as number;
      

    const minMax = StockHelper.calcMinMax(minMaxMultiplier * ([PredictionTypeEnum.VALUE_AT_8PM].includes(predictionType) ? 3 : 1), d.dataLowDay, d.dataHighDay, last.c);

    const chartYMin = minMax.min as number;
    const chartYMax = minMax.max as number;
    const pricePerPixel = (chartYMax - chartYMin) / (chartHeight - (plotMarginTop + plotMarginBottom));
    setPricePerPixel(pricePerPixel);
    const selHeightPx = isHighLow ? 40 : stepSizeMultiplied * (PredictionTypeHelper.isUpDown(predictionTypeEnum) ? 2 : 1) / pricePerPixel; 
    setSelectorHeightPx(selHeightPx);

    setInitialSliderRange({
      yHigh: (symbolDataLive.item.stockValue + stepSizeMultiplied),
      yLow: symbolDataLive.item.stockValue - stepSizeMultiplied,
      yMid: symbolDataLive.item.stockValue
    })

    const range = {
      yMin: chartYMin,
      yMax: chartYMax,
    } as IRangeYAxis
    setRangeYAxis(range);

    const cData = ChartOptions.plotData('main', d.plotLines.during, ChartOptions.plotLineColor);
    const chartParams: ChartParams = {
      tickVals: d.tickVals, tickText: d.tickText, formatStr: ChartOptions.formatPriceStr(d.dataHighDay),
      yMin: chartYMin, yMax: chartYMax, plotData: [cData],
      rangeBreaks: d.chartDataPlotlyXGaps, rangeBreaksLines: d.chartDatePlotlyXGapsLines,
    };
    const chart: IChart = ChartOptions.dailyChartTemplate(chartParams) as IChart;
    chart.layout.margin = { l: 0, r: plotMarginRight, b: plotMarginBottom, t: plotMarginTop, pad: 0, };
    chart.layout.height = chartHeight;
    chart.divId = `${symbol}-${timeSecsLatest}`;

    // if (PredictionTypeHelper.isUpDown(predictionTypeEnum)) {
    //   chart.layout.yaxis = { ...chart.layout.yaxis, showticklabels: false, zeroline: false }
    // }

    if (predictionTypeEnum !== PredictionTypeEnum.VALUE_30_MIN) {
      chart.layout.shapes.push(...[
        ChartOptions.shapeHorizontalLineValue(last.c),
      ]);
    }

    chart.layout.shapes.push(...[
      ChartOptions.shapeVerticalLinePredictionTime(timeSecsLatest, 0.25, 0.75),
    ]);
    setChartData(chart);

    const startPos = Math.round((range.yMax - last.c) / pricePerPixel - selHeightPx / 2);

    setSelectorStartPosPx(startPos);

    // setBigRangePosition({
    //   yHigh: (range.yMax - symbolDataLive.item.stockValue - stepSizeMultiplied) / pricePerPixel,
    //   yMid: (range.yMax - symbolDataLive.item.stockValue) / pricePerPixel,
    //   yLow: (range.yMax - symbolDataLive.item.stockValue + stepSizeMultiplied) / pricePerPixel
    // })

    // console.log([startPos, startPos - (stepSizeMultiplied * pricePerPixel) ])
    // console.log([range.yMax, last.c, pricePerPixel, selHeightPx]);
    // console.log([symbolDataLive.item.stockValue]);

    setLoaded(true);
    setLoading(false);

  }, [symbolData.item.time, symbolData.item.statsLatestTime, symbolData.item.stepSize, cardWidth, symbolDataLive.item.stockValue, chartHeight, predictionTypeEnum]);

 
  const refreshChart = useCallback(async (refreshAll: boolean = false) => {
    // if (refreshCounter < REFRESH_LIMIT && 
    if (symbolData.apiState === APIState.LOADED && symbolDataLive.apiState === APIState.LOADED) {
      symbolDataLive.refresh();
      if (refreshAll) {
        symbolData.refresh();
      }
      await loadChart(symbol, predictionTypeEnum);
      // console.log([refreshLimit, refreshCounter, (refreshLimit > 0 && refreshLimit < refreshCounter)])
      if (refreshLimit > 0 && refreshLimit > refreshCounter) {
        // console.log('incrementing counter');
        setRefreshCounter(refreshCounter + 1);
      }
      // console.log('refreshing chart');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbolData.apiState, symbolDataLive.apiState, loadChart, symbol, predictionTypeEnum, refreshLimit, refreshCounter])


  useEffect(()=>{
    if (symbolData.apiState === APIState.LOADED && symbolDataLive.apiState === APIState.LOADED) {
      loadChart(symbol, predictionTypeEnum)
    }
  }, [loadChart, predictionTypeEnum, symbol, symbolData.apiState, symbolDataLive.apiState]);


  useEffect(() => {
    // refreshes stock quote
    const now = Date.now();
    const todayCloseDate = StockHelper.workingHours(new Date()).end;
    let symbolDataLiveTimer: NodeJS.Timeout;

    // console.log('in useEffect symbolDataLive');

    // console.log([symbolDataLive.apiState === APIState.LOADED, todayCloseDate.getTimeSec(), symbolDataLive.item.expAt, now])

    let timeoutMs = (symbolDataLive.item.expAt * 1000) - (now) - 2000; // refresh 2 seconds sooner to account for network lag.
    // if there is issue with time sync, need to make sure at least 15s is available
    // @TODO: add expireIn to response
    timeoutMs = Math.max(8_500, timeoutMs);

    if (symbolDataLive.apiState === APIState.LOADED && todayCloseDate.getTimeSec() >= symbolDataLive.item.expAt) {

      if (refreshLimit === 0 || refreshLimit > refreshCounter) {
        // console.log(`will refresh quote in ${(symbolDataLive.item.expAt * 1000) - (now)} ms`);
        // console.log([symbolDataLive.item.expAt * 1000, now]);
        symbolDataLiveTimer = setTimeout(async () => {
          if (!isSubmitting) {
            await refreshChart();
          }
          setDataLiveTimerActive(false);
        }, timeoutMs);
      }

    }

    // clean up
    return () => {
      if (symbolDataLiveTimer) {
        // console.log('cleared symbolDataLiveTimer');
        clearTimeout(symbolDataLiveTimer);
      }
    }

  }, [refreshLimit, dataLiveTimerActive, isSubmitting, predictionTypeEnum, symbol.symbolName, refreshChart, symbolDataLive.apiState, symbolDataLive.item.expAt, refreshCounter])

  useEffect(() => {
    // refreshes stepSize
    const now = Date.now();
    const todayCloseDate = StockHelper.workingHours(new Date()).end;
    let symbolDataTimer: NodeJS.Timeout;

    if (symbolData.apiState === APIState.LOADED && todayCloseDate.getTimeSec() >= symbolData.item.expAt) {

      if (refreshLimit === 0 || refreshLimit > refreshCounter) {
        // console.log(`will refresh stepSize in ${(symbolData.item.expAt * 1000) - (now)} ms`);
        // console.log([symbolData.item.expAt * 1000, now]);

        symbolDataTimer = setTimeout(async () => {
          if (!isSubmitting) {
            await refreshChart(true);
          }
          setDataTimerActive(false);
        }, (symbolData.item.expAt * 1000) - (now));
      }
    }

    // clean up
    return () => {
      if (symbolDataTimer) {
        // console.log('cleared symbolDataTimer');
        clearTimeout(symbolDataTimer);
      }
    }

  }, [dataTimerActive, isSubmitting, predictionTypeEnum, symbol.symbolName, refreshChart, symbolData.apiState, symbolData.item.expAt])  


  const onClickSavePrediction = async () => {

    if (!isSaveAllowed) {
      msgrContext.setMessage({ body: 'Cannot save. Prediction time is no longer valid.' }, true, Severity.FATAL);
      return;
    }

    setIsSubmitting(true);

    // validation: check for idle time; user may have gone away and clicked save after some time has elapsed.
    // if more than 4 minutes, notify user and refresh
    // @TODO: note to revisit this to test during market hours.
    const limit = (4 * 60 * 1_000);
    const now = (new Date()).getTime() / 1_000;
    const elapsed = now - (symbolData.item.time || 0);

    if (elapsed >= limit) {
      msgrContext.setMessage({ body: 'Form was idling for more than several minutes. Please try again.' }, true, Severity.FATAL);
      //await refreshChart();
      return;
    }
    const value = (() => {
      if ([
        PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
        PredictionTypeEnum.VALUE_1H_UP_DOWN,
        PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
        PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
        PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
        PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
        PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
        ].includes(Number(predictionTypeEnum))) {
        if (sliderRange.yLow < Number(initialSliderRange?.yLow)) {
          return Number(initialSliderRange?.yLow);
        }
        if (sliderRange.yHigh > Number(initialSliderRange?.yHigh)) {
          return Number(initialSliderRange?.yHigh);
        }
      }
      return sliderRange.yMid
    })();

    const prediction: IPrediction = {
      stockSymbol: symbol?.symbolName || '',
      typeId: predictionTypeEnum,
      value: value,
      valueAt: NewYorkTz.roundTimeNoSeconds(valueAt),
      valueDeviation: symbolData.item.stepSize || 0,
      valueTime: symbolData.item.time || 0,
      description: description.trim() === '' ? null : description.trim(),
      stockToken: symbolDataLive.item.stockToken,
      stepSizeToken: symbolData.item.stepSizeToken,
    }
    await onSaveCallback(prediction);
    setIsSubmitting(false);
  }

  const renderTitleInstruction = (symbol: IStockSymbol, predictionTypeEnum: PredictionTypeEnum): JSX.Element => {
    return <>
      <div className="mx-3 mt-1">
        <div className="tool-head"><PredictionTypeTitle symbol={symbol} predictionTypeEnum={predictionTypeEnum} withTime={true} /></div>
        <div className="tool-body">
          {[
            PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
            PredictionTypeEnum.VALUE_1H_UP_DOWN,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
            PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
            PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
          ].includes(Number(predictionTypeEnum)) ?
            `Select whether ${symbol.symbolName} will go up or down using the slider` :
            `Select the price below by dragging the slider up and down`}
        </div>
      </div>
    </>
  }

  if (isSaveAllowed === false) {
    return (
      <div className="d-flex justify-content-center m-2 align-items-center">We are no longer accepting predictions for today. Please return after the market close to make a prediction for the next market day.</div>
    );
  }


  if (refreshLimit > 0 && refreshCounter >= refreshLimit) {
    return <div className="d-flex justify-content-center m-2 align-items-center p-3">Idle time exceeded. Please try your prediction again.</div>

  }

  const getTopRange = () => {
    // if (isPredictionType(PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE)) {
    //   return bigRangePosition.yHigh;
    // }
    return selectorStartPosPx;
  }

  const getBottomrange = () => {
    // if (isPredictionType(PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE)) {
    //   return bigRangePosition.yLow;
    // }
    return selectorStartPosPx + selectorHeightPx; 
  }

  // const getMiddleRange = () => {
  //   if (isPredictionType(PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE)) {
  //     return bigRangePosition.yMid;
  //   }
  //   return selectorStartPosPx;
  // }

  return (

    <div className="make-prediction-form">
      {symbol && <>{renderTitleInstruction(symbol, predictionTypeEnum)}</>}

      {loading &&
        <div className="d-flex justify-content-center align-items-center gap-3">
          {refreshCounter > 0 &&
            <div>Updating Price Data..</div>
          }
          <Spinner />
        </div>
      }

      {!loading && symbolData.apiState === APIState.LOADED && symbolDataLive.apiState === APIState.LOADED && 
        <>
          {chartData && loaded && <>
            <div className="d-flex flex-row prediction-tool" style={{ padding: "0 10px 0 10px" }} ref={refCard}>
              <div className="flex-grow-0 prediction-tool-stock" style={{ width: "100%" }}>
                <div className="prediction-tool-stock-detail position-absolute top-0 my-3 ms-2 p-2" style={{ zIndex: "1", backgroundColor: "rgba(0,0,0,.5)" }}>
                <>
                  <StockPrice symbolDataLive={symbolDataLive.item} />
                  {[
                    PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
                    PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
                    PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE
                  ].includes(predictionTypeEnum) && isRangeOverlapping(sliderRange, initialSliderRange as ISliderRange) && 
                    <div className="text-14 mt-2">🎯 Score is based on % of predicted move achieved after this minute's close.</div>
                  }
                </>
                </div>
                {/* <div style={{position: "absolute", color: "yellow", top: `${rangeTop + plotMarginTop}px`, width: "100%", height:"2px", zIndex:100000}}></div> */}
                {/* <div style={{position: "absolute", bottom: "0", fontSize:"10px", background: 'black', zIndex: 1000}}>
                  <pre>{JSON.stringify({
                    initialSliderRange,
                    sliderRange
                  }, null, 2)}</pre>
                </div> */}
                <div style={{ position: "relative" }}>
                  <PredictionChart chartData={chartData} />

                  {/* middle gray background for 30 min*/}
                  {predictionTypeEnum === PredictionTypeEnum.VALUE_30_MIN &&
                    <div className="prediction-tool-overlay"
                      style={{
                        position: 'absolute',
                        width: `calc(100% - ${plotMarginRight}px)`,
                        height: `${selectorHeightPx}px`, top: `${getTopRange() + plotMarginTop}px`,
                      }}>

                      <div className={`prediction-tool-overlay-range prediction-tool-overlay-range-gray`}>
                        <div className="prediction-tool-overlay-range-background" />
                      </div>

                      {
                        isRangeOverlapping(sliderRange, initialSliderRange as ISliderRange) &&
                        <div className="d-flex justify-content-end align-items-center">
                          <div className="px-2 prediction-tool-overlay-instructions shimmer" style={{ top: `-5px` }}>Please select a move:<br />up or down</div>
                        </div>
                      }
                    </div>
                  }

                  {/* middle background for 30 min-up-down */}
                  {[
                    PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
                    PredictionTypeEnum.VALUE_1H_UP_DOWN,
                    PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
                    PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
                    PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
                    PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
                    PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
                  ].includes(Number(predictionTypeEnum)) &&
                    <>
                      <div className="prediction-tool-overlay"
                        style={{
                          position: 'absolute',
                          width: `calc(100% - ${plotMarginRight}px)`,
                          height: `${selectorHeightPx}px`, top: `${selectorStartPosPx + plotMarginTop}px`,
                        }}>

                        <div className="prediction-tool-overlay"
                          style={{
                            position: 'absolute',
                            width: `calc(100% - 0px)`,
                            top: `${-(selectorStartPosPx + plotMarginTop)}px`,
                          }}
                        >
                          {
                            <div className="gray-gradient-dashed-bottom d-flex flex-row justify-content-end align-items-end" style={{
                              height: `${getTopRange() + plotMarginTop}px`,
                              transform: 'scaleY(1)'
                            }}
                            >
                              <div className="text-light-charcoal text-13 fw-bold me-1 mb-3">
                                {/* Rise Up Past {initialSliderRange?.yHigh.toFixed(2)} */}
                                Rise by {Helper.formatCurrency(stepSizeMultiplied, 2)}
                              </div>
                            </div>
                          }
                        </div>

                        <div className={`prediction-tool-overlay-range prediction-tool-overlay-range-gray`}>
                          <div className="prediction-tool-overlay-range-background" />
                        </div>

                        {
                          isRangeOverlapping(sliderRange, initialSliderRange as ISliderRange) &&
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="px-2 prediction-tool-overlay-instructions shimmer" style={{ top: `-5px` }}>Please select a move:<br />up or down</div>
                          </div>
                        }


                        <div className="gray-gradient-dashed-top d-flex flex-row justify-content-end align-items-start" style={{
                          height: `${chartHeight - ((getBottomrange()) + plotMarginTop + plotMarginBottom)}px`,
                        }}
                        >
                          <div className="text-light-charcoal text-13 fw-bold me-1 mt-3">
                            {/* Drop Below {initialSliderRange?.yLow.toFixed(2)} */}
                            Drop by {Helper.formatCurrency(stepSizeMultiplied, 2)}
                          </div>
                        </div>
                      </div>

                      {/* top overlay gradient for 30 min up/dn */}
                      {sliderRange.yLow > Number(initialSliderRange?.yHigh) &&
                        <>
                          <div className="text-12 fw-bold bg-charcoal p-2 rounded prediction-tool-price-color-green"
                            style={{
                              position: 'absolute',
                              top: `${getTopRange() + plotMarginTop - 15}px`,
                              right: 0
                            }}
                          >+{Helper.formatCurrency(stepSizeMultiplied, 2)}</div>
                          <div className="prediction-tool-overlay"
                            style={{
                              position: 'absolute',
                              width: `calc(100% - ${plotMarginRight}px)`,
                              top: `0px`,
                            }}
                          >
                            {
                              sliderRange.yLow > Number(initialSliderRange?.yHigh) &&
                              <div className="green-gradient-dashed-bottom d-flex flex-row justify-content-end align-items-end" style={{
                                height: `${getTopRange() + plotMarginTop}px`,
                                transform: 'scaleY(1)'
                              }}
                              >
                                <div className="prediction-tool-price-color-green text-13 fw-bold me-1 mb-3">
                                  {/* Rise Up Past {initialSliderRange?.yHigh.toFixed(2)} */}
                                  Rise by {Helper.formatCurrency(stepSizeMultiplied, 2)}
                                </div>
                              </div>
                            }
                          </div>
                        </>
                      }

                      {/* bottom overlay gradient for 30 min up/dn */}
                      {sliderRange.yHigh < Number(initialSliderRange?.yLow) &&
                        <>
                          <div className="text-12 fw-bold bg-charcoal p-2 rounded prediction-tool-price-color-red"
                            style={{
                              position: 'absolute',
                              top: `${selectorStartPosPx + selectorHeightPx}px`,
                              right: 0
                            }}
                          >-{Helper.formatCurrency(stepSizeMultiplied, 2)}</div>

                          <div className="prediction-tool-overlay"
                            style={{
                              position: 'absolute',
                              width: `calc(100% - ${plotMarginRight}px)`,
                              top: `${(getBottomrange()) + plotMarginTop}px`,
                            }}
                          >
                            <div className="red-gradient-dashed-top d-flex flex-row justify-content-end align-items-start" 
                            style={{
                              height: `${chartHeight - ((getBottomrange()) + plotMarginTop + plotMarginBottom)}px`,
                            }}
                            >
                              <div className="prediction-tool-price-color-red text-13 fw-bold me-1 mt-3">
                                {/* Drop Below {initialSliderRange?.yLow.toFixed(2)} */}
                                Drop by {Helper.formatCurrency(stepSizeMultiplied, 2)}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </>
                  }

                  <div ref={overlayRef} className={`prediction-tool-overlay`}
                    style={{
                      width: `calc(100% - ${plotMarginRight}px)`,
                      height: `${selectorHeightPx}px`, top: `${selectorStartPosPx + plotMarginTop}px`,
                    }}>
                    {rangedPredictions.includes(predictionTypeEnum) && <div className={`prediction-tool-overlay-range prediction-tool-overlay-range-${overlayColors?.gradient}`}>
                      <div className="prediction-tool-overlay-range-background" />
                    </div>}

                    {(isHighLow) && <div className={`prediction-tool-overlay-line prediction-tool-overlay-line-${overlayColors?.gradient}`} />}

                    {(predictionTypeEnum === PredictionTypeEnum.VALUE_30_MIN) && <div className={`prediction-tool-overlay-line prediction-tool-overlay-line-${overlayColors?.gradient}`} />}

                  </div>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center prediction-tool-wrapper">
                <div ref={containerElementRef} className="prediction-tool-box"
                  style={{ margin: `${plotMarginTop}px 0 ${plotMarginBottom}px 0` }}>
                  <div className="prediction-tool-box-color">
                    <div className="prediction-tool-select d-flex flex-row align-items-center" ref={handleRef}
                      style={{ height: `${selectorHeightPx}px`, top: `${selectorStartPosPx}px` }}>

                      {showToolTip &&
                        <div className={`prediction-tool-tip`} style={{ top: `${0 - (handleRef.current ? (handleRef.current.offsetHeight / 2) + 5 : 0)}px` }}>
                          <div className="d-flex justify-content-center align-items-center gap-1">
                            <div className="prediction-tool-tip-text shimmer">Use slider to select price range.</div>
                            <div className="prediction-tool-tip-arrow"><Icons.ArrowRight /></div>
                          </div>
                        </div>
                      }
                      <div className={`prediction-tool-arrow prediction-tool-arrow-up`}></div>
                      <div className={`prediction-tool-arrow prediction-tool-arrow-down`}></div>
                      <div className="prediction-tool-lines">
                        <div className="prediction-tool-lines-line"></div>
                        <div className="prediction-tool-lines-line"></div>
                        <div className="prediction-tool-lines-line"></div>
                      </div>

                      {rangedPredictions.includes(predictionTypeEnum) && <>
                        <div className={`prediction-tool-price prediction-tool-price-high prediction-tool-price prediction-tool-price-color-${overlayColors?.priceHigh}`}
                          style={{ width: `${plotMarginRight - 12}px`, left: `-${plotMarginRight - 9}px` }}>{sliderRange.yHigh.toFixed(2)}</div>
                        <div className={`prediction-tool-price prediction-tool-price-low prediction-tool-price prediction-tool-price-color-${overlayColors?.priceLow}`}
                          style={{ width: `${plotMarginRight - 12}px`, left: `-${plotMarginRight - 9}px` }}>{sliderRange.yLow.toFixed(2)}</div>
                      </>}

                      {isHighLow &&
                        <div className={`prediction-tool-price prediction-tool-price-exact prediction-tool-price prediction-tool-price-color-${overlayColors?.priceHigh}`}
                          style={{ width: `${plotMarginRight - 12}px`, left: `-${plotMarginRight - 9}px` }}>{sliderRange.yMid.toFixed(2)}</div>
                      }

                      {predictionTypeEnum === PredictionTypeEnum.VALUE_30_MIN && !isRangeOverlapping(sliderRange, initialSliderRange as ISliderRange) &&
                        <div className={`prediction-tool-price prediction-tool-price-exact prediction-tool-price prediction-tool-price-color-${overlayColors?.priceHigh}`}
                          style={{ width: `${plotMarginRight - 12}px`, left: `-${plotMarginRight - 9}px` }}>{sliderRange.yMid.toFixed(2)}</div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>

            {predictionTypeEnum !== PredictionTypeEnum.VALUE_AT_8PM &&
              <div className="m-2">
                <div className="form-group prediction-description">
                  <textarea
                    className="form-control"
                    id="explain-prediction"
                    onChange={(e: any) => { setDescription(e.target.value) }}
                    placeholder="Enter your explanation...(Optional)"
                    rows={2}
                    value={description}
                  />
                </div>
              </div>
            }
            <div className="mt-3 mb-2 px-3 text-center d-flex">
              <button type="submit"
                disabled={isSubmitting || (!(sliderRange.yHigh < Number(initialSliderRange?.yLow) || sliderRange.yLow > Number(initialSliderRange?.yHigh)) &&
                  [
                    PredictionTypeEnum.VALUE_30_MIN,
                    PredictionTypeEnum.VALUE_30_MIN_UP_DOWN,
                    PredictionTypeEnum.VALUE_1H_UP_DOWN,
                    PredictionTypeEnum.VALUE_CLOSE_UP_DOWN,
                    PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D,
                    PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE,
                    PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE,
                    PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE,
                  ].includes(predictionTypeEnum)
                )}
                className="btn btn-light-purple btn-curved-side btn-make-prediction flex-fill"
                onClick={onClickSavePrediction}>Make Prediction</button>
              {isSubmitting &&
                <Spinner minHeight={20} />
              }
            </div>
          </>
          }
        </>
      }

      {symbolData.error &&
        <div className="alert alert-danger m-3 text-black">{MessageHelper.translate(symbolData.error.message)}</div>
      }

      {symbolDataLive.error &&
        <div className="alert alert-danger m-3 text-black">{MessageHelper.translate(symbolDataLive.error.message)}</div>
      }


    </div>
  )
}
