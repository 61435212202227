export enum AutoTradePositionTypeEnum {
    LONG = 1,
    SHORT = 2,
}

export class AutoTradePositionTypeEnumHelper {
    static names: Map<AutoTradePositionTypeEnum, string> = new Map<AutoTradePositionTypeEnum, string>([
        [AutoTradePositionTypeEnum.LONG, 'Long'],
        [AutoTradePositionTypeEnum.SHORT, 'Short'],
    ])
}