import React, { useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { userContestApiService } from 'services/common/ContestApiService';
import { AccountContext } from 'components/common';
import { ContestHelper } from '_utils/ContestHelper';
import { ContestState } from '_constants/ContestState';
import { useModalLoginRegisterPassword } from '_hooks/useModalLoginRegisterPassword';
import { IContestOutletContext } from './IContestOutletContext';
import { RankedList } from './components/RankedList';
import { RegisterLoginButton } from './components/RegisterLoginButton';


export const ContestDetailPage: React.FunctionComponent = () => {

  const acctContext = useContext(AccountContext);

  const { contest, isJoined } = useOutletContext<IContestOutletContext>();

  const onRegisterLogin = async () => {
    if (!isJoined) await userContestApiService.joinContest(contest.id);
  }

  const loginRegisterPassword = useModalLoginRegisterPassword(onRegisterLogin, onRegisterLogin);
  const contestState = ContestHelper.getContestState(contest, new Date());

  const UserHasEntered = () => {
    return (
      // <>You're part of the contest and made a prediction</>
      <div className="contest-rankings">
        {contestState !== ContestState.CLOSED &&
          <div className="contest-anon my-3">
            <div className="contest-anon-head my-2">Rankings not yet available.</div>
            <div className="contest-anon-body my-2">To see the final results, check back {ContestHelper.getResultDateTime(contest, new Date())}</div>
          </div>
        }

        {contestState === ContestState.CLOSED &&
          <RankedList />
        }
      </div>
    );
  }

  const UserHasNotEntered = () => {
    return <>
      {/* <>You haven't entered the contest</> */}
      {/* {contestState === ContestState.OPEN &&
        <div className="contest-anon my-3">
          <div className="contest-anon-head my-2">Enter the contest & test your skills!</div>
          <div className="contest-anon-body my-2">Click the button below to enter your price prediction and sign up. Then check back to see what others are predicting.YSER</div>
        </div>
      } */}

        {contestState === ContestState.CLOSED &&
        <>
          <div className="contest-anon my-3">
            <div className="contest-anon-head my-2">This contest has ended.</div>
          </div>
          <div className="fw-bold">Results</div>
          <RankedList />
        </>}      
    </>;
  }

  const AnonymousUser = () => {
    return (
      <>
        {contestState === ContestState.OPEN &&
          <>
            <div className="contest-anon my-3">
              <div className="contest-anon-head my-2">Enter the contest & test your skills!</div>
              <div className="contest-anon-body my-2">Click the button below to sign up and make a prediction. Then check back to see what others are predicting.</div>
            </div>
            <RegisterLoginButton onClick={() => loginRegisterPassword.register.show(true)} />
          </>
        }

        {contestState === ContestState.CLOSED &&
          <div className="contest-anon my-3">
            <div className="contest-anon-head my-2">This contest has ended.</div>
          </div>
        }

        {contestState === ContestState.PRE_OPEN &&
          <>
            <div className="contest-anon my-3">
              <div className="contest-anon-head my-2">Enter the contest & test your skills!</div>
              <div className="contest-anon-body my-2">Click the button below to sign up and enter the contest. Submit your prediction when the contest starts in {ContestHelper.getTimeUntilOpen(contest, new Date())}, then check back to see what others are predicting.</div>
            </div>
            <RegisterLoginButton onClick={() => loginRegisterPassword.register.show(true)} />
          </>
        }
      </>
    );
  }


  return <>
    {contest &&
      <>
        <>{loginRegisterPassword.render()}</>

        {!acctContext?.isLoggedIn() && <AnonymousUser />}

        {acctContext?.isLoggedIn() && isJoined && <UserHasEntered />}

        {acctContext?.isLoggedIn() && !isJoined && <UserHasNotEntered />}
      </>
    }
  </>;
}