import { StatsDateDropdownItemType, StatsDateFilterEnum } from "components/user/stats/StatsDateDropDownFilter";
import { DateEx, NewYorkTz, PredictionTypeEnum, StockHelper } from "predictagram-lib";
import { IUserStatsPredictionTypeResponse } from "services/UserPredictionApiService";

    // group the items
export interface ITotal {
  totalPredictions: number,
  avgScoreWeighted: number
}

export const groupMap: Map<PredictionTypeEnum, number> = new Map<PredictionTypeEnum, number>([
  [PredictionTypeEnum.VALUE_30_MIN_UP_DOWN, 1],
  [PredictionTypeEnum.VALUE_30_MIN_UP_DOWN_DISTANCE, 1],
  [PredictionTypeEnum.VALUE_1H_UP_DOWN, 2],
  [PredictionTypeEnum.VALUE_1H_UP_DOWN_DISTANCE, 2],
  [PredictionTypeEnum.VALUE_CLOSE_UP_DOWN, 3],
  [PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_DISTANCE, 3],
  [PredictionTypeEnum.VALUE_CLOSE_UP_DOWN_3D, 4],
  [PredictionTypeEnum.VALUE_AT, 5],
  [PredictionTypeEnum.VALUE_30_MIN, 5],
  [PredictionTypeEnum.VALUE_CLOSE, 6],
  [PredictionTypeEnum.VALUE_HIGH, 7],
  [PredictionTypeEnum.VALUE_LOW, 8],      
]);

export const groupMapNames: Map<number, string> = new Map<number, string>([
  [1, "30-minute Price Move"],
  [2, "1-Hour Price Move"],
  [3, "Closing Price Move"],
  [4, "Multi-day Price Move"],
  [5, "Intraday Price Range"],
  [6, "Closing Price Range"],
  [7, "Closing High"],
  [8, "Closing Low"],
]);

export interface IGroupedPredictionTypeTotals {
  groupId: number, 
  groupName: string | undefined,
  totalPredictions: number,
  avgScoreWeighted: number
}

export class UserStatsHelper {
  static getDateRange(dateFilter: StatsDateFilterEnum, date: Date = new Date()): { startDate?: DateEx | undefined, endDate?: DateEx | undefined } {

    if (dateFilter === StatsDateFilterEnum.ALL_TIME) {
      return {
        startDate: undefined,
        endDate: undefined
      }
    }

    const startDate = new Date(date.getTime());
    const numdays = this.getDropdownItems(new Date()).get(dateFilter)?.value;

    if (typeof numdays === "number") {

      // if (dateFilter === StatsDateFilterEnum.LAST_5) {
      //   // get the last 5 trading days, inclusive
      //   const stockDay = StockHelper.getIfIsTradingDay(date.getTime() / 1000);
      //   if (stockDay !== null) {
      //     const daysAgo = stockDay.prevNext(-5);
      //     return {
      //       startDate: StockHelper.workingHours(new Date(daysAgo.now())).start,
      //       endDate: undefined
      //     }

      //   }
      // }

      // set the date to new date
      startDate.setDate(startDate.getDate() + numdays);

      // if yesterday is not trading day, get the previous trading date
      if (dateFilter === StatsDateFilterEnum.YESTERDAY) {
        // check if market open yesterday 
        if (!StockHelper.isTradingDay(startDate)) {
          const previousTradingDay = StockHelper.findPreviousTradingDay(startDate);
          return {
            startDate: StockHelper.workingHours(previousTradingDay).start,
            endDate: StockHelper.workingHours(previousTradingDay).end
          }
        }
      }


      return {
        startDate: StockHelper.workingHours(startDate).start,
        endDate: undefined
      };
    }

    startDate.setFullYear(startDate.getFullYear() - 1);
    return {
      startDate: StockHelper.workingHours(startDate).start,
      endDate: undefined,
    }

  }

  static groupedPredictionTypes(items: IUserStatsPredictionTypeResponse[]): IGroupedPredictionTypeTotals[] {
    /*
        VALUE_AT_8PM = 20 -- excluded from grouping
    */
    
    const reducer = (result: Map<number, ITotal >, item: IUserStatsPredictionTypeResponse) => {
      const key = groupMap.get(item.predictionTypeId);
      if (key) {
        const t = result.get(key);
        if (t) {
          t.totalPredictions += item.predictionsCompleted;
          t.avgScoreWeighted += item.predictionsCompleted * item.scoreAvg;
        } else {
          result.set(key, {
            totalPredictions: item.predictionsCompleted,
            avgScoreWeighted: item.scoreAvg * item.predictionsCompleted,
          })
        }
      }
      return result;
    }
    const reduced = items.reduce(reducer, new Map<number, ITotal>());
    // weighted average calculation
    const weighted = Array.from(reduced).map( (i) => {
      const [key, total]: [number, ITotal] = i;

      return {
        groupId: key,
        groupName: groupMapNames.get(key),
        totalPredictions: total.totalPredictions,
        avgScoreWeighted: total.avgScoreWeighted / total.totalPredictions
      }
    })

    return weighted.sort((a, b) => a.groupId - b.groupId);
  }


  static getDropdownItems (currentDate: Date = new Date()) {
    const itemsMap = new Map<StatsDateFilterEnum, StatsDateDropdownItemType>([
      [StatsDateFilterEnum.ALL_TIME, { key: StatsDateFilterEnum.ALL_TIME, value: null, label: 'All Time' }],
      [StatsDateFilterEnum.LAST_30, { key: StatsDateFilterEnum.LAST_30, value: -30, label: 'Last 30 Days' }],
      [StatsDateFilterEnum.LAST_7, { key: StatsDateFilterEnum.LAST_7, value: -7, label: 'Last 7 Days' }],
    ]);

    if (StockHelper.isTradingDay(currentDate)) {
      // is yesterday a trading day
      const yesterday = NewYorkTz.getDateMidnight(new Date(currentDate.getTime()));
      yesterday.setDate(currentDate.getDate() - 1);
      if (StockHelper.isTradingDay(yesterday)) {
        itemsMap.set(StatsDateFilterEnum.YESTERDAY, { key: StatsDateFilterEnum.YESTERDAY, value: -1, label: 'Yesterday' });
      } else {
        itemsMap.set(StatsDateFilterEnum.YESTERDAY, { key: StatsDateFilterEnum.YESTERDAY, value: -1, label: this.getPreviousTradingDayLabel(currentDate) });
      }

      itemsMap.set(StatsDateFilterEnum.TODAY, { key: StatsDateFilterEnum.TODAY, value: 0, label: 'Today'});

    } else {
      itemsMap.set(StatsDateFilterEnum.YESTERDAY, { key: StatsDateFilterEnum.YESTERDAY, value: -1, label: this.getPreviousTradingDayLabel(currentDate) });
    }

    return itemsMap;
    
  }

  static getPreviousTradingDayLabel = (currentDate: Date) => {
    const prevDay = StockHelper.findPreviousTradingDay(currentDate);
    const label = NewYorkTz.getWeekday(prevDay);
    return label;
  }
}