import React, { useContext } from 'react';
import { Footer } from 'components/common';
import { FooterSignupLogin } from 'components/public/layout/FooterSignupLogin';
import { ShortRegisterForm } from 'components/user/account/ShortRegisterForm';
import { useNavigate } from 'react-router-dom';
import { Url } from '_constants';
import { MessengerContext, Severity } from 'components/common/messenger';
import { InfluencerCarousel } from '../InfluencerCarousel';
import { HashLink as Link } from 'react-router-hash-link';


const LandingPageText = (): JSX.Element => {
  return (
    <div className="landing-page-text">
      <div className="landing-page-title"><span className="landing-page-highlight">Free</span> access to the best <span className="landing-page-highlight">real-time</span> stock market predictions.</div>
      <div className="landing-page-subtitle">Our growing community makes daily predictions for $SPY, $QQQ, $AAPL, $TSLA and other top symbols.</div>
    </div>
  );
}

export const LandingPageV4: React.FunctionComponent<{ hasLoginSignup?: boolean, hasDisclaimer?: boolean, hasCarousel?: boolean }> = ({ hasLoginSignup = false, hasDisclaimer = false, hasCarousel=false }) => {

  const navigate = useNavigate();
  const msgrContext = useContext(MessengerContext);
  const onRegister = () => {
    msgrContext.setMessage({ body: 'Thank you for registering! You will now be redirected to the website.' }, true, Severity.NORMAL)
    window.setTimeout(() => {
      navigate(Url.DEFAULT_HOME);
    }, 3_000)
  }

  const renderFooter = (): JSX.Element => {
    if (hasDisclaimer && hasLoginSignup) {
      return <FooterSignupLogin />
    }
    if (hasDisclaimer && !hasLoginSignup) {
      return <Footer />
    }
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="text-smaller text-lighter-charcoal mt-3">&copy; {new Date().getFullYear()} Predictagram.com</div>
      </div>
    );
  }

  return (
    <div className="landing-page-v4">

      <div className="container landing-v4 d-flex flex-column justify-content-center align-items-center mt-0 p-0">

        <div className="landing-v4-content d-flex flex-column justify-content-center align-items-start">
          <div className="landing-v4-logo">
            <img src="/images/logo-light.png" alt="Predictagram Stock Predictions" />
          </div>

          <div className="landing-v4-text">
            <LandingPageText />
          </div>

          <div className="landing-v4-section">
            <Link to="#signup">
            <button type="button" className="landing-v4-btn-signup">Sign up</button>
            </Link>
          </div>

          {hasCarousel &&
          <div className="landing-v4-section pb-3">

            <div className="landing-v4-section-subtitle">
              Featuring <span className="fw-bold">exclusive daily predictions</span> from these top finance and trading voices and more.
            </div>
            <InfluencerCarousel />
          </div>
          }


          <div className="landing-v4-section bg-off-white">
            <div className="landing-v4-section-title">Verified Prediction Accuracy</div>
            <div className="landing-v4-section-subtitle">
              Everyone's prediction history is tracked and visible, and users are given an average accuracy score for full transparency.
            </div>
            <div className="landing-v4-section-image">
              <div className="d-none d-md-block">
                <img src="/images/know-who.png" alt="Know who's predicting" />
              </div>
              <div className="d-block d-sm-none">
              <img src="/images/know-who-mobile.png" alt="Know who's predicting" />
              </div>
            </div>
          </div>

          <div className="landing-v4-section">
            <div className="landing-v4-section-title">Never Miss Out</div>
            <div className="landing-v4-section-subtitle">
              Follow the predictors you trust, and get notified whenever they make a prediction.
            </div>
            <div className="landing-v4-section-image">
              <div className="d-none d-md-block">
              <img src="/images/never-miss.png" alt="Never Miss Out" />
              </div>
              <div className="d-block d-sm-none">
              <img src="/images/never-miss-mobile.png" alt="Never Miss Out" />
              </div>
            </div>
          </div>

          <div className="landing-v4-section bg-off-white pb-5">
            <div className="landing-v4-section-title">Claim your username and see what our top predictors are saying right now!</div>
            <div className="landing-v4-section-subtitle">
              We will never share your email.
            </div>

            <div className="landing-v4-form py-3">
              <section id="signup">
              <ShortRegisterForm  darkMode={false} skipFg={true} onLoginCallback={() => { }} onRegisterCallback={onRegister} buttonLabel='Sign up' hasLogin={false} />
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="landing-v4-footer">
        {renderFooter()}
      </div>


    </div >
  );
}