import { IUserTicketResponse } from 'interfaces/IUserTicket';
import { userAuthenticationService } from './AuthenticationService';
import { AbortableHttpService } from './AbortableHttpService';
import { config } from 'config';

export interface IUserStreakStatResponse {
  points: number,
  days: number,
  multiplierPct: number,
}

export interface IUserAchievementUserStatSearchFilter {
  minExperiencePoints?: number,
  maxExperiencePoints?: number,
  userId?: number,
  startTime?: number,
  endTime?: number
}

export interface IUserAchievementUserStatResponse {
  userId: number,
  points: number,
  predictions: number,
  avatarUrl: string | null,
  username: string,
}

export class UserAchievementService {

  constructor(
    protected apiBaseUrl: string,
    protected httpService: AbortableHttpService
  ) { }

  /**
   * get the logged-in user's tickets
   * @returns IUserTicketResponse[]
   */
  async getTickets(abortSignal?: AbortSignal): Promise<IUserTicketResponse[]> {
    const url = `${this.apiBaseUrl}/management/achievement/tickets`;
    return await this.httpService.httpPost<IUserTicketResponse[]>(url, {}, abortSignal);
  }

  /**
   * get the logged-in user's streaks
   * @param abortSignal 
   * @returns 
   */
  async getStreakStats(abortSignal?: AbortSignal): Promise<IUserStreakStatResponse> {
    const url = `${this.apiBaseUrl}/management/achievement/streak-stats`;
    return await this.httpService.httpPost<IUserStreakStatResponse>(url, {}, abortSignal);
  }

  /**
   * get list of users XP stats
   * @param filters 
   * @param abortSignal 
   * @returns 
   */
  async getUserStats(filters: IUserAchievementUserStatSearchFilter, abortSignal?: AbortSignal): Promise<IUserAchievementUserStatResponse[]> {
    const url = `${this.apiBaseUrl}/achievement/streak/users-stats`;
    return await this.httpService.httpPost<IUserAchievementUserStatResponse[]>(url, {filters}, abortSignal);
  }

}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const httpService = new AbortableHttpService(userAuthenticationService);
export const userAchievementService = new UserAchievementService(apiBaseUrl, httpService)