import React from 'react'
import { Form, Formik } from 'formik';
import { IPredictionAdminSearchOptions } from 'services/AdminApiService';
import ReactDatePicker from 'react-datepicker';
import { FieldWithError } from 'components/common';

export const AdminRegistrationsFilter: React.FunctionComponent<{ 
  initialValues: IPredictionAdminSearchOptions,
  onClick: (search:IPredictionAdminSearchOptions)=>void,
 }> = ({ initialValues, onClick }) => {

  const onSubmit = (data: any, actions: any) => {
    onClick({
      ...data,
      usernameLike: /^\d+$/.test(data['usernameLike']) ? undefined : data['usernameLike'],
      userId: /^\d+$/.test(data['usernameLike']) ? parseInt(data['usernameLike']): undefined,
    });
  }

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue, isSubmitting }) => {
          return <Form>
            <div className="d-flex justify-content-start align-items-center gap-3 my-3">

              <div className="d-flex flex-column">
                <div className="fw-bold text-14">User Name or ID</div>
                <FieldWithError fieldName='usernameLike'
                  errors={errors}
                  touched={touched}
                  placeholder="User Id or Name" />
              </div>

              

              <div className="d-flex flex-column">
                <span className="fw-bold">Start Date</span>
                <ReactDatePicker
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("startTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.startTime * 1000)}
                />
              </div>

              <div className="d-flex flex-column">
                <span className="fw-bold">End Date</span>
                <ReactDatePicker
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("endTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.endTime * 1000)}
                />
              </div>

              <div className="align-self-end">
                <button type="submit" className="btn btn-primary p-2" /*disabled={isSubmitting}*/>Update Search</button>
              </div>


            </div>

          </Form>
        }}


      </Formik>


    </div>
  )
}
