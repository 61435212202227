import React from 'react'
import { Emoji } from 'components/common/Emoji';
import { Helper } from '_utils';
import { ProgressBar } from './ProgressBar';
import { UrlHelper } from '_utils/UrlHelper';
import { useNavigate } from 'react-router-dom';

export const TopPerformerSymbol:React.FunctionComponent<{symbol: string, accuracy: number, predictions: number}> = ({symbol, accuracy, predictions}) => {
  const accuracyPct = Math.round(accuracy * 100);
  const navigate = useNavigate();

  return (
  <div className="user-stats-top-perfomer">
    <div className="d-flex justify-content-start align-items-center gap-3">
      <div role="button" className="fw-bold text-18" onClick={()=>navigate(UrlHelper.getSymbolUrl(symbol))}>{symbol}</div>
      <div className="d-flex justify-content-start align-items-center">
        <div className="fw-bold text-18"><Emoji symbol="🎯" text={""} label="accuracy" /></div>
        <div className="fw-bold text-16">{accuracyPct}%</div>
      </div>
      <div>{predictions} {Helper.pluralize('prediction', predictions)}</div>
    </div>
    <div style={{width: "100%", height: "0.625rem"}}>
          <ProgressBar progress={accuracyPct} />
      </div>
  </div>
 ); 
}
