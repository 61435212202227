import React from 'react'
import { Footer, MintGreenLogo } from './DiscountPromoPage'
import { SignupForm } from './SignupForm'
import { ScrollToTop } from 'components/common'
import { Helmet } from 'react-helmet';
import { config } from 'config';

export const DiscountPromoSignupPage: React.FunctionComponent = () => {

  const symbols = [
    'AAPL',
    'AMD',
    'AMZN',
    'COIN',
    'DIS',
    'GOOGL',
    'IWM',
    'META',
    'MSFT',
    'NFLX',
    'NVDA',
    'QQQ',
    'SPY',
    'TNA',
    'TSLA',
  ]

  const SupportedSymbols = () => {
    return (
      <div className="bg-darkest-gray w-100 p-5">
        <div className="d-flex justify-content-center align-items-center">

          <div className="discount-promo-supported-symbols">
            <div className="text-22 fw-bold text-center">*Supported Symbols</div>
            <div className="text-center">(This list will continue to grow as we develop more algorithms)</div>

            <div className="d-flex flex-row flex-wrap mt-3">
              {symbols.map((symbol) => (
                <div className="discount-promo-supported-symbols-symbol m-2 text-center" key={`key-${symbol}`}><div className="pill bg-black text-white p-2">{symbol}</div></div>
              ))}

            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>

      <Helmet>
        <title>Predictagram: Predictagram: Pro Signals Package - Signup Page</title>
        <meta property="og:title" content={`Predictagram: Pro Signals Package - Signup Page`} />
        <meta property="og:description" content={`Sign up page to access to our Top 30 proprietary trade signal algorithms.`} />
        <meta property="og:url" content={`${config.wwwDomain}/pro-signals-package/signup`} />
      </Helmet>

      <ScrollToTop />
      <div className="discount-promo-signup">

        <div className="d-flex flex-column justify-content-center align-items-center">

          <div className="my-3">
            <MintGreenLogo />
          </div>

          <div className="discount-promo-headline">Claim your <span className="text-mint-green">Pro Package</span> Discount</div>

          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="discount-promo-headline my-3">First 500 Signups Save 50%!</div>

            <div className="rounded bg-white text-black text-center p-3 w-100">
              <div className="fw-bold text-28">All Symbols*</div>
              <div className="text-22"><span className="text-decoration-line-through">$199.99</span> $99.99/mo</div>
            </div>
          </div>

          <div className="discount-promo-signup-lock text-center">
            <div className="text-21 my-3">Lock in our introductory price of <span className="fw-bold">$99/month</span> for unlimited signals for all symbols* for one year only if you reserve now. </div>
            <div className="m-3">
              Enter your email address here to reserve and be notified upon launch, expected this summer.
            </div>
          </div>

          <div className="my-3">
            <SignupForm />
          </div>

          <div className="text-14 fst-italic opacity-60 my-3">No credit card required at this time.</div>

          <SupportedSymbols />

          <Footer />
        </div>

      </div>
    </>
  )
}
