import { NewYorkTz } from 'predictagram-lib'
import React from 'react'

export const NewYorkDate:React.FunctionComponent<{dateInMs: number}> = ({dateInMs}) => {
  const date = new Date(dateInMs);
  const dt = NewYorkTz.mdyhms(date);

  const month = dt.month.padStart(2, '0');
  const day = dt.day.padStart(2, '0');
  const year = dt.year;
  const hour = dt.hour23;
  const minute = dt.minute.padStart(2, '0');
  const seconds = dt.seconds.padStart(2, '0');

  const str = `${year}/${month}/${day} ${hour}:${minute}:${seconds}`;
  return (
    <>{str}</>
  )
}
