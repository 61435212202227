import {
  AccessorFnColumnDef,
  AccessorKeyColumnDef,
  ColumnHelper,
  createColumnHelper,
  DeepValue,
  Table
} from "@tanstack/react-table";
import {ColumnDefTemplate, RowData} from "@tanstack/table-core/src/types";
import {CellContext} from "@tanstack/table-core/src/core/cell";
import {StringOrTemplateHeader} from "@tanstack/table-core/build/lib/types";
import {HeaderContext} from "@tanstack/table-core/src/core/headers";
import {MathEx} from "predictagram-lib";

type Params<T> = {
  header?: StringOrTemplateHeader<T, any>,
  cell?:ColumnDefTemplate<CellContext<T, any>>,
  sortingFn?: any,
  footer?: ColumnDefTemplate<HeaderContext<T, any>>
  meta?: {classNames:string[]},
}

class ColumnBuilder<T extends RowData> {
  protected column:AccessorFnColumnDef<T, DeepValue<T, any>> | AccessorKeyColumnDef<T, DeepValue<T, any>>;

  constructor(protected helper: ColumnHelper<T>, protected name: string, protected paramsIn: Params<T> = {} as any) {
    this.column = helper.accessor(name as any, Object.assign({
      header: () => name,
      // id: name,
      cell: function (info:any) { return info.getValue()},
    }, paramsIn) as any);
  }

  number(params?:{round?:number, fixed?:number}) {
    if (!this.column.meta) {
      this.column.meta = {classNames: []};
    }
    if (params?.round || params?.fixed) {
      this.column.cell = (v)=>{
        let val = v.getValue();
        if (params?.round) {
          val = MathEx.round(val, params.round as number) as any;
        }
        if (params?.fixed) {
          val = val.toFixed(params.fixed);
        }
        return val;
      }
    }
    this.column.meta.classNames.push('number');
    return this;
  }
  footerSum(params?:{parseFn?:(v:any)=>number, round?:number, fixed?:number}) {
    this.column.footer = ({ table }) => {
      let result = this.sum(table as any, params?.parseFn);
      result = params?.round ? MathEx.round(result, params?.round) : result;
      result = params?.fixed ? result.toFixed(params.fixed) : result as any;
      return result;
    }
    return this;
  }

  numberFooterSum(params?:{parseFn?:(v:any)=>number, round?:number, fixed?:number}) {
    this.number(params);
    this.footerSum(params);
    return this;
  }

  public build() {
    return this.column;
  }

  protected sum(table: Table<T>, parse?:(v:any)=>any) {
    if (!parse) {
      parse = (v)=>v;
    }
    return table.getFilteredRowModel().rows.reduce((total, row) => total + (parse as any)(row.getValue(this.name)), 0);
  }


}

export class ReactTableHelper {
  public static createHelper<T>() {
    const helper = createColumnHelper<T>();

    return {
      accessor: helper.accessor,
      // columnNumber: (name: string, params?:Params<T>)=>{
      //
      //   return helper.accessor(name as any, Object.assign({
      //     header: () => name,
      //     cell: function (info:any) { return info.getValue()},
      //     meta: {classNames:['number']},
      //     // @ts-ignore
      //   }, params) as any)
      // },
      columnBuilder: (name: string, params?:Params<T>)=>new ColumnBuilder(helper, name, params),
      // columnMoney: (name: string, params?:Params<T>)=>{
      //   return helper.accessor(name as any, Object.assign({
      //     header: () => params?.header||name,
      //     cell: function (info:any) { return '$'+info.getValue()},
      //     meta: {classNames:['money']},
      //   }, params) as any);
      // },


    }
  }
}
