import React, { useEffect, useState } from "react";
import { IUserStatistic } from "interfaces/IUserStatistic";
import { Spinner } from "react-bootstrap";
import { useUserStats } from "_hooks/useUserStats";
import { UserStatistic } from "components/user/contest/components/UserStatistic";
import { userPredictionApiService } from "services/UserPredictionApiService";
import { DateFilterEnum, DateFilters, IDateFilterTimeRange } from "./components/DateFilters";
import { NewYorkTz as tz } from "predictagram-lib";
import { APIState } from "_constants/APIState";
import { StatsHelper } from "./StatsHelper";
import { Helmet } from 'react-helmet';
import { config } from 'config';
import { Url } from "_constants";


interface IFilterConfig { minScore: number, minPredictions?: number, days: number };

export const filterConfigMap: Map<DateFilterEnum, IFilterConfig> = new Map<DateFilterEnum, IFilterConfig>([
  [DateFilterEnum.SEVEN_DAYS, { minScore: 0.40, days: 7 }],
  [DateFilterEnum.THIRTY_DAYS, { minScore: 0.40, days: 30 }],
  [DateFilterEnum.TODAY, { minScore: 0.50, days: 1 }],
  [DateFilterEnum.YESTERDAY, { minScore: 0.50, days: 1 }],
]);


export const LeaderboardUsersPage: React.FunctionComponent = () => {

  const initialFilterConfig = filterConfigMap.get(DateFilterEnum.SEVEN_DAYS);

  const [dateFilter, setDateFilter] = useState<IFilterConfig>(initialFilterConfig as any);
  const [stats, setStats] = useState<IUserStatistic[]>([]);

  // show stats only after it's sorted, api result is raw and is not yet ready for rendering
  const [isSorted, setIsSorted] = useState<boolean>(false);

  const initialStartTime = () => {
    const today = tz.getDateMidnight(new Date()).getTime();
    const dayInMs = 24 * 60 * 60 * 1000;
    const sevenDaysAgo = today - (dayInMs * 7);
    return sevenDaysAgo;
  }
  
  const { items: apiStats, apiState, error, setOptions: setSearchOptions } = useUserStats(userPredictionApiService, {
    startTime: initialStartTime() / 1000,
    minScore: initialFilterConfig?.minScore,
    minPredictions: initialFilterConfig?.minPredictions
  });

  useEffect(() => {
    setStats(apiStats.sort((a: IUserStatistic, b: IUserStatistic) => {
      const daysRange = dateFilter.days;
      return StatsHelper.usersLeadBoardSortId(b as any, daysRange) - StatsHelper.usersLeadBoardSortId(a as any, daysRange);
    }).slice(0, 30));
    setIsSorted(true);
  }, [apiStats, dateFilter.days]);

  const onClickCallback = (dateFilterEnum: DateFilterEnum, timeRange: IDateFilterTimeRange) => {
    setIsSorted(false);
    const fc = filterConfigMap.get(dateFilterEnum);
    setDateFilter(fc as any);
    setSearchOptions({
      startTime: timeRange.startTime / 1000,
      endTime: timeRange.endTime ? timeRange.endTime / 1000 : undefined,
      minPredictions: fc?.minPredictions,
      minScore: fc?.minScore
    })

  }

  return (
    <>

      <Helmet>
        <title>Predictagram: Users Leaderboard</title>
        <meta property="og:title" content={`Predictagram: Users Leaderboard`} />
        <meta property="og:description" content={`See who's leading the prediction on our leaderboard!`} />
        <meta property="og:url" content={`${config.wwwDomain}/${Url.LEADERBOARD_USERS}`} />
      </Helmet>

      <div className="ranked-list">

        <div className="my-3">
          <DateFilters onClick={onClickCallback} />
        </div>

        <div className="team-roster mx-3 my-2">
          {(apiState === APIState.LOADING || (apiState === APIState.LOADED && !isSorted)) && <Spinner />}

          {apiState === APIState.LOADED && isSorted && stats.length === 0 && <div className="my-5 d-flex justify-content-center align-items-center">No rankings available.</div>}

          {apiState === APIState.LOADED && isSorted && stats.length > 0 && stats.map((m: IUserStatistic, i: number) => <div className="row my-1 py-2 team-roster-item" key={`roster-key-${i}`}>
            <UserStatistic statistic={m} index={i + 1} isDisplayPredictions={true} />
          </div>)
          }

          {error && <div>{error.message}</div>}
        </div>
      </div>
    </>
  );
}
