import React from 'react';

export const InfluencerCarousel: React.FunctionComponent = () => {

  return (
    <div className="inf-carousel">
      <div className="inf-carousel-container">
        <div className="inf-carousel-container-images d-flex flex-row">
        <img src="/images/lp/v5/inf-group.png" alt="Top Predictors" />
        <img src="/images/lp/v5/inf-group.png" alt="Top Predictors" />
        </div>
      </div>
    </div>
  );
}
