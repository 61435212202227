import React, {useRef} from 'react'
import { PredictionTypesDropdown } from "components/common/PredictionsDropDown";
import { Form, Formik } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { SymbolsDropdown } from 'components/common/SymbolsDropdown';
import { PredictionTypeEnum } from 'predictagram-lib';
import {StockHelper} from "../../../_utils/stock.helper";

export interface ISearchOptions {
  predictionType: PredictionTypeEnum|null,
  symbolName: string|null,
  startTime: number,
  endTime: number,
  isDownload: boolean,
}

export const AdminPredictionFilter: React.FunctionComponent<{ 
  // initialValues: ISearchOptions,
  onSubmit: (search:ISearchOptions)=>void,
 }> = ({ /*initialValues,*/ onSubmit }) => {
  const todayStart = StockHelper.workingHours().start.getTimeSec();
  const todayEnd = StockHelper.workingHours().end.getTimeSec();

  const initialValues = {
    startTime: todayStart,
    endTime: todayEnd,
  }

  const isDownload = useRef(false);
  const filterData = (data:any):ISearchOptions=>{
    return {
      symbolName: data.stockSymbol==='0' ? null : data.stockSymbol,
      predictionType: Number(data.predictionType) === 0 ? null : data.predictionType,
      isDownload: isDownload.current,
      startTime: data.startTime,
      endTime: data.endTime,
    }
    // return {
    //   ...data,
    //   symbolNames: data.stockSymbol ? data.stockSymbol === "0" ? undefined : [data.stockSymbol] : undefined,
    //   predictionType: Number(data.predictionType) === 0 ? null : data.predictionType,
    //   isDownload: isDownload.current,
    // };
  }
  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={(d)=>onSubmit(filterData(d))}>
        {({ values, touched, errors, setFieldValue, isSubmitting }) => {
          return <Form>
            <div className="d-flex justify-content-between align-items-center gap-3 my-3">
              <PredictionTypesDropdown name="predictionType" errors={errors} touched={touched} />
              <SymbolsDropdown name="stockSymbol" errors={errors} touched={touched} defaultDescription="All"/>

              <div>
                <span>Start Date</span>
                <ReactDatePicker
                  showTimeSelect
                  dateFormat="MM/dd/yyyy hh:mm aa"
                  onChange={(date: Date) => {
                    setFieldValue("startTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.startTime * 1000)}
                />
              </div>

              <div>
                <span>End Date</span>
                <ReactDatePicker
                  showTimeSelect
                  dateFormat="MM/dd/yyyy hh:mm aa"
                  onChange={(date: Date) => {
                    setFieldValue("endTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.endTime * 1000)}
                />
              </div>

              <div className="align-self-center">
                <button type="submit"  onClick={()=>isDownload.current=false} className="btn btn-primary p-2" /*disabled={isSubmitting}*/>Search</button>
              </div>

              <div className="align-self-center">
                <div className="align-self-center">
                  <button type="submit" onClick={()=>isDownload.current=true} className="btn btn-primary p-2" /*disabled={isSubmitting}*/>Download To Excel</button>
                </div>
              </div>

            </div>

          </Form>
        }}


      </Formik>


    </div>
  )
}
