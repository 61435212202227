import React from 'react';
import { Fire } from 'components/common/Icons';
import { IUserStreakStatResponse } from 'services/UserAchievementService';
import { Badge } from './Badge';


export const StreakBadge: React.FunctionComponent<{ streakStat: IUserStreakStatResponse, onClick: ()=>void }> = ({ streakStat, onClick }) => {

  return (
    <div className="badge-black-hover rounded">
      <Badge footerText={`x${streakStat.days}`} onClick={onClick} role="button" >
        <div className="streak-icon" style={{ position: "relative", width: "1.125rem" }} >
          <Fire width={18} height={21} />
        </div>
      </Badge>
    </div>
  );

}
