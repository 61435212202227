import React from 'react'
import { DropdownFilter, IDropdownFilterItem } from 'components/common/DropDownFilter/DropdownFilter'
import { UserStatsHelper } from '_utils/UserStatsHelper';


export type StatsDateDropdownItemType = IDropdownFilterItem<StatsDateFilterEnum, number | null>;

export enum StatsDateFilterEnum {
  ALL_TIME = 1,
  LAST_30 = 2,
  LAST_7 = 3,
  YESTERDAY = 4,
  TODAY = 5
}

export const statsDateTypeDropDownItemsMap: Map<StatsDateFilterEnum, StatsDateDropdownItemType> = new Map<StatsDateFilterEnum, StatsDateDropdownItemType>();

// export const statsDateDropDownItems: StatsDateDropdownItemType[] = Array.from(statsDateTypeDropDownItemsMap).map((item) => {
//   return item[1]
// })

// export const getUserTypeDropDownItem = (userTypeFilter: StatsDateFilterEnum) => {
//   return statsDateTypeDropDownItemsMap.get(userTypeFilter) || statsDateDropDownItems[0];
// }

interface IProps {
  initialValue: StatsDateFilterEnum
  setValue: (value: StatsDateFilterEnum) => void,
  className?: string,
}

export const StatsDateDropDownFilter: React.FunctionComponent<IProps> = ({ initialValue, setValue, className }) => {

  const dditems = UserStatsHelper.getDropdownItems(new Date());
  dditems.forEach((value, key) => {
    statsDateTypeDropDownItemsMap.set(key, value);
  });
  
  const items = Array.from(statsDateTypeDropDownItemsMap).map((item) => {
      return item[1]
    })
    
  const _setValue = (value: StatsDateFilterEnum) => {
    setValue(value);
  }

  return (
    <DropdownFilter<StatsDateFilterEnum, number | null> items={items} initialSelectedKey={initialValue} onSelect={(item) => _setValue(item)} className={className} />
  )
}
