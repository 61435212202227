import React, { useContext } from 'react'
import { APIState } from '_constants/APIState';
import { useAccountSettings } from '_hooks/useAccountSettings';
import { Spinner } from 'components/common/Spinner';
import { MessengerContext } from 'components/common/messenger';



export const JoinWaitListButton: React.FunctionComponent<{ color?: "white" | "platinum" }> = ({ color = "white" }) => {

  const { settings: userSettings, apiState, updateSettings, settingsError } = useAccountSettings();
  const msgrContext = useContext(MessengerContext);

  const isWaitlisted = !!userSettings.tradegramWaitingList;

  const onClick = async () => {
    if (isWaitlisted) {
      msgrContext.setMessage({ body: "You are already on the waiting list" });
      return;
    }
    try {
      await updateSettings({ tradegramWaitingList: true } as unknown);
      msgrContext.setMessage({ body: <div className="mx-3">You have been added to the waiting list. We will email you when you have been granted access.</div> });
    } catch (error: any) {
      msgrContext.setMessage({ body: "Sorry, could not add to waiting list." });
    }
  }

  if (apiState === APIState.LOADING) {
    return <Spinner />
  }

  if (isWaitlisted) {
    return (
      <div>
        <div className="fw-bold">You are on the waitlist!</div>
        <div className="text-14">It may take up to a week for Closed Beta access to be granted.</div>
      </div>
    );
  }

  if (color === "platinum") {
    return (
      <div role="button" className="bg-gradient-platinum px-4 py-2 text-black text-12 fw-bold pill d-inline text-center" onClick={onClick}>Request Access</div>
    );
  }

  return (
    <div role="button" className="pill bg-white fw-bold text-black d-inline p-2 px-4 text-14" onClick={onClick}>Request Access</div>
  )

}

