import React, { useContext } from 'react';
import { config } from 'config';
import { Url } from '_constants';
import { MessengerContext, Severity } from './messenger';
import { RWebShare } from "react-web-share";
import { AccountContext } from './AccountContext';
import { SearchParams } from '_constants/SearchParams';
import { IContest } from 'interfaces/IContest';
import { ShareBase } from './ShareBase';

export const ShareContest: React.FunctionComponent<{ contest: IContest }> = ({ contest }) => {

  const msgrContext = useContext(MessengerContext);
  const acctContext = useContext(AccountContext);
  const baseUrlPath = `${config.wwwDomain}${Url.USER_CONTEST.replace(':contestId', contest.id ? contest.id.toString() : '')}`;
  const qstrings: string[] = [];

  if (acctContext?.isLoggedIn()) {
    qstrings.push(`${SearchParams.UI_REFERRAL_USER_ID}=${acctContext.account.id}`);
    qstrings.push(`${SearchParams.UI_REFERRAL_CONTEST_ID}=${contest.id}`);
  }

  const url = qstrings.length > 0 ? `${baseUrlPath}?${qstrings.join("&")}` : baseUrlPath;

  const onCopyLink = async () => {
    if (contest) {
      try {
        // console.log('copied url', { url });
        await navigator.clipboard.writeText(url);
        msgrContext.setMessage({ body: 'Url was copied to clipboard!' });
      } catch (error: any) {
        msgrContext.setMessage({ body: `Could not copy: ${error.message}` }, true, Severity.FATAL);
        console.error({ error });
      }
    }
  }

  return (
    <ShareBase onCopyLink={onCopyLink} title='Share Contest' className="my-2">
      {({ setShow }) => {
        return <RWebShare
          data={{
            text: `I entered a contest at Predictagram where we make daily stock price predictions! It's fun and completely free. Come join me and maybe we can win some prizes together!`,
            url: url,
            title: `Enter ${contest.name} Contest`,
          }}
          onClick={() => setShow(false)}
        >
          <div role="button">Share via...</div>
        </RWebShare>
      }}
    </ShareBase>
  );
}


