export enum BrokerTransactionStatusEnum {
    OPEN = 1,
    CLOSED = 2,
}

export enum BrokerTransactionVerificationStatusEnum {
    APPROVED    = 1,
    PENDING     = 2,
    REJECTED    = 3,
}
