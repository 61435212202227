import React from 'react'

import { useOutletContext, useParams } from 'react-router-dom';
import { IPublicProfile } from 'interfaces/IPublicProfile';
import { PredictorTrades } from './PredictorTrades';

export const PredictorPositionsPage:React.FunctionComponent = () => {

  const { user } = useOutletContext<{user: IPublicProfile}>();
  const { securityId } = useParams();

  return (

    <PredictorTrades user={user} securityId={securityId ? parseInt(securityId) : undefined}/>

  )
}

