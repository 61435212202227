import { useRef, useState } from "react"
import * as XLSX from 'xlsx';
import { Download } from "react-bootstrap-icons";

export function useExcelDownload<T>(mapper: (item: T) => {}, filename: string) {

  const [downloading, setDownloading] = useState<boolean>(false);

  const mapperRef = useRef(mapper);

  const downloadToExcel = (data: T[], name?:string) => {
    setDownloading(true);
    const excelData = data;
    const jsonData =  excelData.map(mapperRef.current);
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, `${name||filename}.xlsx`);
    setDownloading(false);
  }

  const render = (data:T[]) => {
    return <button className="btn btn-primary py-2" onClick={() => downloadToExcel(data)} disabled={downloading}>Download To Excel</button>
  }

  const renderIcon= (data:T[]) => {
    return <button className="btn btn-primary py-2" onClick={() => downloadToExcel(data)} disabled={downloading}><Download /></button>
  }

  return {
    render, downloadToExcel, renderIcon
  }

}
