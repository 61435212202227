import React, { useContext, useState } from 'react';
import { Helper } from '_utils';
import { AccountContext } from 'components/common/AccountContext';
import { ConfirmCheck, FieldWithError } from 'components/common/form';
import { Form, Formik } from 'formik';
import { validationMap, Validator } from 'schemas';
import * as Icons from 'components/common/Icons';
import { MessageHelper } from '_utils/MessageHelper';
import { PredictorCard } from './PredictorCard';
import { useVerificationReminder } from '_hooks/useVerificationReminder';
import { Retry } from './Retry';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PixelHelper, VerificationEventEnum } from '_utils/PixelHelper';
import { ExclamationOctagonFill } from 'react-bootstrap-icons';

interface IProps {
  onSuccess: (phoneNumber: number) => void,
  handleClose: ()=>void,
  initialPhoneNumber?: number,
}

const PhoneConfirmationWizard: React.FunctionComponent<IProps> = ({ onSuccess, handleClose, initialPhoneNumber }) => {

  const acctContext = useContext(AccountContext);

  const enum Stage {
    ENTER_PHONE_NUMBER = 1,
    ENTER_CONF_CODE = 2,
    SUBMIT_CONF_CODE = 3,
    DONE = 99,
  }

  const initialValues = {
    phoneNumber: acctContext?.account.phoneNumber ? Helper.formatPhoneAsUS(acctContext?.account.phoneNumber.toString()) : undefined,
    confirmationCode: '',
    agreeToReceiveText: true
  }

  const [currentStage, setCurrentStage] = useState<Stage>(Stage.ENTER_PHONE_NUMBER);
  const [ retries, setRetries ] = useState<number>(0);
  const verificationReminder = useVerificationReminder(new Date(), false);

  const _handleClose = () => {
    verificationReminder.setLastReminder();
    handleClose();
  }

  const _onSubmit = async (data: any, actions: any) => {

    if (!acctContext || acctContext === null) {
      throw Error('unexpected error. Missing account');
    }

    const { setStatus } = actions;

    if (currentStage === Stage.ENTER_PHONE_NUMBER) {
      // verify the phone number
      try {
        // save it in profile first
        setStatus("");
        await acctContext?.update({ phoneNumber: Helper.removeCharsFromPhone(data.phoneNumber) });
        // per #491, registerPhoneNumber  does not support params. phone number is taken from profile.
        await acctContext?.registerPhoneNumber();
        PixelHelper.trackVerificationEvent(VerificationEventEnum.ENTER_PHONE_NUMBER);
        setCurrentStage(Stage.ENTER_CONF_CODE);
      } catch (error: any) {
        PixelHelper.trackVerificationEvent(VerificationEventEnum.SMS_FAILED);
        setStatus(MessageHelper.translate((error as Error).message))
      }
    }

    if (currentStage === Stage.SUBMIT_CONF_CODE) {
      const { phoneNumber, confirmationCode } = data;
      try {
        PixelHelper.trackVerificationEvent(VerificationEventEnum.SUBMIT_VERCODE);
        await acctContext.verifyMobile(Helper.removeCharsFromPhone(phoneNumber), confirmationCode);
        onSuccess(phoneNumber);
        handleClose();
        setCurrentStage(Stage.DONE);
      } catch (error: any) {
        setCurrentStage(Stage.ENTER_CONF_CODE);
        setStatus(MessageHelper.translate((error as Error).message));
      }
    }
  }

  return (
    <div className="settings-dialog phone-verification">
      <Formik initialValues={initialValues} onSubmit={_onSubmit} validationSchema={validationMap.get(Validator.PHONE_NUMBER_FORM)}>
        {({ values, errors, touched, status, setFieldValue, isSubmitting, handleSubmit }) => {
          return <Form>

            <div className="d-flex justify-content-between flex-column" style={{ height: "95%" }}>

              <PredictorCard />

              <div className="mt-3">
                <div className="d-flex gap-1"><Icons.PurpleCheck /><span className="text-18 fw-bold">Verify Your Account</span></div>
                <div className="text-12 mt-3">Verify your account to continue to be eligible to appear on the leaderboard and win prizes.</div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="mt-3">
                    <span className="text-12">Phone Number</span>

                    <PhoneInput defaultCountry="us"
                      value={initialPhoneNumber?.toString()}
                      onChange={(phone) => setFieldValue("phoneNumber", phone)} />
                    
                    {/* <FormattedPhone
                      initialValue={values["phoneNumber"]}
                      className={'mt-1 form-control' + (errors && errors["phoneNumber"] && touched && touched["phoneNumber"] ? ' is-invalid' : '')}
                      fieldName={"phoneNumber"} setPhoneNumber={(phone: string) => { setFieldValue("phoneNumber", phone) }} />
                    {errors && <ErrorMessage name={"phoneNumber"} component="div" className="invalid-feedback" />} */}
                  </div>
                </div>

                <div className="my-3">
                  <ConfirmCheck
                    name="agreeToReceiveText"
                    errors={errors}
                    touched={touched}
                  >
                    <div className="text-10">You agree to receive text messages from Predictagram, and you acknowledge that standard text messaging rates from your wireless carrier may apply.</div>
                  </ConfirmCheck>
                </div>

                <div className="my-3">

                  {currentStage === Stage.ENTER_PHONE_NUMBER &&
                    <button type="submit" className="btn btn-primary text-14" disabled={isSubmitting}>
                      {`${retries > 0 ? "Re-s" : "S"}end Verification Code`}
                    </button>
                  }

                  {currentStage === Stage.ENTER_CONF_CODE &&
                    <Retry onElapsed={() => {
                      PixelHelper.trackVerificationEvent(VerificationEventEnum.RETRY_VERCODE);
                      setRetries(retries + 1);
                      setCurrentStage(Stage.ENTER_PHONE_NUMBER);
                    }} />
                  }
                </div>

                <div className="my-3">
                  <FieldWithError fieldName="confirmationCode" placeholder='Enter Code' disabled={currentStage === Stage.ENTER_PHONE_NUMBER && retries === 0} errors={errors} touched={touched} label="Verification Code" />
                </div>

                {/* <ActionButtons isSubmitting={isSubmitting} /> */}
                {status && <div className="mb-3 text-red">
                    <div className="d-flex gap-2 align-items-start"><ExclamationOctagonFill className="mt-1" /> {status}</div>
                  </div>}

                <div className="d-flex flex-column gap-2">
                  <button type="button" className="btn btn-secondary text-16 w-100" onClick={()=>{setCurrentStage(Stage.SUBMIT_CONF_CODE); handleSubmit()}} disabled={isSubmitting || (currentStage === Stage.ENTER_PHONE_NUMBER && retries === 0)}>Submit</button>
                  <button type="button" className="btn btn-tertiary text-16 w-100" onClick={_handleClose}>I'll do this later</button>
                </div>
              </div>

            </div>
          </Form>
        }}
      </Formik>
    </div>
  );
}

export { PhoneConfirmationWizard }