import React from 'react';
import { InfoCircleFill } from 'react-bootstrap-icons';

export const HelpTypeSelect: React.FunctionComponent<{ handleClose: () => void, withHeader?: boolean }> = ({ handleClose, withHeader = true }) => {
  return (
    <div className="help d-flex flex-column justify-content-center m-auto mt-3">

      {!!withHeader &&
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <InfoCircleFill />
            <div>Prediction Types</div>
          </div>

          <div>
            <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
          </div>
        </div>
      }

      <div className="modal-help-prediction-type text-14">
        <div>
          <ul>
            <li className="my-3"><span className="text-lavender">30 Minute Up/Down Prediction</span> - stock price below or above selected price a half hour from now. </li>
            <li className="my-3"><span className="text-lavender">1 Hour Up/Down Prediction</span> - stock price below or above selected price an hour from now. </li>
            <li className="my-3"><span className="text-lavender">3-Day Up/Down Prediction</span> - stock price below or above selected price 3 trading days from now. </li>
            <li className="my-3"><span className="text-lavender">Closing Up/Down Prediction</span> - stock price below or above selected price at market close. </li>
            <li className="my-3"><span className="text-lavender">Intraday/Two-Hour Prediction</span> - stock price two hours from now. Not available after 1pm EST.</li>
            <li className="my-3"><span className="text-lavender">Closing Prediction</span> - stock price at 4pm EST. Not available after 3:30pm EST.</li>
            <li className="my-3"><span className="text-lavender">High Prediction</span> - the highest stock price from now until 4pm EST.</li>
            <li className="my-3"><span className="text-lavender">Low Prediction</span> - the lowest stock price from now until 4pm EST.</li>
          </ul>
        </div>
      </div>
    </div>

  );
}