import React, { useState } from 'react';
import {Info} from 'components/common/Icons';

export const Tooltip: React.FunctionComponent<{ children: any, text: JSX.Element }> = ({ children, text }) => {

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  return (
    <div role="button" 
      onMouseEnter={()=>setShowTooltip(true)}
      onMouseLeave={()=>setShowTooltip(false)}
      onClick={()=>setShowTooltip(!showTooltip)}
      style={{ position: 'relative', cursor: 'pointer' }}>

      <div className="d-flex justify-content-center align-items-center gap-1">
        {children}
        <Info width={12} className="mb-2" />
      </div>
      {showTooltip &&
        <div style={{
          position: 'absolute',
          top: "-1.5625rem",
          left: 5,
          zIndex: 10000
        }} className="bg-charcoal text-white text-10 text-nowrap p-1 rounded">
          {text}
        </div>
      }

    </div>

  )
}
