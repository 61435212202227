import React, { useState } from 'react'
import { ApiStateImpl, IApiState } from '_constants/APIState';
import { Spinner } from 'components/common/Spinner';
import { DateHelper, NewYorkTz } from 'predictagram-lib';
import { adminPredictionApiService, IPredictionAdminSearchOptions } from 'services/AdminApiService';
import { AdminPredictionFilter, ISearchOptions } from './AdminPredictionFilter';
import { PredictionLazy } from "../../public/ListingsPage/components/Prediction/PredictionLazy";
import { useScrollCounter } from '_hooks/useScrollCounter';
import { IPredictionAdmin } from 'interfaces/IPrediction';
import { predictionTypeNames } from '_constants/PredictionTypeEnum';
import { Checkbox } from "../../common/form/Checkbox";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import { UsernameDropDown } from './UsernameDropDown';

export const AdminPredictionsSearch: React.FunctionComponent = () => {

  const [counter] = useScrollCounter();
  const [showChart, setShowChart] = useState<boolean>(false);

  const [predictions, setPredictions] = useState<IPredictionAdmin[]>([]);
  const [gridState, setGridState] = useState<IApiState>(ApiStateImpl.IDLE);
  const [fileState, setFileState] = useState<IApiState>(ApiStateImpl.IDLE);
  const [status, setStatus] = useState<string>('');

  const [selectedUserId, setSelectedUserId] = useState<number>(0);

  const loadWrapper = async (setState: any, func: () => Promise<any>) => {
    try {
      setState(ApiStateImpl.LOADING);
      await func();
      setState(ApiStateImpl.LOADED);
    } catch (e) {
      setState(ApiStateImpl.error(e));

    }
  }

  const onClickCallback = async (searchOptions: ISearchOptions) => {
    const opts: IPredictionAdminSearchOptions = {
      startTime: searchOptions.startTime,
      endTime: searchOptions.endTime,
      predictionType: searchOptions.predictionType || undefined,
      symbolNames: searchOptions.symbolName ? [searchOptions.symbolName] : undefined,
      userId: selectedUserId === 0 ? undefined : selectedUserId
    };
    setStatus('');
    if (searchOptions.isDownload) {
      await loadWrapper(setFileState, async () => {
        opts.limit = 20_000;
        const rawData = await adminPredictionApiService.search(opts);
        if (rawData.length === 0) {
          setStatus('No data to export.');
          return;
        }
        const mappedData = rawData.map(excelMapper);
        const filename = `predictions-${DateHelper.dateTimeFormatUs(searchOptions.startTime).substring(0, 10)}-${DateHelper.dateTimeFormatUs(searchOptions.endTime).substring(0, 10)}`;
        const csv = new ExportToCsv({ useKeysAsHeaders: true, quoteStrings: '', filename });
        csv.generateCsv(mappedData);
      });
    } else {
      await loadWrapper(setGridState, async () => {
        opts.limit = 2_000;
        const preds = await adminPredictionApiService.search(opts);
        setPredictions(preds);
      });
    }
  }


  const excelMapper = (p: IPredictionAdmin) => {
    const valueAtDateString = DateHelper.dateTimeFormatUs(p.valueAt);
    const createdAtDateString = DateHelper.dateTimeFormatUs(p.createdAt);

    return {
      id: p.id,
      typeId: p.typeId,
      categories: predictionTypeNames.get(p.typeId),
      userId: p.userId,
      username: p.username,
      rawScore: p.scoreReal,
      rawScoreV2: p.scoreRealV2,
      rawScoreV3: p.scoreRealV3,
      boostedScore: p.scoreUser,
      boostedScoreV2: p.scoreRealV2Details?.scoreUser,
      actualValue: p?.scoreActualValue,
      priceAtPrediction: p.scoreValueAtMadeTime,

      predictedValue: p.value,
      valueDeviation: p.valueDeviation,

      stockSymbol: p.stockSymbol,

      valueAtEpoch: p.valueAt,
      valueAtString: valueAtDateString,
      createdAtEpoch: p.createdAt,
      createdAtString: createdAtDateString,
      userLast30AverageScore: p.userLast30AverageScoreReal,
      userLast30PredictionsCompleted: p.userLast30PredictionsCompleted,
    };

  }

  return (
    <div className="admin-predictions">

      <div className="d-flex justify-content-center align-items-center gap-3">
        <div className="d-flex justify-content-start flex-row gap-3">
          <div className="d-flex flex-column">
            <Checkbox label="Show Chart" value={showChart} onChange={() => setShowChart(!showChart)} />
          </div>
        </div>

        {gridState.isLoaded() && <UsernameDropDown items={predictions.map(p => {
          return { id: p.userId, username: p.username }
        })} setUser={setSelectedUserId} initialValue={selectedUserId} />}

        <AdminPredictionFilter onSubmit={onClickCallback}/>
      </div>

      {fileState.isLoading() && <Spinner />}
      {fileState.isError() && <div>{fileState.error()?.message}</div>}


      {gridState.isLoading() && <Spinner />}
      {gridState.isError() && <div>{gridState.error()?.message}</div>}

      {status && <div className="alert alert-danger">{status}</div>}

      {gridState.isLoaded() &&
        <>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Type ID</th>
                <th>Prediction Type Name</th>
                <th>User ID</th>
                {showChart && <th>Chart</th>}
                <th>Score Data</th>
                <th>Stock Symbol</th>
                <th className="text-end">Value</th>
                <th className="text-end">Value At</th>
                <th className="text-end">Val. Deviation</th>
                <th className="text-end">Score</th>
                <th className="text-end">createdAt</th>
                {/*<th className="text-end">Real Score</th>*/}
                {/*<th className="text-end">Real Score V2</th>*/}
                {/*<th className="text-end">Boosted Score</th>*/}
              </tr>
            </thead>

            <tbody>
              {predictions.map((p, i) => <tr key={`tr-prediction-${i}-key`}>
                <td>{p.id}</td>
                <td>{p.typeId}</td>
                <td>{predictionTypeNames.get(p.typeId)}</td>
                <td>{p.userId}</td>
                {showChart && <td>
                  <PredictionLazy refresh={counter} showProfile={false} prediction={p} key={`prediction-key-${i}`} onScrollBottom={false} cardWidth={300} />
                </td>}
                <td>
                  <table className="table">
                    <tbody>
                      <tr><td>Raw Score:</td><td>{p.scoreReal}</td></tr>
                      <tr><td>Raw Score v2:</td><td>{p.scoreRealV2}</td></tr>
                      <tr><td>Raw Score v3:</td><td>{p.scoreRealV3}</td></tr>
                      <tr><td>Boosted Score:</td><td>{p.scoreUser}</td></tr>
                      <tr><td>Boosted Score v2:</td><td>{p?.scoreRealV2Details?.scoreUser}</td></tr>
                      <tr><td>Predicted Val:</td><td>{p.value}</td></tr>
                      <tr><td>Actual Val:</td><td>{p?.scoreActualValue}</td></tr>
                      <tr><td>Val. Deviation:</td><td>{p.valueDeviation}</td></tr>
                      <tr><td>Value At Pred Made Time</td><td>{p.scoreValueAtMadeTime}</td></tr>
                      <tr><td>Actual Duration Secs:</td><td>{p.scoreStockTime?p.scoreStockTime-p.valueTime:''}</td></tr>
                    </tbody>
                  </table>
                </td>
                <td>{p.stockSymbol}</td>
                <td className="text-end">{p.value}</td>
                <td className="text-end">{NewYorkTz.format(new Date(p.valueAt * 1000)).monthNameDateTime()}</td>
                <td className="text-end">{p.valueDeviation}</td>
                <td className="text-end">{p.score}</td>
                <td className="text-end">{NewYorkTz.format(new Date(p.createdAt * 1000)).monthNameDateTime()}</td>
                {/*<td className="text-end">{p.scoreReal}</td>*/}
                {/*<td className="text-end">{p.scoreRealV2}</td>*/}
                {/*<td className="text-end">{p.scoreUser}</td>*/}
              </tr>)}
            </tbody>
          </table>
        </>
      }
    </div>
  )
}
