import React, { useContext, useState } from 'react'
import { Avatar, AvatarSizeEnum } from 'components/common/Avatar'
import { AccountContext } from 'components/common'
import { IPublicProfile } from 'interfaces/IPublicProfile'
import { Spinner } from 'components/common/Spinner'
import { useSearchParams } from 'react-router-dom'
import { TicketsCollected } from './TicketsCollected'
import { AccuracyBar } from './AccuracyBar'
import { TicketProgress } from './TicketProgress'
import { useUserTicketsActive } from '_hooks/useUserTicketsActive'
import { ModalContestRules } from 'components/common/ModalContestRules'
import { Helmet } from 'react-helmet';

const PredictionCount: React.FunctionComponent<{ max: number, current: number }> = ({ max, current }) => {
  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <div style={{ height: "2.3125rem" }}><span className="text-white text-32 fw-bold">{current}</span> <span className="text-24 fw-medium text-gray">/ {max}</span></div>
      <div className="text-gray text-12 ">predictions</div>
    </div>
  );
}

export const TicketPage: React.FunctionComponent = () => {

  const acctContext = useContext(AccountContext);
  const [showRules, setShowRules] = useState<boolean>(false);

  // @for testing various states
  const [searchParams] = useSearchParams();
  const userTickets = useUserTicketsActive(searchParams.get('file'));
  const activeTicket = userTickets.activeTicket;

  const AccountWithProgress = () => {
    const accuracy = (activeTicket?.scoreAvg || 0) * 100;
    return (
      <>
        <div className="d-flex justify-content-center align-items-center">
          <div className="align-self-bottom mt-2">{acctContext && acctContext.account && <Avatar profile={acctContext.account as IPublicProfile} imageWidth={"29px"} size={AvatarSizeEnum.LARGE} />}</div>
          <div style={{ width: "10rem" }} className="d-flex flex-column gap-2 text-white">
            {activeTicket &&
              <AccuracyBar
                value={accuracy}
                threshold={activeTicket.scoreThreshold * 100}
                predictions={activeTicket.predictionsCompleted} />
            }
          </div>
        </div>

        <div className="text-12 text-center mt-3 text-white">
          {(activeTicket && activeTicket.scoreAvg < activeTicket.scoreThreshold) ?
            <>Keep trying! You need 🎯70% to qualify for a ticket.</>
            :
            <>🎉 Looking good so far!</>
          }
        </div>
      </>
    );
  }

  if (userTickets.apiState.isLoading) {
    return <div className="d-flex justify-content-center align-items-center flex-column">
      <Spinner />
    </div>
  }

  if (userTickets.error) {
    return <div className="d-flex justify-content-center align-items-center flex-column">
      Unexpected error: {userTickets.error}
    </div>
  }

  if (!activeTicket) {
    return (
      <>
        <Helmet>
          <title>Predictagram: Tickets</title>
        </Helmet>
        <div>
          <>No Active Ticket</>
        </div>

      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Predictagram: Tickets</title>
      </Helmet>
      <ModalContestRules show={showRules} handleClose={() => setShowRules(false)} />
      <div className="ticket-container mb-5">
        <div className="d-flex justify-content-center align-items-center flex-column mx-3">
          <div className="fw-bold text-white text-18">Weekly Contest Entry</div>
          <div className="text-14 text-center text-off-white my-3 px-3 px-md-5">
            Earn up to 3 chances to win <span className="text-orange fw-bold">$200</span> each week by making predictions with over 70% average accuracy!<br />Track your progress below.
          </div>
          <div className="team-link-contest-rules mb-2 text-decoration-underline fw-bold" onClick={() => setShowRules(true)} role="button">View Contest Rules</div>
        </div>

        <div className="border-bottom border-color-darkest-gray my-3"></div>

        <div className="d-flex flex-column justify-content-center align-items-center gap-3 mt-3">
          <TicketProgress ticket={activeTicket} />
          <PredictionCount max={activeTicket.predictionsCompletedThreshold} current={activeTicket.predictionsCompleted} />
          <div className="my-3">
            <AccountWithProgress />
          </div>
          <TicketsCollected tickets={userTickets.data} />
        </div>

      </div>
    </>
  )
}
