import React, { useState } from 'react';
import { ContestRulesLink } from './ContestRulesLink';
import { Helper } from '_utils';
import { RoundCloseButton } from 'components/common/buttons/RoundCloseButton';
import Cookies from 'js-cookie';
import { StorageKey } from '_constants';
import { useUserTicketsActive } from '_hooks/useUserTicketsActive';

export const WeeklyContest: React.FunctionComponent = () => {

  const monday = Helper.getStartDateOfWeek(1, new Date());
  const friday = Helper.getStartDateOfWeek(5, new Date());

  const activeTicket = useUserTicketsActive(null);

  const initialShow = (): boolean => {
    const lastReminder = Cookies.get(StorageKey.COOKIES_CONTEST_REMINDER);
    return lastReminder === undefined;
  }

  const [show, setShow] = useState<boolean>(initialShow());

  const closeCard = () => {
    setShow(false);
    const exp =  Helper.getStartDateOfWeek(7, new Date()); // expires the following sunday.
    Cookies.set(StorageKey.COOKIES_CONTEST_REMINDER, exp.getTime().toString(), { expires: exp.getTime() });
  }

  if (!show) {
    return <></>
  }

  return (
    <div className="team-contest-overview weekly px-3">

      <div style={{position: "relative"}}>
        <div className="team-contest-overview-head text-semi-bold my-3">Weekly Contest</div>

        <div className="d-flex justify-content-start align-items-center gap-2">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div className="team-contest-overview-amount weekly" style={{ width: "9.0625rem" }}>$200</div>
            <div className="team-contest-overview-image weekly" style={{ width: "1.75rem" }}>
              <img src="/images/money-bag.png" alt="money-bag" />
            </div>
          </div>
        </div>

        <div style={{position:"absolute", top: 0, right: 0}}>
          <RoundCloseButton onClick={closeCard} />
        </div>

      </div>

      <div className="team-contest-overview-text my-3">
        If you make at least 30 predictions during the week of {Helper.dateFormatMMDD(monday)} - {Helper.dateFormatMMDD(friday)}, with over 70% accuracy, you will be eligible for a random $200 prize drawing.
      </div>

      <div className="d-inline-flex rounded-pill justify-content-center align-items-center bg-black pe-3">
        {activeTicket.renderTicket(25, 4)}
        <div className="text-14 text-sunset fw-bold" role="button" onClick={()=>activeTicket.showModal()}>
          Requires entry ticket
        </div>

      </div>

      <div>
        <ContestRulesLink />
      </div>
    </div>
  );
}