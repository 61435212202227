import React, { ChangeEvent, useState } from 'react'
import { cancelReasonDropDownItemsMap, CancelReasonFilterEnum, CancelSubscriptionReasonDropDown } from '../dashboard/filters/CancelSubscriptionReasonDropDown'


export const CancelSubscription: React.FunctionComponent<{ onClick: (reason: string)=> void }> = ({ onClick }) => {

  const [inputValue, setInputValue] = useState<string>('');
  const [selectedReason, setSelectedReason] = useState<CancelReasonFilterEnum>(CancelReasonFilterEnum.NO_REASON);

  const maxChars = 200;

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= maxChars) {
      setInputValue(event.target.value);
    }
  };

  const render = () => {
    return (
      <div className="d-flex flex-column gap-3">
        <div className="fw-bold">{"We're sorry to see you go :("}</div>

        <div>
          What is your reason for canceling Pro Signals?
          <div className="mt-2">
          <CancelSubscriptionReasonDropDown
            className="text-14"
            initialValue={CancelReasonFilterEnum.NO_REASON}
            setValue={(v) => setSelectedReason(v)} />
          </div>

          <div className="mt-3">Share your feedback</div>
          <div className="">
            <textarea
              style={{resize: "none"}}
              className="w-100 rounded my-2 bg-darkest-gray text-white"
              value={inputValue}
              onChange={handleInputChange}
              maxLength={maxChars}
              rows={3}
              placeholder="Optional Feedback"
            />
          </div>
        </div>
      </div>

    );
  }

  const reason = `${cancelReasonDropDownItemsMap.get(selectedReason)?.label.toString() || ''} - ${inputValue}`;

  return (
    <>
      <div className="d-flex flex-column justify-content-between h-100">
        <div className="align-self-start mt-5">{render()}</div>
        <button disabled={selectedReason===CancelReasonFilterEnum.NO_REASON} className="btn btn-secondary align-self-center mb-5" onClick={()=>onClick(reason)}>Cancel Subscription</button>
      </div>
    </>

  )
}
