export enum AutoTradeStatusReasonEnum {
    UNDEFINED                       = 0,
    OPTION_CONTRACT_NOT_FOUND       = 1,
    ORDER_SUBMITTED                 = 2,
    ORDER_FILLED                    = 3,
    ORDER_CANCELED                  = 4,
    EMPTY_CONTRACT_PRICE            = 4,
    CANCELED_LIMIT_TRIES_REACHED    = 5,
    EMPTY_API_ORDER_RESPONSE        = 6,
    OPTION_NOT_FOUND_STRIKE_PRICE   = 7,
    OPTION_EMPTY_CONTRACT_PRICE     = 8,
    API_ORDER_CREATION_FAILED       = 9,
    API_ORDER_CREATION_SAME_SIDE_ERROR = 10, // Cannot have open orders on both sides of the same US Option contract
    EMPTY_API_RESPONSE              = 11,
    STOCK_CONTRACT_NOT_FOUND        = 12,
    // 503 error, but order is really created  - IBKR buggy api
    // https://redmine.predictagram.com/issues/924
    API_ORDER_CREATION_FAILED_503   = 13,
    UNEXPECTED_CASE                 = 1000,
}

export class AutoTradeStatusReasonEnumHelper {
    static names: Map<AutoTradeStatusReasonEnum, string> = new Map([
        [AutoTradeStatusReasonEnum.UNDEFINED, 'Undefined'],
        [AutoTradeStatusReasonEnum.OPTION_CONTRACT_NOT_FOUND, 'Option Contract Not Found'],
        [AutoTradeStatusReasonEnum.ORDER_SUBMITTED, 'Order Submitted'],
        [AutoTradeStatusReasonEnum.ORDER_FILLED, 'Order Filled'],
        [AutoTradeStatusReasonEnum.ORDER_CANCELED, 'Order Cancelled'],
        [AutoTradeStatusReasonEnum.EMPTY_CONTRACT_PRICE, 'Empty Contract Price'],
        [AutoTradeStatusReasonEnum.CANCELED_LIMIT_TRIES_REACHED, 'Cancel Limit Tries Reached'],
        [AutoTradeStatusReasonEnum.EMPTY_API_ORDER_RESPONSE, 'Empty API Order Response'],
        [AutoTradeStatusReasonEnum.OPTION_NOT_FOUND_STRIKE_PRICE, 'Option Not Found Strike Price'],
        [AutoTradeStatusReasonEnum.OPTION_EMPTY_CONTRACT_PRICE, 'Option Empty Contract Price'],
        [AutoTradeStatusReasonEnum.API_ORDER_CREATION_FAILED, 'API Creation Failed'],
        [AutoTradeStatusReasonEnum.API_ORDER_CREATION_FAILED_503, 'API Creation Failed 503'],
        [AutoTradeStatusReasonEnum.API_ORDER_CREATION_SAME_SIDE_ERROR, 'Option Both Sides '],
        [AutoTradeStatusReasonEnum.EMPTY_API_RESPONSE, 'Empty Api Response'],
        [AutoTradeStatusReasonEnum.UNEXPECTED_CASE, 'Unexpected Case'],
        [AutoTradeStatusReasonEnum.STOCK_CONTRACT_NOT_FOUND, 'Stock Contract Not Found'],
    ]);
}
