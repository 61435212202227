import React, {useRef, useState} from "react";
import {BaseTable, FieldWithError} from "components/common";
import {adminApiReportService, IAdminUserStatsApiSearchOptions as Options} from "services/AdminApiReportService";
import {Spinner} from "components/common/Spinner";
// import { AdminRegistrationsFilter } from "./Filter";
import {IAdminUserStats, NewYorkTz} from "predictagram-lib";
import {useApiServiceState} from "../../../../_hooks/useApiServiceState";
import {Form, Formik} from "formik";
import ReactDatePicker from "react-datepicker";
import {DropDown} from "../../../common/form/DropDown";
import {useExcelDownload} from "../../../../_hooks/useExcelDownload";
import LazyLoad from "react-lazy-load";
import {Chart} from "react-google-charts";

// @NOTE: object as key doesn't work
const orderByList = new Map( [
  [ 1, [{column:'predictionsCompleted', order: 'desc'}]],
  [ 2, [{column:'referrers', order: 'desc'}]],
]);
const orderBy = new Map([
  [ 1,  'Predictions Completed'],
  [ 2,  'Referrers'],
]) as any;
const orderByDefault = orderByList.get(1);

const Filter: React.FunctionComponent<{
  initialValues: Options,
  onClick: (search:Options)=>void,
}> = ({ initialValues, onClick }) => {

  const onSubmit = (dataIn: any, actions: any) => {
    const data = Object.assign({}, dataIn);
    data.orderBy = data.orderBy ? orderByList.get(parseInt(data.orderBy)) : orderByDefault;
    onClick({
      ...data,
      usernameLike: /^\d+$/.test(data['usernameLike']) ? undefined : data['usernameLike'],
      userId: /^\d+$/.test(data['usernameLike']) ? parseInt(data['usernameLike']): undefined,
    });
  }


  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue }) => {
          return <Form>
            <div className="d-flex justify-content-start align-items-center gap-3 my-3">

              <div className="d-flex flex-column">
                <div className="fw-bold text-14">User Name or ID</div>
                <FieldWithError fieldName='usernameLike'
                  errors={errors}
                  touched={touched}
                  placeholder="User Name or Id" />
              </div>


              <div className="d-flex flex-column">
                <DropDown enumMap={orderBy}
                          touched={touched}
                          errors={errors}
                          label="Order By"
                          name="orderBy"/>
              </div>

              <div className="d-flex flex-column">
                <span className="fw-bold">Start Date</span>
                <ReactDatePicker
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("startTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.startTime * 1000)}
                />
              </div>

              <div className="d-flex flex-column">
                <span className="fw-bold">End Date</span>
                <ReactDatePicker
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("endTime", date.getTime() / 1000);
                  }}
                  selected={new Date(values.endTime * 1000)}
                />
              </div>
              <div className="align-self-end">
                <button type="submit" className="btn btn-primary p-2">Update Search</button>
              </div>
            </div>

          </Form>
        }}

      </Formik>
    </div>
  )
}


export const AdminReportUsersStatsList: React.FunctionComponent = () => {

  const todayStart = NewYorkTz.getDateMidnight(new Date()).getTime() / 1000;
  const todayEnd = todayStart + (24 * 60 * 60) - 1; // set to last minute of today

  const excelDownload = useExcelDownload<any>((v)=>{return {...v}}, 'users-stats');

  const initialSearch: Options = {
    startTime: todayStart,
    endTime: todayEnd,
    orderBy: orderByDefault as any,
    userId: undefined
  }
  const initialValues = {
    startTime: todayStart,
    endTime: todayEnd,
    userId: undefined,
    usernameLike: undefined,
  } as Options;
  const searchOptions = useRef(initialSearch);

  type StatItem = IAdminUserStats&{index:number};
  const supplier = async(ops:Options):Promise<StatItem[]>=> {
    const data = await adminApiReportService.getUsersStats(ops);
    let pos = 1;
    for(const r of data) {
      (r as any).index = pos;
      pos++;
    }
    // for (let i=0; i<=data.length; i++) {
    //   (data as any)[i].index = i+1;
    // }
    return data as any;
  }

  // const [ options, setOptions ] = useState<Options>(initialSearch);
  const {items, state, setSupplier} = useApiServiceState<StatItem[]>(()=>supplier(initialSearch));

  const useChart = (item: StatItem)=>{

    const [chartData, setChartData] = useState<any>(null);

     const loadChart = async() =>{
       const dailyStats = await adminApiReportService.getUserStatsDay(item.userId, searchOptions.current);
       // console.debug({dailyStats});
       const data:any = [
         [{label:"", type:'date', }, {label:"Preds", type:'number'}, {label:"Refs", type:"number"}]
       ];
       let prevD:Date|null = null;
       dailyStats.forEach(r=>{
         const d = new Date(r.date + ' 00:00:00');
         // fill gaps(empty days) in returned result
         if (prevD!==null) {
           let curr = new Date(prevD);
           curr.setDate(curr.getDate()+1);
           while (curr<d) {
             data.push([new Date(curr), 0, 0]);
             curr.setDate(curr.getDate()+1);
           }
         }
         // console.debug({d, item});
         data.push([d, r.predictions, r.referrers]);
         prevD = d;
       });

       setChartData(data);

     };

    const options = {
      hAxis: {
        // format: "Y,m,d",
      },
      legend: {
        position: 'top',
      }
    };

    return (
      <LazyLoad height={100} width={600} onContentVisible={loadChart}>
        <>
        {chartData && <Chart
          chartType="LineChart"
          data={chartData}
          width="100%"
          height="100%"
          legendToggle
          options={options}
        />}
        </>
      </LazyLoad>
    );
  };

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['index', ['Index', undefined]],
    ['userId', ['User', undefined]],
    ['username', ['User Name', undefined]],
    ['email', ['User Email', undefined]],
    ['scoreRaw', ['Score Raw', undefined]],
    ['scoreBoosted', ['Score Boosted', undefined]],
    ['chart', ['Daily Stats', useChart]],
    // ['scoreRaw', ['Score Raw', undefined]],
    ['predictions', ['Predictions', undefined]],
    ['predictionsCompleted', ['Predictions Completed', undefined]],
    ['referrers', ['Referrers', undefined]],
  ]);

  const onClickCallback = (opts: Options) => {
    searchOptions.current = {
      startTime: opts.startTime,
      endTime: opts.endTime,
      userId: opts.userId ? Number(opts.userId) : undefined,
    } as Options;
    setSupplier(()=>supplier(opts));
  }  

  return (
    <>
    <div>
      <div className="d-flex justify-content-center align-items-end gap-3">
      <Filter initialValues={initialValues} onClick={onClickCallback} />
      <div className="mb-3">{state.isLoaded() && <>{excelDownload.render(items as any)}</>}</div>
      </div>
    </div>

    {state.isLoading() && <Spinner />}

    {state.isError() && <div>{state.error()?.message}</div>}

    {state.isLoaded() &&
    <BaseTable
      data={items as any}
      fieldList={fieldList}
      onSelectClick={() => { }}
    />
    }
    </>
  );
};


