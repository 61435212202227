import React, { useState, CSSProperties } from 'react'
import { IPublicProfile } from 'interfaces/IPublicProfile'
import { AvatarSection, AvatarSectionReversed } from './AvatarSections'


interface IProps {
  profile: IPublicProfile,
  isUpward: boolean,
  hovered: boolean,
  onHover?: ()=>void,
  onLeave?: ()=>void,
}

export const PredictionArrow: React.FunctionComponent<IProps> = ({ profile, hovered: currentHovered, isUpward = true, onHover, onLeave}) => {
  //const [currentHovered, setCurrentHovered] = useState<boolean>(hovered);
  const [toggle, setToggle] = useState<boolean>(false);

  const containerStyle: CSSProperties = {
    width: "40px",
    height: "100px",
    color: "red",
    transform: `translateX(-50%) ${toggle ? `scale(0.35) translateY(${isUpward ? 65 : -65}%)`: ''}`,
    position: "relative",
    zIndex: currentHovered ? '1000' : "unset",
  }

  const arrowContainerStyle: CSSProperties = {
    position: "absolute",
    bottom: "0px",
    width: "40px",
    height: "100%",
    display: 'block',
    //backgroundColor: isUpward ? "rgb(75,75,0,0.5)" : "rgb(175,175,175,0.5)",
  }

  const greenArrowStyle: CSSProperties = {
    width: currentHovered ? "100%" : "80%",
    height: currentHovered ? "100%" : "80%",
    backgroundImage: currentHovered
      ? 'url("/images/arrows/up-green.svg")'
      : 'url("/images/arrows/up-green-gradient.svg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: 'height 0.3s ease',
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'center',
    position: 'relative',
  }

  const redArrowStyle: CSSProperties = {
    width: currentHovered ? "100%" : "100%",
    height: currentHovered ? "100%" : "70%",
    backgroundImage: currentHovered
      ? 'url("/images/arrows/dn-red.svg")'
      : 'url("/images/arrows/dn-red-gradient.svg")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    transition: 'all 0.3s ease',
    backgroundPositionX: 'center',
    position: 'relative',
  }

  const avatarSectionStyle: CSSProperties = {
    position: 'absolute',
    transform: isUpward ? 'translateY(75px)' : 'translateY(-75px)',
    display: currentHovered ? 'block' : 'none',
  }

  const handleHover = () => {
    if (onHover) {
      onHover();
    }
    //setCurrentHovered(true);
  };

  const handleLeave = () => {
    if (onLeave) {
      onLeave();
    }
    //setCurrentHovered(false);
  };

  return (
    <div style={containerStyle}>
      {isUpward ?
        <>
          <div style={arrowContainerStyle}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            onDoubleClick={()=>setToggle(!toggle)}
          >
            <div className="d-flex justify-content-center w-100 h-100 align-items-end">
              <div style={greenArrowStyle}/>
              <div style={avatarSectionStyle}>
                <AvatarSection profile={profile} />
              </div>
            </div>
          </div>
        </>
        :
        <>
          <div style={arrowContainerStyle}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            onDoubleClick={()=>setToggle(!toggle)}
          >
            <div className="d-flex justify-content-center w-100 h-100 align-items-start">
              <div style={redArrowStyle} />
              <div style={avatarSectionStyle}>
                <AvatarSectionReversed profile={profile} />
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}
