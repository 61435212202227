import React, { useState } from 'react'
import { APIState } from '_constants/APIState'
import { Spinner } from 'components/common/Spinner'
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect'
import { UserTypeFilterTabs } from './filters/UserTypeFilterTabs'
import * as Icons from "components/common/Icons";
import * as ReactIcons from 'react-bootstrap-icons';
import { IFilteredPredictions } from '_hooks/useFilteredPredictions'
import { Happenings } from './Happenings'
import { PredictionListLazy } from 'components/public/ListingsPage/components/PredictionListLazy'
import { SymbolFilter, SymbolFilterTypeEnum } from './filters/SymbolFilter'
import { IAccountSettings } from 'interfaces/account/IAccountSettings'
import { PredictionTypeDropDownFilter, PredictionTypeFilterEnum, predictionTypeDropDownItemsMap } from './filters/PredictionTypeDropDownFilter'
import { UserTypeFilterEnum } from './filters/UserTypeDropDownFilter'
import { useDashboardSettings } from '_hooks/useDashboardSettings'
import { StorageKey } from '_constants'
import { Help } from 'components/public/ListingsPage/components/Help'
import { ModalHelp } from 'components/public/Help/ModalHelp'
import { useNavigate } from 'react-router-dom'
import { RoundBackButton } from '../../common/buttons/RoundBackButton'

interface IProps {
  settings: IAccountSettings,
  userTypeFilterState: [UserTypeFilterEnum, React.Dispatch<React.SetStateAction<UserTypeFilterEnum>>],
  isExpandedState: [boolean, (value: boolean) => void],
  predictionTypeFilterState: [PredictionTypeFilterEnum, (predictionFilterType: PredictionTypeFilterEnum) => void],
  selectedSymbolsState: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  symbolFilterTypeState: [SymbolFilterTypeEnum, React.Dispatch<React.SetStateAction<SymbolFilterTypeEnum>>],
  filteredPredictions: IFilteredPredictions,
}

export const HappeningsFull: React.FunctionComponent<IProps> = ({
  settings,
  userTypeFilterState,
  isExpandedState,
  predictionTypeFilterState,
  selectedSymbolsState,
  symbolFilterTypeState,
  filteredPredictions,
}) => {


  const [dashboardSettings, setDashboardSettings] = useDashboardSettings(localStorage, StorageKey.DASHBOARD_SETTINGS);

  const [isExpanded, setIsExpanded] = isExpandedState;
  const [predictionTypeFilter, setPredictionTypeFilter] = predictionTypeFilterState;
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const navigate = useNavigate();

  const onChangeSymbols = (symbols: string[]) => {
    filteredPredictions.setOptions({
      ...filteredPredictions.options,
      symbolNames: symbols
    })
  }

  const _setSelectedPredictionTypes = (predictionTypeFilter: PredictionTypeFilterEnum) => {
    filteredPredictions.setOptions({
      ...filteredPredictions.options,
      predictionTypes: predictionTypeDropDownItemsMap.get(predictionTypeFilter)?.value
    })
    setPredictionTypeFilter(predictionTypeFilter);
  }

  const _setIsExpanded = (value: boolean) => {
    setIsExpanded(value);
    setDashboardSettings({
      ...dashboardSettings,
      isExpanded: value,
    })
  }

  return (
    <div className="dashboard bg-gradient-dark-blue">
      <ModalHelp show={showHelp} handleClose={() => setShowHelp(false)} />
      <div className="d-flex justify-content-between align-items-center bg-gradient-blue py-2 px-1">
        <div className="ms-3" role="button" onClick={()=>{
          navigate(-1);
          }}><RoundBackButton /></div>

        <div className="d-flex justify-content-center align-items-center gap-2 ">
          <ReactIcons.LightningChargeFill />
          <div className="my-3 fw-bold">Recent Predictions</div>
        </div>

        <div className="me-3"><Help onClick={()=>setShowHelp(true)} /></div>
      </div>

      <div className="mx-3 my-3 my-md-5 d-flex gap-1 align-items-start flex-column">
        <UserTypeFilterTabs userTypeFilterState={userTypeFilterState} />
        <div className="dashboard-subfilters mt-3">
          <div className="dashboard-subfilters-container mx-md-3">
          <div className="dashboard-subfilter" title="Filter by Stock Symbol">
              <SymbolFilter settings={settings} selectedSymbolsState={selectedSymbolsState} onChangeSymbols={onChangeSymbols} symbolFilterTypeState={symbolFilterTypeState}/>
            </div>
            <div className="dashboard-subfilter" title="Filter by Prediction Type">
              <PredictionTypeDropDownFilter setValue={_setSelectedPredictionTypes} initialSelectedKey={predictionTypeFilter} className="prediction-type-filter"/>
            </div>
            <div className="dashboard-subfilter" title="Show/Hide Chart">
              <div className={`chart-btn p-2 ${isExpanded ? "chart-btn-enabled" : ""}`} role="button" onClick={() => _setIsExpanded(!isExpanded)}><Icons.LineChartWithBorder on={isExpanded} /> </div>
            </div>
          </div>
        </div>


        {filteredPredictions.apiState === APIState.LOADING ? (
            <div className="happening-item"><Spinner /></div>
        ) : (
          <div className="container g-0 p-md-3">
          {isExpanded ? 
            <PredictionListLazy predictions={filteredPredictions.items} onSelectSort={()=>{}} orderBy={sortTypeEnum.LATEST} showMenu={false}/>
            : 
            <Happenings predictions={filteredPredictions.items} />
          }
          </div>
        )}

      </div>
    </div>
  )
}
