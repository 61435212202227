import React, { useState } from "react";
import { Formik, Form } from "formik";
import { FieldWithError } from "components/common";
import { validationMap, Validator } from "schemas";
import { userAccountService } from "services";
import { useParams } from "react-router";
import { Eye, EyeSlash } from "react-bootstrap-icons";

const ResetPassword: React.FunctionComponent = () => {
  const [isComplete, setIsComplete] = useState<Boolean>(false);
  const { authID } = useParams();

  const [show, setShow] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const toggleShowHidePassword = () => {
    setShow(!show);
  }

  const toggleShowHideConfirmPassword = () => {
    setShowConfirm(!showConfirm);
  }

  const initialValues: any = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = validationMap.get(Validator.RESET_PASSWORD_FORM);

  const onSubmit = async (data: any, actions: any) => {
    // @TODO: change to AccountProvider
    const { setSubmitting, setStatus } = actions;
    setSubmitting(true);
    const payload = {
      authId: authID || "",
      password: data.password,
      repeatPassword: data.confirmPassword,
    };
    try {
      await userAccountService.resetPassword(payload);
      setIsComplete(true);
    } catch (err) {
      setSubmitting(false);
      setStatus(`Password reset failed: ${err}`);
    }
  };

  return (
    <div className="container my-3 reset-password-form">
      <div className="page-title">Reset Your Password</div>
      {isComplete ? (
        <div className="text-center my-5 small-text-2">Your password has been succesfully reset.</div>
      ) : (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ isSubmitting, errors, touched, status, setSubmitting }) => {
            return (
              <Form className="form d-flex flex-row justify-content-center">
                <div className="my-3">

                  <div>Enter Password</div>
                  <div className="d-flex flex-row justify-content-start align-items-center">
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName="password"
                      placeholder="Enter Password"
                      type={show ? "input" : "password"}
                    />
                    <div role="link" className="btn btn-link" onClick={() => { toggleShowHidePassword() }}>{show ? <Eye className="text-light" /> : <EyeSlash className="text-light" />}</div>
                  </div>

                  <div className="mt-3">Confirm Password</div>
                  <div className="d-flex flex-row justify-content-start align-items-center">
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName="confirmPassword"
                      placeholder="Enter Password Again"
                      type={showConfirm ? "input" : "password"}
                    />
                    <div role="link" className="btn btn-link" onClick={() => { toggleShowHideConfirmPassword() }}>{showConfirm ? <Eye className="text-light" /> : <EyeSlash className="text-light" />}</div>
                  </div>

                  <div className="my-3">
                    <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                      Confirm
                    </button>
                  </div>

                </div>


                {status && (
                  <div className="alert">{status}</div>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export { ResetPassword };
