import { useCallback, useEffect, useRef, useState } from "react";
import { APIState } from "_constants/APIState";
import { IUserStatsPredictionTypeResponse, IUserStatsPredictionTypeSearchOptions, UserPredictionApiService } from "services/UserPredictionApiService";


export interface IUserStatPredictionType {
  items: IUserStatsPredictionTypeResponse[],
  apiState: {
    isLoading: () => boolean,
    isLoaded: () => boolean,
    isIdle: () => boolean,
    isError: () => boolean
  },
  error: Error | null,
  reload: () => void,
}

export function useUserStatsPredictionType (service: UserPredictionApiService, userId: number, symbolName?: string, startTime?: number, endTime?: number): IUserStatPredictionType {

  const [ items, setItems ] = useState<IUserStatsPredictionTypeResponse[]>([]);
  const [ apiState, setApiState ] = useState<APIState>(APIState.IDLE);
  const [ error, setError ] = useState<Error | null>(null);
  const [refresh, setRefresh] = useState<number>(0);
  
  const serviceRef = useRef(service);

  const load = useCallback(async ()=>{
    try {
      setApiState(APIState.LOADING);
      const params:IUserStatsPredictionTypeSearchOptions = {
        userId,
        symbolName,
        endTime,
        startTime
      }
      const items = await serviceRef.current.getUserStatsPredictionType (params);
      setItems(items);
      setApiState(APIState.LOADED);
    } catch (error) {
      setApiState(APIState.ERROR);
      setError(error as Error);
    } 
  }, [userId, symbolName, endTime, startTime])

  useEffect(()=>{

    load();

    // clean up
  }, [load, refresh])

  const reload = () => {
    setRefresh(refresh + 1);
  }

  const isLoaded = () => apiState === APIState.LOADED;
  const isLoading = () => apiState === APIState.LOADING;
  const isError = () => apiState === APIState.ERROR;
  const isIdle = () => apiState === APIState.IDLE;

  return {items, apiState: {isLoaded, isLoading, isError, isIdle}, error, reload};

}




// import { IUserStatsStockSymbolResponse, IUserStatsStockSymbolSearchOptions, userPredictionApiService } from "services/UserPredictionApiService";
// import { useApi2 } from "./useApi2";


// export function useUserStatsStockSymbol(userId: number, searchOptions?: IUserStatsStockSymbolSearchOptions) {
//   const load = async () => {
//     console.log({userId, searchOptions});
//     return await userPredictionApiService.getUserStatsStockSymbol(userId, searchOptions);
//   }

//   return useApi2<IUserStatsStockSymbolResponse[]>(() => load())

// }



// import { IUserStatsPredictionTypeResponse, IUserStatsPredictionTypeSearchOptions, userPredictionApiService } from "services/UserPredictionApiService";
// import { useApi2 } from "./useApi2";

// export function useUserStatsPredictionType(userId: number, searchOptions: IUserStatsPredictionTypeSearchOptions) {
//   const load = async () => {
//     return await userPredictionApiService.getUserStatsPredictionType(userId, searchOptions);
//   }
//   return useApi2<IUserStatsPredictionTypeResponse[]>(()=>load());
// }
