import { useAdminAutoTradeStockLimit } from "_hooks/useAdminAutoTradeStockLimit";
import { ErrorMessage } from "components/common/ErrorMessage";
import { Spinner } from "components/common/Spinner";
import React, { useContext, useState } from "react";
import { Pencil } from "react-bootstrap-icons";
import { IAutoTradeStockLimit } from "services/AdminApiService";
import { OpenMaxDropdown } from "./OpenMaxDropdown";
import { MessengerContext } from "components/common/messenger";

export const AdminStockLimitList: React.FunctionComponent = () => {


  const { api, update } = useAdminAutoTradeStockLimit();
  const msgrContext = useContext(MessengerContext);

  const [editId, setEditId] = useState<number | undefined>(undefined);
  const [maxOpen, setMaxOpen] = useState<number | null>(null);

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['stockSymbolId', ['Stock Symbol ID', undefined]],
    ['symbolName', ['Symbol Name', undefined]],
    ['quantityOpenMax', ['Open Max', (v: IAutoTradeStockLimit) => v.quantityOpenMax || 'not set']],
  ]);

  const saveHandler = async (item: IAutoTradeStockLimit) => {
    try {
      await update(item, maxOpen || null);
      item.quantityOpenMax = maxOpen || null;
      setMaxOpen(null);
      setEditId(undefined);
    } catch (error: any) {
      console.error(error);
      msgrContext.setMessage({body: `failed: ${error.message}`});
    }
  }


  const render = () => {

    if (api.apiState.isLoading) {
      return <Spinner />
    }

    if (api.apiState.isError) {
      return <ErrorMessage>{api.error}</ErrorMessage>
    }

    if (api.apiState.isLoaded) {

      return (
        <table className="table table-hover" style={{maxWidth: "600px"}}>
          <thead><tr>
            {Array.from(fieldList).map(([fieldName, [title, func]]) => <th key={`col-${fieldName}`}>
              {title}
            </th>)}
            <th>Edit</th>
          </tr>
          </thead>
          <tbody>
            {api.data.map(item =>
              <tr>
                {Array.from(fieldList.entries()).map((field: [string, [string, Function | undefined]], i: number) => {
                  const [fieldName, [, fn]] = field;
                  if ((fieldName ==='quantityOpenMax') && editId===item.stockSymbolId) {
                    return <div className="d-flex gap-2">
                        <OpenMaxDropdown value={item.quantityOpenMax} setValue={setMaxOpen} />
                        <button className="btn btn-primary" onClick={()=>saveHandler(item)}>Save</button>
                        <button className="btn btn-secondary" onClick={()=>setEditId(undefined)}>Cancel</button>
                      </div>
                  }
                  if (typeof fn === 'function') {
                    return <td key={`${fieldName}-${i}`}>{fn(item)}</td>;
                  }
                  return <td key={`${fieldName}-${i}`}>{Object(item)[fieldName]}</td>;
                })}

                <td><div role="button" onClick={()=>setEditId(item.stockSymbolId)}><Pencil /></div></td>
              </tr>
            )}
          </tbody>
        </table>

      );
    }

    return <></>


  }



  return (
    <>
      <div>
        <div className="page-title">Stock Symbol Limits</div>

        {render()}
      </div>
    </>
  );
};


