export enum BrokerTransactionTypeEnum {
    // for option
    BUY_TO_OPEN      = 1,
    BUY_TO_CLOSE     = 2,
    SELL_TO_OPEN     = 3,
    SELL_TO_CLOSE    = 4,
}

const Trans = BrokerTransactionTypeEnum;
export class BrokerTransactionTypeHelper {

    public static brokerTransactionTypeNames: Map<BrokerTransactionTypeEnum, string> = new Map<BrokerTransactionTypeEnum, string>([
       [BrokerTransactionTypeEnum.BUY_TO_OPEN, 'Buy To Open'],
       [BrokerTransactionTypeEnum.BUY_TO_CLOSE, 'Buy To Close'],
       [BrokerTransactionTypeEnum.SELL_TO_OPEN, 'Sell To Open'],
       [BrokerTransactionTypeEnum.SELL_TO_CLOSE, 'Sell To Close'],
    ])

    public static brokerTransactionTypeShortNames: Map<BrokerTransactionTypeEnum, string> = new Map<BrokerTransactionTypeEnum, string>([
        [BrokerTransactionTypeEnum.BUY_TO_OPEN, 'BTO'],
        [BrokerTransactionTypeEnum.BUY_TO_CLOSE, 'BTC'],
        [BrokerTransactionTypeEnum.SELL_TO_OPEN, 'STO'],
        [BrokerTransactionTypeEnum.SELL_TO_CLOSE, 'STC'],
    ])

    protected static combinations = new Map<BrokerTransactionTypeEnum, BrokerTransactionTypeEnum[]>([
        [Trans.BUY_TO_OPEN, [Trans.BUY_TO_OPEN, Trans.SELL_TO_CLOSE]],
        [Trans.BUY_TO_CLOSE, [Trans.BUY_TO_OPEN, Trans.BUY_TO_CLOSE,Trans.SELL_TO_OPEN]],
        [Trans.SELL_TO_OPEN, [Trans.BUY_TO_CLOSE, Trans.SELL_TO_OPEN]],
        [Trans.SELL_TO_CLOSE, [Trans.BUY_TO_OPEN, Trans.SELL_TO_OPEN, Trans.SELL_TO_CLOSE]],
    ]);

    public static isClose(typeId: BrokerTransactionTypeEnum) {
        return [
            BrokerTransactionTypeEnum.BUY_TO_CLOSE,
            BrokerTransactionTypeEnum.SELL_TO_CLOSE,
        ].includes(typeId);
    }

    public static isOpen(typeId: BrokerTransactionTypeEnum) {
        return !this.isClose(typeId);
    }

    public static getName(b: BrokerTransactionTypeEnum) {
        return this.brokerTransactionTypeNames.get(b);
    }

    public static getShortName(b: BrokerTransactionTypeEnum) {
        return this.brokerTransactionTypeShortNames.get(b);
    }

    public static isBuy(typeId: BrokerTransactionTypeEnum) {
        return [
            BrokerTransactionTypeEnum.BUY_TO_CLOSE,
            BrokerTransactionTypeEnum.BUY_TO_OPEN,
        ].includes(typeId);
    }

    public static isSell(typeId: BrokerTransactionTypeEnum) {
        return !BrokerTransactionTypeHelper.isBuy(typeId);
    }

    public static validCombinations(forType: BrokerTransactionTypeEnum) {
        return this.combinations.get(forType) as BrokerTransactionTypeEnum[];
    }

    public static isValidSequence(prev: BrokerTransactionTypeEnum, next: BrokerTransactionTypeEnum) {
        return this.validCombinations(prev).includes(next);
    }
}
