import React, { useContext, useState } from "react";
import { ListContainer } from "components/common";
import { IContest } from "interfaces/IContest";
import { AdminLeagueTeamForm } from "./Form";
import { adminLeagueTeamApiService } from "services/AdminApiService";
import { FormButtons, Url } from "_constants";
import { IDataField } from "interfaces";
import { MessengerContext, Severity } from "components/common/messenger";
import { AdminModalLogoUpload } from "components/admin/contests/ModalLogoUpload";
import { ITeam } from "interfaces/league";
import { Link } from "react-router-dom";

export const AdminLeagueTeamList: React.FunctionComponent = () => {
  const emptyValues: ITeam = {
    name: '',
    description: '',
  } as ITeam

  const [league, setLeague] = useState<ITeam | undefined>(undefined);
  const [showLogoForm, setShowLogoForm] = useState<boolean>(false);

  const msgrContext = useContext(MessengerContext);
  const editableFields: IDataField[] = [
    { name: 'id', title: 'ID', editable: false },
    { name: 'name', title: 'Name', editable: true },
    { name: 'description', title: 'Description', editable: true },
    { name: 'ownerId', title: 'Owner ID', editable: true },
    { name: 'statusId', title: 'Status ID', editable: true },
    { name: 'leagueId', title: 'Leage ID', editable: false },
  ];

  const uploadLogo = (row: ITeam) => {
    return <button className="btn btn-secondary" type="button" onClick={() => { setLeague(row); setShowLogoForm(true) }}>
      Upload Logo
    </button>
  }

  const getInvitationUrl = (row: ITeam) => {
    const url = Url.USER_TEAM_INVITATION.replace(':teamId', row.id.toString());
    const path = `${window.location.protocol}//${window.location.hostname}${url}`;
    return <div>{path}</div>;
  }
  
  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['name', ['Name', undefined]],
    ['description', ['Description', undefined]],
    ['leagueId', ['League', undefined]],
    ['ownerId', ['Owner User ID', undefined]],
    ['invitationUrl', ['Invitation URL', getInvitationUrl]],
    ['logo', ['Upload Logo', uploadLogo]],
  ]);

  const onSaveLogo = async (url: string) => {
    const payload: ITeam = {
      logo: url
    } as ITeam;

    try {
      if (!league) {
        throw Error('Unexpected error. Missing League');
      }
      adminLeagueTeamApiService.update(payload, league.id);
      setShowLogoForm(false)
    } catch (error: any) {
      console.error(error);
      msgrContext.setMessage({ body: `${error.message}` }, true, Severity.FATAL);
    }
  }

  return (
    <>
      <AdminModalLogoUpload handleClose={()=>setShowLogoForm(false)} show={showLogoForm} onSave={onSaveLogo} />
      <ListContainer
        title="Teams"
        apiService={adminLeagueTeamApiService}
        emptyValues={emptyValues}
        fieldList={fieldList}
        buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
        editableFields={editableFields}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }) => {
          return (
            <AdminLeagueTeamForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick} editableFields={editableFields} />
          );
        }}
      </ListContainer>
    </>
  );
};


