import {StockDirectionType as DirectionType, IPredictionAdmin, PointUpDown, SignalPatternInputPred, SignalPrice} from "../../interface";
import {HelperSignal} from "./helper.signal";
import {ValidationError} from "../../error";

export class PredictionSignal {
    public static calcSignalsConsensusNPredMmin(
            input: SignalPatternInputPred,
    ) {
        const items = input.predictions
        const currPos = input.currPos;
        const duration = input.options.durationSecs;
        const minPreds = input.options.minCount;
        if (duration==null || minPreds==null) {
            throw new ValidationError('empty duration or count');
        }
        let count = 0;
        let sum = 0;
        let currPredDir = 0;
        const p = items[currPos];
        for (let y = currPos; y >= 0; y--) {
            const py = items[y];
            if (py.valueTime<p.valueTime-duration) {
                break;
            }
            const stockVal = py.valueStock;
            if (stockVal) {
                const predDir = HelperSignal.direction(py.value, stockVal);
                count++;
                sum+=predDir;
                if (currPos===y) {
                    currPredDir = predDir;
                }
            }
        }
        if (count>=minPreds && sum!==0 && currPredDir===sum/Math.abs(sum)) {
            return {
                price: items[currPos].valueStock,
                dir: sum < 0 ? DirectionType.DOWN : DirectionType.UP,
            } as SignalPrice;
        }
        return null;
    }
}
