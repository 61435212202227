import React from "react";
import { Formik, Form } from "formik";
import { FieldWithError } from "components/common";
import { ShowHidePassword } from "./ShowHidePassword";
import { useNavigate } from "react-router-dom";
import { Url } from "_constants";

const LoginForm: React.FunctionComponent<{
  initialFormState: any;
  validationSchema: any;
  onSubmit: any;
  onClickRegister?: any;
  passwordResetUrl?: string;
  onClickForgot?: ()=>void;

}> = ({ initialFormState, validationSchema, onSubmit, onClickRegister, passwordResetUrl, onClickForgot }) => {

  const navigate = useNavigate();

  const _onClickRegister = () => {
    if (onClickRegister) {
      onClickRegister();
      return;
    }

    navigate(Url.USER_REGISTRATION);
  }

  return (
    <Formik initialValues={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, status, touched, isSubmitting }) => {
        return (
          <Form className="form d-flex flex-column align-items-start">

            <div className="mt-3">
              <FieldWithError
                errors={errors}
                touched={touched}
                fieldName="email"
                label="Email Address"
                placeholder="Enter E-Mail Address"
              />
            </div>

            <div className="mt-3">
              <ShowHidePassword
                errors={errors}
                touched={touched}
                fieldName="password"
                placeholder="Enter Password"
                label="Password"
                readOnly={false}
              />
            </div>

            <div className="text-center">
                <div role="button" className="text-light-purple" onClick={onClickForgot}>Forgot password?</div>
              </div>

            <div className="mt-3 w-100">
              <button disabled={isSubmitting} type="submit" className="btn btn-secondary btn-login-form">Login</button>
            </div>

            <div className="d-flex flex-row justify-content-start align-items-center gap-1 my-3">
              <div>Or, </div><div role="button" className="text-white text-decoration-underline" onClick={_onClickRegister}>Create a free account!</div>
            </div>

            {status && <div className={"alert alert-danger"}>{status}</div>}
            {isSubmitting && <div className="spinner-border"></div>}
          </Form>
        );
      }}
    </Formik>
  );
};

export { LoginForm };
