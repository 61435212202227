import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { usePublicProfile } from '_hooks/usePublicProfile'
import { userPredictionApiService } from 'services/UserPredictionApiService'
import { AvatarLink } from '../dashboard/UserInfo'
import { AvatarSizeEnum } from 'components/common/Avatar'
import { RoundBackButton } from 'components/common/buttons/RoundBackButton'
import { StatsDateDropDownFilter, StatsDateFilterEnum } from './StatsDateDropDownFilter'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { Spinner } from 'react-bootstrap'
import { UserStatsHelper } from '_utils/UserStatsHelper'
import { Helmet } from 'react-helmet';

export const UserStatsPage = () => {

  const { userId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const timeRange = queryParams.get("df");
  const navigate = useNavigate();

  const initialDateFilter: StatsDateFilterEnum = timeRange ? parseInt(timeRange) as StatsDateFilterEnum : StatsDateFilterEnum.LAST_30;
  const dateRange = UserStatsHelper.getDateRange(initialDateFilter);

  const profile = usePublicProfile(parseInt(userId || "0"), userPredictionApiService, true);
  const [dateFilter, setDateFilter] = useState<StatsDateFilterEnum>(initialDateFilter);
  const [startTime, setStartTime] = useState<number | undefined>(dateRange.startDate ? dateRange.startDate.getTimeSec() : undefined)
  const [endTime, setEndTime] = useState<number | undefined>(dateRange.endDate ? dateRange.endDate.getTimeSec() : undefined)

  useEffect(() => {
    const dateRange = UserStatsHelper.getDateRange(dateFilter);
    setStartTime(dateRange.startDate ? dateRange.startDate.getTimeSec() : undefined);
    setEndTime(dateRange.endDate ? dateRange.endDate.getTimeSec() : undefined);
  }, [dateFilter])

  if (profile.error) {
    return <ErrorMessage className="my-3">Unexpected error: {profile.error.message}</ErrorMessage>
  }

  if (!profile.loaded) {
    return <Spinner />
  }

  if (profile.loaded) {
    return (
      <>
      <Helmet>
        <title>Predictagram: Stats - {profile.item.username} </title>
      </Helmet>

      <div className="user-stats mx-3 mb-5">
        {/* <div className="mb-3"><RoundBackButton onClick={() => navigate(UrlHelper.getProfileUrl(profile.item.userId || 0))} /></div> */}
        <div className="mb-3"><RoundBackButton onClick={() => navigate(-1)} /></div>

        {profile &&
          <div className="d-flex justify-content-between align-items-center flex-wrap my-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
                <div className="user-stats-avatar">
                  <AvatarLink profile={profile.item} size={AvatarSizeEnum.MEDIUM} />
                </div>
                <div className="fw-bold text-18">{profile.item.username}'s stats</div>
              </div>
            </div>
            <div className="user-stats-date-dropdown"><StatsDateDropDownFilter initialValue={dateFilter} setValue={setDateFilter} /></div>
          </div>
        }

        {/* <div className="d-flex justify-content-between align-items-center my-3">
          <div className="text-18 fw-bold d-flex justify-content-center gap-1 text-center w-100"><div className="border-bottom py-2 px-3"><LightningChargeFill /> Predictions</div></div>
          <div className="text-18 fw-bold d-flex justify-content-center gap-1 text-center w-100"><TradesIcon /> Trades</div>
        </div> */}

        <div className="mt-4">
          <div className="d-flex text-14 justify-content-start gap-1">
            {/* {startTime && <div>{NewYorkTz.format(new Date(startTime * 1000)).monthNameDateTime()}</div>}
            through
            {endTime ? <div>{NewYorkTz.format(new Date(endTime * 1000)).monthNameDateTime()}</div> : ' Today'} */}
          </div>
        <Outlet context={{ profile: profile.item, startTime, endTime, userId }} />
        </div>

      </div>
      </>
    )
  }

  return <>Unknown error</>

}
