import { config } from "config";
import { AuthenticationService, userAuthenticationService } from "./AuthenticationService";
import { AbortableHttpService } from "./AbortableHttpService";

export class SignalsApiServiceError extends Error {}


export interface IUserSignalAlert {
  id: number,
  symbolName: string,
  directionType: UserSignalAlertDirectionType,
  stockTime: number,
  winRateLat30: number,
  profitRateLast30: number,
  stockPrice: number,
}

export interface IUserSignalAlertSearch {
  startTime?: number,
  endTime?: number,
  symbolName?: string,
}

export interface ISignalPlan {
  id: number,
  name: string,
  description: string,
  price: number,
  priceOld: number,
}

export enum UserSignalAlertDirectionType {
  DOWN = -1,
  UP =  1,
}

export interface ISignalSettings {
  id: number,
  symbolId: number,
  stockSymbol: string,
  signalEnable: boolean,
  signalSendSms: boolean,
  signalSendEmail: boolean,
  signalSendNotification: boolean
}

export class UserSignalApiService {
  constructor(
    protected apiBaseUrl:string, 
    protected authenticationService: AuthenticationService,
    protected httpService: AbortableHttpService
  ) { }


  async getSignalAlerts(filters?: IUserSignalAlertSearch, abortSignal?: AbortSignal): Promise<IUserSignalAlert[]> {
    const url = `${this.apiBaseUrl}/management/signal/alerts`;
    return await this.httpService.httpPost<IUserSignalAlert[]>(url, filters ? {filters} : {}, abortSignal, false);
  }

  async getSignalPlans(abortSignal?: AbortSignal): Promise<ISignalPlan[]> {
    const url = `${this.apiBaseUrl}/signal/plans`;
    return await this.httpService.httpPost<ISignalPlan[]>(url, {}, abortSignal, false);
  }

  async getSignalSettings(abortSignal?: AbortSignal): Promise<ISignalSettings[]> {
    const url = `${this.apiBaseUrl}/management/settings/symbols`;
    return await this.httpService.httpPost<ISignalSettings[]>(url, {}, abortSignal, false);
  }

  async setSignalSettings(symbolId: number, signalSetting: Partial<ISignalSettings>, abortSignal?: AbortSignal): Promise<boolean> {
    const url = `${this.apiBaseUrl}/management/settings/symbol/${symbolId}`;
    try {
      const response: any = await this.httpService.put(url, signalSetting, abortSignal, false);
      if (!response.success) {
        throw Error('unexepected error: Setting not saved. API rejected requested.');
      }
      return true;
    } catch (error: any) {
      throw Error(error.message);
    }
  }



}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
export const userSignalApiService: UserSignalApiService = new UserSignalApiService(apiBaseUrl, userAuthenticationService, new AbortableHttpService(userAuthenticationService));

