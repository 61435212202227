import { useApi } from "./useApi";
import { IAutoTrade } from "predictagram-lib";
import { IAutoTradeSearchFilter, adminAutoTradesService } from "services/AdminApiService";

export function useAdminAutoTrades (filter: IAutoTradeSearchFilter) {
  const load = async (filter: IAutoTradeSearchFilter) => {
    return await adminAutoTradesService.getFilteredData(filter);
  }
  return useApi<IAutoTrade[], IAutoTradeSearchFilter>(load, filter);
}

