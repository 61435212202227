import React from 'react';
import { AdminNavigation } from './Navigation';

const Header:React.FunctionComponent = () => {
  return (
    <div className="container-fluid bg-white">
      <AdminNavigation />
    </div>
  );
}

export { Header as AdminHeader }