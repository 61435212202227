import React from 'react'
import { TradeSetup } from './TradeSetup'


export const TradeSetupPage = () => {

  return (
    <TradeSetup />
  )
}
