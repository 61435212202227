import {IParsedOption, OptionPattern} from "./OptionPattern";
import {OptionType} from "predictagram-lib";
import {TradegramDateHelper} from "./TradegramDateHelper";

export class OptionPatternOsi extends OptionPattern {

  protected pattern = /^([A-Za-z]+)(\d{6})([CPcp])(\d{8})$/; // NVDA231215C00500000

  parse(optionName: string) {
    const match = optionName.match(this.pattern);
    if (!match) {
      throw new Error('Invalid option contract name format');
    }
    const symbol = match[1];
    const dateString = match[2]; // yymmdd 
    const optionType = match[3].toUpperCase() === 'C' ? OptionType.CALL : OptionType.PUT;
    const price = (parseFloat(match[4]) / 1000).toFixed(3);

    const parsedOption: IParsedOption = {
      symbol,
      date: TradegramDateHelper.convertYYMMDDToDate(dateString).getTime() / 1000,
      optionType,
      price: parseFloat(price)
    }
    return parsedOption;
  }
}
