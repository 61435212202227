import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { userContestApiService } from 'services/common/ContestApiService';

export const ContestJoinPage: React.FunctionComponent = () => {

  const [ message, setMessage ] = useState<string>('Joining contest...');
  
  const params = useParams();
  const { contestId } = params;

  useEffect(()=>{
    const _load = async () => {
        try {
          await userContestApiService.joinContest(parseInt(contestId || '0'));
          setMessage('Thank you for joining the contest!');
        } catch (error: any) {
          setMessage(`Unexepcted Error. ${error.message}`);
        } 
    }
    _load();
  }, [contestId])


  return(
    <div>
      {message}
    </div>
  );
}