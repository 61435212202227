import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { validationMap, Validator } from 'schemas';
import { AccountSetup } from './subforms';
import { AccountContext } from 'components/common';
import { IAccount } from 'interfaces';
import { MessengerContext, Severity } from 'components/common/messenger';
import { MessageHelper } from '_utils/MessageHelper';
import { useReferral } from '_hooks/useReferral';
import ReCAPTCHA from 'react-google-recaptcha';
import { config } from 'config';


const ThankYou = () => {
  return (
    <div className="container mt-3">
      <p>Thank you for registering!</p>
      <p>Click <a href="/user/profile">here</a> to manage your profile.</p>
    </div>
  );
}


export enum RegistrationStep {
  ENTER_REG_DATA = 1,
  RECAPTCHA = 2,
  DONE = 3
}

export const RegistrationForm: React.FunctionComponent<{ onSuccess: () => void }> = ({ onSuccess }) => {

  // const initialValues = {
  //   email: 'roy@test.com',
  //   password: 'h3@vyL1ftinG',
  //   confirmPassword: 'h3@vyL1ftinG',
  //   agreeTerms: false,
  // }

  const initialValues = {
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    agreeTerms: false,
    ctoken: '',
  }

  const referral = useReferral();
  const acctContext = useContext(AccountContext)
  const msgrContext = useContext(MessengerContext);
  const [step, setStep ] = useState<RegistrationStep>(RegistrationStep.ENTER_REG_DATA);

  const onSubmit = async (data: any, actions: any) => {

    if (step === RegistrationStep.ENTER_REG_DATA) {
      setStep(RegistrationStep.RECAPTCHA);
      return;
    }

    const { email, password, username, ctoken } = data;
    const { setSubmitting } = actions;

    const acct: IAccount = {
      email,
      password,
      nickname: username,
      username,
    }

    try {
      await acctContext?.register(acct, ctoken, referral);
      onSuccess();
    } catch (error: any) {
      setSubmitting(false);
      console.error((error as Error).message);
      const msg = MessageHelper.translate((error as Error).message);
      msgrContext.setMessage({body: msg}, true, Severity.NORMAL);
      setStep(RegistrationStep.ENTER_REG_DATA);
    }
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize valid onSubmit={onSubmit} validationSchema={validationMap.get(Validator.SIMPLE_REGISTRATION)}>
      {({ errors, touched, isSubmitting, values, status, setFieldValue }) => {
        return (
          <Form>

            {step === RegistrationStep.ENTER_REG_DATA &&
              <>
              <AccountSetup errors={errors} touched={touched} title={<></>} />
              <button className="btn btn-secondary w-100" type="submit">Sign up</button>
              </>
            }

            {step === RegistrationStep.RECAPTCHA &&
              <>
                <div className="mt-3 d-flex flex-column justify-content-center align-items-center" style={{minHeight: "300px"}}>
                  <ReCAPTCHA theme="dark" sitekey={config.recaptchaSiteKey} onChange={(token: string | null) => setFieldValue('ctoken', token)} />
                </div>

                <div className="d-flex flex-row justify-content-center my-3">
                  <button disabled={isSubmitting} type="submit" className="btn btn-secondary w-100">Create Account</button>
                  {isSubmitting && <div className="spinner-border"></div>}
                </div>
              </>
            }

          </Form>
        );
      }}
    </Formik>
  );
}

export const FullRegistration: React.FunctionComponent = () => {
  enum STAGE {
    ENTER_DATA = 1,
    DONE = 2,
  }

  const [stage, setStage] = useState<STAGE>(STAGE.ENTER_DATA);

  useEffect(() => { }, [stage])


  return (
    <div className="register-email">
      {stage === STAGE.ENTER_DATA &&
        <RegistrationForm onSuccess={() => setStage(STAGE.DONE)} />
      }

      {stage === STAGE.DONE &&
        <ThankYou />
      }

    </div>
  );
}

