import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const SurveyTerms = () => {
  return (
    <div className="my-3 mb-5">

      <Link to="/"><img src="/images/purple-logo.png" alt='Predictagram' style={{ maxWidth: "200px" }} /></Link>
      <div className="container">
        <h2 className="my-3 fw-bold text-18">Survey Terms of Service</h2>
        <p><div className="fw-bold mt-3 mb-1">Informational Purposes Only</div> This survey is for informational purposes only and does not constitute a solicitation for investment or an offer to sell securities. Participation in the survey does not guarantee an opportunity to invest in Predictagram or its crowdfunding offerings.</p>
        <p><div className="fw-bold mt-3 mb-1">No Guarantee of Investment Opportunity</div> Completion of the survey does not guarantee respondents an opportunity to invest in Predictagram through crowdfunding or any other means. Investment opportunities are subject to regulatory requirements, due diligence, and other factors.</p>
        <p><div className="fw-bold mt-3 mb-1">Not Financial, Investment, or Legal Advice</div> The information provided in this survey does not constitute financial, investment, or legal advice. Participants are encouraged to consult with qualified professionals before making any investment decisions.</p>
        <p><div className="fw-bold mt-3 mb-1">Risk Disclosure</div> Investing in startups or early-stage companies involves significant risks, including the potential for loss of investment capital, lack of liquidity, and uncertainty of returns. Please consider these risks carefully before making any investment decisions.</p>
        <p><div className="fw-bold mt-3 mb-1">Confidentiality</div> Survey responses will be kept confidential and used solely for the purpose of gauging interest in potential crowdfunding opportunities with Predictagram. Your data will be collected, stored, and protected in accordance with applicable privacy laws.</p>
        <p><div className="fw-bold mt-3 mb-1">Forward-Looking Statements</div> Any statements or projections about Predictagram's future performance, prospects, or financial outcomes included in the survey may be forward-looking and inherently speculative in nature.</p>
        <p><div className="fw-bold mt-3 mb-1">Regulatory Compliance</div> Predictagram will comply with all applicable securities laws and regulations governing crowdfunding offerings, including investor accreditation requirements and disclosure obligations.</p>
        <p><div className="fw-bold mt-3 mb-1">No Endorsement</div> Participation in the survey does not imply endorsement or approval of Predictagram or its crowdfunding efforts by any regulatory authority, financial institution, or third party.</p>
        <p><div className="fw-bold mt-3 mb-1">Disclaimer of Liability</div> Predictagram disclaims liability for any inaccuracies, errors, or omissions in the survey or its contents. Participants are advised to use their own judgment and discretion when interpreting survey results and making investment decisions.</p>
        <p><div className="fw-bold mt-3 mb-1">Modification Rights</div> Predictagram reserves the right to modify or terminate the survey at any time, for any reason, without prior notice to participants. Survey responses may be used to inform decision-making but do not create any obligations on the part of Predictagram.</p>
        <p>By continuing with this survey, you acknowledge that you have read, understood, and agree to the terms of this disclaimer.    </p>
      </div>
    </div>
  );
}

export const SurveyTermsPage: React.FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>Predictagram: Survey Terms of Service</title>
      </Helmet>
      <div className="container-sm">
        <SurveyTerms />
      </div>
    </>
  )
}
