import React, { useEffect, useReducer } from 'react';
import { IAccount, ICredentials, IAccountContext } from 'interfaces';
import { AccountContext } from './AccountContext';

import { AuthenticationService, AccountService } from 'services';
import { useUniqueUserId } from '_hooks/useUniqueUserId';
import { IReferralParams } from '_hooks/useReferral';
import { PredictionCountHelper } from '_utils/PredictionCountHelper';
import { useUtmSource } from '_hooks/useUtmSource';
import { PixelHelper } from '_utils/PixelHelper';
import { LastNotificationHelper } from '_utils/LastNotificationHelper';

enum AccountAction {
  LOGIN = 1,
  LOGOUT = 2,
  UPDATE = 3,
  CHANGE_PASSWORD = 4,
  REGISTER = 5,
  RESET_PASSWORD = 6,
  CONFIRM_EMAIL = 7,
  REGISTER_PHONE = 8,
  VERIFY_MOBILE = 9,
  CHECK_REGISTERED_PHONE_NUMBER = 10,
  DELETE_ACCOUNT = 11,
  GET_SETTINGS = 12,
  REFRESH_LOCAL = 13,
  NEW_NOTIFICATION = 14,
  ERROR = 99,
}

interface IAccountState {
  account: IAccount | null;
  error: any;
}

interface IAccountAction {
  type: AccountAction,
  payload?: any,
}

const accountReducer = (state: IAccountState, action: IAccountAction): any => {

  switch(action.type) {
    case AccountAction.LOGIN: {
      const result = {
        error: null,
        account: action.payload.account,
      };
      //console.log('dispatched login with result ', result);
      return result;
    }

    case AccountAction.REFRESH_LOCAL: {
      return {
        error: null,
        account: action.payload.account,
      }
    }

    case AccountAction.LOGOUT: {
      return {
        error: null,
        account: null,
      }
    }

    case AccountAction.ERROR: {
      return {
        account: state.account,
        error: action.payload.error,
      }
    }

   case AccountAction.UPDATE: {
      return {
        error: null,
        account: action.payload.account,
      }
    }

    case AccountAction.REGISTER: {
      return {
        error: null,
        account: action.payload.account,
      }
    }

    case AccountAction.REGISTER_PHONE: {
      return {
        error: null,
        account: state.account,
      }
    }

    case AccountAction.VERIFY_MOBILE: {
      return {
        error: null,
        account: action.payload.account,
      }
    }

    case AccountAction.CHECK_REGISTERED_PHONE_NUMBER: {
      return {
        error: null,
        account: state.account,
      }
    }

    case AccountAction.GET_SETTINGS: {
      const settings = action.payload.settings;
      return {
        error: null,
        account: {
          ...state.account,
          ...settings,
        }
      }
    }

    case AccountAction.DELETE_ACCOUNT: {
      return {
        error: null,
        account: null,
      }
    }

    case AccountAction.NEW_NOTIFICATION: {
      return {
        error: null,
        account: {
          ...state.account,
          hasNewNotification: !action.payload.seen,
        }
      }
    }

    default:
      throw new Error(`Invalid Action Type: ${action.type}`);
  }

}

const AccountProvider: React.FunctionComponent<{authService: AuthenticationService, accountService: AccountService, disableNotification?: boolean, children: any}> = ({authService, accountService, disableNotification=false,children}) => {

  const defaultAccountState: IAccountState = {
    account: authService.getLocalAccount() || null,
    error: null,
  }
  
  const [ accountState, dispatchAccountAction ] = useReducer( accountReducer, defaultAccountState);
  const uniqueUserId = useUniqueUserId();
  const utmSource = useUtmSource();

  useEffect(()=> {
    let intervalId: NodeJS.Timeout;

    if (!disableNotification) {
      const _updateInterval = async () => {
        await setLastNotificationHandler();
        intervalId = setInterval(async ()=>{
          await setLastNotificationHandler();
        }, 3 * 60_000); // check every 3 minutes
      }
      if (accountState.account && accountState.account !== null) {
        _updateInterval();
      }
    }
    return ()=>clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  


  const loginHandler = async (credentials: ICredentials) => {
    try {
      await authService.login(credentials);
      const account: any = await authService.getLocalAccount();
      PredictionCountHelper.clear(localStorage);
      dispatchAccountAction({type: AccountAction.LOGIN, payload: {account}});
      return account;
    } catch (error: any) {
      console.error('in loginhandler error', error);
      // dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }


  const logoutHandler = (callback?: () => void ) => {
    authService.logout();
    dispatchAccountAction({type: AccountAction.LOGOUT, payload: {}});
    if (callback) {
      callback();
    }
  }

  const updateHandler = async (account: IAccount) => {
    try {
      //console.log('updating with', {account});
      await accountService.updateAccount(account);
      const updatedAccount: IAccount | null = await authService.getLocalAccount();
      if (updatedAccount !== null) {
        //console.log('payload', {updatedAccount});
        dispatchAccountAction({type: AccountAction.UPDATE, payload: {account: updatedAccount}});
        return updatedAccount;
      } else {
        throw Error('could not get account after update');
      }
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const registerHandler = async (account: IAccount, ctoken: string | null, referral?: IReferralParams) => {
    try {
      const acct = await accountService.register(account, ctoken, referral, utmSource);
      PixelHelper.registered(utmSource, acct);
      dispatchAccountAction({type: AccountAction.REGISTER, payload: {account: acct as IAccount}});
      return acct as IAccount;
    } catch (error: any) {
      throw Error(error);
    }
  }


  const changePasswordHandler = async () => {
  }
  
  const confirmEmailHandler = async () => {

  }

  const resetPasswordHandler = async () => {
    try {
      
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const registerPhoneNumberHandler = async () => {
    try {
      const response = await accountService.registerPhoneNumber();
      if (response === false) {
        throw Error("We're sorry, but our service provider was unable to send a code to this phone number. Please contact us at support@predictagram.com and request to verify your phone number.")
      }
      dispatchAccountAction({type: AccountAction.REGISTER_PHONE, payload: {}})
      return response;
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const checkRegisteredPhoneNumberHandler = async (phoneNumber: number, phoneNumberCode: string) => {
    try {
      const success: boolean = await accountService.checkRegisteredPhoneNumber(phoneNumber, phoneNumberCode, uniqueUserId);
      if (!success) {
        throw Error('Could not verify the code. Please check again.');
      }
      dispatchAccountAction({type: AccountAction.CHECK_REGISTERED_PHONE_NUMBER, payload: {}})
      return success;
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }  

  const verifyMobileHandler = async (phoneNumber: number, code: string) => {
    try {
      const response = await accountService.verifyMobile(phoneNumber, code);
      //console.log('verifyMobileHandler response', {response});
      const updatedAccount = await accountService.getAccount();
      dispatchAccountAction({type: AccountAction.VERIFY_MOBILE, payload: {account: updatedAccount}})
      return response;
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const isLoggedInHandler = (): boolean => {
    return accountState.account !== null;
  }

  const deleteHandler = async (password: string, callback?: ()=>void) => {
    try {
      await accountService.deleteAccount({password});
      authService.logout();
      dispatchAccountAction({type: AccountAction.DELETE_ACCOUNT, payload: {account: null}})
      if (callback) callback();
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const getSettingsHandler = async () => {
    try {
      const settings = await accountService.getSettings();
      dispatchAccountAction({type: AccountAction.GET_SETTINGS, payload: {
        settings
      }})
      return settings;
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const refreshAccountHandler = async () => {
    try {
      const account = await accountService.refreshLocalAccount();
      dispatchAccountAction({type: AccountAction.REFRESH_LOCAL, payload: {
        account
      }})
      return account;
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const setLastNotificationHandler = async () => {
    try {
      const lastNotification = await accountService.getLastNotification();
      const hasNew = LastNotificationHelper.isNew(lastNotification, localStorage);
      if (hasNew) {
        LastNotificationHelper.setLast(lastNotification, localStorage);
      }
      dispatchAccountAction({type: AccountAction.NEW_NOTIFICATION, payload: {seen: LastNotificationHelper.isSeen(localStorage)}}); 
    } catch (error: any) {
      console.error(error);
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const setSeenLastHandler = async () => {
    LastNotificationHelper.setSeen(localStorage);
    await setLastNotificationHandler();
  }

  const accountContext: IAccountContext = {
    account: accountState.account,
    login: loginHandler,
    logout: logoutHandler,
    update: updateHandler,
    register: registerHandler,
    changePassword: changePasswordHandler,
    error: accountState.error,
    confirmEmail: confirmEmailHandler,
    resetPassword: resetPasswordHandler,
    //confirmMobile: confirmMobileHandler,
    registerPhoneNumber: registerPhoneNumberHandler,
    checkRegisteredPhoneNumber: checkRegisteredPhoneNumberHandler,
    verifyMobile: verifyMobileHandler,
    isLoggedIn: isLoggedInHandler,
    delete: deleteHandler,
    getSettings: getSettingsHandler,
    refreshAccount: refreshAccountHandler,
    setLastNotification: setLastNotificationHandler,
    setSeenLast: setSeenLastHandler,
  }

  return (
    <AccountContext.Provider value={accountContext}>
      {children}
    </AccountContext.Provider>
  );

}


export { AccountProvider }
