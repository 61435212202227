import React, { useEffect, useRef, useState } from 'react';

export const Retry: React.FunctionComponent<{ onElapsed: () => void }> = ({ onElapsed }) => {

  const [msecs, setMsecs] = useState<number>(120_000);
  const onElapsedRef = useRef(onElapsed);

  useEffect(() => {

    if (msecs <= 0) {
      onElapsedRef.current();
      return;
    }

    const timer = setInterval(() => {
      if (msecs > 0) {
        setMsecs(msecs - 1000);
      }
    }, 1000)

    return () => {
      clearInterval(timer);
    }

  }, [msecs])

  if (msecs > 0) {
    return (
      <div className="retry">
        <div className="d-flex justify-content-center align-items-center gap-2">Code Sent. Retry in <div className="timer">{msecs / 1000}</div></div>
      </div>
    );
  }

  return (
    <div></div>
  );
}