import React, { useContext } from 'react';
import { config } from 'config';
import { IPrediction } from 'interfaces/IPrediction';
import { Url } from '_constants';
import { MessengerContext, Severity } from './messenger';
import { RWebShare } from "react-web-share";
import { AccountContext } from './AccountContext';
import { SearchParams } from '_constants/SearchParams';
import { ShareBase } from './ShareBase';

export const SharePostPrediction: React.FunctionComponent<{ prediction: IPrediction }> = ({ prediction }) => {

  const msgrContext = useContext(MessengerContext);
  const acctContext = useContext(AccountContext);

  const baseUrlPath = `${config.wwwDomain}${Url.PUBLIC_STOCK_DETAIL.replace(':predictionId', prediction.id ? prediction.id.toString() : '')}`;
  const predId = `?${SearchParams.UI_REFERRAL_PREDICTION_ID}=${prediction.id}`;
  const refId = acctContext?.isLoggedIn() ? `&${SearchParams.UI_REFERRAL_USER_ID}=${acctContext.account.id}` : '';
  const url = `${baseUrlPath}${predId}${refId}`;

  const onCopyLink = async () => {
    if (prediction) {
      try {
        await navigator.clipboard.writeText(url);
        msgrContext.setMessage({ body: 'Url was copied to clipboard!' });
      } catch (error: any) {
        msgrContext.setMessage({ body: `Could not copy: ${error.message}` }, true, Severity.FATAL);
        console.error({ error });
      }
    }
  }

  return (
    <ShareBase onCopyLink={onCopyLink} className="my-2" title={<span className="text-14 fw-bold">Share</span>}>
      {({setShow}) => {
        return <RWebShare
          data={{
            text: `Check out this prediction for ${prediction.stockSymbol} for today`,
            url: url,
            title: "Check out my Predictagram",
          }}
          onClick={()=>setShow(false)}
        >
          <div role="button">Share via...</div>
        </RWebShare>
      }
      }
    </ShareBase>
  );
}

