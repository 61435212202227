import React from 'react';

import { PredictionListLazy } from 'components/public/ListingsPage/components/PredictionListLazy';
import { userPredictionApiService } from 'services/UserPredictionApiService';
import { APIState } from '_constants/APIState';
import { Spinner } from 'components/common/Spinner';
import { sortTypeEnum } from 'components/public/ListingsPage/components/Prediction/SortTypeSelect';
import { useFollowedPredictions } from '_hooks/useFollowedPrediction';
import { Helmet } from 'react-helmet';

export const FollowedPredictions: React.FunctionComponent = () => {

  const { items: predictions, apiState, setSort, options } = useFollowedPredictions(userPredictionApiService);

  if (apiState === APIState.LOADING) {
    return <Spinner />
  }

  if (apiState === APIState.LOADED) {
    return (
      <>
      <Helmet>
      <title>Predictagram: Followed Users</title>
      </Helmet>
  
      <div className="container">
        <div className="my-3">
          {predictions &&
            <PredictionListLazy orderBy={options?.orderBy || sortTypeEnum.LATEST} onSelectSort={setSort} predictions={predictions} />
          }
        </div>
      </div>
      </>
    );
  }

  return <></>;

}