import React from 'react'
import { IDropdownFilterItem } from 'components/common/DropDownFilter/DropdownFilter';


export enum PositionStatusEnum {
  ALL = 1,
  OPEN = 2,
  CLOSED = 3,
}

interface IProps {
  filterState: [PositionStatusEnum ,
    React.Dispatch<React.SetStateAction<PositionStatusEnum>>],
}

export type PositionStatusFilterType = IDropdownFilterItem<PositionStatusEnum, PositionStatusEnum>;

export const securityTypeFilterMap: Map<PositionStatusEnum, PositionStatusFilterType> = new Map<PositionStatusEnum, PositionStatusFilterType>([
  [PositionStatusEnum.ALL, { key: PositionStatusEnum.ALL, value: PositionStatusEnum.ALL, label: 'All' }],
  [PositionStatusEnum.OPEN, { key: PositionStatusEnum.OPEN, value: PositionStatusEnum.OPEN, label: 'Open' }],
  [PositionStatusEnum.CLOSED, { key: PositionStatusEnum.CLOSED, value: PositionStatusEnum.CLOSED, label: 'Closed' }],
]
);

export const PositionStatusFilterTabs: React.FunctionComponent<IProps> = ({ filterState }) => {

  const [filter, setFilter] = filterState;
  const items = Array.from(securityTypeFilterMap).map((item) => item[1]);

  const _setFilter = (key: PositionStatusEnum ) => {
    setFilter(key);
  }

  return (
    <div className="dashboard-filter d-flex justify-content-start align-items-center gap-3 gap-md-5">

      {items.map((item, idx) => <div
        role="button"
        key={`dropdown-key-${idx}`}
        className={`d-flex justify-content-center align-items-center dashboard-filter-item ${filter === item.key ? "dashboard-filter-item-selected" : ""}`}
        onClick={() => _setFilter(item.key)}
      >
        <div className="mx-4">{item.label}</div>
      </div>)}
    </div>
  );

}

