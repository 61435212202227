import { config } from "config";
import { AuthenticationService, userAuthenticationService } from "./AuthenticationService";
import { HttpService } from "./HttpService";
import { IAddress } from "interfaces";


export interface ICreditCard {
  cardNumber: number | null,
  expirationDate: number | null,
  cardCode: string | null,
}

export interface IPaymentProfile {
  billTo: IAddress,
  creditCard: ICreditCard,
}


class PaymentService {

  constructor(
    protected apiBaseUrl: string,
    protected authenticationService: AuthenticationService,
    protected httpService: HttpService
  ) {}

  async listPaymentProfile() {
    const url = `${this.apiBaseUrl}/management/payment/profiles`;
    try {
      const result = await httpService.httpPost<any>(url, {}, true);
      return result;
    } catch (error: any) {
      throw new Error(error.message);
    }
  } 

  async submitPaymentProfile(profile: IPaymentProfile) {
    const url = `${this.apiBaseUrl}/management/payment/profile`;
    try {
      const result = await httpService.httpPost<any>(url, profile, true);
      return result;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  // async subscribeToPlan(planId: number) {
  //   const url = `${this.apiBaseUrl}/management/signal/plan/subscribe/${planId}`;
  //   try {
  //     const result = await httpService.httpPost<any>(url, {}, true);
  //     return result;
  //   } catch (error: any) {
  //     throw new Error(error.message);
  //   }
  // }


  // async subscribeAndPay(profile: IPaymentProfile, planId: number) {
  //   const current: any = await this.listPaymentProfile();
  //   try {
  //     if (current.data.length === 0 ) {
  //       await this.submitPaymentProfile(profile);
  //     }
  //     await this.subscribeToPlan(planId);
  //   } catch (error: any) {
  //     throw new Error(error.message)
  //   }
  // }


}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const httpService = new HttpService(userAuthenticationService);
const paymentService = new PaymentService(apiBaseUrl, userAuthenticationService, httpService);

export { paymentService, PaymentService }