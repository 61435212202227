import { useApi } from "./useApi";
import { ISearchOptions, userPredictionApiService } from "services/UserPredictionApiService";
import { sortTypeEnum } from "components/public/ListingsPage/components/Prediction/SortTypeSelect";
import { IPrediction } from "predictagram-lib";

/**
 * 
 * @returns the last prediction of user's followed users
 */
export function useLastFollowedPrediction () {
  const load = async () => {

    const searchOptions: ISearchOptions = {
      orderBy: sortTypeEnum.LATEST,
      limit: 1,
    }
    
    const _load = async () => {
      return await userPredictionApiService.getPredictionsByFollowed (searchOptions);
    }
    return await _load();
  }
  return useApi<IPrediction[], undefined>(load, undefined);
}

