import { config } from "config";
import { ISearchNameResult } from "interfaces/ISearchNameResult";

import { AbortableHttpService } from "./AbortableHttpService";
import { userAuthenticationService } from "./AuthenticationService";

export type SymbolSearchType = {
  symbolName: string,
  symbolDescription: string,
}

class SearchService {

  private userController: AbortController | null  = null;
  private symbolController: AbortController | null = null;

  constructor(
    protected config: { apiUrlBase: string },
    protected httpService: AbortableHttpService,
  ) { }

  async searchUserName(username: string): Promise<ISearchNameResult[]> {

    if (this.userController) {
      this.userController.abort();
    }

    this.userController = new AbortController();

    const url = `${this.config.apiUrlBase}/search/users`;
    const payload = { usernameLike: username };

    try {
      return await this.httpService.httpPost<ISearchNameResult[]>(url, payload, this.userController.signal);
    } catch (error: any) {
      if (error.name === 'AbortError') {
        return Promise.reject();
      }
      throw new Error((error as Error).message);
    }
  }

  cancelUserSearch () {
    if (this.userController) {
      this.userController.abort();
      this.userController = null;
    }
  }

  // async searchStockSymbol(symbolNameLike: string): Promise<SymbolSearchType[]> {

  // }

  //return await this.httpService.getMany<ISearchNameResult>(url, {usernameLike: username});


}

const searchService = new SearchService({apiUrlBase: `${config.apiDomain}${config.apiBasePath}/user`}, new AbortableHttpService(userAuthenticationService));

export { searchService, SearchService }
