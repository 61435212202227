import { AuthenticationService, adminAuthenticationService } from "./AuthenticationService";
import { HttpService } from "./HttpService";
import { config } from 'config';
import { IAdminUserStats, IAdminUserStatsDay } from "predictagram-lib";
import { IUserAchievementUserStatResponse } from "./UserAchievementService";

export interface IAdminContestApiItem {
  contestId: number,
  contestName: string,
  userUsername: string,
  userEmail: string,
  predictionId: number,
  stockSymbolId: number
}

export interface IRegistrationApiItem {
  id?:number,
  userId: number,
  userUsername: string,
  userEmail: string,
  refById: string,
  refByUsername: string,
  refByEmail: string,
  refTypeId: number,
  refTeamId: number,
  refPredictionId: number,
  refContestId: number,
  phoneNumberConfirmed: number,
  createdAt: number,
  ip4: string,
  utmSource: string,
}

export interface IRegistrationApiSearchOptions {
  startTime: number,
  endTime: number,
  limit?: number,
}

// @TODO: maybe move to lib too?
export interface IAdminUserStatsApiSearchOptions {
  startTime: number,
  endTime: number,
  orderBy: object,
  limit?: number,
  userId?: number,
  usernameLike?: string,
}

export interface IUserStreakStat extends IUserAchievementUserStatResponse {
  averageScoreUser: number,
}

export interface IStreakStatSearchOptions {
  startTime?: number,
  endTime?: number
}

class AdminApiReportService  {

  constructor(
    protected authenticationService: AuthenticationService,
    protected baseUrl: string,
    protected httpService: HttpService
  ) {}

  async getContestants(contestId: number): Promise<IAdminContestApiItem[]> {
    const url = `${this.baseUrl}/contestants`;
    const payload = {filters: {contestId} };
    return await this.httpService.getMany<IAdminContestApiItem>(url, payload, this.authenticationService);
  }

  async getRegistrations(options: IRegistrationApiSearchOptions): Promise<IRegistrationApiItem[]> {
    const url = `${this.baseUrl}/registrations`;
    const payload = {filters: options};
    return await this.httpService.getMany<IRegistrationApiItem>(url, payload, this.authenticationService);
  }  

  async getUsersStats(options: IAdminUserStatsApiSearchOptions): Promise<IAdminUserStats[]> {
    const url = `${this.baseUrl}/users`;
    const payload = {filters: options};
    return await this.httpService.getMany(url, payload, this.authenticationService);
  }

  async getUserStatsDay(userId:number, options: IAdminUserStatsApiSearchOptions): Promise<IAdminUserStatsDay[]> {
    const url = `${this.baseUrl}/user/day/${userId}`;
    const payload = {filters: options};
    return await this.httpService.getMany(url, payload, this.authenticationService);
  }

  async getUserStreakStats(options?: IStreakStatSearchOptions): Promise<IUserStreakStat[]> {
    const url = `${this.baseUrl}/users-streak-stats`;
    const payload = {filters: options};
    return await this.httpService.getMany(url, payload, this.authenticationService);
  }
}

const basePath = `${config.apiDomain}${config.apiBasePath}/admin/report`;
const adminApiReportService: AdminApiReportService = new AdminApiReportService(adminAuthenticationService, basePath, new HttpService(adminAuthenticationService));
export {
  adminApiReportService,
  AdminApiReportService
}
