import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";

export const useUtmSource = (): string | undefined => {
  const COOKIE_NAME = 'utmSource';
  const PARAM_NAME = 'utm_source';
  const [ searchParams ] = useSearchParams();
  const utmSource = searchParams.get(PARAM_NAME) || ''; 

  if (utmSource) {
    Cookies.set(COOKIE_NAME, utmSource, { expires: 1 });
    return utmSource;
  }
  return Cookies.get(COOKIE_NAME);
}