import { StorageKey } from "_constants";
import { NewYorkTz } from "predictagram-lib";

export class LeagueHelper  {

  static showLeaguePriceCard(cacheValue: string | null, currentDate: Date): boolean {
    const deadline = new Date('04/01/24 23:59:59 EDT').getTime();
    if (currentDate.getTime() > deadline) {
      return false;
    }

    if  (cacheValue === null)  {
      return true;
    }

    // cachevalue expected to have the timestemp in ms
    const reappearDateMs = new Date('04/01/24 00:00:00 EDT').getTime();
    const cacheMs = parseInt(cacheValue);
    // cached on same day as reappar, do not show
    if (NewYorkTz.isSameDay(new Date(cacheMs), new Date(reappearDateMs))) {
      return false;
    }
    // cached before, not yet reappear - do not show
    if (cacheMs < reappearDateMs && currentDate.getTime() < reappearDateMs) {
      return false;
    }
    return true;
  }

  static showDisclaimer():boolean {
    const s = localStorage.getItem(StorageKey.SEEN_LEAGUE_DISCLAIMER);
    return s === null;
  }
}