import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { MessengerProvider } from 'components/common/messenger';
import { AccountProvider } from 'components/common/AccountProvider';
import { userAccountService } from 'services/AccountService';
import { userAuthenticationService } from 'services/AuthenticationService';
import { FolloweeProvider } from 'components/common/FolloweeProvider';
import { SymbolsProvider } from 'components/common/SymbolsProvider';
import { predictionService } from 'services';
import { PredictionProvider } from 'components/common/PredictionProvider';
import { PixelHelper } from '_utils/PixelHelper';
import { useUtmSource } from '_hooks/useUtmSource';


export const MarketingLayout: React.FunctionComponent = () => {
  const utmSource = useUtmSource();
  
  useEffect(()=>{
    PixelHelper.firePageView(utmSource);
  },[])

  return (
    <MessengerProvider>
      <AccountProvider accountService={userAccountService} authService={userAuthenticationService}>
        <SymbolsProvider predictionService={predictionService}>
          <FolloweeProvider>
            <PredictionProvider>
              <Outlet />
            </PredictionProvider>
          </FolloweeProvider>
        </SymbolsProvider>
      </AccountProvider>
    </MessengerProvider>
  );
}